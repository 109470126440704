import { TableBody, TableBodyProps, TableCell, TableRow, CircularProgress } from '@mui/material'
import React, { ReactNode } from 'react'

export type ExTableBodyProps = TableBodyProps & {
  children?: ReactNode
  loading: boolean
  emptyMessage?: string
}

export const ExTableBody: React.FC<ExTableBodyProps> = (props: ExTableBodyProps) => {
  const { loading, emptyMessage, ...rest } = props

  const progress = (): ReactNode => {
    return (
      <TableRow>
        <TableCell colSpan={100} style={{ textAlign: 'center' }}>
          <CircularProgress />
        </TableCell>
      </TableRow>
    )
  }

  const empty = (): ReactNode => {
    return (
      <TableRow>
        <TableCell colSpan={100} style={{ textAlign: 'center' }}>
          {props.emptyMessage ? props.emptyMessage : '結果がありません'}
        </TableCell>
      </TableRow>
    )
  }

  return (
    <TableBody {...rest}>
      {props.loading ? progress() : props.children && React.Children.count(props.children) > 0 ? props.children : empty()}
    </TableBody>
  )
}

export default ExTableBody
