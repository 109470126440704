import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import React from 'react'

type Props = {
  open: boolean
}

const OverlayLoadingProgress: React.FC<Props> = (props: { open: boolean }) => {
  return (
    <div className="loading-progress">
      <Backdrop className={'backdrop'} open={props.open}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <style>{`
        .loading-progress .backdrop {
          z-index: 10000;
          color: '#fff';
        }
      `}</style>
    </div>
  )
}

export default OverlayLoadingProgress
