import Paper from '@mui/material/Paper'
import { AuthorizationForm } from 'api/admin/authorization'
import { StringField } from 'components/shared/form/input'
import ThemedButton from 'components/shared/themed_button'
import Logo from 'images/logo.png'
import { Form } from 'rac'
import React from 'react'

type LoginFormProps = {
  form: Form<AuthorizationForm>
  handleClick: () => void
}
const LoginForm: React.FC<LoginFormProps> = (props) => {
  return (
    <Paper className="login-area">
      <div style={{ textAlign: 'center', width: '100%', marginBottom: 20 }}>
        <img src={Logo} alt="reprua-logo" style={{ objectFit: 'contain', width: 200 }} />
        <p style={{ fontWeight: 'bold' }}>機器管理</p>
      </div>
      <form className="login-form">
        <div>
          <StringField form={props.form} attr="email" className="email" label="メールアドレス" data-test="email-input" />
        </div>
        <div>
          <StringField
            form={props.form}
            attr="password"
            className="password"
            label="パスワード"
            type="password"
            data-test="password-input"
          />
        </div>
        <div style={{ textAlign: 'right' }}>
          <ThemedButton
            color="primary"
            onClick={() => {
              props.handleClick()
            }}
            data-test="login-button"
          >
            ログイン
          </ThemedButton>
        </div>
      </form>
    </Paper>
  )
}

export default LoginForm
