import { Checkbox, Collapse, FormControlLabel, Table, TableBody, TableRow, Tooltip } from '@mui/material'
import { useFetchPartnersApi } from 'api/mypage/partner'
import { useDeleteProofreadEventApi, usePlanProofreadEventApi } from 'api/mypage/proofread_event'
import { useFetchUsersApi } from 'api/mypage/users'
import { Flex } from 'components/shared/flex'
import {
  DateField,
  FilesInput,
  MonthField,
  MultipleCheckBoxField,
  NumberField,
  SelectField,
  StringField,
} from 'components/shared/form/input'
import LinkText from 'components/shared/link_text'
import Modal, { ModalSet } from 'components/shared/modal'
import { ExTableCell } from 'components/shared/table'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { MypageStateContext } from 'contexts/mypage_state_context'
import { ProofreadEvent, ProofreadEventForm } from 'entities/proofread_event'
import { useForm } from 'rac'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { MypageRouteHelper } from 'routes/mypage'
import { useEffectSkipFirst } from 'utils/hooks'
import { ProofreadApproverViewModel } from 'view_models/proofread_approver'
import { ProofreadEventViewModel } from 'view_models/proofread_event'

type NoPlanEventFormDialogProps = {
  modalSet: ModalSet<ProofreadEvent>
  onComplete: (event?: ProofreadEvent) => void
  onClickHistory: (event: ProofreadEvent) => void
}
export const NoPlanEventFormDialog: React.FC<NoPlanEventFormDialogProps> = (props) => {
  const history = useHistory()
  const [eventVm, setEventVm] = useState(new ProofreadEventViewModel(props.modalSet.item))
  const globalState = useContext(GlobalStateContext)
  const mypageState = useContext(MypageStateContext)
  const deleteApi = useDeleteProofreadEventApi(props.modalSet.item.id!)
  const usersApi = useFetchUsersApi()
  const partnersApi = useFetchPartnersApi()

  const approvableUsers = useMemo(() => {
    return usersApi.response.users.filter((u) => u.id !== mypageState.currentUser.id && u.authority?.proofread)
  }, [usersApi.response.users, mypageState.currentUser.id])
  const initialApproverIds = useMemo(() => {
    const eventUserId = props.modalSet.item.userId
    const isEventUserApprovable = Boolean(approvableUsers.find((user) => user.id === eventUserId))
    const isEventUserEqualsToCurrentUser = eventUserId === mypageState.currentUser.id

    // 現在ログインしているユーザがイベントに紐づくユーザではない かつ イベントに紐づくユーザは承認可能な場合
    // 初期値にイベントに紐づくユーザID設定する
    return eventUserId && !isEventUserEqualsToCurrentUser && isEventUserApprovable ? [eventUserId] : []
  }, [props.modalSet.item.userId, mypageState.currentUser.id, approvableUsers])
  const patchForm = useForm<ProofreadEventForm>({
    ...props.modalSet.item,
    aasmState: 'planned',
    approverIds: initialApproverIds,
    requireSupplier: !props.modalSet.item.instrument?.isShared && Boolean(props.modalSet.item.instrument?.proofreadInfo?.suppliableId),
    suppliableId: props.modalSet.item.instrument?.proofreadInfo?.suppliableId,
  })
  const patchApi = usePlanProofreadEventApi(patchForm)

  const handleSubmit = () => {
    const message = patchForm.object.startDate
      ? '校正イベントを登録します。校正期間に含まれる予約はキャンセルされますが、よろしいですか？'
      : '校正イベントを登録します。よろしいですか？'
    globalState.confirm(message, (e) => {
      if (e === 'ok') {
        patchApi.execute()
      }
    })
  }

  const handleDelete = () => {
    globalState.confirm('校正計画から外します。よろしいですか？', (e) => {
      if (e === 'ok') {
        deleteApi.execute()
      }
    })
  }

  // 承認可能なユーザの取得

  const handleClickSettingLink = () => {
    history.push(`${MypageRouteHelper.settingShow()}`)
  }

  useEffectSkipFirst(() => {
    setEventVm(new ProofreadEventViewModel(props.modalSet.item))
    patchForm.resetForm()
  }, [props.modalSet.item.id])

  useEffectSkipFirst(() => {
    if (!patchApi.isSuccess()) return
    props.onComplete(patchApi.response.proofreadEvent)
    mypageState.reloadUser()
  }, [patchApi.loading])

  useEffectSkipFirst(() => {
    if (!deleteApi.isSuccess()) return
    props.onComplete()
    mypageState.reloadUser()
  }, [deleteApi.loading])

  useEffect(() => {
    usersApi.execute()
    partnersApi.execute()
  }, [])

  useEffect(() => {
    if (patchForm.object.suppliableType) {
      const supplieableId =
        patchForm.object.suppliableType === 'Dealer' ? partnersApi.response.dealers[0] : partnersApi.response.outsideDealers[0]
      patchForm.newUpdateObject('suppliableId', supplieableId?.id)
    }
  }, [patchForm.object.suppliableType])

  return (
    <Modal
      modalId="event-planning-form"
      modalState={props.modalSet.modalState}
      title="校正計画設定"
      subTitle={eventVm.getInstrumentName()}
      footerStyle={{ display: 'inherit' }}
      footer={
        <Flex justifyContent="space-between" alignItems="center">
          <ThemedButton color="error" onClick={handleDelete}>
            計画から外す
          </ThemedButton>
          <div>
            <ThemedButton color="secondary" style={{ margin: 15 }} onClick={props.modalSet.closeModal}>
              閉じる
            </ThemedButton>
            <ThemedButton color="primary" onClick={handleSubmit}>
              設定
            </ThemedButton>
          </div>
        </Flex>
      }
    >
      <Flex justifyContent="space-between" style={{ marginBottom: 24 }}>
        <div>
          <label>最終校正月</label>
          <div style={{ fontSize: 20 }}>{eventVm.getLastResultDate()}</div>
        </div>
        <div>
          <label>校正周期</label>
          <div style={{ fontSize: 20 }}>{eventVm.getCycle()}</div>
        </div>
        <div>
          <label>有効期限</label>
          <div style={{ fontSize: 20 }}>{eventVm.getExpiredDate()}</div>
        </div>
        <div>
          <ThemedButton color="secondary" onClick={() => props.onClickHistory(props.modalSet.item)}>
            過去の校正を確認
          </ThemedButton>
        </div>
      </Flex>
      <Flex justifyContent="space-between">
        <MonthField label="校正月" form={patchForm} attr="scheduledDate" apiError={patchApi.apiError} required />
        <NumberField label="校正価格" form={patchForm} attr="price" apiError={patchApi.apiError} />
        <SelectField
          label="担当者"
          form={patchForm}
          attr="userId"
          apiError={patchApi.apiError}
          required
          data={usersApi.response.users.map((u) => ({ label: `${u.familyName} ${u.givenName}`, value: u.id }))}
          labelId="user-id"
        />
      </Flex>

      {/* 機器が共有状態なら、業者を選択できないようにする（業者選択自体が実質意味のないものになっている）*/}

      <>
        <Tooltip title={props.modalSet.item.instrument?.isShared && '機器が共有中なので外部業者に委託できません'}>
          <FormControlLabel
            control={
              <Checkbox
                checked={patchForm.object.requireSupplier}
                onChange={(e) => {
                  patchForm.update((f) => (f.requireSupplier = e.target.checked))
                }}
                disabled={props.modalSet.item.instrument?.isShared}
              />
            }
            label="外部業者に委託する"
          />
        </Tooltip>
        <Collapse in={patchForm.object.requireSupplier && !props.modalSet.item.instrument?.isShared}>
          <Flex>
            <SelectField
              form={patchForm}
              labelId="proofread-supplier-id-label"
              attr="suppliableId"
              label="外部業者"
              data={(partnersApi.response.outsideDealers || []).map((p) => ({ value: p.id!, label: p.name! }))}
              apiError={patchApi.apiError}
              required={patchForm.object.requireSupplier}
              disabled={!patchForm.object.requireSupplier}
            />
          </Flex>
        </Collapse>
      </>

      {/* 機器が共有状態なら、校正期間はディーラーが決めるので、予約作成は不要 */}
      {!props.modalSet.item.instrument?.isShared && props.modalSet.item.instrument?.canReserve && (
        <>
          <p style={{ color: 'red', marginBottom: 16 }}>予約管理対象機器のため、校正予約を作成します</p>
          <Flex>
            <DateField form={patchForm} attr="startDate" label="校正開始日" apiError={patchApi.apiError} required />
            <DateField form={patchForm} attr="endDate" label="校正終了日" apiError={patchApi.apiError} required />
          </Flex>
        </>
      )}
      <label>計画時添付ファイル</label>
      <FilesInput form={patchForm} attr="attachmentsAttributes" apiError={patchApi.apiError} />
      {(props.modalSet.item.attachments || []).length > 0 && (
        <div style={{ margin: '-15px 0 24px  0' }}>
          <label>添付ファイル</label>
          <ul style={{ listStyle: 'none', paddingLeft: 12 }}>
            {props.modalSet.item.attachments?.map((a) => {
              if (a.kind === 'plan')
                return (
                  <li>
                    <a href={a.url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                      {a.name}
                    </a>
                  </li>
                )
            })}
          </ul>
        </div>
      )}
      <div style={{ margin: '12px 0 ' }}>
        <label>承認者を選択してください</label>
        <Flex flexWrap="wrap">
          {approvableUsers.length > 0 ? (
            approvableUsers.map((user) => (
              <MultipleCheckBoxField
                label={`${user.familyName} ${user.givenName}`}
                form={patchForm}
                attr="approverIds"
                checked={patchForm.object.approverIds!.includes(user.id!)}
                apiError={patchApi.apiError}
                id={user.id!}
                key={user.id}
              />
            ))
          ) : (
            <div>
              <p>校正を承認可能なユーザがいません。</p>
              <p>
                承認者が必要な場合は
                <LinkText inline onClick={handleClickSettingLink}>
                  設定
                </LinkText>
                から「校正履歴の閲覧・編集の権限」を設定してください。
              </p>
            </div>
          )}
        </Flex>
      </div>
      {(props.modalSet.item.approvers || []).length > 0 && (
        <div>
          <label>承認結果</label>
          <Table>
            <TableBody>
              {props.modalSet.item.approvers?.map((a) => {
                if (a.kind === 'plan') {
                  const aVm = new ProofreadApproverViewModel(a)
                  return (
                    <TableRow key={`approve-${a.id}`}>
                      <ExTableCell style={{ width: 130 }}>
                        <div>
                          {a.approver?.familyName} {a.approver?.givenName}
                        </div>
                      </ExTableCell>
                      <ExTableCell style={{ width: 80 }}>
                        <div>{aVm.getApproveLabel()}</div>
                      </ExTableCell>
                      <ExTableCell>
                        <div>{aVm.getApprovalNote()}</div>
                      </ExTableCell>
                    </TableRow>
                  )
                }
              })}
            </TableBody>
          </Table>
        </div>
      )}
      <StringField label="計画メモ" form={patchForm} attr="noteOnPlan" apiError={patchApi.apiError} multiline />
    </Modal>
  )
}

export default NoPlanEventFormDialog
