import { Alert, AlertTitle } from '@mui/lab'
import { Snackbar } from '@mui/material'
import { ApiError } from 'rac'
import React, { useState } from 'react'
import { useEffectSkipFirst } from 'utils/hooks/index'

type Props = {
  error: ApiError
}
const ErrorSnackbar: React.FC<Props> = (props: Props) => {
  const [open, setOpen] = useState(false)

  useEffectSkipFirst(() => {
    if (props.error.messages.length > 0) {
      setOpen(true)
    }
  }, [props.error])

  const handleCloseSnackbar = () => {
    setOpen(false)
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleCloseSnackbar}
      className="error-snackbar"
    >
      <Alert variant="filled" severity="error">
        <AlertTitle>エラー</AlertTitle>
        <div data-test="error-alert-message">
          {props.error.messages.map((e, i) => (
            <div key={i}>{e}</div>
          ))}
        </div>
      </Alert>
    </Snackbar>
  )
}

export default ErrorSnackbar
