import { AxiosHttpClient } from 'api/axios'
import { useRepruaIndexApi } from 'api/shared_api_hooks'
import { ID, PagingResponse } from 'entities'
import { RepairEstimate } from 'entities/repair_estimate'
import { RepairEventSearchForm } from 'entities/repair_event'
import { IndexApiSet } from 'rac'
import { useEffectSkipFirst } from 'utils/hooks'

export type RepairEstimatesResponse = PagingResponse & {
  repairEstimates: RepairEstimate[]
}

/**
 * 一覧
 */
export type PartnerFetchInstrumentRepairEstimatesApiSet = IndexApiSet<RepairEstimatesResponse> & { execute: () => void }
export function usePartnerFetchInstrumentRepairEstimatesApi(instrumentId?: ID): PartnerFetchInstrumentRepairEstimatesApiSet {
  const api = useRepruaIndexApi<RepairEstimatesResponse, RepairEventSearchForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { repairEstimates: [], totalCount: 0 },
  })

  const execute = () => {
    const path = `instruments/${instrumentId}/repair_estimates`
    api.execute(path)
  }

  useEffectSkipFirst(() => {
    execute()
  }, [api.pageSet.page, api.pageSet.perPage, api.orderSet.order, api.orderSet.orderBy])

  return { ...api, execute }
}
