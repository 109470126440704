import { useFetchAdministratorApi } from 'api/admin/administrators'
import { AdminStateContext } from 'contexts/admin_state_context'
import React from 'react'

type AdminStateContainerProps = {
  children: React.ReactNode
}
export const AdminStateContainer = ({ children }: AdminStateContainerProps) => {
  const [currentAdmin, setCurrentAdmin] = React.useState({})
  const api = useFetchAdministratorApi()

  const reloadAdmin = () => {
    api.execute()
  }

  return (
    <AdminStateContext.Provider value={{ currentAdmin: currentAdmin, setCurrentAdmin: setCurrentAdmin, reloadAdmin: reloadAdmin }}>
      {children}
    </AdminStateContext.Provider>
  )
}

export default AdminStateContainer
