import { Tooltip } from '@mui/material'
import ThemedButton from 'components/shared/themed_button'
import React from 'react'

export const DisabledRestoreManagementButton = () => {
  return (
    <Tooltip title="削除済の顧客が紐づく場合はもとに戻すことができません。顧客を変更してください。">
      <div>
        <ThemedButton color="success" disabled>
          もとに戻す
        </ThemedButton>
      </div>
    </Tooltip>
  )
}
