import { Paper, TableContainer, TableHead, Box, TableBody, Table, TableRow, TableCell } from '@mui/material'
import { useFetchPartnerDealerUsersApi } from 'api/partner/dealer_users'
import { DealerDivisionDealerUserRelationModal } from 'components/partner/setting/dealer_division_dealer_user_relation_modal'
import { DealerUserAuthorityFormDialog } from 'components/partner/setting/dealer_user_authority_form_dialog'
import { LaboratoryRelationFormDialog } from 'components/partner/setting/laboratory_relation_form_dialog'
import { DropdownMenu } from 'components/shared/dropdown_menu'
import { useModal, useMultipleModal } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { PartnerStateContext } from 'contexts/partner_state_context'
import { DealerUser } from 'entities/dealer_user'
import { authorityKeys } from 'entities/dealer_user_authority'
import React, { useContext, useEffect, useState } from 'react'
import { DealerUserViewModel } from 'view_models/dealer_user'

export const DealerUserListBox = () => {
  const globalState = useContext(GlobalStateContext)
  const { currentDealerUser, reloadUser, reloadSelectableOwners } = useContext(PartnerStateContext)
  const fetchApi = useFetchPartnerDealerUsersApi()
  const [dealerUsers, setDealerUsers] = useState<DealerUser[]>([])
  const authorityModal = useModal()
  const manageModal = useMultipleModal<DealerUser>()
  const divisionModal = useMultipleModal<DealerUser>()

  useEffect(() => {
    fetchApi.execute()
  }, [currentDealerUser.id])

  useEffect(() => {
    globalState.setLoading(fetchApi.loading)
    if (!fetchApi.isSuccess()) return
    setDealerUsers(fetchApi.response.dealerUsers)
  }, [fetchApi.loading])

  const handleComplete = () => {
    authorityModal.setOpen(false)
    fetchApi.execute()
    reloadUser()
  }

  const handleCompleteManageLab = () => {
    fetchApi.execute()
    reloadUser()
    reloadSelectableOwners()
  }

  const handleCompleteDivision = () => {
    divisionModal.closeModal()
    fetchApi.execute()
    reloadUser()
    reloadSelectableOwners()
  }

  return (
    <Box>
      {currentDealerUser.authority?.privileged && (
        <>
          <DealerDivisionDealerUserRelationModal modalSet={divisionModal} onComplete={handleCompleteDivision} />
          <DealerUserAuthorityFormDialog modalState={authorityModal} users={dealerUsers} onComplete={handleComplete} />
          <LaboratoryRelationFormDialog modalSet={manageModal} onComplete={handleCompleteManageLab} />
        </>
      )}
      <Box display="flex" alignItems="center" marginBottom={1}>
        <h3 style={{ marginRight: '10px' }}>ユーザー権限設定</h3>
        {currentDealerUser.authority?.privileged && (
          <ThemedButton color="primary" onClick={() => authorityModal.setOpen(true)}>
            権限設定
          </ThemedButton>
        )}
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>名前</TableCell>
              <TableCell>ユーザー権限</TableCell>
              <TableCell>担当顧客</TableCell>
              <TableCell>所属部署</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dealerUsers.map((user: DealerUser, idx: number) => {
              const vm = new DealerUserViewModel(user)
              return (
                <TableRow key={idx}>
                  <TableCell width={160}>{vm.getName()}</TableCell>
                  <TableCell width={240}>
                    {authorityKeys.map((type, idx) => (
                      <span key={`auth-${idx}`}>{vm.authority(type)}</span>
                    ))}
                  </TableCell>
                  <TableCell width={500}>
                    {(user.laboratories || []).map((labo, idx) => (
                      <span key={`lab-${idx}`}>
                        {labo.name}
                        {idx + 1 === user.laboratories?.length ? '' : ' / '}
                      </span>
                    ))}
                    <br />
                    {(user.outsideLaboratories || []).map((labo, idx) => (
                      <span key={`outside-lab-${idx}`}>
                        {labo.name}
                        {idx + 1 === user.outsideLaboratories?.length ? '' : ' / '}
                      </span>
                    ))}
                  </TableCell>
                  <TableCell width={500}>
                    {(user.divisions || []).map((division, idx) => (
                      <span key={`lab-${idx}`}>
                        {division.name}
                        {idx + 1 === user.divisions?.length ? '' : ' / '}
                      </span>
                    ))}
                  </TableCell>
                  <TableCell width={200} align="right">
                    {currentDealerUser.authority?.privileged && (
                      <DropdownMenu
                        id="dealer-user-settings"
                        buttonText="担当設定"
                        listItems={[
                          { text: '担当顧客設定', onClick: () => manageModal.openModal(user) },
                          { text: '部署設定', onClick: () => divisionModal.openModal(user) },
                        ]}
                      />
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
