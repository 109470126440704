import { PrintImageType } from 'entities/print_setting'
import Cookies from 'js-cookie'
import { TabTypes } from 'utils/tab_types'

enum Key {
  UserToken = 'user_token',
  AdminToken = 'admin_token',
  DealerUserToken = 'dealer_token',
  PrintImageType = 'print_image_type',
}
export default class CookieManager {
  // ラボユーザートークン
  public static saveUserToken(token: string) {
    Cookies.set(Key.UserToken, token, { expires: 60, secure: true })
  }
  public static getUserToken(): string {
    return Cookies.get(Key.UserToken) as string
  }
  public static removeUserToken(): void {
    Cookies.remove(Key.UserToken)
  }
  public static hasUserToken(): boolean {
    return !CookieManager.getUserToken() ? false : true
  }

  // 管理者ユーザートークン
  public static saveAdminToken(token: string) {
    Cookies.set(Key.AdminToken, token, { expires: 60, secure: true })
  }
  public static getAdminToken(): string {
    return Cookies.get(Key.AdminToken) as string
  }
  public static removeAdminToken(): void {
    Cookies.remove(Key.AdminToken)
  }
  public static hasAdminToken(): boolean {
    return !CookieManager.getAdminToken() ? false : true
  }

  // ディーラーユーザートークン
  public static saveDealerUserToken(token: string) {
    Cookies.set(Key.DealerUserToken, token, { expires: 60, secure: true })
  }
  public static getDealerUserToken(): string {
    return Cookies.get(Key.DealerUserToken) as string
  }
  public static removeDealerUserToken(): void {
    Cookies.remove(Key.DealerUserToken)
  }
  public static hasDealerUserToken(): boolean {
    return !CookieManager.getDealerUserToken() ? false : true
  }

  // タブ選択保持
  public static saveSelectedTab<T extends string>(type: TabTypes, value: T): void {
    Cookies.set(`${type}-tab`, value)
  }
  public static getSelectedTab<T>(type: TabTypes): T | undefined {
    const tabId = Cookies.get(`${type}-tab`) as T | undefined
    return tabId
  }

  // 印刷イメージタイプ保持（バーコード or QRコード）
  public static saveSelectedPrintImageType(value: PrintImageType): void {
    Cookies.set(Key.PrintImageType, value)
  }
  public static getSelectedPrintImageType(): PrintImageType {
    return Cookies.get(Key.PrintImageType) as PrintImageType
  }
}
