import { Grid, List, ListItem, ListItemText, Paper } from '@mui/material'
import { useFetchLaboratoryApi } from 'api/mypage/laboratory'
import ApprovalSettingListBox from 'components/mypage/setting/approval_setting_list_box'
import { CancelPolicyListBox } from 'components/mypage/setting/cancel_policy_list_box'
import { InstrumentCategoryListBox } from 'components/mypage/setting/instrument_category_list_box'
import { OutsideDealersBox } from 'components/mypage/setting/outside_dealers_box'
import { PersonalSettingsBox } from 'components/mypage/setting/personal_settings_box'
import { ReservationConstraintListBox } from 'components/mypage/setting/reservation_constraint_list_box'
import { UserListBox } from 'components/mypage/setting/user_list_box'
import { ContentHeader, ContentTitle } from 'components/shared/content'
import { GlobalStateContext } from 'contexts/global_state_context'
import { MypageStateContext } from 'contexts/mypage_state_context'
import { Laboratory } from 'entities/laboratory'
import React, { useContext, useEffect, useState } from 'react'
import { StaticContext } from 'react-router'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import CookieManager from 'utils/cookie_util'
import { tabTypes } from 'utils/tab_types'

export type SettingShowProps = {}

type Props = RouteComponentProps<{}, StaticContext, SettingShowProps>

type SettingItem = {
  component: JSX.Element
}

type SettingItemKeys =
  | 'users'
  | 'cancelPolicies'
  | 'equipmentCategories'
  | 'constraints'
  | 'approvals'
  | 'outsideDealers'
  | 'personalSettings'

type SettingItems = {
  [key in SettingItemKeys]: SettingItem
}

export const SettingShow: React.FC<Props> = (props: Props) => {
  const globalState = useContext(GlobalStateContext)
  const laboratoryApi = useFetchLaboratoryApi()
  const [laboratory, setLaboratory] = useState<Laboratory | undefined>({})
  const { currentUser, reloadUser } = useContext(MypageStateContext)
  const selectedTab = CookieManager.getSelectedTab<SettingItemKeys>(tabTypes.SETTING)
  const [currentSetting, setCurrentSetting] = useState<SettingItemKeys>(
    // 'mailNotification' は旧名で、セッション保持している場合にバグが発生してしまうため
    // @ts-ignore
    !selectedTab || selectedTab === 'mailNotification' ? 'users' : selectedTab,
  )

  const handleCompleteUpdate = () => {
    laboratoryApi.execute(currentUser.laboratoryId!, false)
    reloadUser()
  }

  const settingItems: SettingItems = {
    users: {
      component: <UserListBox users={laboratory?.users || []} onComplete={handleCompleteUpdate} isManager={false} />,
    },
    cancelPolicies: {
      component: <CancelPolicyListBox />,
    },
    equipmentCategories: {
      component: <InstrumentCategoryListBox />,
    },
    constraints: {
      component: <ReservationConstraintListBox />,
    },
    approvals: {
      component: <ApprovalSettingListBox users={laboratory?.users} />,
    },
    outsideDealers: {
      component: <OutsideDealersBox />,
    },
    personalSettings: {
      component: <PersonalSettingsBox />,
    },
  }

  useEffect(() => {
    if (currentUser.laboratoryId) {
      laboratoryApi.execute(currentUser.laboratoryId, false)
    }
  }, [currentUser.laboratoryId])

  useEffect(() => {
    CookieManager.saveSelectedTab<SettingItemKeys>(tabTypes.SETTING, currentSetting)
  }, [currentSetting])

  /**
   * ローディングプログレスの表示
   */
  useEffect(() => {
    globalState.setLoading(laboratoryApi.loading)
    if (laboratoryApi.isSuccess()) {
      setLaboratory(laboratoryApi.response.laboratory)
    }
  }, [laboratoryApi.loading])

  return (
    <>
      <ContentHeader>
        <ContentTitle title="設定" />
      </ContentHeader>
      <Grid container spacing={3} style={{ padding: '20px 10px' }}>
        {/* ユーザ */}
        <Grid item xs={9}>
          {settingItems[currentSetting].component}
        </Grid>

        {/* キャンセルポリシー、機器カテゴリ */}
        <Grid item xs={3}>
          <Paper style={{ marginTop: 56 }}>
            <List component="nav" aria-label="main mailbox folders">
              <ListItem button onClick={() => setCurrentSetting('users')} selected={currentSetting === 'users'}>
                <ListItemText primary="ユーザー権限設定" />
              </ListItem>
              <ListItem button onClick={() => setCurrentSetting('cancelPolicies')} selected={currentSetting === 'cancelPolicies'}>
                <ListItemText primary="キャンセルポリシー設定" />
              </ListItem>
              <ListItem button onClick={() => setCurrentSetting('equipmentCategories')} selected={currentSetting === 'equipmentCategories'}>
                <ListItemText primary="機器カテゴリ設定" />
              </ListItem>
              <ListItem button onClick={() => setCurrentSetting('constraints')} selected={currentSetting === 'constraints'}>
                <ListItemText primary="予約条件設定" />
              </ListItem>
              <ListItem button onClick={() => setCurrentSetting('approvals')} selected={currentSetting === 'approvals'}>
                <ListItemText primary="多段階承認設定" />
              </ListItem>
              <ListItem button onClick={() => setCurrentSetting('outsideDealers')} selected={currentSetting === 'outsideDealers'}>
                <ListItemText primary="外部業者設定" />
              </ListItem>
              <ListItem button onClick={() => setCurrentSetting('personalSettings')} selected={currentSetting === 'personalSettings'}>
                <ListItemText primary="個人設定" />
              </ListItem>
            </List>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default withRouter(SettingShow)
