import { MutationErrorResponse } from 'api/MutationErrorResponse'
import { AxiosHttpClient } from 'api/axios'
import { useRepruaIndexApi } from 'api/shared_api_hooks'
import { GlobalStateContext } from 'contexts/global_state_context'
import { ID } from 'entities'
import { LaboratoryAuthority } from 'entities/laboratory_authority'
import { User } from 'entities/user'
import { BaseResponse, IndexApiSet } from 'rac'
import { useContext, useEffect } from 'react'
import { useMutation } from 'react-query'
import { useMutationMessageHandler } from 'utils/hooks/use_mutation_message_handler'

type LaboratoryUsersResponse = BaseResponse & {
  users: User[]
}
/**
 * 一覧
 */
export function useFetchAdminLaboratoryUsersApi(): IndexApiSet<LaboratoryUsersResponse> & { execute: (id: ID) => void } {
  const api = useRepruaIndexApi<LaboratoryUsersResponse, {}>(new AxiosHttpClient('admin'), { initialResponse: { users: [] } })

  const execute = (id: ID) => {
    const path = `laboratories/${id}/users`
    api.execute(path)
  }

  return { ...api, execute }
}

export type PostAdminLaboratoryUpdateAuthorityResponse = BaseResponse & {
  laboratoryAuthority: LaboratoryAuthority
}

export type PostAdminLaboratoryUpdateAuthorityParams = {
  laboratoryId: ID
  enableInstrumentSharing: boolean
}

/**
 * 機器インポートの権限のオンオフのAPI
 */
export const usePostAdminLaboratoryUpdateAuthorityApi = () => {
  const client = new AxiosHttpClient('admin')
  const globalState = useContext(GlobalStateContext)
  const mutation = useMutation<PostAdminLaboratoryUpdateAuthorityResponse, MutationErrorResponse, PostAdminLaboratoryUpdateAuthorityParams>(
    'laboratories_update_authority',
    async (params) => {
      const { data } = await client.post(`laboratories/${params.laboratoryId}/update_authority`, params)
      return data
    },
  )

  // 成功時のトーストメッセージ
  useEffect(() => {
    if (mutation.isSuccess && mutation.data.message) {
      globalState.setNotificationMessage({ body: mutation.data.message })
    }
  }, [mutation.isLoading])

  useMutationMessageHandler(mutation)

  return mutation
}
