import { TableContainer, Table, TableHead, TableRow } from '@mui/material'
import { PartnerFetchProofreadEventsApi } from 'api/partner/proofread_plans/proofread_event'
import { CompletedEventFormDialog } from 'components/partner/proofread/proofread_event/completed_event_form_dialog'
import { CheckboxFooter } from 'components/shared/checkbox_footer'
import ImageView from 'components/shared/image_view'
import { useMultipleModal } from 'components/shared/modal'
import Pagination from 'components/shared/pagination'
import { AllCheckboxTableCell, ExTableCell, ExTableBody, ExTableRow, CheckboxTableCell } from 'components/shared/table'
import ThemedButton from 'components/shared/themed_button'
import { ProofreadEvent } from 'entities/proofread_event'
import React from 'react'
import { getTableHeight, PROOFREAD_CHECKED_TABLE_PADDING, PROOFREAD_TABLE_PADDING } from 'utils/component_heights'
import { useCheckboxes, useEffectSkipFirst } from 'utils/hooks'
import { ProofreadEventViewModel } from 'view_models/proofread_event'

type CompletedEventTableProps = {
  indexApi: PartnerFetchProofreadEventsApi
  onUpdate: () => void
  onClickExAttr: (event: ProofreadEvent) => void
  onClickBulkExAttr: (eventIds: number[]) => void
  isPlanForLaboratory: boolean
}
export const CompletedEventTable = (props: CompletedEventTableProps) => {
  const checkboxSet = useCheckboxes(props.indexApi.response.proofreadEvents)
  const checkModalSet = useMultipleModal<ProofreadEvent>()
  const handleComplete = () => {
    checkModalSet.modalState.setOpen(false)
    props.indexApi.execute()
  }

  useEffectSkipFirst(() => {
    if (!props.indexApi.isSuccess()) return
  }, [props.indexApi.loading])

  return (
    <>
      <CompletedEventFormDialog modalSet={checkModalSet} isPlanForLaboratory={props.isPlanForLaboratory} onComplete={handleComplete} />
      <TableContainer
        style={{
          maxHeight:
            checkboxSet.checkedIds.length > 0 ? getTableHeight(PROOFREAD_CHECKED_TABLE_PADDING) : getTableHeight(PROOFREAD_TABLE_PADDING),
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <AllCheckboxTableCell checkboxSet={checkboxSet} />
              <ExTableCell colSpan={2} align="center">
                製品名
              </ExTableCell>
              <ExTableCell align="center">引渡日</ExTableCell>
              <ExTableCell align="center">返却日</ExTableCell>
              <ExTableCell align="center">料金</ExTableCell>
              <ExTableCell />
              <ExTableCell />
            </TableRow>
          </TableHead>
          <ExTableBody loading={props.indexApi.loading}>
            {props.indexApi.response.proofreadEvents.map((event: ProofreadEvent) => {
              const vm = new ProofreadEventViewModel(event)
              return (
                <ExTableRow key={event.id}>
                  <CheckboxTableCell checkboxId={event.id!} checkboxesSet={checkboxSet} />
                  <ExTableCell width={100}>
                    <ImageView maxHeight={100} maxWidth={100} src={vm.getInstrumentImage()} />
                  </ExTableCell>
                  <ExTableCell>{vm.getInstrumentInfo()}</ExTableCell>
                  <ExTableCell align="center">{vm.getSupplierDeliveredDate()}</ExTableCell>
                  <ExTableCell align="center">{vm.getSupplierReturnedDate()}</ExTableCell>
                  <ExTableCell align="center">{vm.getPrice()}</ExTableCell>
                  <ExTableCell width={122}>
                    <ThemedButton color="success" onClick={() => props.onClickExAttr(event)}>
                      管理情報
                    </ThemedButton>
                  </ExTableCell>
                  <ExTableCell>
                    <ThemedButton color="secondary" onClick={() => checkModalSet.openModal(event)}>
                      確認
                    </ThemedButton>
                  </ExTableCell>
                </ExTableRow>
              )
            })}
          </ExTableBody>
        </Table>
      </TableContainer>
      <Pagination response={props.indexApi.response} pageSet={props.indexApi.pageSet} />
      <CheckboxFooter
        checkboxSet={checkboxSet}
        content={
          <>
            <ThemedButton color="success" onClick={() => props.onClickBulkExAttr(checkboxSet.checkedIds)} style={{ marginRight: 12 }}>
              校正管理情報を一括登録
            </ThemedButton>
          </>
        }
      />
    </>
  )
}

export default CompletedEventTable
