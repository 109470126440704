import { TableContainer, Table, TableHead, TableRow } from '@mui/material'
import { PartnerFetchProofreadEventsApi } from 'api/partner/proofread_plans/proofread_event'
import ScheduleEventFormDialog from 'components/partner/proofread/proofread_event/schedule_event_form_dialog'
import { CheckboxFooter } from 'components/shared/checkbox_footer'
import ImageView from 'components/shared/image_view'
import { useModal } from 'components/shared/modal'
import Pagination from 'components/shared/pagination'
import { ExTableCell, ExTableBody, ExTableRow, CheckboxTableCell, AllCheckboxTableCell } from 'components/shared/table'
import ThemedButton from 'components/shared/themed_button'
import { ProofreadEvent } from 'entities/proofread_event'
import React from 'react'
import { getTableHeight, PROOFREAD_CHECKED_TABLE_PADDING, PROOFREAD_TABLE_PADDING } from 'utils/component_heights'
import { useCheckboxes, useEffectSkipFirst } from 'utils/hooks'
import { ProofreadEventViewModel } from 'view_models/proofread_event'

type ApprovedPlanEventTableProps = {
  indexApi: PartnerFetchProofreadEventsApi
  planId: number
  onUpdate: () => void
  onClickExAttr: (event: ProofreadEvent) => void
  onClickBulkExAttr: (eventIds: number[]) => void
}
export const ApprovedPlanEventTable: React.FC<ApprovedPlanEventTableProps> = (props) => {
  const checkboxSet = useCheckboxes(props.indexApi.response.proofreadEvents)
  const scheduleModalState = useModal()

  const handleCompleteSchedule = () => {
    scheduleModalState.setOpen(false)
    props.onUpdate()
  }

  useEffectSkipFirst(() => {
    if (!props.indexApi.isSuccess()) return
  }, [props.indexApi.loading])

  return (
    <>
      <ScheduleEventFormDialog
        modalState={scheduleModalState}
        eventIds={checkboxSet.checkedIds}
        onComplete={handleCompleteSchedule}
        planId={props.planId}
      />
      <TableContainer
        style={{
          maxHeight:
            checkboxSet.checkedIds.length > 0 ? getTableHeight(PROOFREAD_CHECKED_TABLE_PADDING) : getTableHeight(PROOFREAD_TABLE_PADDING),
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <AllCheckboxTableCell checkboxSet={checkboxSet} />
              <ExTableCell colSpan={2} align="center">
                製品名
              </ExTableCell>
              <ExTableCell align="center">校正月</ExTableCell>
              <ExTableCell align="center">料金</ExTableCell>
              <ExTableCell />
            </TableRow>
          </TableHead>
          <ExTableBody loading={props.indexApi.loading}>
            {props.indexApi.response.proofreadEvents.map((event: ProofreadEvent) => {
              const vm = new ProofreadEventViewModel(event)
              return (
                <ExTableRow key={event.id}>
                  <CheckboxTableCell checkboxId={event.id!} checkboxesSet={checkboxSet} />
                  <ExTableCell width={100}>
                    <ImageView maxHeight={100} maxWidth={100} src={vm.getInstrumentImage()} />
                  </ExTableCell>
                  <ExTableCell>{vm.getInstrumentInfo()}</ExTableCell>
                  <ExTableCell align="center">{vm.getScheduledDate()}</ExTableCell>
                  <ExTableCell align="center">{vm.getPrice()}</ExTableCell>
                  <ExTableCell width={122}>
                    <ThemedButton color="success" onClick={() => props.onClickExAttr(event)}>
                      管理情報
                    </ThemedButton>
                  </ExTableCell>
                </ExTableRow>
              )
            })}
          </ExTableBody>
        </Table>
      </TableContainer>
      <Pagination response={props.indexApi.response} pageSet={props.indexApi.pageSet} />
      <CheckboxFooter
        checkboxSet={checkboxSet}
        content={
          <>
            <ThemedButton color="primary" onClick={() => scheduleModalState.setOpen(true)} style={{ marginRight: 12 }}>
              引渡日を設定
            </ThemedButton>
            <ThemedButton color="success" onClick={() => props.onClickBulkExAttr(checkboxSet.checkedIds)} style={{ marginRight: 12 }}>
              校正管理情報を一括登録
            </ThemedButton>
          </>
        }
      />
    </>
  )
}

export default ApprovedPlanEventTable
