import { Table, TableHead, TableRow } from '@mui/material'
import { usePartnerPatchProofreadScheduleApi, usePartnerPostProofreadSchedlueApi } from 'api/partner/proofread_schedule'
import { Flex } from 'components/shared/flex'
import { DateField, StringField } from 'components/shared/form/input'
import ImageView from 'components/shared/image_view'
import Modal, { ModalSet, ModalState } from 'components/shared/modal'
import { ExTableCell, ExTableBody, ExTableRow } from 'components/shared/table'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { ProofreadEvent } from 'entities/proofread_event'
import { ProofreadSchedule, ProofreadScheduleForm } from 'entities/proofread_schedule'
import { useForm } from 'rac'
import React, { useContext, useState } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'
import { ProofreadEventViewModel } from 'view_models/proofread_event'
import { ProofreadScheduleViewModel } from 'view_models/proofread_schedule'

type ScheduleEventFormDialogProps = {
  modalState: ModalState
  planId: number
  eventIds: number[]
  onComplete: () => void
}
export const ScheduleEventFormDialog: React.FC<ScheduleEventFormDialogProps> = (props) => {
  const globalState = useContext(GlobalStateContext)

  const postForm = useForm<ProofreadScheduleForm>({ proofreadPlanId: props.planId })
  const postApi = usePartnerPostProofreadSchedlueApi(postForm)

  const handleSubmit = () => {
    globalState.confirm('校正スケジュールをラボ側に申請します。よろしいですか？', (e) => {
      if (e === 'ok') {
        postApi.execute()
      }
    })
  }

  useEffectSkipFirst(() => {
    if (!props.modalState.open) return
    postForm.newUpdateObject('eventIds', props.eventIds)
  }, [props.modalState.open])

  useEffectSkipFirst(() => {
    if (!postApi.isSuccess()) return
    props.onComplete()
  }, [postApi.loading])

  return (
    <Modal
      title={`日程の依頼 - ${props.eventIds.length}件選択中`}
      modalId="create-schedule-modal"
      modalState={props.modalState}
      size="sm"
      footer={
        <>
          <ThemedButton color="secondary" onClick={() => props.modalState.setOpen(false)}>
            閉じる
          </ThemedButton>
          <ThemedButton color="primary" onClick={handleSubmit}>
            設定
          </ThemedButton>
        </>
      }
    >
      <DateField label="校正開始日（引渡日・出張校正開始日等）" form={postForm} attr="deliveredDate" apiError={postApi.apiError} required />
      <DateField label="校正終了日（返却日・出張校正終了日等）" form={postForm} attr="returnedDate" apiError={postApi.apiError} required />
      <StringField label="メモ" form={postForm} attr="note" apiError={postApi.apiError} multiline />
    </Modal>
  )
}

export default ScheduleEventFormDialog

type ScheduleEventEditFormDialogProps = {
  modalSet: ModalSet<ProofreadSchedule>
  onComplete: () => void
}
export const ScheduleEventEditFormDialog: React.FC<ScheduleEventEditFormDialogProps> = (props) => {
  const globalState = useContext(GlobalStateContext)

  const patchForm = useForm<ProofreadScheduleForm>({ ...props.modalSet.item }, 'proofreadSchedule')
  const patchApi = usePartnerPatchProofreadScheduleApi(patchForm)

  const handleSubmit = () => {
    globalState.confirm('校正スケジュールを更新してラボに申請します。よろしいですか？', (e) => {
      if (e === 'ok') {
        patchApi.execute()
      }
    })
  }

  useEffectSkipFirst(() => {
    if (props.modalSet.item) {
      patchForm.resetForm()
    }
  }, [props.modalSet.item])

  useEffectSkipFirst(() => {
    if (!patchApi.isSuccess()) return
    props.onComplete()
  }, [patchApi.loading])

  return (
    <Modal
      title="日程の変更申請"
      modalId="edit-schedule-modal"
      modalState={props.modalSet.modalState}
      footer={
        <>
          <ThemedButton color="secondary" onClick={() => props.modalSet.closeModal()}>
            閉じる
          </ThemedButton>
          <ThemedButton color="primary" onClick={handleSubmit}>
            申請
          </ThemedButton>
        </>
      }
    >
      <Flex>
        <DateField
          label="校正開始日（引渡日・出張校正開始日等）"
          form={patchForm}
          attr="deliveredDate"
          apiError={patchApi.apiError}
          required
        />
        <DateField
          label="校正終了日（返却日・出張校正終了日等）"
          form={patchForm}
          attr="returnedDate"
          apiError={patchApi.apiError}
          required
        />
      </Flex>
      <StringField label="メモ" form={patchForm} attr="note" apiError={patchApi.apiError} multiline />

      <Table>
        <TableHead>
          <TableRow>
            <ExTableCell colSpan={2} align="center">
              製品名
            </ExTableCell>
            <ExTableCell align="center">校正月</ExTableCell>
            <ExTableCell align="center">費用</ExTableCell>
          </TableRow>
        </TableHead>
        <ExTableBody loading={false}>
          {props.modalSet.item.proofreadEvents?.map((event: ProofreadEvent) => {
            const vm = new ProofreadEventViewModel(event)
            return (
              <ExTableRow key={event.id}>
                <ExTableCell width={100}>
                  <ImageView maxHeight={100} maxWidth={100} src={vm.getInstrumentImage()} />
                </ExTableCell>
                <ExTableCell>{vm.getInstrumentInfo(true)}</ExTableCell>
                <ExTableCell align="center">{vm.getScheduledDate()}</ExTableCell>
                <ExTableCell align="center">{vm.getPrice()}</ExTableCell>
              </ExTableRow>
            )
          })}
        </ExTableBody>
      </Table>
    </Modal>
  )
}

type ScheduleEventCheckDialogProps = {
  modalSet: ModalSet<ProofreadSchedule>
}
export const ScheduleEventCheckDialog: React.FC<ScheduleEventCheckDialogProps> = (props) => {
  const [scheduleVm, setScheduleVm] = useState(new ProofreadScheduleViewModel(props.modalSet.item))

  useEffectSkipFirst(() => {
    setScheduleVm(new ProofreadScheduleViewModel(props.modalSet.item))
  }, [props.modalSet.item.id])

  return (
    <Modal
      title="校正スケジュールの確認"
      modalId="check-schedule-modal"
      modalState={props.modalSet.modalState}
      footer={
        <>
          <ThemedButton color="secondary" onClick={() => props.modalSet.closeModal()}>
            閉じる
          </ThemedButton>
        </>
      }
    >
      <Flex justifyContent="space-around" style={{ marginBottom: 24 }}>
        <div>
          <label>引渡日</label>
          <div style={{ fontSize: 20 }}>{scheduleVm.getDeliveredDate()}</div>
        </div>
        <div>
          <label>返却日</label>
          <div style={{ fontSize: 20 }}>{scheduleVm.getReturnedDate()}</div>
        </div>
      </Flex>
      <div>
        <label>メモ</label>
        <div>{scheduleVm.getNote()}</div>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <ExTableCell colSpan={2} align="center">
              製品名
            </ExTableCell>
            <ExTableCell align="center">校正月</ExTableCell>
            <ExTableCell align="center">費用</ExTableCell>
          </TableRow>
        </TableHead>
        <ExTableBody loading={false}>
          {props.modalSet.item.proofreadEvents?.map((event: ProofreadEvent) => {
            const vm = new ProofreadEventViewModel(event)
            return (
              <ExTableRow key={event.id}>
                <ExTableCell width={100}>
                  <ImageView maxHeight={100} maxWidth={100} src={vm.getInstrumentImage()} />
                </ExTableCell>
                <ExTableCell>{vm.getInstrumentInfo(true)}</ExTableCell>
                <ExTableCell align="center">{vm.getScheduledDate()}</ExTableCell>
                <ExTableCell align="center">{vm.getPrice()}</ExTableCell>
              </ExTableRow>
            )
          })}
        </ExTableBody>
      </Table>
    </Modal>
  )
}
