import { MutationErrorResponse } from 'api/MutationErrorResponse'
import { AxiosHttpClient } from 'api/axios'
import { useRepruaIndexApi } from 'api/shared_api_hooks'
import { GlobalStateContext } from 'contexts/global_state_context'
import { ID } from 'entities'
import { DealerAuthority } from 'entities/dealer_authority'
import { DealerUser } from 'entities/dealer_user'
import { BaseResponse, IndexApiSet } from 'rac'
import { useContext, useEffect } from 'react'
import { useMutation } from 'react-query'
import { useMutationMessageHandler } from 'utils/hooks/use_mutation_message_handler'

type DealerUsersResponse = BaseResponse & {
  dealerUsers: DealerUser[]
}
/**
 * 一覧
 */
export function useFetchAdminDealerUsersApi(): IndexApiSet<DealerUsersResponse> & { execute: (id: ID) => void } {
  const api = useRepruaIndexApi<DealerUsersResponse, {}>(new AxiosHttpClient('admin'), { initialResponse: { dealerUsers: [] } })

  const execute = (id: ID) => {
    const path = `dealers/${id}/dealer_users`
    api.execute(path)
  }

  return { ...api, execute }
}

export type PostAdminDealerUpdateAuthorityResponse = BaseResponse & {
  dealerAuthority: DealerAuthority
}

export type PostAdminDealerUpdateAuthorityParams = {
  dealerId: ID
  instrumentImport: boolean
  enableInstrumentSharing: boolean
}

/**
 * 機器インポートの権限のオンオフのAPI
 */
export const usePostAdminDealerUpdateAuthorityApi = () => {
  const client = new AxiosHttpClient('admin')
  const globalState = useContext(GlobalStateContext)
  const mutation = useMutation<PostAdminDealerUpdateAuthorityResponse, MutationErrorResponse, PostAdminDealerUpdateAuthorityParams>(
    'dealers_update_authority',
    async (params) => {
      const { data } = await client.post(`dealers/${params.dealerId}/update_authority`, params)
      return data
    },
  )

  // 成功時のトーストメッセージ
  useEffect(() => {
    if (mutation.isSuccess && mutation.data.message) {
      globalState.setNotificationMessage({ body: mutation.data.message })
    }
  }, [mutation.isLoading])

  useMutationMessageHandler(mutation)

  return mutation
}
