import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import ThemedButton from 'components/shared/themed_button'
import { ReservationConstraintSet } from 'entities/reservation_constraint_set'
import React, { useState } from 'react'

import { ConstraintDetail } from './constraint_detail'

type ConstraintDetailDialogProps = {
  reservationConstraintSets: ReservationConstraintSet[]
}

export const ConstraintDetailDialog: React.FC<ConstraintDetailDialogProps> = (props: ConstraintDetailDialogProps) => {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <ThemedButton onClick={handleClickOpen} color="secondary">
        予約条件の確認
      </ThemedButton>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="xl">
        <DialogTitle id="form-dialog-title">自分に割り当てられた予約条件</DialogTitle>
        <DialogContent>
          {props.reservationConstraintSets.map((constraintSet) => {
            return (
              <div style={{ marginBottom: 10 }} key={constraintSet.id}>
                <ConstraintDetail reservationConstraintSet={constraintSet} />
              </div>
            )
          })}
        </DialogContent>
        <DialogActions>
          <ThemedButton onClick={handleClose} color="secondary">
            閉じる
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}
