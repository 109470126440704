import { SelectableOwners } from 'containers/partner_state_container'
import { Dealer } from 'entities/dealer'
import { DealerUser } from 'entities/dealer_user'
import React, { Dispatch, SetStateAction } from 'react'

type PartnerStateContext = {
  currentDealer: Dealer
  currentDealerUser: DealerUser
  setCurrentDealer: Dispatch<SetStateAction<Dealer>>
  setCurrentDealerUser: Dispatch<SetStateAction<DealerUser>>
  reloadUser: () => void
  selectableOwners: SelectableOwners
  setSelectableOwners: Dispatch<SetStateAction<SelectableOwners>>
  reloadSelectableOwners: () => void
}

export const initialPartnerState: PartnerStateContext = {
  currentDealer: {},
  currentDealerUser: {},
  setCurrentDealer: () => {},
  setCurrentDealerUser: () => {},
  setSelectableOwners: () => {},
  reloadUser: () => {},
  /* 顧客フィルターの選択肢 */
  selectableOwners: { laboratories: [], outsideLaboratories: [] },
  reloadSelectableOwners: () => {},
}

export const PartnerStateContext = React.createContext(initialPartnerState)
