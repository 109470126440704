import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { TableContainer, Table, TableHead, TableRow } from '@mui/material'
import { PartnerFetchProofreadEventsApi } from 'api/partner/proofread_plans/proofread_event'
import { usePartnerFetchProofreadSchedulesApi } from 'api/partner/proofread_plans/proofread_schedule'
import {
  ScheduleEventCheckDialog,
  ScheduleEventEditFormDialog,
} from 'components/partner/proofread/proofread_event/schedule_event_form_dialog'
import { CheckboxFooter } from 'components/shared/checkbox_footer'
import ImageView from 'components/shared/image_view'
import { useMultipleModal } from 'components/shared/modal'
import Pagination from 'components/shared/pagination'
import { ExTableCell, ExTableBody, ExTableRow, CheckboxTableCell, AllCheckboxTableCell } from 'components/shared/table'
import ThemedButton from 'components/shared/themed_button'
import { ProofreadEvent } from 'entities/proofread_event'
import { ProofreadSchedule } from 'entities/proofread_schedule'
import React, { useEffect } from 'react'
import { getTableHeight, PROOFREAD_CHECKED_TABLE_PADDING, PROOFREAD_TABLE_PADDING } from 'utils/component_heights'
import { useCheckboxes } from 'utils/hooks'
import { ProofreadEventViewModel } from 'view_models/proofread_event'
import { ProofreadScheduleViewModel } from 'view_models/proofread_schedule'

type WaitingDateEventTableProps = {
  indexApi: PartnerFetchProofreadEventsApi
  planId: number
  onUpdate: () => void
  onClickExAttr: (event: ProofreadEvent) => void
  onClickBulkExAttr: (eventIds: number[]) => void
}
export const WaitingDateEventTable = (props: WaitingDateEventTableProps) => {
  const checkboxSet = useCheckboxes(props.indexApi.response.proofreadEvents)
  const scheduleApi = usePartnerFetchProofreadSchedulesApi(Number(props.planId))
  const editModal = useMultipleModal<ProofreadSchedule>()
  const checkModal = useMultipleModal<ProofreadSchedule>()

  const handleCompleteEdit = () => {
    editModal.closeModal()
    props.onUpdate()
  }

  useEffect(() => {
    scheduleApi.execute()
  }, [])

  return (
    <>
      <ScheduleEventEditFormDialog modalSet={editModal} onComplete={handleCompleteEdit} />
      <ScheduleEventCheckDialog modalSet={checkModal} />
      <TableContainer
        style={{
          maxHeight:
            checkboxSet.checkedIds.length > 0 ? getTableHeight(PROOFREAD_CHECKED_TABLE_PADDING) : getTableHeight(PROOFREAD_TABLE_PADDING),
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <ExTableCell align="center">引渡予定日</ExTableCell>
              <ExTableCell align="center">返却予定日</ExTableCell>
              <ExTableCell align="center">対象機器数</ExTableCell>
              <ExTableCell align="center">日程承認</ExTableCell>
              <ExTableCell />
            </TableRow>
          </TableHead>
          <ExTableBody loading={props.indexApi.loading}>
            {scheduleApi.response.proofreadSchedules.map((schedule: ProofreadSchedule) => {
              const vm = new ProofreadScheduleViewModel(schedule)
              return (
                <ExTableRow key={schedule.id}>
                  <ExTableCell align="center">{vm.getDeliveredDate()}</ExTableCell>
                  <ExTableCell align="center">{vm.getReturnedDate()}</ExTableCell>
                  <ExTableCell align="center">{vm.getInstrumentCount()}</ExTableCell>
                  <ExTableCell align="center">
                    {schedule.aasmState === 'requested' ? '' : <CheckCircleOutlineIcon style={{ color: 'green' }} />}
                  </ExTableCell>
                  <ExTableCell>
                    {schedule.aasmState === 'requested' ? (
                      <ThemedButton color="primary" onClick={() => editModal.openModal(schedule)}>
                        編集
                      </ThemedButton>
                    ) : (
                      <ThemedButton color="secondary" onClick={() => checkModal.openModal(schedule)}>
                        確認
                      </ThemedButton>
                    )}
                  </ExTableCell>
                </ExTableRow>
              )
            })}
          </ExTableBody>
        </Table>
        <Pagination response={props.indexApi.response} pageSet={props.indexApi.pageSet} />
        <div style={{ padding: 10, backgroundColor: '#FFF2CC', margin: 10 }}>以下はラボによる日程承認待ちの機器です</div>
        <Table>
          <TableHead>
            <TableRow>
              <AllCheckboxTableCell checkboxSet={checkboxSet} />
              <ExTableCell colSpan={2} align="center">
                製品名
              </ExTableCell>
              <ExTableCell align="center">校正月</ExTableCell>
              <ExTableCell align="center">料金</ExTableCell>
              <ExTableCell align="center">引渡予定日</ExTableCell>
              <ExTableCell align="center">返却予定日</ExTableCell>
              <ExTableCell />
            </TableRow>
          </TableHead>
          <ExTableBody loading={props.indexApi.loading}>
            {props.indexApi.response.proofreadEvents.map((event: ProofreadEvent) => {
              const vm = new ProofreadEventViewModel(event)
              return (
                <ExTableRow key={event.id}>
                  <CheckboxTableCell checkboxId={event.id!} checkboxesSet={checkboxSet} />
                  <ExTableCell width={100}>
                    <ImageView maxHeight={100} maxWidth={100} src={vm.getInstrumentImage()} />
                  </ExTableCell>
                  <ExTableCell>{vm.getInstrumentInfo()}</ExTableCell>
                  <ExTableCell align="center">{vm.getScheduledDate()}</ExTableCell>
                  <ExTableCell align="center">{vm.getPrice()}</ExTableCell>
                  <ExTableCell align="center">{vm.getSupplierDeliveredDate()}</ExTableCell>
                  <ExTableCell align="center">{vm.getSupplierReturnedDate()}</ExTableCell>
                  <ExTableCell width={122}>
                    <ThemedButton color="success" onClick={() => props.onClickExAttr(event)}>
                      管理情報
                    </ThemedButton>
                  </ExTableCell>
                </ExTableRow>
              )
            })}
          </ExTableBody>
        </Table>
      </TableContainer>
      <Pagination response={props.indexApi.response} pageSet={props.indexApi.pageSet} />
      <CheckboxFooter
        checkboxSet={checkboxSet}
        content={
          <>
            <ThemedButton color="success" onClick={() => props.onClickBulkExAttr(checkboxSet.checkedIds)} style={{ marginRight: 12 }}>
              校正管理情報を一括登録
            </ThemedButton>
          </>
        }
      />
    </>
  )
}

export default WaitingDateEventTable
