import MuiTab from '@mui/material/Tab'
import MuiTabs from '@mui/material/Tabs'
import React, { useEffect } from 'react'
import { makeStyles } from 'tss-react/mui'

interface TabPanelProps {
  id: string
  children?: React.ReactNode
  index: any
  value: any
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, id, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== id} id={`tabpanel-${id}-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {children}
    </div>
  )
}

const useStyles = makeStyles()({
  root: {
    flexGrow: 1,
  },
  selected: {
    backgroundColor: 'white',
  },
})

export type TabComponent = { id: string; label: string; component: React.ReactNode; visible?: boolean }

type TabsProps = {
  tabComponents: TabComponent[]
  onSelect?: (tabId: string) => void
  initTabId: string
}

export default function Tabs(props: TabsProps) {
  const { tabComponents, onSelect, initTabId } = props
  const { classes } = useStyles()
  const [value, setValue] = React.useState(initTabId)

  const handleChange = (_event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue)
  }
  const _visible = (visible?: boolean) => {
    if (typeof visible === 'undefined') {
      return true
    } else {
      return visible
    }
  }

  useEffect(() => {
    onSelect && onSelect(value)
  }, [value])

  useEffect(() => {
    setValue(initTabId)
  }, [initTabId])

  return (
    <div className={classes.root}>
      <MuiTabs value={value} onChange={handleChange} aria-label="simple tabs example">
        {tabComponents.map((tab) => {
          if (_visible(tab.visible)) {
            return <MuiTab key={tab.id} label={tab.label} value={tab.id} classes={{ selected: classes.selected }} />
          }
        })}
      </MuiTabs>
      {tabComponents.map((tab, i) => {
        if (_visible(tab.visible)) {
          return (
            <TabPanel key={tab.id} value={value} index={i} id={tab.id}>
              {tab.component}
            </TabPanel>
          )
        }
      })}
    </div>
  )
}
