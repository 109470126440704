import { Paper, Box } from '@mui/material'
import { usePartnerPatchDealerUserApi } from 'api/partner/dealer_users'
import { DataLabel } from 'components/shared/data/data_label'
import { Flex } from 'components/shared/flex'
import { StringField } from 'components/shared/form/input'
import ThemedButton from 'components/shared/themed_button'
import { PartnerStateContext } from 'contexts/partner_state_context'
import { DealerUserForm } from 'entities/dealer_user'
import { useForm } from 'rac'
import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useEffectSkipFirst } from 'utils/hooks'

export const PersonalSettingsBox = () => {
  const { classes } = useStyles()
  const { currentDealerUser } = useContext(PartnerStateContext)
  const dealerUserForm = useForm<DealerUserForm>({ note: currentDealerUser.note, passwordConfirmation: '' }, 'dealerUser')
  const patchDealerUser = usePartnerPatchDealerUserApi(dealerUserForm)
  const [requirePasswordConfirmation, setRequirePasswordConfirmation] = useState(false)

  const handleClickUpdateAccountSetting = () => {
    const dealerUserId = currentDealerUser.id
    if (dealerUserId) patchDealerUser.execute()
  }

  useEffect(() => {
    setRequirePasswordConfirmation(Boolean(dealerUserForm.object.password))
  }, [dealerUserForm])

  useEffectSkipFirst(() => {
    if (patchDealerUser.isSuccess()) {
      // 入力をクリアする
      dealerUserForm.update((f) => {
        f.passwordCurrent = ''
        f.passwordConfirmation = ''
        f.password = ''
      })
    }
  }, [patchDealerUser.loading])

  return (
    <Box>
      <Box display={'flex'} alignItems={'center'} marginBottom={1}>
        <h3 style={{ marginRight: '10px' }}>個人設定</h3>
      </Box>
      <Paper className={classes.root}>
        <div className={classes.boxTitle}>
          <label>アカウント情報</label>
        </div>
        <Flex className={classes.stringFieldGroup}>
          <DataLabel label="氏名" value={currentDealerUser.name} />
          <DataLabel label="メールアドレス" value={currentDealerUser.email} />
        </Flex>
        <Flex className={classes.stringFieldGroup}>
          <StringField
            label="用途・メモ"
            form={dealerUserForm}
            attr="note"
            placeholder="例：営業グループ、事務グループ等"
            apiError={patchDealerUser.apiError}
          />
        </Flex>

        <div className={classes.boxTitle}>
          <label>パスワードの変更</label>
        </div>
        <Flex className={classes.stringFieldGroup}>
          <StringField
            label="現在のパスワード"
            form={dealerUserForm}
            attr="passwordCurrent"
            placeholder="パスワードを変更する時のみご入力ください"
            type="password"
            apiError={patchDealerUser.apiError}
          />
        </Flex>
        <Flex className={classes.stringFieldGroup}>
          <StringField
            label="パスワード"
            form={dealerUserForm}
            attr="password"
            placeholder="8文字以上のパスワードをご入力ください"
            type="password"
            apiError={patchDealerUser.apiError}
          />
        </Flex>
        <Flex className={classes.stringFieldGroup}>
          <StringField
            label="パスワード（再確認）"
            form={dealerUserForm}
            attr="passwordConfirmation"
            placeholder="確認のため同じパスワードをご入力ください"
            type="password"
            required={requirePasswordConfirmation}
            apiError={patchDealerUser.apiError}
          />
        </Flex>
        <Flex justifyContent="flex-end" style={{ padding: 10 }}>
          <ThemedButton color="success" onClick={handleClickUpdateAccountSetting}>
            保存
          </ThemedButton>
        </Flex>
      </Paper>
    </Box>
  )
}

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    maxWidth: 600,
  },
  stringFieldGroup: {
    height: 70,
    margin: '0 20px 0 20px',
  },
  boxTitle: {
    paddingTop: 20,
    marginLeft: 20,
    marginBottom: 20,
  },
}))
