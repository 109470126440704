import { usePartnerFetchLaboratoriesApi } from 'api/partner/laboratories'
import { usePartnerFetchOutsideLaboratoriesApi } from 'api/partner/outside_laboratories'
import { usePartnerPostProofreadPlanApi } from 'api/partner/proofread_plan'
import { Flex } from 'components/shared/flex'
import { AutocompleteSelectField, MonthField, StringField } from 'components/shared/form/input'
import Modal, { ModalState } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { ProofreadPlan, ProofreadPlanClietableType, ProofreadPlanForm } from 'entities/proofread_plan'
import { useForm } from 'rac'
import React, { useContext, useEffect, useMemo } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'

type ProofreadPlanFormDialogProps = {
  modalState: ModalState
  onComplete: (res: ProofreadPlan) => void
  clientableType: ProofreadPlanClietableType
}
export const ProofreadPlanFormDialog = (props: ProofreadPlanFormDialogProps) => {
  const laboratoriesIndexApi = usePartnerFetchLaboratoriesApi({ useFilter: true })
  const outsideLaboratoriesIndexApi = usePartnerFetchOutsideLaboratoriesApi({ useFilter: true })
  const postForm = useForm<ProofreadPlanForm>(
    {
      clientableType: props.clientableType,
    },
    'proofreadPlan',
  )
  const postApi = usePartnerPostProofreadPlanApi(postForm)
  const globalState = useContext(GlobalStateContext)

  useEffectSkipFirst(() => {
    props.onComplete(postApi.response.proofreadPlan)
  }, [postApi.response.proofreadPlan])

  useEffect(() => {
    globalState.setLoading(postApi.loading)
  }, [postApi.loading])

  useEffect(() => {
    if (props.modalState.open) {
      if (props.clientableType === 'OutsideLaboratory') {
        outsideLaboratoriesIndexApi.execute()
      } else {
        laboratoriesIndexApi.execute()
      }
    }
  }, [props.clientableType, props.modalState.open])

  useEffect(() => {
    postForm.update((f) => {
      f.clientableType = props.clientableType
    })
  }, [props.clientableType])

  const handleSubmit = () => {
    postApi.execute()
  }

  const labSelectData = useMemo(
    () =>
      laboratoriesIndexApi.response.laboratories.map((lab) => {
        return { value: lab.id!, label: lab.name! }
      }),
    [laboratoriesIndexApi.response.laboratories],
  )

  const outsideLabSelectData = useMemo(
    () =>
      outsideLaboratoriesIndexApi.response.outsideLaboratories.map((lab) => {
        return { value: lab.id!, label: lab.name! }
      }),
    [outsideLaboratoriesIndexApi.response.outsideLaboratories],
  )

  return (
    <>
      <Modal
        modalState={props.modalState}
        modalId="create-plan-modal"
        title="校正計画を開始する"
        size="sm"
        footer={
          <>
            <ThemedButton onClick={() => props.modalState.setOpen(false)} color="secondary">
              閉じる
            </ThemedButton>
            <ThemedButton onClick={handleSubmit} color="success">
              登録
            </ThemedButton>
          </>
        }
      >
        <Flex>
          <AutocompleteSelectField
            form={postForm}
            attr="clientableId"
            labelId="select-outside-laboratory"
            data={props.clientableType === 'Laboratory' ? labSelectData : outsideLabSelectData}
            label={props.clientableType === 'Laboratory' ? 'リプルア顧客' : '外部顧客'}
            apiError={postApi.apiError}
            required
          />
        </Flex>
        <Flex style={{ marginBottom: 12 }}>
          <StringField label="名称" form={postForm} attr="title" apiError={postApi.apiError} required />
        </Flex>
        <Flex>
          <MonthField label="開始月" form={postForm} attr="startDate" apiError={postApi.apiError} required />
          <MonthField label="終了月" form={postForm} attr="endDate" apiError={postApi.apiError} required />
        </Flex>
      </Modal>
    </>
  )
}
