import { AxiosHttpClient } from 'api/axios'
import { useRepruaIndexApi, useRepruaShowApi, useRepruaPostApi } from 'api/shared_api_hooks'
import { ID, PagingResponse } from 'entities'
import { OwnerFilter } from 'entities/dealer'
import { getDealerInquirySearchQuery, Inquiry, InquiryForm, InquirySearchForm } from 'entities/inquiry'
import { User } from 'entities/user'
import { ApiSet, BaseResponse, Form, IndexApiSet } from 'rac'
import { useRecoilState } from 'recoil'
import { ownerFilterAtom } from 'recoil/partner/owner_filter'
import { useEffectSkipFirst } from 'utils/hooks'

type InquiriesResponse = PagingResponse & {
  inquiries: Inquiry[]
  users: User[]
}

export type InquiriesIndexApiSet = IndexApiSet<InquiriesResponse> & {
  execute: () => void
  executeAndPageReset: () => void
}
/**
 * 問合せ一覧
 */
export function usePartnerFetchInquiriesApi(props: { searchForm: Form<InquirySearchForm> }): InquiriesIndexApiSet {
  // 顧客フィルターを適用する
  const [filter] = useRecoilState(ownerFilterAtom)
  const path = 'inquiries'
  const api = useRepruaIndexApi<InquiriesResponse, InquirySearchForm & OwnerFilter>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { inquiries: [], users: [], totalCount: 0 },
  })
  const { searchText, ...rest } = props.searchForm.object

  const execute = () => {
    api.execute(path, { params: { ...rest, ...filter }, searchQuery: getDealerInquirySearchQuery(searchText) })
  }

  const executeAndPageReset = () => {
    if (api.pageSet.page > 1) {
      api.pageSet.setPage(1)
    } else {
      execute()
    }
  }

  useEffectSkipFirst(() => {
    // ページの更新をハンドリングしてapiを実行
    api.execute(path, { params: props.searchForm.object })
  }, [api.pageSet.page, api.pageSet.perPage, api.orderSet.order, api.orderSet.orderBy, filter])

  return {
    ...api,
    execute,
    executeAndPageReset,
  }
}

export type InquiryResponse = BaseResponse & {
  inquiry: Inquiry
}

const initialResponse = {
  inquiry: {},
}

/**
 * １件の問合せを取得
 */
export function usePartnerFetchInquiryApi(): ApiSet<InquiryResponse> & { execute: (id: ID) => void } {
  const api = useRepruaShowApi<InquiryResponse, {}>(new AxiosHttpClient('dealer_user'), { initialResponse: initialResponse })

  const execute = (id: ID) => {
    const apiPath = `inquiries/${id}`
    api.execute(apiPath)
  }

  return {
    ...api,
    execute,
  }
}

/**
 * 新規問合せを登録
 */
export function usePartnerPostInquiryApi(): ApiSet<InquiryResponse> & { execute: (form: Form<InquiryForm>) => void } {
  const api = useRepruaPostApi<InquiryResponse, InquiryForm>(new AxiosHttpClient('dealer_user'), { initialResponse: initialResponse })

  const execute = (form: Form<InquiryForm>) => {
    const apiPath = 'inquiries'
    api.execute(apiPath, form)
  }

  return {
    ...api,
    execute,
  }
}
