import { Table, TableHead, TableRow, TableContainer, Tooltip } from '@mui/material'
import { usePartnerBulkUpdateProofreadEventApi } from 'api/partner/proofread_event'
import { PartnerFetchProofreadEventsApi } from 'api/partner/proofread_plans/proofread_event'
import { NewDefectReportFormDialog } from 'components/partner/defect_report/defect_report_form_dialog'
import AttachedResultEventFormDialog from 'components/partner/proofread/proofread_event/attached_result_event_form_dialog'
import { CheckboxFooter } from 'components/shared/checkbox_footer'
import ImageView from 'components/shared/image_view'
import { useModal, useMultipleModal } from 'components/shared/modal'
import Pagination from 'components/shared/pagination'
import BulkResultFormDialog from 'components/shared/proofread/bulk_result_form_dialog'
import { InstrumentCategoryFilterTableHeader } from 'components/shared/proofread/instrument_category_filter_table_header'
import { InstrumentInfoFilterTableHeader } from 'components/shared/proofread/instrument_info_filter_table_header'
import { ExTableCell, ExTableBody, ExTableRow, AllCheckboxTableCell, CheckboxTableCell, OrderTableCell } from 'components/shared/table'
import { TableExAttributesInfo } from 'components/shared/table_ex_attributes_info'
import ThemedButton from 'components/shared/themed_button'
import { ExColumn } from 'entities/ex_column'
import { ProofreadEvent, ProofreadEventForm, ProofreadEventSearchForm } from 'entities/proofread_event'
import moment from 'moment'
import { Form, useForm } from 'rac'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { PartnerRouteHelper } from 'routes/partner'
import { getTableHeight, PROOFREAD_CHECKED_TABLE_PADDING, PROOFREAD_TABLE_PADDING } from 'utils/component_heights'
import { useCheckboxes } from 'utils/hooks'
import { useProofreadEventFilter } from 'utils/hooks/use_proofread_event_filter'
import { ProofreadEventViewModel } from 'view_models/proofread_event'

type AttachedResultEventTableProps = {
  searchForm: Form<ProofreadEventSearchForm>
  planId: number
  indexApi: PartnerFetchProofreadEventsApi
  tableShowColumns: ExColumn[]
  onUpdate: () => void
  onClickExAttr: (event: ProofreadEvent) => void
  onClickBulkExAttr: (eventIds: number[]) => void
}
export const AttachedResultEventTable: React.FC<AttachedResultEventTableProps> = (props) => {
  const proofreadEventFilterSet = useProofreadEventFilter(props.indexApi.response.filter, props.searchForm, props.indexApi)
  const history = useHistory()
  const resultModalSet = useMultipleModal<ProofreadEvent>()
  const bulkResultModalSet = useMultipleModal<number[]>()
  const [prEvent, setPrEvent] = useState<ProofreadEvent>({})
  const newReportModal = useModal()
  const checkboxSet = useCheckboxes(props.indexApi.response.proofreadEvents)

  const patchForm = useForm<ProofreadEventForm>({
    eventIds: bulkResultModalSet.item,
    aasmState: 'approved_result',
    result: 'ok',
    proofreadPlanId: props.planId,
    scheduledDate: moment().toString(),
    proofreadInfoAttributes: {},
  })
  const patchApi = usePartnerBulkUpdateProofreadEventApi(patchForm)

  const handleClickResult = (event: ProofreadEvent) => {
    resultModalSet.openModal(event)
  }
  const handleCompleteResult = () => {
    resultModalSet.closeModal()
    props.onUpdate()
  }

  const handleClickBulkResult = (eventIds: number[]) => {
    bulkResultModalSet.openModal(eventIds)
  }

  const handleClickReport = (prEvent: ProofreadEvent) => {
    setPrEvent(prEvent)
    if (prEvent.defectReport) {
      history.push(`${PartnerRouteHelper.defectReportShow(prEvent.defectReport.id!)}`)
    } else {
      newReportModal.setOpen(true)
    }
  }
  const handleCompleteReport = () => {
    newReportModal.setOpen(false)
    props.onUpdate()
  }

  return (
    <>
      <AttachedResultEventFormDialog modalSet={resultModalSet} onComplete={handleCompleteResult} />
      <BulkResultFormDialog modalSet={bulkResultModalSet} onComplete={handleCompleteResult} patchForm={patchForm} patchApi={patchApi} />
      <NewDefectReportFormDialog
        modalState={newReportModal}
        instrumentId={prEvent.instrumentId}
        proofreadEventId={prEvent.id}
        onComplete={handleCompleteReport}
      />
      <TableContainer
        style={{
          maxHeight:
            checkboxSet.checkedIds.length > 0 ? getTableHeight(PROOFREAD_CHECKED_TABLE_PADDING) : getTableHeight(PROOFREAD_TABLE_PADDING),
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <AllCheckboxTableCell checkboxSet={checkboxSet} />
              <ExTableCell />
              <ExTableCell>
                <InstrumentInfoFilterTableHeader proofreadEventFilterSet={proofreadEventFilterSet} />
                {props.tableShowColumns.map((col) => (
                  <div style={{ fontSize: 12, lineHeight: '12px' }} key={`tablecol-${col.id}`}>
                    {col.name}
                  </div>
                ))}
              </ExTableCell>
              <ExTableCell align="center">
                <InstrumentCategoryFilterTableHeader proofreadEventFilterSet={proofreadEventFilterSet} />
              </ExTableCell>
              <ExTableCell align="center">校正月</ExTableCell>
              <ExTableCell align="center">校正価格</ExTableCell>
              <OrderTableCell orderAttr="repairState" orderSet={props.indexApi.orderSet} align="center" width={120}>
                修理状況
              </OrderTableCell>
              <ExTableCell width={120} />
              <ExTableCell width={120} />
              <ExTableCell width={120} />
            </TableRow>
          </TableHead>
          <ExTableBody loading={props.indexApi.loading}>
            {props.indexApi.response.proofreadEvents.map((event: ProofreadEvent) => {
              const vm = new ProofreadEventViewModel(event)
              return (
                <ExTableRow key={event.id}>
                  <CheckboxTableCell checkboxId={event.id!} checkboxesSet={checkboxSet} />
                  <ExTableCell width={100}>
                    <ImageView maxHeight={100} maxWidth={100} src={vm.getInstrumentImage()} />
                  </ExTableCell>
                  <ExTableCell>
                    <div>{vm.getInstrumentInfo()}</div>
                    <TableExAttributesInfo tableExAttrs={event.tableExAttrs || []} id={event.id} />
                  </ExTableCell>
                  <ExTableCell align="center">{vm.getInstrumentCategoryName()}</ExTableCell>
                  <ExTableCell align="center">{vm.getScheduledDate()}</ExTableCell>
                  <ExTableCell align="center">{vm.getPrice()}</ExTableCell>
                  <ExTableCell align="center">{vm.getRepairLabel()}</ExTableCell>
                  <ExTableCell>
                    <ThemedButton color="success" onClick={() => props.onClickExAttr(event)}>
                      管理情報
                    </ThemedButton>
                  </ExTableCell>
                  <ExTableCell>
                    <Tooltip title={!vm.canMakeResult() ? '修理が完了していないため校正結果を入力できません' : ''}>
                      <div>
                        <ThemedButton color="primary" disabled={!vm.canMakeResult()} onClick={() => handleClickResult(event)}>
                          結果入力
                        </ThemedButton>
                      </div>
                    </Tooltip>
                  </ExTableCell>
                  <ExTableCell>
                    <ThemedButton
                      variant={event.defectReport ? 'outlined' : 'contained'}
                      color="error"
                      onClick={() => handleClickReport(event)}
                    >
                      {event.defectReport ? '不具合詳細' : '不具合報告'}
                    </ThemedButton>
                  </ExTableCell>
                </ExTableRow>
              )
            })}
          </ExTableBody>
        </Table>
      </TableContainer>
      <Pagination response={props.indexApi.response} pageSet={props.indexApi.pageSet} />
      <CheckboxFooter
        checkboxSet={checkboxSet}
        content={
          <>
            <ThemedButton color="success" onClick={() => props.onClickBulkExAttr(checkboxSet.checkedIds)} style={{ marginRight: 12 }}>
              管理情報を一括更新
            </ThemedButton>
            <ThemedButton color="primary" onClick={() => handleClickBulkResult(checkboxSet.checkedIds)} style={{ marginRight: 12 }}>
              結果を一括入力
            </ThemedButton>
          </>
        }
      />
    </>
  )
}

export default AttachedResultEventTable
