import { RhfAutocompleteSelectField, SelectItem } from 'components/shared/form/rhf_input'
import { PartnerStateContext } from 'contexts/partner_state_context'
import { OwnerFilter, SEPARATOR } from 'entities/dealer'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { ownerFilterAtom } from 'recoil/partner/owner_filter'
import { PartnerRouteHelper } from 'routes/partner'
import { useEffectSkipFirst } from 'utils/hooks'

const ITEM_NAME = 'owner-filter'
const INITIAL_FILTER: OwnerFilter = {
  ownerableId: '',
  ownerableType: '',
  ownerableString: SEPARATOR,
}

/**
 *  LocalStorage に値がある場合は LocalStorage の値を読み込み、
 * ない場合は初期値を読み込む
 */
const getDefaultFilter = (): OwnerFilter => {
  const filterJson = localStorage.getItem(ITEM_NAME)
  if (filterJson) {
    const filter: OwnerFilter = JSON.parse(filterJson)
    return filter
  } else {
    return INITIAL_FILTER
  }
}

export const OwnerFilterField = () => {
  const { selectableOwners, currentDealer } = useContext(PartnerStateContext)
  const [, setFilter] = useRecoilState(ownerFilterAtom)
  const [filterDisabled, setFilterDisabled] = useState<boolean>(false)
  const filterForm = useForm<OwnerFilter>({ defaultValues: getDefaultFilter() })
  const { ownerableId, ownerableType, ownerableString } = filterForm.watch()
  const history = useHistory()
  const FILTER_WHITELIST: string[] = [
    PartnerRouteHelper.instrumentIndex(),
    PartnerRouteHelper.unmanagedInstrumentIndex(),
    PartnerRouteHelper.barcodeIndex(),
    PartnerRouteHelper.unprintedBarcodeIndex(),
    PartnerRouteHelper.defectReportIndex(),
    PartnerRouteHelper.repairEventIndex(),
    PartnerRouteHelper.repairEstimateIndex(),
    PartnerRouteHelper.exAttributeIndex(),
    PartnerRouteHelper.proofreadPlanIndex(),
    PartnerRouteHelper.proofreadableInstrumentIndex(),
    PartnerRouteHelper.instrumentRelationEventIndex(),
    // PartnerRouteHelper.inquiryIndex(),
  ]

  /**
   * 選択肢変更時のコールバック
   */
  useEffect(() => {
    // selectableOwners が空の場合はローディングが終わっていないのでスキップ
    if (selectableOwners.laboratories.length === 0 && selectableOwners.outsideLaboratories.length === 0) return
    if (
      (ownerableType === 'OutsideLaboratory' && selectableOwners.outsideLaboratories.some((l) => l.id === ownerableId)) ||
      (ownerableType === 'Laboratory' && selectableOwners.laboratories.some((l) => l.id === ownerableId))
    ) {
      filterForm.setValue('ownerableString', `${getDefaultFilter().ownerableId}${SEPARATOR}${getDefaultFilter().ownerableType}`)
    } else {
      // 選択肢に localStorage の値が無ければ Form と localStorage を初期化
      filterForm.setValue('ownerableString', INITIAL_FILTER.ownerableString)
    }
  }, [selectableOwners.laboratories, selectableOwners.outsideLaboratories])

  /**
   * フォーム変更時のコールバック
   */
  useEffectSkipFirst(() => {
    if (ownerableString === INITIAL_FILTER.ownerableString) {
      setFilter({ ownerableId: INITIAL_FILTER.ownerableId, ownerableType: INITIAL_FILTER.ownerableType })
    } else if (ownerableString) {
      // 文字列の顧客情報をIDとTypeに分割
      const [idStr, type] = ownerableString.split(SEPARATOR)
      const id = parseInt(idStr)

      // 選択肢にIDが含まれていれば、Atomを更新
      if (
        (type === 'OutsideLaboratory' && selectableOwners.outsideLaboratories.some((l) => l.id === id)) ||
        (type === 'Laboratory' && selectableOwners.laboratories.some((l) => l.id === id))
      ) {
        setFilter({ ownerableId: id, ownerableType: type })
      }
    }
  }, [ownerableString])

  /**
   * 顧客フィルターの Disable制御をパス名をもとに行う
   */
  useEffect(() => {
    const pathname = history.location.pathname
    if (FILTER_WHITELIST.includes(pathname)) {
      setFilterDisabled(false)
    } else {
      setFilterDisabled(true)
    }
  }, [history.location])

  const getItems = () => {
    let items: SelectItem[] = []
    if (currentDealer.authority?.enableInstrumentSharing) {
      items = selectableOwners.laboratories.map((owner) => ({
        value: `${owner.id}${SEPARATOR}Laboratory`,
        label: owner.name!,
      }))
    }

    items = items.concat(
      selectableOwners.outsideLaboratories.map((owner) => ({
        value: `${owner.id}${SEPARATOR}OutsideLaboratory`,
        label: owner.name!,
      })),
    )

    return items
  }

  return (
    <RhfAutocompleteSelectField
      name="ownerableString"
      control={filterForm.control}
      items={getItems()}
      innerLabel="顧客フィルター"
      blankItem={{ label: 'すべての顧客', value: INITIAL_FILTER.ownerableString! }}
      disableClearable
      disabled={filterDisabled}
      tooltip={filterDisabled ? 'このページでは顧客フィルターは無効です' : ''}
    />
  )
}
