import { AxiosHttpClient } from 'api/axios'
import { useRepruaPostApi } from 'api/shared_api_hooks'
import { ApiSet, Form, BaseResponse } from 'rac'

type AuthorizationResponse = BaseResponse & {
  accessToken: string
  onetimeToken: string
}

export type AuthorizationForm = {
  email: string
  password: string
}

/**
 * 認証Api
 */
export function usePartnerAuthorizationApi(): ApiSet<AuthorizationResponse> & { execute: (form: Form<AuthorizationForm>) => void } {
  const api = useRepruaPostApi<AuthorizationResponse, { email: string; password: string }>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { accessToken: '', onetimeToken: '' },
  })

  const execute = (form: Form<AuthorizationForm>) => {
    const path = 'authorizations'
    api.execute(path, form)
  }

  return { ...api, execute }
}

type OnetimePasswordResponse = BaseResponse & {
  accessToken: string
}
export type OnetimePasswordForm = {
  email: string
  onetimeToken: string
  onetimePassword: string
}
/**
 * ワンタイムパスワード
 */
export function useOnetimePasswordApi(): ApiSet<OnetimePasswordResponse> & { execute: (form: Form<OnetimePasswordForm>) => void } {
  const api = useRepruaPostApi<OnetimePasswordResponse, OnetimePasswordForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { accessToken: '' },
  })

  const execute = (form: Form<OnetimePasswordForm>) => {
    const path = 'onetime_password'
    api.execute(path, form)
  }

  return { ...api, execute }
}
