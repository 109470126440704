import StatusLabel from 'components/shared/status_label'
import { ProofreadEvent, proofreadEventStateTexts } from 'entities/proofread_event'
import moment from 'moment'
import React from 'react'
import { ViewModel } from 'view_models'
import { InstrumentViewModel } from 'view_models/instrument'
import { ProofreadInfoViewModel } from 'view_models/proofread_info'
import { ProofreadPlanViewModel } from 'view_models/proofread_plan'

export class ProofreadEventViewModel extends ViewModel<ProofreadEvent> {
  private instrumentVm = new InstrumentViewModel(this.object.instrument || {})
  private infoVm = new ProofreadInfoViewModel(this.object.instrument?.proofreadInfo || {})

  public getInstrumentName = () => {
    return this.instrumentVm.getInstrumentName()
  }

  public getInstrumentInfo = (linkDisabled?: boolean) => {
    return this.instrumentVm.getInstrumentBaseInfoIncludeMaker(linkDisabled)
  }

  public getRoom = () => {
    return this.instrumentVm.roomName()
  }

  public getLastResultDate = () => {
    return this.infoVm.getLastResultDate()
  }

  public getCycle = () => {
    return this.infoVm.getCycle()
  }

  public getExpiredDate = () => {
    return this.infoVm.getExpiredDate()
  }

  public getInstrumentImage = () => {
    return this.instrumentVm.getImageUrl()
  }

  public getInstrumentCategoryName = () => {
    return this.instrumentVm.instrumentCategoryName()
  }

  public getSupplier = () => {
    return this.object.supplier?.name || '-'
  }

  public getUser = () => {
    return this.object.userName || '-'
  }

  public getScheduledDate = () => {
    if (!this.object.scheduledDate) return '-'
    return moment(this.object.scheduledDate).format('YYYY/MM')
  }

  public getPrice = () => {
    return this.object.price ? `¥${this.object.price?.toLocaleString()}` : '-'
  }

  public getManager = () =>{
    return this.object.instrument?.managerName || '-'
  }

  public getLastEventScheduledDate = () => {
    if (!this.object.lastEvent?.scheduledDate) return '-'
    return moment(this.object.lastEvent?.scheduledDate).format('YYYY/MM')
  }

  public getLastEventPrice = () => {
    return this.object.lastEvent?.price ? `¥${this.object.lastEvent?.price?.toLocaleString()}` : '-'
  }

  public getLastEventSupplier = () => {
    return this.object.lastEvent?.supplier?.name || '-'
  }

  public getLastEventUser = () => {
    return this.object.lastEvent?.userName || '-'
  }

  public getSupplierDeliveredDate = () => {
    if (!this.object.proofreadSchedule?.deliveredDate) return '調整中'
    return moment(this.object.proofreadSchedule?.deliveredDate).format('YYYY/MM/DD')
  }

  public getSupplierReturnedDate = () => {
    if (!this.object.proofreadSchedule?.returnedDate) return '調整中'
    return moment(this.object.proofreadSchedule?.returnedDate).format('YYYY/MM/DD')
  }

  public getResultLabel = () => {
    if (this.object.result === 'ok') {
      return <StatusLabel color="#EB978E" text="合格" />
    } else if (this.object.result === 'ng') {
      return <StatusLabel color="#5DA2CA" text="不合格" />
    } else if (this.object.result === 'canceled') {
      return <StatusLabel color="#aaa" text="取り下げ" />
    } else {
      return <StatusLabel color="#0a0a0a" text="未完了" />
    }
  }

  public getStatusLabel = () => {
    if (this.object.aasmState === 'approved_result') {
      return this.getResultLabel()
    } else {
      return <StatusLabel color="#0a0a0a" text={this.getStatusText()} />
    }
  }

  private getStatusText = () => {
    if (this.object.aasmState === 'approved_result') {
      if (this.object.result === 'ok') {
        return '合格'
      } else if (this.object.result === 'ng') {
        return '不合格'
      } else {
        return '取り下げ'
      }
    } else {
      return proofreadEventStateTexts[this.object.aasmState || 'no_plan']
    }
  }

  public getReservationText = () => {
    if (this.object.reservation) {
      return `${moment(this.object.reservation.startAt).format('YYYY/MM/DD')} 〜 ${moment(this.object.reservation.endAt).format(
        'YYYY/MM/DD',
      )}`
    }
  }

  public getLaboratoryName = () => {
    const plan = this.object.proofreadPlan
    if (plan) {
      const planVM = new ProofreadPlanViewModel(plan)
      return planVM.getLaboratoryName()
    } else {
      return '-'
    }
  }

  public getRepairLabel = () => {
    switch (this.object.repairState) {
      case 'unnecessary':
        return <></>
      case 'reported':
        return <StatusLabel text="不具合あり" color="#F44336" />
      case 'estimated':
        return <StatusLabel text="見積済" color="#3788D8" />
      case 'unrepaired':
        return <StatusLabel text="未修理" color="#F44336" />
      case 'repaired':
        return <StatusLabel text="修理済" color="#4CAF50" />
      case 'unrepairable':
        return <StatusLabel text="修理不可" color="#808080" />
      default:
        break
    }
  }

  public canMakeResult = () => {
    if (this.object.repairState === 'unnecessary' || this.object.repairState === 'repaired' || this.object.repairState === 'unrepairable') {
      return true
    } else {
      return false
    }
  }

  public getPlanName = () => {
    return this.object.proofreadPlan?.title || '-'
  }
}
