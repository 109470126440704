import { BaseEntity, ID } from 'entities'
import { AttachmentAttributes } from 'entities/attachment_attributes'
import { DefectReport } from 'entities/defect_report'
import { ExAttribute } from 'entities/ex_attribute'
import { Instrument } from 'entities/instrument'
import { Laboratory } from 'entities/laboratory'
import { ProofreadEvent } from 'entities/proofread_event'
import { RepairEstimate } from 'entities/repair_estimate'
import { RepairEventAttachment } from 'entities/repair_event_attachment'
import { SearchQuery } from 'rac'

type OwnerableType = 'Laboratory' | 'Dealer'

/**
 * 校正情報
 */
export type RepairEvent = BaseEntity & {
  instrumentId?: ID
  defectReportId?: ID
  ownerableId?: number
  ownerableType?: OwnerableType
  recorderableId?: ID
  recorderableType?: 'User' | 'DealerUser'
  suppliableId?: ID
  suppliableType?: string
  proofreadEventId?: ID
  repairInfoId?: ID
  aasmState?: string
  startAt?: string
  endAt?: string
  price?: number
  noteToSupplier?: string
  detail?: string
  repairUserName?: string
  supplierName?: string
  recorderName?: string
  tableExAttrs?: ExAttribute[]

  proofreadEvent?: ProofreadEvent
  fileAttachments?: RepairEventAttachment[]
  instrument?: Instrument
  defectReport?: DefectReport
  repairEstimate?: RepairEstimate
  laboratory?: Laboratory
}

export type RepairEventForm = RepairEvent & {
  attachmentsAttributes?: AttachmentAttributes[]
  skipFixResult?: boolean
}

export type RepairEventSearchForm = {
  searchText?: string
}

export const getRepairEventSearchQuery = (searchText?: string): SearchQuery => ({
  suffix: 'Cont',
  searchText: searchText || '',
  attrs: [
    'noteToSupplier',
    'detail',
    ['repairInfoDefectReport', { attrs: ['point', 'content'] }],
    ['suppliable', { attrs: ['name'], polymorphicType: 'OutsideLaboratory' }],

    // FIXME: multiDBの不具合暫定対応
    // ['ownerable', { attrs: ['name'], polymorphicType: 'Laboratory' }],
    // ['ownerable', { attrs: ['name'], polymorphicType: 'Dealer' }],
    // ['recorderable', { attrs: ['familyName', 'givenName'], polymorphicType: 'User' }],
    // ['recorderable', { attrs: ['name'], polymorphicType: 'DealerUser' }],
  ],
})
