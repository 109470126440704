import Logo from 'images/logo_wh.png'
import React from 'react'
import { Menu, ProSidebar, SidebarContent, SidebarHeader } from 'react-pro-sidebar'

type Props = {
  children: React.ReactNode
}

export const Sidebar = ({ children }: Props) => {
  return (
    <ProSidebar className="drawer" breakPoint="xs">
      <SidebarHeader>
        <div className="toolbar">
          <img src={Logo} alt="reprua-logo" style={{ width: '100%', objectFit: 'contain', paddingRight: 40, paddingLeft: 40 }} />
        </div>
      </SidebarHeader>
      <SidebarContent>
        <Menu>{children}</Menu>
      </SidebarContent>
    </ProSidebar>
  )
}
