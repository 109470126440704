import { Box, Paper, Tooltip, Typography } from '@mui/material'
import { useDeleteApprovalSettingApi, useFetchApprovalSettingsApi } from 'api/mypage/approval_setting'
import { Flex } from 'components/shared/flex'
import { useModal } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { MypageStateContext } from 'contexts/mypage_state_context'
import { ApprovalSetting } from 'entities/approval_setting'
import { User } from 'entities/user'
import React, { useContext, useEffect, useState } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'

import ApprovalChart from './approval_chart'
import ApprovalSettingFormDialog, { APPROVALS, approvalValue } from './approval_setting_form_dialog'

type Approvals = {
  approval: 'first' | 'second' | 'third' | 'fourth' | 'fifth'
  approvers: string[]
  maximumAmount: number | undefined
  nolimit: boolean
}[]

type ApprovalSettingListBoxProps = {
  users?: User[]
}
const ApprovalSettingListBox: React.FC<ApprovalSettingListBoxProps> = (props) => {
  const globalState = useContext(GlobalStateContext)
  const { currentUser } = useContext(MypageStateContext)
  const indexApi = useFetchApprovalSettingsApi()
  const [setting, setSetting] = useState<ApprovalSetting>({})
  const [approvals, setApprovals] = useState<Approvals>([])
  const [maxStep, setMaxStep] = useState<number>(0)
  const settingModal = useModal()
  const deleteApi = useDeleteApprovalSettingApi()

  useEffect(() => {
    indexApi.execute()
  }, [])

  const hanldleClickDelete = () => {
    globalState.confirm('承認設定を削除します。よろしいですか？', (e) => {
      if (e === 'ok') {
        deleteApi.execute(setting.id!)
      }
    })
  }

  const handleComplete = () => {
    settingModal.setOpen(false)
    indexApi.execute()
  }

  useEffectSkipFirst(() => {
    if (!deleteApi.isSuccess()) return
    indexApi.execute()
  }, [deleteApi.loading])

  useEffectSkipFirst(() => {
    if (!indexApi.isSuccess()) return
    // 承認設定はラボごとに一つのものとして扱う
    setSetting(indexApi.response.approvalSettings[0] || {})
  }, [indexApi.loading])

  useEffectSkipFirst(() => {
    if (!setting || !setting.approvers || !setting.conditions) {
      setApprovals([])
    } else {
      const list: Approvals = APPROVALS.map((ap) => {
        const condition = setting.conditions!.find((c) => c.approval === ap)
        const approvers = setting.approvers!.filter((a) => a.approval === ap)
        return {
          approval: ap,
          approvers: approvers.map((a) => a.userName || ''),
          maximumAmount: condition?.maximumAmount,
          nolimit: condition?.nolimit || false,
        }
      }).filter((i) => i.approvers.length > 0)
      setApprovals(list)
      setMaxStep(list.length)
    }
  }, [setting])

  return (
    <Box marginBottom={1}>
      <ApprovalSettingFormDialog modalState={settingModal} approvalSetting={setting} users={props.users} onComplete={handleComplete} />
      <Box display={'flex'} alignItems={'center'}>
        <h3 style={{ marginRight: '10px' }}>多段階承認設定</h3>
        {currentUser.authority?.privileged && (
          <ThemedButton color="primary" onClick={() => settingModal.setOpen(true)}>
            設定変更
          </ThemedButton>
        )}
      </Box>
      {indexApi.isSuccess() && (
        <Paper style={{ paddingBottom: 12 }}>
          {!setting || approvals.length === 0 ? (
            <div style={{ padding: 12 }}>
              <Typography variant="h6">承認設定が存在しません</Typography>
            </div>
          ) : (
            <>
              <Flex justifyContent="space-between" style={{ padding: 12 }}>
                <Typography variant="h6">{setting.name || ''}</Typography>
                <Tooltip title={setting.proceeding ? '承認待ちの申請があるため削除できません' : ''}>
                  <div>
                    {currentUser.authority?.privileged && (
                      <ThemedButton color="error" onClick={hanldleClickDelete} disabled={setting.proceeding}>
                        削除
                      </ThemedButton>
                    )}
                  </div>
                </Tooltip>
              </Flex>
              <ul style={{ display: 'flex', width: '100%', listStyle: 'none' }}>
                {approvals.map((approval) => {
                  // 最大の承認段階以下のもののみ表示する
                  const step = approvalValue(approval.approval)
                  const visible = step <= maxStep
                  const width = `${100 / maxStep}%`
                  const text = (
                    <div>
                      {approval.nolimit ? (
                        <label>上限額なし</label>
                      ) : (
                        <>
                          {approval.maximumAmount ? (
                            <>
                              <label>¥ {approval.maximumAmount?.toLocaleString()}</label>
                              まで発注可能
                            </>
                          ) : (
                            <br />
                          )}
                        </>
                      )}
                      <ul style={{ listStyle: 'none', paddingTop: 6 }}>
                        {approval.approvers.map((a, i) => (
                          <li key={i}>
                            <label>{a}</label>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )
                  return <ApprovalChart key={`check-${approval}`} visible={visible} step={step} width={width} text={text} />
                })}
              </ul>
              <div style={{ padding: '0 12px' }}>
                <dl>
                  <dt>承認不要で発注可能な額(税込)</dt>
                  <dd style={{ fontWeight: 'bold', paddingLeft: 12, marginBottom: 6 }}>
                    ¥{setting.conditions?.find((c) => c.approval === 'nonapproval')?.maximumAmount?.toLocaleString()}
                  </dd>
                  <dt>承認者による発注</dt>
                  <dd style={{ fontWeight: 'bold', paddingLeft: 12 }}>
                    {setting.selfApproval ? '承認をパスする' : '他の承認者の承認が必要'}
                  </dd>
                </dl>
              </div>
            </>
          )}
        </Paper>
      )}
    </Box>
  )
}

export default ApprovalSettingListBox
