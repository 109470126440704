import { usePostEstimateApprovalResultApi } from 'api/mypage/approval_result'
import { Flex } from 'components/shared/flex'
import { StringField } from 'components/shared/form/input'
import Modal, { ModalState } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { ApprovalResultForm } from 'entities/approval_result'
import { useForm } from 'rac'
import React from 'react'
import { useEffectSkipFirst } from 'utils/hooks'

type CreateApproveFormDialogProps = {
  modalState: ModalState
  approved: boolean
  repairInfoId: number
  onComplete: () => void
}
export const CreateApproveFormDialog: React.FC<CreateApproveFormDialogProps> = (props) => {
  const postForm = useForm<ApprovalResultForm>({ repairInfoId: props.repairInfoId, approved: props.approved })
  const postApi = usePostEstimateApprovalResultApi()

  useEffectSkipFirst(() => {
    postForm.resetForm()
  }, [props.approved])

  const handleSubmit = () => {
    postApi.execute(postForm)
  }

  useEffectSkipFirst(() => {
    if (!postApi.isSuccess()) return
    props.onComplete()
  }, [postApi.loading])

  return (
    <Modal
      title="承認内容についてのコメント"
      modalId="approve-form-modal"
      modalState={props.modalState}
      footer={
        <Flex>
          <ThemedButton color="secondary" onClick={() => props.modalState.setOpen(false)}>
            キャンセル
          </ThemedButton>
          <ThemedButton color={props.approved ? 'success' : 'error'} onClick={handleSubmit}>
            申請を{props.approved ? '承認' : '却下'}
          </ThemedButton>
        </Flex>
      }
    >
      <StringField label="コメント" form={postForm} attr="note" multiline />
    </Modal>
  )
}

export default CreateApproveFormDialog
