import { AxiosHttpClient } from 'api/axios'
import { useRepruaDownloadApi, useRepruaIndexApi } from 'api/shared_api_hooks'
import { PagingResponse } from 'entities'
import { initialInstrumentFilter, InstrumentFilter } from 'entities/instrument'
import { getProofreadEventSearchQuery, ProofreadEvent, ProofreadEventSearchForm } from 'entities/proofread_event'
import { BaseResponse, Form, IndexApiSet } from 'rac'
import { useEffectSkipFirst } from 'utils/hooks'

export type ProofreadEventsResponse = PagingResponse & {
  proofreadEvents: ProofreadEvent[]
  filter: InstrumentFilter
}
export type ProofreadEventResponse = BaseResponse & {
  proofreadEvent: ProofreadEvent
}

/**
 * 一覧
 */
export type PartnerFetchProofreadEventsApi = IndexApiSet<ProofreadEventsResponse> & {
  execute: (form?: ProofreadEventSearchForm) => void
  executeAndPageReset: () => void
}
export const usePartnerFetchProofreadEventsApi = (planId: number, form: Form<ProofreadEventSearchForm>): PartnerFetchProofreadEventsApi => {
  const api = useRepruaIndexApi<ProofreadEventsResponse, ProofreadEventSearchForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { proofreadEvents: [], filter: initialInstrumentFilter, totalCount: 0 },
  })

  const { searchText, ...rest } = form.object

  const execute = (formObject?: ProofreadEventSearchForm) => {
    const path = `proofread_plans/${planId}/proofread_events`
    api.execute(path, { params: { ...rest, ...formObject }, searchQuery: getProofreadEventSearchQuery(searchText) })
  }

  const executeAndPageReset = () => {
    if (api.pageSet.page > 1) {
      api.pageSet.setPage(1)
    } else {
      execute()
    }
  }

  useEffectSkipFirst(() => {
    execute()
  }, [api.pageSet.page, api.pageSet.perPage, api.orderSet.order, api.orderSet.orderBy])

  return { ...api, execute, executeAndPageReset }
}

/**
 * 出力
 */
export const usePartnerExportProofreadEventsApi = (planId: number): { execute: (format: 'xlsx') => void } => {
  const api = useRepruaDownloadApi<any>(new AxiosHttpClient('dealer_user'))

  const execute = (format: 'xlsx') => {
    const path = `proofread_plans/${planId}/proofread_events.${format}`
    api.execute(path)
  }

  return { execute }
}
