import { Card, Table, TableContainer, TableHead, TableRow } from '@mui/material'
import { DefectReportsIndexApiSet } from 'api/mypage/instruments/defect_report'
import { ProofreadEventsIndexApiSet } from 'api/mypage/instruments/proofread_events'
import { RepairEstimatesIndexApiSet } from 'api/mypage/instruments/repair_estimate'
import { RepairEventsIndexApiSet } from 'api/mypage/instruments/repair_event'
import { InstrumentEventTableSet } from 'components/mypage/instrument/use_instrument_event_table'
import StatusLabel from 'components/shared/status_label'
import { ExTableCell, ExTableBody } from 'components/shared/table'
import Tabs from 'components/shared/tabs'
import ViewMoreButton from 'components/shared/view_more_button'
import { MypageStateContext } from 'contexts/mypage_state_context'
import { ID } from 'entities'
import { DefectReport } from 'entities/defect_report'
import { ProofreadEvent } from 'entities/proofread_event'
import { RepairEstimate } from 'entities/repair_estimate'
import { RepairEvent } from 'entities/repair_event'
import React, { useContext } from 'react'
import { makeStyles } from 'tss-react/mui'
import CookieManager from 'utils/cookie_util'
import { tabTypes } from 'utils/tab_types'
import { DefectReportViewModel } from 'view_models/defect_report'
import { ProofreadEventViewModel } from 'view_models/proofread_event'
import { RepairEstimateViewModel } from 'view_models/repair_estimate'
import { RepairEventViewModel } from 'view_models/repair_event'

type TabIds = 'report_table' | 'estimate_table' | 'repair_table' | 'proofread_table'
export type ObjectType = 'DefectReport' | 'RepairEstimate' | 'RepairEvent' | 'ProofreadEvent'

type InstrumentEventTableProps = {
  instrumentEventTableSet: InstrumentEventTableSet
}
const InstrumentEventTable: React.FC<InstrumentEventTableProps> = (props) => {
  const mypageState = useContext(MypageStateContext)
  const currentUser = mypageState.currentUser
  const {
    fetchReportsApi,
    fetchEstimatesApi,
    fetchRepairEventsApi,
    fetchProofreadsApi,
    defectReports,
    repairEstimates,
    repairEvents,
    proofreadEvents,
    defectReport,
    repairEstimate,
    repairEvent,
    proofreadEvent,
  } = props.instrumentEventTableSet

  const initTabId = CookieManager.getSelectedTab<TabIds>(tabTypes.MYPAGE_INSTRUMENT_EVENT_INDEX) || 'report_table'

  const handleClickRow = (object: Object, type: ObjectType) => {
    props.instrumentEventTableSet.handleSelectRow(object, type)
  }

  const reportTableProps = {
    defectReports: defectReports,
    indexApi: fetchReportsApi,
    onClickRow: handleClickRow,
    selectedId: defectReport?.id,
  }
  const estimateTableProps = {
    repairEstimates: repairEstimates,
    indexApi: fetchEstimatesApi,
    onClickRow: handleClickRow,
    selectedId: repairEstimate?.id,
  }
  const repairTableProps = {
    repairEvents: repairEvents,
    indexApi: fetchRepairEventsApi,
    onClickRow: handleClickRow,
    selectedId: repairEvent?.id,
  }
  const proofreadTableProps = {
    proofreadEvents: proofreadEvents,
    indexApi: fetchProofreadsApi,
    onClickRow: handleClickRow,
    selectedId: proofreadEvent?.id,
  }

  const handleSaveTabId = (tabId: string) => {
    CookieManager.saveSelectedTab(tabTypes.MYPAGE_INSTRUMENT_EVENT_INDEX, tabId)
  }

  const tabComponents = [
    {
      id: 'report_table',
      label: '不具合報告',
      component: <DefectReportMicroTable {...reportTableProps} />,
      visible: currentUser.authority?.repair,
    },
    {
      id: 'estimate_table',
      label: '修理見積',
      component: <RepairEstimateMicroTable {...estimateTableProps} />,
      visible: currentUser.authority?.repair,
    },
    {
      id: 'repair_table',
      label: '修理履歴',
      component: <RepairEventMicroTable {...repairTableProps} />,
      visible: currentUser.authority?.repair,
    },
    {
      id: 'proofread_table',
      label: '校正履歴',
      component: <ProofreadEventMicroTable {...proofreadTableProps} />,
      visible: currentUser.authority?.proofread,
    },
  ]

  return (
    <>
      <Card style={{ height: 230, width: '100%' }}>
        <Tabs tabComponents={tabComponents} initTabId={initTabId} onSelect={handleSaveTabId} />
      </Card>
    </>
  )
}

export default InstrumentEventTable

const useStyle = makeStyles()({
  clickableRow: {
    cursor: 'pointer',
  },
})

type DefectReportMicroTableProps = {
  defectReports: DefectReport[]
  indexApi: DefectReportsIndexApiSet
  onClickRow: (report: DefectReport, type: 'DefectReport') => void
  selectedId?: ID
}
const DefectReportMicroTable: React.FC<DefectReportMicroTableProps> = (props) => {
  const { response, loading, pageSet } = props.indexApi
  const { classes } = useStyle()
  const handleClickRow = (report: DefectReport) => {
    props.onClickRow(report, 'DefectReport')
  }

  return (
    <TableContainer style={{ height: 180 }}>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <ExTableCell width={60} align="center">
              発生日時
            </ExTableCell>
            <ExTableCell width={60}>報告者</ExTableCell>
            <ExTableCell width={60}>不具合箇所</ExTableCell>
            <ExTableCell width={300}>不具合内容</ExTableCell>
          </TableRow>
        </TableHead>
        <ExTableBody loading={loading}>
          {props.defectReports.map((report) => {
            const vm = new DefectReportViewModel(report)
            return (
              <TableRow
                key={`defect_report_${report.id}`}
                onClick={() => handleClickRow(report)}
                hover
                className={classes.clickableRow}
                selected={report.id === props.selectedId}
              >
                <ExTableCell width={60} align="center">
                  {vm.getReportedAtText()}
                </ExTableCell>
                <ExTableCell width={60}>{vm.getUserName()}</ExTableCell>
                <ExTableCell width={60} style={{ fontWeight: 'bold' }}>
                  {report.point}
                </ExTableCell>
                <ExTableCell width={300}>{report.content}</ExTableCell>
              </TableRow>
            )
          })}
        </ExTableBody>
      </Table>
      <ViewMoreButton pageSet={pageSet} totalCount={response.totalCount} count={props.defectReports.length} />
    </TableContainer>
  )
}

type RepairEstimateMicroTableProps = {
  repairEstimates: RepairEstimate[]
  indexApi: RepairEstimatesIndexApiSet
  onClickRow: (estimate: RepairEstimate, type: 'RepairEstimate') => void
  selectedId?: ID
}
const RepairEstimateMicroTable: React.FC<RepairEstimateMicroTableProps> = (props) => {
  const { response, loading, pageSet } = props.indexApi
  const { classes } = useStyle()
  const handleClickRow = (estimate: RepairEstimate) => {
    props.onClickRow(estimate, 'RepairEstimate')
  }

  return (
    <TableContainer style={{ height: 180 }}>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <ExTableCell align="center" width={100}>
              見積依頼日
            </ExTableCell>
            <ExTableCell align="center" width={120}>
              ステータス
            </ExTableCell>
            <ExTableCell align="center" width={200}>
              依頼者
            </ExTableCell>
            <ExTableCell align="center" width={300}>
              依頼先
            </ExTableCell>
            <ExTableCell align="center" width={120}>
              金額
            </ExTableCell>
            <ExTableCell align="center" width={120}>
              修理期間
            </ExTableCell>
            <ExTableCell align="center" width={120}>
              見積No.
            </ExTableCell>
          </TableRow>
        </TableHead>
        <ExTableBody loading={loading}>
          {props.repairEstimates.map((estimate: RepairEstimate) => {
            const vm = new RepairEstimateViewModel(estimate)
            return (
              <TableRow
                key={`repair_estimate_${estimate.id}`}
                onClick={() => handleClickRow(estimate)}
                hover
                className={classes.clickableRow}
                selected={estimate.id === props.selectedId}
              >
                <ExTableCell align="center">{vm.getRequestDate()}</ExTableCell>
                <ExTableCell align="center" style={{ padding: 0 }}>
                  <StatusLabel text={vm.getStatusText()} color={vm.getStatusColor()} style={{ display: 'inline' }} />
                </ExTableCell>
                <ExTableCell align="center">{vm.getRequestUserName()}</ExTableCell>
                <ExTableCell align="center">{vm.getSupplierName()}</ExTableCell>
                {estimate.aasmState === 'estimating' ? (
                  <ExTableCell colSpan={2} align="center">
                    見積中
                  </ExTableCell>
                ) : (
                  <>
                    <ExTableCell align="center">{vm.getPriceText()}</ExTableCell>
                    <ExTableCell align="center">{vm.getDeliveryTerm()}</ExTableCell>
                  </>
                )}
                <ExTableCell>{vm.getEstimateNumber()}</ExTableCell>
              </TableRow>
            )
          })}
        </ExTableBody>
      </Table>
      <ViewMoreButton pageSet={pageSet} totalCount={response.totalCount} count={props.repairEstimates.length} />
    </TableContainer>
  )
}

type RepairEventMicroTableProps = {
  repairEvents: RepairEvent[]
  indexApi: RepairEventsIndexApiSet
  onClickRow: (rEvent: RepairEvent, type: 'RepairEvent') => void
  selectedId?: ID
}
const RepairEventMicroTable: React.FC<RepairEventMicroTableProps> = (props) => {
  const { response, loading, pageSet } = props.indexApi
  const { classes } = useStyle()
  const handleClickRow = (rEvent: RepairEvent) => {
    props.onClickRow(rEvent, 'RepairEvent')
  }

  return (
    <TableContainer style={{ height: 180 }}>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <ExTableCell width={240} align="center">
              修理期間
            </ExTableCell>
            <ExTableCell width={100}>修理結果</ExTableCell>
            <ExTableCell width={120}>修理費用</ExTableCell>
            <ExTableCell>不具合箇所 / 修理内容詳細</ExTableCell>
          </TableRow>
        </TableHead>
        <ExTableBody loading={loading}>
          {props.repairEvents.map((repairEvent: RepairEvent) => {
            const vm = new RepairEventViewModel(repairEvent)
            return (
              <TableRow
                key={`repair_event_${repairEvent.id}`}
                onClick={() => handleClickRow(repairEvent)}
                hover
                className={classes.clickableRow}
                selected={repairEvent.id === props.selectedId}
              >
                <ExTableCell width={120} align="center">
                  {vm.getPeriodText()}
                </ExTableCell>
                <ExTableCell width={100} align="center" style={{ padding: 0 }}>
                  <StatusLabel text={vm.getResultText()} color={vm.getResultColor()} style={{ display: 'inline' }} />
                </ExTableCell>
                <ExTableCell>{vm.getPriceText()}</ExTableCell>
                <ExTableCell>
                  <label>{vm.getDefectPoint()} </label>/ {vm.getDetail()}
                </ExTableCell>
              </TableRow>
            )
          })}
        </ExTableBody>
      </Table>
      <ViewMoreButton pageSet={pageSet} totalCount={response.totalCount} count={props.repairEvents.length} />
    </TableContainer>
  )
}

type ProofreadEventMicroTableProps = {
  proofreadEvents: ProofreadEvent[]
  indexApi: ProofreadEventsIndexApiSet
  onClickRow: (pEvent: ProofreadEvent, type: 'ProofreadEvent') => void
  selectedId?: ID
}
const ProofreadEventMicroTable: React.FC<ProofreadEventMicroTableProps> = (props) => {
  const { response, loading, pageSet } = props.indexApi
  const { classes } = useStyle()
  const handleClickRow = (pEvent: ProofreadEvent) => {
    props.onClickRow(pEvent, 'ProofreadEvent')
  }

  return (
    <TableContainer style={{ height: 180 }}>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <ExTableCell>校正月</ExTableCell>
            <ExTableCell>校正価格</ExTableCell>
            <ExTableCell>委託先</ExTableCell>
            <ExTableCell>担当者</ExTableCell>
            <ExTableCell>ステータス</ExTableCell>
          </TableRow>
        </TableHead>
        <ExTableBody loading={loading}>
          {props.proofreadEvents.map((proofreadEvent) => {
            const eventVm = new ProofreadEventViewModel(proofreadEvent)
            return (
              <TableRow
                key={`proofread_event_${proofreadEvent.id}`}
                onClick={() => handleClickRow(proofreadEvent)}
                hover
                className={classes.clickableRow}
                selected={proofreadEvent.id === props.selectedId}
              >
                <ExTableCell>{eventVm.getScheduledDate()}</ExTableCell>
                <ExTableCell>{eventVm.getPrice()}</ExTableCell>
                <ExTableCell>{eventVm.getSupplier()}</ExTableCell>
                <ExTableCell>{eventVm.getUser()}</ExTableCell>
                <ExTableCell>{eventVm.getStatusLabel()}</ExTableCell>
              </TableRow>
            )
          })}
        </ExTableBody>
      </Table>
      <ViewMoreButton pageSet={pageSet} totalCount={response.totalCount} count={props.proofreadEvents.length} />
    </TableContainer>
  )
}
