import { usePartnerFetchLaboratoriesApi } from 'api/partner/laboratories'
import { usePartnerFetchOutsideLaboratoriesApi } from 'api/partner/outside_laboratories'
import { useFetchExColumnsApi } from 'api/shared/ex_columns'
import { ContentHeader, ContentTitle } from 'components/shared/content'
import { EditExColumnDialog, NewExColumnDialog } from 'components/shared/ex_attribute/ex_column_dialog'
import { ExColumnTable } from 'components/shared/ex_attribute/ex_column_table'
import { Flex } from 'components/shared/flex'
import { useModal } from 'components/shared/modal'
import StatusLabel from 'components/shared/status_label'
import { PartnerStateContext } from 'contexts/partner_state_context'
import { ExColumn, ExtensionClass } from 'entities/ex_column'
import { InstrumentOwnerableType } from 'entities/instrument'
import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'

export type DealerClient = { clientableId?: number; clientableType?: InstrumentOwnerableType }
const clientType = 'dealer_user'

export const ExColumnIndex = () => {
  const partnerState = useContext(PartnerStateContext)
  const indexApiSet = useFetchExColumnsApi(clientType)
  const newModalState = useModal()
  const editModalState = useModal()
  const [selectedColumn, setSelectedColumn] = useState<ExColumn | null>(null)
  const [selectedExtensionClass, setSelectedExtensionClass] = useState<ExtensionClass>('InstrumentInfoAttribute')
  const [dealerClient, setDealerClient] = useState<DealerClient>({
    clientableId: undefined,
    clientableType: undefined,
  })
  const indexOutsideLabApi = usePartnerFetchOutsideLaboratoriesApi({ useFilter: true })
  const indexLaboratoryApi = usePartnerFetchLaboratoriesApi({ useFilter: true })

  const handleClickNewButton = (extensionClass: ExtensionClass, client: DealerClient) => {
    setSelectedExtensionClass(extensionClass)
    setDealerClient(client)
    newModalState.setOpen(true)
  }

  const handleClickEditButton = (column: ExColumn) => {
    setSelectedColumn(column)
    editModalState.setOpen(true)
  }

  const handleCompleteCreate = () => {
    newModalState.setOpen(false)
    indexApiSet.execute()
  }

  const handleCompleteUpdate = () => {
    editModalState.setOpen(false)
    indexApiSet.execute()
  }

  useEffect(() => {
    indexApiSet.execute()
    indexOutsideLabApi.execute()
    indexLaboratoryApi.execute()
  }, [])

  return (
    <>
      <NewExColumnDialog
        clientType={clientType}
        modalState={newModalState}
        onComplete={handleCompleteCreate}
        extensionClass={selectedExtensionClass}
        dealerClientId={dealerClient.clientableId}
        dealerClientType={dealerClient.clientableType}
      />
      <EditExColumnDialog clientType={clientType} modalState={editModalState} onComplete={handleCompleteUpdate} exColumn={selectedColumn} />
      <ContentHeader>
        <ContentTitle title="管理情報" />
      </ContentHeader>
      <Flex>
        <div style={{ width: '100%' }}>
          <ExColumnTable
            title="製品管理情報 - 共通"
            extensionClass="InstrumentInfoAttribute"
            exColumns={indexApiSet.response.exColumns.filter(
              (column) => column.extensionClass === 'InstrumentInfoAttribute' && (!column.clientableId || !column.clientableType),
            )}
            loading={indexApiSet.loading}
            onClickNewButton={handleClickNewButton}
            onClickEditButton={handleClickEditButton}
            editable
            open
          />
          {partnerState.currentDealer.authority?.enableInstrumentSharing &&
            indexLaboratoryApi.response.laboratories.map((lab) => (
              <ExColumnTable
                key={`instrument-ex-laboratory-${lab.id}`}
                title={`製品管理情報 - ${lab.name}`}
                extensionClass="InstrumentInfoAttribute"
                exColumns={indexApiSet.response.exColumns.filter(
                  (column) =>
                    column.extensionClass === 'InstrumentInfoAttribute' &&
                    column.clientableId === lab.id &&
                    column.clientableType === 'Laboratory',
                )}
                loading={indexApiSet.loading}
                onClickNewButton={handleClickNewButton}
                onClickEditButton={handleClickEditButton}
                editable
                clientableId={lab.id}
                clientableType="Laboratory"
              />
            ))}
          {indexOutsideLabApi.response.outsideLaboratories.map((lab) => (
            <ExColumnTable
              key={`instrument-ex-outside-lab-${lab.id}`}
              title={
                <>
                  製品管理情報 - {lab.name}
                  <StatusLabel text="外部顧客" color="gray" style={{ marginLeft: 10 }} />
                </>
              }
              extensionClass="InstrumentInfoAttribute"
              exColumns={indexApiSet.response.exColumns.filter(
                (column) =>
                  column.extensionClass === 'InstrumentInfoAttribute' &&
                  column.clientableId === lab.id &&
                  column.clientableType === 'OutsideLaboratory',
              )}
              loading={indexApiSet.loading}
              onClickNewButton={handleClickNewButton}
              onClickEditButton={handleClickEditButton}
              editable
              clientableId={lab.id}
              clientableType="OutsideLaboratory"
            />
          ))}
        </div>
        <div style={{ width: '100%' }}>
          <ExColumnTable
            title="校正管理情報 - 共通"
            extensionClass="ProofreadEvent"
            exColumns={indexApiSet.response.exColumns.filter(
              (column) => column.extensionClass === 'ProofreadEvent' && (!column.clientableId || !column.clientableType),
            )}
            loading={indexApiSet.loading}
            onClickNewButton={handleClickNewButton}
            onClickEditButton={handleClickEditButton}
            editable
            open
          />
          {partnerState.currentDealer.authority?.enableInstrumentSharing &&
            indexLaboratoryApi.response.laboratories.map((lab) => (
              <ExColumnTable
                key={`ProofreadEvent-ex-laboratory-${lab.id}`}
                title={`校正管理情報 - ${lab.name}`}
                extensionClass="ProofreadEvent"
                exColumns={indexApiSet.response.exColumns.filter(
                  (column) =>
                    column.extensionClass === 'ProofreadEvent' && column.clientableId === lab.id && column.clientableType === 'Laboratory',
                )}
                loading={indexApiSet.loading}
                onClickNewButton={handleClickNewButton}
                onClickEditButton={handleClickEditButton}
                editable
                clientableId={lab.id}
                clientableType="Laboratory"
              />
            ))}
          {indexOutsideLabApi.response.outsideLaboratories.map((lab) => (
            <ExColumnTable
              key={`ProofreadEvent-ex-outside-lab-${lab.id}`}
              title={
                <>
                  校正管理情報 - {lab.name}
                  <StatusLabel text="外部顧客" color="gray" style={{ marginLeft: 10 }} />
                </>
              }
              extensionClass="ProofreadEvent"
              exColumns={indexApiSet.response.exColumns.filter(
                (column) =>
                  column.extensionClass === 'ProofreadEvent' &&
                  column.clientableId === lab.id &&
                  column.clientableType === 'OutsideLaboratory',
              )}
              loading={indexApiSet.loading}
              onClickNewButton={handleClickNewButton}
              onClickEditButton={handleClickEditButton}
              editable
              clientableId={lab.id}
              clientableType="OutsideLaboratory"
            />
          ))}
        </div>
      </Flex>
    </>
  )
}

export default withRouter(ExColumnIndex)
