import { ApprovalResult } from 'entities/approval_result'
import React from 'react'
import { ApprovalResultViewModel } from 'view_models/approval_result'

type ApprovalResultsTableProps = {
  approvalResults: ApprovalResult[]
  label?: string
  emptyText?: string
}
const ApprovalResultsTable: React.FC<ApprovalResultsTableProps> = (props) => {
  return (
    <div style={{ width: '100%' }}>
      <label>{props.label || '承認者'}</label>
      <table width="100%" style={{ marginTop: 6, paddingLeft: 6 }}>
        {props.approvalResults.length === 0 ? (
          <>{props.emptyText || 'なし'}</>
        ) : (
          props.approvalResults.map((result) => {
            const vm = new ApprovalResultViewModel(result)
            return (
              <tr key={result.id}>
                <td width="13%">
                  <span style={{ fontSize: '0.8rem' }}>{vm.getApprovalStep()}</span>
                </td>
                <td width="20%">
                  <label>{vm.getApproverName()}</label>
                </td>
                <td width="9%">
                  <label style={{ fontSize: '0.8rem' }}>{vm.getApproveText()}</label>
                </td>
                <td width="18%">
                  <span style={{ fontSize: '0.8rem' }}>{vm.getApprovedAtText()}</span>
                </td>
                <td width="40%">{vm.getNote()}</td>
              </tr>
            )
          })
        )}
      </table>
    </div>
  )
}

export default ApprovalResultsTable
