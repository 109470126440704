import { Paper } from '@mui/material'
import { useExportDefectReportsApi, useFetchDefectReportsApi } from 'api/mypage/defect_report'
import { EditDefectReportFormDialog } from 'components/mypage/defect_report/defect_report_form_dialog'
import { DefectReportTable } from 'components/mypage/defect_report/defect_report_table'
import { ContentHeader, ContentTitle } from 'components/shared/content'
import { useModal } from 'components/shared/modal'
import { SearchBar } from 'components/shared/search_bar'
import ThemedButton from 'components/shared/themed_button'
import { DefectReport, DefectReportSearchForm } from 'entities/defect_report'
import { useForm } from 'rac'
import React, { useEffect, useState } from 'react'
import { StaticContext } from 'react-router'
import { withRouter, RouteComponentProps } from 'react-router-dom'

export type DefectReportIndexProps = {}
type Props = RouteComponentProps<{}, StaticContext, DefectReportIndexProps>

export const DefectReportIndex: React.FC<Props> = (props: Props) => {
  const searchForm = useForm<DefectReportSearchForm>({})
  const api = useFetchDefectReportsApi(searchForm)
  const [selectedReport, setSelectedReport] = useState<DefectReport>({})
  const editModal = useModal()
  const exportApi = useExportDefectReportsApi(searchForm)
  const handleSearch = () => {
    api.executeAndPageReset()
  }

  useEffect(() => {
    api.execute()
  }, [])

  const handleClickEditButton = (defectReport: DefectReport) => {
    setSelectedReport(defectReport)
    editModal.setOpen(true)
  }

  const handleCompleteUpdate = () => {
    editModal.setOpen(false)
    api.execute()
  }

  const handleClickExport = (format: 'xlsx') => {
    exportApi.execute(format)
  }

  return (
    <>
      <EditDefectReportFormDialog modalState={editModal} defectReport={selectedReport} onComplete={handleCompleteUpdate} />
      <ContentHeader
        subHeader={
          <>
            <ThemedButton color="secondary" onClick={() => handleClickExport('xlsx')} style={{ marginLeft: 10 }}>
              Excel出力
            </ThemedButton>
          </>
        }
      >
        <ContentTitle title="不具合報告一覧" />
        <SearchBar<DefectReportSearchForm>
          form={searchForm}
          searchAttr="searchText"
          placeholder="機器名称・不具合箇所・不具合内容で検索"
          onSubmit={handleSearch}
        />
      </ContentHeader>
      <Paper>
        <DefectReportTable
          indexApi={api}
          onClickEdit={(defectReport: DefectReport) => handleClickEditButton(defectReport)}
          showInstrument
        />
      </Paper>
    </>
  )
}
export default withRouter(DefectReportIndex)
