import { Typography } from '@mui/material'
import { EditRepairEventFormDialog } from 'components/mypage/repair_event/repair_event_form_dialog'
import AttachmentsList from 'components/shared/data/attachments_list'
import DataBlock from 'components/shared/data/data_block'
import DataCard from 'components/shared/data/data_card'
import DataLabel from 'components/shared/data/data_label'
import { Flex } from 'components/shared/flex'
import { useModal } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { RepairEvent } from 'entities/repair_event'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { MypageRouteHelper } from 'routes/mypage'
import { RepairEventViewModel } from 'view_models/repair_event'

type RepairEventDataCardProps = {
  repairEvent: RepairEvent | undefined
  canEdit?: boolean
  onCompleteUpdate?: (event?: RepairEvent) => void
  noTitle?: boolean
  emptyMessage?: string
  showDetail?: boolean
}
const RepairEventDataCard: React.FC<RepairEventDataCardProps> = (props) => {
  const editModal = useModal()
  const history = useHistory()
  const [vm, setVm] = useState(new RepairEventViewModel(props.repairEvent || {}))

  const handleClickEditButton = () => {
    editModal.setOpen(true)
  }

  const handleClickDetail = () => {
    history.push(MypageRouteHelper.repairEventShow(props.repairEvent!.id!))
  }

  const handleCompleteUpdate = (event?: RepairEvent) => {
    editModal.setOpen(false)
    props.onCompleteUpdate && props.onCompleteUpdate(event)
  }

  useEffect(() => {
    if (props.repairEvent) {
      setVm(new RepairEventViewModel(props.repairEvent))
    }
  }, [props.repairEvent])

  return (
    <>
      <EditRepairEventFormDialog
        onComplete={(event?: RepairEvent) => handleCompleteUpdate(event)}
        modalState={editModal}
        repairEvent={props.repairEvent || {}}
      />
      <DataCard>
        <Flex alignItems="center" justifyContent="space-between">
          {!props.noTitle && <Typography variant="h6">修理履歴</Typography>}
          {props.repairEvent && props.showDetail && (
            <ThemedButton color="secondary" onClick={handleClickDetail}>
              詳細
            </ThemedButton>
          )}
          {props.canEdit && props.repairEvent && (
            <ThemedButton color="primary" onClick={handleClickEditButton}>
              編集
            </ThemedButton>
          )}
        </Flex>
        {props.repairEvent ? (
          <>
            {props.repairEvent.repairEstimate && (
              <DataBlock>
                <DataLabel label="依頼先" value={vm.getSupplierName()} />
              </DataBlock>
            )}
            <DataBlock>
              <DataLabel label="記録者" value={vm.getRecorderName()} />
              <DataLabel label="修理期間" value={vm.getPeriodText()} />
              <DataLabel label="修理費用" value={vm.getPriceText()} />
            </DataBlock>
            {props.repairEvent.repairEstimate && (
              <DataBlock>
                <DataLabel label="repruaパートナーへのコメント" value={vm.getNoteToSupplier()} />
              </DataBlock>
            )}
            <DataBlock>
              <DataLabel label="修理結果" value={vm.getResultText()} width="50%" />
              <DataLabel label="修理内容詳細" value={vm.getDetail()} />
            </DataBlock>
            <DataBlock noBorder>
              <AttachmentsList fileAttachments={props.repairEvent.fileAttachments || []} />
            </DataBlock>
          </>
        ) : (
          <span>{props.emptyMessage || '修理履歴を選択してください'}</span>
        )}
      </DataCard>
    </>
  )
}

export default RepairEventDataCard
