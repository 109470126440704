import { Table, TableBody, TableHead, TableRow } from '@mui/material'
import { usePatchProofreadEventApproveApi } from 'api/mypage/proofread_event'
import { Flex } from 'components/shared/flex'
import { StringField } from 'components/shared/form/input'
import Modal, { ModalSet } from 'components/shared/modal'
import { ExTableCell } from 'components/shared/table'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { MypageStateContext } from 'contexts/mypage_state_context'
import { ProofreadApproverForm } from 'entities/proofread_approver'
import { ProofreadEvent } from 'entities/proofread_event'
import { useForm } from 'rac'
import React, { useContext, useState } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'
import { ProofreadApproverViewModel } from 'view_models/proofread_approver'
import { ProofreadEventViewModel } from 'view_models/proofread_event'

type PlannedEventFormDialogProps = {
  modalSet: ModalSet<ProofreadEvent>
  onComplete: (event: ProofreadEvent) => void
  onClickHistory: (event: ProofreadEvent) => void
}
export const PlannedEventFormDialog: React.FC<PlannedEventFormDialogProps> = (props) => {
  const { currentUser } = useContext(MypageStateContext)
  const globalState = useContext(GlobalStateContext)

  const [eventVm, setEventVm] = useState(new ProofreadEventViewModel(props.modalSet.item))

  const patchForm = useForm<ProofreadApproverForm>({ proofreadEventId: props.modalSet.item.id!, kind: 'plan', approverId: currentUser.id })
  const patchApi = usePatchProofreadEventApproveApi(patchForm)

  const handleApprove = (status: 'approved' | 'rejected') => {
    globalState.confirm(`校正計画を${status === 'approved' ? '承認' : '却下'}します。よろしいですか？`, (e) => {
      if (e === 'ok') {
        patchApi.execute(status)
      }
    })
  }

  useEffectSkipFirst(() => {
    patchForm.resetForm()
    setEventVm(new ProofreadEventViewModel(props.modalSet.item))
  }, [props.modalSet.item.id])

  useEffectSkipFirst(() => {
    if (!patchApi.isSuccess()) return
    props.onComplete(props.modalSet.item)
  }, [patchApi.loading])

  return (
    <Modal
      modalId="event-planning-form"
      modalState={props.modalSet.modalState}
      title="校正計画承認"
      subTitle={eventVm.getInstrumentName()}
      footer={
        <>
          <ThemedButton onClick={props.modalSet.closeModal} color="secondary">
            閉じる
          </ThemedButton>
          {props.modalSet.item.approvers?.some((a) => a.approverId === currentUser.id && a.kind === 'plan' && a.aasmState === 'yet') && (
            <>
              <ThemedButton color="error" onClick={() => handleApprove('rejected')}>
                却下
              </ThemedButton>
              <ThemedButton color="success" onClick={() => handleApprove('approved')}>
                承認
              </ThemedButton>
            </>
          )}
        </>
      }
    >
      <Flex justifyContent="space-between" style={{ marginBottom: 24 }}>
        <div>
          <label>最終校正月</label>
          <div style={{ fontSize: 20 }}>{eventVm.getLastResultDate()}</div>
        </div>
        <div>
          <label>校正周期</label>
          <div style={{ fontSize: 20 }}>{eventVm.getCycle()}</div>
        </div>
        <div>
          <label>有効期限</label>
          <div style={{ fontSize: 20 }}>{eventVm.getExpiredDate()}</div>
        </div>
        <div>
          <ThemedButton color="secondary" onClick={() => props.onClickHistory(props.modalSet.item)}>
            過去の校正を確認
          </ThemedButton>
        </div>
      </Flex>
      <Table>
        <TableHead>
          <TableRow>
            <ExTableCell />
            <ExTableCell>校正月</ExTableCell>
            <ExTableCell>校正価格</ExTableCell>
            <ExTableCell>委託先</ExTableCell>
            <ExTableCell>担当者</ExTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <ExTableCell>前回の校正</ExTableCell>
            <ExTableCell>{eventVm.getLastEventScheduledDate()}</ExTableCell>
            <ExTableCell>{eventVm.getLastEventPrice()}</ExTableCell>
            <ExTableCell>{eventVm.getLastEventSupplier()}</ExTableCell>
            <ExTableCell>{eventVm.getLastEventUser()}</ExTableCell>
          </TableRow>
          <TableRow>
            <ExTableCell>今回の校正</ExTableCell>
            <ExTableCell>{eventVm.getScheduledDate()}</ExTableCell>
            <ExTableCell>{eventVm.getPrice()}</ExTableCell>
            <ExTableCell>{eventVm.getSupplier()}</ExTableCell>
            <ExTableCell>{eventVm.getUser()}</ExTableCell>
          </TableRow>
        </TableBody>
      </Table>
      {props.modalSet.item.reservation && (
        <div style={{ paddingTop: 12 }}>
          <label>校正期間</label>
          <div style={{ fontSize: 20, paddingLeft: 12 }}>{eventVm.getReservationText()}</div>
        </div>
      )}
      <div style={{ marginTop: 12 }}>
        <label>添付ファイル</label>
        <ul style={{ listStyle: 'none', marginTop: 6, paddingLeft: 12 }}>
          {props.modalSet.item.attachments?.map((a) => {
            if (a.kind === 'plan')
              return (
                <li>
                  <a href={a.url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    {a.name}
                  </a>
                </li>
              )
          })}
          {(props.modalSet.item.attachments || []).length === 0 && <span>なし</span>}
        </ul>
      </div>
      <div style={{ marginTop: 12 }}>
        <label>承認者</label>
        <Table>
          <TableBody>
            {props.modalSet.item.approvers?.map((a) => {
              if (a.kind === 'plan') {
                const aVm = new ProofreadApproverViewModel(a)
                return (
                  <TableRow key={`approve-${a.id}`}>
                    <ExTableCell>
                      <div style={{ width: 130 }}>
                        {a.approver?.familyName} {a.approver?.givenName}
                      </div>
                    </ExTableCell>
                    <ExTableCell>
                      <div style={{ width: 60 }}>{aVm.getApproveLabel()}</div>
                    </ExTableCell>
                    <ExTableCell>
                      <div style={{ width: 360 }}>{aVm.getApprovalNote()}</div>
                    </ExTableCell>
                  </TableRow>
                )
              }
            })}
          </TableBody>
        </Table>
      </div>
      <div style={{ marginTop: 12 }}>
        <label>計画時メモ</label>
        <p style={{ paddingLeft: 12, marginTop: 6 }}>{props.modalSet.item.noteOnPlan || 'なし'}</p>
      </div>

      {props.modalSet.item.approvers?.some((a) => a.approverId === currentUser.id && a.kind === 'plan' && a.aasmState === 'yet') && (
        <StringField label="承認メモ" form={patchForm} attr="note" apiError={patchApi.apiError} multiline style={{ marginTop: 24 }} />
      )}
    </Modal>
  )
}

export default PlannedEventFormDialog
