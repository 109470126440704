import { AlertColor } from '@mui/material'
import { ConfirmCallbackEvent } from 'containers/global_state_container'
import { ApiError } from 'rac'
import React, { Dispatch, SetStateAction } from 'react'

type NotificationMessage = {
  body: string
  colorType?: AlertColor
  title?: string
}

type GlobalState = {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  apiError: ApiError
  setError: Dispatch<SetStateAction<ApiError>>
  notificationMessage: NotificationMessage
  setNotificationMessage: Dispatch<SetStateAction<NotificationMessage>>
  confirm: (message: string, callback: (event: ConfirmCallbackEvent) => void) => void
  statusCode: number
  setStatusCode: Dispatch<SetStateAction<number>>
}

export const initialGlobalState: GlobalState = {
  loading: false,
  setLoading: (value: SetStateAction<boolean>) => {},
  apiError: { message: '', messages: [''], details: {} },
  setError: (value: SetStateAction<{ message: string; messages: string[]; details: {} }>) => {},
  notificationMessage: { body: '' },
  setNotificationMessage: (value: SetStateAction<NotificationMessage>) => {},
  confirm: (message: string, callback: (event: ConfirmCallbackEvent) => void) => {},
  statusCode: 0,
  setStatusCode: (value: SetStateAction<number>) => {},
}

export const GlobalStateContext = React.createContext(initialGlobalState)
