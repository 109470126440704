import { AxiosHttpClient } from 'api/axios'
import { useRepruaIndexApi, useRepruaPatchApi } from 'api/shared_api_hooks'
import { PagingResponse } from 'entities'
import { Dealer } from 'entities/dealer'
import { UpdateDivisionIdsForm, DealerUser, DealerUserForm } from 'entities/dealer_user'
import { ApiSet, BaseResponse, Form } from 'rac'
import { useQuery } from 'react-query'

type DealerUsersResponse = PagingResponse & {
  dealerUsers: DealerUser[]
}
/**
 * 一覧
 */
export function useFetchPartnerDealerUsersApi(): ApiSet<DealerUsersResponse> & { execute: () => void } {
  const api = useRepruaIndexApi<DealerUsersResponse, {}>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { dealerUsers: [], totalCount: 0 },
  })

  const execute = () => {
    const path = 'dealer_users/'
    api.execute(path)
  }

  return { ...api, execute }
}

type DealerUserResponse = BaseResponse & {
  dealer: Dealer
  dealerUser: DealerUser
}
/**
 * ログイン中のユーザーを取得
 */
export const useCurrentDealerUserQuery = () => {
  const client = new AxiosHttpClient('dealer_user')
  const query = useQuery<undefined, undefined, DealerUserResponse>(
    'current_dealer_user',
    async () => {
      const { data } = await client.get('dealer_users/current', {})
      return data
    },
    { enabled: false },
  )

  return query
}

/**
 * 更新
 */
export const usePartnerPatchDealerUserApi = (
  form: Form<DealerUserForm>,
): ApiSet<DealerUserResponse> & {
  execute: () => void
} => {
  const api = useRepruaPatchApi<DealerUserResponse, DealerUserForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { dealerUser: {}, dealer: {} },
  })

  const execute = () => {
    const path = 'dealer_users'
    api.execute(path, form.object)
  }

  return { ...api, execute }
}

/**
 * deaer_division_idsの更新
 */
export const usePartnerPatchDealerUserUpdateDivisionIdsApi = (
  form: Form<UpdateDivisionIdsForm>,
): ApiSet<DealerUserResponse> & {
  execute: (dealerUserId: number) => void
} => {
  const api = useRepruaPatchApi<DealerUserResponse, UpdateDivisionIdsForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { dealerUser: {}, dealer: {} },
  })

  const execute = (dealerUserId: number) => {
    const path = `dealer_users/${dealerUserId}/update_division_ids`
    api.execute(path, form.object)
  }

  return { ...api, execute }
}
