import { Typography } from '@mui/material'
import AttachmentsList from 'components/shared/data/attachments_list'
import DataBlock from 'components/shared/data/data_block'
import DataCard from 'components/shared/data/data_card'
import DataLabel from 'components/shared/data/data_label'
import { DropdownMenu } from 'components/shared/dropdown_menu'
import { Flex } from 'components/shared/flex'
import { useModal } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { DefectReport } from 'entities/defect_report'
import { RepairEvent } from 'entities/repair_event'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { PartnerRouteHelper } from 'routes/partner'
import { DefectReportViewModel } from 'view_models/defect_report'
import { EditDefectReportFormDialog } from './defect_report_form_dialog'
import { RepairEstimateRequestFormDialog } from 'components/partner/repair_estimate/repair_estimate_form_dialog'
import { NewRepairEventFormDialogFromDefectReport } from '../repair_event/repair_event_form_dialog'
import { RepairEstimate } from 'entities/repair_estimate'

type DefectReportDataCardProps = {
  defectReport?: DefectReport
  canEdit?: boolean
  onCompleteEdit?: (report?: DefectReport) => void
  onCompleteCreateEvent?: (repairEvent?: RepairEvent) => void
  onCompleteCreateEstimate?: (repairEstimate: RepairEstimate) => void
  noTitle?: boolean
  showDetail?: boolean
  emptyMessage?: string
}
export const DefectReportDataCard: React.FC<DefectReportDataCardProps> = (props) => {
  const history = useHistory()
  const [vm, setVm] = useState(new DefectReportViewModel(props.defectReport || {}))
  const editModal = useModal()
  const repairEventModal = useModal()
  const createEstimateRequestModal = useModal()

  const handleCompleteUpdate = (report?: DefectReport) => {
    editModal.setOpen(false)
    if (props.onCompleteEdit) {
      props.onCompleteEdit(report || undefined)
    }
  }
  const handleCompleteDelete = (_report?: DefectReport) => {
    editModal.setOpen(false)
    if (props.onCompleteEdit) {
      props.onCompleteEdit()
    }
  }

  const handleCompleteCreateRepairEvent = (repairEvent?: RepairEvent) => {
    repairEventModal.setOpen(false)
    if (props.onCompleteCreateEvent) {
      props.onCompleteCreateEvent(repairEvent)
    }
  }

  const handleCompleteCreateEstimate = (repairEstimate: RepairEstimate) => {
    createEstimateRequestModal.setOpen(false)
    if (props.onCompleteCreateEstimate) {
      props.onCompleteCreateEstimate(repairEstimate)
    }
  }

  const handleClickDetail = () => {
    history.push(PartnerRouteHelper.defectReportShow(props.defectReport!.id!))
  }

  useEffect(() => {
    if (props.defectReport) {
      setVm(new DefectReportViewModel(props.defectReport))
    }
  }, [props.defectReport])

  return (
    <DataCard>
      {props.canEdit && props.defectReport && (
        <>
          <EditDefectReportFormDialog
            modalState={editModal}
            defectReport={props.defectReport}
            onComplete={handleCompleteUpdate}
            onDelete={handleCompleteDelete}
            onClose={() => editModal.setOpen(false)}
          />
          <RepairEstimateRequestFormDialog
            modalState={createEstimateRequestModal}
            defectReport={props.defectReport}
            onComplete={handleCompleteCreateEstimate}
          />
          <NewRepairEventFormDialogFromDefectReport
            modalState={repairEventModal}
            defectReport={props.defectReport}
            onClose={() => repairEventModal.setOpen(false)}
            onComplete={handleCompleteCreateRepairEvent}
          />
        </>
      )}
      <Flex alignItems="center" justifyContent="space-between">
        {!props.noTitle && <Typography variant="h6">不具合報告</Typography>}
        {props.defectReport && props.showDetail && (
          <ThemedButton color="secondary" onClick={handleClickDetail}>
            詳細
          </ThemedButton>
        )}
        {props.canEdit && props.defectReport && (
          <DropdownMenu
            buttonText="アクション"
            buttonColor="primary"
            id="defect-report-action"
            listItems={[
              { text: '不具合報告の編集', onClick: () => editModal.setOpen(true) },
              { text: '修理見積を作成', onClick: () => createEstimateRequestModal.setOpen(true) },
              { text: '修理履歴を作成', onClick: () => repairEventModal.setOpen(true) },
            ]}
          />
        )}
      </Flex>
      {props.defectReport ? (
        <>
          <DataBlock>
            <DataLabel label="発生日時" value={vm.getReportedAtText()} />
            <DataLabel label="報告者" value={vm.getUserName()} />
          </DataBlock>
          <DataBlock>
            <DataLabel label="不具合箇所" value={vm.getPoint()} />
          </DataBlock>
          <DataBlock>
            <DataLabel label="不具合発生時の状況" value={vm.getContent()} />
          </DataBlock>
          <DataBlock noBorder>
            <AttachmentsList fileAttachments={props.defectReport.fileAttachments || []} />
          </DataBlock>
        </>
      ) : (
        <span>{props.emptyMessage || '不具合報告を選択してください'}</span>
      )}
    </DataCard>
  )
}

export default DefectReportDataCard
