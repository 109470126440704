import DropDownCheckbox from 'components/shared/dropdown_checkbox'
import React from 'react'
import { ProofreadEventFilterSet } from 'utils/hooks/use_proofread_event_filter'

type InstrumentInfoFilterTableHeaderProps = {
  proofreadEventFilterSet: ProofreadEventFilterSet
}
export const InstrumentCategoryFilterTableHeader = ({ proofreadEventFilterSet }: InstrumentInfoFilterTableHeaderProps) => {
  const { searchForm, handleSearch, instrumentCategoryNamesOptions } = proofreadEventFilterSet

  return (
    <DropDownCheckbox
      form={searchForm}
      attr={['q', 'proofreadInfoInstrumentInstrumentCategoryIdIn']}
      name="makers"
      items={instrumentCategoryNamesOptions()}
      title="機器カテゴリ"
      onSubmit={handleSearch}
      btnStyle={{ padding: '4px 8px' }}
      isLeft={true}
      showFilter
      autocomplete
      containerStyle={{ marginLeft: 10 }}
    />
  )
}
