import { ApprovalResult } from 'entities/approval_result'
import moment from 'moment'
import { ViewModel } from 'view_models'

export class ApprovalResultViewModel extends ViewModel<ApprovalResult> {
  public getApprovalStep(): string {
    switch (this.object.approval) {
      case 'first':
        return '1次承認'
      case 'second':
        return '2次承認'
      case 'third':
        return '3次承認'
      case 'fourth':
        return '4次承認'
      case 'fifth':
        return '5次承認'
      default:
        return '-'
    }
  }

  public getApproverName(): string {
    return this.object.approver || ''
  }

  public getApproveText(): string {
    return this.object.approved ? '承認' : '却下'
  }
  public getApprovedAtText(): string {
    return moment(this.object.createdAt).format('MM/DD HH:mm')
  }

  public getNote(): string {
    return this.object.note || ''
  }
}
