import { AxiosHttpClient } from 'api/axios'
import { useRepruaIndexApi } from 'api/shared_api_hooks'
import { PagingResponse } from 'entities'
import { User } from 'entities/user'
import { ApiSet } from 'rac'

type UsersResponse = PagingResponse & {
  users: User[]
}

/**
 * 取得
 */
export function useFetchUsersManagerApi(): ApiSet<UsersResponse> & {
  execute: () => void
} {
  const api = useRepruaIndexApi<UsersResponse, {}>(new AxiosHttpClient('user'), { initialResponse: { users: [], totalCount: 0 } })

  const execute = () => {
    const path = 'manager/users'
    api.execute(path)
  }

  return { ...api, execute }
}
