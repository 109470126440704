import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import BuildIcon from '@mui/icons-material/Build'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import CropFreeIcon from '@mui/icons-material/CropFree'
import DomainIcon from '@mui/icons-material/Domain'
import LaunchIcon from '@mui/icons-material/Launch'
import ListIcon from '@mui/icons-material/List'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import MailIcon from '@mui/icons-material/Mail'
import RoomIcon from '@mui/icons-material/MeetingRoom'
import LogoutIcon from '@mui/icons-material/PowerSettingsNew'
import SettingsIcon from '@mui/icons-material/Settings'
import ShareIcon from '@mui/icons-material/Share'
import { Tooltip } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import CssBaseline from '@mui/material/CssBaseline'
import Toolbar from '@mui/material/Toolbar'
import { Flex } from 'components/shared/flex'
import { Sidebar } from 'components/shared/sidebar'
import { SidebarMenuItem, SidebarSubMenuItem } from 'components/shared/sidebar_menu_item'
import { GlobalStateContext } from 'contexts/global_state_context'
import { MypageStateContext } from 'contexts/mypage_state_context'
import { SidebarContext } from 'contexts/sidebar_context'
import { MenuItemPathPattern } from 'entities/menu_item_path_pattern'
import React, { ReactNode, useContext } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { MypageRouteHelper } from 'routes/mypage'
import CookieManager from 'utils/cookie_util'

import './mypage_layout.scss'

enum LabDrawerMenuId {
  ROOM,
  INSTRUMENT,
  BARCODE,
  MY_CALENDAR,
  PROOFREAD,
  REPAIR,
  EX_COLUMN,
  INSTRUMENT_RELATION,
  INQUIRY,
  MANAGER_LAB,
  SETTING,
}

enum LabDrawerSubMenuId {
  BARCODE_INDEX,
  BARCODE_UNPRINTED,
  PROOFREAD_PLANS,
  PROOFREAD_APPROVERS,
  PROOFREAD_INSTRUMENTS,
  REPAIR_DEFECT_REPORTS,
  REPAIR_ESTIMATES,
  REPAIR_EVENTS,
  LABORATORIES_INDEX,
  LABORATORIES_RESERVATIONS,
  INSTRUMENT_SHARING_IMPORT,
}

type Props = RouteComponentProps & {
  children?: ReactNode
  className?: string
}

const menuItemPathPatterns: MenuItemPathPattern[] = [
  { id: LabDrawerMenuId.ROOM, pathPattern: new RegExp('mypage/rooms', 'i') },
  { id: LabDrawerMenuId.INSTRUMENT, pathPattern: new RegExp('mypage/instruments', 'i') },
  { id: LabDrawerMenuId.BARCODE, pathPattern: new RegExp('mypage/barcodes*|mypage/unprinted_barcodes') },
  { id: LabDrawerMenuId.MY_CALENDAR, pathPattern: new RegExp('mypage/reservations', 'i') },
  { id: LabDrawerMenuId.PROOFREAD, pathPattern: new RegExp('mypage/proofread_*', 'i') },
  { id: LabDrawerMenuId.PROOFREAD, pathPattern: new RegExp('mypage/proofread_*', 'i') },
  { id: LabDrawerMenuId.REPAIR, pathPattern: new RegExp('mypage/defect_*|mypage/repair_*', 'i') },
  { id: LabDrawerMenuId.EX_COLUMN, pathPattern: new RegExp('mypage/ex_attributes') },
  { id: LabDrawerMenuId.INSTRUMENT_RELATION, pathPattern: new RegExp('mypage/import/instruments*') },
  { id: LabDrawerMenuId.INQUIRY, pathPattern: new RegExp('mypage/inquiries', 'i') },
  { id: LabDrawerMenuId.MANAGER_LAB, pathPattern: new RegExp('mypage/laboratories', 'i') },
  { id: LabDrawerMenuId.SETTING, pathPattern: new RegExp('mypage/setting', 'i') },
]

const subMmenuItemPathPatterns: MenuItemPathPattern[] = [
  { id: LabDrawerSubMenuId.BARCODE_INDEX, pathPattern: new RegExp('mypage/barcodes') },
  { id: LabDrawerSubMenuId.BARCODE_UNPRINTED, pathPattern: new RegExp('mypage/unprinted_barcodes') },
  { id: LabDrawerSubMenuId.PROOFREAD_PLANS, pathPattern: new RegExp('mypage/proofread_plans', 'i') },
  { id: LabDrawerSubMenuId.PROOFREAD_APPROVERS, pathPattern: new RegExp('mypage/proofread_approvals') },
  { id: LabDrawerSubMenuId.PROOFREAD_INSTRUMENTS, pathPattern: new RegExp('mypage/proofreadable_instruments') },
  { id: LabDrawerSubMenuId.REPAIR_DEFECT_REPORTS, pathPattern: new RegExp('mypage/defect_reports') },
  { id: LabDrawerSubMenuId.REPAIR_ESTIMATES, pathPattern: new RegExp('mypage/repair_estimates', 'i') },
  { id: LabDrawerSubMenuId.REPAIR_EVENTS, pathPattern: new RegExp('mypage/repair_events', 'i') },
  { id: LabDrawerSubMenuId.LABORATORIES_INDEX, pathPattern: new RegExp('mypage/laboratories') },
  { id: LabDrawerSubMenuId.INSTRUMENT_SHARING_IMPORT, pathPattern: new RegExp('mypage/import/instruments') },
  { id: LabDrawerSubMenuId.LABORATORIES_RESERVATIONS, pathPattern: new RegExp('mypage/laboratories/reservations') },
]

const MypageLayout: React.FC<Props> = ({ history, children, className }) => {
  const mypageState = useContext(MypageStateContext)
  const globalState = useContext(GlobalStateContext)
  const sidebarContext = useContext(SidebarContext)
  const currentUser = mypageState.currentUser

  React.useEffect(() => {
    const pathName = history.location.pathname
    const setMenuId = () => {
      menuItemPathPatterns.forEach((item) => {
        if (item.pathPattern?.test(pathName)) {
          sidebarContext.setActiveLabDrawerMenuId(item.id)
        }
      })
    }
    const setSubMenuId = () => {
      let isAnySubMenuActive = false
      subMmenuItemPathPatterns.forEach((item) => {
        if (item.pathPattern?.test(pathName)) {
          sidebarContext.setActiveLabDrawerSubMenuId(item.id)
          isAnySubMenuActive = true
        }
      })
      // URLがどのサブメニューに該当しない場合は初期化する
      if (!isAnySubMenuActive) {
        sidebarContext.setActiveLabDrawerSubMenuId(-1)
      }
    }

    setMenuId()
    setSubMenuId()
  }, [history.location.pathname])

  React.useEffect(() => {
    if (!currentUser?.laboratory) {
      return
    }

    if (!currentUser.laboratory.useEquipment) {
      globalState.setNotificationMessage({ title: 'エラー', body: '機器管理の利用許可がありません', colorType: 'error' })
      CookieManager.removeUserToken()
      history.replace('/')
    }
  }, [currentUser.laboratory?.useEquipment])

  const handleClickLogout = () => {
    CookieManager.removeUserToken()
    history.push(MypageRouteHelper.login())
  }

  const isMenuActive = (id: number) => id === sidebarContext.activeLabDrawerMenuId
  const isSubMenuActive = (id: number) => id === sidebarContext.activeLabDrawerSubMenuId

  return (
    <div className={`root mypage ${className}`}>
      <CssBaseline />
      <AppBar position="fixed" className="app-bar" color="default">
        <Toolbar>
          <Flex alignItems="center" justifyContent="flex-end" style={{ width: '100%' }}>
            <div>
              {mypageState.currentUser && mypageState.currentUser.givenName && (
                <>
                  <div>{mypageState.currentUser.laboratoryName}</div>
                  <div>{`${mypageState.currentUser.familyName} ${mypageState.currentUser.givenName}`}</div>
                </>
              )}
            </div>
            <Tooltip title="ログアウト" onClick={handleClickLogout}>
              <LogoutIcon />
            </Tooltip>
          </Flex>
        </Toolbar>
      </AppBar>
      <Sidebar>
        <SidebarMenuItem
          active={isMenuActive(LabDrawerMenuId.ROOM)}
          label="ルーム管理"
          icon={<RoomIcon />}
          onClick={() => history.push(MypageRouteHelper.roomIndex())}
        />
        <SidebarMenuItem
          active={isMenuActive(LabDrawerMenuId.INSTRUMENT)}
          label="機器管理"
          icon={<ListIcon />}
          onClick={() => history.push(MypageRouteHelper.instrumentIndex())}
        />
        {!currentUser.laboratory?.isManaged && (
          <SidebarMenuItem active={isMenuActive(LabDrawerMenuId.BARCODE)} label="QRコード" icon={<CropFreeIcon />}>
            <SidebarSubMenuItem
              active={isSubMenuActive(LabDrawerSubMenuId.BARCODE_INDEX)}
              label="QRコード一覧"
              onClick={() => history.push(MypageRouteHelper.barcodeIndex())}
            />
            <SidebarSubMenuItem
              active={isSubMenuActive(LabDrawerSubMenuId.BARCODE_UNPRINTED)}
              label="未印刷"
              onClick={() => history.push(MypageRouteHelper.unprintedBarcodeIndex())}
            />
          </SidebarMenuItem>
        )}
        <SidebarMenuItem
          active={isMenuActive(LabDrawerMenuId.MY_CALENDAR)}
          label="マイカレンダー"
          icon={<CalendarTodayIcon />}
          onClick={() => history.push(MypageRouteHelper.reservationIndex())}
        />
        {currentUser.authority?.proofread && (
          <SidebarMenuItem active={isMenuActive(LabDrawerMenuId.PROOFREAD)} label="校正管理" icon={<AssignmentTurnedInIcon />}>
            <SidebarSubMenuItem
              active={isSubMenuActive(LabDrawerSubMenuId.PROOFREAD_PLANS)}
              label="計画一覧"
              onClick={() => history.push(MypageRouteHelper.proofreadPlanIndex())}
            />
            <SidebarSubMenuItem
              active={isSubMenuActive(LabDrawerSubMenuId.PROOFREAD_APPROVERS)}
              label="承認申請一覧"
              onClick={() => history.push(MypageRouteHelper.proofreadApprovalIndex())}
            />
            <SidebarSubMenuItem
              active={isSubMenuActive(LabDrawerSubMenuId.PROOFREAD_INSTRUMENTS)}
              label="機器一覧"
              onClick={() => history.push(MypageRouteHelper.proofreadableInstrumentIndex())}
            />
          </SidebarMenuItem>
        )}
        {currentUser.authority?.repair && (
          <SidebarMenuItem active={isMenuActive(LabDrawerMenuId.REPAIR)} label="修理管理" icon={<BuildIcon />}>
            <SidebarSubMenuItem
              active={isSubMenuActive(LabDrawerSubMenuId.REPAIR_DEFECT_REPORTS)}
              label="不具合報告"
              onClick={() => history.push(MypageRouteHelper.defectReportIndex())}
            />
            <SidebarSubMenuItem
              active={isSubMenuActive(LabDrawerSubMenuId.REPAIR_ESTIMATES)}
              label="修理見積"
              onClick={() => history.push(MypageRouteHelper.repairEstimateIndex())}
            />
            <SidebarSubMenuItem
              active={isSubMenuActive(LabDrawerSubMenuId.REPAIR_EVENTS)}
              label="修理履歴"
              onClick={() => history.push(MypageRouteHelper.repairEventIndex())}
            />
          </SidebarMenuItem>
        )}
        {currentUser.laboratory?.authority?.enableInstrumentSharing && (
          <SidebarMenuItem active={isMenuActive(LabDrawerMenuId.INSTRUMENT_RELATION)} label="機器共有" icon={<ShareIcon />}>
            <SidebarSubMenuItem
              active={isSubMenuActive(LabDrawerSubMenuId.INSTRUMENT_SHARING_IMPORT)}
              label="機器インポート"
              onClick={() => history.push(MypageRouteHelper.instrumentImport())}
            />
          </SidebarMenuItem>
        )}
        <SidebarMenuItem
          active={isMenuActive(LabDrawerMenuId.EX_COLUMN)}
          label="管理情報"
          icon={<LocalOfferIcon />}
          onClick={() => history.push(MypageRouteHelper.exAttributeIndex())}
        />
        {currentUser.laboratory?.isManager && (
          <SidebarMenuItem active={isMenuActive(LabDrawerMenuId.MANAGER_LAB)} label="ラボ管理" icon={<DomainIcon />}>
            <SidebarSubMenuItem
              active={isSubMenuActive(LabDrawerSubMenuId.LABORATORIES_INDEX)}
              label="管理ラボ一覧"
              onClick={() => history.push(MypageRouteHelper.laboratoryIndex())}
            />
            <SidebarSubMenuItem
              active={isSubMenuActive(LabDrawerSubMenuId.LABORATORIES_RESERVATIONS)}
              label="すべての予約"
              onClick={() => history.push(MypageRouteHelper.assetReservationIndex())}
            />
          </SidebarMenuItem>
        )}
        {currentUser.laboratory?.authority?.enableInstrumentSharing && (
          <SidebarMenuItem
            active={isMenuActive(LabDrawerMenuId.INQUIRY)}
            label="問合せ"
            icon={<MailIcon />}
            onClick={() => history.push(MypageRouteHelper.inquiryIndex())}
          />
        )}
        <SidebarMenuItem
          active={isMenuActive(LabDrawerMenuId.SETTING)}
          label="設定"
          icon={<SettingsIcon />}
          onClick={() => history.push(MypageRouteHelper.settingShow())}
        />
        {(currentUser.laboratory?.usePurchase || currentUser.laboratory?.useStock) && (
          <SidebarMenuItem
            active={false}
            label={currentUser.laboratory?.usePurchase ? '購買管理ログイン' : '在庫管理ログイン'}
            icon={<LaunchIcon />}
            onClick={() => {
              if (currentUser.laboratory?.usePurchase) {
                // 購買管理を使用できる場合は購買管理に飛ばす
                window.open(`${process.env.REACT_APP_PURCHASE_HOST}/mypage`, '_blank')
              } else {
                // 購買管理を使用できない場合は在庫管理に飛ばす
                window.open(`${process.env.REACT_APP_PURCHASE_HOST}/mypage/inventory/dashboards`, '_blank')
              }
            }}
          />
        )}
      </Sidebar>
      <main className="content">
        <div className="toolbar" />
        {children}
      </main>
    </div>
  )
}

export default withRouter(MypageLayout)
