import { ProofreadSchedule } from 'entities/proofread_schedule'
import moment from 'moment'
import { ViewModel } from 'view_models'

export class ProofreadScheduleViewModel extends ViewModel<ProofreadSchedule> {
  public getSupplier = () => {
    return this.object.supplier?.name || '-'
  }

  public getDeliveredDate = () => {
    if (!this.object.deliveredDate) return '調整中'
    return moment(this.object.deliveredDate).format('YYYY/MM/DD')
  }

  public getReturnedDate = () => {
    if (!this.object.returnedDate) return '調整中'
    return moment(this.object.returnedDate).format('YYYY/MM/DD')
  }

  public getNote = () => {
    return this.object.note || 'なし'
  }

  public getInstrumentCount = () => {
    return this.object.targetInstrumentCount || '-'
  }

  // 本校正イベントに登録されている機器の担当者の名前を取得する
  // なおここで取得する担当者は現時点での機器の担当者であり、校正イベント登録時の担当者ではない
  public getManagers = () => {
    const managerNames: string[] = []
    this.object.proofreadEvents?.forEach((event) => {
      if (event.instrument?.managerName != null) {
        managerNames.push(event.instrument?.managerName)
      }
    })
    // 重複を削除した配列を返す
    return Array.from(new Set(managerNames));
  }
}
