import { Step, StepLabel, Stepper, StepProps } from '@mui/material'
import Modal, { ModalSize, ModalState } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import React, { ReactElement, useEffect, useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { makeStyles } from 'tss-react/mui'
import { useEffectSkipFirst } from 'utils/hooks'

export type ModalStep = {
  label: string
  body: ReactElement
  allowSkip?: boolean
  allowBack?: boolean
  onClickNext?: () => void
  onClickSkip?: () => void
  onClickBack?: () => void
  onCloseModal?: () => void
}

type StepperModalProps = {
  size: ModalSize
  modalState: ModalState
  title: string
  steps: ModalStep[]
  index?: number
  changeIndexManually?: boolean
  onCloseModal?: () => void
}

/**
 * StepperModal
 * @param props StepperModalProps
 * @param props.size モーダルのサイズ
 * @param props.modalState モーダルステートオブジェクト
 * @param props.title モーダルタイトル
 * @param props.steps ステップオブジェクトの配列
 * @param props.index 現在のステップのindex
 * @param props.changeIndexManually trueの場合，次へボタン，スキップボタン等をクリックしてもステップが変更されない．indexを使ってステップを手動で動かしたい場合に有効．
 */
export const StepperModal = (props: StepperModalProps) => {
  const [activeStep, setActiveStep] = useState(0)
  const [skipped, setSkipped] = React.useState(new Set<number>())
  const getActiveStep = () => props.steps[activeStep]
  const { classes } = useStyles()

  const isStepSkipped = (step: number) => {
    return skipped.has(step)
  }

  const handleNext = () => {
    const currentStep = getActiveStep()
    if (currentStep.onClickNext) {
      currentStep.onClickNext()
    }
    if (!props.changeIndexManually) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    const currentStep = getActiveStep()
    if (currentStep.onClickBack) {
      currentStep.onClickBack()
    }
    if (!props.changeIndexManually) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }
  }

  const handleSkip = () => {
    const currentStep = getActiveStep()
    if (currentStep.onClickSkip) {
      currentStep.onClickSkip()
    }
    if (!props.changeIndexManually) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values())
      newSkipped.add(activeStep)
      return newSkipped
    })
  }

  useEffect(() => {
    const index = props.index
    if (index != null) {
      setActiveStep(index)
    }
  }, [props.index])

  useEffectSkipFirst(() => {
    if (!props.modalState.open) {
      if (props.onCloseModal) {
        props.onCloseModal()
      }
      const currentStep = getActiveStep()
      if (currentStep.onCloseModal) {
        currentStep.onCloseModal()
      }
    }
  }, [props.modalState.open])

  return (
    <Modal
      size={props.size}
      modalState={props.modalState}
      modalId={'stepper-modal'}
      title={props.title}
      footer={
        <>
          <ThemedButton color="secondary" onClick={(_) => props.modalState.setOpen(false)}>
            キャンセル
          </ThemedButton>
          {activeStep !== props.steps.length && (
            <>
              {getActiveStep().allowBack && activeStep > 0 && (
                <ThemedButton color="secondary" onClick={handleBack}>
                  戻る
                </ThemedButton>
              )}
              {getActiveStep().allowSkip && activeStep >= 0 && activeStep !== props.steps.length && (
                <ThemedButton color="secondary" onClick={handleSkip}>
                  スキップ
                </ThemedButton>
              )}
              <ThemedButton color="success" onClick={handleNext}>
                {activeStep === props.steps.length - 1 ? '完了' : '次へ'}
              </ThemedButton>
            </>
          )}
        </>
      }
    >
      <div style={{ padding: '0 105x 0 15px', flexGrow: 1 }}>
        <Stepper className={classes.stepper} activeStep={activeStep}>
          {props.steps.map((step, index) => {
            const stepProps: StepProps = {}
            if (isStepSkipped(index)) {
              stepProps.completed = false
            }
            return (
              <Step key={step.label} {...stepProps}>
                <StepLabel
                  StepIconProps={{ classes: { text: classes.stepperIconText, completed: classes.stepperIconCompleted } }}
                  classes={{ label: classes.stepperLabel, iconContainer: classes.stepperIconContainer }}
                >
                  {step.label}
                </StepLabel>
              </Step>
            )
          })}
        </Stepper>
      </div>

      <div style={{ minHeight: '300px' }}>
        <SwipeableViews index={activeStep > props.steps.length - 1 ? props.steps.length - 1 : activeStep} disabled>
          {props.steps.map((step, idx) => (
            <div key={`stepper-body-${step.label}`} style={idx !== activeStep ? { display: 'none' } : {}}>
              {step.body}
            </div>
          ))}
        </SwipeableViews>
      </div>
    </Modal>
  )
}

const useStyles = makeStyles()({
  stepper: {
    padding: '5px 15px 5px 15px',
  },
  stepperLabel: {
    fontSize: 14,
  },
  stepperIconContainer: {
    transform: 'scale(1.4)',
  },
  stepperIconText: {
    fontSize: 11,
    fontWeight: 'bold',
  },
  stepperIconCompleted: {
    color: '#9e9e9e !important',
  },
})
