import { Administrator } from 'entities/administrator'
import React, { Dispatch, SetStateAction } from 'react'

type AdminStateContext = {
  currentAdmin: Administrator
  setCurrentAdmin: Dispatch<SetStateAction<Administrator>>
  reloadAdmin: () => void
}

export const initialAdminState: AdminStateContext = {
  currentAdmin: {},
  setCurrentAdmin: () => {},
  reloadAdmin: () => {},
}

export const AdminStateContext = React.createContext(initialAdminState)
