import { AxiosHttpClient } from 'api/axios'
import { useRepruaDeleteApi, useRepruaDownloadApi, useRepruaIndexApi, useRepruaPostApi } from 'api/shared_api_hooks'
import { InstrumentImportResult, InstrumentImportForm } from 'entities/instrument_import_result'
import { BaseResponse, Form, IndexApiSet } from 'rac'

type LaboratoryInstrumentImportResultsResponse = BaseResponse & {
  instrumentImportResults: InstrumentImportResult[]
}

type LaboratoryInstrumentImportResultsParams = {
  laboratoryId: number
}

/**
 * インポート結果の取得
 */
type AdminLaboratoryInstrumentImportResultsApiSet = IndexApiSet<LaboratoryInstrumentImportResultsResponse> & {
  execute: (id: number) => void
}
export const useAdminLaboratoryInstrumentImportResults = (): AdminLaboratoryInstrumentImportResultsApiSet => {
  const api = useRepruaIndexApi<LaboratoryInstrumentImportResultsResponse, LaboratoryInstrumentImportResultsParams>(
    new AxiosHttpClient('admin'),
    {
      initialResponse: { instrumentImportResults: [] },
    },
  )

  const execute = (id: number) => {
    const path = `laboratories/${id}/instrument_excel_import_results`
    api.execute(path)
  }

  return { ...api, execute }
}

type LaboratoryInstrumentImportResultResponse = BaseResponse & {
  instrumentImportResult: InstrumentImportResult
}

/**
 * 機器のエクセルインポート
 */
export const useAdminPostLaboratoryInstrumentImport = () => {
  const api = useRepruaPostApi<LaboratoryInstrumentImportResultResponse, InstrumentImportForm>(new AxiosHttpClient('admin'), {
    initialResponse: { instrumentImportResult: {} },
  })

  const execute = (instrumentForm: Form<InstrumentImportForm>, laboratoryId: number) => {
    const path = `laboratories/${laboratoryId}/import_instrument_excel`
    api.execute(path, instrumentForm)
  }

  return { ...api, execute }
}

/**
 * インポートの取り消し
 */
export const useAdminDeleteLaboratoryInstrumentImport = () => {
  const api = useRepruaDeleteApi<LaboratoryInstrumentImportResultResponse>(new AxiosHttpClient('admin'), {
    initialResponse: { instrumentImportResult: {} },
  })

  const execute = (instrumentImportResultId: number) => {
    const path = `instrument_excel_import_results/${instrumentImportResultId}`
    api.execute(path)
  }

  return { ...api, execute }
}

export function useAdminFetchLaboratoryInstrumentImportFormat(): { execute: (laboratoryId: number) => void } {
  const api = useRepruaDownloadApi<any>(new AxiosHttpClient('admin'))

  const execute = (laboratoryId: number) => {
    const path = `laboratories/${laboratoryId}/download_instrument_excel_import_format`
    api.execute(path)
  }

  return { execute }
}
