import { Typography } from '@mui/material'
import AttachmentsList from 'components/shared/data/attachments_list'
import DataBlock from 'components/shared/data/data_block'
import DataCard from 'components/shared/data/data_card'
import DataLabel from 'components/shared/data/data_label'
import { DropdownMenu, ListItem } from 'components/shared/dropdown_menu'
import { Flex } from 'components/shared/flex'
import ImageView from 'components/shared/image_view'
import { Instrument } from 'entities/instrument'
import React, { useEffect, useState } from 'react'
import { InstrumentViewModel } from 'view_models/instrument'

type InstrumentDataCardProps = {
  instrument: Instrument
  canEdit?: boolean
  onClickEdit?: () => void
  onClickMemo?: () => void
  onClickConstraintSet?: () => void
}
export const InstrumentDataCard: React.FC<InstrumentDataCardProps> = (props) => {
  const [vm, setVm] = useState(new InstrumentViewModel(props.instrument))
  const [listItems, setListItems] = useState<ListItem[]>([])

  useEffect(() => {
    setVm(new InstrumentViewModel(props.instrument))
    if (!props.canEdit) return
    const items: ListItem[] = []
    if (props.onClickEdit) {
      items.push({ text: '機器情報の編集', onClick: () => props.onClickEdit!() })
    }
    if (props.onClickMemo) {
      items.push({ text: 'メモの入力', onClick: () => props.onClickMemo!() })
    }
    if (props.onClickConstraintSet) {
      items.push({ text: '予約条件の設定', onClick: () => props.onClickConstraintSet!() })
    }
    setListItems(items)
  }, [props.instrument])

  return (
    <DataCard>
      <Flex alignItems="center" justifyContent="space-between">
        <Typography variant="h6">機器情報</Typography>
        {props.canEdit && <DropdownMenu id="instrument-edit" buttonText="編集" buttonColor="primary" listItems={listItems} />}
      </Flex>
      <DataBlock style={{ width: '100%' }}>
        <div style={{ width: '50%', marginRight: 10 }}>
          <ImageView style={{ width: '100%' }} maxWidth={500} maxHeight={200} src={props.instrument.instrumentBasicInfo?.imageUrl} />
        </div>
        <Flex flexDirection="column" style={{ width: '50%' }}>
          <DataBlock style={{ width: '100%' }}>
            <DataLabel label="機器名" value={props.instrument.instrumentBasicInfo?.name} />
          </DataBlock>
          <DataBlock style={{ width: '100%' }} noBorder>
            <DataLabel label="メーカー / 型番" value={`${props.instrument.instrumentBasicInfo?.maker} / ${vm.modelNumber()}`} />
          </DataBlock>
        </Flex>
      </DataBlock>
      <DataBlock>
        <DataLabel label="取得日" value={vm.purchasedAtText()} width="50%" />
        <DataLabel label="取得価額" value={vm.purchasedPriceText()} width="50%" />
      </DataBlock>
      <DataBlock>
        <DataLabel label="担当者" value={vm.getManagerName()} width="50%" />
        <DataLabel label="担当業者" value={vm.getDealerName()} width="50%" />
      </DataBlock>
      <DataBlock>
        <DataLabel label="管理番号" value={vm.manageNumber()} width="30%" />
        <DataLabel label="シリアルNo." value={vm.serialNumber()} width="30%" />
      </DataBlock>
      <DataBlock>
        <DataLabel label="外部リンク" value={vm.getLink()} />
      </DataBlock>
      <DataBlock>
        <AttachmentsList fileAttachments={props.instrument.instrumentBasicInfo?.fileAttachments || []} />
      </DataBlock>
      <DataBlock noBorder>
        <DataLabel label="備考" value={vm.getNote()} />
      </DataBlock>
    </DataCard>
  )
}

export default InstrumentDataCard
