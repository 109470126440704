import { useFetchAdministratorApi } from 'api/admin/administrators'
import { AdminStateContext } from 'contexts/admin_state_context'
import { GlobalStateContext } from 'contexts/global_state_context'
import React, { useState, useContext } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { AdminRouteHelper } from 'routes/admin'
import CookieManager from 'utils/cookie_util'
import { useEffectSkipFirst } from 'utils/hooks/use_effect_skip_first'

type Props = {
  children: React.ReactNode
}
const AdminAuth = ({ children }: Props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(CookieManager.hasAdminToken())
  const globalState = useContext(GlobalStateContext)
  const location = useLocation()
  const api = useFetchAdministratorApi()
  const adminState = useContext(AdminStateContext)

  React.useEffect(() => {
    api.execute()
  }, [])

  useEffectSkipFirst(() => {
    if (api.isSuccess()) {
      adminState.setCurrentAdmin(api.response.administrator)
    }
  }, [api.loading])

  useEffectSkipFirst(() => {
    if (globalState.statusCode === 401) {
      CookieManager.removeAdminToken()
      setIsAuthenticated(false)
    } else if (CookieManager.hasAdminToken()) {
      setIsAuthenticated(true)
    }
  }, [globalState.statusCode])

  return isAuthenticated ? <div>{children}</div> : <Redirect to={{ pathname: AdminRouteHelper.login(), state: { from: location } }} />
}

export default AdminAuth
