import { TableContainer, Paper, Table, TableRow, TableCell, Stack } from '@mui/material'
import {
  usePartnerBulkCreateDealerUserLaboratoryRelationsApi,
  usePartnerDeleteDealerUserLaboratoryRelationApi,
  usePartnerFetchDealerUserLaboratoryRelationsApi,
} from 'api/partner/dealer_users/dealer_user_laboratory_relations'
import { usePartnerFetchLaboratoriesApi } from 'api/partner/laboratories'
import { usePartnerFetchOutsideLaboratoriesApi } from 'api/partner/outside_laboratories'
import { Flex } from 'components/shared/flex'
import { AutocompleteMultipleCheckBoxField } from 'components/shared/form/input'
import Modal, { ModalSet } from 'components/shared/modal'
import StatusLabel from 'components/shared/status_label'
import { ExTableBody } from 'components/shared/table'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { PartnerStateContext } from 'contexts/partner_state_context'
import { DealerUser } from 'entities/dealer_user'
import { DealerUserLaboratoryRelation, DealerUserLaboratoryRelationForm } from 'entities/dealer_user_laboratory_relation'
import { useForm } from 'rac'
import React, { useContext, useEffect, useState } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'

type LaboratoryRelationFormDialogProps = {
  modalSet: ModalSet<DealerUser>
  onComplete?: () => void
}

export const LaboratoryRelationFormDialog: React.FC<LaboratoryRelationFormDialogProps> = (props: LaboratoryRelationFormDialogProps) => {
  const globalState = useContext(GlobalStateContext)
  const partnerState = useContext(PartnerStateContext)
  const outsidePostForm = useForm<DealerUserLaboratoryRelationForm>({
    manageableIds: [],
    manageableType: 'OutsideLaboratory',
  })
  const postForm = useForm<DealerUserLaboratoryRelationForm>({
    manageableIds: [],
    manageableType: 'Laboratory',
  })
  const postApi = usePartnerBulkCreateDealerUserLaboratoryRelationsApi()
  const deleteApi = usePartnerDeleteDealerUserLaboratoryRelationApi()
  const outsideLabApi = usePartnerFetchOutsideLaboratoriesApi({ fetchAll: true })
  const laboratoryApi = usePartnerFetchLaboratoriesApi({ fetchAll: true })

  const relationsApi = usePartnerFetchDealerUserLaboratoryRelationsApi()
  const [relations, setRelations] = useState<DealerUserLaboratoryRelation[]>([])

  const handleSubmit = (manageableType: 'Laboratory' | 'OutsideLaboratory') => {
    if (!props.modalSet.item.id) return
    if (manageableType === 'Laboratory') {
      postApi.execute(props.modalSet.item.id, postForm)
    } else {
      postApi.execute(props.modalSet.item.id, outsidePostForm)
    }
  }

  const handleDelete = (relationId: number) => {
    globalState.confirm('担当を解除します。よろしいですか？', (event) => {
      if (event === 'ok') {
        deleteApi.execute(props.modalSet.item.id!, relationId)
      }
    })
  }

  useEffect(() => {
    outsideLabApi.execute()
    laboratoryApi.execute()
  }, [])

  // 新しいユーザーのモーダルが開かれるたびに実行
  useEffectSkipFirst(() => {
    if (!props.modalSet.item.id) return
    relationsApi.execute(props.modalSet.item.id)
    postForm.resetForm()
  }, [props.modalSet.item.id])

  useEffectSkipFirst(() => {
    if (!relationsApi.isSuccess()) return
    setRelations(relationsApi.response.dealerUserLaboratoryRelations)
  }, [relationsApi.loading])

  useEffectSkipFirst(() => {
    if (postApi.isSuccess()) {
      props.modalSet.closeModal()
      if (props.onComplete) props.onComplete()
      props.modalSet.resetItem()
    }
  }, [postApi.loading])

  useEffectSkipFirst(() => {
    if (deleteApi.isSuccess()) {
      if (props.onComplete) props.onComplete()
      relationsApi.execute(props.modalSet.item.id!)
    }
  }, [deleteApi.loading])

  return (
    <Modal
      modalId="laboratory-relation-modal"
      title={`担当顧客設定 - ${props.modalSet.item.name}`}
      modalState={props.modalSet.modalState}
      size="sm"
      footer={
        <ThemedButton onClick={props.modalSet.closeModal} color="secondary">
          閉じる
        </ThemedButton>
      }
    >
      <Stack mb={4} gap={2}>
        <h3>新規担当顧客</h3>
        {partnerState.currentDealer.authority?.enableInstrumentSharing && (
          <Flex alignItems="center">
            <AutocompleteMultipleCheckBoxField
              form={postForm}
              attr="manageableIds"
              items={laboratoryApi.response.laboratories
                .filter(
                  // すでに担当のものは除外する
                  (lab) =>
                    !relations
                      .filter((rel) => rel.manageableType === 'Laboratory')
                      .map((rel) => rel.manageableId)
                      .includes(lab.id),
                )
                .map((lab) => ({ value: lab.id!, label: lab.name! }))}
              label="リプルア顧客"
              id="laboratory-autocomplete-checkbox"
              style={{ flexBasis: '100%' }}
            />
            <ThemedButton onClick={() => handleSubmit('Laboratory')} color="success">
              保存
            </ThemedButton>
          </Flex>
        )}
        <Flex alignItems="center">
          <AutocompleteMultipleCheckBoxField
            form={outsidePostForm}
            attr="manageableIds"
            items={outsideLabApi.response.outsideLaboratories
              .filter(
                // すでに担当のものは除外する
                (lab) =>
                  !relations
                    .filter((rel) => rel.manageableType === 'OutsideLaboratory')
                    .map((rel) => rel.manageableId)
                    .includes(lab.id),
              )
              .map((lab) => ({ value: lab.id!, label: lab.name! }))}
            label="外部顧客"
            id="outsidelab-autocomplete-checkbox"
            style={{ flexBasis: '100%' }}
          />
          <ThemedButton onClick={() => handleSubmit('OutsideLaboratory')} color="success">
            保存
          </ThemedButton>
        </Flex>
      </Stack>
      <Stack>
        <h3>現在の担当顧客</h3>
        <TableContainer component={Paper}>
          <Table>
            <ExTableBody loading={relationsApi.loading}>
              {relations.map((rel, idx) => (
                <TableRow key={idx}>
                  <TableCell>{rel.manageable?.name || '-'}</TableCell>
                  <TableCell>{rel.manageableType === 'OutsideLaboratory' && <StatusLabel text="外部顧客" color="gray" />}</TableCell>
                  <TableCell align="right">
                    <ThemedButton color="error" onClick={() => handleDelete(rel.id!)}>
                      解除
                    </ThemedButton>
                  </TableCell>
                </TableRow>
              ))}
            </ExTableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Modal>
  )
}
