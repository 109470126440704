import { Laboratory } from 'entities/laboratory'
import { Room } from 'entities/room'
import { ViewModel } from 'view_models'

export class RoomViewModel extends ViewModel<Room> {
  public proprietaryFlag(): string {
    return this.object.proprietaryFlag ? '専有' : 'ー'
  }

  public getNote(): string {
    if (this.object.note) {
      return this.object.note
    } else {
      return '-'
    }
  }

  public fixDeadline = (deadline: number): string => {
    if (deadline >= 24) {
      return `${deadline / 24} 日`
    } else {
      return `${deadline} 時間`
    }
  }

  public getCancelPolicy(): string {
    const cancelPolicy = this.object.reservationTerm?.cancelPolicy
    if (cancelPolicy) {
      return `${this.fixDeadline(cancelPolicy.deadline)}前までキャンセル可能`
    } else {
      return '-'
    }
  }

  public isRoomOwner(currentLaboratory: Laboratory): boolean {
    if (this.object.laboratoryId === currentLaboratory.id) {
      return true
    } else {
      return false
    }
  }
}
