import { IconButton, IconButtonProps } from '@mui/material'
import { ThemeColor } from 'config/theme'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

type ContainedIconButtonProps = IconButtonProps & {
  color: ThemeColor
}

export const ContainedIconButton = ({ color, children, ...rest }: ContainedIconButtonProps) => {
  const { classes } = useStyles({ color })

  return (
    <IconButton {...rest} classes={classes} size="large">
      {children}
    </IconButton>
  )
}

const useStyles = makeStyles<{ color: ThemeColor }>()((theme, { color }) => ({
  root: {
    backgroundColor: theme.palette[color].main,
    '&:hover': {
      backgroundColor: theme.palette[color].dark,
    },
  },
}))
