import { SelectData } from 'components/shared/form/input'
import { BaseEntity } from 'entities'
import { ApprovalResult } from 'entities/approval_result'
import { AttachmentAttributes } from 'entities/attachment_attributes'
import { DefectReport } from 'entities/defect_report'
import { ExAttribute } from 'entities/ex_attribute'
import { Instrument } from 'entities/instrument'
import { Laboratory } from 'entities/laboratory'
import { RepairApproval } from 'entities/repair_approval'
import { RepairEstimateAttachment } from 'entities/repair_estimate_attachment'
import { RepairEvent } from 'entities/repair_event'
import { SearchQuery } from 'rac'

export type RepairEstimateState = 'estimating' | 'estimated' | 'ordered'
export const deliveryTerms = {
  WITHIN_1DAY: 'within_1day',
  WITHIN_2DAY: 'within_2day',
  WITHIN_3DAY: 'within_3day',
  WITHIN_4DAY: 'within_4day',
  WITHIN_1WEEK: 'within_1week',
  WITHIN_2WEEK: 'within_2week',
  WITHIN_3WEEK: 'within_3week',
  WITHIN_4WEEK: 'within_4week',
  AFTER_1MONTH: 'after_1month',
  MEETING: 'meeting',
  UNKNOWN: 'unknown',
} as const
export const deliveryTermsJa = {
  WITHIN_1DAY: '1営業日',
  WITHIN_2DAY: '2営業日',
  WITHIN_3DAY: '3営業日',
  WITHIN_4DAY: '4営業日',
  WITHIN_1WEEK: '1週間',
  WITHIN_2WEEK: '2週間',
  WITHIN_3WEEK: '3週間',
  WITHIN_4WEEK: '4週間',
  AFTER_1MONTH: '1ヶ月以上',
  MEETING: '別途相談',
  UNKNOWN: '未定',
} as const
export type DeliveryTermKeys = keyof typeof deliveryTerms
export type DeliveryTerm = typeof deliveryTerms[DeliveryTermKeys]
export type DeliveryTermJa = typeof deliveryTermsJa[DeliveryTermKeys]

/**
 * 修理見積
 */
export type RepairEstimate = BaseEntity & {
  defectReportId?: number
  suppliableId?: number
  suppliableType?: string
  repairInfoId?: number
  userId?: number
  estimateNumber?: string
  subtotal?: number
  tax?: number
  total?: number
  deliveryTerm?: DeliveryTerm
  aasmState?: RepairEstimateState
  note?: string
  survey?: string

  fileAttachments?: RepairEstimateAttachment[]
  approvalResults?: ApprovalResult[]
  instrument?: Instrument
  defectReport?: DefectReport
  userName?: string
  supplierName?: string
  repairApproval?: RepairApproval
  repairEvent?: RepairEvent
  laboratory?: Laboratory
  skipApproval?: boolean
  approverRequest?: boolean
  sharedEstimate?: boolean // 親ラボが作成した見積かどうか
  tableExAttrs?: ExAttribute[]
}

export const getDeliveryTermSelectData = (): SelectData[] => {
  const data: SelectData[] = []
  Object.keys(deliveryTerms).forEach((key) => {
    data.push({
      value: deliveryTerms[key as DeliveryTermKeys],
      label: deliveryTermsJa[key as DeliveryTermKeys],
    })
  })

  return data
}

export type RepairEstimateForm = RepairEstimate & {
  attachmentsAttributes?: AttachmentAttributes[]
}

export type RepairEstimateSearchForm = {
  searchText?: string
}

export const getRepairEstimateSearchQuery = (searchText?: string): SearchQuery => ({
  suffix: 'Cont',
  searchText: searchText || '',
  attrs: [
    'note',
    'survey',
    'estimateNumber',
    ['repairInfoDefectReport', { attrs: ['point', 'content'] }],

    // FIXME: multiDBの不具合暫定対応
    // ['user', { attrs: ['familyName', 'givenName'] }],
    // ['suppliable', { attrs: ['name'], polymorphicType: 'Dealer' }],
    // ['suppliable', { attrs: ['name'], polymorphicType: 'OutsideDealer' }],
  ],
})
