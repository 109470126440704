import { AxiosHttpClient } from 'api/axios'
import { useRepruaIndexApi } from 'api/shared_api_hooks'
import { ID, PagingResponse } from 'entities'
import { Instrument } from 'entities/instrument'
import { ProofreadEvent } from 'entities/proofread_event'
import { IndexApiSet } from 'rac'
import { useEffectSkipFirst } from 'utils/hooks'

export type ProofreadEventsResponse = PagingResponse & {
  proofreadEvents: ProofreadEvent[]
  instrument: Instrument
}

/**
 * 一覧
 */
export type PartnerFetchInstrumentProofreadEventsApiSet = IndexApiSet<ProofreadEventsResponse> & { execute: () => void }
export function usePartnerFetchInstrumentProofreadEventsApi(instrumentId?: ID): PartnerFetchInstrumentProofreadEventsApiSet {
  const api = useRepruaIndexApi<ProofreadEventsResponse, {}>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { proofreadEvents: [], totalCount: 0, instrument: {} },
  })

  const execute = () => {
    const path = `instruments/${instrumentId}/proofread_events`
    api.execute(path)
  }

  useEffectSkipFirst(() => {
    execute()
  }, [api.pageSet.page, api.pageSet.perPage, api.orderSet.order, api.orderSet.orderBy])

  return { ...api, execute }
}
