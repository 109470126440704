import { Table, TableHead, TableRow, Paper, TableContainer } from '@mui/material'
import { useFetchInstrumentCategoriesApi } from 'api/mypage/instrument_categories'
import { useFetchInstrumentProofreadEventsApi } from 'api/mypage/instruments/proofread_events'
import { useFetchLaboratoriesApi } from 'api/mypage/laboratory'
import { useFetchProofreadableInstrumentsApi } from 'api/mypage/proofreadable_instrument'
import { ContentHeader, ContentTitle } from 'components/shared/content'
import { Flex } from 'components/shared/flex'
import ImageView from 'components/shared/image_view'
import { useModal, useMultipleModal } from 'components/shared/modal'
import Pagination from 'components/shared/pagination'
import { DetailSearchModal } from 'components/shared/proofreadable_instruments/detail_search_modal'
import { PlansModal } from 'components/shared/proofreadable_instruments/plans_modal'
import { SearchBar } from 'components/shared/search_bar'
import { ExTableCell, OrderTableCell, ExTableRow, ExTableBody } from 'components/shared/table'
import { DisplaySettingModal } from 'components/shared/table/display_setting'
import ThemedButton from 'components/shared/themed_button'
import { ID } from 'entities'
import { Instrument, ProofreadableInstrumentSearchForm } from 'entities/instrument'
import { useForm } from 'rac'
import React, { useEffect } from 'react'
import { StaticContext } from 'react-router'
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom'
import { MypageRouteHelper } from 'routes/mypage'
import { INDEX_TABLE_PADDING, getTableHeight } from 'utils/component_heights'
import { useDisplaySetting } from 'utils/hooks/index'
import { InstrumentViewModel } from 'view_models/instrument'
import { ProofreadInfoViewModel } from 'view_models/proofread_info'

import 'pages/mypage/proofreadable_instrument/index.scss'

const initialDisplaySettings = [{ attr: 'maker', name: 'メーカー', visible: true }]
const initialDetailSearch = {
  expiredAtFrom: '',
  expiredAtTo: '',
  showOnlyUnregister: false,
}

export type ProofreadableInstrumentIndexProps = {}

type Props = RouteComponentProps<{}, StaticContext, ProofreadableInstrumentIndexProps>

const ProofreadableInstrumentIndex: React.FC<Props> = (props: Props) => {
  const searchForm = useForm<ProofreadableInstrumentSearchForm>({ q: {}, ...initialDetailSearch })
  const proofreadableInstrumentIndexApi = useFetchProofreadableInstrumentsApi(searchForm)
  const displaySettingSet = useDisplaySetting(initialDisplaySettings, 'proofreadable-instrument-table')
  const detailSearchModalState = useModal()
  const plansModalState = useMultipleModal<Instrument>()
  const eventsIndexApi = useFetchInstrumentProofreadEventsApi(plansModalState.item?.id)
  const history = useHistory()

  useEffect(() => {
    proofreadableInstrumentIndexApi.execute()
  }, [])

  const handleSearch = () => {
    proofreadableInstrumentIndexApi.executeAndPageReset()
  }

  const handleDetailSearch = () => {
    proofreadableInstrumentIndexApi.executeAndPageReset()
  }

  const handleClickPlansButton = (instrument: Instrument) => {
    plansModalState.openModal(instrument)
  }

  const handleClickSearchDetailButton = () => {
    detailSearchModalState.setOpen(true)
  }

  const handleClickPlanDetail = (planId?: ID) => {
    if (planId) {
      history.push(MypageRouteHelper.proofreadPlanShow(planId))
    }
  }

  useEffect(() => {
    if (proofreadableInstrumentIndexApi.isSuccess()) {
      detailSearchModalState.setOpen(false)
    }
  }, [proofreadableInstrumentIndexApi.loading])

  return (
    <>
      <DetailSearchModal modalState={detailSearchModalState} onSubmit={handleDetailSearch} searchForm={searchForm} />
      <PlansModal
        modalState={plansModalState.modalState}
        instrument={plansModalState.item}
        onClickPlanDetail={handleClickPlanDetail}
        eventsIndexApi={eventsIndexApi}
      />
      <ContentHeader
        subHeader={
          <>
            <DisplaySettingModal displaySettingSet={displaySettingSet} />
          </>
        }
      >
        <ContentTitle title="校正管理 - 機器一覧" />
        <Flex alignItems="center">
          <ThemedButton onClick={handleClickSearchDetailButton} color="secondary">
            詳細検索
          </ThemedButton>
          <SearchBar
            form={searchForm}
            searchAttr="searchText"
            placeholder="メーカー・製品名・型番・シリアル番号・管理番号で検索"
            onSubmit={handleSearch}
          />
        </Flex>
      </ContentHeader>
      <Paper>
        <TableContainer style={{ maxHeight: getTableHeight(INDEX_TABLE_PADDING) }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <ExTableCell width={150}></ExTableCell>
                <OrderTableCell
                  minWidth={150}
                  orderAttr="maker"
                  orderSet={proofreadableInstrumentIndexApi.orderSet}
                  open={displaySettingSet.isVisible('maker')}
                >
                  メーカー
                </OrderTableCell>
                <OrderTableCell minWidth={200} orderAttr="name" orderSet={proofreadableInstrumentIndexApi.orderSet}>
                  型番 / シリアル番号 / 管理番号 / 製品名
                </OrderTableCell>
                <ExTableCell width={120} align="center">
                  校正基準月・最終校正月
                </ExTableCell>
                <ExTableCell width={100} align="center">
                  有効期限
                </ExTableCell>
                <ExTableCell width={100} align="center">
                  校正周期
                </ExTableCell>
                <ExTableCell width={140} align="center">
                  アラーム
                </ExTableCell>
                <ExTableCell width={100} align="center"></ExTableCell>
              </TableRow>
            </TableHead>
            <ExTableBody loading={proofreadableInstrumentIndexApi.loading}>
              {proofreadableInstrumentIndexApi.response?.instruments.map((instrument: Instrument) => {
                if (instrument.proofreadInfo) {
                  const instrumentVM = new InstrumentViewModel(instrument)
                  const proofreadInfoVM = new ProofreadInfoViewModel(instrument.proofreadInfo)

                  return (
                    <ExTableRow key={instrument.id}>
                      <ExTableCell width={100} align="center">
                        <ImageView maxHeight={100} maxWidth={100} src={instrument.instrumentBasicInfo?.imageUrl} />
                      </ExTableCell>
                      <ExTableCell width={150} open={displaySettingSet.isVisible('maker')}>
                        {instrumentVM.getMakerName()}
                      </ExTableCell>
                      <ExTableCell>{instrumentVM.getInstrumentBaseInfo()}</ExTableCell>
                      <ExTableCell width={120} align="center">
                        {proofreadInfoVM.getLastResultDate()}
                      </ExTableCell>
                      <ExTableCell width={100} align="center">
                        {proofreadInfoVM.getExpiredDate()}
                      </ExTableCell>
                      <ExTableCell width={100} align="center">
                        {proofreadInfoVM.getCycle()}
                      </ExTableCell>
                      <ExTableCell width={140} align="center">
                        <div>{proofreadInfoVM.getProofreadAlarmDate() || '-'}</div>
                        {proofreadInfoVM.getAlarmExceededLabel()}
                      </ExTableCell>
                      <ExTableCell width={100} align="center">
                        <Flex flexDirection="column">
                          <ThemedButton color="primary" onClick={() => handleClickPlansButton(instrument)}>
                            計画
                          </ThemedButton>
                        </Flex>
                      </ExTableCell>
                    </ExTableRow>
                  )
                } else {
                  return null
                }
              })}
            </ExTableBody>
          </Table>
        </TableContainer>
        <Pagination response={proofreadableInstrumentIndexApi.response} pageSet={proofreadableInstrumentIndexApi.pageSet} />
      </Paper>
    </>
  )
}

export default withRouter(ProofreadableInstrumentIndex)
