import { Paper } from '@mui/material'
import { usePartnerExportDefectReportsApi, usePartnerFetchDefectReportsApi } from 'api/partner/defect_report'
import { DefectReportTable } from 'components/partner/defect_report/defect_report_table'
import { ContentHeader, ContentTitle } from 'components/shared/content'
import { SearchBar } from 'components/shared/search_bar'
import ThemedButton from 'components/shared/themed_button'
import { DefectReportSearchForm } from 'entities/defect_report'
import { useForm } from 'rac'
import React, { useEffect } from 'react'
import { StaticContext } from 'react-router'
import { withRouter, RouteComponentProps } from 'react-router-dom'
export type DefectReportIndexProps = {}

type Props = RouteComponentProps<{}, StaticContext, DefectReportIndexProps>

export const DefectReportIndex: React.FC<Props> = (props: Props) => {
  const searchForm = useForm<DefectReportSearchForm>({})
  const api = usePartnerFetchDefectReportsApi(searchForm)
  const exportApi = usePartnerExportDefectReportsApi(searchForm)
  const handleSearch = () => {
    api.executeAndPageReset()
  }
  const handleClickExport = (format: 'xlsx') => {
    exportApi.execute(format)
  }

  useEffect(() => {
    api.execute()
  }, [])

  return (
    <>
      <ContentHeader
        subHeader={
          <>
            <ThemedButton color="secondary" onClick={() => handleClickExport('xlsx')} style={{ marginLeft: 10 }}>
              Excel出力
            </ThemedButton>
          </>
        }
      >
        <ContentTitle title="不具合報告一覧" />
        <SearchBar<DefectReportSearchForm>
          form={searchForm}
          searchAttr="searchText"
          placeholder="機器名称・不具合箇所・不具合内容で検索"
          onSubmit={handleSearch}
        />
      </ContentHeader>
      <Paper>
        <DefectReportTable indexApi={api} showInstrument />
      </Paper>
    </>
  )
}
export default withRouter(DefectReportIndex)
