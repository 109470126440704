import { useDeleteOutsideDealerApi, usePatchOutsideDealerApi, usePostOutsideDealerApi } from 'api/mypage/outside_dealer'
import { Flex } from 'components/shared/flex'
import { StringField } from 'components/shared/form/input'
import Modal, { useModal } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { OutsideDealer, OutsideDealerForm } from 'entities/outside_dealer'
import { ApiError, Form, useForm } from 'rac'
import React, { useEffect, useContext } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'

type DialogFormContentProps = {
  outsideDealerForm: Form<OutsideDealerForm>
  error: ApiError
}

const DialogFormContent: React.FC<DialogFormContentProps> = (props) => {
  const { outsideDealerForm, error } = props

  return (
    <>
      <Flex>
        <StringField label="名前" attr="name" form={outsideDealerForm} apiError={error} required />
      </Flex>
      <Flex>
        <StringField label="連絡先" attr="contactDetail" form={outsideDealerForm} apiError={error} />
      </Flex>
    </>
  )
}

//
// 登録
//
type NewOutsideDealerFormDialogProps = {
  onComplete: () => void
}

export const NewOutsideDealerFormDialog = (props: NewOutsideDealerFormDialogProps) => {
  const modalState = useModal()
  const api = usePostOutsideDealerApi()
  const outsideDealerForm = useForm<OutsideDealerForm>({ name: '' }, 'outsideDealer')
  const globalState = useContext(GlobalStateContext)

  useEffectSkipFirst(() => {
    globalState.setLoading(api.loading)

    if (api.isSuccess()) {
      modalState.setOpen(false)
      props.onComplete()
    }
  }, [api.loading])

  const handleOpen = () => {
    modalState.setOpen(true)
  }

  const handleClose = () => {
    modalState.setOpen(false)
  }

  const handleSubmit = () => {
    api.execute(outsideDealerForm)
  }

  return (
    <div>
      <ThemedButton color="primary" onClick={handleOpen}>
        新規登録
      </ThemedButton>

      <Modal
        size="sm"
        modalState={modalState}
        onClose={handleClose}
        modalId="new-outside-modal-form"
        title="外部業者登録"
        footer={
          <>
            <ThemedButton onClick={handleClose} color="secondary">
              閉じる
            </ThemedButton>
            <ThemedButton onClick={handleSubmit} color="success">
              登録
            </ThemedButton>
          </>
        }
      >
        <DialogFormContent outsideDealerForm={outsideDealerForm} error={api.apiError} />
      </Modal>
    </div>
  )
}

//
// 更新
//
type EditOutsideDealerFormDialogProps = {
  outsideDealer: OutsideDealer
  onComplete: () => void
}

export const EditOutsideDealerFormDialog = (props: EditOutsideDealerFormDialogProps) => {
  const { outsideDealer, onComplete } = props
  const api = usePatchOutsideDealerApi()
  const modalState = useModal()
  const outsideDealerForm = useForm<OutsideDealerForm>(outsideDealer, 'outsideDealer')
  const globalState = useContext(GlobalStateContext)

  useEffectSkipFirst(() => {
    globalState.setLoading(api.loading)

    if (api.isSuccess()) {
      modalState.setOpen(false)

      onComplete()
    }
  }, [api.loading])

  const handleOpen = () => {
    modalState.setOpen(true)
  }
  const handleClose = () => {
    modalState.setOpen(false)
  }

  const handleSubmit = () => {
    api.execute(outsideDealerForm.object)
  }

  return (
    <div>
      <ThemedButton onClick={handleOpen}>編集</ThemedButton>

      <Modal
        size="sm"
        modalState={modalState}
        onClose={handleClose}
        modalId="new-outside-modal-form"
        title="外部業者編集"
        footer={
          <>
            <ThemedButton onClick={handleClose} color="secondary">
              閉じる
            </ThemedButton>
            <ThemedButton onClick={handleSubmit} color="success">
              更新
            </ThemedButton>
          </>
        }
      >
        <DialogFormContent outsideDealerForm={outsideDealerForm} error={api.apiError} />
      </Modal>
    </div>
  )
}

//
// 削除
//
type DeleteOutsideDealerButton = {
  outsideDealer: OutsideDealer
  onComplete: () => void
}

export const DeleteOutsideDealerButton = (props: DeleteOutsideDealerButton) => {
  const { outsideDealer, onComplete } = props
  const api = useDeleteOutsideDealerApi()
  const globalState = useContext(GlobalStateContext)

  useEffect(() => {
    globalState.setLoading(api.loading)
  }, [api.loading])

  useEffectSkipFirst(() => {
    if (api.isSuccess()) {
      onComplete()
    }
  }, [api.loading])

  const handleDelete = () => {
    globalState.confirm('本当に削除しますか？', (e) => {
      if (e === 'ok') {
        api.execute(outsideDealer.id!)
      }
    })
  }

  return (
    <ThemedButton color="error" onClick={handleDelete}>
      削除
    </ThemedButton>
  )
}
