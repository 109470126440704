import { Table, TableHead, TableRow, TableCell, TableContainer } from '@mui/material'
import {
  useLaboratoryInstrumentImportResults,
  // useDeleteLaboratoryInstrumentImport,
  usePostLaboratoryInstrumentImportMutation,
} from 'api/mypage/instruments/import'
import { useFetchPartnersApi } from 'api/mypage/partner'
import { ContentHeader, ContentTitle } from 'components/shared/content'
import { Flex } from 'components/shared/flex'
import { RhfBaseTextField, RhfSelectField } from 'components/shared/form/rhf_input'
import Modal, { ModalState, useModal } from 'components/shared/modal'
import { SubscriptionPlanLimitCount } from 'components/shared/subscription_plan_limit_count'
import { ExTableBody } from 'components/shared/table'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { MypageStateContext } from 'contexts/mypage_state_context'
import { InstrumentImportError } from 'entities/instrument_import_error'
import React, { useEffect, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { withRouter } from 'react-router-dom'
import { getTableHeight, SHARING_IMPORT_TABLE_PADDING } from 'utils/component_heights'
import { useEffectSkipFirst } from 'utils/hooks'
import { InstrumentImportResultViewModel } from 'view_models/instrument_import_result'

/** 機器共有インポートの画面 */
const InstrumentImport = () => {
  /** 機器インポートのモーダルのステータス */
  const mypageState = useContext(MypageStateContext)
  const InstrumentImportModalState = useModal()
  const errorDetailModal = useModal()
  const [importErrors, setImportErrors] = useState<InstrumentImportError[]>([])
  const handleClickImportErrorDetailButton = (importErrors: InstrumentImportError[]) => {
    errorDetailModal.setOpen(true)
    setImportErrors(importErrors)
  }
  const indexApi = useLaboratoryInstrumentImportResults()
  // const deleteApi = useDeleteLaboratoryInstrumentImport()
  // const globalState = useContext(GlobalStateContext)

  const loading = indexApi.loading
  const instrumentImportResults = indexApi.response.instrumentImportResults

  const toggleShowModal = () => {
    InstrumentImportModalState.setOpen(!InstrumentImportModalState.open)
  }

  const handleComplete = () => {
    indexApi.execute()
    mypageState.reloadInstrumentTotalCount()
  }

  // TODO: 取り消し機能は一旦保留
  // const handleClickImportCancelButton = (instrumentImportResultId: number) => {
  //   globalState.confirm(
  //     '本当に取消しますか？（Excelファイルからインポートされた機器は削除されますが、作成された顧客マスタ、部署、機器カテゴリは削除されずに残ります。）',
  //     (event) => {
  //       if (event === 'ok') {
  //         deleteApi.execute(instrumentImportResultId)
  //       }
  //     },
  //   )
  // }

  // useEffectSkipFirst(() => {
  //   globalState.setLoading(deleteApi.loading)
  //   if (deleteApi.isSuccess()) {
  //     handleComplete()
  //   }
  // }, [deleteApi.loading])

  useEffect(() => {
    indexApi.execute()
  }, [])

  return (
    <>
      <ErrorDetailModal modalState={errorDetailModal} importErrors={importErrors} />
      <InstrumentImportModal modalState={InstrumentImportModalState} handleComplete={handleComplete} />
      <ContentHeader
        subHeader={
          <>
            <SubscriptionPlanLimitCount />
          </>
        }
      >
        <ContentTitle title="機器共有インポート">
          <Flex justifyContent="space-between" style={{ flexGrow: 1 }}>
            <ThemedButton onClick={toggleShowModal} color="success">
              Excelインポート
            </ThemedButton>
          </Flex>
        </ContentTitle>
      </ContentHeader>
      <TableContainer style={{ maxHeight: getTableHeight(SHARING_IMPORT_TABLE_PADDING) }}>
        <Table stickyHeader style={{ backgroundColor: '#ffffff' }}>
          <TableHead>
            <TableRow>
              <TableCell>ファイル名</TableCell>
              <TableCell width={80} align="center">
                総数
              </TableCell>
              <TableCell width={80} align="center">
                失敗数
              </TableCell>
              <TableCell width={150}>作成日</TableCell>
              <TableCell width={100} align="center">
                エラー
              </TableCell>
              {/* <TableCell width={80} /> */}
            </TableRow>
          </TableHead>
          <ExTableBody loading={loading} style={{ justifyContent: 'center', alignItems: 'center' }}>
            {instrumentImportResults?.map((result) => {
              const vm = new InstrumentImportResultViewModel(result)
              return (
                <TableRow key={result.id}>
                  <TableCell>{result.fileName}</TableCell>
                  <TableCell align="center">{result.total}</TableCell>
                  <TableCell align="center">{result.failedCount}</TableCell>
                  <TableCell>{vm.createdAt().format('Y/MM/DD HH:mm')}</TableCell>
                  <TableCell align="center">
                    {result.instrumentImportErrors && result.instrumentImportErrors.length > 0 && (
                      <ThemedButton color="primary" onClick={() => handleClickImportErrorDetailButton(result.instrumentImportErrors!)}>
                        詳細
                      </ThemedButton>
                    )}
                  </TableCell>
                  {/* <TableCell align="center">
                      <ThemedButton color="error" onClick={() => handleClickImportCancelButton(result.id!)}>
                        取消
                      </ThemedButton>
                    </TableCell> */}
                </TableRow>
              )
            })}
          </ExTableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default withRouter(InstrumentImport)

type ErrorDetailModalProps = {
  importErrors: InstrumentImportError[]
  modalState: ModalState
}

/** エラーの詳細モーダル */
const ErrorDetailModal = ({ modalState, importErrors }: ErrorDetailModalProps) => {
  return (
    <Modal
      size="lg"
      modalId="admin-dealer-user-instrument-import-error-detail"
      title="エラー詳細"
      modalState={modalState}
      footer={
        <Flex>
          <ThemedButton onClick={() => modalState.setOpen(false)} color="secondary">
            キャンセル
          </ThemedButton>
        </Flex>
      }
    >
      <TableContainer style={{ maxHeight: 600 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>行数</TableCell>
              <TableCell>エラー内容</TableCell>
            </TableRow>
          </TableHead>
          <ExTableBody loading={false} style={{ justifyContent: 'center', alignItems: 'center' }}>
            {importErrors.map((error) => {
              return (
                <TableRow key={error.id}>
                  <TableCell>{error.rowNumber}</TableCell>
                  <TableCell>{error.message && convertErrorMessage(error.message)}</TableCell>
                </TableRow>
              )
            })}
          </ExTableBody>
        </Table>
      </TableContainer>
    </Modal>
  )
}

const convertErrorMessage = (messages: string) => {
  return (
    <>
      {messages.split(',').map((message, index) => {
        return <p key={`error-message-${index}`}>{message}</p>
      })}
    </>
  )
}

type InstrumentImportModalProps = {
  modalState: ModalState
  handleComplete: () => void
}

type InstrumentImportFormType = {
  dealerId: string
  uploadFile?: any
}

/** 機器インポートのモーダル */
const InstrumentImportModal = ({ modalState, handleComplete }: InstrumentImportModalProps) => {
  const globalState = useContext(GlobalStateContext)

  const form = useForm<InstrumentImportFormType>({
    defaultValues: {
      dealerId: '',
      uploadFile: undefined,
    },
  })
  const postLaboratoryInstrumentImportMutation = usePostLaboratoryInstrumentImportMutation()
  const suppliersIndexApi = useFetchPartnersApi()
  const dealers = suppliersIndexApi.response?.dealers

  const handleSubmit = () => {
    const uploadFile = form.watch('uploadFile')
    if (uploadFile === undefined) {
      return
    }
    postLaboratoryInstrumentImportMutation.mutate({
      dealerId: form.watch('dealerId'),
      uploadFile: uploadFile,
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFile = (e: any) => {
    const file = e.target.files[0]
    form.setValue('uploadFile', file)
  }

  useEffect(() => {
    suppliersIndexApi.execute()
  }, [])

  useEffectSkipFirst(() => {
    globalState.setLoading(postLaboratoryInstrumentImportMutation.isLoading)
    if (postLaboratoryInstrumentImportMutation.isSuccess) {
      form.reset()
      handleComplete()
      modalState.setOpen(false)
    } else if (postLaboratoryInstrumentImportMutation.isError) {
      const errorMessages = postLaboratoryInstrumentImportMutation.error.response.data.messages
      globalState.setNotificationMessage({
        body: errorMessages?.length ? errorMessages[0] : 'エラーが発生しました。しばらく経ってからアクセスしてください。',
        colorType: 'error',
      })
    }
  }, [postLaboratoryInstrumentImportMutation.isLoading])

  return (
    <Modal
      size="lg"
      modalId="admin-dealer-user-instrument-import-error-detail"
      title="Excelインポート"
      modalState={modalState}
      footer={
        <Flex>
          <ThemedButton onClick={handleSubmit} color="success" disabled={!form.watch('uploadFile')}>
            アップロード
          </ThemedButton>
        </Flex>
      }
    >
      <div style={{ fontSize: 13, marginBottom: 10 }}>
        <p>パートナーから受け取ったExcelファイルに、部屋名を入力してください。</p>
        <p>部屋名が入力されていない場合は、登録されません。</p>
      </div>
      <RhfSelectField
        name="dealerId"
        control={form.control}
        items={dealers?.map((dealer) => ({ value: dealer.id!, label: dealer.name! }))}
        label="リプルアパートナー"
      />
      {/* Excelファイルの形式のファイルのみ入力できるように */}
      <RhfBaseTextField
        type="file"
        name="uploadFile"
        control={form.control}
        onChange={handleFile}
        label="ファイル"
        accept=".xlsx,.xlsm,.xlsb,xltx,.xltm,.xls,.xlt,.xls,.xml,.xlam,.xla,.xlw,.xlr"
      />
    </Modal>
  )
}
