import { Paper, Box } from '@mui/material'
import { usePatchUserApi } from 'api/mypage/users'
import { DataLabel } from 'components/shared/data/data_label'
import { Flex } from 'components/shared/flex'
import { StringField } from 'components/shared/form/input'
import ThemedButton from 'components/shared/themed_button'
import { MypageStateContext } from 'contexts/mypage_state_context'
import { UserForm } from 'entities/user'
import { useForm } from 'rac'
import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useEffectSkipFirst } from 'utils/hooks'

export const AccountSettingsForm = () => {
  const { classes } = useStyles()
  const mypageState = useContext(MypageStateContext)
  const currentUser = mypageState.currentUser
  const userForm = useForm<UserForm>({ division: currentUser.division, passwordConfirmation: '' }, 'user')
  const patchUser = usePatchUserApi(userForm)
  const [requirePasswordConfirmation, setRequirePasswordConfirmation] = useState(false)

  const handleClickUpdateAccountSetting = () => {
    patchUser.execute()
  }

  useEffect(() => {
    setRequirePasswordConfirmation(Boolean(userForm.object.password))
  }, [userForm])

  useEffectSkipFirst(() => {
    if (patchUser.isSuccess()) {
      // 入力をクリアする
      userForm.update((f) => {
        f.passwordCurrent = ''
        f.passwordConfirmation = ''
        f.password = ''
      })
    }
  }, [patchUser.loading])

  return (
    <Box>
      <Paper className={classes.root}>
        <div className={classes.boxTitle}>
          <label>アカウント情報</label>
        </div>
        <Flex className={classes.stringFieldGroup}>
          <DataLabel label="姓" value={currentUser.familyName} />
          <DataLabel label="名" value={currentUser.givenName} />
        </Flex>

        <Flex className={classes.stringFieldGroup}>
          <DataLabel label="メールアドレス" value={currentUser.email} />
        </Flex>
        <Flex className={classes.stringFieldGroup}>
          <DataLabel label="組織・会社名" value={currentUser.laboratoryName} />
          <StringField label="講座・部署名" form={userForm} attr="division" apiError={patchUser.apiError} />
        </Flex>
        <div className={classes.boxTitle}>
          <label>パスワードの変更</label>
        </div>
        <Flex className={classes.stringFieldGroup}>
          <StringField
            label="現在のパスワード"
            form={userForm}
            attr="passwordCurrent"
            placeholder="パスワードを変更する時のみご入力ください"
            type="password"
            apiError={patchUser.apiError}
          />
        </Flex>
        <Flex className={classes.stringFieldGroup}>
          <StringField
            label="新しいパスワード"
            form={userForm}
            attr="password"
            placeholder="8文字以上のパスワードをご入力ください"
            type="password"
            apiError={patchUser.apiError}
          />
        </Flex>
        <Flex className={classes.stringFieldGroup}>
          <StringField
            label="新しいパスワード（確認）"
            form={userForm}
            attr="passwordConfirmation"
            placeholder="確認のため同じパスワードをご入力ください"
            type="password"
            required={requirePasswordConfirmation}
            apiError={patchUser.apiError}
          />
        </Flex>
        <Flex justifyContent="flex-end" style={{ padding: 10 }}>
          <ThemedButton color="success" onClick={handleClickUpdateAccountSetting}>
            保存
          </ThemedButton>
        </Flex>
      </Paper>
    </Box>
  )
}

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    maxWidth: 600,
  },
  stringFieldGroup: {
    height: 70,
    margin: '0 20px 0 20px',
  },
  boxTitle: {
    paddingTop: 20,
    marginLeft: 20,
    marginBottom: 20,
  },
}))
