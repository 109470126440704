import { TableContainer, Table, TableHead, TableRow } from '@mui/material'
import { useFetchInstrumentsApi } from 'api/mypage/instrument'
import { useBulkCreateEventsApi } from 'api/mypage/proofread_event'
import { useFetchRoomsApi } from 'api/mypage/room'
import { Flex } from 'components/shared/flex'
import { SelectField } from 'components/shared/form/input'
import ImageView from 'components/shared/image_view'
import Modal, { ModalState } from 'components/shared/modal'
import Pagination from 'components/shared/pagination'
import { SearchBar } from 'components/shared/search_bar'
import { ExTableCell, ExTableBody, ExTableRow, AllCheckboxTableCell, CheckboxTableCell } from 'components/shared/table'
import ThemedButton from 'components/shared/themed_button'
import { Instrument, InstrumentSearchForm } from 'entities/instrument'
import { ProofreadEventForm } from 'entities/proofread_event'
import { Room } from 'entities/room'
import { useForm } from 'rac'
import React, { useEffect } from 'react'
import { useCheckboxes, useEffectSkipFirst } from 'utils/hooks'
import { InstrumentViewModel } from 'view_models/instrument'
import { ProofreadInfoViewModel } from 'view_models/proofread_info'

type CreateEventModalProps = {
  modalState: ModalState
  planId: number
  onComplete: () => void
}
export const CreateEventModal: React.FC<CreateEventModalProps> = (props) => {
  const searchForm = useForm<InstrumentSearchForm>({ q: { instrumentRoomIdEq: undefined }, proofreadPlanId: props.planId })
  const postForm = useForm<ProofreadEventForm>({ proofreadPlanId: props.planId })

  const roomsIndexApi = useFetchRoomsApi({ perPage: 1000 })
  const instrumentsIndexApi = useFetchInstrumentsApi(searchForm)
  const bulkCreateApi = useBulkCreateEventsApi(postForm)

  const checkboxSet = useCheckboxes(instrumentsIndexApi.response.instruments)

  const handleSearch = () => {
    instrumentsIndexApi.executeAndPageReset()
  }

  useEffect(() => {
    instrumentsIndexApi.execute()
    roomsIndexApi.execute()
  }, [])

  useEffectSkipFirst(() => {
    postForm.newUpdateObject('instrumentIds', checkboxSet.checkedIds)
  }, [checkboxSet.checkedIds])

  useEffectSkipFirst(() => {
    if (!bulkCreateApi.isSuccess()) return
    props.onComplete()
    checkboxSet.clear()
    instrumentsIndexApi.execute()
  }, [bulkCreateApi.loading])

  return (
    <Modal
      title="校正計画に機器を追加する"
      modalId="create-proofread-event-modal"
      modalState={props.modalState}
      size="xl"
      footer={
        <>
          <p style={{ marginRight: 12 }}>
            <span style={{ fontSize: 24, fontWeight: 'bold' }}>{checkboxSet.checkedIds.length}</span> 件選択
          </p>
          <ThemedButton color="primary" disabled={checkboxSet.checkedIds.length === 0} onClick={bulkCreateApi.execute}>
            登録
          </ThemedButton>
        </>
      }
    >
      <Flex alignItems="center" justifyContent="flex-end">
        <SelectField
          label="部屋"
          width={200}
          form={searchForm}
          attr={['q', 'instrumentRoomIdEq']}
          labelId="search-select-room"
          data={roomsIndexApi.response?.rooms.map((room: Room) => {
            return { value: room.id!, label: room.name! }
          })}
          includeBlank
        />
        <SearchBar<InstrumentSearchForm>
          form={searchForm}
          searchAttr="searchText"
          placeholder="メーカー・製品名・型番・シリアル番号・管理番号で検索"
          onSubmit={handleSearch}
        />
      </Flex>
      <div style={{ padding: 10, backgroundColor: '#FFF2CC', margin: 10 }}>
        校正管理対象の機器で、現校正計画に未登録の機器のみ表示されます
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <AllCheckboxTableCell checkboxSet={checkboxSet} />
              <ExTableCell colSpan={2} align="center">
                製品名
              </ExTableCell>
              <ExTableCell align="center">最終校正日</ExTableCell>
              <ExTableCell align="center">有効期限</ExTableCell>
              <ExTableCell align="center">校正周期</ExTableCell>
            </TableRow>
          </TableHead>
          <ExTableBody loading={instrumentsIndexApi.loading}>
            {instrumentsIndexApi.response.instruments.map((instrument: Instrument) => {
              const vm = new InstrumentViewModel(instrument)
              const prInfoVm = new ProofreadInfoViewModel(instrument.proofreadInfo || {})
              return (
                <ExTableRow key={instrument.id}>
                  <CheckboxTableCell checkboxesSet={checkboxSet} checkboxId={instrument.id!} />
                  <ExTableCell width={100}>
                    <ImageView maxHeight={100} maxWidth={100} src={vm.getImageUrl()} />
                  </ExTableCell>
                  <ExTableCell>
                    <span style={{ fontSize: 12 }}>{vm.getInstrumentNumbers()}</span>
                    <div style={{ fontSize: 20 }}>{vm.getInstrumentName()}</div>
                  </ExTableCell>
                  {instrument.requireProofread ? (
                    <>
                      <ExTableCell align="center">{prInfoVm.getLastResultDate()}</ExTableCell>
                      <ExTableCell align="center">{prInfoVm.getExpiredDate()}</ExTableCell>
                      <ExTableCell align="center">{prInfoVm.getCycle()}</ExTableCell>
                    </>
                  ) : (
                    <ExTableCell align="center" colSpan={3}>
                      校正管理対象外です
                    </ExTableCell>
                  )}
                </ExTableRow>
              )
            })}
          </ExTableBody>
        </Table>
        <Pagination response={instrumentsIndexApi.response} pageSet={instrumentsIndexApi.pageSet} />
      </TableContainer>
    </Modal>
  )
}

export default CreateEventModal
