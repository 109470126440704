import React from 'react'
import { makeStyles } from 'tss-react/mui'

import styles from '../../styles/style.module.scss'

type FooterProps = {
  content: JSX.Element
}
export const Footer: React.FC<FooterProps> = (props) => {
  const { classes } = useStyle()
  return (
    <div className={classes.root}>
      <div className={classes.inner}>{props.content}</div>
    </div>
  )
}

export default Footer

const useStyle = makeStyles()(() => ({
  root: {
    position: 'fixed',
    left: styles.drawerWidth,
    bottom: 0,
    width: '100%',
    height: 70,
    backgroundColor: '#fff',
    borderTop: '2px solid #ccc',
  },
  inner: {
    display: 'flex',
    padding: 16,
  },
}))
