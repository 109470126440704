import { ID, BaseEntity } from 'entities'
import { AttachmentAttributes } from 'entities/attachment_attributes'
import { Dealer } from 'entities/dealer'
import { DealerDivision } from 'entities/dealer_division'
import { ExAttribute } from 'entities/ex_attribute'
import { InstrumentAlarmAttribute, InstrumentAlarmAttributeForm } from 'entities/instrument_alarm_attribute'
import { InstrumentBasicInfo, InstrumentBasicInfoForm } from 'entities/instrument_basic_info'
import { InstrumentCategory } from 'entities/instrument_category'
import { InstrumentInfoAttribute } from 'entities/instrument_info_attribute'
import { Laboratory } from 'entities/laboratory'
import { OutsideLaboratory } from 'entities/outside_laboratory'
import { ProofreadAlarm, ProofreadAlarmForm } from 'entities/proofread_alarm'
import { ProofreadInfo, ProofreadInfoForm } from 'entities/proofread_info'
import { ReservationTerm, ReservationTermForm } from 'entities/reservation_term'
import { SearchQuery } from 'rac'


/**
 * 機器
 */
export type InstrumentOwnerableType = 'Laboratory' | 'OutsideLaboratory'
export type TableExAttr = { inputType: string; name: string; value: string }

export type Instrument = BaseEntity & {
  // instruments テーブルのカラム
  ownerableId?: number
  ownerableType?: InstrumentOwnerableType
  roomId?: number
  managerId?: number
  approvalSettingId?: number
  outsideDealerId?: number
  canReserve?: boolean
  requireProofread?: boolean
  aasmState?: 'managed' | 'unmanaged' | 'discarded'
  sharingState?: 'waiting' | 'sharing' | 'non_shared' | 'outside'

  // build_json に merge される項目
  instrumentInfoAttribute?: InstrumentInfoAttribute
  instrumentBasicInfo?: InstrumentBasicInfo
  labBasicInfo?: InstrumentBasicInfo
  instrumentAlarms?: InstrumentAlarmAttribute[]
  reservationTerm?: ReservationTerm
  currentState?: CurrentState
  managerName?: string
  roomName?: string
  dealerName?: string
  dealer?: Dealer
  outsideDealerContactDetail?: string
  proofreadInfo?: ProofreadInfo
  proofreadAlarm?: ProofreadAlarm
  isLastProofreadNg?: boolean
  exAttributes?: ExAttribute[]
  tableExAttrs?: ExAttribute[]
  dealerExAttrs?: ExAttribute[]
  isShared?: boolean

  // supplier_build_json に merge される項目
  ownerable?: Laboratory | OutsideLaboratory
  dealerDivisions?: DealerDivision[]

  // 削除予定
  barcode?: string
}

export type CurrentState = 'use' | 'usable' | 'proofread' | 'repair'

export const currentStateTexts: { [P in CurrentState]: string } = {
  use: '利用中',
  proofread: '校正中',
  repair: '修理中',
  usable: '利用可能',
}

export const currentStateColors: { [P in CurrentState]: string } = {
  use: '#1976d2',
  proofread: '#00796b',
  repair: '#d32f2f',
  usable: '#4caf50',
}

export type InstrumentForm = Instrument & {
  instrumentBasicInfoAttributes?: InstrumentBasicInfoForm
  instrumentAlarmsAttributes?: InstrumentAlarmAttributeForm[]
  reservationTermAttributes?: ReservationTermForm
  proofreadInfoAttributes: ProofreadInfoForm
  imageAttributes?: AttachmentAttributes
  attachmentsAttributes?: AttachmentAttributes[]
  alarmEnable?: boolean
  proofreadAlarmAttributes?: ProofreadAlarmForm
}

export type BulkUpdateInstrumentStateForm = {
  instrumentIds: ID[]
}

export type PrintInstrumentBarcodeForm = {
  ids: string[]
}

export type InstrumentSearchForm = {
  q: {
    canReserveTrue?: boolean
    instrumentRoomIdEq?: number
  }
  // 「未選択」を含んだフィルタリングはransackではできない
  outsideLaboratoryIds?: string[]
  dealerDivisionIds?: string[]
  proofreadPlanId?: number
  searchText?: string
  unprinted?: boolean
  ids?: (string | number)[]
  canReserveFilter?: string[]
  requireProofreadFilter?: string[]
  sharingInstrumentFilter?: string[]
}

export type ProofreadableInstrumentSearchForm = InstrumentSearchForm & {
  expiredAtFrom: string
  expiredAtTo: string
  showOnlyUnregister: boolean
}

// 機器を検索する際、 InstrumentInfoAttribute を起点にして検索する必要があるため
export const getInstrumentSearchQuery = (searchText?: string): SearchQuery => ({
  suffix: 'Cont',
  searchText: searchText || '',
  attrs: [
    [
      'instrumentBasicInfo',
      {
        attrs: ['name', 'maker', 'serialNumber', 'manageNumber', 'modelNumber', 'note', ['instrumentCategory', { attrs: ['name'] }]],
      },
    ],
    ['exAttributeValues', { attrs: ['value'] }],
    [
      'instrument',
      {
        attrs: [
          [
            'ownerable',
            {
              attrs: ['name', ['dealerDivisions', { attrs: ['name'] }]],
              polymorphicType: 'OutsideLaboratory',
            },
          ],
        ],
      },
    ],
    // managerはlabnavi_dev.usersテーブルが存在しないというエラーが発生するためコメントアウト
    // ransackの問題の可能性もあり，すぐに解決ができない状態．将来ElasticSearchなどを導入することで全件検索を達成したい
    // ['manager', { attrs: ['familyName', 'givenName'] }],
  ],
})

export const isOwnerableOutsideLaboratory = (instrument: Instrument, ownerable: unknown): ownerable is OutsideLaboratory => {
  return Boolean(ownerable) && instrument.ownerableType === 'OutsideLaboratory'
}

export const cantEditProofreadInfoMessage = '校正履歴が存在するため編集することができません'

export type InstrumentFilter = {
  makers: string[]
  modelNumbers: string[]
  manageNumbers: string[]
  instrumentCategoryFilter: {
    includeNil: boolean
    instrumentCategories: InstrumentCategory[]
  }
}

export const initialInstrumentFilter = {
  makers: [],
  modelNumbers: [],
  manageNumbers: [],
  instrumentCategoryFilter: {
    includeNil: false,
    instrumentCategories: [],
  },
}
