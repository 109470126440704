import { CssBaseline, ThemeProvider, Theme, StyledEngineProvider } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import theme from 'config/theme'
import AdminStateContainer from 'containers/admin_state_container'
import GlobalStateContainer from 'containers/global_state_container'
import MypageStateContainer from 'containers/mypage_state_container'
import PartnerStateContainer from 'containers/partner_state_container'
import SidebarContainer from 'containers/sidebar_container'
import ja from 'date-fns/locale/ja'
import React from 'react'
import { Helmet } from 'react-helmet'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter, Route } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import AdminRoutes from 'routes/admin'
import MypageRoutes from 'routes/mypage'
import PartnerRoutes from 'routes/partner'
import StaticPageRoutes from 'routes/static_page'
import '@mui/styles'
import './App.scss'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const appTitle = 'リプルア機器管理'

function App() {
  const queryClient = new QueryClient()

  return (
    <StyledEngineProvider injectFirst>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Helmet title={`${appTitle}`} />
          <RecoilRoot>
            <GlobalStateContainer>
              <SidebarContainer>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
                  <BrowserRouter>
                    <MypageStateContainer>
                      <Route component={MypageRoutes} />
                    </MypageStateContainer>
                    <AdminStateContainer>
                      <Route component={AdminRoutes} />
                    </AdminStateContainer>
                    <PartnerStateContainer>
                      <Route component={PartnerRoutes} />
                    </PartnerStateContainer>
                    <Route component={StaticPageRoutes} />
                  </BrowserRouter>
                </LocalizationProvider>
              </SidebarContainer>
            </GlobalStateContainer>
          </RecoilRoot>
        </ThemeProvider>
      </QueryClientProvider>
    </StyledEngineProvider>
  )
}

export default App
