import { FetchProofreadEventsApi } from 'api/mypage/proofread_plans/proofread_event'
import { PartnerFetchProofreadEventsApi } from 'api/partner/proofread_plans/proofread_event'
import { DropDownCheckboxItem } from 'components/shared/form/input'
import { InstrumentFilter } from 'entities/instrument'
import { ProofreadEventSearchForm } from 'entities/proofread_event'
import { Form } from 'rac'

export type ProofreadEventFilterSet = {
  searchForm: Form<ProofreadEventSearchForm>
  handleSearch: () => void
  makersOptions: () => DropDownCheckboxItem[]
  modelNumbersOptions: () => DropDownCheckboxItem[]
  manageNumbersOptions: () => DropDownCheckboxItem[]
  instrumentCategoryNamesOptions: () => DropDownCheckboxItem[]
}

export const useProofreadEventFilter = (
  filter: InstrumentFilter,
  searchForm: Form<ProofreadEventSearchForm>,
  indexApi: FetchProofreadEventsApi | PartnerFetchProofreadEventsApi,
): ProofreadEventFilterSet => {
  const handleSearch = () => {
    indexApi.executeAndPageReset()
  }

  const makersOptions = () => {
    const options: DropDownCheckboxItem[] = filter.makers.map((maker) => {
      return { value: maker, label: maker }
    })

    return options
  }

  const modelNumbersOptions = () => {
    const options: DropDownCheckboxItem[] = filter.modelNumbers.map((modelNumber) => {
      return { value: modelNumber, label: modelNumber }
    })

    return options
  }

  const manageNumbersOptions = () => {
    const options: DropDownCheckboxItem[] = filter.manageNumbers.map((manageNumber) => {
      if (manageNumber.length === 0) {
        return { value: '', label: '未指定' }
      } else {
        return { value: manageNumber, label: manageNumber }
      }
    })

    return options
  }

  const instrumentCategoryNamesOptions = () => {
    const instrumentCategories = filter.instrumentCategoryFilter.instrumentCategories
    const options: DropDownCheckboxItem[] = instrumentCategories.map((category) => {
      return { value: String(category.id!), label: category.name! }
    })

    if (filter.instrumentCategoryFilter.includeNil) {
      options.unshift({ value: '', label: '未設定' })
    }

    return options
  }

  return {
    searchForm,
    handleSearch,
    makersOptions,
    modelNumbersOptions,
    manageNumbersOptions,
    instrumentCategoryNamesOptions,
  }
}
