import { useCurrentDealerUserQuery } from 'api/partner/dealer_users'
import { usePartnerFetchLaboratoriesApi } from 'api/partner/laboratories'
import { usePartnerFetchOutsideLaboratoriesApi } from 'api/partner/outside_laboratories'
import { PartnerStateContext } from 'contexts/partner_state_context'
import { Dealer } from 'entities/dealer'
import { DealerUser } from 'entities/dealer_user'
import { Laboratory } from 'entities/laboratory'
import { OutsideLaboratory } from 'entities/outside_laboratory'
import React, { useState } from 'react'

export type SelectableOwners = { laboratories: Laboratory[]; outsideLaboratories: OutsideLaboratory[] }
type PartnerStateContainerProps = {
  children: React.ReactNode
}
const PartnerStateContainer = ({ children }: PartnerStateContainerProps) => {
  const [currentDealer, setCurrentDealer] = useState<Dealer>({})
  const [currentDealerUser, setCurrentDealerUser] = useState<DealerUser>({})
  const [selectableOwners, setSelectableOwners] = useState<SelectableOwners>({ laboratories: [], outsideLaboratories: [] })
  const currentDealerUserQuery = useCurrentDealerUserQuery()
  const selectableOutsideLabApi = usePartnerFetchOutsideLaboratoriesApi({})
  const selectableLaboratoryApi = usePartnerFetchLaboratoriesApi({})

  const reloadUser = () => {
    currentDealerUserQuery.refetch()
  }

  const reloadSelectableOwners = () => {
    selectableLaboratoryApi.execute()
    selectableOutsideLabApi.execute()
  }

  return (
    <PartnerStateContext.Provider
      value={{
        currentDealer: currentDealer,
        currentDealerUser: currentDealerUser,
        setCurrentDealer: setCurrentDealer,
        setCurrentDealerUser: setCurrentDealerUser,
        reloadUser: reloadUser,
        selectableOwners: selectableOwners,
        setSelectableOwners: setSelectableOwners,
        reloadSelectableOwners: reloadSelectableOwners,
      }}
    >
      {currentDealerUser && children}
    </PartnerStateContext.Provider>
  )
}

export default PartnerStateContainer
