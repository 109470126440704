import { ErrorBoundary } from '@sentry/react'
import AdminLayout from 'components/admin/admin_layout'
import { FrontendErrorFallback } from 'components/shared/frontend_error_fallback'
import Auth from 'containers/admin_auth'
import AuthorityStateContainer from 'containers/authority_state_container'
import DealerIndex from 'pages/admin/dealer'
import DealerShow from 'pages/admin/dealer/show'
import LaboratoryIndex from 'pages/admin/laboratory'
import LaboratoryShow from 'pages/admin/laboratory/show'
import LoginPage from 'pages/admin/login'
import React from 'react'
import { Switch, Route } from 'react-router-dom'

const AdminRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path={AdminRouteHelper.root()}>
        <LoginPage />
      </Route>
      <Route exact path={AdminRouteHelper.login()}>
        <LoginPage />
      </Route>
      <Route path="/admin">
        <Auth>
          <AdminLayout>
            <ErrorBoundary fallback={FrontendErrorFallback}>
              <AuthorityStateContainer>
                <Switch>
                  {/* ラボラトリー */}
                  <Route exact path={AdminRouteHelper.laboratoryIndex()}>
                    <LaboratoryIndex />
                  </Route>
                  <Route exact path={AdminRouteHelper.laboratoryShow(':id')}>
                    <LaboratoryShow />
                  </Route>
                  {/* ディーラー */}
                  <Route exact path={AdminRouteHelper.dealerIndex()}>
                    <DealerIndex />
                  </Route>
                  <Route exact path={AdminRouteHelper.dealerShow(':id')}>
                    <DealerShow />
                  </Route>
                </Switch>
              </AuthorityStateContainer>
            </ErrorBoundary>
          </AdminLayout>
        </Auth>
      </Route>
    </Switch>
  )
}

export default AdminRoutes

/**
 * ルート定義
 */
export class AdminRouteHelper {
  static basePath = (path: string): string => `/admin/${path}`

  public static root = (): string => '/admin'
  // ログイン
  public static login = (): string => AdminRouteHelper.basePath('login')

  // ラボラトリー
  public static laboratoryIndex = (): string => AdminRouteHelper.basePath('laboratories')
  public static laboratoryShow = (id: number | string): string => AdminRouteHelper.basePath(`laboratories/${id}`)

  // ディーラー
  public static dealerIndex = (): string => AdminRouteHelper.basePath('dealers')
  public static dealerShow = (id: number | string): string => AdminRouteHelper.basePath(`dealers/${id}`)
}
