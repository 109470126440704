import { AxiosHttpClient } from 'api/axios'
import { useRepruaIndexApi, useRepruaShowApi, useRepruaDownloadApi } from 'api/shared_api_hooks'
import { ID, PagingResponse } from 'entities'
import { Laboratory } from 'entities/laboratory'
import { ReservationSearchForm, ReservationsResponse } from 'entities/reservation'
import { ReservationDownloadForm } from 'entities/reservation_download'
import { IndexApiSet, ApiSet, Form, BaseResponse } from 'rac'

export type LaboratoriesResponse = PagingResponse & {
  laboratories: Laboratory[]
}

/**
 * 一覧
 */
export function useFetchLaboratoriesApi(): IndexApiSet<LaboratoriesResponse> & { execute: () => void } {
  const api = useRepruaIndexApi<LaboratoriesResponse, unknown>(new AxiosHttpClient('user'), {
    initialResponse: { laboratories: [], totalCount: 0 },
  })

  const execute = () => {
    const path = 'manager/laboratories'
    api.execute(path)
  }

  return { ...api, execute }
}

export type LaboratoryResponse = BaseResponse & {
  laboratory: Laboratory
}

/**
 * 取得
 */
export function useFetchLaboratoryApi(): ApiSet<LaboratoryResponse> & { execute: (id: ID, isManager: boolean) => void } {
  const api = useRepruaShowApi<LaboratoryResponse, { id: ID }>(new AxiosHttpClient('user'), { initialResponse: { laboratory: {} } })

  const execute = (id: ID, isManager: boolean) => {
    const path = isManager ? `manager/laboratories/${id}` : `laboratories/${id}`
    api.execute(path, { id: id })
  }

  return { ...api, execute }
}

/**
 * 一覧
 */
export function useFetchAssetReservationsApi(): IndexApiSet<ReservationsResponse> & {
  execute: (form: Form<ReservationSearchForm>) => void
} {
  const api = useRepruaIndexApi<ReservationsResponse, ReservationSearchForm>(new AxiosHttpClient('user'), {
    initialResponse: { reservations: [], totalCount: 0 },
  })

  const execute = (form: Form<ReservationSearchForm>) => {
    const path = 'manager/laboratories/reservations'
    api.execute(path, { params: form.object })
  }

  return { ...api, execute }
}

// 管理対象ラボの予約を含めた予約をエクセルでダウンロードする
export function useFetchXlsxAssetReservationsApi(): { execute: (form?: Form<ReservationDownloadForm>) => void } {
  const api = useRepruaDownloadApi<ReservationDownloadForm>(new AxiosHttpClient('user'))
  
  const execute = (form?: Form<ReservationDownloadForm>) => {
    const path = `manager/laboratories/reservations.xlsx?submit_type=${form ? 'search' : 'all'}`
    api.execute(path, form ? { params: form.object} : {})
  }

  return { execute }
}
