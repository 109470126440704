import { AxiosHttpClient } from 'api/axios'
import { useRepruaIndexApi, useRepruaPatchApi } from 'api/shared_api_hooks'
import { PagingResponse } from 'entities'
import { User, UserForm } from 'entities/user'
import { IndexApiSet, ApiSet, BaseResponse, Form } from 'rac'
import { useQuery } from 'react-query'

type UsersResponse = PagingResponse & {
  users: User[]
}

/**
 * 一覧
 */
export function useFetchUsersApi(): IndexApiSet<UsersResponse> & { execute: () => void } {
  const api = useRepruaIndexApi<UsersResponse, unknown>(new AxiosHttpClient('user'), { initialResponse: { users: [], totalCount: 0 } })

  const execute = () => {
    const path = 'users'
    api.execute(path)
  }
  return { ...api, execute }
}

type UserResponse = BaseResponse & {
  user: User
}

/**
 * ログイン中のユーザーを取得
 */
export const useCurrentUserQuery = () => {
  const client = new AxiosHttpClient('user')
  const query = useQuery<undefined, undefined, UserResponse>(
    'current_user',
    async () => {
      const { data } = await client.get('users/current', {})
      return data
    },
    { enabled: false },
  )

  return query
}

/**
 * 更新
 */
export const usePatchUserApi = (
  form: Form<UserForm>,
): ApiSet<UserResponse> & {
  execute: () => void
} => {
  const api = useRepruaPatchApi<UserResponse, UserForm>(new AxiosHttpClient('user'), {
    initialResponse: { user: {} },
  })

  const execute = () => {
    const path = 'users'
    api.execute(path, form.object)
  }

  return { ...api, execute }
}
