import { Collapse } from '@mui/material'
import { Flex } from 'components/shared/flex'
import { CheckBoxField, FilesInput, MonthField, NumberField, SelectField, StringField } from 'components/shared/form/input'
import Modal, { ModalSet } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { ProofreadEventForm } from 'entities/proofread_event'
import { ApiSet, BaseResponse, Form } from 'rac'
import React, { useContext } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'

const results = [
  { label: '不合格', value: 'ng' },
  { label: '合格', value: 'ok' },
]

type BulkResultFormDialogProps = {
  modalSet: ModalSet<number[]>
  patchForm: Form<ProofreadEventForm>
  patchApi: ApiSet<BaseResponse> & { execute: () => void }
  onComplete: () => void
}
export const BulkResultFormDialog: React.FC<BulkResultFormDialogProps> = (props) => {
  const globalState = useContext(GlobalStateContext)
  const { patchForm, patchApi } = props

  const handleSubmit = () => {
    patchApi.execute()
  }

  useEffectSkipFirst(() => {
    patchForm.resetForm()
  }, [props.modalSet.item])

  useEffectSkipFirst(() => {
    globalState.setLoading(patchApi.loading)
    if (patchApi.isSuccess()) {
      props.onComplete()
    }
  }, [patchApi.loading])

  return (
    <Modal
      modalId="event-bulk-result-form"
      modalState={props.modalSet.modalState}
      title="校正結果一括入力"
      size="sm"
      footer={
        <>
          <ThemedButton onClick={props.modalSet.closeModal}>閉じる</ThemedButton>
          <ThemedButton
            color="success"
            onClick={handleSubmit}
            disabled={patchForm.object.result === 'ng' && !patchForm.object.approveNoRepair}
          >
            登録
          </ThemedButton>
        </>
      }
    >
      <SelectField label="合否" form={patchForm} attr="result" apiError={patchApi.apiError} required data={results} labelId="result-id" />
      <div>
        <label>添付ファイル</label>
        <FilesInput form={patchForm} attr="attachmentsAttributes" apiError={patchApi.apiError} />
      </div>
      <Collapse in={patchForm.object.result === 'ok'}>
        <Flex style={{ marginTop: 12 }}>
          <MonthField label="校正月" form={patchForm} attr="scheduledDate" required apiError={patchApi.apiError} />
          <NumberField label="校正周期" form={patchForm} attr={['proofreadInfoAttributes', 'cycle']} apiError={patchApi.apiError} />
          <SelectField
            form={patchForm}
            labelId="proofread-unit-label"
            attr={['proofreadInfoAttributes', 'cycleUnit']}
            label="周期単位"
            data={[
              { value: 'month', label: '月毎' },
              { value: 'year', label: '年毎' },
            ]}
            apiError={patchApi.apiError}
          />
        </Flex>
      </Collapse>
      <StringField
        label="結果入力時メモ"
        form={patchForm}
        attr="noteOnResult"
        apiError={patchApi.apiError}
        multiline
        style={{ marginTop: 12 }}
      />
      <Collapse in={patchForm.object.result === 'ng'}>
        <CheckBoxField form={patchForm} attr="approveNoRepair" label="修理せずに校正結果を不合格にする" labelStyle={{ fontSize: 14 }} />
        <p style={{ color: 'red', fontSize: 12 }}>修理履歴を元に校正を進める場合は、不具合報告を作成してください</p>
      </Collapse>
    </Modal>
  )
}

export default BulkResultFormDialog
