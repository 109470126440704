import { AxiosHttpClient } from 'api/axios'
import { useRepruaShowApi } from 'api/shared_api_hooks'
import { Administrator } from 'entities/administrator'
import { BaseResponse, ApiSet } from 'rac'

type AdministratorResponse = BaseResponse & {
  administrator: Administrator
}
/**
 * 取得
 */
export function useFetchAdministratorApi(): ApiSet<AdministratorResponse> & { execute: () => void } {
  const api = useRepruaShowApi<AdministratorResponse, {}>(new AxiosHttpClient('admin'), { initialResponse: { administrator: {} } })

  const execute = () => {
    const path = 'administrators/current'
    api.execute(path)
  }

  return { ...api, execute }
}
