import { Popover } from '@mui/material'
import { FileAttachment } from 'entities/file_attachment'
import React from 'react'

import ThemedButton from './themed_button'

export type AttachmentsPopoverProps = {
  fileAttachments?: FileAttachment[]
}

export const AttachmentsPopover: React.FC<AttachmentsPopoverProps> = (props: AttachmentsPopoverProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div>
      {props.fileAttachments && props.fileAttachments.length > 0 && (
        <>
          <ThemedButton aria-describedby={id} color="secondary" onClick={handleClick}>
            ファイル
          </ThemedButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <div style={{ padding: 10 }}>
              {props.fileAttachments.map((fileAttachment: FileAttachment) => {
                return (
                  <div key={fileAttachment.url} style={{ margin: 5 }}>
                    <a href={fileAttachment.url} target="_blank" rel="noopener noreferrer">
                      {fileAttachment.name}
                    </a>
                  </div>
                )
              })}
            </div>
          </Popover>
        </>
      )}
    </div>
  )
}
