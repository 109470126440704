import { AxiosHttpClient } from 'api/axios'
import { useRepruaIndexApi } from 'api/shared_api_hooks'
import { PagingResponse } from 'entities'
import { Laboratory } from 'entities/laboratory'
import { IndexApiSet } from 'rac'
import { useRecoilState } from 'recoil'
import { ownerFilterAtom } from 'recoil/partner/owner_filter'
import { useEffectSkipFirst } from 'utils/hooks'


type LaboratoriesResponse = PagingResponse & {
  laboratories: Laboratory[]
}

export function usePartnerFetchLaboratoriesApi({ fetchAll = false, useFilter = false }): IndexApiSet<LaboratoriesResponse> & { execute: () => void } {
  const [filter] = useRecoilState(ownerFilterAtom)

  const api = useRepruaIndexApi<LaboratoriesResponse, {}>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { laboratories: [], totalCount: 0 },
  })

  const execute = () => {
    const path = `laboratories?fetch_all=${fetchAll}&use_filter=${useFilter}`
    api.execute(path, { params: useFilter ? filter : {} })
  }

  useEffectSkipFirst(() => {
    execute()
  }, [api.pageSet.page, api.pageSet.perPage, api.orderSet.order, api.orderSet.orderBy])

  useEffectSkipFirst(() => {
    if (useFilter) {
      execute()
    }
  }, [filter])

  return { ...api, execute }
}
