import { CancelPolicy } from 'entities/cancel_policy'

import { BaseEntity } from '.'

/**
 * 予約ルール
 */
export type ReservationTerm = BaseEntity & {
  unit?: string
  price?: number
  startTime?: number
  endTime?: number
  section?: number
  cancelPolicyId?: number

  cancelPolicy?: CancelPolicy
}

export const getTimeSelectObject = (): { value: string; label: string }[] => {
  const hours: number[] = []
  for (let i = 0; i < 24; i++) {
    hours.push(i)
  }
  const objects: { value: string; label: string }[] = []
  hours.forEach((hour) => {
    let value = `${('00' + hour).slice(-2)}:00`
    objects.push({ value: value, label: value })
    value = `${('00' + hour).slice(-2)}:30`
    objects.push({ value: value, label: value })
  })
  objects.push({ value: '23:59', label: '23:59' })
  return objects
}

export type ReservationTermForm = ReservationTerm & {
  imageAttribute?: {
    attachment?: string
  }
}
