import { Typography } from '@mui/material'
import LinkText from 'components/shared/link_text'
import { ID } from 'entities'
import { InstrumentOwnerableType } from 'entities/instrument'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { MypageRouteHelper } from 'routes/mypage'
import { PartnerRouteHelper } from 'routes/partner'

type LinkedInstrumentNameProps = {
  instrumentId?: ID
  instrumentName?: string
  ownerableType?: InstrumentOwnerableType
  linkDisabled?: boolean
}
export const LinkedInstrumentName = ({ ownerableType, instrumentId, instrumentName, linkDisabled }: LinkedInstrumentNameProps) => {
  const history = useHistory()
  const handleClickInstrumentName = () => {
    if (instrumentId && ownerableType) {
      if (ownerableType === 'Laboratory') {
        history.push(`${MypageRouteHelper.instrumentShow(instrumentId)}`)
      } else {
        history.push(`${PartnerRouteHelper.instrumentShow(instrumentId)}`)
      }
    }
  }

  return (
    <>
      {linkDisabled ? (
        <Typography variant="subtitle1">{instrumentName}</Typography>
      ) : (
        <LinkText variant="subtitle1" onClick={handleClickInstrumentName}>
          {instrumentName}
        </LinkText>
      )}
    </>
  )
}
