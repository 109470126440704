import { BaseEntity } from 'entities'

import { ReservationTimeConstraint, ReservationTimeConstraintForm } from './reservation_time_constraint'

export type DayOfTheWeek = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun'
export const DayOfTheWeeks: { [key in DayOfTheWeek]: string } = {
  mon: '月',
  tue: '火',
  wed: '水',
  thu: '木',
  fri: '金',
  sat: '土',
  sun: '日',
}
export type ReservationConstraint = BaseEntity & {
  dayOfTheWeeks?: DayOfTheWeek[]
  reservableHoliday?: boolean
  reservationTimeConstraints?: ReservationTimeConstraint[]
  unavailable?: boolean
}

export type ReservationConstraintForm = ReservationConstraint & {
  reservationTimeConstraintsAttributes?: ReservationTimeConstraintForm[]
  dayOfTheWeeksObject?: { [key: number]: DayOfTheWeek }
  _destroy?: boolean
}
