import React, { SetStateAction } from 'react'

type SidebarContext = {
  activeLabDrawerMenuId: number
  setActiveLabDrawerMenuId: React.Dispatch<React.SetStateAction<number>>
  activeLabDrawerSubMenuId: number
  setActiveLabDrawerSubMenuId: React.Dispatch<React.SetStateAction<number>>
  activeDealerDrawerMenuId: number
  setActiveDealerDrawerMenuId: React.Dispatch<React.SetStateAction<number>>
  activeDealerDrawerSubMenuId: number
  setActiveDealerDrawerSubMenuId: React.Dispatch<React.SetStateAction<number>>
  activeAdminDrawerMenuId: number
  setActiveAdminDrawerMenuId: React.Dispatch<React.SetStateAction<number>>
}

export const initialSidebarContext: SidebarContext = {
  activeLabDrawerMenuId: 0,
  setActiveLabDrawerMenuId: (value: SetStateAction<number>) => {},
  activeLabDrawerSubMenuId: 0,
  setActiveLabDrawerSubMenuId: (value: SetStateAction<number>) => {},
  activeDealerDrawerMenuId: 0,
  setActiveDealerDrawerMenuId: (value: SetStateAction<number>) => {},
  activeDealerDrawerSubMenuId: 0,
  setActiveDealerDrawerSubMenuId: (value: SetStateAction<number>) => {},
  activeAdminDrawerMenuId: 0,
  setActiveAdminDrawerMenuId: (value: SetStateAction<number>) => {},
}

export const SidebarContext = React.createContext(initialSidebarContext)
