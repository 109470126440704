import { TableHead, TableRow, Table, TableContainer } from '@mui/material'
import { DefectReportsResponse } from 'api/mypage/defect_report'
import { useFetchExColumnsApi } from 'api/shared/ex_columns'
import { AttachmentsPopover } from 'components/shared/attachments_popover'
import { Flex } from 'components/shared/flex'
import { Hint } from 'components/shared/hint'
import Pagination from 'components/shared/pagination'
import StatusLabel from 'components/shared/status_label'
import { OrderTableCell, ExTableCell, ExTableBody, ExTableRow } from 'components/shared/table'
import { TableExAttributesInfo } from 'components/shared/table_ex_attributes_info'
import ThemedButton from 'components/shared/themed_button'
import { DefectReport } from 'entities/defect_report'
import { IndexApiSet } from 'rac'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { MypageRouteHelper } from 'routes/mypage'
import { INDEX_TABLE_PADDING, getTableHeight } from 'utils/component_heights'
import { DefectReportViewModel } from 'view_models/defect_report'

type DefectReportTableProps = {
  indexApi: IndexApiSet<DefectReportsResponse> & { execute: () => void }
  onClickEdit: (defectReport: DefectReport) => void
  showInstrument?: boolean
}

export const DefectReportTable: React.FC<DefectReportTableProps> = (props: DefectReportTableProps) => {
  const history = useHistory()
  const exColumnApi = useFetchExColumnsApi('user')

  useEffect(() => {
    exColumnApi.execute()
  }, [])

  return (
    <>
      <TableContainer style={{ maxHeight: getTableHeight(INDEX_TABLE_PADDING) }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <OrderTableCell width={70} align="center" orderAttr="reportedAt" orderSet={props.indexApi.orderSet}>
                発生日
              </OrderTableCell>
              <OrderTableCell width={70} orderAttr="reportedUserName" orderSet={props.indexApi.orderSet}>
                報告者
              </OrderTableCell>
              {props.showInstrument && (
                <ExTableCell width={150}>
                  <div>
                    メーカー / 型番 / 管理番号 / 製品名
                    {exColumnApi.response.exColumns
                      .filter((col) => col.visibleOnTable)
                      .map((col) => (
                        <div style={{ fontSize: 12, lineHeight: '12px' }} key={`tablecol-${col.id}`}>
                          {col.name}
                        </div>
                      ))}
                  </div>
                </ExTableCell>
              )}
              <ExTableCell width={200}>不具合箇所 / 不具合内容</ExTableCell>
              <ExTableCell width={70} align="center">
                添付ファイル
              </ExTableCell>
              <ExTableCell width={70} align="center">
                <Flex justifyContent="center" alignItems="center">
                  経過日数
                  <div style={{ marginTop: 5 }}>
                    <Hint
                      title={
                        <>
                          修理未対応の不具合報告について
                          <br />
                          報告日から経過した日数を表示します。
                        </>
                      }
                    />
                  </div>
                </Flex>
              </ExTableCell>
              <ExTableCell width={70} align="center">
                ステータス
              </ExTableCell>
              <ExTableCell width={70}></ExTableCell>
            </TableRow>
          </TableHead>
          <ExTableBody loading={props.indexApi.loading}>
            {props.indexApi.response?.defectReports.map((defectReport: DefectReport) => {
              const defectReportVM = new DefectReportViewModel(defectReport)
              return (
                <ExTableRow key={defectReport.id}>
                  <ExTableCell width={70} align="center">
                    {defectReportVM.getReportedAtText()}
                  </ExTableCell>
                  <ExTableCell width={70}>{defectReport.reportedUserName}</ExTableCell>
                  {props.showInstrument && (
                    <ExTableCell width={150}>
                      <div>{defectReportVM.getInstrumentInfo()}</div>
                      <TableExAttributesInfo tableExAttrs={defectReport.tableExAttrs || []} id={defectReport.id} />
                    </ExTableCell>
                  )}
                  <ExTableCell width={200}>
                    <>
                      <div style={{ paddingBottom: 5, fontWeight: 'bold' }}>{defectReport.point}</div>
                      <div>{defectReport.content}</div>
                    </>
                  </ExTableCell>

                  <ExTableCell width={70} align="center">
                    <AttachmentsPopover fileAttachments={defectReport.fileAttachments} />
                  </ExTableCell>
                  <ExTableCell width={70} align="center">
                    {defectReportVM.getElapsedDates()}
                  </ExTableCell>
                  <ExTableCell width={70} align="center">
                    <StatusLabel text={defectReportVM.getStatusText()} color={defectReportVM.getStatusColor()} />
                  </ExTableCell>
                  <ExTableCell width={100} align="center">
                    <ThemedButton
                      color="secondary"
                      onClick={() => history.push(MypageRouteHelper.repairInfoShow(defectReport.repairInfoId!))}
                    >
                      詳細
                    </ThemedButton>
                  </ExTableCell>
                </ExTableRow>
              )
            })}
          </ExTableBody>
        </Table>
      </TableContainer>
      <Pagination response={props.indexApi.response} pageSet={props.indexApi.pageSet} />
    </>
  )
}
