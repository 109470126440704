import { Collapse, Tooltip } from '@mui/material'
import { usePartnerPostRepairEventApi, usePartnerPatchRepairEventApi, usePartnerDeleteRepairEventApi } from 'api/partner/repair_event'
import { Flex } from 'components/shared/flex'
import { StringField, DateField, NumberField, SelectField, FilesInput, CheckBoxField } from 'components/shared/form/input'
import Modal, { ModalState } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { ConfirmCallbackEvent } from 'containers/global_state_container'
import { GlobalStateContext } from 'contexts/global_state_context'
import { PartnerStateContext } from 'contexts/partner_state_context'
import { DefectReport } from 'entities/defect_report'
import { FileAttachment } from 'entities/file_attachment'
import { ProofreadEvent } from 'entities/proofread_event'
import { RepairEventForm, RepairEvent } from 'entities/repair_event'
import { Form, useForm, ApiError } from 'rac'
import React, { useEffect, useContext } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'

const dialogContent = (repairEventForm: Form<RepairEventForm>, error: ApiError) => {
  return (
    <>
      <Flex>
        <SelectField
          form={repairEventForm}
          labelId="proofread-is-label"
          attr="aasmState"
          label="修理履歴"
          data={[
            { value: 'yet', label: '未修理' },
            { value: 'repaired', label: '修理済' },
            { value: 'unrepairable', label: '修理不可' },
          ]}
          apiError={error}
          required
        />
        <NumberField label="修理費用" attr="price" form={repairEventForm} apiError={error} />
      </Flex>

      <Flex>
        <DateField label="修理開始日" attr="startAt" form={repairEventForm} apiError={error} clearable />
        <DateField label="完了予定日 / 完了日" attr="endAt" form={repairEventForm} apiError={error} clearable />
      </Flex>
      {repairEventForm.object.noteToSupplier && (
        <div style={{ marginTop: 10, marginBottom: 20 }}>
          <label>リプルア顧客からのコメント</label>
          <div>{repairEventForm.object.noteToSupplier}</div>
        </div>
      )}
      <Flex>
        <StringField label="修理内容詳細" attr="detail" form={repairEventForm} apiError={error} multiline />
      </Flex>

      {repairEventForm.object.fileAttachments &&
        repairEventForm.object.fileAttachments.length > 0 &&
        repairEventForm.object.fileAttachments.map((fileAttachment: FileAttachment) => {
          return (
            <div key={fileAttachment.url} style={{ margin: 5 }}>
              <a href={fileAttachment.url} target="_blank" rel="noopener noreferrer">
                {fileAttachment.name}
              </a>
            </div>
          )
        })}
      <FilesInput form={repairEventForm} attr="attachmentsAttributes" />
      <Collapse in={repairEventForm.object.aasmState !== 'yet' && !!repairEventForm.object.proofreadEventId}>
        <CheckBoxField form={repairEventForm} attr="skipFixResult" label="校正結果の入力をスキップする" labelStyle={{ fontSize: 14 }} />
        <p style={{ paddingBottom: 12 }}>
          修理結果を「修理済」で登録すると、校正結果は合格で登録されます。
          <br />
          修理結果を「修理不可」で登録すると、校正結果は不合格で登録されます。
          <br />
          <span style={{ color: 'red' }}>修理履歴の編集ができなくなります</span>
        </p>
      </Collapse>
    </>
  )
}

type NewRepairEventFormDialogProps = {
  modalState: ModalState
  onComplete: () => void
  onClose: () => void
  instrumentId?: number
  proofreadEvent?: ProofreadEvent
  requireConfirm?: boolean
}

export const NewRepairEventFormDialog: React.FC<NewRepairEventFormDialogProps> = (props: NewRepairEventFormDialogProps) => {
  const repairEventForm = useForm<RepairEventForm>({ aasmState: 'yet' }, 'repairEvent')
  const api = usePartnerPostRepairEventApi()
  const globalState = useContext(GlobalStateContext)

  useEffect(() => {
    if (!props.modalState.open) return

    repairEventForm.update((f) => {
      f.instrumentId = props.instrumentId
      if (props.proofreadEvent) {
        f.proofreadEventId = props.proofreadEvent.id

        if (props.proofreadEvent.defectReport) {
          f.defectReportId = props.proofreadEvent.defectReport.id
        }
      }
    })
  }, [props.modalState.open])

  useEffectSkipFirst(() => {
    globalState.setLoading(api.loading)
    if (!api.loading && !api.isError) {
      props.modalState.setOpen(false)
      props.onComplete()
    }
  }, [api.loading])

  const handleSubmit = () => {
    if (props.requireConfirm) {
      globalState.confirm('変更した修理期間に含まれる予定はキャンセルされますが、よろしいですか？', (event: ConfirmCallbackEvent) => {
        if (event === 'ok') {
          api.execute(repairEventForm)
        }
      })
    } else {
      api.execute(repairEventForm)
    }
  }

  return (
    <div>
      <Modal
        modalState={props.modalState}
        onClose={props.onClose}
        aria-labelledby="form-dialog-title"
        modalId="edit-repair-event-modal"
        title="修理履歴の作成"
        size="sm"
        footer={
          <>
            <ThemedButton onClick={props.onClose} color="secondary">
              閉じる
            </ThemedButton>
            <ThemedButton onClick={handleSubmit} color="success">
              登録
            </ThemedButton>
          </>
        }
      >
        {dialogContent(repairEventForm, api.apiError)}
      </Modal>
    </div>
  )
}

type EditRepairEventFormDialogProps = {
  modalState: ModalState
  repairEvent?: RepairEvent
  onComplete: (event?: RepairEvent) => void
  onClose: () => void
  showConfirm?: boolean
}

export const EditRepairEventFormDialog: React.FC<EditRepairEventFormDialogProps> = (props: EditRepairEventFormDialogProps) => {
  const { repairEvent, onComplete, onClose } = props
  const { currentDealerUser } = useContext(PartnerStateContext)
  const api = usePartnerPatchRepairEventApi()
  const deleteApi = usePartnerDeleteRepairEventApi()
  const globalState = useContext(GlobalStateContext)

  const repairEventForm = useForm<RepairEventForm>({}, 'repairEvent')

  useEffectSkipFirst(() => {
    if (api.isSuccess()) {
      onClose()
      onComplete(api.response.repairEvent)
    }
  }, [api.loading])

  useEffect(() => {
    if (repairEvent) {
      repairEventForm.set(repairEvent)
    }
  }, [repairEvent])

  const handleSubmit = () => {
    api.execute(repairEventForm.object)
    // TODO: ディーラー連携後コメント解除
    // if (props.showConfirm) {
    //   globalState.confirm('変更はラボに送信、通知されます。よろしいですか？', (event: ConfirmCallbackEvent) => {
    //     if (event === 'ok') {
    //       api.execute(repairEventForm.object)
    //     }
    //   })
    // } else {
    //   api.execute(repairEventForm.object)
    // }
  }

  const handleClickDelete = () => {
    globalState.confirm('本当に削除しますか？', (event) => {
      const eventId = props.repairEvent?.id
      if (event === 'ok' && eventId) {
        deleteApi.execute(eventId)
      }
    })
  }

  useEffect(() => {
    if (deleteApi.isSuccess()) {
      onComplete()
    }
  }, [deleteApi.loading])

  const isDisabledDeleteButton = () => {
    return !(props.repairEvent?.recorderableId === currentDealerUser.id && props.repairEvent?.recorderableType === 'DealerUser')
  }

  return (
    <Modal
      modalId="edit-repair-event-modal"
      title="修理履歴編集"
      modalState={props.modalState}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
      size="sm"
      footer={
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', flexDirection: 'row' }}>
          <Tooltip title={isDisabledDeleteButton() ? '作成者以外削除出来ません' : ''}>
            <div>
              <ThemedButton
                color={isDisabledDeleteButton() ? undefined : 'error'}
                onClick={() => handleClickDelete()}
                disabled={isDisabledDeleteButton()}
              >
                削除
              </ThemedButton>
            </div>
          </Tooltip>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <ThemedButton onClick={onClose} color="secondary" style={{ marginRight: 10 }}>
              閉じる
            </ThemedButton>
            <ThemedButton onClick={handleSubmit} color="success">
              更新
            </ThemedButton>
          </div>
        </div>
      }
    >
      {dialogContent(repairEventForm, api.apiError)}
    </Modal>
  )
}

type NewRepairEventFormDialogFromDefectReportProps = {
  onComplete: (repairEvent?: RepairEvent) => void
  defectReport?: DefectReport
  modalState: ModalState
  onClose: () => void
}

export const NewRepairEventFormDialogFromDefectReport: React.FC<NewRepairEventFormDialogFromDefectReportProps> = (
  props: NewRepairEventFormDialogFromDefectReportProps,
) => {
  const repairEventForm = useForm<RepairEventForm>({ aasmState: 'yet' }, 'repairEvent')
  const api = usePartnerPostRepairEventApi()
  const globalState = useContext(GlobalStateContext)

  useEffect(() => {
    repairEventForm.update((f) => {
      f.instrumentId = props.defectReport?.instrument?.id
      f.proofreadEventId = props.defectReport?.proofreadEvent?.id
      f.defectReportId = props.defectReport?.id
    })
  }, [props.defectReport])

  useEffectSkipFirst(() => {
    globalState.setLoading(api.loading)
    if (!api.loading && !api.isError) {
      props.onComplete(api.response.repairEvent)
    }
  }, [api.loading])

  const handleSubmit = () => {
    globalState.confirm('送信します、よろしいですか？', (event: ConfirmCallbackEvent) => {
      if (event === 'ok') {
        api.execute(repairEventForm)
      }
    })
  }

  return (
    <Modal
      modalId="new-repair-event-modal"
      title="修理履歴登録"
      modalState={props.modalState}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
      size="sm"
      footer={
        <>
          <ThemedButton onClick={props.onClose} color="secondary">
            閉じる
          </ThemedButton>
          <ThemedButton onClick={handleSubmit} color="success">
            登録
          </ThemedButton>
        </>
      }
    >
      {dialogContent(repairEventForm, api.apiError)}
    </Modal>
  )
}
