import { Flex } from 'components/shared/flex'
import ThemedButton from 'components/shared/themed_button'
import { MypageStateContext } from 'contexts/mypage_state_context'
import { ReservationConstraint, DayOfTheWeeks } from 'entities/reservation_constraint'
import { ReservationConstraintSet } from 'entities/reservation_constraint_set'
import { ReservationTimeConstraint } from 'entities/reservation_time_constraint'
import React, { useContext } from 'react'
import { ReservationTimeConstraintViewModel } from 'view_models/reservation_time_constraint'

type ConstraintListBoxProps = {
  reservationConstraintSet: ReservationConstraintSet
  onClickEdit?: (reservationConstraintSet: ReservationConstraintSet) => void
  onClickDelete?: (reservationConstraintSet: ReservationConstraintSet) => void
}

export const ConstraintDetail: React.FC<ConstraintListBoxProps> = (props: ConstraintListBoxProps) => {
  const { currentUser } = useContext(MypageStateContext)

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center" style={{ fontSize: 18, fontWeight: 'bold' }}>
        <div>{props.reservationConstraintSet.name}</div>
        <Flex>
          {props.onClickEdit && currentUser && currentUser.authority?.constraint && (
            <ThemedButton color="secondary" onClick={() => props.onClickEdit!(props.reservationConstraintSet)}>
              編集
            </ThemedButton>
          )}
          {props.onClickDelete && currentUser && currentUser.authority?.constraint && (
            <ThemedButton color="error" onClick={() => props.onClickDelete!(props.reservationConstraintSet)}>
              削除
            </ThemedButton>
          )}
        </Flex>
      </Flex>
      <Flex flexWrap="wrap">
        {props.reservationConstraintSet.reservationConstraints &&
          props.reservationConstraintSet.reservationConstraints.map((reservationConstraint: ReservationConstraint, index: number) => {
            return (
              <div style={{ padding: 15, margin: '10px 10px 0 10px', backgroundColor: '#f1f1f1', minWidth: 300 }} key={index}>
                {reservationConstraint.unavailable ? (
                  <div style={{ fontWeight: 'bold' }}>利用不可</div>
                ) : (
                  <>
                    <div style={{ marginBottom: 5, fontWeight: 'bold' }}>予約条件{index + 1}</div>
                    <Flex style={{ marginBottom: 5 }}>
                      {reservationConstraint.dayOfTheWeeks!.map((dayOfTheWeek, j) => {
                        return <div key={j}>{DayOfTheWeeks[dayOfTheWeek]}</div>
                      })}
                      <div>(祝日{reservationConstraint.reservableHoliday ? '可' : '不可'})</div>
                    </Flex>
                    <div>
                      {reservationConstraint.reservationTimeConstraints &&
                        reservationConstraint.reservationTimeConstraints.map((timeConstraint: ReservationTimeConstraint, k) => {
                          const timeConstraintVm = new ReservationTimeConstraintViewModel(timeConstraint)
                          return (
                            <div key={k}>
                              <span
                                style={{ fontSize: 16 }}
                              >{`${timeConstraintVm.startTimeText()} 〜 ${timeConstraintVm.endTimeText()}`}</span>{' '}
                              / 利用価格: {`${timeConstraintVm.price()}`}
                            </div>
                          )
                        })}
                    </div>
                  </>
                )}
              </div>
            )
          })}
      </Flex>
    </>
  )
}
