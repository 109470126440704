import { BaseEntity, ID } from 'entities'
import lodash from 'lodash'
import { useState, useEffect } from 'react'

export type CheckboxesSet = {
  allChecked: boolean
  changeAllChecked: () => void
  isChecked: (id: ID) => boolean
  updateCheckedIds: (id: ID) => void
  clear: () => void
  checkedIds: ID[]
}

/**
 * 一覧からチェックした要素のIDを管理する
 *
 * @param elements IDをもった要素
 * @param disabledAttr disabled判定をしたいカラム
 */
export function useCheckboxes<T extends BaseEntity>(elements?: T[], disabledAttr?: keyof T): CheckboxesSet {
  const [allChecked, setAllChecked] = useState(false)
  const [checkedIds, setCheckedIds] = useState<ID[]>([])

  /**
   * 全てチェックのチェックに応じてcheckedIdsを更新する
   */
  useEffect(() => {
    if (elements) {
      let allElms = elements
      if (disabledAttr) {
        allElms = allElms.filter((elem) => elem[disabledAttr])
      }
      const allIds = allElms.map((element) => element.id!)
      if (allChecked && elements) {
        const union = lodash.union(checkedIds, allIds)
        setCheckedIds(union)
      } else {
        const diff = lodash.difference(checkedIds, allIds)
        setCheckedIds(diff)
      }
    }
  }, [allChecked])

  /**
   * allCheckedフラグを切り替える
   */
  const changeAllChecked = () => {
    setAllChecked(!allChecked)
  }

  /**
   * 指定したIDがcheckedIdsにあればtrue
   *
   * @param id ID
   */
  const isChecked = (id: ID): boolean => {
    return checkedIds.includes(id)
  }

  /**
   * 指定したIDがcheckedIdsにあればcheckedIdsからIDを削除し、なければ追加する
   *
   * @param checkId ID
   */
  const updateCheckedIds = (checkId: ID) => {
    let copyIds: ID[] = []
    if (isChecked(checkId)) {
      copyIds = checkedIds.filter((id) => id !== checkId)
      setCheckedIds(() => copyIds)
    } else {
      copyIds = Object.assign([], checkedIds)
      copyIds.push(checkId)
      setCheckedIds(() => copyIds)
    }
  }

  /**
   * checedIdsを空にする
   */
  const clear = () => {
    setCheckedIds([])
  }

  /**
   * 返却値
   */
  const checkboxesSet: CheckboxesSet = {
    allChecked: allChecked,
    changeAllChecked: changeAllChecked,
    isChecked: isChecked,
    updateCheckedIds: updateCheckedIds,
    checkedIds: checkedIds,
    clear: clear,
  }

  return checkboxesSet
}
