import { ErrorBoundary } from '@sentry/react'
import PartnerLayout from 'components/partner/partner_layout'
import { FrontendErrorFallback } from 'components/shared/frontend_error_fallback'
import AuthorityStateContainer from 'containers/authority_state_container'
import DealerUserAuth from 'containers/dealer_user_auth'
import { PartnerStateContext } from 'contexts/partner_state_context'
import BarcodeIndex from 'pages/partner/barcode/index'
import BarcodePreview from 'pages/partner/barcode/preview'
import DefectReportIndex from 'pages/partner/defect_report'
import DefectReportShow from 'pages/partner/defect_report/show'
import { ExColumnIndex } from 'pages/partner/ex_column'
import InquiryIndex from 'pages/partner/inquiry'
import InstrumentIndex from 'pages/partner/instrument'
import InstrumentImport from 'pages/partner/instrument/import'
import InstrumentShow from 'pages/partner/instrument/show'
import InstrumentRelationEventIndex from 'pages/partner/instrument_relation_event'
import Login from 'pages/partner/login/index'
import ProofreadPlanIndex from 'pages/partner/proofread_plan'
import ProofreadPlanShow from 'pages/partner/proofread_plan/show'
import ProofreadableInstrumentIndex from 'pages/partner/proofreadable_instrument/index'
import RepairEstimateIndex from 'pages/partner/repair_estimate'
import RepairEstimateShow from 'pages/partner/repair_estimate/show'
import RepairEventIndex from 'pages/partner/repair_event'
import RepairEventShow from 'pages/partner/repair_event/show'
import RepairInfoShow from 'pages/partner/repair_info/show'
import SettingShow from 'pages/partner/setting/show'
import UnmanagedInstrumentIndex from 'pages/partner/unmanaged_instrument/index'
import UnprintedBarcodeIndex from 'pages/partner/unprinted_barcode/index'
import React, { useContext } from 'react'
import { Route, Switch } from 'react-router-dom'

const PartnerRoutes: React.FC = () => {
  const partnerState = useContext(PartnerStateContext)

  return (
    <Switch>
      <Route exact path={PartnerRouteHelper.root()}>
        <Login />
      </Route>
      <Route exact path={PartnerRouteHelper.login()}>
        <Login />
      </Route>
      <Route path="/partner">
        <DealerUserAuth>
          <PartnerLayout>
            <ErrorBoundary fallback={FrontendErrorFallback}>
              <AuthorityStateContainer>
                <Switch>
                  {/* 機器 */}
                  <Route exact path={PartnerRouteHelper.instrumentIndex()}>
                    <InstrumentIndex />
                  </Route>
                  <Route exact path={PartnerRouteHelper.instrumentShow(':id')}>
                    <InstrumentShow />
                  </Route>
                  <Route exact path={PartnerRouteHelper.instrumentImport()}>
                    <InstrumentImport />
                  </Route>

                  {/* QR・バーコード */}
                  <Route exact path={PartnerRouteHelper.barcodeIndex()}>
                    <BarcodeIndex />
                  </Route>
                  <Route exact path={PartnerRouteHelper.barcodePreview()}>
                    <BarcodePreview />
                  </Route>
                  <Route exact path={PartnerRouteHelper.unprintedBarcodeIndex()}>
                    <UnprintedBarcodeIndex />
                  </Route>

                  {/* 管理対象外の機器 */}
                  <Route exact path={PartnerRouteHelper.unmanagedInstrumentIndex()}>
                    <UnmanagedInstrumentIndex />
                  </Route>

                  {/* 不具合報告 */}
                  <Route exact path={PartnerRouteHelper.defectReportIndex()}>
                    <DefectReportIndex />
                  </Route>
                  <Route exact path={PartnerRouteHelper.defectReportShow(':id')}>
                    <DefectReportShow />
                  </Route>
                  {/* 修理管理 */}
                  <Route exact path={PartnerRouteHelper.repairInfoShow(':id')}>
                    <RepairInfoShow />
                  </Route>
                  {/* 修理結果 */}
                  <Route exact path={PartnerRouteHelper.repairEventIndex()}>
                    <RepairEventIndex />
                  </Route>
                  <Route exact path={PartnerRouteHelper.repairEventShow(':id')}>
                    <RepairEventShow />
                  </Route>
                  {/* 修理見積 */}
                  <Route exact path={PartnerRouteHelper.repairEstimateIndex()}>
                    <RepairEstimateIndex />
                  </Route>
                  <Route exact path={PartnerRouteHelper.repairEstimateShow(':id')}>
                    <RepairEstimateShow />
                  </Route>

                  {/* 校正計画 */}
                  <Route exact path={PartnerRouteHelper.proofreadPlanIndex()}>
                    <ProofreadPlanIndex />
                  </Route>
                  <Route exact path={PartnerRouteHelper.proofreadPlanShow(':id')}>
                    <ProofreadPlanShow />
                  </Route>
                  <Route exact path={PartnerRouteHelper.proofreadableInstrumentIndex()}>
                    <ProofreadableInstrumentIndex />
                  </Route>

                  {/* 管理情報 */}
                  <Route exact path={PartnerRouteHelper.exAttributeIndex()}>
                    <ExColumnIndex />
                  </Route>
                  <Route exact path={PartnerRouteHelper.inquiryShow(':id')}>
                    <InquiryIndex />
                  </Route>
                  {/* 設定 */}
                  <Route exact path={PartnerRouteHelper.settingShow()}>
                    <SettingShow />
                  </Route>
                  {partnerState.currentDealer.authority?.enableInstrumentSharing && (
                    <>
                      {/* 機器共有 */}
                      <Route exact path={PartnerRouteHelper.instrumentRelationEventIndex()}>
                        <InstrumentRelationEventIndex />
                      </Route>
                      {/* 問合せ */}
                      <Route exact path={PartnerRouteHelper.inquiryIndex()}>
                        <InquiryIndex />
                      </Route>
                    </>
                  )}
                </Switch>
              </AuthorityStateContainer>
            </ErrorBoundary>
          </PartnerLayout>
        </DealerUserAuth>
      </Route>
    </Switch>
  )
}

export default PartnerRoutes

export class PartnerRouteHelper {
  public static root = (): string => '/partner'
  static basePath = (path: string): string => `${PartnerRouteHelper.root()}/${path}`

  // ログイン
  public static login = (): string => PartnerRouteHelper.basePath('login')

  // 機器
  public static instrumentIndex = (): string => PartnerRouteHelper.basePath('instruments')
  public static instrumentShow = (id: number | string): string => PartnerRouteHelper.basePath(`instruments/${id}`)
  public static instrumentRepairResultIndex = (instrumentId: number | string): string =>
    PartnerRouteHelper.basePath(`instruments/${instrumentId}/repair_results`)
  public static instrumentImport = (): string => PartnerRouteHelper.basePath('import/instruments')

  // QR・バーコード
  public static barcodeIndex = (): string => PartnerRouteHelper.basePath('barcodes')
  public static barcodePreview = (): string => PartnerRouteHelper.basePath('barcodes/preview')
  public static unprintedBarcodeIndex = (): string => PartnerRouteHelper.basePath('unprinted_barcodes')

  // 管理対象外の機器
  public static unmanagedInstrumentIndex = (): string => PartnerRouteHelper.basePath('unmanaged_instruments')

  // 不具合報告一覧
  public static defectReportIndex = (): string => PartnerRouteHelper.basePath('defect_reports')
  public static defectReportShow = (id: number | string): string => PartnerRouteHelper.basePath(`defect_reports/${id}`)

  // 校正管理
  public static proofreadPlanIndex = (): string => PartnerRouteHelper.basePath('proofread_plans')
  public static proofreadPlanShow = (id: number | string): string => PartnerRouteHelper.basePath(`proofread_plans/${id}`)
  public static proofreadableInstrumentIndex = (): string => PartnerRouteHelper.basePath('proofreadable_instruments')

  // 修理管理
  public static repairInfoShow = (id: number | string): string => PartnerRouteHelper.basePath(`repair_infos/${id}`)

  // 修理結果
  public static repairEventIndex = (): string => PartnerRouteHelper.basePath('repair_events')
  public static repairEventShow = (id: number | string): string => PartnerRouteHelper.basePath(`repair_events/${id}`)

  // 修理見積
  public static repairEstimateIndex = (): string => PartnerRouteHelper.basePath('repair_estimates')
  public static repairEstimateShow = (id: number | string): string => PartnerRouteHelper.basePath(`repair_estimates/${id}`)

  // 機器共有イベント
  public static instrumentRelationEventIndex = (): string => PartnerRouteHelper.basePath('instrument_relation_events')

  // 管理情報
  public static exAttributeIndex = (): string => PartnerRouteHelper.basePath('ex_attributes')

  // 問合せ
  public static inquiryIndex = (): string => PartnerRouteHelper.basePath('inquiries')
  public static inquiryShow = (id: number | string): string => PartnerRouteHelper.basePath(`inquiries/${id}`)

  // 設定
  public static settingShow = (): string => PartnerRouteHelper.basePath('setting')
}
