import { Paper, TableContainer, TableHead, Box, TableBody, Table, TableRow, TableCell } from '@mui/material'
import { useModal } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { MypageStateContext } from 'contexts/mypage_state_context'
import { User } from 'entities/user'
import { authorityKeys } from 'entities/user_authority'
import React, { useContext } from 'react'
import { UserViewModel } from 'view_models/user'

import { UserAuthorityFormDialog } from './user_authority_form_dialog'

type Props = {
  users: User[]
  isManager: boolean
  onComplete?: () => void
}

export const UserListBox: React.FC<Props> = (props) => {
  const { currentUser } = useContext(MypageStateContext)
  const authorityModal = useModal()

  return (
    <Box>
      {(props.isManager || currentUser.authority?.privileged) && (
        <UserAuthorityFormDialog
          users={props.users}
          onComplete={props.onComplete}
          isManager={props.isManager}
          modalState={authorityModal}
        />
      )}
      <Box display={'flex'} alignItems={'center'} marginBottom={1}>
        <h3 style={{ marginRight: '10px' }}>ユーザー権限</h3>
        {(props.isManager || currentUser.authority?.privileged) && (
          <ThemedButton color="primary" onClick={() => authorityModal.setOpen(true)}>
            権限設定
          </ThemedButton>
        )}
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>名前</TableCell>
              <TableCell>ユーザー権限</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.users &&
              props.users.map((user: User, idx: number) => {
                const userVM = new UserViewModel(user)
                return (
                  <TableRow key={idx}>
                    <TableCell width={120}>{userVM.fullName()}</TableCell>
                    <TableCell>
                      {authorityKeys.map((type, idx) => (
                        <span key={idx}>{userVM.authority(type, idx)}</span>
                      ))}
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
