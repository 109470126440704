import React from 'react'

/** エラー発生時のフォールバック表示コンポーネント */
export const FrontendErrorFallback = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <h2>問題が発生しました</h2>
      <br />
      ご不便をお掛けして申し訳ございません。
      <br />
      しばらく時間をおいて、画面を再読み込みしても解決しない場合、
      <br />
      お手数ですが、お問い合わせください。
    </div>
  )
}
