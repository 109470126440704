import { usePartnerPatchDealerDivisionApi } from 'api/partner/dealer_divisions'
import { usePartnerFetchDealerDivisionLaboratoryRelationsApi } from 'api/partner/dealer_divisions/dealer_division_laboratory_relations'
import { usePartnerFetchLaboratoriesApi } from 'api/partner/laboratories'
import { usePartnerFetchOutsideLaboratoriesApi } from 'api/partner/outside_laboratories'
import { Flex } from 'components/shared/flex'
import { MultipleCheckBoxField } from 'components/shared/form/input'
import Modal, { ModalSet } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { PartnerStateContext } from 'contexts/partner_state_context'
import { DealerDivision, DealerDivisionForm } from 'entities/dealer_division'
import { useForm } from 'rac'
import React, { useContext, useEffect } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'

type Props = {
  modalSet: ModalSet<DealerDivision>
  onComplete: () => void
}
export const DealerDivisionLaboratoryRelationModal = ({ modalSet, onComplete }: Props) => {
  const form = useForm<DealerDivisionForm>({ laboratoryIds: [], outsideLaboratoryIds: [] })
  const partnerState = useContext(PartnerStateContext)
  const fetchOutsideLabApi = usePartnerFetchOutsideLaboratoriesApi({ fetchAll: true })
  const fetchLaboratoryApi = usePartnerFetchLaboratoriesApi({ fetchAll: true })
  const fetchRelationApi = usePartnerFetchDealerDivisionLaboratoryRelationsApi()
  const patchDivisionApi = usePartnerPatchDealerDivisionApi(form)

  useEffect(() => {
    fetchLaboratoryApi.execute()
    fetchOutsideLabApi.execute()
  }, [])

  useEffectSkipFirst(() => {
    if (!modalSet.modalState.open) return
    fetchRelationApi.execute(modalSet.item.id!)
  }, [modalSet.modalState.open])

  // DBに保存されている部署×外部ラボのリレーションを元にフォームを更新する
  useEffectSkipFirst(() => {
    if (!fetchRelationApi.isSuccess()) return
    const outsideLabIds = fetchRelationApi.response.dealerDivisionLaboratoryRelations
      .filter((rel) => rel.manageableType === 'OutsideLaboratory')
      .map((rel) => rel.manageableId!)
    const laboratoryIds = fetchRelationApi.response.dealerDivisionLaboratoryRelations
      .filter((rel) => rel.manageableType === 'Laboratory')
      .map((rel) => rel.manageableId!)

    form.update((f) => {
      f.outsideLaboratoryIds = outsideLabIds.concat('') // 空のパラメーターを送る為空文字を挿入
      f.laboratoryIds = laboratoryIds.concat('')
    })
  }, [fetchRelationApi.loading])

  useEffectSkipFirst(() => {
    if (!patchDivisionApi.isSuccess()) return
    onComplete()
  }, [patchDivisionApi.loading])

  return (
    <Modal
      modalState={modalSet.modalState}
      modalId="dealer-division-laboratory-relation-modal"
      title="担当顧客の設定"
      size="sm"
      footer={
        <>
          <ThemedButton onClick={() => modalSet.closeModal()} color="secondary">
            閉じる
          </ThemedButton>
          <ThemedButton onClick={() => patchDivisionApi.execute(modalSet.item.id!)} color="success">
            更新
          </ThemedButton>
        </>
      }
    >
      {partnerState.currentDealer.authority?.enableInstrumentSharing && (
        <>
          <h3>リプルア顧客</h3>
          <Flex flexWrap="wrap">
            {fetchLaboratoryApi.response?.laboratories.length === 0 ? (
              <div style={{ width: '100%', textAlign: 'center' }}>結果がありません</div>
            ) : (
              fetchLaboratoryApi.response?.laboratories.map((lab) => {
                return (
                  <MultipleCheckBoxField
                    key={lab.id}
                    id={lab.id!}
                    label={lab.name!}
                    form={form}
                    attr="laboratoryIds"
                    checked={form.object.laboratoryIds!.includes(lab.id!)}
                  />
                )
              })
            )}
          </Flex>
        </>
      )}

      <h3>外部顧客</h3>
      <Flex flexWrap="wrap">
        {fetchOutsideLabApi.response?.outsideLaboratories.length === 0 ? (
          <div style={{ width: '100%', textAlign: 'center' }}>結果がありません</div>
        ) : (
          fetchOutsideLabApi.response?.outsideLaboratories.map((lab) => {
            return (
              <MultipleCheckBoxField
                key={lab.id}
                id={lab.id!}
                label={lab.name!}
                form={form}
                attr="outsideLaboratoryIds"
                checked={form.object.outsideLaboratoryIds!.includes(lab.id!)}
              />
            )
          })
        )}
      </Flex>
    </Modal>
  )
}
