import { Paper, Table, TableContainer, TableHead, TableRow, Switch } from '@mui/material'
import { useFetchAdminLaboratoryUsersApi, usePostAdminLaboratoryUpdateAuthorityApi } from 'api/admin/laboratories/laboratory_users'
import { useFetchAdminManagedLaboratoriesApi, useDeleteAdminManagedLaboratoryApi } from 'api/admin/laboratories/managed_laboratory'
import {
  useFetchAdminLaboratoriesSubscriptionPlanApi,
  usePatchAdminLaboratoriesSubscriptionPlanApi,
} from 'api/admin/laboratories/subscription_plan'
import { useFetchAdminLaboratoryApi } from 'api/admin/laboratory'
import { useAdminUserAgentsApi } from 'api/admin/users/user_agents'
import { InstrumentImportModalContainer } from 'components/admin/laboratory/instrument_import_modal_container'
import { ManagementLaboratoryRelationFormDialog } from 'components/admin/laboratory/management_laboratory_relation_form'
import { UserAuthorityFormDialog } from 'components/admin/laboratory/user_authority_form_dialog'
import { ContentHeader, ContentTitle } from 'components/shared/content'
import { Flex } from 'components/shared/flex'
import { RhfSelectField } from 'components/shared/form/rhf_input'
import { useModal } from 'components/shared/modal'
import { ExTableCell, ExTableBody, ExTableRow } from 'components/shared/table'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { Laboratory } from 'entities/laboratory'
import { getSubscriptionPlanTypeFromLimitCount, SubscriptionPlanType, subscriptionPlanTypeSelectData } from 'entities/subscription_plan'
import { User } from 'entities/user'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { StaticContext } from 'react-router'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import CookieManager from 'utils/cookie_util'
import { useEffectSkipFirst } from 'utils/hooks/use_effect_skip_first'

type Params = { id?: string }
type Props = RouteComponentProps<Params, StaticContext, Record<string, never>>

type SubscriptionPlanFormValues = {
  subscriptionPlanType: SubscriptionPlanType
}

export const ShowLaboratory = (props: Props) => {
  const params = props.match.params
  const laboratoryId = Number(params.id)
  const planForm = useForm<SubscriptionPlanFormValues>({ defaultValues: { subscriptionPlanType: 'option' } })
  const labApi = useFetchAdminLaboratoryApi()
  const [laboratory, setLaboratory] = useState<Laboratory>({})
  const [enableInstrumentSharing, setEnableInstrumentSharing] = useState<boolean>(false)

  const usersApi = useFetchAdminLaboratoryUsersApi()
  const [users, setUsers] = useState<Laboratory[]>([])

  const managementLaboratoriesApi = useFetchAdminManagedLaboratoriesApi()
  const deleteManagementLaboratoryApi = useDeleteAdminManagedLaboratoryApi()
  const postAdminLaboratoryUpdateAuthorityApi = usePostAdminLaboratoryUpdateAuthorityApi()

  const globalState = useContext(GlobalStateContext)
  const authorityModal = useModal()
  const importModal = useModal()
  const fetchPlanApi = useFetchAdminLaboratoriesSubscriptionPlanApi(laboratoryId)
  const patchPlanApi = usePatchAdminLaboratoriesSubscriptionPlanApi(laboratoryId)

  const userAgentsApi = useAdminUserAgentsApi()

  useEffect(() => {
    labApi.execute(laboratoryId)
    usersApi.execute(laboratoryId)
    managementLaboratoriesApi.execute(laboratoryId)
    fetchPlanApi.refetch()
  }, [])

  useEffect(() => {
    if (!labApi.isSuccess()) return

    if (labApi.response.laboratory) {
      setLaboratory(labApi.response.laboratory)
      setEnableInstrumentSharing(Boolean(labApi.response.laboratory.authority?.enableInstrumentSharing))
    }
  }, [labApi.loading])

  useEffect(() => {
    if (!usersApi.isSuccess()) return

    if (usersApi.response.users) {
      setUsers(usersApi.response.users)
    }
  }, [usersApi.loading])

  useEffect(() => {
    if (!userAgentsApi.isSuccess()) return

    if (userAgentsApi.response.accessToken) {
      CookieManager.saveUserToken(userAgentsApi.response.accessToken)
      const targetUrl = `${window.location.protocol}//${window.location.host}/mypage/rooms`
      window.open(targetUrl)
    }
  }, [userAgentsApi.loading])

  const handleAgentLogin = (id: number) => {
    userAgentsApi.execute(id)
  }

  const handleDeleteManagedLaboratory = (managedLaboratoryId: number) => {
    globalState.confirm('本当に削除しますか？', (event) => {
      if (event === 'ok') {
        deleteManagementLaboratoryApi.execute(laboratoryId, managedLaboratoryId)
      }
    })
  }

  const handleClickImport = () => {
    importModal.setOpen(true)
  }

  const handleClickEnableInstrumentSharing = () => {
    postAdminLaboratoryUpdateAuthorityApi.mutate({
      laboratoryId: laboratoryId,
      enableInstrumentSharing: !enableInstrumentSharing,
    })
    setEnableInstrumentSharing(!enableInstrumentSharing)
  }

  useEffectSkipFirst(() => {
    if (deleteManagementLaboratoryApi.isSuccess()) {
      managementLaboratoriesApi.execute(laboratoryId)
    }
  }, [deleteManagementLaboratoryApi.loading])

  useEffect(() => {
    globalState.setLoading(postAdminLaboratoryUpdateAuthorityApi.isLoading)
  }, [postAdminLaboratoryUpdateAuthorityApi.isLoading])

  useEffect(() => {
    if (fetchPlanApi.data?.subscriptionPlan) {
      planForm.setValue('subscriptionPlanType', getSubscriptionPlanTypeFromLimitCount(fetchPlanApi.data?.subscriptionPlan?.limitCount))
    }
  }, [fetchPlanApi.data?.subscriptionPlan])

  const handleUpdatePlan = (value: string) => {
    patchPlanApi.mutate(
      { subscriptionPlanType: value },
      {
        onSuccess: () => {
          globalState.setNotificationMessage({ body: 'プランを変更しました' })
        },
      },
    )
  }

  return (
    <>
      <InstrumentImportModalContainer
        laboratoryId={laboratory.id!}
        modalState={importModal}
        onComplete={() => importModal.setOpen(false)}
      />
      <ContentHeader>
        <ContentTitle title={`ラボ詳細 - ${laboratory.name || ''}`}>
          <ThemedButton color="primary" onClick={handleClickImport}>
            機器インポート
          </ThemedButton>
        </ContentTitle>
      </ContentHeader>
      <Paper style={{ padding: 20, marginBottom: 20 }}>
        <TableContainer>
          <Flex justifyContent="space-between">
            <div style={{ fontWeight: 'bold', fontSize: 18 }}>管理しているラボ</div>
            <div>
              <ManagementLaboratoryRelationFormDialog
                managerLaboratoryId={laboratoryId}
                onComplete={() => managementLaboratoriesApi.execute(laboratoryId)}
              />
            </div>
          </Flex>
          <Table>
            <TableHead>
              <TableRow>
                <ExTableCell minWidth={300}>ラボ名</ExTableCell>
                <ExTableCell width={80} align="center"></ExTableCell>
              </TableRow>
            </TableHead>
            <ExTableBody loading={managementLaboratoriesApi.loading} emptyMessage="管理しているラボは存在しません">
              {managementLaboratoriesApi.response.laboratories.map((laboratory: Laboratory) => {
                return (
                  <ExTableRow key={laboratory.id}>
                    <ExTableCell>{laboratory.name}</ExTableCell>
                    <ExTableCell>
                      <ThemedButton color="error" onClick={() => handleDeleteManagedLaboratory(laboratory.id!)}>
                        削除
                      </ThemedButton>
                    </ExTableCell>
                  </ExTableRow>
                )
              })}
            </ExTableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Paper style={{ padding: 20 }}>
        <UserAuthorityFormDialog users={users} modalState={authorityModal} laboratoryId={laboratory?.id || 0} />
        <Flex justifyContent="space-between">
          <div style={{ fontWeight: 'bold', fontSize: 18 }}>ユーザー</div>
          {users.length > 0 && (
            <ThemedButton color="primary" onClick={() => authorityModal.setOpen(true)}>
              権限設定
            </ThemedButton>
          )}
        </Flex>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <ExTableCell minWidth={300}>ユーザー名</ExTableCell>
                <ExTableCell minWidth={200}>メールアドレス</ExTableCell>
                <ExTableCell width={160} align="center"></ExTableCell>
              </TableRow>
            </TableHead>
            <ExTableBody loading={usersApi.loading}>
              {users.map((user: User) => {
                return (
                  <ExTableRow key={user.id}>
                    <ExTableCell>{`${user.familyName} ${user.givenName}`}</ExTableCell>
                    <ExTableCell>{user.email}</ExTableCell>
                    <ExTableCell>
                      <ThemedButton color="secondary" onClick={() => handleAgentLogin(user.id!)}>
                        代行ログイン
                      </ThemedButton>
                    </ExTableCell>
                  </ExTableRow>
                )
              })}
            </ExTableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Paper style={{ padding: 20, marginTop: 20, width: '45%', marginBottom: 20 }}>
        <div style={{ fontWeight: 'bold', fontSize: 18 }}>機能設定</div>
        <TableContainer>
          <Table>
            <ExTableBody loading={false}>
              <ExTableRow>
                <ExTableCell>機器共有機能</ExTableCell>
                <ExTableCell>
                  <Switch checked={enableInstrumentSharing} onClick={handleClickEnableInstrumentSharing} />
                </ExTableCell>
              </ExTableRow>
            </ExTableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Paper style={{ padding: 20, marginTop: 20, width: '45%', marginBottom: 20 }}>
        <div style={{ fontWeight: 'bold', fontSize: 18 }}>プラン設定</div>
        <TableContainer>
          <Table>
            <ExTableBody loading={false}>
              <ExTableRow>
                <ExTableCell>現在の登録機器数</ExTableCell>
                <ExTableCell>
                  {labApi.response.laboratory.instrumentTotalCount} / {labApi.response.laboratory.subscriptionPlan?.limitCount || 'Unlimited'}
                </ExTableCell>
              </ExTableRow>
              <ExTableRow>
                <ExTableCell>プラン</ExTableCell>
                <ExTableCell>
                  <RhfSelectField
                    control={planForm.control}
                    name="subscriptionPlanType"
                    items={subscriptionPlanTypeSelectData}
                    onChange={(e) => handleUpdatePlan(e.target.value)}
                    disabled={planForm.getValues('subscriptionPlanType') === 'option'}
                  />
                </ExTableCell>
              </ExTableRow>
            </ExTableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  )
}

export default withRouter(ShowLaboratory)
