import { usePartnerFetchInquiryCommentsApi } from 'api/partner/inquiries/inquiry_comment'
import { usePartnerFetchInquiryApi } from 'api/partner/inquiry'
import { InquiryCommentFormView } from 'components/partner/inquiry/inquiry_comment_form_view'
import { InquiryStoreContext, pushComments, replaceComments, setInquiry } from 'components/partner/inquiry/inquiry_store'
import LoadingProgress from 'components/shared/loading_progress'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { InquiryAttachment, InquiryComment } from 'entities/inquiry_comment'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useEffectSkipFirst } from 'utils/hooks'
import { InquiryViewModel } from 'view_models/inquiry'
import { InquiryCommentViewModel } from 'view_models/inquiry_comment'

const headerHeight = 64
const inquiryHeaderMarginBottom = 15

type InquiryCommentsViewProps = {
  currentInquiryId: number
}

const senderType = 'Dealer'

export const InquiryCommentsView: React.FC<InquiryCommentsViewProps> = (props: InquiryCommentsViewProps) => {
  const { classes } = useStyles()
  const { currentInquiryId } = props
  const globalState = useContext(GlobalStateContext)
  const commentEndRef = useRef<HTMLDivElement>(null)
  const commentInnerRef = useRef<HTMLUListElement>(null)
  const formInnerRef = useRef<HTMLDivElement>(null)
  const inquiryHeaderHeightRef = useRef<HTMLDivElement>(null)

  const showApiSet = usePartnerFetchInquiryApi()
  const commentIndexApiSet = usePartnerFetchInquiryCommentsApi()

  const [beforeHeight, setBeforeHight] = useState(0)
  const store = useContext(InquiryStoreContext)

  /**
   * inquiry取得apiの読み込み開始・終了
   */
  useEffectSkipFirst(() => {
    globalState.setLoading(showApiSet.loading)
    if (!showApiSet.isSuccess()) return

    store.dispatch(setInquiry(showApiSet.response.inquiry))
    changeUrl(showApiSet.response.inquiry?.id)
  }, [showApiSet.loading])

  /**
   * ターゲットの問合せが変更
   */
  useEffect(() => {
    if (!currentInquiryId) return

    showApiSet.execute(currentInquiryId)
    commentIndexApiSet.executeAndResetPage(currentInquiryId)
    // コメントをブランクにリセット
    store.dispatch(replaceComments([]))
  }, [currentInquiryId])

  /**
   * コメント取得apiの開始・終了
   */
  useEffectSkipFirst(() => {
    if (!commentIndexApiSet.isSuccess()) return
    // コメントの読み込み完了
    store.dispatch(pushComments(commentIndexApiSet.response.comments))
  }, [commentIndexApiSet.loading])

  useEffect(() => {
    setTimeout(() => {
      if (!commentInnerRef?.current) return

      if (beforeHeight > 0) {
        const height = commentInnerRef.current.clientHeight - beforeHeight
        scrollTo(height)
        setBeforeHight(0)
      } else {
        scrollToBottom()
      }
    }, 10)
  }, [store.comments])

  /**
   * urlを変更する
   * @param id inquiryのid
   */
  const changeUrl = (id?: number) => {
    if (!id) return

    if (window.history && window.history.pushState) {
      window.history.pushState('reprua_partner', '', `/partner/inquiries/${id}`)
    }
  }

  // 既存問合せにコメント後
  const handleCreateComment = (comment: InquiryComment) => {
    const comments = [comment, ...store.comments]
    store.dispatch(replaceComments(comments))
  }

  // スクロール
  const scrollToBottom = () => {
    if (commentEndRef && commentEndRef.current && commentInnerRef && commentInnerRef.current) {
      commentEndRef.current.scrollTo(0, commentInnerRef.current.clientHeight)
    }
  }

  const scrollTo = (value: number) => {
    if (commentEndRef && commentEndRef.current) {
      commentEndRef.current.scrollTo(0, value)
    }
  }

  const calcHeight = () => {
    if (inquiryHeaderHeightRef?.current) {
      return window.innerHeight - inquiryHeaderHeightRef.current.clientHeight - inquiryHeaderMarginBottom - headerHeight
    } else {
      return 0
    }
  }

  const calcCommentTableHeight = () => {
    if (formInnerRef?.current && inquiryHeaderHeightRef?.current) {
      return (
        window.innerHeight -
        inquiryHeaderHeightRef.current.clientHeight -
        formInnerRef.current.clientHeight -
        inquiryHeaderMarginBottom -
        headerHeight -
        20
      )
    } else {
      return 0
    }
  }

  /**
   * 以前のメッセージ
   */
  const handleClickMoreMessage = () => {
    if (!commentInnerRef || !commentInnerRef.current) {
      return
    }
    setBeforeHight(commentInnerRef.current.clientHeight)
    commentIndexApiSet.pageSet.setPage(commentIndexApiSet.pageSet.page + 1)
  }

  const getInquirableView = () => {
    const inquiryVm = new InquiryViewModel(store.inquiry)
    return (
      <span style={{ fontSize: 12, marginLeft: 20 }}>
        <a href={inquiryVm.inquireablePath()}>{inquiryVm.inquireableNumber()}</a>
      </span>
    )
  }

  return (
    <>
      {store.inquiry && (
        <div className={classes.root}>
          <div ref={inquiryHeaderHeightRef}>
            <div className={classes.inquiryHeader}>
              <div className={classes.inquiryTitle}>
                {store.inquiry.subject}
                {getInquirableView()}
              </div>
            </div>
          </div>
          <div style={{ height: calcHeight() }}>
            <div ref={commentEndRef} style={{ overflowY: 'scroll', height: calcCommentTableHeight() }}>
              <ul ref={commentInnerRef} style={{ display: 'flex', flexDirection: 'column-reverse' }}>
                {commentIndexApiSet.loading && store.comments.length === 0 ? (
                  <LoadingProgress />
                ) : (
                  store.comments.map((comment) => {
                    const vm = new InquiryCommentViewModel(comment)
                    const commentRowClassName = `${classes.commentRow} ${
                      vm.isSent(senderType) ? classes.sentCommentRow : classes.receivedCommentRow
                    }`
                    const commentBodyClassName = `${classes.commentBody} ${
                      vm.isSent(senderType) ? classes.sentCommentBody : classes.receivedCommentBody
                    }`
                    const commentBoxClassName = `${classes.commentBox} ${
                      vm.isSent(senderType) ? classes.sentCommentBox : classes.receivedCommentBox
                    }`
                    const userIconClassName = `${classes.userIcon} ${
                      vm.isSent(senderType) ? classes.sentUserIcon : classes.receivedUserIcon
                    }`
                    const senderNameTextClassName = `${classes.senderNameText} ${
                      vm.isSent(senderType) ? classes.sentSenderNameText : classes.receivedSenderNameText
                    }`

                    return (
                      <li className={commentRowClassName} key={`comment-${store.inquiry.id}-${comment.id}`}>
                        <div className={commentBoxClassName}>
                          <div className={userIconClassName}>{vm.getIcon()}</div>
                          <div>
                            <div className={senderNameTextClassName}>
                              <div>
                                {comment.senderName}
                                <span className={classes.sendDateText}>{vm.sentAtText()}</span>
                              </div>
                            </div>
                            {comment.body && <div className={commentBodyClassName}>{vm.getBody()}</div>}
                            {comment.attachment && <InquiryAttachmentComponent attachment={comment.attachment} />}
                          </div>
                        </div>
                      </li>
                    )
                  })
                )}
                {commentIndexApiSet.response.totalCount > store.comments.length &&
                  store.comments.length > 0 &&
                  (commentIndexApiSet.loading ? (
                    <LoadingProgress />
                  ) : (
                    <div style={{ width: '100%', padding: 5 }}>
                      <ThemedButton
                        style={{ width: '100%', paddingBottom: 10, paddingTop: 10 }}
                        color="primary"
                        onClick={handleClickMoreMessage}
                      >
                        以前のメッセージを読み込む
                      </ThemedButton>
                    </div>
                  ))}
              </ul>
            </div>
            <div ref={formInnerRef}>
              <InquiryCommentFormView inquiryId={currentInquiryId} onComplete={handleCreateComment} />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const useStyles = makeStyles()({
  root: {
    width: '100%',
  },
  inquiryHeader: {
    padding: 15,
    marginBottom: 15,
  },
  inquiryTitle: {
    fontWeight: 'bold',
  },
  commentsTable: {
    padding: 15,
  },
  commentRow: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    marginBottom: 30,
  },
  sentCommentRow: {
    justifyContent: 'flex-end',
  },
  receivedCommentRow: {
    justifyContent: 'flex-start',
  },
  commentBox: {
    width: '90%',
  },
  sentCommentBox: {
    marginRight: 60,
  },
  receivedCommentBox: {
    marginLeft: 60,
  },
  senderNameText: { display: 'flex' },
  sentSenderNameText: {
    justifyContent: 'flex-end',
  },
  receivedSenderNameText: {
    justifyContent: 'flex-start',
  },
  sendDateText: {
    color: '#a3a3a5',
    marginLeft: 20,
  },
  commentBody: {
    padding: 15,
    marginTop: 6,
    borderRadius: 4,
  },
  sentCommentBody: {
    backgroundColor: '#d3ecf5',
  },
  receivedCommentBody: {
    backgroundColor: '#fff',
  },
  userIcon: {
    position: 'absolute',
    top: 5,
  },
  sentUserIcon: {
    right: 5,
  },
  receivedUserIcon: {
    left: 5,
  },
})

/**
 *  添付画像・ファイルの表示
 */
type InquiryAttachmentProps = {
  attachment: InquiryAttachment
}

const InquiryAttachmentComponent = (props: InquiryAttachmentProps) => {
  const { url, name, type } = props.attachment

  return (
    <>
      {type === 'image' ? (
        <div style={{ padding: 10 }}>
          <img src={url} alt={name} />
        </div>
      ) : (
        <div style={{ padding: 10 }}>
          <span>添付ファイル: </span>
          <a href={url}>{name}</a>
        </div>
      )}
    </>
  )
}

export default InquiryCommentsView
