import Login from 'pages/mypage/login/index'
import React from 'react'
import { Route, Switch } from 'react-router-dom'

const StaticPageRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path={StaticPageRouteHelper.root()}>
        <Login />
      </Route>
    </Switch>
  )
}

export default StaticPageRoutes

/**
 * ルート定義
 */
export class StaticPageRouteHelper {
  public static root = (): string => '/'
}
