import { Laboratory } from 'entities/laboratory'
import { Maker } from 'entities/maker'
import { ReservationConstraintRelation } from 'entities/reservation_constraint_relation'
import { User } from 'entities/user'
import moment from 'moment'

import { ViewModel } from '.'

export class ReservationConstraintRelationViewModel extends ViewModel<ReservationConstraintRelation> {
  public constraintTargetableName = () => {
    if (this.object.constraintTargetableType === 'User') {
      const user = this.object.constraintTargetable as User
      return `${user.familyName} ${user.givenName}`
    } else if (this.object.constraintTargetableType === 'Laboratory') {
      return (this.object.constraintTargetable as Laboratory).name
    } else if (this.object.constraintTargetableType === 'All') {
      return '利用者全体'
    }
  }
}
