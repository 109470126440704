import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { Table, TableHead, TableRow, Paper, TableContainer, Tooltip } from '@mui/material'
import { useFetchApprovalSettingsApi } from 'api/mypage/approval_setting'
import { useFetchCancelPoliciesApi } from 'api/mypage/cancel_policy'
import { useFetchInstrumentsApi, useExportInstrumentsApi } from 'api/mypage/instrument'
import { useFetchInstrumentCategoriesApi } from 'api/mypage/instrument_categories'
import { useFetchPartnersApi } from 'api/mypage/partner'
import { useFetchRoomsApi } from 'api/mypage/room'
import { useFetchExColumnsApi } from 'api/shared/ex_columns'
import { NewDefectReportFormDialog, NewDefectReportWithEstimateFormDialog } from 'components/mypage/defect_report/defect_report_form_dialog'
import { NewInstrumentFormDialog, EditInstrumentFormDialog } from 'components/mypage/instrument/instrument_form_dialog'
import { NewRepairEventFormDialog } from 'components/mypage/repair_event/repair_event_form_dialog'
import { ContainedIconButton } from 'components/shared/contained_icon_button'
import { ContentHeader, ContentTitle } from 'components/shared/content'
import DropDownCheckbox from 'components/shared/dropdown_checkbox'
import { Flex } from 'components/shared/flex'
import { SelectField } from 'components/shared/form/input'
import ImageView from 'components/shared/image_view'
import { useMultipleModal } from 'components/shared/modal'
import Pagination from 'components/shared/pagination'
import { SearchBar } from 'components/shared/search_bar'
import { SubscriptionPlanLimitCount } from 'components/shared/subscription_plan_limit_count'
import { ExTableCell, OrderTableCell, ExTableBody } from 'components/shared/table'
import { DisplaySettingModal } from 'components/shared/table/display_setting'
import { TableExAttributesInfo } from 'components/shared/table_ex_attributes_info'
import ThemedButton from 'components/shared/themed_button'
import { MypageStateContext } from 'contexts/mypage_state_context'
import { Instrument, InstrumentSearchForm } from 'entities/instrument'
import { Room } from 'entities/room'
import { IndexApiState, useForm, Order } from 'rac'
import React, { useContext, useEffect } from 'react'
import { StaticContext } from 'react-router'
import { withRouter, RouteComponentProps, useLocation } from 'react-router-dom'
import { MypageRouteHelper } from 'routes/mypage'
import { INDEX_TABLE_PADDING, getTableHeight } from 'utils/component_heights'
import { useDisplaySetting, useEffectSkipFirst } from 'utils/hooks/index'
import { booleanFilterOptions, booleanFilterValues } from 'utils/utils'
import { InstrumentViewModel } from 'view_models/instrument'

import './index.scss'

const initialDisplaySettings = [
  { attr: 'createdAt', name: '作成日', visible: true },
  { attr: 'maker', name: 'メーカー', visible: true },
  { attr: 'room', name: '部屋', visible: true },
  { attr: 'purchasedAt', name: '取得日', visible: true },
  { attr: 'purchasedPrice', name: '取得価額', visible: true },
]

export type InstrumentIndexProps = {
  roomId?: number
  calendar: boolean
}

type Props = RouteComponentProps<undefined, StaticContext, InstrumentIndexProps>

const InstrumentIndex: React.FC<Props> = (props: Props) => {
  // クエリパラメータの取得
  const search = useLocation().search
  const query = new URLSearchParams(search)

  const searchForm = useForm<InstrumentSearchForm>({
    q: { instrumentRoomIdEq: query.get('room_id') ? Number(query.get('room_id')) : props.location.state?.roomId },
    searchText: query.get('q') || undefined,
    canReserveFilter: query.get('reserve')?.split(',') ?? booleanFilterValues,
    requireProofreadFilter: query.get('proofread')?.split(',') ?? booleanFilterValues,
    sharingInstrumentFilter: query.get('sharing')?.split(',') ?? booleanFilterValues,
  })
  const initialState: Partial<IndexApiState> = {
    ...(query.get('order') && { order: query.get('order') as Order }),
    ...(query.get('order_by') && { orderBy: query.get('order_by') as string }),
    ...(query.get('page') && { page: Number(query.get('page')) }),
    ...(query.get('perPage') && { perPage: Number(query.get('perPage')) }),
  }
  const instrumentIndexApi = useFetchInstrumentsApi(searchForm, initialState)
  const [editDialogOpen, setEditDialogOpen] = React.useState(false)
  const [editInstrument, setEditInstrument] = React.useState<Instrument>({})
  const displaySettingSet = useDisplaySetting(initialDisplaySettings, 'instruments-table')
  const roomIndexApi = useFetchRoomsApi({ perPage: 1000 })
  const categoryIndexApi = useFetchInstrumentCategoriesApi()
  const { currentUser } = useContext(MypageStateContext)
  const cancelPolicyIndexApi = useFetchCancelPoliciesApi()
  const suppliersIndexApi = useFetchPartnersApi()
  const approvalsIndexApi = useFetchApprovalSettingsApi()
  const mypageState = useContext(MypageStateContext)
  const exportApi = useExportInstrumentsApi(searchForm)
  const newDefectReportModal = useMultipleModal<Instrument>()
  const newReportWithEstimateModal = useMultipleModal<Instrument>()
  const newRepairEventModal = useMultipleModal<Instrument>()
  const exColumnApi = useFetchExColumnsApi('user')

  useEffect(() => {
    roomIndexApi.execute()
    instrumentIndexApi.execute()
    cancelPolicyIndexApi.execute()
    categoryIndexApi.execute()
    suppliersIndexApi.execute()
    approvalsIndexApi.execute()
    exColumnApi.execute()
  }, [])

  useEffectSkipFirst(() => {
    pushHistory()
  }, [
    instrumentIndexApi.orderSet.order,
    instrumentIndexApi.orderSet.orderBy,
    instrumentIndexApi.pageSet.page,
    instrumentIndexApi.pageSet.perPage
  ])

  const handleCreate = () => {
    instrumentIndexApi.execute()
    // 所有中の機器数を更新する
    mypageState.reloadInstrumentTotalCount()
  }

  const handleUpdate = () => {
    instrumentIndexApi.execute()
    setEditDialogOpen(false)
  }

  const handleDelete = () => {
    instrumentIndexApi.execute()
    setEditDialogOpen(false)
  }

  const handleClickEditButton = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, instrument: Instrument) => {
    e.stopPropagation()
    setEditInstrument(instrument)
    setEditDialogOpen(true)
  }

  const handleClickDetail = (instrument: Instrument) => {
    props.history.push(MypageRouteHelper.instrumentShow(instrument.id!))
  }

  const handleSearch = () => {
    instrumentIndexApi.executeAndPageReset()
    pushHistory()
  }

  const handleClickExport = (format: 'xlsx') => {
    exportApi.execute(format)
  }

  const handleClickUnmanagedInstrumentsLink = () => {
    props.history.push(MypageRouteHelper.unmanagedInstrumentIndex())
  }

  // 検索条件をURLに保持
  const pushHistory = () => {
    const order = instrumentIndexApi.orderSet.order
    const orderBy = instrumentIndexApi.orderSet.orderBy
    const page = instrumentIndexApi.pageSet.page
    const perPage = instrumentIndexApi.pageSet.perPage
    const params = {
      ...(searchForm.object.searchText && { q: searchForm.object.searchText }),
      ...(searchForm.object.q.instrumentRoomIdEq && { room_id: searchForm.object.q.instrumentRoomIdEq?.toString() }),
      ...(searchForm.object.canReserveFilter && { reserve: searchForm.object.canReserveFilter?.join(',') }),
      ...(searchForm.object.requireProofreadFilter && { proofread: searchForm.object.requireProofreadFilter?.join(',') }),
      ...(searchForm.object.sharingInstrumentFilter && { sharing: searchForm.object.sharingInstrumentFilter?.join(',') }),
      ...((order && orderBy) && { order: order, order_by: orderBy }),
      ...(page && { page: page.toString() }),
      ...(perPage && { perPage: perPage.toString() })
    }
    const queryParams = new URLSearchParams(params).toString();
    props.history.push(`${window.location.pathname}?${queryParams}`)
  }

  return (
    <>
      <NewDefectReportFormDialog
        modalState={newDefectReportModal.modalState}
        instrumentId={newDefectReportModal.item.id}
        onComplete={() => newDefectReportModal.closeModal()}
      />
      <NewDefectReportWithEstimateFormDialog
        modalSet={newReportWithEstimateModal}
        onComplete={() => newReportWithEstimateModal.closeModal()}
        outsideDealers={suppliersIndexApi.response.outsideDealers}
      />
      <NewRepairEventFormDialog
        modalState={newRepairEventModal.modalState}
        instrumentId={newRepairEventModal.item.id}
        onComplete={() => newRepairEventModal.closeModal()}
      />
      <EditInstrumentFormDialog
        onComplete={handleUpdate}
        onCompleteExcludeManagement={handleDelete}
        instrument={editInstrument}
        open={editDialogOpen}
        onCancel={() => setEditDialogOpen(false)}
        rooms={roomIndexApi.response?.rooms}
        instrumentCategories={categoryIndexApi.response?.instrumentCategories}
        cancelPolicies={cancelPolicyIndexApi.response?.cancelPolicies}
        dealers={suppliersIndexApi.response.dealers}
        outsideDealers={suppliersIndexApi.response.outsideDealers}
        approvalSettings={approvalsIndexApi.response.approvalSettings}
        onCompleteRemoveImage={instrumentIndexApi.execute}
      />
      <ContentHeader
        subHeader={
          <>
            <SubscriptionPlanLimitCount />
            <ThemedButton variant="text" onClick={handleClickUnmanagedInstrumentsLink} style={{ marginRight: 10 }}>
              管理対象外の機器一覧
            </ThemedButton>
            <DisplaySettingModal displaySettingSet={displaySettingSet} />
            <ThemedButton color="secondary" onClick={() => handleClickExport('xlsx')} style={{ marginLeft: 10 }}>
              Excel出力
            </ThemedButton>
          </>
        }
      >
        <ContentTitle title="機器一覧">
          {currentUser.authority?.instrument && (
            <NewInstrumentFormDialog
              onComplete={handleCreate}
              rooms={roomIndexApi.response?.rooms}
              instrumentCategories={categoryIndexApi.response?.instrumentCategories}
              cancelPolicies={cancelPolicyIndexApi.response.cancelPolicies}
              dealers={suppliersIndexApi.response.dealers}
              outsideDealers={suppliersIndexApi.response.outsideDealers}
              approvalSettings={approvalsIndexApi.response.approvalSettings}
            />
          )}
        </ContentTitle>
        <Flex alignItems="center">
          <SelectField
            label="部屋"
            width={200}
            form={searchForm}
            attr={['q', 'instrumentRoomIdEq']}
            labelId="search-select-room"
            data={
              roomIndexApi.response?.rooms
                ? roomIndexApi.response.rooms.map((room: Room) => {
                  return { value: room.id!, label: room.name! }
                })
                : []
            }
            includeBlank
          />
          <SearchBar
            form={searchForm}
            searchAttr="searchText"
            placeholder="メーカー・製品名・型番・シリアル番号・管理番号で検索"
            onSubmit={handleSearch}
          />
        </Flex>
      </ContentHeader>
      <Paper>
        <TableContainer style={{ maxHeight: getTableHeight(INDEX_TABLE_PADDING) }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <ExTableCell width={150}></ExTableCell>
                <OrderTableCell
                  width={100}
                  open={displaySettingSet.isVisible('createdAt')}
                  align="center"
                  orderAttr="createdAt"
                  orderSet={instrumentIndexApi.orderSet}
                >
                  登録日
                </OrderTableCell>
                <OrderTableCell
                  width={120}
                  open={displaySettingSet.isVisible('maker')}
                  orderAttr="maker"
                  orderSet={instrumentIndexApi.orderSet}
                >
                  メーカー
                </OrderTableCell>
                <OrderTableCell minWidth={230} orderAttr="name" orderSet={instrumentIndexApi.orderSet}>
                  <div>
                    型番 / シリアル番号 / 管理番号 / 製品名
                    {exColumnApi.response.exColumns
                      .filter((col) => col.visibleOnTable)
                      .map((col) => (
                        <div style={{ fontSize: 12, lineHeight: '12px' }} key={`tablecol-${col.id}`}>
                          {col.name}
                        </div>
                      ))}
                  </div>
                </OrderTableCell>
                <ExTableCell width={120} open={displaySettingSet.isVisible('room')}>
                  部屋
                </ExTableCell>
                <ExTableCell width={120} open={displaySettingSet.isVisible('currentState')} align="center">
                  ステータス
                </ExTableCell>
                <OrderTableCell
                  width={100}
                  open={displaySettingSet.isVisible('purchasedAt')}
                  align="center"
                  orderAttr="purchasedAt"
                  orderSet={instrumentIndexApi.orderSet}
                >
                  取得日
                </OrderTableCell>
                <OrderTableCell
                  width={100}
                  open={displaySettingSet.isVisible('purchasedPrice')}
                  align="center"
                  orderAttr="purchasedPrice"
                  orderSet={instrumentIndexApi.orderSet}
                >
                  取得価額
                </OrderTableCell>
                <ExTableCell width={130}>
                  <DropDownCheckbox
                    form={searchForm}
                    attr="canReserveFilter"
                    name="canReserveFilter"
                    items={booleanFilterOptions}
                    title={
                      <div>
                        <div>カレンダー</div>
                        <div>予約</div>
                      </div>
                    }
                    onSubmit={handleSearch}
                    btnStyle={{ padding: '4px 8px' }}
                    isLeft={true}
                    maxWidth={600}
                    showFilter
                  />
                </ExTableCell>
                <ExTableCell width={120}>
                  <DropDownCheckbox
                    form={searchForm}
                    attr="requireProofreadFilter"
                    name="requireProofreadFilter"
                    items={booleanFilterOptions}
                    title="校正管理"
                    onSubmit={handleSearch}
                    btnStyle={{ padding: '4px 8px' }}
                    isLeft={true}
                    maxWidth={600}
                    showFilter
                  />
                </ExTableCell>
                {currentUser.laboratory?.authority?.enableInstrumentSharing && (
                  <ExTableCell width={120}>
                    <DropDownCheckbox
                      form={searchForm}
                      attr="sharingInstrumentFilter"
                      name="sharingInstrumentFilter"
                      items={booleanFilterOptions}
                      title="機器共有"
                      onSubmit={handleSearch}
                      btnStyle={{ padding: '4px 8px' }}
                      isLeft={true}
                      maxWidth={600}
                      showFilter
                    />
                  </ExTableCell>
                )}
                <ExTableCell width={100} align="center" />
              </TableRow>
            </TableHead>
            <ExTableBody loading={instrumentIndexApi.loading}>
              {instrumentIndexApi.response?.instruments.map((instrument: Instrument, idx: number) => {
                const instrumentVM = new InstrumentViewModel(instrument)
                return (
                  <TableRow key={idx} hover={true} onClick={() => handleClickDetail(instrument)} sx={{ cursor: 'pointer' }}>
                    <ExTableCell align="center">
                      <ImageView maxHeight={100} maxWidth={100} src={instrument.instrumentBasicInfo?.imageUrl} />
                    </ExTableCell>
                    <ExTableCell open={displaySettingSet.isVisible('createdAt')} align="center">
                      {instrumentVM.registeredAt()}
                    </ExTableCell>
                    <ExTableCell open={displaySettingSet.isVisible('maker')}>{instrumentVM.getMakerName()}</ExTableCell>
                    <ExTableCell>
                      <div style={{ fontSize: 11, marginBottom: 5 }}>{instrumentVM.getInstrumentNumbers()}</div>
                      <div style={{ fontSize: 16 }}>{instrumentVM.getInstrumentName()}</div>
                      {instrumentVM.getExternalLink()}
                      <TableExAttributesInfo tableExAttrs={instrument.tableExAttrs || []} id={instrument.id} />
                    </ExTableCell>
                    <ExTableCell open={displaySettingSet.isVisible('room')}>{instrumentVM.roomName()}</ExTableCell>
                    <ExTableCell align="center" open={displaySettingSet.isVisible('currentState')}>
                      {instrumentVM.currentStateView()}
                    </ExTableCell>
                    <ExTableCell align="center" open={displaySettingSet.isVisible('purchasedAt')}>
                      {instrumentVM.purchasedAtText()}
                    </ExTableCell>
                    <ExTableCell align="center" open={displaySettingSet.isVisible('purchasedPrice')}>
                      {instrumentVM.purchasedPriceText()}
                    </ExTableCell>
                    <ExTableCell align="center">
                      {instrument.canReserve ? (
                        <ContainedIconButton
                          color="primary"
                          onClick={(e) => {
                            e.stopPropagation()
                            props.history.push({
                              pathname: MypageRouteHelper.instrumentShow(instrument.id!),
                              state: { calendar: true },
                            })
                          }}
                        >
                          <CalendarTodayIcon style={{ color: 'white', fontSize: 20 }} />
                        </ContainedIconButton>
                      ) : (
                        '予約不可'
                      )}
                    </ExTableCell>
                    <ExTableCell align="center">
                      {instrument.requireProofread && (
                        <>
                          {instrument.isLastProofreadNg ? (
                            <label
                              style={{ border: '2px solid #5DA2CA', color: '#5DA2CA', borderRadius: 5, padding: '2px 3px', fontSize: 12 }}
                            >
                              不合格
                            </label>
                          ) : (
                            <CheckCircleOutlineIcon style={{ color: 'green' }} />
                          )}
                        </>
                      )}
                    </ExTableCell>
                    {currentUser.laboratory?.authority?.enableInstrumentSharing && (
                      <ExTableCell align="center">
                        {instrument.isShared && <CheckCircleOutlineIcon style={{ color: 'green' }} />}
                      </ExTableCell>
                    )}
                    <ExTableCell width={100} align="center">
                      <Flex flexDirection="column">
                        {currentUser.authority?.instrument && (
                          <Tooltip title={!instrumentVM.isInstrumentOwner(currentUser.laboratory!) ? '他社の機器は編集出来ません' : ''}>
                            <div>
                              <ThemedButton
                                disabled={!instrumentVM.isInstrumentOwner(mypageState.currentUser.laboratory!)}
                                color="secondary"
                                size="small"
                                onClick={(e) => handleClickEditButton(e, instrument)}
                              >
                                編集
                              </ThemedButton>
                            </div>
                          </Tooltip>
                        )}
                      </Flex>
                    </ExTableCell>
                  </TableRow>
                )
              })}
            </ExTableBody>
          </Table>
        </TableContainer>
        <Pagination response={instrumentIndexApi.response} pageSet={instrumentIndexApi.pageSet} />
      </Paper>
    </>
  )
}

export default withRouter(InstrumentIndex)
