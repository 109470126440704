import { SidebarContext } from 'contexts/sidebar_context'
import React, { ReactNode } from 'react'

type SidebarContainerProps = {
  children: ReactNode
}

const SidebarContainer = (props: SidebarContainerProps) => {
  const [activeLabDrawerMenuId, setActiveLabDrawerMenuId] = React.useState<number>(0)
  const [activeLabDrawerSubMenuId, setActiveLabDrawerSubMenuId] = React.useState<number>(0)
  const [activeDealerDrawerMenuId, setActiveDealerDrawerMenuId] = React.useState<number>(0)
  const [activeDealerDrawerSubMenuId, setActiveDealerDrawerSubMenuId] = React.useState<number>(0)
  const [activeAdminDrawerMenuId, setActiveAdminDrawerMenuId] = React.useState<number>(0)

  return (
    <SidebarContext.Provider
      value={{
        activeLabDrawerMenuId,
        setActiveLabDrawerMenuId,
        activeLabDrawerSubMenuId,
        setActiveLabDrawerSubMenuId,
        activeDealerDrawerMenuId,
        setActiveDealerDrawerMenuId,
        activeDealerDrawerSubMenuId,
        setActiveDealerDrawerSubMenuId,
        activeAdminDrawerMenuId,
        setActiveAdminDrawerMenuId,
      }}
    >
      {props.children}
    </SidebarContext.Provider>
  )
}

export default SidebarContainer
