import { Link } from '@mui/material'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { PartnerRouteHelper } from 'routes/partner'

export const NoSelectableOutsideLaboratory = () => {
  const history = useHistory()

  return (
    <div>
      登録可能な顧客がいません。
      <Link
        onClick={() => history.push({ pathname: `${PartnerRouteHelper.settingShow()}`, state: { settingItemKey: 'outsideLaboratories' } })}
      >
        外部顧客を追加するにはこちら。
      </Link>
    </div>
  )
}
