import { Tooltip } from '@mui/material'
import { useFetchPartnersApi } from 'api/mypage/partner'
import { usePostEstimateRepairApprovalApi } from 'api/mypage/repair_approval'
import { useFetchRepairInfoApi } from 'api/mypage/repair_info'
import DefectReportDataCard from 'components/mypage/defect_report/defect_report_data_card'
import InstrumentDataCard from 'components/mypage/instrument/instrument_data_card'
import CreateApproveFormDialog from 'components/mypage/repair_estimate/create_approve_form_dialog'
import RepairEstimateDataCard from 'components/mypage/repair_estimate/repair_estimate_data_card'
import RepairEventDataCard from 'components/mypage/repair_event/repair_event_data_card'
import { NewRepairEventFormDialogFromEstimate } from 'components/mypage/repair_event/repair_event_form_dialog'
import { ContentHeader, ContentTitle } from 'components/shared/content'
import { Flex } from 'components/shared/flex'
import Footer from 'components/shared/footer'
import { useModal, useMultipleModal } from 'components/shared/modal'
import StatusLabel from 'components/shared/status_label'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { MypageStateContext } from 'contexts/mypage_state_context'
import { DefectReport } from 'entities/defect_report'
import { ProofreadEvent } from 'entities/proofread_event'
import { RepairApprovalForm } from 'entities/repair_approval'
import { RepairEstimate } from 'entities/repair_estimate'
import { RepairEvent } from 'entities/repair_event'
import { useForm } from 'rac'
import React, { useContext, useEffect, useState } from 'react'
import { StaticContext } from 'react-router'
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom'
import { MypageRouteHelper } from 'routes/mypage'
import { RepairEstimateViewModel } from 'view_models/repair_estimate'
import { RepairEventViewModel } from 'view_models/repair_event'

type Props = RouteComponentProps<{ id: string }, StaticContext>
export const RepairInfoShow: React.FC<Props> = (props) => {
  const fetchApi = useFetchRepairInfoApi()

  const globalState = useContext(GlobalStateContext)
  const { currentUser } = useContext(MypageStateContext)
  const history = useHistory()

  const postApprovalForm = useForm<RepairApprovalForm>({ repairInfoId: Number(props.match.params.id) })
  const postApprovalApi = usePostEstimateRepairApprovalApi()
  const partnersApi = useFetchPartnersApi()

  const createModal = useMultipleModal<RepairEstimate>()
  const approvalModal = useModal()

  const [approved, setApproved] = useState(false)
  const [vmRepairEstimate, setVmRepairEstimate] = useState<RepairEstimateViewModel>()
  const [vmRepairEvent, setVmRepairEvent] = useState<RepairEventViewModel>()

  const refetch = () => {
    fetchApi.execute(Number(props.match.params.id))
  }

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    globalState.setLoading(fetchApi.loading)
    if (fetchApi.isSuccess()) {
      if (fetchApi.response.repairInfo.repairEvent != undefined) {
        setVmRepairEvent(new RepairEventViewModel(fetchApi.response.repairInfo.repairEvent))
      }
      if (fetchApi.response.repairInfo.repairEstimate != undefined) {
        setVmRepairEstimate(new RepairEstimateViewModel(fetchApi.response.repairInfo.repairEstimate))
      }
    }
  }, [fetchApi.loading])

  const handleCompleteCreateHistory = () => {
    createModal.closeModal()
    refetch()
  }

  const handleCompleteEditEvent = (repairEvent?: RepairEvent) => {
    if (repairEvent) {
      refetch()
    } else {
      // 削除された場合
      props.history.push(MypageRouteHelper.repairEventIndex())
    }
  }

  const onCompleteCreateEstimate = () => {
    refetch()
  }

  const handleCompleteEditEstimate = () => {
    refetch()
  }

  const handleCompleteEditReport = (report?: DefectReport) => {
    if (report) {
      refetch()
    } else {
      history.push(MypageRouteHelper.defectReportIndex())
    }
  }

  const handleClickLinkProofreadEvent = (proofreadEvent: ProofreadEvent | undefined) => {
    const planId = proofreadEvent?.proofreadPlanId
    if (planId) {
      props.history.push(MypageRouteHelper.proofreadPlanShow(planId))
    }
  }

  const handleApproved = (approved: boolean) => {
    setApproved(approved)
    approvalModal.setOpen(true)
  }

  const handleCompleteApproved = () => {
    refetch()
    approvalModal.setOpen(false)
  }

  const handleCreateApproval = () => {
    globalState.confirm('修理の承認申請をします。よろしいですか？', (e) => {
      if (e === 'ok') {
        postApprovalApi.execute(postApprovalForm)
      }
    })
  }

  const canEdit = () => {
    const repairEvent = fetchApi.response.repairInfo.repairEvent
    const proofreadEvent = repairEvent?.proofreadEvent
    if (
      // ラボが作成した修理履歴か
      repairEvent &&
      repairEvent.suppliableType !== 'Dealer' &&
      repairEvent.ownerableType === 'Laboratory' &&
      repairEvent.ownerableId === currentUser.laboratoryId
    ) {
      if (proofreadEvent && (proofreadEvent.aasmState === 'fix_result' || proofreadEvent?.aasmState === 'approved_result')) {
        // 校正結果入力後は編集不可
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  }

  const setButton = (estimate: RepairEstimate) => {
    if (estimate.aasmState === 'estimated' && !estimate.sharedEstimate) {
      if (!estimate.instrument?.approvalSettingId || estimate.repairApproval?.aasmState === 'approved' || estimate.skipApproval) {
        return (
          <ThemedButton color="success" onClick={() => createModal.openModal(estimate)}>
            修理を依頼
          </ThemedButton>
        )
      } else {
        if (estimate.repairApproval) {
          if (estimate.repairApproval?.aasmState === 'rejected') {
            return <></>
          } else if (!estimate.approverRequest && estimate.repairApproval.nextApprovers?.includes(currentUser.id || 0)) {
            return (
              <>
                <ThemedButton color="success" onClick={() => handleApproved(true)}>
                  申請を承認
                </ThemedButton>
                <ThemedButton color="error" onClick={() => handleApproved(false)}>
                  申請を却下
                </ThemedButton>
              </>
            )
          } else {
            return <></>
          }
        } else {
          return (
            <ThemedButton color="success" onClick={handleCreateApproval}>
              修理の許可申請
            </ThemedButton>
          )
        }
      }
    } else {
      return <></>
    }
  }

  return (
    <>
      <CreateApproveFormDialog
        modalState={approvalModal}
        repairInfoId={Number(props.match.params.id)}
        approved={approved}
        onComplete={handleCompleteApproved}
      />
      <NewRepairEventFormDialogFromEstimate
        modalSet={createModal}
        onComplete={handleCompleteCreateHistory}
        canReserve={fetchApi.response.repairInfo.repairEstimate?.instrument?.canReserve}
      />
      {fetchApi.isSuccess() && (
        <>
          <ContentHeader>
            <ContentTitle title="修理情報詳細">
              {vmRepairEstimate && <StatusLabel text={vmRepairEstimate.getStatusText()} color={vmRepairEstimate.getStatusColor()} />}
              {vmRepairEvent && <StatusLabel text={vmRepairEvent.getResultText()} color={vmRepairEvent.getResultColor()} />}
            </ContentTitle>
          </ContentHeader>

          <Flex style={{ marginBottom: 60 }}>
            {fetchApi.response.repairInfo.instrument && <InstrumentDataCard instrument={fetchApi.response.repairInfo.instrument} />}
            <Flex flexDirection="column" style={{ flexBasis: '100%' }}>
              <DefectReportDataCard
                defectReport={fetchApi.response.repairInfo.defectReport}
                canEdit={
                  fetchApi.response.repairInfo.defectReport &&
                  !fetchApi.response.repairInfo.defectReport.repairEstimate &&
                  !fetchApi.response.repairInfo.defectReport.repairEvent &&
                  (!fetchApi.response.repairInfo.defectReport.sharedReport ||
                    isMyReport(fetchApi.response.repairInfo.defectReport, currentUser.id!))
                }
                outsideDealers={partnersApi.response.outsideDealers}
                onCompleteCreateEstimate={fetchApi.response.repairInfo.defectReport?.sharedReport ? undefined : onCompleteCreateEstimate}
                onCompleteCreateEvent={fetchApi.response.repairInfo.defectReport?.sharedReport ? undefined : onCompleteCreateEstimate}
                onCompleteEdit={
                  fetchApi.response.repairInfo.defectReport && isMyReport(fetchApi.response.repairInfo.defectReport, currentUser.id!)
                    ? handleCompleteEditReport
                    : undefined
                }
                emptyMessage="不具合報告が存在しません"
                dealer={fetchApi.response.repairInfo.instrument?.dealer}
              />
              <RepairEstimateDataCard
                repairEstimate={fetchApi.response.repairInfo.repairEstimate}
                canEdit={fetchApi.response.repairInfo.repairEstimate ? setCanEdit(fetchApi.response.repairInfo.repairEstimate) : false}
                onCompleteEdit={handleCompleteEditEstimate}
                emptyMessage="修理見積が存在しません"
              />
              <RepairEventDataCard
                repairEvent={fetchApi.response.repairInfo.repairEvent}
                onCompleteUpdate={handleCompleteEditEvent}
                canEdit={canEdit()}
                emptyMessage="修理履歴が存在しません"
              />
            </Flex>
          </Flex>

          <Footer
            content={
              <Flex>
                {fetchApi.response.repairInfo.repairEstimate && setButton(fetchApi.response.repairInfo.repairEstimate)}
                {fetchApi.response.repairInfo.proofreadEvent && (
                  <Tooltip title={!currentUser.authority?.proofread ? '校正管理権限がないため使用できません' : ''}>
                    <div>
                      <ThemedButton
                        color="primary"
                        onClick={() => handleClickLinkProofreadEvent(fetchApi.response.repairInfo.proofreadEvent)}
                        disabled={!currentUser.authority?.proofread}
                      >
                        校正計画
                      </ThemedButton>
                    </div>
                  </Tooltip>
                )}
                <ThemedButton color="secondary" onClick={history.goBack}>
                  戻る
                </ThemedButton>
              </Flex>
            }
          />
        </>
      )}
    </>
  )
}

export default withRouter(RepairInfoShow)

const isMyReport = (report: DefectReport, userId: number) => {
  return report.reporterableType === 'User' && report.reporterableId === userId
}

const setCanEdit = (estimate: RepairEstimate) => {
  return (
    // ディラー委託でない・承認申請していない・修理結果のない見積は編集できる
    estimate.suppliableType !== 'Dealer' && !estimate.repairApproval && !estimate.repairEvent && !estimate.sharedEstimate
  )
}
