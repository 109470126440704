import StatusLabel from 'components/shared/status_label'
import theme from 'config/theme'
import { ProofreadApprover } from 'entities/proofread_approver'
import React from 'react'
import { ViewModel } from 'view_models'

export class ProofreadApproverViewModel extends ViewModel<ProofreadApprover> {
  public getApproveLabel = () => {
    if (!this.object.aasmState) return
    switch (this.object.aasmState) {
      case 'approved':
        return <label style={{ border: '2px solid #EB978E', color: '#EB978E', borderRadius: 5, padding: 5 }}>承認</label>
      case 'rejected':
        return <label style={{ border: '2px solid #5DA2CA', color: '#5DA2CA', borderRadius: 5, padding: 5 }}>却下</label>
      default:
        return <label style={{ border: '2px solid #808080', color: '#808080', borderRadius: 5, padding: 5 }}>未承認</label>
    }
  }

  public getApprovalNote = () => {
    return this.object.note || ''
  }

  public isYet = (): boolean => {
    return this.object.aasmState === 'yet'
  }

  public isApproved = (): boolean => {
    return this.object.aasmState === 'approved'
  }

  public getCurrentUserApproveLabel = () => {
    if (!this.object.aasmState) return
    const style = { width: 75, margin: 'auto' }

    switch (this.object.aasmState) {
      case 'approved':
        return <StatusLabel text="承認済み" color="#808080" style={style} />
      case 'rejected':
        return <StatusLabel text="却下" color="#5DA2CA" style={style} />
      default:
        return <StatusLabel text="承認待ち" color={theme.palette.error.main} style={style} />
    }
  }
}
