import { AxiosHttpClient } from 'api/axios'
import { useRepruaIndexApi, useRepruaShowApi } from 'api/shared_api_hooks'
import { ID } from 'entities'
import { Laboratory } from 'entities/laboratory'
import { ApiSet, BaseResponse, IndexApiSet } from 'rac'

type LaboratoriesResponse = BaseResponse & {
  laboratories: Laboratory[]
}
/**
 * 一覧
 */
export function useFetchAdminLaboratoriesApi(): IndexApiSet<LaboratoriesResponse> & { execute: () => void } {
  const api = useRepruaIndexApi<LaboratoriesResponse, {}>(new AxiosHttpClient('admin'), {
    initialResponse: { laboratories: [] },
    initialState: { perPage: 1000 },
  })

  const execute = () => {
    const path = 'laboratories'
    api.execute(path)
  }

  return { ...api, execute }
}

type LaboratoryResponse = BaseResponse & {
  laboratory: Laboratory
}
/**
 * 取得
 */
export function useFetchAdminLaboratoryApi(): ApiSet<LaboratoryResponse> & { execute: (id: ID) => void } {
  const api = useRepruaShowApi<LaboratoryResponse, { id: ID }>(new AxiosHttpClient('admin'), { initialResponse: { laboratory: {} } })

  const execute = (id: ID) => {
    const path = `laboratories/${id}`
    api.execute(path, { id: id })
  }

  return { ...api, execute }
}
