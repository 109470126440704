import { AxiosHttpClient, HttpClientType } from 'api/axios'
import { useRepruaDeleteApi } from 'api/shared_api_hooks'
import { ID } from 'entities'
import { BaseResponse } from 'rac'

/**
 * 一覧
 */
export const useDeleteInstrumentImageApi = (clientType: HttpClientType) => {
  const api = useRepruaDeleteApi<BaseResponse>(new AxiosHttpClient(clientType), {
    initialResponse: {},
  })

  const execute = (instrumentId: ID) => {
    const path = `instruments/${instrumentId}/image`
    api.execute(path)
  }

  return { ...api, execute }
}
