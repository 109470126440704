import { FetchInstrumentApiSet } from 'api/mypage/instrument'
import { DefectReportsIndexApiSet, useFetchInstrumentDefectReportsApi } from 'api/mypage/instruments/defect_report'
import { ProofreadEventsIndexApiSet, useFetchInstrumentProofreadEventsApi } from 'api/mypage/instruments/proofread_events'
import { RepairEstimatesIndexApiSet, useFetchInstrumentRepairEstimatesApi } from 'api/mypage/instruments/repair_estimate'
import { RepairEventsIndexApiSet, useFetchInstrumentRepairEventsApi } from 'api/mypage/instruments/repair_event'
import { ObjectType } from 'components/mypage/instrument/instrument_event_table'
import { MypageStateContext } from 'contexts/mypage_state_context'
import { DefectReport } from 'entities/defect_report'
import { ProofreadEvent } from 'entities/proofread_event'
import { RepairEstimate } from 'entities/repair_estimate'
import { RepairEvent } from 'entities/repair_event'
import { useContext, useState } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'

export type InstrumentEventTableSet = {
  defectReports: DefectReport[]
  repairEstimates: RepairEstimate[]
  repairEvents: RepairEvent[]
  proofreadEvents: ProofreadEvent[]
  defectReport?: DefectReport
  setDefectReport: React.Dispatch<React.SetStateAction<DefectReport | undefined>>
  repairEstimate?: RepairEstimate
  setRepairEstimate: React.Dispatch<React.SetStateAction<RepairEstimate | undefined>>
  repairEvent?: RepairEvent
  setRepairEvent: React.Dispatch<React.SetStateAction<RepairEvent | undefined>>
  proofreadEvent?: ProofreadEvent
  fetchReportsApi: DefectReportsIndexApiSet
  fetchEstimatesApi: RepairEstimatesIndexApiSet
  fetchRepairEventsApi: RepairEventsIndexApiSet
  fetchProofreadsApi: ProofreadEventsIndexApiSet
  handleRefetchDefectReports: () => void
  handleRefetchRepairEvents: () => void
  handleRefetchRepair: () => void
  handleSelectRow: (object: Object, type: ObjectType) => void
}

export const useInstrumentEventTableSet = (instrumentShowApi: FetchInstrumentApiSet): InstrumentEventTableSet => {
  const mypageState = useContext(MypageStateContext)
  const currentUser = mypageState.currentUser
  const [isInitLoading, setIsInitLoading] = useState<boolean>(true)
  const [defectReports, setDefectReports] = useState<DefectReport[]>([])
  const [repairEstimates, setRepairEstimates] = useState<RepairEstimate[]>([])
  const [repairEvents, setRepairEvents] = useState<RepairEvent[]>([])
  const [proofreadEvents, setProofreadEvents] = useState<ProofreadEvent[]>([])

  const [defectReport, setDefectReport] = useState<DefectReport>()
  const [repairEstimate, setRepairEstimate] = useState<RepairEstimate>()
  const [repairEvent, setRepairEvent] = useState<RepairEvent>()
  const [proofreadEvent, setProofreadEvent] = useState<ProofreadEvent>()

  const fetchReportsApi = useFetchInstrumentDefectReportsApi(instrumentShowApi.response.instrument.id)
  const fetchEstimatesApi = useFetchInstrumentRepairEstimatesApi(instrumentShowApi.response.instrument.id)
  const fetchRepairEventsApi = useFetchInstrumentRepairEventsApi(instrumentShowApi.response.instrument.id)
  const fetchProofreadsApi = useFetchInstrumentProofreadEventsApi(instrumentShowApi.response.instrument.id)

  const handleRefetchDefectReports = () => {
    setDefectReports([])
    if (fetchReportsApi.pageSet.page > 1) {
      fetchReportsApi.pageSet.setPage(1)
    } else {
      fetchReportsApi.execute()
    }
  }

  const handleRefetchRepairEvents = () => {
    setRepairEvents([])
    if (fetchRepairEventsApi.pageSet.page > 1) {
      fetchRepairEventsApi.pageSet.setPage(1)
    } else {
      fetchRepairEventsApi.execute()
    }
  }

  const handleRefetchRepair = () => {
    setDefectReports([])
    setRepairEstimates([])
    setRepairEvents([])
    if (fetchReportsApi.pageSet.page > 1) {
      fetchReportsApi.pageSet.setPage(1)
    } else {
      fetchReportsApi.execute()
    }
    if (fetchEstimatesApi.pageSet.page > 1) {
      fetchEstimatesApi.pageSet.setPage(1)
    } else {
      fetchEstimatesApi.execute()
    }
    if (fetchRepairEventsApi.pageSet.page > 1) {
      fetchRepairEventsApi.pageSet.setPage(1)
    } else {
      fetchRepairEventsApi.execute()
    }
  }

  const handleSelectRow = (object: DefectReport | RepairEstimate | RepairEvent | ProofreadEvent, type: ObjectType) => {
    switch (type) {
      case 'DefectReport': {
        const report = object as DefectReport
        setDefectReport(report)
        setRepairEstimate(report?.repairEstimate)
        setRepairEvent(report?.repairEvent)
        setProofreadEvent(report?.proofreadEvent)
        break
      }
      case 'RepairEstimate': {
        const estimate = object as RepairEstimate
        setDefectReport(estimate?.defectReport)
        setRepairEstimate(estimate)
        setRepairEvent(estimate?.repairEvent)
        setProofreadEvent(estimate?.defectReport?.proofreadEvent)
        break
      }
      case 'RepairEvent': {
        const rEvent = object as RepairEvent
        setDefectReport(rEvent?.defectReport)
        setRepairEstimate(rEvent?.repairEstimate)
        setRepairEvent(rEvent)
        setProofreadEvent(rEvent?.proofreadEvent)
        break
      }
      case 'ProofreadEvent': {
        const pEvent = object as ProofreadEvent
        setDefectReport(pEvent?.defectReport)
        setRepairEstimate(pEvent?.defectReport?.repairEstimate)
        setRepairEvent(pEvent?.repairEvent)
        setProofreadEvent(pEvent)
        break
      }
      default:
        break
    }
  }

  useEffectSkipFirst(() => {
    if (!instrumentShowApi.isSuccess()) return
    if (currentUser.authority?.repair) {
      fetchReportsApi.pageSet.setPerPage(3)
      fetchEstimatesApi.pageSet.setPerPage(3)
      fetchRepairEventsApi.pageSet.setPerPage(3)
    }
    if (instrumentShowApi.response.instrument.proofreadInfo && currentUser.authority?.proofread) {
      fetchProofreadsApi.pageSet.setPerPage(3)
    }
  }, [instrumentShowApi.loading, currentUser])

  useEffectSkipFirst(() => {
    if (!isInitLoading) return
    // 最初の画面読み込み時に一番最新の不具合または校正履歴を表示する
    if (fetchReportsApi.isSuccess()) {
      handleSelectRow(fetchReportsApi.response.defectReports[0], 'DefectReport')
      setIsInitLoading(false)
    } else if (fetchProofreadsApi.isSuccess()) {
      handleSelectRow(fetchProofreadsApi.response.proofreadEvents[0], 'ProofreadEvent')
      setIsInitLoading(false)
    }
  }, [fetchReportsApi.loading, fetchProofreadsApi.loading])

  useEffectSkipFirst(() => {
    if (!fetchReportsApi.isSuccess()) return
    setDefectReports([...defectReports, ...fetchReportsApi.response.defectReports])
  }, [fetchReportsApi.loading])

  useEffectSkipFirst(() => {
    if (!fetchEstimatesApi.isSuccess()) return
    setRepairEstimates([...repairEstimates, ...fetchEstimatesApi.response.repairEstimates])
  }, [fetchEstimatesApi.loading])

  useEffectSkipFirst(() => {
    if (!fetchRepairEventsApi.isSuccess()) return
    setRepairEvents([...repairEvents, ...fetchRepairEventsApi.response.repairEvents])
  }, [fetchRepairEventsApi.loading])

  useEffectSkipFirst(() => {
    if (!fetchProofreadsApi.isSuccess()) return
    setProofreadEvents([...proofreadEvents, ...fetchProofreadsApi.response.proofreadEvents])
  }, [fetchProofreadsApi.loading])

  return {
    defectReports,
    repairEstimates,
    repairEvents,
    proofreadEvents,
    defectReport,
    setDefectReport,
    repairEstimate,
    setRepairEstimate,
    repairEvent,
    setRepairEvent,
    proofreadEvent,
    fetchReportsApi,
    fetchEstimatesApi,
    fetchRepairEventsApi,
    fetchProofreadsApi,
    handleRefetchDefectReports,
    handleRefetchRepairEvents,
    handleRefetchRepair,
    handleSelectRow,
  }
}
