import SearchIcon from '@mui/icons-material/Search'
import { IconButton } from '@mui/material'
import { StringField } from 'components/shared/form/input'
import { Form } from 'rac'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

type SearchBarProps<T> = {
  form: Form<T>
  searchAttr: keyof T
  placeholder: string
  onSubmit: () => void
}
export const SearchBar = <T,>({ form, searchAttr, onSubmit, placeholder }: SearchBarProps<T>) => {
  const { classes: inputClasses } = useInputStyles()
  const { classes: iconButtonClasses } = useIconButtonStyles()

  return (
    <div style={{ display: 'flex' }}>
      <StringField
        width={500}
        form={form}
        attr={searchAttr}
        placeholder={placeholder}
        label="検索"
        onDownEnter={onSubmit}
        InputProps={{
          classes: inputClasses,
          endAdornment: (
            <IconButton onClick={onSubmit} classes={iconButtonClasses} size="large">
              <SearchIcon style={{ color: 'white' }} />
            </IconButton>
          ),
        }}
      />
    </div>
  )
}

const useInputStyles = makeStyles()({
  root: {
    paddingRight: 0,
  },
})

const useIconButtonStyles = makeStyles()({
  root: {
    height: 43,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    backgroundColor: '#9c27b0',
    padding: '0 10px 0 10px',
    '&:hover': {
      background: '#7b1fa2',
    },
  },
})
