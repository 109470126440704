import { Table, TableContainer, TableHead, TableRow } from '@mui/material'
import { RepairEstimatesResponse } from 'api/mypage/instruments/repair_estimate'
import { useFetchExColumnsApi } from 'api/shared/ex_columns'
import Pagination from 'components/shared/pagination'
import StatusLabel from 'components/shared/status_label'
import { OrderTableCell, ExTableCell, ExTableBody, ExTableRow } from 'components/shared/table'
import { TableExAttributesInfo } from 'components/shared/table_ex_attributes_info'
import ThemedButton from 'components/shared/themed_button'
import { RepairEstimate } from 'entities/repair_estimate'
import * as H from 'history'
import { IndexApiSet } from 'rac'
import React, { useEffect } from 'react'
import { MypageRouteHelper } from 'routes/mypage'
import { INDEX_TABLE_PADDING, getTableHeight } from 'utils/component_heights'
import { RepairEstimateViewModel } from 'view_models/repair_estimate'

type RepairEstimateTableProps = {
  indexApi: IndexApiSet<RepairEstimatesResponse> & { execute: () => void }
  history: H.History
  showInstrument?: boolean
}

const RepairEstimateTable: React.FC<RepairEstimateTableProps> = (props: RepairEstimateTableProps) => {
  const { indexApi, showInstrument } = props
  const exColumnApi = useFetchExColumnsApi('user')

  useEffect(() => {
    exColumnApi.execute()
  }, [])

  return (
    <>
      <TableContainer style={{ maxHeight: getTableHeight(INDEX_TABLE_PADDING) }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <OrderTableCell align="center" width={100} orderAttr="reportedAt" orderSet={indexApi.orderSet}>
                見積依頼日
              </OrderTableCell>
              {showInstrument && (
                <ExTableCell width={200}>
                  <div>
                    メーカー / 型番 / 管理番号 / 製品名
                    {exColumnApi.response.exColumns
                      .filter((col) => col.visibleOnTable)
                      .map((col) => (
                        <div style={{ fontSize: 12, lineHeight: '12px' }} key={`tablecol-${col.id}`}>
                          {col.name}
                        </div>
                      ))}
                  </div>
                </ExTableCell>
              )}
              <ExTableCell align="center" width={120}>
                ステータス
              </ExTableCell>
              <ExTableCell align="center" width={120}>
                依頼者
              </ExTableCell>
              <ExTableCell align="center" width={180}>
                依頼先
              </ExTableCell>
              <ExTableCell align="center" width={120}>
                金額
              </ExTableCell>
              <ExTableCell align="center" width={120}>
                修理期間
              </ExTableCell>
              <ExTableCell align="center" width={130}>
                見積No.
              </ExTableCell>
              <ExTableCell width={100} />
            </TableRow>
          </TableHead>
          <ExTableBody loading={indexApi.loading}>
            {indexApi.response.repairEstimates.map((repairEstimate: RepairEstimate) => {
              const vm = new RepairEstimateViewModel(repairEstimate)
              return (
                <ExTableRow key={repairEstimate.id}>
                  <ExTableCell align="center">{vm.getRequestDate()}</ExTableCell>
                  {showInstrument && (
                    <ExTableCell>
                      <div>{vm.getInstrumentInfo()}</div>
                      <TableExAttributesInfo tableExAttrs={repairEstimate.tableExAttrs || []} id={repairEstimate.id} />
                    </ExTableCell>
                  )}
                  <ExTableCell align="center">
                    <StatusLabel text={vm.getStatusText()} color={vm.getStatusColor()} />
                  </ExTableCell>
                  <ExTableCell align="center">{vm.getRequestUserName()}</ExTableCell>
                  <ExTableCell>{vm.getSupplierName()}</ExTableCell>
                  {repairEstimate.aasmState === 'estimating' ? (
                    <ExTableCell colSpan={2} align="center">
                      見積中
                    </ExTableCell>
                  ) : (
                    <>
                      <ExTableCell align="right">{vm.getPriceText()}</ExTableCell>
                      <ExTableCell align="center">{vm.getDeliveryTerm()}</ExTableCell>
                    </>
                  )}
                  <ExTableCell width={130}>{vm.getEstimateNumber()}</ExTableCell>
                  <ExTableCell align="center">
                    <ThemedButton
                      color="secondary"
                      onClick={() => props.history.push(MypageRouteHelper.repairInfoShow(repairEstimate.repairInfoId!))}
                    >
                      詳細
                    </ThemedButton>
                  </ExTableCell>
                </ExTableRow>
              )
            })}
          </ExTableBody>
        </Table>
      </TableContainer>
      <Pagination response={indexApi.response} pageSet={indexApi.pageSet} />
    </>
  )
}

export default RepairEstimateTable
