import { AxiosHttpClient } from 'api/axios'
import { useRepruaIndexApi } from 'api/shared_api_hooks'
import { ID } from 'entities'
import { ReservationSearchForm, ReservationsResponse } from 'entities/reservation'
import { IndexApiSet, Form } from 'rac'

/**
 * 一覧
 */
export function usePartnerFetchInstrumentReservationsApi(
  instrumentId: ID,
): IndexApiSet<ReservationsResponse> & { execute: (form: Form<ReservationSearchForm>) => void } {
  const api = useRepruaIndexApi<ReservationsResponse, ReservationSearchForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { reservations: [], totalCount: 0, managingLaboratoryIds: [] },
  })

  const execute = (form: Form<ReservationSearchForm>) => {
    const path = `instruments/${instrumentId}/reservations`
    api.execute(path, { params: form.object })
  }

  return { ...api, execute }
}
