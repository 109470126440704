import { Paper, Table, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'
import {
  PartnerFetchProofreadPlansApiSet,
  usePartnerDeleteProofreadPlanApi,
  usePartnerFetchProofreadPlansApi,
} from 'api/partner/proofread_plan'
import { ProofreadPlanFormDialog } from 'components/partner/proofread/proofread_plan/proofread_plan_form_dialog'
import { ContentHeader, ContentTitle } from 'components/shared/content'
import { Flex } from 'components/shared/flex'
import { useModal } from 'components/shared/modal'
import Pagination from 'components/shared/pagination'
import { ExTableCell, ExTableBody, ExTableRow } from 'components/shared/table'
import Tabs from 'components/shared/tabs'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { PartnerStateContext } from 'contexts/partner_state_context'
import { supplierProofreadEventStateTexts } from 'entities/proofread_event'
import { ProofreadPlan, ProofreadPlanClietableType } from 'entities/proofread_plan'
import React, { useEffect, useContext, useState } from 'react'
import { StaticContext } from 'react-router'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { PartnerRouteHelper } from 'routes/partner'
import { getTableHeight, HEIGHTS } from 'utils/component_heights'
import CookieManager from 'utils/cookie_util'
import { tabTypes } from 'utils/tab_types'
import { ProofreadPlanViewModel } from 'view_models/proofread_plan'

const TABLE_PADDING = HEIGHTS.FLOAT_HEADER + HEIGHTS.PROOFREAD_CONTENT_HEADER + HEIGHTS.PAGINATION + HEIGHTS.MARGIN

type TabIds = 'for_laboratory' | 'for_outside_laboratory'

export type ProofreadPlanIndexProps = never
type Props = RouteComponentProps<never, StaticContext, ProofreadPlanIndexProps>

export const ProofreadPlanIndex: React.FC<Props> = (props) => {
  const globalState = useContext(GlobalStateContext)
  const partnerState = useContext(PartnerStateContext)
  const labPlansApi = usePartnerFetchProofreadPlansApi('laboratory')
  const supplierPlansApi = usePartnerFetchProofreadPlansApi('supplier')
  const deletePlanApi = usePartnerDeleteProofreadPlanApi()
  const modalState = useModal()
  const [clientableType, setClientableType] = useState<ProofreadPlanClietableType>('OutsideLaboratory')
  const handleClickCreateOutsideLabPlan = () => {
    modalState.setOpen(true)
    setClientableType('OutsideLaboratory')
  }
  const handleClickCreateLabPlan = () => {
    modalState.setOpen(true)
    setClientableType('Laboratory')
  }
  const handleCompleteCreate = () => {
    modalState.setOpen(false)
    labPlansApi.execute()
    supplierPlansApi.execute()
  }
  const handleClickDetailButton = (id: number) => {
    props.history.push(PartnerRouteHelper.proofreadPlanShow(id))
  }
  const handleClickDeleteButton = (id: number) => {
    globalState.confirm('本当に削除しますか？', (event) => {
      if (event === 'ok') {
        deletePlanApi.execute(id)
      }
    })
  }
  const initTabId = CookieManager.getSelectedTab<TabIds>(tabTypes.PARTNER_PROOFREAD_INDEX) || 'for_outside_laboratory'

  const labTableProps = {
    api: labPlansApi,
    onClickDetailButton: handleClickDetailButton,
    onClickDeleteButton: handleClickDeleteButton,
    onClickCreate: handleClickCreateLabPlan,
  }
  const supplierTableProps = {
    api: supplierPlansApi,
    onClickDetailButton: handleClickDetailButton,
    onClickDeleteButton: handleClickDeleteButton,
    onClickCreate: handleClickCreateOutsideLabPlan,
  }
  const tabComponents = partnerState.currentDealer.authority?.enableInstrumentSharing
    ? [
        { id: 'for_laboratory', label: 'リプルア顧客', component: <ForLaboratoryTable {...labTableProps} /> },
        { id: 'for_outside_laboratory', label: '外部顧客', component: <ForOutsideLabTable {...supplierTableProps} /> },
      ]
    : [{ id: 'for_outside_laboratory', label: '外部顧客', component: <ForOutsideLabTable {...supplierTableProps} /> }]

  const handleSaveTabId = (tabId: string) => {
    CookieManager.saveSelectedTab(tabTypes.PARTNER_PROOFREAD_INDEX, tabId)
  }

  useEffect(() => {
    labPlansApi.execute()
    supplierPlansApi.execute()
  }, [])

  useEffect(() => {
    globalState.setLoading(deletePlanApi.loading)
    if (deletePlanApi.isSuccess()) {
      supplierPlansApi.execute()
    }
  }, [deletePlanApi.loading])

  return (
    <>
      <ProofreadPlanFormDialog clientableType={clientableType} modalState={modalState} onComplete={handleCompleteCreate} />
      <ContentHeader>
        <ContentTitle title="校正計画一覧" />
      </ContentHeader>
      <Tabs tabComponents={tabComponents} initTabId={initTabId} onSelect={handleSaveTabId} />
    </>
  )
}

type TableProps = {
  api: PartnerFetchProofreadPlansApiSet
  onClickDetailButton: (id: number) => void
  onClickDeleteButton: (id: number) => void
  onClickCreate: () => void
}
const ForLaboratoryTable = (props: TableProps) => {
  const { api, onClickDetailButton } = props
  return (
    <Paper>
      <Flex justifyContent="flex-end" style={{ margin: 10 }}>
        <ThemedButton color="primary" onClick={props.onClickCreate}>
          新しく校正計画を開始する
        </ThemedButton>
      </Flex>
      <TableContainer style={{ maxHeight: getTableHeight(TABLE_PADDING) }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <ExTableCell>顧客</ExTableCell>
              <ExTableCell>名称</ExTableCell>
              <ExTableCell>対象期間</ExTableCell>
              <ExTableCell width={120} align="center">
                依頼機器数
              </ExTableCell>
              <ExTableCell width={120} align="center">
                {supplierProofreadEventStateTexts.no_plan}
              </ExTableCell>
              <ExTableCell width={120} align="center">
                {supplierProofreadEventStateTexts.approved_plan}
              </ExTableCell>
              <ExTableCell width={120} align="center">
                {supplierProofreadEventStateTexts.waiting_date}
              </ExTableCell>
              <ExTableCell width={120} align="center">
                {supplierProofreadEventStateTexts.fix_date}
              </ExTableCell>
              <ExTableCell width={120} align="center">
                {supplierProofreadEventStateTexts.completed}
              </ExTableCell>
              <ExTableCell width={30} />
            </TableRow>
          </TableHead>
          <ExTableBody loading={api.loading}>
            {api.response.proofreadPlans &&
              api.response.proofreadPlans.map((plan: ProofreadPlan) => {
                const vm = new ProofreadPlanViewModel(plan)
                return (
                  <ExTableRow key={plan.id}>
                    <ExTableCell>{vm.getLaboratoryName()}</ExTableCell>
                    <ExTableCell>{vm.getTitle()}</ExTableCell>
                    <ExTableCell>{vm.getTargetRange()}</ExTableCell>
                    <ExTableCell align="center">{vm.getInstrumentCount()}</ExTableCell>
                    <ExTableCell align="center">{vm.getStateCount('no_plan')}</ExTableCell>
                    <ExTableCell align="center">{vm.getStateCount('approved_plan')}</ExTableCell>
                    <ExTableCell align="center">{vm.getStateCount('waiting_date')}</ExTableCell>
                    <ExTableCell align="center">{vm.getStateCount('fix_date')}</ExTableCell>
                    <ExTableCell align="center">{vm.getStateCount('completed')}</ExTableCell>
                    <ExTableCell>
                      <ThemedButton color="primary" onClick={() => onClickDetailButton(plan.id!)}>
                        詳細
                      </ThemedButton>
                    </ExTableCell>
                  </ExTableRow>
                )
              })}
          </ExTableBody>
        </Table>
      </TableContainer>
      <Pagination response={api.response} pageSet={api.pageSet} />
    </Paper>
  )
}

const ForOutsideLabTable = (props: TableProps & { onClickCreate: () => void }) => {
  const { api, onClickDetailButton, onClickDeleteButton } = props
  return (
    <Paper>
      <Flex justifyContent="flex-end" style={{ margin: 10 }}>
        <ThemedButton color="primary" onClick={props.onClickCreate}>
          新しく校正計画を開始する
        </ThemedButton>
      </Flex>
      <TableContainer style={{ maxHeight: getTableHeight(TABLE_PADDING) }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <ExTableCell>顧客</ExTableCell>
              <ExTableCell>名称</ExTableCell>
              <ExTableCell>対象期間</ExTableCell>
              <ExTableCell width={120} align="center">
                依頼機器数
              </ExTableCell>
              <ExTableCell width={120} align="center">
                {supplierProofreadEventStateTexts.no_plan}
              </ExTableCell>
              <ExTableCell width={120} align="center">
                {supplierProofreadEventStateTexts.attached_result}
              </ExTableCell>
              <ExTableCell width={120} align="center">
                {supplierProofreadEventStateTexts.approved_result}
              </ExTableCell>
              <ExTableCell width={30} />
            </TableRow>
          </TableHead>
          <ExTableBody loading={api.loading}>
            {api.response.proofreadPlans &&
              api.response.proofreadPlans.map((plan: ProofreadPlan) => {
                const vm = new ProofreadPlanViewModel(plan)
                return (
                  <ExTableRow key={plan.id}>
                    <ExTableCell>{vm.getLaboratoryName()}</ExTableCell>
                    <ExTableCell>{vm.getTitle()}</ExTableCell>
                    <ExTableCell>{vm.getTargetRange()}</ExTableCell>
                    <ExTableCell align="center">{vm.getInstrumentCount()}</ExTableCell>
                    <ExTableCell align="center">{vm.getStateCount('no_plan')}</ExTableCell>
                    <ExTableCell align="center">{vm.getStateCount('attached_result')}</ExTableCell>
                    <ExTableCell align="center">{vm.getStateCount('approved_result')}</ExTableCell>
                    <ExTableCell>
                      <Flex>
                        <ThemedButton color="primary" onClick={() => onClickDetailButton(plan.id!)}>
                          詳細
                        </ThemedButton>
                        <Tooltip
                          title={
                            vm.getInstrumentCount() !== vm.getStateCount('no_plan') ? '校正が進んでいる機器があるため削除できません' : ''
                          }
                        >
                          <div>
                            <ThemedButton
                              color="error"
                              onClick={() => onClickDeleteButton(plan.id!)}
                              disabled={vm.getInstrumentCount() !== vm.getStateCount('no_plan')}
                            >
                              削除
                            </ThemedButton>
                          </div>
                        </Tooltip>
                      </Flex>
                    </ExTableCell>
                  </ExTableRow>
                )
              })}
          </ExTableBody>
        </Table>
      </TableContainer>
      <Pagination response={api.response} pageSet={api.pageSet} />
    </Paper>
  )
}

export default withRouter(ProofreadPlanIndex)
