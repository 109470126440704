import { appTitle } from 'App'
import React, { ReactNode } from 'react'
import { Helmet } from 'react-helmet'
import { makeStyles } from 'tss-react/mui'

type ContentHeaderProps = {
  children?: ReactNode
  subHeader?: ReactNode
}

const useStyle = makeStyles()({
  contentHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 10,
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  subHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: '0 10px 10px 10px',
  },
  contentTitle: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: 20,
    },
  },
})

export const ContentHeader: React.FC<ContentHeaderProps> = (props: ContentHeaderProps) => {
  const { classes } = useStyle()
  return (
    <>
      <div className={classes.contentHeader}>{props.children}</div>
      {props.subHeader && <div className={classes.subHeader}>{props.subHeader}</div>}
    </>
  )
}

type ContentTitleProps = {
  title: string
  children?: ReactNode
}

export const ContentTitle: React.FC<ContentTitleProps> = (props: ContentTitleProps) => {
  const { classes } = useStyle()
  return (
    <>
      <Helmet title={`${props.title} | ${appTitle}`} />
      <div className={classes.contentTitle}>
        <h2>{props.title}</h2>
        {props.children}
      </div>
    </>
  )
}
