import { AxiosHttpClient } from 'api/axios'
import {
  useRepruaIndexApi,
  useRepruaPostApi,
  useRepruaPatchApi,
  useRepruaDeleteApi,
  useRepruaShowApi,
  useRepruaDownloadApi,
} from 'api/shared_api_hooks'
import { ID, PagingResponse } from 'entities'
import { getInstrumentSearchQuery, Instrument, InstrumentForm, InstrumentSearchForm, PrintInstrumentBarcodeForm } from 'entities/instrument'
import { Laboratory } from 'entities/laboratory'
import { IndexApiSet, Form, ApiSet, BaseResponse, IndexApiState } from 'rac'
import { useQuery } from 'react-query'
import { useEffectSkipFirst } from 'utils/hooks'

type InstrumentsResponse = PagingResponse & {
  instruments: Instrument[]
}

/**
 * 一覧
 */
type FetchInstrumentsApiSet = IndexApiSet<InstrumentsResponse> & {
  execute: () => void
  executeAndPageReset: () => void
}
export function useFetchInstrumentsApi(searchForm: Form<InstrumentSearchForm>, initialState?: Partial<IndexApiState>): FetchInstrumentsApiSet {
  const api = useRepruaIndexApi<InstrumentsResponse, InstrumentSearchForm>(new AxiosHttpClient('user'), {
    initialResponse: { instruments: [], totalCount: 0 },
    ...((initialState && Object.keys(initialState).length > 0) && { initialState: initialState })
  })

  const { searchText, ...rest } = searchForm.object

  const execute = () => {
    const path = 'instruments'
    api.execute(path, { params: rest, searchQuery: getInstrumentSearchQuery(searchText) })
  }

  const executeAndPageReset = () => {
    if (api.pageSet.page > 1) {
      api.pageSet.setPage(1)
    } else {
      execute()
    }
  }

  useEffectSkipFirst(() => {
    execute()
  }, [api.pageSet.page, api.pageSet.perPage, api.orderSet.order, api.orderSet.orderBy])

  return { ...api, execute, executeAndPageReset }
}

type InstrumentTotalCountResponse = {
  totalCount: number
}
/**
 * 機器の総数の値を取得する
 */
export const useFetchInstrumentTotalCountApi = () => {
  const client = new AxiosHttpClient('user')
  const query = useQuery<undefined, undefined, InstrumentTotalCountResponse>(
    'laboratories_instrument_total_count',
    async () => {
      const { data } = await client.get('instruments/total_count', {})
      return data
    },
    { enabled: false },
  )

  return query
}

type InstrumentResponse = BaseResponse & {
  instrument: Instrument
  currentLaboratory?: Laboratory
  managedLaboratories?: Laboratory[]
}

/**
 * 取得
 */
export type FetchInstrumentApiSet = ApiSet<InstrumentResponse> & { execute: (id: ID) => void }
export function useFetchInstrumentApi(): FetchInstrumentApiSet {
  const api = useRepruaShowApi<InstrumentResponse, { id: ID }>(new AxiosHttpClient('user'), { initialResponse: { instrument: {} } })

  const execute = (id: ID) => {
    const path = `instruments/${id}`
    api.execute(path, { id: id })
  }

  return { ...api, execute }
}

/**
 * 登録
 */
export function usePostInstrumentApi(): ApiSet<InstrumentResponse> & { execute: (instrumentForm: Form<InstrumentForm>) => void } {
  const api = useRepruaPostApi<InstrumentResponse, InstrumentForm>(new AxiosHttpClient('user'), { initialResponse: { instrument: {} } })

  const execute = (instrumentForm: Form<InstrumentForm>) => {
    const path = 'instruments'
    api.execute(path, instrumentForm)
  }

  return { ...api, execute }
}

/**
 * 更新
 */
export function usePatchInstrumentApi(): ApiSet<InstrumentResponse> & { execute: (instrumentForm: InstrumentForm) => void } {
  const api = useRepruaPatchApi<InstrumentResponse, InstrumentForm>(new AxiosHttpClient('user'), { initialResponse: { instrument: {} } })

  const execute = (instrumentForm: InstrumentForm) => {
    const path = `instruments/${instrumentForm.id}`
    api.execute(path, instrumentForm)
  }

  return { ...api, execute }
}

/**
 * 印刷
 */
export function usePrintInstrumentsApi(): ApiSet<BaseResponse> & {
  execute: (form: PrintInstrumentBarcodeForm) => void
} {
  const api = useRepruaPatchApi<BaseResponse, PrintInstrumentBarcodeForm>(new AxiosHttpClient('user'), {
    initialResponse: {},
  })

  const execute = (form: PrintInstrumentBarcodeForm) => {
    const path = 'instruments/print'
    api.execute(path, form)
  }

  return { ...api, execute }
}

/**
 * 削除
 */
export function useDeleteInstrumentApi(): ApiSet<InstrumentResponse> & { execute: (id: ID) => void } {
  const api = useRepruaDeleteApi<InstrumentResponse>(new AxiosHttpClient('user'), { initialResponse: { instrument: {} } })

  const execute = (id: ID) => {
    const path = `instruments/${id}`
    api.execute(path)
  }

  return { ...api, execute }
}

/**
 * 出力
 */
export const useExportInstrumentsApi = (searchForm: Form<InstrumentSearchForm>): { execute: (format: 'xlsx') => void } => {
  const api = useRepruaDownloadApi<InstrumentSearchForm>(new AxiosHttpClient('user'))

  const { searchText, ...rest } = searchForm.object

  const execute = (format: 'xlsx') => {
    const path = `instruments.${format}`
    api.execute(path, { params: rest, searchQuery: getInstrumentSearchQuery(searchText) })
  }

  return { execute }
}
