import { Box } from '@mui/material'
import React from 'react'

import { AccountSettingsForm } from './account_settings_form'
import { MailNortificationSettingsForm } from './mail_notification_settings_form'

export const PersonalSettingsBox = () => {
  return (
    <Box>
      <Box display={'flex'} alignItems={'center'} marginBottom={1}>
        <h3 style={{ marginRight: '10px' }}>個人設定</h3>
      </Box>
      <MailNortificationSettingsForm />
      <AccountSettingsForm />
    </Box>
  )
}
