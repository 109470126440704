import { AxiosHttpClient } from 'api/axios'
import { useRepruaIndexApi } from 'api/shared_api_hooks'
import { Dealer } from 'entities/dealer'
import { OutsideDealer } from 'entities/outside_dealer'
import { BaseResponse, IndexApiSet } from 'rac'

export type PartnersResponse = BaseResponse & {
  dealers: Dealer[]
  outsideDealers: OutsideDealer[]
}

/**
 * 一覧
 */
export function useFetchPartnersApi(): IndexApiSet<PartnersResponse> & { execute: () => void } {
  const api = useRepruaIndexApi<PartnersResponse, unknown>(new AxiosHttpClient('user'), {
    initialResponse: { dealers: [], outsideDealers: [] },
  })

  const execute = () => {
    const path = 'partners'
    api.execute(path)
  }

  return { ...api, execute }
}
