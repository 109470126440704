import { Table, TableHead, TableBody, TableRow, TableCell, Checkbox } from '@mui/material'
import { useAdminPatchDealerUserAuthorityApi } from 'api/admin/dealers/dealer_user_authority'
import Modal, { ModalState } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { DealerUser } from 'entities/dealer_user'
import { authorityData, AuthorityTypes, BulkUpdateDealerUserAuthorityForm, DealerUserAuthority } from 'entities/dealer_user_authority'
import { Form, useForm } from 'rac'
import React, { useEffect } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'

const dialogContent = (form: Form<BulkUpdateDealerUserAuthorityForm>, users: DealerUser[]) => {
  const handleChange = (userId: number, type: AuthorityTypes, value: boolean) => {
    const authority = form.object.dealerUserAuthorities.find((a) => a.dealerUserId === userId)
    const changedAuth = { ...authority, [type]: value }
    const updateState = form.object.dealerUserAuthorities.map((auth) => (auth.dealerUserId !== userId ? auth : changedAuth))
    form.update((f) => (f.dealerUserAuthorities = updateState))
  }

  const isChecked = (userId: number, type: AuthorityTypes) => {
    const auth = form.object.dealerUserAuthorities.find((a) => a.dealerUserId === userId)
    return !auth ? false : auth[type]
  }

  const styles = {
    th: {
      padding: '0 0 16px',
    },
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>名前</TableCell>
          <TableCell width={80} style={styles.th}>
            管理者
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {users.map((user, index) => {
          const authorityKeys = Object.keys(authorityData)
          return (
            <TableRow key={index}>
              <TableCell>{user.name || '-'}</TableCell>
              {authorityKeys.map((el, idx) => {
                const auth = el as AuthorityTypes

                return (
                  <TableCell key={idx} style={{ padding: '8px 10px' }}>
                    <Checkbox
                      checked={isChecked(user.id!, auth)}
                      onChange={(e) => handleChange(user.id!, auth, e.target.checked)}
                      size="small"
                    />
                  </TableCell>
                )
              })}
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

type DealerUserAuthorityFormDialogProps = {
  users: DealerUser[]
  modalState: ModalState
  dealerId: number
  onComplete: () => void
}

export const DealerUserAuthorityFormDialog: React.FC<DealerUserAuthorityFormDialogProps> = (props: DealerUserAuthorityFormDialogProps) => {
  const form = useForm<BulkUpdateDealerUserAuthorityForm>({ dealerUserAuthorities: [] }, 'dealerUserAuthority')
  const patchApi = useAdminPatchDealerUserAuthorityApi(props.dealerId)

  const handleSubmit = () => {
    if (props.users[0]) {
      patchApi.execute(form.object)
    }
  }

  // formの初期化
  useEffect(() => {
    const initialState: DealerUserAuthority[] = []
    props.users.forEach((user) => {
      const auth = user.authority!
      initialState.push({
        dealerUserId: user.id,
        privileged: auth.privileged,
      })
    })
    form.set({ dealerUserAuthorities: initialState })
  }, [props.users])

  useEffectSkipFirst(() => {
    if (patchApi.isSuccess()) {
      if (props.onComplete) props.onComplete()
    }
  }, [patchApi.loading])

  return (
    <Modal
      modalId="admin-dealer-user-authority-modal"
      title="ユーザー権限"
      modalState={props.modalState}
      footer={
        <>
          <ThemedButton onClick={() => props.modalState.setOpen(false)} color="secondary">
            キャンセル
          </ThemedButton>
          <ThemedButton onClick={handleSubmit} color="success">
            保存
          </ThemedButton>
        </>
      }
    >
      {dialogContent(form, props.users)}
    </Modal>
  )
}
