import {
  usePartnerDeleteDealerDivisionRelationApi,
  usePartnerPatchDealerDivisionApi,
  usePartnerPostDealerDivisionApi,
} from 'api/partner/dealer_divisions'
import { Flex } from 'components/shared/flex'
import { StringField } from 'components/shared/form/input'
import Modal, { ModalSet, ModalState } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { DealerDivisionForm, DealerDivision } from 'entities/dealer_division'
import { ApiError, Form, useForm } from 'rac'
import React, { useContext } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'

type ModalBodyProps = {
  form: Form<DealerDivisionForm>
  apiError: ApiError
}
const ModalBody = ({ form, apiError }: ModalBodyProps) => {
  return (
    <Flex>
      <StringField label="名前" attr="name" form={form} apiError={apiError} required />
    </Flex>
  )
}

type CreateDealerDivisionModalProps = {
  modalState: ModalState
  onComplete: () => void
}
export const CreateDealerDivisionModal = ({ modalState, onComplete }: CreateDealerDivisionModalProps) => {
  const form = useForm<DealerDivisionForm>({}, 'dealerDivision')
  const api = usePartnerPostDealerDivisionApi()

  const handleSubmit = () => {
    api.execute(form)
  }

  useEffectSkipFirst(() => {
    if (!api.isSuccess()) return
    onComplete()
  }, [api.loading])

  return (
    <Modal
      modalId="dealer-division-new"
      title="部署登録"
      modalState={modalState}
      size="xs"
      footer={
        <>
          <ThemedButton onClick={() => modalState.setOpen(false)} color="secondary">
            閉じる
          </ThemedButton>
          <ThemedButton onClick={handleSubmit} color="success">
            登録
          </ThemedButton>
        </>
      }
    >
      <ModalBody form={form} apiError={api.apiError} />
    </Modal>
  )
}

type UpdateDealerDivisionModalProps = {
  modalSet: ModalSet<DealerDivision>
  onComplete: () => void
}
export const UpdateDealerDivisionModal = ({ modalSet, onComplete }: UpdateDealerDivisionModalProps) => {
  const form = useForm<DealerDivisionForm>({}, 'dealerDivision')
  const patchApi = usePartnerPatchDealerDivisionApi(form)
  const deleteApi = usePartnerDeleteDealerDivisionRelationApi()
  const globalState = useContext(GlobalStateContext)

  const handleSubmit = () => {
    patchApi.execute(modalSet.item.id!)
  }

  const handleDelete = () => {
    globalState.confirm('本当に削除しますか？', (event) => {
      if (event === 'ok') {
        deleteApi.execute(modalSet.item.id!)
      }
    })
  }

  useEffectSkipFirst(() => {
    if (!modalSet.modalState.open) return
    form.update((f) => (f.name = modalSet.item.name))
  }, [modalSet.modalState.open])

  useEffectSkipFirst(() => {
    if (!patchApi.isSuccess()) return
    onComplete()
  }, [patchApi.loading])

  useEffectSkipFirst(() => {
    if (!deleteApi.isSuccess()) return
    onComplete()
  }, [deleteApi.loading])

  return (
    <Modal
      modalId="dealer-division-update"
      title="部署編集"
      modalState={modalSet.modalState}
      size="xs"
      footer={
        <Flex justifyContent="space-between" style={{ width: '100%' }}>
          <ThemedButton onClick={handleDelete} color="error">
            削除
          </ThemedButton>
          <div>
            <ThemedButton onClick={() => modalSet.closeModal()} color="secondary">
              閉じる
            </ThemedButton>
            <ThemedButton onClick={handleSubmit} color="success" style={{ marginLeft: 5 }}>
              更新
            </ThemedButton>
          </div>
        </Flex>
      }
    >
      <ModalBody form={form} apiError={patchApi.apiError} />
    </Modal>
  )
}
