import { Paper, Table, TableContainer, TableHead, TableRow, Switch } from '@mui/material'
import { useFetchAdminDealerApi } from 'api/admin/dealers'
import { useFetchAdminDealerUsersApi, usePostAdminDealerUpdateAuthorityApi } from 'api/admin/dealers/dealer_users'
import { useAdminDealerUserAgentsApi } from 'api/admin/users/user_agents'
import { DealerUserAuthorityFormDialog } from 'components/admin/dealer/dealer_user_authority_form_dialog'
import { InstrumentImportModalContainer } from 'components/admin/dealer/instrument_import_modal_container'
import { ContentHeader, ContentTitle } from 'components/shared/content'
import { Flex } from 'components/shared/flex'
import { useModal } from 'components/shared/modal'
import { ExTableCell, ExTableBody, ExTableRow } from 'components/shared/table'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { Dealer } from 'entities/dealer'
import { DealerUser } from 'entities/dealer_user'
import React, { useContext, useEffect, useState } from 'react'
import { StaticContext } from 'react-router'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import CookieManager from 'utils/cookie_util'

type ShowDealerProps = {}

type Props = RouteComponentProps<{}, StaticContext, ShowDealerProps>

type AuthorityFormValues = {
  instrumentImport: boolean
  enableInstrumentSharing: boolean
}

export const ShowDealer: React.FC<Props> = (props) => {
  const dealerApi = useFetchAdminDealerApi()
  const [dealer, setDealer] = useState<Dealer>({})
  const [authorityForm, setAuthorityForm] = useState<AuthorityFormValues>({
    instrumentImport: false,
    enableInstrumentSharing: false,
  })

  const dealerUsersApi = useFetchAdminDealerUsersApi()
  const [dealerUsers, setDealerUsers] = useState<DealerUser[]>([])

  const postAdminDealerUpdateAuthorityApi = usePostAdminDealerUpdateAuthorityApi()

  const dealerUserAgentsApi = useAdminDealerUserAgentsApi()
  const params: { id: number } = props.match.params as { id: number }
  const authorityModal = useModal()
  const importModal = useModal()
  const globalState = useContext(GlobalStateContext)

  useEffect(() => {
    dealerApi.execute(params.id)
    dealerUsersApi.execute(params.id)
  }, [])

  useEffect(() => {
    if (!dealerApi.isSuccess()) return

    if (dealerApi.response.dealer) {
      setDealer(dealerApi.response.dealer)
      setAuthorityForm({
        instrumentImport: Boolean(dealerApi.response.dealer?.authority?.instrumentImport),
        enableInstrumentSharing: Boolean(dealerApi.response.dealer?.authority?.enableInstrumentSharing),
      })
    }
  }, [dealerApi.loading])

  useEffect(() => {
    if (!dealerUsersApi.isSuccess()) return

    if (dealerUsersApi.response.dealerUsers) {
      setDealerUsers(dealerUsersApi.response.dealerUsers)
    }
  }, [dealerUsersApi.loading])

  useEffect(() => {
    if (!dealerUserAgentsApi.isSuccess()) return

    if (dealerUserAgentsApi.response.accessToken) {
      CookieManager.saveDealerUserToken(dealerUserAgentsApi.response.accessToken)
      const targetUrl = `${window.location.protocol}//${window.location.host}/partner/instruments`
      window.open(targetUrl)
    }
  }, [dealerUserAgentsApi.loading])

  const handleAgentLogin = (id: number) => {
    dealerUserAgentsApi.execute(id)
  }

  const handleClickImport = () => {
    importModal.setOpen(true)
  }

  const handleClickInstrumentImport = () => {
    const newFormValue = {
      ...authorityForm,
      instrumentImport: !authorityForm.instrumentImport,
    }
    postAdminDealerUpdateAuthorityApi.mutate({
      dealerId: params.id,
      ...newFormValue,
    })
    setAuthorityForm(newFormValue)
  }

  const handleClickEnableInstrumentSharing = () => {
    const newFormValue = {
      ...authorityForm,
      enableInstrumentSharing: !authorityForm.enableInstrumentSharing,
    }
    postAdminDealerUpdateAuthorityApi.mutate({
      dealerId: params.id,
      ...newFormValue,
    })
    setAuthorityForm(newFormValue)
  }

  useEffect(() => {
    globalState.setLoading(postAdminDealerUpdateAuthorityApi.isLoading)
  }, [postAdminDealerUpdateAuthorityApi.isLoading])

  return (
    <>
      <DealerUserAuthorityFormDialog
        dealerId={dealer.id!}
        modalState={authorityModal}
        users={dealerUsers}
        onComplete={() => authorityModal.setOpen(false)}
      />
      <InstrumentImportModalContainer dealerId={dealer.id!} modalState={importModal} onComplete={() => importModal.setOpen(false)} />
      <ContentHeader>
        <ContentTitle title={`ディーラー詳細 - ${dealer.name || ''} ${dealer.branch || ''}`}/>
          {dealerUsers.length > 0 && (
            <Flex>
              <ThemedButton color="primary" onClick={() => authorityModal.setOpen(true)}>
                権限設定
              </ThemedButton>
              <ThemedButton color="primary" onClick={handleClickImport}>
                機器インポート
              </ThemedButton>
            </Flex>
          )}
      </ContentHeader>
      <Paper style={{ padding: 20 }}>
        <div style={{ fontWeight: 'bold', fontSize: 18 }}>ディーラーユーザー</div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <ExTableCell minWidth={300}>ユーザー名</ExTableCell>
                <ExTableCell minWidth={200}>メールアドレス</ExTableCell>
                <ExTableCell width={160} align="center"></ExTableCell>
              </TableRow>
            </TableHead>
            <ExTableBody loading={dealerUsersApi.loading}>
              {dealerUsers.map((user: DealerUser) => {
                return (
                  <ExTableRow key={user.id}>
                    <ExTableCell>{user.name}</ExTableCell>
                    <ExTableCell>{user.email}</ExTableCell>
                    <ExTableCell>
                      <ThemedButton color="secondary" onClick={() => handleAgentLogin(user.id!)}>
                        代行ログイン
                      </ThemedButton>
                    </ExTableCell>
                  </ExTableRow>
                )
              })}
            </ExTableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Paper style={{ padding: 20, marginTop: 20, width: '45%', marginBottom: 20 }}>
        <div style={{ fontWeight: 'bold', fontSize: 18 }}>機能設定</div>
        <TableContainer>
          <Table>
            <ExTableBody loading={dealerUsersApi.loading}>
              <ExTableRow>
                <ExTableCell>機器インポート</ExTableCell>
                <ExTableCell>
                  <Switch checked={authorityForm.instrumentImport} onClick={handleClickInstrumentImport} />
                </ExTableCell>
              </ExTableRow>
              <ExTableRow>
                <ExTableCell>機器共有機能</ExTableCell>
                <ExTableCell>
                  <Switch checked={authorityForm.enableInstrumentSharing} onClick={handleClickEnableInstrumentSharing} />
                </ExTableCell>
              </ExTableRow>
            </ExTableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  )
}

export default withRouter(ShowDealer)
