import { Table, TableHead, TableRow, TableContainer } from '@mui/material'
import { ProofreadEventsResponse } from 'api/mypage/proofread_plans/proofread_event'
import ImageView from 'components/shared/image_view'
import Pagination from 'components/shared/pagination'
import { ExTableCell, ExTableBody, ExTableRow } from 'components/shared/table'
import { ProofreadEvent } from 'entities/proofread_event'
import { IndexApiSet } from 'rac'
import React from 'react'
import { ProofreadEventViewModel } from 'view_models/proofread_event'

type FixDateEventTableProps = {
  indexApi: IndexApiSet<ProofreadEventsResponse> & { execute: () => void }
  onUpdate: () => void
}
export const FixDateEventTable: React.FC<FixDateEventTableProps> = (props) => {
  return (
    // TODO: ディーラー連携後、画面確認しながらテーブル高さの調整
    <TableContainer>
      <div style={{ padding: 10, backgroundColor: '#FFF2CC', margin: 10 }}>
        以下はパートナーによる校正結果の添付待ちの機器です。ラボ側での作業はありません
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <ExTableCell colSpan={2} align="center">
              製品名
            </ExTableCell>
            <ExTableCell align="center">担当者</ExTableCell>
            <ExTableCell align="center">引渡日</ExTableCell>
            <ExTableCell align="center">返却日</ExTableCell>
          </TableRow>
        </TableHead>
        <ExTableBody loading={props.indexApi.loading}>
          {props.indexApi.response.proofreadEvents.map((event: ProofreadEvent) => {
            const vm = new ProofreadEventViewModel(event)
            return (
              <ExTableRow key={event.id}>
                <ExTableCell width={100}>
                  <ImageView maxHeight={100} maxWidth={100} src={vm.getInstrumentImage()} />
                </ExTableCell>
                <ExTableCell>{vm.getInstrumentInfo()}</ExTableCell>
                <ExTableCell align="center">{vm.getUser()}</ExTableCell>
                <ExTableCell align="center">{vm.getSupplierDeliveredDate()}</ExTableCell>
                <ExTableCell align="center">{vm.getSupplierReturnedDate()}</ExTableCell>
              </ExTableRow>
            )
          })}
        </ExTableBody>
      </Table>
      <Pagination response={props.indexApi.response} pageSet={props.indexApi.pageSet} />
    </TableContainer>
  )
}

export default FixDateEventTable
