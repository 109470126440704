import StatusLabel from 'components/shared/status_label'
import { DeliveryTermKeys, deliveryTerms, deliveryTermsJa, RepairEstimate } from 'entities/repair_estimate'
import moment from 'moment'
import React from 'react'
import { ViewModel } from 'view_models'
import { InstrumentViewModel } from 'view_models/instrument'

export class RepairEstimateViewModel extends ViewModel<RepairEstimate> {
  public getRequestDate(): string {
    return moment(this.object.createdAt).format('Y/MM/DD')
  }

  public getStatusText(): string {
    switch (this.object.aasmState) {
      case 'estimating':
        return '見積中'
      case 'ordered':
        return '修理依頼済'
      case 'estimated':
        if (this.object.instrument?.approvalSettingId && this.object.repairApproval) {
          if (this.object.repairApproval.aasmState === 'applied') {
            return `${this.object.repairApproval?.nextApproval}次承認`
          } else {
            return this.object.repairApproval.aasmState === 'approved' ? '承認済' : '却下'
          }
        } else {
          return '見積済'
        }
      default:
        return ''
    }
  }

  public getStatusColor(): string {
    switch (this.object.aasmState) {
      case 'estimating':
        return '#808080'
      case 'ordered':
        return '#4CAF50'
      case 'estimated':
        if (this.object.instrument?.approvalSettingId && this.object.repairApproval) {
          if (this.object.repairApproval.aasmState === 'applied') {
            return '#3788D8'
          } else {
            return this.object.repairApproval.aasmState === 'approved' ? '#4CAF50' : '#F44336'
          }
        } else {
          return '#9C27B0'
        }
      default:
        return ''
    }
  }

  public getStatusLabel() {
    switch (this.object.aasmState) {
      case 'estimating':
        return <StatusLabel text={this.getStatusText()} color="red" />
      case 'estimated':
        return <StatusLabel text={this.getStatusText()} color="green" />
      case 'ordered':
        return <StatusLabel text={this.getStatusText()} color="blue" />
      default:
        return '-'
    }
  }

  public getRequestUserName(): string {
    return this.object.userName || '-'
  }

  public getSupplierName(): string {
    return this.object.supplierName || '-'
  }

  public getLaboratoryName(): string {
    return this.object.laboratory?.name || '-'
  }

  public getInstrumentName(): string {
    return this.object.instrument?.instrumentBasicInfo?.name || '-'
  }

  public getPriceText(): string {
    return this.object.total ? `¥${this.object.total.toLocaleString()}` : '-'
  }

  public getPriceExTaxText(): string {
    return this.object.subtotal ? `¥${this.object.subtotal.toLocaleString()}` : '-'
  }

  public getDeliveryTerm(): string {
    const entries = Object.entries(deliveryTerms)
    const elem = entries.find(([_, val]) => val === this.object.deliveryTerm)
    if (elem) {
      const key = elem[0]
      return deliveryTermsJa[key as DeliveryTermKeys]
    } else {
      return '-'
    }
  }
  public getEstimateNumber(): string {
    return this.object.estimateNumber || '-'
  }

  public getNote(): string {
    return this.object.note || '-'
  }

  public getSurvey(): string {
    return this.object.survey || '-'
  }

  public getInstrumentInfo = (linkDisabled = false) => {
    if (this.object.instrument) {
      const vm = new InstrumentViewModel(this.object.instrument)
      return vm.getInstrumentBaseInfoIncludeMaker(linkDisabled)
    } else {
      '-'
    }
  }
}
