import { Button, Modal, Paper } from '@mui/material'
import { OnetimePasswordForm } from 'api/admin/authorization'
import OtpInput from 'react-otp-input';
import { Form } from 'rac'
import React, { useState } from 'react'

type OnetimePasswordProps = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  form: Form<OnetimePasswordForm>
  handleClick: () => void
}

export const OnetimePassword: React.FC<OnetimePasswordProps> = (props) => {
  const handleClose = () => {
    props.setOpen(false)
  }

  const [otp, setOtp] = useState<string>('')

  const handleChange = (otp: string) => {
    setOtp(otp)
    props.form.newUpdateObject('onetimePassword', otp)
  }

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
      }}
    >
      <Paper
        sx={{
          width: 540,
          padding: 2.5,
          marginTop: 5,
        }}
      >
        <p style={{ margin: 0 }}>ご登録メールアドレスに送信したワンタイムパスワードを入力してください。</p>
        <p style={{ color: 'red' }}>半角数字で入力してください。</p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            margin: 20,
          }}
        >
          <OtpInput
            value={otp}
            isInputNum
            onChange={handleChange}
            numInputs={6}
            inputStyle={{
              height: 50,
              width: 50,
              margin: 10,
              fontSize: 25,
            }}
          />
        </div>
        <div>
          <Button
            onClick={props.handleClick}
            color="primary"
            style={{
              float: 'right',
            }}
          >
            ログイン
          </Button>
        </div>
      </Paper>
    </Modal>
  )
}

export default OnetimePassword
