import { Paper, TableContainer, TableHead, Box, Table, TableRow, TableCell } from '@mui/material'
import {
  usePartnerDeleteInstrumentCategoryApi,
  usePartnerFetchInstrumentCategoriesApi,
  usePartnerPatchInstrumentCategoryApi,
  usePartnerPostInstrumentCategoryApi,
} from 'api/partner/instrument_categories'
import { Flex } from 'components/shared/flex'
import {
  NewInstrumentCategoryFormDialog,
  EditInstrumentCategoryFormDialog,
  DeleteInstrumentCategoryButton,
} from 'components/shared/instrument_category_form_dialog'
import { useModal } from 'components/shared/modal'
import { ExTableBody } from 'components/shared/table/table_body/ex_table_body'
import { ExTableCell } from 'components/shared/table/table_cell/ex_table_cell'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { InstrumentCategory } from 'entities/instrument_category'
import React, { useEffect, useContext, useState } from 'react'

type InstrumentCategoryListBoxProps = {}

export const InstrumentCategoryListBox: React.FC<InstrumentCategoryListBoxProps> = (_props: InstrumentCategoryListBoxProps) => {
  const fetchApi = usePartnerFetchInstrumentCategoriesApi()
  const postApi = usePartnerPostInstrumentCategoryApi()
  const patchApi = usePartnerPatchInstrumentCategoryApi()
  const deleteApi = usePartnerDeleteInstrumentCategoryApi()
  const globalState = useContext(GlobalStateContext)
  const [instrumentCategory, setInstrumentCategory] = useState<InstrumentCategory>()
  const modalState = useModal()

  const handleClickEditButton = (instrumentCategory: InstrumentCategory) => {
    setInstrumentCategory(instrumentCategory)
    modalState.setOpen(true)
  }

  useEffect(() => {
    fetchApi.execute()
  }, [])

  const handleComplete = () => {
    fetchApi.execute()
  }

  useEffect(() => {
    globalState.setLoading(fetchApi.loading)
  }, [fetchApi.loading])

  return (
    <>
      <EditInstrumentCategoryFormDialog
        onComplete={handleComplete}
        instrumentCategory={instrumentCategory}
        modalState={modalState}
        apiSet={patchApi}
        onSubmit={(form) => patchApi.execute(form)}
      />
      <Box>
        <Box display={'flex'} alignItems={'center'} marginBottom={1}>
          <h3 style={{ marginRight: '10px' }}>機器カテゴリ</h3>
          <NewInstrumentCategoryFormDialog onComplete={handleComplete} apiSet={postApi} onSubmit={(form) => postApi.execute(form)} />
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>名前</TableCell>
                <ExTableCell width={150} />
              </TableRow>
            </TableHead>

            <ExTableBody loading={fetchApi.loading}>
              {fetchApi.response?.instrumentCategories.map((instrumentCategory: InstrumentCategory, idx: number) => {
                return (
                  <TableRow key={idx}>
                    <TableCell>{instrumentCategory.name}</TableCell>
                    <ExTableCell>
                      <Flex>
                        <ThemedButton color="secondary" onClick={() => handleClickEditButton(instrumentCategory)}>
                          編集
                        </ThemedButton>
                        <DeleteInstrumentCategoryButton
                          onComplete={handleComplete}
                          instrumentCategory={instrumentCategory}
                          apiSet={deleteApi}
                          onSubmit={(id) => {
                            deleteApi.execute(id)
                          }}
                        />
                      </Flex>
                    </ExTableCell>
                  </TableRow>
                )
              })}
            </ExTableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}
