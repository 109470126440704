import { Checkbox, Tooltip } from '@mui/material'
import { ExTableCellProps, ExTableCell } from 'components/shared/table/table_cell/ex_table_cell'
import { ID } from 'entities'
import React from 'react'
import { CheckboxesSet } from 'utils/hooks/index'

type CheckboxTableCellProps = ExTableCellProps & {
  checkboxesSet: CheckboxesSet
  checkboxId: ID
  disabled?: boolean
  tooltipText?: string
}

export const CheckboxTableCell = (props: CheckboxTableCellProps) => {
  const handleClick = (e: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>) => {
    props.checkboxesSet.updateCheckedIds(props.checkboxId)
    e.stopPropagation()
  }

  const { checkboxesSet, checkboxId, disabled, tooltipText, ...rest } = props

  return (
    <ExTableCell {...rest} width={40} onClick={disabled ? undefined : handleClick} style={{ padding: 5 }}>
      <Tooltip title={tooltipText}>
        <div>
          <Checkbox checked={checkboxesSet.isChecked(checkboxId)} value="primary" disabled={disabled} />
        </div>
      </Tooltip>
    </ExTableCell>
  )
}

type AllCheckboxTableCellProps = ExTableCellProps & {
  checkboxSet: CheckboxesSet
}

export const AllCheckboxTableCell: React.FC<AllCheckboxTableCellProps> = (props: AllCheckboxTableCellProps) => {
  const { checkboxSet, ...rest } = props

  return (
    <ExTableCell {...rest} width={40} onClick={checkboxSet.changeAllChecked} style={{ padding: 5 }}>
      <Checkbox checked={checkboxSet.allChecked} value="primary" />
    </ExTableCell>
  )
}
