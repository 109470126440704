import { BaseEntity } from 'entities'
import { Instrument } from 'entities/instrument'

export type PrintImageType = 'barcode' | 'qrcode'

export type PrintLayoutSetting = {
  pagePaddingTb?: number
  pagePaddingLr?: number
  gapTb?: number
  gapLr?: number
  imageScale?: number
  fontSize?: number
  width?: number
  height?: number
  perPage?: number
  offset?: number
  paddingTb?: number
  paddingLr?: number
  showBorder?: boolean
}

export type PrintSetting = BaseEntity &
  PrintLayoutSetting &
  CodeDisplaySetting & {
    laboratoryId?: number
  }

export type PrintSettingForm = PrintSetting & {}

export type PrintSettingCheckItem = {
  key:
  | 'showName'
  | 'showMaker'
  | 'showModelNumber'
  | 'showManageNumber'
  | 'showSerialNumber'
  | 'showBorder'
  | 'showDealerName'
  | 'showOutsideLaboratoryName'
  | 'showOutsideDealerContactDetail'
  | 'showBaseDate'
  label:
  | '製品名'
  | 'メーカー'
  | '型番'
  | '管理番号'
  | 'シリアル番号'
  | '罫線表示'
  | '担当業者'
  | '顧客名'
  | '連絡先'
  | '最終校正月'
}

export type CodeProps = {
  instrument: Instrument
  displaySetting: CodeDisplaySetting
  layoutSetting?: CodeLayoutSetting
}

type CodeLayoutSetting = {
  areaWidth?: number | string
  height?: number | string
  padding?: number | string
  imageScale?: number | string
  fontScale?: string
  border?: string
}

type CodeDisplaySetting = {
  showName?: boolean
  showMaker?: boolean
  showModelNumber?: boolean
  showManageNumber?: boolean
  showSerialNumber?: boolean
  showBorder?: boolean
  showDealerName?: boolean
  showOutsideLaboratoryName?: boolean
  showOutsideDealerContactDetail?: boolean
  showBaseDate?: boolean
}

type PageLayoutSetting = {
  padding?: string
  gap?: string
}

export const getRecommendSmallLayoutSetting = (): PrintLayoutSetting => {
  return {
    pagePaddingTb: 30.5,
    pagePaddingLr: 23,
    gapTb: 4,
    gapLr: 4,
    imageScale: 100,
    fontSize: 100,
    width: 20,
    height: 20,
    perPage: 70,
    offset: 0,
    paddingTb: 0.2,
    paddingLr: 1.4,
    showBorder: false,
  }
}

export const getRecommendLargeLayoutSetting = (): PrintLayoutSetting => {
  return {
    pagePaddingTb: 14.5,
    pagePaddingLr: 22,
    gapTb: 4,
    gapLr: 4,
    imageScale: 100,
    fontSize: 100,
    width: 30,
    height: 30,
    perPage: 40,
    offset: 0,
    paddingTb: 0.8,
    paddingLr: 2,
    showBorder: false,
  }
}

export const getRecommendCodeDisplaySetting = (): CodeDisplaySetting => {
  return {
    showName: false,
    showMaker: false,
    showModelNumber: false,
    showManageNumber: false,
    showSerialNumber: false,
    showDealerName: false,
    showOutsideDealerContactDetail: false,
    showBaseDate: false,
  }
}

export const getPageLayoutSetting = (formObject: PrintSettingForm): PageLayoutSetting => ({
  padding: `${formObject.pagePaddingTb}mm ${formObject.pagePaddingLr}mm `,
  gap: `${formObject.gapTb}mm ${formObject.gapLr}mm `,
})

export const getCodeLayoutSetting = (formObject: PrintSettingForm): CodeLayoutSetting => ({
  areaWidth: `${formObject.width}mm`,
  height: `${formObject.height}mm`,
  padding: `${formObject.paddingTb}mm ${formObject.paddingLr}mm `,
  imageScale: `${formObject.imageScale}%`,
  fontScale: `scale(${formObject.fontSize ? formObject.fontSize / 100 : ''})`,
  border: formObject.showBorder ? '1px dashed rgb(221, 221, 221)' : 'none',
})

export const getCodeDisplaySetting = (formObject: PrintSettingForm): CodeDisplaySetting => ({
  showName: formObject.showName,
  showMaker: formObject.showMaker,
  showModelNumber: formObject.showModelNumber,
  showManageNumber: formObject.showManageNumber,
  showSerialNumber: formObject.showSerialNumber,
  showDealerName: formObject.showDealerName,
  showOutsideDealerContactDetail: formObject.showOutsideDealerContactDetail,
  showOutsideLaboratoryName: formObject.showOutsideLaboratoryName,
  showBaseDate: formObject.showBaseDate,
})