import { Paper } from '@mui/material'
import { useFetchInstrumentsApi } from 'api/mypage/instrument'
import { useFetchRoomsApi } from 'api/mypage/room'
import { InstrumentTableWithBarcode } from 'components/mypage/instrument/instrument_table_with_barcode'
import AccessDeniedMessage from 'components/shared/access_denied_message'
import { CheckboxFooter } from 'components/shared/checkbox_footer'
import { ContentHeader, ContentTitle } from 'components/shared/content'
import { Flex } from 'components/shared/flex'
import { SelectField } from 'components/shared/form/input'
import { SearchBar } from 'components/shared/search_bar'
import { DisplaySettingModal } from 'components/shared/table/display_setting'
import ThemedButton from 'components/shared/themed_button'
import { MypageStateContext } from 'contexts/mypage_state_context'
import { InstrumentSearchForm } from 'entities/instrument'
import { Room } from 'entities/room'
import { useForm } from 'rac'
import React, { useEffect, useContext } from 'react'
import { StaticContext } from 'react-router'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { useCheckboxes, useDisplaySetting } from 'utils/hooks/index'

const initialDisplaySettings = [
  { attr: 'createdAt', name: '作成日', visible: true },
  { attr: 'maker', name: 'メーカー', visible: true },
  { attr: 'room', name: '部屋', visible: true },
  { attr: 'purchasedAt', name: '取得日', visible: true },
  { attr: 'purchasedPrice', name: '取得価額', visible: true },
]

type BarcodeIndexProps = {}

type Props = RouteComponentProps<{}, StaticContext, BarcodeIndexProps>

const BarcodeIndex: React.FC<Props> = (_props: Props) => {
  const searchForm = useForm<InstrumentSearchForm>({ q: {} })
  const instrumentIndexApi = useFetchInstrumentsApi(searchForm)
  const displaySettingSet = useDisplaySetting(initialDisplaySettings, 'instruments-table')
  const roomIndexApi = useFetchRoomsApi()
  const checkboxSet = useCheckboxes(instrumentIndexApi.response.instruments)

  useEffect(() => {
    roomIndexApi.execute()
    instrumentIndexApi.execute()
  }, [])

  const handleSearch = () => {
    instrumentIndexApi.executeAndPageReset()
  }

  const handleJumpPreviewPage = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    window.location.href = `${window.location.protocol}//${window.location.host}/mypage/barcodes/preview?ids=${checkboxSet.checkedIds}`
  }

  const tableProps = { displaySettingSet, instrumentIndexApi, checkboxSet }

  const mypageState = useContext(MypageStateContext)
  const currentUser = mypageState.currentUser
  if (currentUser.laboratory?.isManaged) {
    return <AccessDeniedMessage target="該当情報" />
  }

  return (
    <>
      <ContentHeader subHeader={<DisplaySettingModal displaySettingSet={displaySettingSet} />}>
        <ContentTitle title="QRコード一覧" />
        <Flex alignItems="center">
          <SelectField
            label="部屋"
            width={200}
            form={searchForm}
            attr={['q', 'instrumentRoomIdEq']}
            labelId="search-select-room"
            data={
              roomIndexApi.response?.rooms
                ? roomIndexApi.response.rooms.map((room: Room) => {
                    return { value: room.id!, label: room.name! }
                  })
                : []
            }
            includeBlank
          />
          <SearchBar
            form={searchForm}
            searchAttr="searchText"
            placeholder="メーカー・製品名・型番・シリアル番号・管理番号で検索"
            onSubmit={handleSearch}
          />
        </Flex>
      </ContentHeader>
      <Paper>
        <InstrumentTableWithBarcode {...tableProps} />
      </Paper>
      <CheckboxFooter
        checkboxSet={checkboxSet}
        content={
          <ThemedButton color="primary" onClick={(e) => handleJumpPreviewPage(e)} style={{ marginRight: 12 }}>
            印刷ページへ
          </ThemedButton>
        }
      />
    </>
  )
}

export default withRouter(BarcodeIndex)
