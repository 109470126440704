import { EventApi, EventInput, ViewApi } from '@fullcalendar/core'
import { ToggleButton, ToggleButtonGroup } from '@mui/lab'
import { Paper } from '@mui/material'
import { useFetchInstrumentCategoriesApi } from 'api/mypage/instrument_categories'
import { useFetchXlsxLaboratoryReservationsApi } from 'api/mypage/laboratories/laboratory_reservations'
import { useFetchLaboratoryReservationsQuery } from 'api/mypage/laboratories/laboratory_reservations_mutation'
import { useFetchRoomsApi } from 'api/mypage/room'
import { useFetchXlsxUserReservationsApi } from 'api/mypage/users/user_reservations'
import { useFetchUserReservationsQuery } from 'api/mypage/users/user_reservations_mutation'
import { DownloadReservationExcelDialog } from 'components/mypage/reservation/download_reservation_excel_dialog'
import { ReservationDetailDialog } from 'components/mypage/reservation/reservation_detail_dialog'
import Calendar from 'components/shared/calendar'
import { ContentHeader, ContentTitle } from 'components/shared/content'
import { RhfSelectField } from 'components/shared/form/rhf_input'
import { useModal } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { FetchUserReservationsParams, Reservation, SelectQuery } from 'entities/reservation'
import React, { useState, useEffect, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { withRouter } from 'react-router-dom'
import { useEffectSkipFirst } from 'utils/hooks'
import { ReservationViewModel } from 'view_models/reservation'

export const ReservationIndex = () => {
  const [detailDialogOpen, setDetailDialogOpen] = useState<boolean>(false)
  const [selectedReservation, setSelectedReservation] = useState<Reservation>({})
  const [selectQuery, setSelectQuery] = useState<SelectQuery>('personal')
  const [events, setEvents] = useState<EventInput[]>([])
  const categoryIndexApi = useFetchInstrumentCategoriesApi()
  const instrumentCategories = categoryIndexApi.response?.instrumentCategories
  const roomIndexApi = useFetchRoomsApi()
  const rooms = roomIndexApi.response?.rooms
  const globalState = useContext(GlobalStateContext)
  const downloadLaboratoryXlsxApi = useFetchXlsxLaboratoryReservationsApi()
  const downloadUserXlsxApi = useFetchXlsxUserReservationsApi()

  const { control, watch } = useForm<FetchUserReservationsParams>()
  const roomIdWatch = watch('roomId')
  const instrumentCategoryIdWatch = watch('instrumentCategoryId')

  const fetchUserReservationsQuery = useFetchUserReservationsQuery({
    roomId: roomIdWatch,
    instrumentCategoryId: instrumentCategoryIdWatch,
  })
  const fetchLaboratoryReservationsQuery = useFetchLaboratoryReservationsQuery({
    roomId: roomIdWatch,
    instrumentCategoryId: instrumentCategoryIdWatch,
  })

  const refetchReservations = () => {
    if (selectQuery === 'personal') {
      fetchUserReservationsQuery.refetch()
    } else {
      fetchLaboratoryReservationsQuery.refetch()
    }
  }

  useEffect(() => {
    refetchReservations()
  }, [selectQuery])

  useEffectSkipFirst(() => {
    refetchReservations()
  }, [roomIdWatch, instrumentCategoryIdWatch])

  useEffect(() => {
    categoryIndexApi.execute()
    roomIndexApi.execute()
  }, [])

  const handleClickEvent = (arg: { el: HTMLElement; event: EventApi; jsEvent: MouseEvent; view: ViewApi }) => {
    const fetchReservationsQuery = selectQuery === 'personal' ? fetchUserReservationsQuery : fetchLaboratoryReservationsQuery
    const reservation = fetchReservationsQuery.data?.reservations.find((reservation) => `${reservation.id!}` === arg.event.id)
    if (reservation) {
      setSelectedReservation(reservation)
      setDetailDialogOpen(true)
    }
  }

  useEffect(() => {
    globalState.setLoading(fetchUserReservationsQuery.isLoading || fetchLaboratoryReservationsQuery.isLoading)

    const fetchReservationsQuery = selectQuery === 'personal' ? fetchUserReservationsQuery : fetchLaboratoryReservationsQuery
    if (fetchReservationsQuery.isSuccess) {
      const eventInputs: EventInput[] = []
      fetchReservationsQuery.data?.reservations.forEach((reservation: Reservation) => {
        const viewModel = new ReservationViewModel(reservation)
        eventInputs.push({
          id: reservation.id!.toString(),
          title: selectQuery === 'personal' ? viewModel.getMyCalendarTitle() : viewModel.getLaboratoryCalendarTitle(),
          start: reservation.startAt,
          end: reservation.endAt,
          color: viewModel.getEventColor(),
        })
      })
      setEvents(eventInputs)
    }
  }, [
    selectQuery,
    roomIdWatch,
    instrumentCategoryIdWatch,
    fetchUserReservationsQuery.isLoading,
    fetchLaboratoryReservationsQuery.isLoading,
  ])

  const handleSelectQuery = (_event: React.MouseEvent<HTMLElement>, newSelect: SelectQuery) => {
    setSelectQuery(newSelect)
  }

  const handleClickOutput = () => {
    downloadExcelModal.setOpen(true)
  }

  const downloadExcelModal = useModal()

  return (
    <>
      <ReservationDetailDialog
        open={detailDialogOpen}
        reservation={selectedReservation}
        onClose={() => {
          setDetailDialogOpen(false)
        }}
      />
      <DownloadReservationExcelDialog
        modalState={downloadExcelModal}
        outputApi={selectQuery === 'personal' ? downloadUserXlsxApi : downloadLaboratoryXlsxApi}
      />
      <ContentHeader>
        <ContentTitle title="マイカレンダー">
          <ToggleButtonGroup className='toggle-button-group' exclusive value={selectQuery} onChange={handleSelectQuery}>
            <ToggleButton value="personal" selected={selectQuery === 'personal'}>
              自分のみ
            </ToggleButton>
            <ToggleButton value="group" selected={selectQuery === 'group'}>
              全て
            </ToggleButton>
          </ToggleButtonGroup>
        </ContentTitle>

        {/* フィルター用のセレクトボックス from */}
        <div style={{ display: 'flex', marginLeft: '10px', marginRight: 'auto' }}>
          <RhfSelectField
            label="部屋"
            name="roomId"
            control={control}
            includeBlank
            blankLabel="全て"
            items={rooms?.map((room) => ({
              label: room.name || '',
              value: room.id || 0,
            }))}
            containerStyle={{ marginRight: '10px', marginLeft: '15px', fontSize: '14px' }}
            selectStyle={{ width: '200px', height: '36px', backgroundColor: '#ffffff' }}
          />

          <RhfSelectField
            label="機器カテゴリ"
            name="instrumentCategoryId"
            control={control}
            includeBlank
            blankLabel="全て"
            items={instrumentCategories?.map((instrumentCategory) => ({
              label: instrumentCategory.name ?? '',
              value: instrumentCategory.id ?? 0,
            }))}
            containerStyle={{ marginRight: '10px', marginLeft: '15px', fontSize: '14px' }}
            selectStyle={{ width: '200px', height: '36px', backgroundColor: '#ffffff' }}
            tooltip="※機器カテゴリフィルタは機器予約にのみ適用され、部屋予約には適用されません。"
            tooltipStyle={{ position: 'absolute', top: '-5px' }}
          />
        </div>
        {/* フィルター用のセレクトボックス to */}

        <ThemedButton color="secondary" onClick={handleClickOutput}>
          Excel出力
        </ThemedButton>
      </ContentHeader>
      <Paper style={{ padding: 40, marginTop: 20 }}>
        <Calendar events={events} eventClick={handleClickEvent} />
      </Paper>
    </>
  )
}

export default withRouter(ReservationIndex)
