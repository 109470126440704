import { useAuthorizationApi, AuthorizationForm, useOnetimePasswordApi, OnetimePasswordForm } from 'api/mypage/authorization'
import LoginForm from 'components/shared/login/login_form'
import OnetimePassword from 'components/shared/login/onetime_password'
import { GlobalStateContext } from 'contexts/global_state_context'
import { ReactRouterLocation } from 'entities/react_router'
import { useForm } from 'rac'
import React, { useContext, useEffect, useState } from 'react'
import { Redirect, StaticContext } from 'react-router'
import { RouteComponentProps, withRouter, useLocation } from 'react-router-dom'
import { MypageRouteHelper } from 'routes/mypage'
import CookieManager from 'utils/cookie_util'
import './index.scss'

type LoginPageProps = unknown
type Props = RouteComponentProps<{}, StaticContext, LoginPageProps>

const LoginPage: React.FC<Props> = (props) => {
  const api = useAuthorizationApi()
  const globalState = useContext(GlobalStateContext)
  const form = useForm<AuthorizationForm>({ email: '', password: '' })

  const onetimeApi = useOnetimePasswordApi()
  const [open, setOpen] = useState(false)
  const onetimeForm = useForm<OnetimePasswordForm>({ email: '', onetimeToken: '', onetimePassword: '' })

  const location = useLocation() as ReactRouterLocation
  /** ログイン後のリダイレクト先 */
  const redirectUrlAfterLogin = location.state?.from.pathname || '/mypage/rooms'

  useEffect(() => {
    globalState.setLoading(api.loading)
    if (!api.isSuccess()) return

    if (api.response.accessToken) {
      CookieManager.saveUserToken(api.response.accessToken)
      props.history.replace(redirectUrlAfterLogin)
    } else if (api.response.onetimeToken) {
      onetimeForm.newUpdateObject('onetimeToken', api.response.onetimeToken)
      setOpen(true)
    }
  }, [api.loading])

  useEffect(() => {
    globalState.setLoading(onetimeApi.loading)
    if (!onetimeApi.isSuccess()) return

    if (onetimeApi.response.accessToken) {
      CookieManager.saveUserToken(onetimeApi.response.accessToken)
      props.history.push('/mypage/rooms')
    }
  }, [onetimeApi.loading])

  const handleClickLoginButton = () => {
    onetimeForm.newUpdateObject('email', form.object.email)
    api.execute(form)
  }

  const handleClickOnetimeLoginButton = () => {
    onetimeApi.execute(onetimeForm)
  }

  if (CookieManager.hasUserToken()) return <Redirect to={{ pathname: MypageRouteHelper.roomIndex() }} />

  return (
    <div className="login">
      <OnetimePassword open={open} setOpen={setOpen} form={onetimeForm} handleClick={handleClickOnetimeLoginButton} />
      <LoginForm form={form} handleClick={handleClickLoginButton} />
    </div>
  )
}

export default withRouter(LoginPage)
