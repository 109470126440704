import { AxiosHttpClient } from 'api/axios'
import { useRepruaDownloadApi, useRepruaIndexApi } from 'api/shared_api_hooks'
import { PagingResponse } from 'entities'
import { initialInstrumentFilter, InstrumentFilter } from 'entities/instrument'
import { ProofreadEvent, ProofreadEventSearchForm } from 'entities/proofread_event'
import { BaseResponse, Form, IndexApiSet } from 'rac'
import { useEffectSkipFirst } from 'utils/hooks'

export type ProofreadEventsResponse = PagingResponse & {
  proofreadEvents: ProofreadEvent[]
  filter: InstrumentFilter
}
export type ProofreadEventResponse = BaseResponse & {
  proofreadEvent: ProofreadEvent
}

/**
 * 一覧
 */
export type FetchProofreadEventsApi = IndexApiSet<ProofreadEventsResponse> & {
  execute: (formObject?: ProofreadEventSearchForm) => void
  executeAndPageReset: () => void
}
export function useFetchProofreadEventsApi(planId: number, form: Form<ProofreadEventSearchForm>): FetchProofreadEventsApi {
  const api = useRepruaIndexApi<ProofreadEventsResponse, ProofreadEventSearchForm>(new AxiosHttpClient('user'), {
    initialResponse: { proofreadEvents: [], filter: initialInstrumentFilter, totalCount: 0 },
  })

  const execute = (formObject?: ProofreadEventSearchForm) => {
    const path = `proofread_plans/${planId}/proofread_events`
    api.execute(path, { params: { ...form.object, ...formObject } })
  }

  const executeAndPageReset = () => {
    if (api.pageSet.page > 1) {
      api.pageSet.setPage(1)
    } else {
      execute()
    }
  }

  useEffectSkipFirst(() => {
    execute()
  }, [api.pageSet.page, api.pageSet.perPage, api.orderSet.order, api.orderSet.orderBy])

  return { ...api, execute, executeAndPageReset }
}

/**
 * 出力
 */
export function useExportProofreadEventsApi(planId: number): { execute: () => void } {
  const api = useRepruaDownloadApi<any>(new AxiosHttpClient('user'))
  const path = `proofread_plans/${planId}/proofread_events.xlsx`

  const execute = () => {
    api.execute(path)
  }

  return { execute }
}
