import { DropDownCheckboxItem, SelectData } from 'components/shared/form/input'
import qs from 'qs'

const snakeCase = require('lodash.snakecase')
const pluralize = require('pluralize')

export const selectDataGenerator = <T extends { [key: string]: any }>(
  items: T[] | null | undefined,
  valueKey: keyof T,
  labelKey: keyof T,
  includeBlank?: boolean,
) => {
  const results: SelectData[] = []

  if (items) {
    for (const item of items) {
      const value = item[valueKey]
      const label = item[labelKey]
      if (value && label) {
        results.push({ value: value, label: label })
      }
    }
  }
  if (includeBlank) {
    results.unshift({ value: '', label: '未選択' })
  }

  return results
}

// モデル名をスネークケースの複数形に変換する
// ex) "DealerMemo" -> "dealer_memos"
export const toPluralSnakeCase = (word: string): string => {
  return pluralize(snakeCase(word))
}

/**
 * 配列を引数のsizeごとの二次元配列に分割する
 */
export const chunk = <T>(arr: T[], size: number) => {
  return arr.reduce((newArr, _, i) => (i % size ? newArr : [...newArr, arr.slice(i, i + size)]), [] as T[][])
}

/**
 * URLのqueryをオブジェクトにマッピングして返す
 */
export const getUrlSearchParams = <T>(): T | undefined => {
  const search = window.location.search.substring(1)
  const params: { [key: string]: any } = qs.parse(search)
  return params as T
}

export const booleanFilterOptions = [
  { value: 'true', label: '有効' },
  { value: 'false', label: '無効' },
]

export const booleanFilterValues = booleanFilterOptions.map((option) => option['value'])
