import { Typography } from '@mui/material'
import AttachmentsList from 'components/shared/data/attachments_list'
import DataBlock from 'components/shared/data/data_block'
import DataCard from 'components/shared/data/data_card'
import DataLabel from 'components/shared/data/data_label'
import { DropdownMenu, ListItem } from 'components/shared/dropdown_menu'
import { Flex } from 'components/shared/flex'
import ImageView from 'components/shared/image_view'
import Modal, { ModalState, useModal } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { Instrument } from 'entities/instrument'
import { InstrumentBasicInfo } from 'entities/instrument_basic_info'
import React, { useEffect, useState } from 'react'
import { InstrumentViewModel } from 'view_models/instrument'
import { InstrumentBasicInfoViewModel } from 'view_models/instrument_basic_info'

type InstrumentDataCardProps = {
  instrument: Instrument
  canEdit?: boolean
  onClickEdit?: () => void
  onClickMemo?: () => void
}
export const InstrumentDataCard: React.FC<InstrumentDataCardProps> = (props) => {
  const [vm, setVm] = useState(new InstrumentViewModel(props.instrument))
  const labBasicInfo = props.instrument.labBasicInfo
  const [listItems, setListItems] = useState<ListItem[]>([])
  const labBasicInfoModal = useModal()

  useEffect(() => {
    setVm(new InstrumentViewModel(props.instrument))
    const items: ListItem[] = []
    if (props.onClickEdit) {
      items.push({ text: '機器情報の編集', onClick: () => props.onClickEdit!() })
    }
    if (props.onClickMemo) {
      items.push({ text: 'メモの入力', onClick: () => props.onClickMemo!() })
    }
    setListItems(items)
  }, [props.instrument])

  return (
    <>
      <LabBasicInfoDialog modalState={labBasicInfoModal} labBasicInfo={labBasicInfo} />
      <DataCard>
        <Flex alignItems="center" justifyContent="space-between">
          <Typography variant="h6">機器情報</Typography>
          <Flex>
            {labBasicInfo && (
              <ThemedButton color="secondary" onClick={() => labBasicInfoModal.setOpen(true)}>
                顧客の機器情報
              </ThemedButton>
            )}
            {props.canEdit && <DropdownMenu id="instrument-edit" buttonText="編集" listItems={listItems} buttonColor="primary" />}
          </Flex>
        </Flex>
        <DataBlock>
          <div style={{ width: '50%', marginRight: 10 }}>
            <ImageView style={{ width: '100%' }} maxWidth={500} maxHeight={200} src={vm.getImageUrl()} />
          </div>
          <Flex flexDirection="column" style={{ width: '50%' }}>
            <DataBlock style={{ width: '100%' }}>
              <DataLabel label="機器名" value={vm.getInstrumentName()} />
            </DataBlock>
            <DataBlock style={{ width: '100%' }} noBorder>
              <DataLabel label="メーカー / 型番" value={`${vm.getMakerName()} / ${vm.modelNumber()}`} />
            </DataBlock>
          </Flex>
        </DataBlock>
        <DataBlock>
          <DataLabel label="管理番号" value={vm.manageNumber()} width="30%" />
          <DataLabel label="シリアルNo." value={vm.serialNumber()} width="30%" />
        </DataBlock>
        <DataBlock>
          <DataLabel label="機器カテゴリ" value={vm.instrumentCategoryName()} width="30%" />
          <DataLabel label="顧客" value={vm.ownerableName()} width="30%" />
          <DataLabel label="部署" value={vm.dealerDivisionNames()} width="30%" />
        </DataBlock>
        <DataBlock>
          <DataLabel label="外部リンク" value={vm.getLink()} />
        </DataBlock>
        <DataBlock>
          <AttachmentsList fileAttachments={props.instrument.instrumentBasicInfo?.fileAttachments || []} />
        </DataBlock>
        <DataBlock noBorder>
          <DataLabel label="備考" value={vm.getNote()} />
        </DataBlock>
      </DataCard>
    </>
  )
}

type LabBasicInfoDialogProps = {
  modalState: ModalState
  labBasicInfo?: InstrumentBasicInfo
}
const LabBasicInfoDialog = ({ modalState, labBasicInfo }: LabBasicInfoDialogProps) => {
  const vm = new InstrumentBasicInfoViewModel(labBasicInfo || {})
  const handleClose = () => {
    modalState.setOpen(false)
  }

  return (
    <Modal
      modalId="new-defect-report"
      modalState={modalState}
      title="顧客の機器情報"
      size="sm"
      footer={
        <>
          <ThemedButton onClick={handleClose} color="secondary">
            閉じる
          </ThemedButton>
        </>
      }
    >
      <DataBlock>
        <DataLabel label="機器名" value={vm.getInstrumentName()} />
      </DataBlock>
      <DataBlock>
        <DataLabel label="部屋" value={vm.getInstrumentRoomName()} />
      </DataBlock>
      <DataBlock>
        <DataLabel label="メーカー / 型番" value={`${vm.getMakerName()} / ${vm.modelNumber()}`} />
      </DataBlock>
      <DataBlock>
        <DataLabel label="管理番号" value={vm.manageNumber()} width="50%" />
        <DataLabel label="シリアルNo." value={vm.serialNumber()} width="50%" />
      </DataBlock>
      <DataBlock>
        <DataLabel label="機器カテゴリ" value={vm.instrumentCategoryName()} />
      </DataBlock>
      <DataBlock>
        <DataLabel label="外部リンク" value={vm.getLink()} />
      </DataBlock>
      <DataBlock noBorder>
        <DataLabel label="備考" value={vm.getNote()} />
      </DataBlock>
    </Modal>
  )
}

export default InstrumentDataCard
