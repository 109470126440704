import { ExAttribute } from 'entities/ex_attribute'
import React from 'react'

type TableExAttributesInfoProps = {
  tableExAttrs: ExAttribute[]
  id?: number
}
export const TableExAttributesInfo = (props: TableExAttributesInfoProps) => {
  return (
    <div>
      {props.tableExAttrs.map((ex) => {
        let value = ex.exAttributeValue.value
        if (ex.exColumn.inputType === 'price' && value) {
          // 金額タイプなら桁区切り
          value = `¥${parseInt(value).toLocaleString()}`
        }
        return (
          <div style={{ fontSize: 12, lineHeight: '12px' }} key={`ex-attribute-${props.id}-${ex.exColumn.id}`}>
            {value || '-'}
          </div>
        )
      })}
    </div>
  )
}
