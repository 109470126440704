import { AxiosHttpClient } from 'api/axios'
import { useRepruaDeleteApi, useRepruaIndexApi, useRepruaPostApi, useRepruaShowApi } from 'api/shared_api_hooks'
import { ID, PagingResponse } from 'entities'
import { ProofreadPlan, ProofreadPlanForm, ProofreadPlanSearchForm } from 'entities/proofread_plan'
import { ApiSet, BaseResponse, Form, IndexApiSet } from 'rac'
import { useEffectSkipFirst } from 'utils/hooks'

export type ProofreadPlansResponse = PagingResponse & {
  proofreadPlans: ProofreadPlan[]
}

/**
 * 一覧
 */
export function useFetchProofreadPlansApi(): IndexApiSet<ProofreadPlansResponse> & { execute: () => void } {
  const api = useRepruaIndexApi<ProofreadPlansResponse, ProofreadPlanSearchForm>(new AxiosHttpClient('user'), {
    initialResponse: { proofreadPlans: [], totalCount: 0 },
  })

  const execute = () => {
    const path = 'proofread_plans'
    api.execute(path)
  }

  useEffectSkipFirst(() => {
    execute()
  }, [api.pageSet.page, api.pageSet.perPage, api.orderSet.order, api.orderSet.orderBy])

  return { ...api, execute }
}

export type ProofreadPlanResponse = BaseResponse & {
  proofreadPlan: ProofreadPlan
}
/**
 * 取得
 */
export function useFetchProofreadPlanApi(id: number): ApiSet<ProofreadPlanResponse> & { execute: () => void } {
  const api = useRepruaShowApi<ProofreadPlanResponse, undefined>(new AxiosHttpClient('user'), {
    initialResponse: { proofreadPlan: {} },
  })

  const execute = () => {
    const path = `proofread_plans/${id}`
    api.execute(path)
  }

  return { ...api, execute }
}

/**
 * 登録
 */
export function usePostProofreadPlanApi(
  form: Form<ProofreadPlanForm>,
): ApiSet<ProofreadPlanResponse> & {
  execute: () => void
} {
  const api = useRepruaPostApi<ProofreadPlanResponse, ProofreadPlanForm>(new AxiosHttpClient('user'), {
    initialResponse: { proofreadPlan: {} },
  })

  const execute = () => {
    const path = 'proofread_plans'
    api.execute(path, form)
  }

  return { ...api, execute }
}

/**
 * 削除
 */
export function useDeleteProofreadPlanApi(): ApiSet<ProofreadPlanResponse> & { execute: (id: ID) => void } {
  const api = useRepruaDeleteApi<ProofreadPlanResponse>(new AxiosHttpClient('user'), { initialResponse: { proofreadPlan: {} } })

  const execute = (id: ID) => {
    const path = `proofread_plans/${id}`
    api.execute(path)
  }

  return { ...api, execute }
}
