import { TableRow, TableRowProps } from '@mui/material'
import React, { ReactNode } from 'react'

export type ExTableRowProps = TableRowProps & {
  children: ReactNode
  checked?: boolean
}

export const ExTableRow: React.FC<ExTableRowProps> = (props: ExTableRowProps) => {
  const checkedColor = '#c2dbff'

  const baseProps = () => {
    const p = { ...props }
    delete p.checked
    return p
  }

  const backgroundColor = (): string => {
    if (props.checked) {
      return checkedColor
    } else {
      return 'inherit'
    }
  }

  return (
    <TableRow className="ex-table-row" {...baseProps()} style={{ backgroundColor: backgroundColor() }}>
      {props.children}
    </TableRow>
  )
}

export default ExTableRow
