import { BaseEntity } from '.'

/**
 * ディーラーユーザー権限
 */
export type DealerUserAuthority = BaseEntity & {
  dealerUserId?: number
  privileged?: boolean
}

export type DealerUserAuthorityForm = DealerUserAuthority

export type BulkUpdateDealerUserAuthorityForm = {
  dealerUserAuthorities: DealerUserAuthorityForm[]
}

export const authorityData = {
  privileged: '管理者',
}

export const initialFormState = {
  dealerUserAuthorities: [],
}

export const authorityKeys = Object.keys(authorityData)
export type AuthorityTypes = 'privileged'
