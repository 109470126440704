import { SelectItem } from 'components/shared/form/rhf_input'

import { BaseEntity } from '.'

/**
 * 部屋
 */
export type SubscriptionPlan = BaseEntity & {
  laboratoryId?: number
  limitCount?: number
}

export type SubscriptionPlanForm = SubscriptionPlan

export type SubscriptionPlanType = 'small' | 'large' | 'unlimited' | 'option'

export const subscriptionPlanTypeSelectData: SelectItem[] = [
  {
    value: 'small',
    label: 'Small（20機器まで）',
  },
  {
    value: 'large',
    label: 'Large（100機器まで）',
  },
  {
    value: 'unlimited',
    label: 'Unlimited（無制限）',
  },
]

// サブスクのプランタイプの取得
export const getSubscriptionPlanTypeFromLimitCount = (limitCount?: number): SubscriptionPlanType => {
  if (limitCount === 20) {
    return 'small'
  } else if (limitCount === 100) {
    return 'large'
  } else if (!limitCount) {
    return 'unlimited'
  } else {
    return 'option'
  }
}
