import { usePatchApprovalSettingApi, usePostApprovalSettingApi } from 'api/mypage/approval_setting'
import { Flex } from 'components/shared/flex'
import { CheckBoxField, NumberField, RadioButtonField, SelectField } from 'components/shared/form/input'
import { ModalState } from 'components/shared/modal'
import { ModalStep, StepperModal } from 'components/shared/stepper_modal'
import { GlobalStateContext } from 'contexts/global_state_context'
import { ApprovalCondition } from 'entities/approval_conditon'
import { ApprovalSetting, ApprovalSettingForm } from 'entities/approval_setting'
import { Approver } from 'entities/approver'
import { User } from 'entities/user'
import { Form, useForm } from 'rac'
import React, { useContext, useEffect, useState } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'

import ApprovalChart from './approval_chart'

export const APPROVALS: ApprovalStep[] = ['first', 'second', 'third', 'fourth', 'fifth']
export const approvalValue = (approval: ApprovalStep | 'nonapproval') => {
  switch (approval) {
    case 'first':
      return 1
    case 'second':
      return 2
    case 'third':
      return 3
    case 'fourth':
      return 4
    case 'fifth':
      return 5
    default:
      return 0
  }
}
type ApprovalStep = 'first' | 'second' | 'third' | 'fourth' | 'fifth'
type CheckItems = {
  [key in ApprovalStep]: boolean
}
// 承認段階設定の連想配列を初期化
const defaultCheckItems = (conditions: ApprovalCondition[] | undefined): CheckItems => {
  return Object.assign(
    {},
    ...APPROVALS.map((key) =>
      // 全ての段階を示す定数に、もともとの設定が含まれているかどうかをvalueに指定
      ({ [key]: (conditions || []).some((c) => c.approval === key) }),
    ),
  )
}

type ApprovalSettingFormDialogProps = {
  modalState: ModalState
  onComplete: () => void
  approvalSetting?: ApprovalSetting
  users?: User[]
}
export const ApprovalSettingFormDialog: React.FC<ApprovalSettingFormDialogProps> = (props) => {
  const globalState = useContext(GlobalStateContext)
  const settingForm = useForm<ApprovalSettingForm>({
    selfApproval: true,
    ...props.approvalSetting,
    conditionsAttributes: [],
    approversAttributes: [],
    setCondAttr: [
      {
        id: (props.approvalSetting?.conditions || []).find((c) => c.approval === 'nonapproval')?.id,
        approval: 'nonapproval',
        nolimit: (props.approvalSetting?.conditions || []).find((c) => c.approval === 'nonapproval')?.nolimit || false,
        maximumAmount: (props.approvalSetting?.conditions || []).find((c) => c.approval === 'nonapproval')?.maximumAmount,
        _destroy: false,
      },
      ...APPROVALS.map((a) => ({
        id: (props.approvalSetting?.conditions || []).find((c) => c.approval === a)?.id,
        approval: a,
        nolimit: (props.approvalSetting?.conditions || []).find((c) => c.approval === a)?.nolimit || false,
        maximumAmount:
          (props.approvalSetting?.conditions || []).find((c) => c.approval === a)?.nolimit || false
            ? undefined
            : (props.approvalSetting?.conditions || []).find((c) => c.approval === a)?.maximumAmount,
        _destroy: false,
      })),
    ],
    setApprAttr: (props.users || []).map((u) => ({
      id: (props.approvalSetting?.approvers || []).find((a) => a.userId === u.id)?.id,
      userId: u.id,
      approval: (props.approvalSetting?.approvers || []).find((a) => a.userId === u.id)?.approval,
      _destroy: false,
    })),
    isSet: false,
  })

  const postApi = usePostApprovalSettingApi()
  const patchApi = usePatchApprovalSettingApi()

  const [checkItems, setCheckItems] = useState<CheckItems>(defaultCheckItems(props.approvalSetting?.conditions))
  // 最大の承認段階(数字)
  const [maxStep, setMaxStep] = useState(0)
  const [stepperIndex, setStepperIndex] = useState(0)

  const goNextStep = () => {
    setStepperIndex((prevIndex) => prevIndex + 1)
  }
  const goPrevStep = () => {
    setStepperIndex((prevIndex) => prevIndex - 1)
  }

  const handleSubmitStep = () => {
    if (!Object.values(checkItems).some((bool) => bool)) {
      globalState.setNotificationMessage({ body: '段階を選択してください', colorType: 'error' })
      return
    }
    settingForm.update((f) => {
      (Object.keys(checkItems) as (keyof CheckItems)[]).forEach((k: ApprovalStep) => {
        f.setCondAttr.find((c) => c.approval === k)!._destroy = !checkItems[k]
      })
    })
    goNextStep()
  }
  const handleSubmitConditions = () => {
    const condAttrs = settingForm.object.setCondAttr.filter((cond) => !cond._destroy)
    const condAmounts = condAttrs.map((cond) => Number(cond.maximumAmount))
    const nolimit = condAttrs[condAttrs.length - 1].nolimit
    // 入力チェック
    const nanIdx = condAmounts.findIndex((cond) => Number.isNaN(cond))
    if (nanIdx >= 0 && !(nanIdx === condAmounts.length - 1 && nolimit)) {
      globalState.setNotificationMessage({ body: '全ての段階の上限額を入力してください', colorType: 'error' })
      return
    }
    // 0以上で入力
    if (condAmounts.some((cond) => cond < 0)) {
      globalState.setNotificationMessage({ body: '上限額は0以上で入力してください', colorType: 'error' })
      return
    }
    // 承認作業中で上限額を下げるのは不可
    if (
      props.approvalSetting?.proceeding &&
      condAttrs.some(
        (scond) => (props.approvalSetting?.conditions?.find((cond) => cond.id === scond.id)?.maximumAmount || 0) > scond.maximumAmount!,
      )
    ) {
      globalState.setNotificationMessage({ body: '承認待ちの申請があるため上限額を下げられません', colorType: 'error' })
      return
    }
    // 段階順に設定されているか
    const unsortIdx = condAmounts.findIndex((cond, i, arr) => cond >= arr[i + 1])
    if (unsortIdx >= 0 && !(unsortIdx === condAmounts.length - 1 && nolimit)) {
      globalState.setNotificationMessage({ body: '上限額を段階順にご入力ください', colorType: 'error' })
      return
    }
    settingForm.update((f) => {
      f.setApprAttr.filter((c) => approvalValue(c.approval || 'fifth') > maxStep).forEach((c) => (c.approval = undefined))
    })
    goNextStep()
  }
  const handleSubmitApprovers = () => {
    settingForm.update((f) => {
      // IDがundefinedのものはidプロパティごと削除
      const conditions: ApprovalCondition[] = f.setCondAttr
        .map((c) => {
          if (!c.id) {
            delete c.id
          }
          if (c.nolimit && approvalValue(c.approval!) !== maxStep) {
            c.nolimit = false
          }
          return c
        })
        .filter((c) => c.approval)
      const approvers: Approver[] = f.setApprAttr
        .map((a) => {
          if (!a.id) {
            delete a.id
          }
          if (!a.approval) {
            a._destroy = true
          } else {
            a._destroy = false
          }
          return a
        })
        .filter((a) => a.approval || a._destroy)
      f.conditionsAttributes = conditions
      f.approversAttributes = approvers
      f.isSet = true
    })
  }

  useEffect(() => {
    settingForm.resetForm()
    setCheckItems(defaultCheckItems(props.approvalSetting?.conditions))
  }, [props.approvalSetting, props.users])

  useEffectSkipFirst(() => {
    if (!settingForm.object.isSet) return
    if (props.approvalSetting && Object.keys(props.approvalSetting).length > 0) {
      patchApi.execute(settingForm.object)
    } else {
      postApi.execute(settingForm)
    }
  }, [settingForm.object.isSet])

  useEffectSkipFirst(() => {
    const api = props.approvalSetting && Object.keys(props.approvalSetting).length > 0 ? patchApi : postApi
    if (!api.isSuccess()) {
      settingForm.newUpdateObject('isSet', false)
      return
    }
    settingForm.resetForm()
    setStepperIndex(0)
    props.onComplete()
  }, [postApi.loading, patchApi.loading])

  const steps: ModalStep[] = [
    {
      label: '承認段階の設定',
      body: (
        <ApprovalStepSettingForm
          proceeding={props.approvalSetting?.proceeding || false}
          checkItems={checkItems}
          maxStep={maxStep}
          setCheckItems={setCheckItems}
          setMaxStep={setMaxStep}
        />
      ),
      onClickNext: handleSubmitStep,
    },
    {
      label: '承認条件の設定',
      body: <ApprovalConditionSettingForm settingForm={settingForm} checkItems={checkItems} maxStep={maxStep} />,
      allowBack: true,
      onClickBack: goPrevStep,
      onClickNext: handleSubmitConditions,
    },
    {
      label: '承認者の設定',
      body: <ApprovalApproverSettingForm settingForm={settingForm} maxStep={maxStep} users={props.users} />,
      allowBack: true,
      onClickBack: goPrevStep,
      onClickNext: handleSubmitApprovers,
    },
  ]
  return <StepperModal modalState={props.modalState} steps={steps} size="lg" title="承認設定" changeIndexManually index={stepperIndex} />
}

export default ApprovalSettingFormDialog

type ApprovalStepSettingFormProps = {
  proceeding: boolean
  checkItems: CheckItems
  maxStep: number
  setCheckItems: React.Dispatch<React.SetStateAction<CheckItems>>
  setMaxStep: React.Dispatch<React.SetStateAction<number>>
}
const ApprovalStepSettingForm: React.FC<ApprovalStepSettingFormProps> = (props) => {
  const globalState = useContext(GlobalStateContext)
  const { proceeding, checkItems, maxStep, setCheckItems, setMaxStep } = props

  useEffect(() => {
    setMaxStep(0)
    // 最後にチェックが入っているものが最大の承認段階
    let key: ApprovalStep
    for (key in checkItems) {
      if (checkItems[key]) {
        setMaxStep(approvalValue(key))
      }
    }
  }, [checkItems])

  const handleChecks = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (proceeding) {
      globalState.setNotificationMessage({ body: '未承認の発注があるため、段階の追加削除ができません', colorType: 'error' })
      return
    }
    setCheckItems({ ...checkItems, [event.target.value]: event.target.checked })
  }

  return (
    <div style={{ padding: 16 }}>
      <p style={{ marginBottom: 12 }}>
        承認段階を以下から選択します。
        <br />
        チェックを入れた最大の承認段階が最終の承認段階となります。
        <br />
      </p>
      <p style={{ marginBottom: 8 }}>例）1段階と3段階をチェックした場合</p>
      <p>
        最大3段階承認となり、1段階と3段階の発注上限金額を次の画面で設定します。
        <br />
        この場合、2段階承認は発注上限の金額を設定しないため、
        <br />
        2段階承認後は必ず3段階承認へ進みます。
      </p>
      <Flex justifyContent="space-around" style={{ margin: '16px 0' }}>
        {/* Object.keys の返り値はstring[]であるため、CheckItemsのキー配列であることを明示 */}
        {(Object.keys(checkItems) as (keyof CheckItems)[]).map((approval: ApprovalStep) => (
          <label key={approval}>
            <input type="checkbox" checked={checkItems[approval]} value={approval} onChange={handleChecks} />
            <span style={{ paddingLeft: 6 }}>{approvalValue(approval)}段階承認を設定</span>
          </label>
        ))}
      </Flex>
      <div>
        <ul style={{ display: 'flex', width: '100%', listStyle: 'none' }}>
          {(Object.keys(checkItems) as (keyof CheckItems)[]).map((key: ApprovalStep) => {
            // 最大の承認段階以下のもののみ表示する
            const step = approvalValue(key)
            const visible = step <= maxStep
            const width = `${100 / maxStep}%`
            const text = <label>{checkItems[key] ? '上限金額を設定' : `承認後 ${step + 1}次承認へ`}</label>
            return <ApprovalChart key={key} visible={visible} step={step} width={width} text={text} />
          })}
        </ul>
      </div>
    </div>
  )
}

type ApprovalConditionSettingFormProps = {
  settingForm: Form<ApprovalSettingForm>
  checkItems: CheckItems
  maxStep: number
}
const ApprovalConditionSettingForm: React.FC<ApprovalConditionSettingFormProps> = (props) => {
  const { settingForm, checkItems, maxStep } = props
  return (
    <div style={{ padding: 16 }}>
      <p>次に段階ごとの承認条件を設定します。</p>
      <div style={{ margin: '16px 0' }}>
        {/* 段階を大きい順に入力欄作成 */}
        {(Object.keys(checkItems) as (keyof CheckItems)[]).reverse().map((key: ApprovalStep, i) => {
          // 最大の承認段階以下のもののみ表示する
          const step = approvalValue(key)
          const visible = checkItems[key]
          const isMax = approvalValue(key) === maxStep
          const idx = settingForm.object.setCondAttr.findIndex((c) => c.approval === key)
          return (
            <div style={{ display: visible ? 'flex' : 'none', margin: '12px 0' }} key={i}>
              <div style={{ width: '40%' }}>
                <label>{step}段階承認 上限額(税込)</label>
              </div>
              <div style={{ width: '50%' }}>
                <NumberField
                  form={settingForm}
                  attr={['setCondAttr', idx, 'maximumAmount']}
                  placeholder="発注可能な上限額"
                  disabled={isMax && settingForm.object.setCondAttr[idx].nolimit}
                />
                {isMax && <CheckBoxField label="上限額を設定しない" form={settingForm} attr={['setCondAttr', idx, 'nolimit']} />}
              </div>
            </div>
          )
        })}
        <Flex style={{ margin: '12px 0' }}>
          <div style={{ width: '40%', margin: 0 }}>
            <label>承認不要で発注可能な額(税込)</label>
          </div>
          <div style={{ width: '50%' }}>
            <NumberField
              form={settingForm}
              attr={['setCondAttr', settingForm.object.setCondAttr.findIndex((c) => c.approval === 'nonapproval'), 'maximumAmount']}
            />
            <p>単価の安い製品であっても必ず承認を必要とする場合、0を設定</p>
          </div>
        </Flex>
        <Flex style={{ margin: '12px 0' }}>
          <div style={{ width: '40%', margin: 0 }}>
            <label>承認者による発注</label>
          </div>
          <div style={{ width: '50%' }}>
            <RadioButtonField label="承認をパスする" value={1} form={settingForm} attr="selfApproval" name="select-self-approval" />
            <RadioButtonField label="他の承認者の承認が必要" value={0} form={settingForm} attr="selfApproval" name="select-self-approval" />
          </div>
        </Flex>
      </div>
    </div>
  )
}

type ApprovalApproverSettingFormProps = {
  settingForm: Form<ApprovalSettingForm>
  maxStep: number
  users?: User[]
}
const ApprovalApproverSettingForm: React.FC<ApprovalApproverSettingFormProps> = (props) => {
  const { settingForm, maxStep, users } = props
  const approvals = APPROVALS.filter((a) => approvalValue(a) <= maxStep)
  return (
    <div style={{ padding: 16 }}>
      <p>最後に各段階の承認者を割り振ります。</p>
      <Flex flexDirection="column" alignItems="flex-end">
        <Flex style={{ width: '100%', marginTop: 12 }}>
          <ul style={{ display: 'flex', width: '100%', listStyle: 'none' }}>
            {APPROVALS.map((approval) => {
              const cond = settingForm.object.setCondAttr.filter((c) => !c._destroy).find((c) => c.approval === approval)
              // 最大の承認段階以下のもののみ表示する
              const step = approvalValue(approval)
              const visible = step <= maxStep
              const width = `${100 / maxStep}%`
              const text = cond ? (
                <>
                  {cond.nolimit && !cond.maximumAmount ? (
                    <label>上限額なし</label>
                  ) : (
                    <>
                      <label>¥ {cond.maximumAmount?.toLocaleString()}</label>
                      <span>まで発注可能</span>
                    </>
                  )}
                </>
              ) : (
                ''
              )
              return <ApprovalChart key={`check-${approval}`} visible={visible} step={step} width={width} text={text} />
            })}
          </ul>
        </Flex>
        <Flex style={{ width: '100%', flexWrap: 'wrap', justifyContent: 'flex-end' }}>
          {(users || []).map((u, i) => {
            const idx = settingForm.object.setApprAttr.findIndex((a) => a.userId === u.id)
            return (
              <Flex alignItems="center" style={{ margin: '6px 0', width: '50%' }} key={i}>
                <div style={{ width: '45%', textAlign: 'end', fontWeight: 'bold' }}>{`${u.familyName} ${u.givenName}`}</div>
                <div style={{ width: '55%' }}>
                  <SelectField
                    label="承認段階を選択"
                    form={settingForm}
                    attr={['setApprAttr', idx, 'approval']}
                    data={[...approvals.map((a) => ({ label: `${approvalValue(a)}次承認者`, value: a }))]}
                    labelId={`approvers-${idx}`}
                    includeBlank
                    blankText="承認者ではない"
                  />
                </div>
              </Flex>
            )
          })}
        </Flex>
      </Flex>
    </div>
  )
}
