import { BaseEntity, ID } from 'entities'
import { AttachmentAttributes } from 'entities/attachment_attributes'
import { DealerUser } from 'entities/dealer_user'
import { DefectReportAttachment } from 'entities/defect_report_attachment'
import { ExAttribute } from 'entities/ex_attribute'
import { Instrument } from 'entities/instrument'
import { ProofreadEvent } from 'entities/proofread_event'
import { RepairEstimate, RepairEstimateForm } from 'entities/repair_estimate'
import { RepairEvent } from 'entities/repair_event'
import { User } from 'entities/user'
import { SearchQuery } from 'rac'

export type DefectReport = BaseEntity & {
  reporterableId?: ID
  reporterableType?: 'User' | 'DealerUser'
  instrumentId?: ID
  repairInfoId?: ID
  proofreadEventId?: ID
  point?: string
  content?: string
  reportedAt?: string
  reportedUserName?: string
  tableExAttrs?: ExAttribute[]

  fileAttachments?: DefectReportAttachment[]
  instrument?: Instrument
  repairEvent?: RepairEvent
  repairEstimate?: RepairEstimate
  proofreadEvent?: ProofreadEvent
  sharedReport?: boolean
  reporterable?: User | DealerUser
}

export type DefectReportForm = DefectReport & {
  attachmentsAttributes?: AttachmentAttributes[]
  repairEstimateAttributes?: RepairEstimateForm
}

export type DefectReportSearchForm = {
  searchText?: string
}

export const getDefectReportSearchQuery = (searchText?: string): SearchQuery => ({
  suffix: 'Cont',
  searchText: searchText || '',
  attrs: [
    'point',
    'content',

    // FIXME: multiDBの不具合暫定対応
    'reportedUserName', // reported_user_nameカラムで検索する
    // ['reporterable', { attrs: ['familyName', 'givenName'], polymorphicType: 'User' }],
    // ['reporterable', { attrs: ['name'], polymorphicType: 'DealerUser' }],
  ],
})

export type ReportWithEstimateForm = {
  defectReport?: DefectReportForm
  repairEstimate?: RepairEstimateForm
  proofreadEventId?: number
}
