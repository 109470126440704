import { Dealer } from 'entities/dealer'
import { OutsideDealer } from 'entities/outside_dealer'

/**
 * 業者
 */
export type Supplier = Dealer | OutsideDealer

export const partnerLabels = [
  { value: 'Dealer', label: 'パートナー' },
  { value: 'OutsideDealer', label: '外部業者' },
]
