import { AxiosHttpClient } from 'api/axios'
import { useRepruaPatchApi, useRepruaShowApi } from 'api/shared_api_hooks'
import { UserMailNotificationSetting, UserMailNotificationSettingForm } from 'entities/user_mail_notification_setting'
import { ApiSet, BaseResponse } from 'rac'

type UserMailNotificationSettingResponse = BaseResponse & {
  userMailNotificationSetting: UserMailNotificationSetting
}

/**
 * 取得
 */
export function useFetchUserMailNotificationSettingApi(): ApiSet<UserMailNotificationSettingResponse> & { execute: () => void } {
  const api = useRepruaShowApi<UserMailNotificationSettingResponse, {}>(new AxiosHttpClient('user'), {
    initialResponse: { userMailNotificationSetting: {} },
  })

  const execute = () => {
    const path = 'user_mail_notification_setting'
    api.execute(path)
  }

  return { ...api, execute }
}

/**
 * 更新
 */
export function usePatchUserMailNotificationSettingApi(): ApiSet<UserMailNotificationSettingResponse> & {
  execute: (userMailNotificationSettingForm: UserMailNotificationSettingForm) => void
} {
  const api = useRepruaPatchApi<UserMailNotificationSettingResponse, UserMailNotificationSettingForm>(new AxiosHttpClient('user'), {
    initialResponse: { userMailNotificationSetting: {} },
  })

  const execute = (userMailNotificationSettingForm: UserMailNotificationSettingForm) => {
    const path = 'user_mail_notification_setting'
    api.execute(path, userMailNotificationSettingForm)
  }

  return { ...api, execute }
}
