import { Table, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'
import { usePartnerBulkDeleteProofreadEventApi } from 'api/partner/proofread_event'
import { PartnerFetchProofreadEventsApi } from 'api/partner/proofread_plans/proofread_event'
import NoPlanEventFormBulkUpdateDialog from 'components/partner/proofread/proofread_event/no_plan_event_form_bulk_update_dialog'
import NoPlanEventFormDialog from 'components/partner/proofread/proofread_event/no_plan_event_form_dialog'
import { CheckboxFooter } from 'components/shared/checkbox_footer'
import ImageView from 'components/shared/image_view'
import { useMultipleModal } from 'components/shared/modal'
import Pagination from 'components/shared/pagination'
import { InstrumentCategoryFilterTableHeader } from 'components/shared/proofread/instrument_category_filter_table_header'
import { InstrumentInfoFilterTableHeader } from 'components/shared/proofread/instrument_info_filter_table_header'
import { ExTableCell, ExTableBody, ExTableRow, AllCheckboxTableCell, CheckboxTableCell } from 'components/shared/table'
import { TableExAttributesInfo } from 'components/shared/table_ex_attributes_info'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { ExColumn } from 'entities/ex_column'
import { ProofreadEvent, ProofreadEventForm, ProofreadEventSearchForm } from 'entities/proofread_event'
import { User } from 'entities/user'
import { Form, useForm } from 'rac'
import React, { useContext, useEffect } from 'react'
import { getTableHeight, PROOFREAD_CHECKED_TABLE_PADDING, PROOFREAD_TABLE_PADDING } from 'utils/component_heights'
import { useCheckboxes } from 'utils/hooks'
import { useProofreadEventFilter } from 'utils/hooks/use_proofread_event_filter'
import { ProofreadEventViewModel } from 'view_models/proofread_event'

type NoPlanEventTableProps = {
  planId: number
  indexApi: PartnerFetchProofreadEventsApi
  searchForm: Form<ProofreadEventSearchForm>
  tableShowColumns: ExColumn[]
  onUpdate: () => void
  onClickHistory: (event: ProofreadEvent) => void
  onClickExAttr: (event: ProofreadEvent) => void
  onClickBulkExAttr: (eventIds: number[]) => void
  isPlanForLaboratory: boolean // リプルア顧客向けかどうか
  users?: User[] // 校正担当として設定できるラボユーザー
}
export const NoPlanEventTable = (props: NoPlanEventTableProps) => {
  const proofreadEventFilterSet = useProofreadEventFilter(props.indexApi.response.filter, props.searchForm, props.indexApi)
  const checkboxSet = useCheckboxes(props.indexApi.response.proofreadEvents)
  const globalState = useContext(GlobalStateContext)
  const settingModalSet = useMultipleModal<ProofreadEvent>()
  const bulkSettingModalSet = useMultipleModal<number[]>()

  const bulkDeleteForm = useForm<ProofreadEventForm>({ eventIds: checkboxSet.checkedIds, proofreadPlanId: props.planId })
  const bulkDeleteApi = usePartnerBulkDeleteProofreadEventApi(bulkDeleteForm)

  const handleClickSetting = (event: ProofreadEvent) => {
    settingModalSet.openModal(event)
  }

  const handleCompleteSetting = () => {
    settingModalSet.closeModal()
    props.onUpdate()
  }

  const handleCompleteBulkSetting = () => {
    bulkSettingModalSet.closeModal()
    props.onUpdate()
  }

  const onClickBulkDelete = () => {
    globalState.confirm('校正計画から外します。よろしいですか？', (e) => {
      if (e === 'ok') {
        bulkDeleteApi.execute()
      }
    })
  }

  // 一括設定ボタン押下時の処理
  const handleClickBulkSettingButton = () => {
    bulkSettingModalSet.openModal(checkboxSet.checkedIds)
  }

  useEffect(() => {
    bulkDeleteForm.resetForm()
  }, [checkboxSet.checkedIds, props.planId])

  useEffect(() => {
    globalState.setLoading(bulkDeleteApi.loading)
    if (bulkDeleteApi.isSuccess()) {
      props.onUpdate()
    }
  }, [bulkDeleteApi.loading])

  const getDisableTooltipText = (mayPlanned?: boolean) => (mayPlanned ? '' : '当機器の他の校正計画が進行中のため、計画できません')

  return (
    <>
      <NoPlanEventFormDialog
        isPlanForLaboratory={props.isPlanForLaboratory}
        modalSet={settingModalSet}
        onComplete={handleCompleteSetting}
        onClickHistory={props.onClickHistory}
        users={props.users}
        />
      <NoPlanEventFormBulkUpdateDialog
        modalSet={bulkSettingModalSet}
        onComplete={handleCompleteBulkSetting}
        proofreadPlanId={props.planId}
        isPlanForLaboratory={props.isPlanForLaboratory}
        users={props.users}
      />
      <TableContainer
        style={{
          maxHeight:
            checkboxSet.checkedIds.length > 0 ? getTableHeight(PROOFREAD_CHECKED_TABLE_PADDING) : getTableHeight(PROOFREAD_TABLE_PADDING),
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <AllCheckboxTableCell checkboxSet={checkboxSet} />
              <ExTableCell />
              <ExTableCell>
                <InstrumentInfoFilterTableHeader proofreadEventFilterSet={proofreadEventFilterSet} />
                {props.tableShowColumns.map((col) => (
                  <div style={{ fontSize: 12, lineHeight: '12px' }} key={`tablecol-${col.id}`}>
                    {col.name}
                  </div>
                ))}
              </ExTableCell>
              <ExTableCell align="center">
                <InstrumentCategoryFilterTableHeader proofreadEventFilterSet={proofreadEventFilterSet} />
              </ExTableCell>
              <ExTableCell align="center">最終校正日</ExTableCell>
              <ExTableCell align="center">有効期限</ExTableCell>
              <ExTableCell align="center">校正周期</ExTableCell>
              <ExTableCell />
              <ExTableCell />
            </TableRow>
          </TableHead>
          <ExTableBody loading={props.indexApi.loading}>
            {props.indexApi.response.proofreadEvents.map((event: ProofreadEvent) => {
              const vm = new ProofreadEventViewModel(event)
              return (
                <ExTableRow key={event.id}>
                  <CheckboxTableCell checkboxId={event.id!} checkboxesSet={checkboxSet} />
                  <ExTableCell width={100}>
                    <ImageView maxHeight={100} maxWidth={100} src={vm.getInstrumentImage()} />
                  </ExTableCell>
                  <ExTableCell>
                    <div>{vm.getInstrumentInfo()}</div>
                    <TableExAttributesInfo tableExAttrs={event.tableExAttrs || []} id={event.id} />
                  </ExTableCell>
                  <ExTableCell align="center">{vm.getInstrumentCategoryName()}</ExTableCell>
                  <ExTableCell align="center">{vm.getLastResultDate()}</ExTableCell>
                  <ExTableCell align="center">{vm.getExpiredDate()}</ExTableCell>
                  <ExTableCell align="center">{vm.getCycle()}</ExTableCell>
                  <ExTableCell width={122}>
                    <ThemedButton color="success" onClick={() => props.onClickExAttr(event)}>
                      管理情報
                    </ThemedButton>
                  </ExTableCell>
                  <ExTableCell>
                    <Tooltip title={getDisableTooltipText(event.mayPlanned)}>
                      <div>
                        <ThemedButton color="primary" onClick={() => handleClickSetting(event)} disabled={!event.mayPlanned}>
                          設定
                        </ThemedButton>
                      </div>
                    </Tooltip>
                  </ExTableCell>
                </ExTableRow>
              )
            })}
          </ExTableBody>
        </Table>
      </TableContainer>
      <Pagination response={props.indexApi.response} pageSet={props.indexApi.pageSet} />
      <CheckboxFooter
        checkboxSet={checkboxSet}
        content={
          <>
            <ThemedButton color="primary" onClick={handleClickBulkSettingButton} style={{ marginRight: 12 }}>
              一括設定
            </ThemedButton>
            <ThemedButton color="error" onClick={onClickBulkDelete} style={{ marginRight: 12 }}>
              計画から外す
            </ThemedButton>
            <ThemedButton color="success" onClick={() => props.onClickBulkExAttr(checkboxSet.checkedIds)} style={{ marginRight: 12 }}>
              校正管理情報を一括登録
            </ThemedButton>
          </>
        }
      />
    </>
  )
}

export default NoPlanEventTable
