import { CancelPolicy } from 'entities/cancel_policy'
import { Instrument } from 'entities/instrument'
import { Laboratory } from 'entities/laboratory'
import { Reservable, Reservation } from 'entities/reservation'
import moment, { Moment } from 'moment'
import { ViewModel } from 'view_models'

export class ReservationViewModel extends ViewModel<Reservation> {
  /**
   * 予約対象（reservable）が機器かどうかを判定する型ガード関数
   * @param reservable 予約対象
   * @returns boolean
   */
  public isReservableInstrument(reservable: Reservable): reservable is Instrument {
    return this.object.reservableType === 'Instrument'
  }

  /**
   * 開始日時
   */
  public startAt(): Moment {
    return moment(this.object.startAt)
  }

  /**
   * 終了日時
   */
  public endAt(): Moment {
    return moment(this.object.endAt)
  }

  /**
   * 予約対象の名前
   * @returns string 予約対象の名前
   */
  public reservableName(): string {
    return this.object.reservableName || ''
  }

  public getStartAtText(): string {
    return this.startAt().format('Y/MM/DD HH:mm')
  }

  public getEndAtText(): string {
    return this.endAt().format('Y/MM/DD HH:mm')
  }

  public getKindText(): string {
    if (this.object.kind === 'use') {
      return '利用'
    } else if (this.object.kind === 'proofread') {
      return '校正'
    } else {
      return '修理'
    }
  }

  public getMyCalendarTitle(): string {
    return `${this.getKindText()} ${this.reservableName()}`
  }

  public isOtherLaboratoryReservation(currentLaboratory?: Laboratory): boolean {
    if (currentLaboratory === undefined) {
      return false
    } else {
      return currentLaboratory.id !== this.object.laboratoryId
    }
  }

  public getLaboratoryCalendarTitle(): string {
    return `${this.getKindText()} ${this.reservableName()} ${this.object.reservedUserName}`
  }

  public getManagerCalendarTitle(): string {
    return `${this.getKindText()} ${this.reservableName()} ${this.object.laboratory?.name}`
  }

  public getCalendarTitle(currentLaboratory?: Laboratory): string {
    if (!currentLaboratory) return ''

    const textArray = [this.getKindText(), this.reservableName()]
    if (this.isOtherLaboratoryReservation(currentLaboratory)) {
      // 自社の予約でない場合
      if (currentLaboratory.isManager) {
        // 管理者は他社のラボ名と予約者名を閲覧できる
        textArray.push(this.object.laboratory?.name || '')
        textArray.push(this.object.reservedUserName || '')
      } else {
        textArray.push('別組織の予約')
      }
    } else {
      // 自社の予約の場合、予約したユーザの名前を表示
      textArray.push(this.object.reservedUserName || '')
    }
    return textArray.join('')
  }

  /**
   * 共有機器のディーラー向けイベントタイトルを取得する
   */
  public getEventTitleForDealer() {
    return [this.getKindText(), this.reservableName()].join('')
  }

  public getEventColor(): string {
    if (this.object.reservableType === 'Room') {
      return '#9c27b0'
    } else if (this.object.kind === 'use') {
      return '#1976d2'
    } else if (this.object.kind === 'proofread') {
      return '#00796b'
    } else {
      return '#d32f2f'
    }
  }

  public isStartedReservation(): boolean {
    return this.startAt() <= moment()
  }

  public isFinishedReservation(): boolean {
    return this.endAt() < moment()
  }

  public fixDeadline = (deadline: number): string => {
    if (deadline >= 24) {
      return `${deadline / 24} 日`
    } else {
      return `${deadline} 時間`
    }
  }

  public isOverDeadline = (cancelPolicy?: CancelPolicy): boolean => {
    if (!cancelPolicy) {
      return false
    }
    const now = moment()
    const dateAndTime = moment(this.object.startAt)
    const hoursToDeadline = dateAndTime.diff(now, 'minutes') / 60.0

    if (cancelPolicy && hoursToDeadline < cancelPolicy.deadline) {
      return true
    } else {
      return false
    }
  }
}
