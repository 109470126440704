import { AxiosHttpClient } from 'api/axios'
import {
  useRepruaIndexApi,
  useRepruaPostApi,
  useRepruaPatchApi,
  useRepruaDeleteApi,
  useRepruaShowApi,
  useRepruaDownloadApi,
} from 'api/shared_api_hooks'
import { ID, PagingResponse } from 'entities'
import { Instrument } from 'entities/instrument'
import { getRepairEventSearchQuery, RepairEvent, RepairEventForm, RepairEventSearchForm } from 'entities/repair_event'
import { IndexApiSet, ApiSet, Form, BaseResponse } from 'rac'
import { useEffectSkipFirst } from 'utils/hooks'

export type RepairEventsResponse = PagingResponse & {
  repairEvents: RepairEvent[]
  instrument: Instrument
}

/**
 * 一覧
 */
type FetchRepairEventsApiSet = IndexApiSet<RepairEventsResponse> & {
  execute: () => void
  executeAndPageReset: () => void
}
export function useFetchRepairEventsApi(searchForm: Form<RepairEventSearchForm>): FetchRepairEventsApiSet {
  const api = useRepruaIndexApi<RepairEventsResponse, RepairEventSearchForm>(new AxiosHttpClient('user'), {
    initialResponse: { repairEvents: [], totalCount: 0, instrument: {} },
  })

  const { searchText, ...rest } = searchForm.object

  const execute = () => {
    const path = 'repair_events'
    api.execute(path, { params: rest, searchQuery: getRepairEventSearchQuery(searchText) })
  }

  const executeAndPageReset = () => {
    if (api.pageSet.page > 1) {
      api.pageSet.setPage(1)
    } else {
      execute()
    }
  }

  useEffectSkipFirst(() => {
    execute()
  }, [api.pageSet.page, api.pageSet.perPage, api.orderSet.order, api.orderSet.orderBy])

  return { ...api, execute, executeAndPageReset }
}

export type RepairEventResponse = BaseResponse & {
  repairEvent: RepairEvent
}

/**
 * 取得
 */
export function useFetchRepairEventApi(): ApiSet<RepairEventResponse> & { execute: (id: ID) => void } {
  const api = useRepruaShowApi<RepairEventResponse, { id: ID }>(new AxiosHttpClient('user'), { initialResponse: { repairEvent: {} } })

  const execute = (id: ID) => {
    const path = `repair_events/${id}`
    api.execute(path, { id: id })
  }

  return { ...api, execute }
}

/**
 * 作成
 */
export function usePostRepairEventApi(): ApiSet<RepairEventResponse> & { execute: (repairEventForm: Form<RepairEventForm>) => void } {
  const api = useRepruaPostApi<RepairEventResponse, RepairEventForm>(new AxiosHttpClient('user'), {
    initialResponse: { repairEvent: {} },
  })

  const execute = (repairEventForm: Form<RepairEventForm>) => {
    const path = 'repair_events'
    api.execute(path, repairEventForm)
  }

  return { ...api, execute }
}

/**
 * 更新
 */
export function usePatchRepairEventApi(): ApiSet<RepairEventResponse> & { execute: (repairEventForm: RepairEventForm) => void } {
  const api = useRepruaPatchApi<RepairEventResponse, RepairEventForm>(new AxiosHttpClient('user'), {
    initialResponse: { repairEvent: {} },
  })

  const execute = (repairEventForm: RepairEventForm) => {
    const path = `repair_events/${repairEventForm.id}`
    api.execute(path, repairEventForm)
  }

  return { ...api, execute }
}

/**
 * 削除
 */
export function useDeleteRepairEventApi(): ApiSet<RepairEventResponse> & { execute: (id: ID) => void } {
  const api = useRepruaDeleteApi<RepairEventResponse>(new AxiosHttpClient('user'), { initialResponse: { repairEvent: {} } })

  const execute = (id: ID) => {
    const path = `repair_events/${id}`
    api.execute(path)
  }

  return { ...api, execute }
}

/**
 * 出力
 */
export const useExportRepairEventsApi = (searchForm: Form<RepairEventSearchForm>): { execute: (format: 'xlsx') => void } => {
  const api = useRepruaDownloadApi<RepairEventSearchForm>(new AxiosHttpClient('user'))

  const { searchText, ...rest } = searchForm.object

  const execute = (format: 'xlsx') => {
    const path = `repair_events.${format}`
    api.execute(path, { params: rest, searchQuery: getRepairEventSearchQuery(searchText) })
  }

  return { execute }
}
