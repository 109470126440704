import { AxiosHttpClient } from 'api/axios'
import { useRepruaIndexApi, useRepruaPostApi, useRepruaPatchApi, useRepruaDeleteApi } from 'api/shared_api_hooks'
import { ID, PagingResponse } from 'entities'
import { ReservationConstraintSet, ReservationConstraintSetForm } from 'entities/reservation_constraint_set'
import { IndexApiSet, ApiSet, Form, BaseResponse } from 'rac'
import { useEffect } from 'react'

type ReservationConstraintSetsResponse = PagingResponse & {
  reservationConstraintSets: ReservationConstraintSet[]
  totalCount: number
}

/**
 * 一覧
 */
export function useFetchReservationConstraintSetsApi(): IndexApiSet<ReservationConstraintSetsResponse> & { execute: () => void } {
  const api = useRepruaIndexApi<ReservationConstraintSetsResponse, {}>(new AxiosHttpClient('user'), {
    initialResponse: { reservationConstraintSets: [], totalCount: 0 },
  })

  const execute = () => {
    const path = 'reservation_constraint_sets'
    api.execute(path)
  }

  useEffect(() => {
    execute()
  }, [api.pageSet.page, api.pageSet.perPage, api.orderSet.order, api.orderSet.orderBy])

  return { ...api, execute }
}

type ReservationConstraintSetResponse = BaseResponse & {
  reservationConstraintSet: ReservationConstraintSet
}

/**
 * 作成
 */
export function usePostReservationConstraintSetApi(): ApiSet<ReservationConstraintSetResponse> & {
  execute: (reservationConstraintSetForm: Form<ReservationConstraintSetForm>) => void
} {
  const api = useRepruaPostApi<ReservationConstraintSetResponse, ReservationConstraintSetForm>(new AxiosHttpClient('user'), {
    initialResponse: { reservationConstraintSet: {} },
  })

  const execute = (reservationConstraintSetForm: Form<ReservationConstraintSetForm>) => {
    const path = 'reservation_constraint_sets'
    api.execute(path, reservationConstraintSetForm)
  }

  return { ...api, execute }
}

/**
 * 更新
 */
export function usePatchReservationConstraintSetApi(): ApiSet<ReservationConstraintSetResponse> & {
  execute: (reservationConstraintSetForm: ReservationConstraintSetForm) => void
} {
  const api = useRepruaPatchApi<ReservationConstraintSetResponse, ReservationConstraintSetForm>(new AxiosHttpClient('user'), {
    initialResponse: { reservationConstraintSet: {} },
  })

  const execute = (reservationConstraintSetForm: ReservationConstraintSetForm) => {
    const path = `reservation_constraint_sets/${reservationConstraintSetForm.id}`
    api.execute(path, reservationConstraintSetForm)
  }

  return { ...api, execute }
}

/**
 * 削除
 */
export function useDeleteReservationConstraintSetApi(): ApiSet<ReservationConstraintSetResponse> & { execute: (id: ID) => void } {
  const api = useRepruaDeleteApi<ReservationConstraintSetResponse>(new AxiosHttpClient('user'), {
    initialResponse: { reservationConstraintSet: {} },
  })

  const execute = (id: ID) => {
    const path = `reservation_constraint_sets/${id}`
    api.execute(path)
  }

  return { ...api, execute }
}
