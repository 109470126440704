import StatusLabel from 'components/shared/status_label'
import React from 'react'

type DeletedLabelProps = {
  style?: React.CSSProperties
}

export const DeletedLabel = ({ style }: DeletedLabelProps) => {
  return <StatusLabel text="削除済" color="#CD5C5C" style={{ width: 60, ...style }} />
}
