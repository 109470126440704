import { Typography, TypographyProps } from '@mui/material'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

type LinkTextProps = TypographyProps & {
  inline?: boolean
}
const LinkText = (props: LinkTextProps) => {
  const { classes } = useStyles()
  return (
    <Typography className={classes.root} style={{ ...props.style, display: props.inline ? 'inline' : undefined }} {...props}>
      {props.children}
    </Typography>
  )
}

const useStyles = makeStyles()({
  root: {
    color: '#3366ff',
    cursor: 'pointer',
  },
})

export default LinkText
