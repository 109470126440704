import { usePartnerBulkPatchProofreadEventApi } from 'api/partner/proofread_event'
import { Flex } from 'components/shared/flex'
import { MonthField, NumberField, SelectField, StringField } from 'components/shared/form/input'
import Modal, { ModalSet } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { ProofreadEvent, ProofreadEventBulkPatchForm } from 'entities/proofread_event'
import { User } from 'entities/user'
import { useForm } from 'rac'
import React, { useContext } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'

type NoPlanEventFormDialogProps = {
  modalSet: ModalSet<number[]>
  onComplete: (event?: ProofreadEvent) => void
  proofreadPlanId: number
  isPlanForLaboratory: boolean
  users?: User[]
}
export const NoPlanEventFormBulkUpdateDialog: React.FC<NoPlanEventFormDialogProps> = (props) => {
  const globalState = useContext(GlobalStateContext)

  const bulkPatchForm = useForm<ProofreadEventBulkPatchForm>({
    aasmState: props.isPlanForLaboratory ? 'approved_plan' : 'attached_result',
    proofreadEventIds: props.modalSet.item,
  })
  const patchApi = usePartnerBulkPatchProofreadEventApi(bulkPatchForm, props.proofreadPlanId)

  const handleSubmit = () => {
    globalState.confirm('校正イベントを一括設定します。よろしいですか？', (e) => {
      if (e === 'ok') {
        patchApi.execute()
      }
    })
  }

  useEffectSkipFirst(() => {
    bulkPatchForm.resetForm()
  }, [props.modalSet.item])

  useEffectSkipFirst(() => {
    if (patchApi.isSuccess()) {
      props.onComplete(patchApi.response.proofreadEvent)
    }
  }, [patchApi.loading])

  return (
    <Modal
      modalId="event-planning-form"
      modalState={props.modalSet.modalState}
      title="校正計画の一括設定"
      footerStyle={{ display: 'inherit' }}
      footer={
        <Flex alignItems="flex-end" justifyContent="flex-end">
          <ThemedButton color="secondary" onClick={props.modalSet.closeModal}>
            閉じる
          </ThemedButton>
          <ThemedButton color="primary" onClick={handleSubmit}>
            設定
          </ThemedButton>
        </Flex>
      }
    >
      <Flex justifyContent="space-between">
        <MonthField label="校正月" form={bulkPatchForm} attr="scheduledDate" apiError={patchApi.apiError} required />
        <NumberField label="校正価格" form={bulkPatchForm} attr="price" apiError={patchApi.apiError} />
        {props.isPlanForLaboratory && props.users &&
          <SelectField
            label="ラボ側の担当者"
            form={bulkPatchForm}
            attr="userId"
            apiError={patchApi.apiError}
            includeBlank
            data={props.users.map((user) => ({ label: `${user.familyName} ${user.givenName}`, value: user.id }))}
            labelId="user-id"
          />
        }
      </Flex>
      <StringField label="計画メモ" form={bulkPatchForm} attr="noteOnPlan" apiError={patchApi.apiError} multiline />
    </Modal>
  )
}

export default NoPlanEventFormBulkUpdateDialog
