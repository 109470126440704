import { FlexProps, Flex } from 'components/shared/flex'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()({
  formBlock: {
    paddingTop: 20,
  },
})

type FormBlockProps = FlexProps & {}
export const FormBlock: React.FC<FormBlockProps> = (props) => {
  const { classes } = useStyles()
  return (
    <Flex justifyContent="flex-start" className={classes.formBlock}>
      {props.children}
    </Flex>
  )
}

export default FormBlock
