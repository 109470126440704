import { usePatchRepairEstimateApi, usePostRepairEstimateApi } from 'api/mypage/repair_estimate'
import { useDeleteAttachmentsApi } from 'api/shared/attachments'
import AttachmentsList from 'components/shared/data/attachments_list'
import DataLabel from 'components/shared/data/data_label'
import { Flex } from 'components/shared/flex'
import { FilesInput, NumberField, SelectField, StringField } from 'components/shared/form/input'
import Modal, { ModalSet, ModalState } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { ID } from 'entities'
import { Dealer } from 'entities/dealer'
import { FileAttachment } from 'entities/file_attachment'
import { OutsideDealer } from 'entities/outside_dealer'
import { getDeliveryTermSelectData, RepairEstimate, RepairEstimateForm } from 'entities/repair_estimate'
import { useForm } from 'rac'
import React, { useContext, useEffect, useState } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'

type RepairEstimateRequestFormDialogProps = {
  modalState: ModalState
  defectReportId: ID
  dealer?: Dealer
  onComplete: (estimate: RepairEstimate) => void
}
/**
 * 共有機器の修理見積依頼フォーム
 */
export const RepairEstimateRequestFormDialog: React.FC<RepairEstimateRequestFormDialogProps> = (props) => {
  const postForm = useForm<RepairEstimateForm>({
    defectReportId: props.defectReportId,
    suppliableType: 'Dealer',
    suppliableId: props.dealer?.id,
    aasmState: 'estimating',
  })
  const postApi = usePostRepairEstimateApi(postForm)

  useEffectSkipFirst(() => {
    if (!postApi.isSuccess()) return
    props.onComplete(postApi.response.repairEstimate)
  }, [postApi.loading])

  return (
    <Modal
      title="見積依頼の作成"
      modalState={props.modalState}
      modalId="repair-estimate-request-form"
      size="sm"
      footer={
        <>
          <ThemedButton color="secondary" onClick={() => props.modalState.setOpen(false)}>
            閉じる
          </ThemedButton>
          <ThemedButton color="success" onClick={postApi.execute} disabled={!postForm.object.suppliableId}>
            修理見積を依頼
          </ThemedButton>
        </>
      }
    >
      {props.dealer ? <>{props.dealer.name} に修理見積を依頼します</> : <>提携しているディーラーが存在しません</>}
    </Modal>
  )
}

type NewRepairEstimateFormDialogProps = {
  modalState: ModalState
  defectReportId: ID
  onComplete: (estimate: RepairEstimate) => void
  outsideDealers?: OutsideDealer[]
}
export const NewRepairEstimateFormDialog: React.FC<NewRepairEstimateFormDialogProps> = (props) => {
  const postForm = useForm<RepairEstimateForm>(
    {
      defectReportId: props.defectReportId,
      suppliableType: 'Dealer',
      aasmState: 'ordered',
    },
    'repairEstimate',
  )
  const postApi = usePostRepairEstimateApi(postForm)

  const inputProps = {
    form: postForm,
    apiError: postApi.apiError,
  }
  const getTotalText = () => `¥${(postForm.object.total || 0).toLocaleString()}`

  const taxRate = 0.1
  useEffect(() => {
    const newTax = Math.ceil((postForm.object.subtotal || 0) * taxRate)
    const newTotal = (postForm.object.subtotal || 0) + newTax
    postForm.update((f) => {
      f.tax = newTax
      f.total = newTotal
    })
  }, [postForm.object.subtotal])
  useEffect(() => {
    const newTotal = (postForm.object.subtotal || 0) + (postForm.object.tax || 0)
    postForm.update((f) => {
      f.total = newTotal
    })
  }, [postForm.object.tax])
  useEffect(() => {
    if (postForm.object.suppliableId) {
      postForm.newUpdateObject('suppliableType', 'OutsideDealer')
    } else {
      postForm.newUpdateObject('suppliableType', null)
    }
  }, [postForm.object.suppliableId])

  useEffectSkipFirst(() => {
    if (!postApi.isSuccess()) return
    props.onComplete(postApi.response.repairEstimate)
  }, [postApi.loading])

  return (
    <Modal
      title="修理見積の作成"
      modalState={props.modalState}
      modalId="new-repair-estimate-form"
      size="sm"
      footer={
        <>
          <ThemedButton color="secondary" onClick={() => props.modalState.setOpen(false)}>
            閉じる
          </ThemedButton>
          <ThemedButton color="success" onClick={postApi.execute}>
            修理見積を作成
          </ThemedButton>
        </>
      }
    >
      {/* 共有機器の場合は共有パートナーを表示して紐づける。外部業者は選択不可 */}
      {props.outsideDealers && props.outsideDealers.length > 0 && (
        <Flex justifyContent="space-between">
          <SelectField
            labelId="estimate-supplier-id"
            label="依頼先"
            attr="suppliableId"
            {...inputProps}
            data={props.outsideDealers.map((o) => ({ label: o.name!, value: o.id! }))}
            includeBlank
          />
        </Flex>
      )}
      <Flex justifyContent="space-between">
        <NumberField label="修理費用（税別）" attr="subtotal" required {...inputProps} style={{ paddingRight: 20 }} />
        <NumberField label="消費税" attr="tax" required {...inputProps} style={{ paddingRight: 20 }} />
        <DataLabel label="合計額" value={getTotalText()} />
      </Flex>
      <Flex>
        <SelectField
          label="納期"
          labelId="delivery-label"
          attr="deliveryTerm"
          data={getDeliveryTermSelectData()}
          required
          {...inputProps}
          style={{ width: '50%' }}
        />
      </Flex>
      <Flex>
        <StringField label="引き渡し・修理作業の進め方・備考等" attr="note" multiline {...inputProps} />
      </Flex>
      <FilesInput attr="attachmentsAttributes" {...inputProps} keyword="repairEstimate" />
    </Modal>
  )
}

type EditRepairEstimateFormDialogProps = {
  modalSet: ModalSet<RepairEstimate>
  onComplete: (estimate: RepairEstimate) => void
  outsideDealers?: OutsideDealer[]
}
const taxRate = 0.1
export const EditRepairEstimateFormDialog: React.FC<EditRepairEstimateFormDialogProps> = (props) => {
  const patchForm = useForm<RepairEstimateForm>({ ...props.modalSet.item, attachmentsAttributes: [{}] })
  const patchApi = usePatchRepairEstimateApi()
  const deleteFileApi = useDeleteAttachmentsApi('user', 'repair_estimates')
  const [targetFileId, setTargetFileId] = useState<number>()
  const inputProps = {
    form: patchForm,
    apiError: patchApi.apiError,
  }
  const getTotalText = () => `¥${(patchForm.object.total || 0).toLocaleString()}`
  const globalState = useContext(GlobalStateContext)

  const onClickRemoveFile = (a: FileAttachment) => {
    setTargetFileId(a.id)
    globalState.confirm(`添付ファイル ${a.name} を削除しますか？ この操作は取り消せません`, (event) => {
      if (event === 'ok') {
        deleteFileApi.execute(patchForm.object.id!, a.id!)
      }
    })
  }

  useEffectSkipFirst(() => {
    patchForm.resetForm()
  }, [props.modalSet.item.id])

  useEffect(() => {
    const newTax = Math.ceil((patchForm.object.subtotal || 0) * taxRate)
    const newTotal = (patchForm.object.subtotal || 0) + newTax
    patchForm.update((f) => {
      f.tax = newTax
      f.total = newTotal
    })
  }, [patchForm.object.subtotal])
  useEffect(() => {
    const newTotal = (patchForm.object.subtotal || 0) + (patchForm.object.tax || 0)
    patchForm.update((f) => {
      f.total = newTotal
    })
  }, [patchForm.object.tax])
  useEffect(() => {
    if (patchForm.object.suppliableId) {
      patchForm.newUpdateObject('suppliableType', 'OutsideDealer')
    } else {
      patchForm.newUpdateObject('suppliableType', null)
    }
  }, [patchForm.object.suppliableId])

  useEffectSkipFirst(() => {
    globalState.setLoading(deleteFileApi.loading)
    if (deleteFileApi.isSuccess()) {
      const arr = patchForm.getValue('fileAttachments') as FileAttachment[]
      const i = arr.findIndex((a) => a.id === targetFileId)
      arr.splice(i, 1)
      patchForm.newUpdateObject('fileAttachments', arr)
    }
  }, [deleteFileApi.loading])

  useEffectSkipFirst(() => {
    if (!patchApi.isSuccess()) return
    props.onComplete(patchApi.response.repairEstimate)
  }, [patchApi.loading])

  return (
    <Modal
      title="修理見積の編集"
      modalState={props.modalSet.modalState}
      modalId="edit-repair-estimate-form"
      size="sm"
      footer={
        <>
          <ThemedButton color="secondary" onClick={props.modalSet.closeModal}>
            閉じる
          </ThemedButton>
          <ThemedButton color="success" onClick={() => patchApi.execute(patchForm.object)}>
            編集
          </ThemedButton>
        </>
      }
    >
      {/* 共有機器の場合は共有パートナーを表示して紐づける。外部業者は選択不可 */}
      {props.outsideDealers && props.outsideDealers.length > 0 && (
        <Flex justifyContent="space-between">
          <SelectField
            labelId="estimate-supplier-id"
            label="依頼先"
            attr="suppliableId"
            {...inputProps}
            data={props.outsideDealers.map((o) => ({ label: o.name!, value: o.id! }))}
            includeBlank
          />
        </Flex>
      )}
      <Flex justifyContent="space-between">
        <NumberField label="修理費用（税別）" attr="subtotal" required {...inputProps} style={{ paddingRight: 20 }} />
        <NumberField label="消費税" attr="tax" required {...inputProps} style={{ paddingRight: 20 }} />
        <DataLabel label="合計額" value={getTotalText()} />
      </Flex>
      <Flex>
        <SelectField
          label="納期"
          labelId="delivery-label"
          attr="deliveryTerm"
          data={getDeliveryTermSelectData()}
          required
          {...inputProps}
          style={{ width: '50%' }}
        />
      </Flex>
      <Flex>
        <StringField label="引き渡し・修理作業の進め方・備考等" attr="note" multiline {...inputProps} />
      </Flex>
      <AttachmentsList
        fileAttachments={patchForm.object.fileAttachments || []}
        // onClickRemove={onClickRemoveFile}
        emptyText=" "
      />

      <FilesInput attr="attachmentsAttributes" {...inputProps} keyword="repairEstimate" />
    </Modal>
  )
}
