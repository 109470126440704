import { AxiosHttpClient } from 'api/axios'
import { ProofreadEventResponse } from 'api/partner/proofread_plans/proofread_event'
import { useRepruaDeleteApi, useRepruaPatchApi, useRepruaPostApi } from 'api/shared_api_hooks'
import { ProofreadEventBulkPatchForm, ProofreadEventForm } from 'entities/proofread_event'
import { ApiSet, BaseResponse, Form } from 'rac'

/**
 * 設定
 *   スタンドアロン校正の結果添付時に使用
 */
export const usePartnerPatchProofreadEventApi = (
  form: Form<ProofreadEventForm>,
): ApiSet<ProofreadEventResponse> & { execute: () => void } => {
  const api = useRepruaPatchApi<ProofreadEventResponse, ProofreadEventForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { proofreadEvent: {} },
  })

  const execute = () => {
    const path = `proofread_events/${form.object.id}`
    api.execute(path, form.object)
  }

  return { ...api, execute }
}

/**
 * 立案
 */
export const usePartnerPlanProofreadEventApi = (
  form: Form<ProofreadEventForm>,
): ApiSet<ProofreadEventResponse> & { execute: () => void } => {
  const api = useRepruaPatchApi<ProofreadEventResponse, ProofreadEventForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { proofreadEvent: {} },
  })

  const execute = () => {
    const path = `proofread_events/${form.object.id}/plan`
    api.execute(path, form.object)
  }

  return { ...api, execute }
}

/**
 * 一括設定
 *   一括立案時に使用
 */
export const usePartnerBulkPatchProofreadEventApi = (
  form: Form<ProofreadEventBulkPatchForm>,
  proofreadPlanId: number,
): ApiSet<ProofreadEventResponse> & { execute: () => void } => {
  const api = useRepruaPatchApi<ProofreadEventResponse, ProofreadEventForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { proofreadEvent: {} },
  })

  const execute = () => {
    const path = `proofread_events/bulk_plan?proofread_plan_id=${proofreadPlanId}`
    api.execute(path, form.object)
  }

  return { ...api, execute }
}

/**
 * 校正完了
 *   共有機器校正の結果添付時に使用
 */
export const usePartnerCompleteProofreadEventApi = (
  form: Form<ProofreadEventForm>,
): ApiSet<ProofreadEventResponse> & { execute: () => void } => {
  const api = useRepruaPatchApi<ProofreadEventResponse, ProofreadEventForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { proofreadEvent: {} },
  })

  const execute = () => {
    const path = 'proofread_events/complete'
    api.execute(path, form.object)
  }

  return { ...api, execute }
}

/**
 * 削除
 */
export function usePartnerDeleteProofreadEventApi(id: number): ApiSet<ProofreadEventResponse> & { execute: () => void } {
  const api = useRepruaDeleteApi<ProofreadEventResponse>(new AxiosHttpClient('dealer_user'), { initialResponse: { proofreadEvent: {} } })

  const execute = () => {
    const path = `proofread_events/${id}`
    api.execute(path)
  }

  return { ...api, execute }
}

/**
 * 一括作成
 */
export function usePartnerBulkCreateEventsApi(form: Form<ProofreadEventForm>): ApiSet<ProofreadEventResponse> & { execute: () => void } {
  const api = useRepruaPostApi<ProofreadEventResponse, ProofreadEventForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { proofreadEvent: {} },
  })

  const execute = () => {
    const path = 'proofread_events/bulk_create'
    api.execute(path, form)
  }

  return { ...api, execute }
}

/**
 * 一括削除
 */
export function usePartnerBulkDeleteProofreadEventApi(form: Form<ProofreadEventForm>): ApiSet<BaseResponse> & { execute: () => void } {
  // FIXME: racのuseDeleteApi は option params を受け付けていないため、patchで対応している
  const api = useRepruaPatchApi<BaseResponse, ProofreadEventForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: {},
  })

  const execute = () => {
    const path = 'proofread_events/bulk_delete'
    api.execute(path, form.object)
  }

  return { ...api, execute }
}

/**
 * 一括更新
 *   一括で結果添付時に使用
 */
export function usePartnerBulkUpdateProofreadEventApi(form: Form<ProofreadEventForm>): ApiSet<BaseResponse> & { execute: () => void } {
  const api = useRepruaPatchApi<BaseResponse, ProofreadEventForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: {},
  })

  const execute = () => {
    const path = 'proofread_events/bulk_update'
    api.execute(path, form.object)
  }

  return { ...api, execute }
}
