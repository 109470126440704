import { AxiosHttpClient } from 'api/axios'
import { useRepruaIndexApi, useRepruaPostApi, useRepruaDeleteApi } from 'api/shared_api_hooks'
import { ID } from 'entities'
import {
  ReservationConstraintRelation,
  ReservationConstraintRelationForm,
  ReservationConstraintRelationSearchForm,
} from 'entities/reservation_constraint_relation'
import { ReservationConstraintSet } from 'entities/reservation_constraint_set'
import { IndexApiSet, ApiSet, Form, BaseResponse } from 'rac'

type RelatedReservationConstraintSetsResponse = BaseResponse & {
  reservationConstraintRelations: ReservationConstraintRelation[]
  myConstraintSets: ReservationConstraintSet[]
}

/**
 * 一覧
 */
export function useFetchReservationConstraintRelationsApi(
  searchForm: Form<ReservationConstraintRelationSearchForm>,
): IndexApiSet<RelatedReservationConstraintSetsResponse> & { execute: () => void } {
  const api = useRepruaIndexApi<RelatedReservationConstraintSetsResponse, {}>(new AxiosHttpClient('user'), {
    initialResponse: { reservationConstraintRelations: [], myConstraintSets: [] },
  })

  const execute = () => {
    const path = 'reservation_constraint_relations'
    api.execute(path, { params: searchForm.object })
  }

  return { ...api, execute }
}

type ReservationConstraintRelationResponse = BaseResponse & {}

/**
 * 作成
 */
export function usePostReservationConstraintRelationApi(): ApiSet<ReservationConstraintRelationResponse> & {
  execute: (reservationConstraintSetForm: Form<ReservationConstraintRelationForm>) => void
} {
  const api = useRepruaPostApi<ReservationConstraintRelationResponse, ReservationConstraintRelationForm>(new AxiosHttpClient('user'), {
    initialResponse: {},
  })

  const execute = (reservationConstraintSetForm: Form<ReservationConstraintRelationForm>) => {
    const path = 'reservation_constraint_relations'
    api.execute(path, reservationConstraintSetForm)
  }

  return { ...api, execute }
}

/**
 * 削除
 */
export function useDeleteReservationConstraintRelationApi(): ApiSet<ReservationConstraintRelationResponse> & { execute: (id: ID) => void } {
  const api = useRepruaDeleteApi<ReservationConstraintRelationResponse>(new AxiosHttpClient('user'), { initialResponse: {} })

  const execute = (id: ID) => {
    const path = `reservation_constraint_relations/${id}`
    api.execute(path)
  }

  return { ...api, execute }
}
