import { Typography } from '@mui/material'
import DataBlock from 'components/shared/data/data_block'
import DataCard from 'components/shared/data/data_card'
import { Flex } from 'components/shared/flex'
import { Instrument } from 'entities/instrument'
import { ReservationConstraintSet } from 'entities/reservation_constraint_set'
import React, { useEffect, useState } from 'react'
import { InstrumentViewModel } from 'view_models/instrument'
import { ReservationTermViewModel } from 'view_models/reservation_term'

import DataLabel from '../../shared/data/data_label'
import { ConstraintDetailDialog } from '../shared/constraint_detail_dialog'

type InstrumentReservationTermDataCardProps = {
  instrument: Instrument
  myConstraintSets: ReservationConstraintSet[]
}
const InstrumentReservationTermDataCard: React.FC<InstrumentReservationTermDataCardProps> = (props) => {
  const [vm, setVm] = useState(new InstrumentViewModel(props.instrument))
  const [reserveVm, setReserveVm] = useState(new ReservationTermViewModel(props.instrument.reservationTerm || {}))

  useEffect(() => {
    setVm(new InstrumentViewModel(props.instrument))
    setReserveVm(new ReservationTermViewModel(props.instrument.reservationTerm || {}))
  }, [props.instrument])

  return (
    <DataCard>
      <Flex alignItems="center" justifyContent="space-between">
        <Typography variant="h6">予約管理情報</Typography>
        {props.instrument.canReserve && props.myConstraintSets.length > 0 && (
          <ConstraintDetailDialog reservationConstraintSets={props.myConstraintSets} />
        )}
      </Flex>
      {props.instrument.canReserve ? (
        <>
          <DataBlock>
            <DataLabel label="金額" value={reserveVm.getPriceAndUnitText()} width="30%" />
            <DataLabel label="貸出時間" value={reserveVm.getAvalableTimeText()} width="30%" />
            <DataLabel label="予約単位" value={reserveVm.getSectionText()} width="30%" />
          </DataBlock>
          <DataBlock noBorder>
            <DataLabel label="キャンセルポリシー" value={vm.getCancelPolicy()} width="100%" />
          </DataBlock>
          {props.myConstraintSets.length > 0 && (
            <div style={{ marginTop: 10, color: 'red', fontSize: 12 }}>
              ※貸出時間及び金額に関しては予約条件での設定があれば、予約条件の設定に依存します
            </div>
          )}
        </>
      ) : (
        <>予約管理対象外です</>
      )}
    </DataCard>
  )
}

export default InstrumentReservationTermDataCard
