import { Divider, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import { InquiriesIndexApiSet, useFetchInquiriesApi } from 'api/mypage/inquiry'
import { InquiryCommentsView } from 'components/mypage/inquiry/inquiry_comments_view'
import { InquiryFormModal } from 'components/mypage/inquiry/inquiry_form_modal'
import { InquiryStore, InquiryStoreContext, pushInquiries, replaceInquiries } from 'components/mypage/inquiry/inquiry_store'
import { ContentTitle } from 'components/shared/content'
import { Flex } from 'components/shared/flex'
import LoadingProgress from 'components/shared/loading_progress'
import { useModal } from 'components/shared/modal'
import { SearchBar } from 'components/shared/search_bar'
import ThemedButton from 'components/shared/themed_button'
import { Inquiry, InquirySearchForm } from 'entities/inquiry'
import { useForm } from 'rac'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { StaticContext } from 'react-router'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'
import { InquiryViewModel } from 'view_models/inquiry'

type InquiriesPageProps = {
  inquiryId: number | null
}

const InquiriesPage = ({ inquiryId }: InquiriesPageProps) => {
  const [currentInquiryId, setCurrentInquiryId] = useState<number>(0)
  const formModalState = useModal()
  const inquirySearchForm = useForm<InquirySearchForm>({})
  const indexApiSet = useFetchInquiriesApi({ searchForm: inquirySearchForm })
  const store = useContext(InquiryStoreContext)
  const { classes } = useStyles()
  const searchFormRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    indexApiSet.execute()
  }, [])

  useEffect(() => {
    store.dispatch(pushInquiries(indexApiSet.response.inquiries))
    const latestInquiry = indexApiSet.response.inquiries[0]
    if (inquiryId) {
      setCurrentInquiryId(inquiryId)
    } else if (currentInquiryId === 0 && latestInquiry) {
      setCurrentInquiryId(latestInquiry.id!)
    }
  }, [indexApiSet.response])

  // 問合せを選択表示
  const handleSelectInquiry = (id: number) => {
    setCurrentInquiryId(id)
  }

  // 新規問合せ送信後
  const handleCompleteInquiry = (id?: number) => {
    if (id) {
      setCurrentInquiryId(id)
    }
    store.dispatch(replaceInquiries([]))
    indexApiSet.execute()
  }

  const handleSearch = () => {
    store.dispatch(replaceInquiries([]))
    indexApiSet.executeAndResetPage()
  }

  const inboxListProps = {
    indexApiSet,
    currentInquiryId,
    onSelectInquiry: handleSelectInquiry,
    inquiries: store.inquiries,
    searchFormHeight: searchFormRef.current?.clientHeight,
  }

  return (
    <>
      <Flex>
        <div className={classes.inboxPanelRoot}>
          <div ref={searchFormRef}>
            <Flex alignItems="center" justifyContent="space-between" className={classes.inboxPanelHeader}>
              <ContentTitle title="問合せ一覧" />
              <ThemedButton color="primary" onClick={() => formModalState.setOpen(true)}>
                新しい問合せ
              </ThemedButton>
            </Flex>
            <div className={classes.keywordSearch}>
              <SearchBar<InquirySearchForm>
                form={inquirySearchForm}
                searchAttr="searchText"
                placeholder="社名・キーワード等で検索"
                onSubmit={handleSearch}
              />
            </div>
          </div>
          <InboxList {...inboxListProps} />
        </div>
        <InquiryCommentsView currentInquiryId={currentInquiryId} />
      </Flex>

      <InquiryFormModal modalState={formModalState} dealers={indexApiSet.response.dealers} onComplete={handleCompleteInquiry} />
    </>
  )
}

/**
 * 問合せ一覧
 */
type InboxListItemProps = {
  inquiry: Inquiry
  currentInquiryId: number
  onSelectInquiry: (id: number) => void
}

const InboxListItem = ({ inquiry, currentInquiryId, onSelectInquiry }: InboxListItemProps) => {
  const vm = new InquiryViewModel(inquiry)
  const { classes } = useTypographyStyles()

  return (
    <>
      <ListItem key={inquiry.id} onClick={() => onSelectInquiry(inquiry.id!)} button selected={vm.isCurrent(currentInquiryId)}>
        <ListItemAvatar>{vm.getIcon()}</ListItemAvatar>
        <ListItemText>
          <Typography component="div" variant="body2">
            {vm.sentAtText()}
          </Typography>
          <Typography component="div" variant="body2">
            {vm.dealerName()}
          </Typography>
          <Typography component="div" variant="subtitle2" className={classes.subtitle2}>
            {inquiry.subject}
          </Typography>
        </ListItemText>
      </ListItem>
      <Divider />
    </>
  )
}

type InboxListProps = {
  indexApiSet: InquiriesIndexApiSet
  inquiries: Inquiry[]
  currentInquiryId: number
  onSelectInquiry: (id: number) => void
  searchFormHeight?: number
}
const InboxList = ({ indexApiSet, inquiries, currentInquiryId, onSelectInquiry, searchFormHeight }: InboxListProps) => {
  // 以前の問合せを読み込む
  const handlePaginate = () => {
    indexApiSet.pageSet.setPage(indexApiSet.pageSet.page + 1)
  }

  const calcInboxHeight = () => window.innerHeight - headerHeight - (searchFormHeight || 0)

  return (
    <>
      {indexApiSet.loading ? (
        <LoadingProgress />
      ) : (
        <div style={{ height: calcInboxHeight(), overflowY: 'scroll' }}>
          <List>
            {inquiries.length > 0
              ? inquiries.map((inquiry, idx) => (
                  <InboxListItem key={idx} inquiry={inquiry} currentInquiryId={currentInquiryId} onSelectInquiry={onSelectInquiry} />
                ))
              : !indexApiSet.loading && <div style={{ textAlign: 'center', width: '100%', padding: 10 }}>問合せ履歴はありません</div>}
          </List>
          {indexApiSet.response.totalCount !== inquiries.length && (
            <Flex justifyContent="center">
              <ThemedButton color="primary" onClick={handlePaginate} style={{ width: '100%', padding: '10px 0 10px 0', marginBottom: 10 }}>
                以前の問合せを読み込む
              </ThemedButton>
            </Flex>
          )}
        </div>
      )}
    </>
  )
}

const headerHeight = 64

const useStyles = makeStyles()({
  inboxPanelRoot: {
    minWidth: 350,
    marginLeft: '-10px',
    backgroundColor: '#fff',
    borderRight: '1px solid #ddd',
  },
  inboxPanelHeader: {
    padding: 15,
  },
  keywordSearch: {
    padding: 10,
  },
})

const useTypographyStyles = makeStyles()({
  subtitle2: {
    fontWeight: 'bold',
  },
})

export type InquiriesIndexProps = {}

type Props = RouteComponentProps<{ id?: string }, StaticContext, InquiriesIndexProps>

const InquiriesIndex = (props: Props) => {
  const getInquiryId = () => {
    const inquiryId = props.match.params.id
    if (inquiryId) {
      return Number(inquiryId)
    } else {
      return null
    }
  }
  Number(props.match.params.id)
  return (
    <InquiryStore>
      <InquiriesPage inquiryId={getInquiryId()} />
    </InquiryStore>
  )
}

export default withRouter(InquiriesIndex)
