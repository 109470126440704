import { AnyObject } from 'entities'
import qs from 'qs'
import { IHttpClient } from 'rac'
import CookieManager from 'utils/cookie_util'

const axiosBase = require('axios')
const camelCase = require('lodash.camelcase')
const isArray = require('lodash.isarray')
const isObject = require('lodash.isobject')
const mapKeys = require('lodash.mapkeys')
const mapValues = require('lodash.mapvalues')
const snakeCase = require('lodash.snakecase')

const mapKeysDeep = (data: any, callback: (value: string, key: string) => AnyObject) => {
  if (isArray(data)) {
    return data.map((innerData: object) => mapKeysDeep(innerData, callback))
  } else if (isObject(data)) {
    return mapValues(mapKeys(data, callback), (val: any) => mapKeysDeep(val, callback))
  } else {
    return data
  }
}

const mapKeysCamelCase = (data: object) => {
  return mapKeysDeep(data, (_: string, key: string) => camelCase(key))
}

export const mapKeysSnakeCase = (data: object) => {
  return mapKeysDeep(data, (_: string, key: string) => snakeCase(key))
}

export type ResponseType = 'json' | 'blob'
/**
 * axiosは動的に取得する
 * TokenがCookieに無い場合、Tokenにundefinedがセットされた状態で初期化され、
 * ログイン後もそのaxiosが使われるためリロードしないとAuthエラーになりバグるため
 */
export const axios = (type: HttpClientType, responseType?: ResponseType) => {
  let tokenBody
  let basePath
  switch (type) {
    case 'admin':
      tokenBody = CookieManager.getAdminToken()
      basePath = 'admin/'
      break
    case 'user':
      tokenBody = CookieManager.getUserToken()
      basePath = 'mypage/'
      break
    case 'dealer_user':
      tokenBody = CookieManager.getDealerUserToken()
      basePath = 'partner/'
      break
  }

  const base = axiosBase.create({
    baseURL: `${process.env.REACT_APP_API_HOST}/api/v1/${basePath}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${tokenBody}`,
    },
    responseType: responseType ? responseType : 'json',
  })

  base.interceptors.request.use((request: any) => {
    if (request.data instanceof FormData) {
      return request
    }
    if (request.method === 'get') {
      const searchQuery = request.params?.searchQuery
      if (searchQuery) {
        delete request.params.searchQuery
      }
      const convertParams = mapKeysSnakeCase(request.params)
      const q = convertParams?.q

      return { ...request, params: { ...convertParams, q: { ...q, ...searchQuery } } }
    } else {
      const convertedData = mapKeysSnakeCase(request.data)

      return { ...request, data: convertedData }
    }
  })

  if (responseType !== 'blob') {
    base.interceptors.response.use(
      (response: any) => {
        const { data } = response
        const convertedData = mapKeysCamelCase(data)
        return { ...response, data: convertedData }
      },
      (error: any) => {
        const { data } = error.response
        const convertData = mapKeysCamelCase(data)
        error.response.data = convertData
        return Promise.reject(error)
      },
    )
  }
  return base
}

export type HttpClientType = 'user' | 'admin' | 'dealer_user'

export class AxiosHttpClient implements IHttpClient {
  private axios: any
  private type: HttpClientType

  constructor(type: HttpClientType) {
    this.type = type
    this.axios = axios(this.type)
  }

  public get(path: string, params: any) {
    const paramsSerializer = (p: any) => qs.stringify(p, { arrayFormat: 'brackets' })
    return this.axios.get(path, { params: params, paramsSerializer: paramsSerializer })
  }

  public post(path: string, params: any) {
    const formData = objectToFormData(params)
    return this.axios.post(path, formData, {
      headers: { 'content-type': 'multipart/form-data' },
    })
  }

  public patch(path: string, params: any) {
    const formData = objectToFormData(params)
    return this.axios.patch(path, formData, {
      headers: { 'content-type': 'multipart/form-data' },
    })
  }

  public delete(path: string) {
    return this.axios.delete(path)
  }

  public download(path: string, params: any) {
    const downloadAxios = axios(this.type, 'blob')

    const paramsSerializer = (p: any) => qs.stringify(p, { arrayFormat: 'brackets' })
    return downloadAxios
      .get(path, { params: params, paramsSerializer: paramsSerializer })
      .then((response: any) => {
        const blob = response.data

        const nav = window.navigator as any
        if (nav && nav.msSaveOrOpenBlob) {
          // IEだとcreateObjectURLが使えないので対策
          nav.msSaveOrOpenBlob(blob)
          return this.axios.get(path)
        }

        //レスポンスヘッダからファイル名を取得します
        const contentDisposition = response.headers['content-disposition']
        let fileName = contentDisposition.substring(contentDisposition.indexOf("''") + 2, contentDisposition.length)
        //デコードするとスペースが"+"になるのでスペースへ置換します
        fileName = decodeURI(fileName).replace(/\+/g, ' ')

        const data = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = data
        link.download = fileName
        link.click()
        return response
      })
      .catch((error: any) => {
        // error
        const errorResponse = error.response
        return errorResponse
      })
  }
}

const WHITE_LIST = ['_destroy']

export const objectToFormData = (obj: any, form?: FormData, namespace?: string) => {
  const fd = form || new FormData()
  let formKey: string

  for (const property in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, property)) {
      const snakedProperty = WHITE_LIST.includes(property) ? property : snakeCase(property)
      if (namespace) {
        formKey = namespace + '[' + snakedProperty + ']'
      } else {
        formKey = snakedProperty
      }

      if (obj[property] === null) {
        // nullの場合は送らない
        continue
      }

      // if the property is an object, but not a File,
      // use recursivity.
      if (typeof obj[property] === 'object' && !(obj[property] instanceof File) && !(obj[property] instanceof Array)) {
        objectToFormData(obj[property], fd, formKey)
      } else if (obj[property] instanceof Array) {
        // eslint-disable-next-line no-loop-func
        obj[property].forEach((element: any) => {
          if (typeof element === 'object' && !(element instanceof File) && !(element instanceof Array)) {
            objectToFormData(element, fd, formKey + '[]')
          } else {
            fd.append(formKey + '[]', element)
          }
        })
      } else {
        // if it's a string or a File object
        fd.append(formKey, obj[property])
      }
    }
  }

  return fd
}
