import { Tooltip, Typography } from '@mui/material'
import { usePostDefectReportApi, usePatchDefectReportApi, useDeleteDefectReportApi } from 'api/mypage/defect_report'
import AttachmentsList from 'components/shared/data/attachments_list'
import DataLabel from 'components/shared/data/data_label'
import { Flex } from 'components/shared/flex'
import { StringField, DateTimeField, FilesInput, SelectField, NumberField } from 'components/shared/form/input'
import Modal, { ModalSet, ModalState } from 'components/shared/modal'
import { ModalStep, StepperModal } from 'components/shared/stepper_modal'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { MypageStateContext } from 'contexts/mypage_state_context'
import { ID } from 'entities'
import { Dealer } from 'entities/dealer'
import { DefectReportForm, DefectReport } from 'entities/defect_report'
import { Instrument } from 'entities/instrument'
import { OutsideDealer } from 'entities/outside_dealer'
import { getDeliveryTermSelectData } from 'entities/repair_estimate'
import { Form, ApiError, useForm } from 'rac'
import React, { useEffect, useContext, useState } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'

const dialogContent = (defectReportForm: Form<DefectReportForm>, error: ApiError) => {
  return (
    <>
      <Flex>
        <DateTimeField label="発生日時" attr="reportedAt" form={defectReportForm} apiError={error} required />
      </Flex>

      <Flex>
        <StringField label="不具合箇所" attr="point" form={defectReportForm} apiError={error} required />
      </Flex>

      <Flex>
        <StringField label="不具合内容" attr="content" form={defectReportForm} apiError={error} multiline required />
      </Flex>
      <AttachmentsList fileAttachments={defectReportForm.object.fileAttachments || []} emptyText=" " />
      <FilesInput form={defectReportForm} attr="attachmentsAttributes" />
    </>
  )
}

type NewDefectReportFormDialogProps = {
  modalState: ModalState
  onComplete?: () => void
  instrumentId?: ID
  proofreadEventId?: ID
}

export const NewDefectReportFormDialog: React.FC<NewDefectReportFormDialogProps> = (props: NewDefectReportFormDialogProps) => {
  const defectReportForm = useForm<DefectReportForm>({}, 'defectReport')
  const api = usePostDefectReportApi()
  const globalState = useContext(GlobalStateContext)

  useEffectSkipFirst(() => {
    if (props.onComplete) props.onComplete()
  }, [api.response.defectReport])

  useEffect(() => {
    if (!props.modalState.open) return
    defectReportForm.update((f) => {
      if (props.instrumentId) {
        f.instrumentId = props.instrumentId
      }
      if (props.proofreadEventId) {
        f.proofreadEventId = props.proofreadEventId
      }
    })
  }, [props.modalState.open])

  useEffect(() => {
    globalState.setLoading(api.loading)
  }, [api.loading])

  const handleSubmit = () => {
    api.execute(defectReportForm)
  }

  return (
    <Modal
      modalId="new-defect-report"
      modalState={props.modalState}
      title="不具合報告の登録"
      size="sm"
      footer={
        <>
          <ThemedButton onClick={() => props.modalState.setOpen(false)} color="secondary">
            閉じる
          </ThemedButton>
          <ThemedButton onClick={handleSubmit} color="success">
            登録
          </ThemedButton>
        </>
      }
    >
      {dialogContent(defectReportForm, api.apiError)}
    </Modal>
  )
}

type EditDefectReportFormDialogProps = {
  modalState: ModalState
  defectReport: DefectReport
  onComplete: (report?: DefectReport) => void
}

export const EditDefectReportFormDialog: React.FC<EditDefectReportFormDialogProps> = (props: EditDefectReportFormDialogProps) => {
  const api = usePatchDefectReportApi()
  const mypageState = useContext(MypageStateContext)
  const deleteApi = useDeleteDefectReportApi()
  const globalState = useContext(GlobalStateContext)

  const defectReportForm = useForm<DefectReportForm>(props.defectReport, 'defectReport')

  useEffectSkipFirst(() => {
    if (!api.isSuccess()) return
    props.onComplete(api.response.defectReport)
  }, [api.loading])

  useEffect(() => {
    defectReportForm.set(props.defectReport)
  }, [props.defectReport])

  const handleSubmit = () => {
    api.execute(defectReportForm.object)
  }

  const handleClickDelete = (defectReport: DefectReport) => {
    globalState.confirm('本当に削除しますか？', (event) => {
      if (event === 'ok') {
        deleteApi.execute(defectReport.id!)
      }
    })
  }

  useEffect(() => {
    if (deleteApi.isSuccess()) {
      props.onComplete()
    }
  }, [deleteApi.loading])

  const isDisabledDeleteButton = (defectReport: DefectReport) => {
    return defectReport.reporterableId !== mypageState.currentUser.id || defectReport.reporterableType !== 'User'
  }

  return (
    <div>
      <Modal
        modalState={props.modalState}
        modalId="edit-defect-report"
        title="不具合報告編集"
        size="sm"
        footer={
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', flexDirection: 'row' }}>
              <Tooltip title={isDisabledDeleteButton(props.defectReport) ? '作成者以外削除出来ません' : ''}>
                <div>
                  <ThemedButton
                    color="error"
                    onClick={() => handleClickDelete(props.defectReport)}
                    disabled={isDisabledDeleteButton(props.defectReport)}
                  >
                    削除
                  </ThemedButton>
                </div>
              </Tooltip>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <ThemedButton onClick={() => props.modalState.setOpen(false)} color="secondary" style={{ marginRight: 10 }}>
                  閉じる
                </ThemedButton>
                <ThemedButton onClick={handleSubmit} color="success">
                  更新
                </ThemedButton>
              </div>
            </div>
          </>
        }
      >
        {dialogContent(defectReportForm, api.apiError)}
      </Modal>
    </div>
  )
}

type NewDefectReportWithEstimateFormDialogProps = {
  modalSet: ModalSet<Instrument>
  onComplete: () => void
  proofreadEventId?: number
  outsideDealers?: OutsideDealer[]
}
export const NewDefectReportWithEstimateFormDialog: React.FC<NewDefectReportWithEstimateFormDialogProps> = (
  props: NewDefectReportWithEstimateFormDialogProps,
) => {
  const { currentUser } = useContext(MypageStateContext)
  const { modalSet, onComplete } = props
  const postForm = useForm<DefectReportForm>(
    {
      instrumentId: modalSet.item.id,
      repairEstimateAttributes: {},
    },
    'defectReport',
  )
  const postApi = usePostDefectReportApi()
  const [stepperIndex, setStepperIndex] = useState(0)
  const [dealer, setDealer] = useState(modalSet.item.dealer)

  useEffectSkipFirst(() => {
    postForm.update((f) => {
      f.instrumentId = modalSet.item.id
      if (props.proofreadEventId) f.proofreadEventId = props.proofreadEventId
    })
    setDealer(modalSet.item.dealer)
  }, [modalSet.item.id])

  const goNextStep = () => {
    setStepperIndex((prevIndex) => prevIndex + 1)
  }
  const goPrevStep = () => {
    setStepperIndex((prevIndex) => prevIndex - 1)
  }

  const handleSubmitDefectReport = () => {
    goNextStep()
  }
  const handleSubmitRepairEstimate = () => {
    postApi.execute(postForm)
  }

  useEffectSkipFirst(() => {
    if (!postApi.isSuccess()) {
      // 不具合報告の入力エラーがある場合は、1ページ目に戻す
      // 現状はaccepts_nested_attributes_forにより，defect_reportの子要素としてrepairEstimateがあるため，
      // repairEstimateのバリデーションエラーは必ずdefectReportの中に含まれている
      const defectReportErrors = postApi.apiError.details['defectReport']
      if (defectReportErrors && !postApi.loading) {
        const keys = Object.keys(defectReportErrors)
        if (keys.find((key) => !key.includes('repairEstimate'))) {
          setStepperIndex(0)
        }
      }
      return
    }
    onComplete()
  }, [postApi.loading])

  const handleCloseModal = () => {
    setStepperIndex(0)
    postForm.resetForm()
  }

  const steps: ModalStep[] = [
    {
      label: '不具合報告の作成',
      body: <div style={{ paddingTop: 12 }}>{dialogContent(postForm, postApi.apiError)}</div>,
      onClickNext: handleSubmitDefectReport,
    },
    {
      label: dealer ? '修理見積の依頼' : '修理見積の作成',
      body: (
        <div style={{ paddingTop: 12 }}>
          {currentUser.laboratory?.authority?.enableInstrumentSharing && modalSet.item.isShared && dealer ? (
            // 共有機器であればディーラーに依頼する
            <EstimateRequestForm form={postForm} dealer={dealer} />
          ) : (
            <EstimateForm form={postForm} apiError={postApi.apiError} outsideDealers={props.outsideDealers} />
          )}
        </div>
      ),
      allowBack: true,
      onClickBack: goPrevStep,
      onClickNext: handleSubmitRepairEstimate,
    },
  ]

  return (
    <StepperModal
      steps={steps}
      modalState={modalSet.modalState}
      size="sm"
      title="修理見積の登録"
      changeIndexManually
      index={stepperIndex}
      onCloseModal={handleCloseModal}
    />
  )
}

type EstimateFormProps = {
  form: Form<DefectReportForm>
  apiError: ApiError
  outsideDealers?: OutsideDealer[]
}
const EstimateForm: React.FC<EstimateFormProps> = (props) => {
  const { form, apiError } = props
  const inputProps = {
    form: form,
    apiError: apiError,
  }
  const getTotalText = () => `¥${(form.object.repairEstimateAttributes?.total || 0).toLocaleString()}`

  const taxRate = 0.1
  useEffect(() => {
    const newTax = Math.ceil((form.object.repairEstimateAttributes?.subtotal || 0) * taxRate)
    const newTotal = (form.object.repairEstimateAttributes?.subtotal || 0) + newTax
    form.update((f) => {
      if (f.repairEstimateAttributes) {
        f.repairEstimateAttributes.tax = newTax
        f.repairEstimateAttributes.total = newTotal
      }
    })
  }, [form.object.repairEstimateAttributes?.subtotal])
  useEffect(() => {
    const newTotal = (form.object.repairEstimateAttributes?.subtotal || 0) + (form.object.repairEstimateAttributes?.tax || 0)
    form.update((f) => {
      if (f.repairEstimateAttributes) {
        f.repairEstimateAttributes.total = newTotal
      }
    })
  }, [form.object.repairEstimateAttributes?.tax])
  useEffect(() => {
    if (form.object.repairEstimateAttributes?.suppliableId) {
      form.newUpdateObject(['repairEstimateAttributes', 'suppliableType'], 'OutsideDealer')
    } else {
      form.newUpdateObject(['repairEstimateAttributes', 'suppliableType'], null)
    }
  }, [form.object.repairEstimateAttributes?.suppliableId])
  return (
    <>
      {props.outsideDealers && props.outsideDealers.length > 0 && (
        <Flex justifyContent="space-between">
          <SelectField
            labelId="estimate-supplier-id"
            label="依頼先"
            attr={['repairEstimateAttributes', 'suppliableId']}
            {...inputProps}
            data={props.outsideDealers.map((o) => ({ label: o.name!, value: o.id! }))}
            includeBlank
          />
        </Flex>
      )}
      <Flex justifyContent="space-between">
        <NumberField
          label="修理費用（税別）"
          attr={['repairEstimateAttributes', 'subtotal']}
          required
          {...inputProps}
          style={{ paddingRight: 20 }}
        />
        <NumberField label="消費税" attr={['repairEstimateAttributes', 'tax']} required {...inputProps} style={{ paddingRight: 20 }} />
        <DataLabel label="合計額" value={getTotalText()} />
      </Flex>
      <Flex>
        <SelectField
          label="納期"
          labelId="delivery-label"
          attr={['repairEstimateAttributes', 'deliveryTerm']}
          data={getDeliveryTermSelectData()}
          required
          {...inputProps}
          style={{ width: '50%' }}
        />
      </Flex>
      <Flex>
        <StringField label="引き渡し・修理作業の進め方・備考等" attr={['repairEstimateAttributes', 'note']} multiline {...inputProps} />
      </Flex>
      <FilesInput attr={['repairEstimateAttributes', 'attachmentsAttributes']} {...inputProps} keyword="repairEstimate" />
    </>
  )
}

type EstimateRequestFormProps = {
  form: Form<DefectReportForm>
  dealer: Dealer
}
/**
 * 共有機器を提携ディーラーに見積依頼する
 */
const EstimateRequestForm: React.FC<EstimateRequestFormProps> = (props) => {
  const { form, dealer } = props

  useEffect(() => {
    form.update((f) => {
      if (f.repairEstimateAttributes) {
        f.repairEstimateAttributes.suppliableType = 'Dealer'
        f.repairEstimateAttributes.suppliableId = dealer.id
        f.repairEstimateAttributes.aasmState = 'estimating'
      }
    })
  }, [dealer.id])

  return <Typography textAlign="center">{props.dealer.name} に修理見積を依頼します</Typography>
}
