import { useRepruaIndexApi } from 'api/shared_api_hooks'
import { PagingResponse } from 'entities'
import { DealerDivisionLaboratoryRelation } from 'entities/dealer_division_laboratory_relation'
import { IndexApiSet } from 'rac'

import { AxiosHttpClient } from '../../axios'

type PartnerFetchDealerDivisionLaboratoryRelationsResponse = PagingResponse & {
  dealerDivisionLaboratoryRelations: DealerDivisionLaboratoryRelation[]
}
/**
 * 一覧
 */
export const usePartnerFetchDealerDivisionLaboratoryRelationsApi = (): IndexApiSet<
  PartnerFetchDealerDivisionLaboratoryRelationsResponse
> & {
  execute: (dealerDivisionId: number) => void
} => {
  const api = useRepruaIndexApi<PartnerFetchDealerDivisionLaboratoryRelationsResponse, {}>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { dealerDivisionLaboratoryRelations: [], totalCount: 0 },
  })

  const execute = (dealerDivisionId: number) => {
    const path = `dealer_divisions/${dealerDivisionId}/dealer_division_laboratory_relations`
    api.execute(path)
  }

  return { ...api, execute }
}
