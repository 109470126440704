import { useRepruaDeleteApi, useRepruaIndexApi, useRepruaPatchApi, useRepruaPostApi } from 'api/shared_api_hooks'
import { ID, PagingResponse } from 'entities'
import { CancelPolicy, CancelPolicyForm, CancelPolicySearchForm } from 'entities/cancel_policy'
import { ApiSet, BaseResponse, Form, IndexApiSet } from 'rac'
import { useEffectSkipFirst } from 'utils/hooks'

import { AxiosHttpClient } from '../axios'

/**
 * 取得
 */
type CancelPoliciesResponse = PagingResponse & {
  cancelPolicies: CancelPolicy[]
}

export function useFetchCancelPoliciesApi(): IndexApiSet<CancelPoliciesResponse> & { execute: () => void } {
  const api = useRepruaIndexApi<CancelPoliciesResponse, CancelPolicySearchForm>(new AxiosHttpClient('user'), {
    initialResponse: { cancelPolicies: [], totalCount: 0 },
  })

  const execute = () => {
    const path = 'cancel_policies'
    api.execute(path)
  }

  useEffectSkipFirst(() => {
    execute()
  }, [api.pageSet.page, api.pageSet.perPage, api.orderSet.order, api.orderSet.orderBy])

  return { ...api, execute }
}

/**
 * 登録
 */
type CancelPolicyResponse = BaseResponse & {}

export function usePostCancelPoliciesApi(): ApiSet<CancelPolicyResponse> & { execute: (cancelPolicyForm: Form<CancelPolicyForm>) => void } {
  const api = useRepruaPostApi<CancelPolicyResponse, CancelPolicyForm>(new AxiosHttpClient('user'), { initialResponse: {} })

  const execute = (cancelPolicyForm: Form<CancelPolicyForm>) => {
    const path = 'cancel_policies'
    api.execute(path, cancelPolicyForm)
  }

  return { ...api, execute }
}

/**
 * 更新
 */
export function usePatchCancelPolicyApi(): ApiSet<CancelPolicyResponse> & { execute: (cancelPolicyForm: CancelPolicyForm) => void } {
  const api = useRepruaPatchApi<CancelPolicyResponse, CancelPolicyForm>(new AxiosHttpClient('user'), { initialResponse: {} })

  const execute = (cancelPolicyForm: CancelPolicyForm) => {
    const path = `cancel_policies/${cancelPolicyForm.id}`
    api.execute(path, cancelPolicyForm)
  }

  return { ...api, execute }
}

/**
 * 削除
 */
export function useDeleteCancelPolicyApi(): ApiSet<CancelPolicyResponse> & { execute: (id: ID) => void } {
  const api = useRepruaDeleteApi<CancelPolicyResponse>(new AxiosHttpClient('user'), { initialResponse: {} })

  const execute = (id: ID) => {
    const path = `cancel_policies/${id}`
    api.execute(path)
  }

  return { ...api, execute }
}
