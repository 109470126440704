import { ViewApi, Duration, EventApi, EventInput } from '@fullcalendar/core'
import { Paper, Tooltip, Typography } from '@mui/material'
import { useFetchCancelPoliciesApi } from 'api/mypage/cancel_policy'
import { usePatchReservationApi } from 'api/mypage/reservation'
import { useFetchReservationConstraintRelationsApi } from 'api/mypage/reservation_constraint_relation'
import { useFetchRoomApi } from 'api/mypage/room'
import { useFetchRoomReservationsApi, usePostRoomReservationApi } from 'api/mypage/rooms/room_reservation'
import { NewReservationFormDialog, EditReservationFormDialog, CalendarData } from 'components/mypage/reservation/reservation_form_dialog'
import { EditRoomFormDialog } from 'components/mypage/room/room_form_dialog'
import { ConstraintDetailDialog } from 'components/mypage/shared/constraint_detail_dialog'
import { ConstraintRelationFormDialog } from 'components/mypage/shared/constraint_relation_form_dialog'
import { UserMemoModal } from 'components/mypage/user_memo/user_memo_modal'
import Calendar from 'components/shared/calendar'
import { ContentHeader, ContentTitle } from 'components/shared/content'
import { Flex } from 'components/shared/flex'
import ImageView from 'components/shared/image_view'
import { useModal } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { ConfirmCallbackEvent } from 'containers/global_state_container'
import { GlobalStateContext } from 'contexts/global_state_context'
import { MypageStateContext } from 'contexts/mypage_state_context'
import { Reservation, ReservationSearchForm } from 'entities/reservation'
import { ReservationConstraintRelationSearchForm } from 'entities/reservation_constraint_relation'
import moment from 'moment'
import { useForm } from 'rac'
import React, { useState, useEffect, useContext } from 'react'
import { StaticContext } from 'react-router'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'
import { useCalendar, useEffectSkipFirst } from 'utils/hooks'
import { ReservationViewModel } from 'view_models/reservation'
import { ReservationTermViewModel } from 'view_models/reservation_term'
import { RoomViewModel } from 'view_models/room'

export type RoomShowProps = {}

type Props = RouteComponentProps<{ id: string }, StaticContext, RoomShowProps>

const styles = {
  number: {
    marginRight: 20,
  },
  textArea: {
    marginTop: 5,
  },
}

const useStyles = makeStyles()({
  calendarEventStyle: {
    opacity: 0.8,
  },
})

export const RoomShow: React.FC<Props> = (props: Props) => {
  const [newDialogOpen, setNewDialogOpen] = useState<boolean>(false)
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false)
  const [editRoomDialogOpen, setEditRoomDialogOpen] = useState<boolean>(false)
  const [calendarData, setCalendarData] = useState<CalendarData>({ date: '', type: '' })
  const [eventInputs, setEventInputs] = useState<EventInput[]>([])
  const searchForm = useForm<ReservationSearchForm>({})
  const reservationIndexApi = useFetchRoomReservationsApi(Number(props.match.params.id))
  const calendarSet = useCalendar(reservationIndexApi, searchForm)
  const globalState = useContext(GlobalStateContext)
  const mypageState = useContext(MypageStateContext)
  const currentUser = mypageState.currentUser
  const [editReservation, setEditReservation] = useState<Reservation>({})
  const roomShowApi = useFetchRoomApi()
  const reservationUpdateApi = usePatchReservationApi()
  const memoModalState = useModal()
  const [constraintSetOpen, setConstraintSetOpen] = useState(false)
  const cancelPolicyIndexApi = useFetchCancelPoliciesApi()
  const { classes } = useStyles()
  const constraintRelationSearchForm = useForm<ReservationConstraintRelationSearchForm>({
    reservationTargetableId: Number(props.match.params.id),
    reservationTargetableType: 'Room',
  })
  const constraintRelationApi = useFetchReservationConstraintRelationsApi(constraintRelationSearchForm)
  useEffect(() => {
    const id = Number(props.match.params.id)
    roomShowApi.execute(id)
    cancelPolicyIndexApi.execute()
    constraintRelationApi.execute()
  }, [])

  /**
   * 予約できないものは表示できないようにする
   **/
  useEffect(() => {
    // if (!room) {
    //   return
    // }
    // if (!room.canReserve) {
    //   props.history.push(MypageRouteHelper.roomIndex())
    // }
    // console.log(roomShowApi.response.room)
  }, [roomShowApi.response.room])

  /**
   * ローディングプログレスの表示
   */
  useEffect(() => {
    globalState.setLoading(reservationIndexApi.loading || roomShowApi.loading || reservationUpdateApi.loading)
  }, [reservationIndexApi.loading, roomShowApi.loading, reservationUpdateApi.loading])

  /**
   * ドラッグドロップで予定が変更された場合は予定を再読み込みする
   */
  useEffectSkipFirst(() => {
    if (!reservationUpdateApi.loading) {
      calendarSet.resetDate()
    }
  }, [reservationUpdateApi.loading])

  /**
   * fullcalendarのdateクリック処理
   * @param event event
   */
  const handleClickDate = (event: {
    date: Date
    dateStr: string
    allDay: boolean
    resource?: any
    dayEl: HTMLElement
    jsEvent: MouseEvent
    view: ViewApi
  }) => {
    setCalendarData({
      date: event.dateStr,
      type: event.view.type,
    })
    setNewDialogOpen(true)
  }

  /**
   * fullcalendarのeventクリック
   * @param arg arg
   */
  const handleClickEvent = (arg: { el: HTMLElement; event: EventApi; jsEvent: MouseEvent; view: ViewApi }) => {
    const reservation = calendarSet.reservations.find((reservation) => `${reservation.id!}` === arg.event.id)
    if (reservation) {
      setEditReservation(reservation)
      setEditDialogOpen(true)
    }
  }

  /**
   * fullcalendarのドラッグドロップで予定が移動したときのイベント
   * @param info info
   */
  const handleDropEvent = (info: {
    el: HTMLElement
    event: EventApi
    oldEvent: EventApi
    delta: Duration
    revert: () => void
    jsEvent: Event
    view: ViewApi
  }) => {
    const reservation = calendarSet.reservations.find((reservation) => `${reservation.id!}` === info.event.id)
    if (reservation) {
      const start = moment(info.event.start)
      reservation.startAt = start.format('Y/MM/DD HH:mm')
      const end = moment(info.event.end)
      reservation.endAt = end.format('Y/MM/DD HH:mm')
      if (reservation.kind === 'repair') {
        globalState.confirm('変更した修理期間に含まれる予定はキャンセルされますが、よろしいですか？', (event: ConfirmCallbackEvent) => {
          if (event === 'cancel') {
            calendarSet.resetDate()
          } else {
            reservationUpdateApi.execute(reservation)
          }
        })
      } else {
        reservationUpdateApi.execute(reservation)
      }
    }
  }

  /**
   * useCalendarで取得した予定をfullcalendar用に組み直す
   */
  useEffect(() => {
    const events: EventInput[] = []
    calendarSet.reservations.forEach((reservation) => {
      const viewModel = new ReservationViewModel(reservation)
      events.push({
        id: reservation.id!.toString(),
        title: viewModel.getCalendarTitle(mypageState.currentUser.laboratory),
        start: reservation.startAt,
        end: reservation.endAt,
        color: viewModel.getEventColor(),
        classNames: viewModel.isOtherLaboratoryReservation(mypageState.currentUser.laboratory) ? classes.calendarEventStyle : '',
      })
    })
    setEventInputs(events)
  }, [calendarSet.reservations])

  /**
   * 予定の新規作成が完了したとき
   */
  const handleCompletePostReservation = () => {
    setNewDialogOpen(false)
    calendarSet.resetDate()
  }

  /**
   * 予定の更新が完了したときのイベント
   */
  const handleCompleteUpdateReservation = () => {
    setEditDialogOpen(false)
    calendarSet.resetDate()
  }

  /**
   * 機器の更新が完了したときのイベント
   */
  const handleCompleteUpdateRoom = () => {
    setEditRoomDialogOpen(false)
    roomShowApi.execute(roomShowApi.response.room.id!)
  }

  /**
   * 機器のViewModelを取得する
   */
  const getRoomVM = (): RoomViewModel => {
    return new RoomViewModel(roomShowApi.response.room)
  }

  /**
   * 予約条件のViewModelを取得する
   */
  const getReservationTermVM = (): ReservationTermViewModel => {
    return new ReservationTermViewModel(roomShowApi.response.room.reservationTerm!)
  }

  return (
    <>
      {roomShowApi.response.room && roomShowApi.response.room.reservationTerm && (
        <>
          <UserMemoModal
            memoableId={roomShowApi.response.room.id!}
            memoableType="Room"
            modalState={memoModalState}
            onClose={() => memoModalState.setOpen(false)}
          />
          <EditRoomFormDialog
            cancelPolicies={cancelPolicyIndexApi.response?.cancelPolicies}
            onComplete={handleCompleteUpdateRoom}
            room={roomShowApi.response.room}
            open={editRoomDialogOpen}
            onClose={() => setEditRoomDialogOpen(false)}
          />
          <NewReservationFormDialog
            isInstrument={false}
            reservable={roomShowApi.response.room}
            apiHooks={usePostRoomReservationApi}
            open={newDialogOpen}
            reservationTerm={roomShowApi.response.room.reservationTerm}
            calendarData={calendarData}
            onCancel={() => setNewDialogOpen(false)}
            onComplete={handleCompletePostReservation}
          />
          <EditReservationFormDialog
            isInstrument={false}
            reservable={roomShowApi.response.room}
            open={editDialogOpen}
            reservationTerm={roomShowApi.response.room.reservationTerm}
            reservation={editReservation}
            onCancel={() => setEditDialogOpen(false)}
            onComplete={() => handleCompleteUpdateReservation()}
          />
          <ContentHeader>
            <ContentTitle title={`ルーム詳細 - ${roomShowApi.response.room.name || ''}`}>{/* ボタンなど */}</ContentTitle>
          </ContentHeader>
          <Paper style={{ padding: 20 }}>
            <Flex>
              <ImageView style={{ width: 300 }} maxWidth={500} maxHeight={200} src={roomShowApi.response.room.imageUrl} />
              <div style={{ flexGrow: 1 }}>
                <Typography variant="h5">{roomShowApi.response.room.name}</Typography>
                <div style={styles.textArea}>{`金額：${getReservationTermVM().getPriceAndUnitText()}`}</div>
                <div style={styles.textArea}>{`貸出時間：${getReservationTermVM().getAvalableTimeText()}`}</div>
                <div style={styles.textArea}>{`予約単位：${getReservationTermVM().getSectionText()}`}</div>
                <div style={styles.textArea}>{`キャンセルポリシー：${getRoomVM().getCancelPolicy()}`}</div>
                <div style={styles.textArea}>{`部屋の説明等：${getRoomVM().getNote()}`}</div>
                {constraintRelationApi.response.myConstraintSets.length > 0 && (
                  <div style={{ marginTop: 10, color: 'red', fontSize: 12 }}>
                    ※貸出時間及び金額に関しては予約条件での設定があれば、予約条件の設定に依存します
                  </div>
                )}
                <Flex style={{ marginTop: 15 }}>
                  {currentUser.authority?.room && (
                    <Tooltip title={getRoomVM().isRoomOwner(currentUser.laboratory!) ? '' : '他社の機器は編集出来ません'}>
                      <div>
                        <ThemedButton
                          color="secondary"
                          disabled={!getRoomVM().isRoomOwner(currentUser.laboratory!)}
                          onClick={() => setEditRoomDialogOpen(true)}
                        >
                          編集
                        </ThemedButton>
                      </div>
                    </Tooltip>
                  )}
                  <ThemedButton color="secondary" onClick={() => memoModalState.setOpen(true)}>
                    メモ
                  </ThemedButton>
                  {getRoomVM().isRoomOwner(currentUser.laboratory!) && (
                    <>
                      <ThemedButton color="primary" onClick={() => setConstraintSetOpen(true)}>
                        予約条件の設定
                      </ThemedButton>
                      <ConstraintRelationFormDialog
                        open={constraintSetOpen}
                        reservationConstraintRelations={constraintRelationApi.response.reservationConstraintRelations}
                        reservationTargetableType="Room"
                        reservationTargetableId={Number(props.match.params.id)}
                        onComplete={() => {
                          constraintRelationApi.execute()
                        }}
                        onClose={() => setConstraintSetOpen(false)}
                      />
                    </>
                  )}
                  {constraintRelationApi.response.myConstraintSets.length > 0 && (
                    <ConstraintDetailDialog reservationConstraintSets={constraintRelationApi.response.myConstraintSets} />
                  )}
                </Flex>
              </div>
            </Flex>
          </Paper>
          <Paper style={{ padding: 40, marginTop: 20 }}>
            <Calendar
              editable={true}
              dateClick={handleClickDate}
              events={eventInputs}
              eventClick={handleClickEvent}
              eventDrop={handleDropEvent}
              datesSet={(arg) => calendarSet.setDate(arg.view.currentStart)}
            />
          </Paper>
        </>
      )}
    </>
  )
}

export default withRouter(RoomShow)
