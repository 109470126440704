import { Flex, FlexProps } from 'components/shared/flex'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()({
  dataBlock: {
    borderBottom: '1px solid #cccccc',
    padding: '20px 0',
  },
  noBorder: {
    borderBottom: 0,
  },
})

type DataBlockProps = FlexProps & {
  noBorder?: boolean
}
export const DataBlock: React.FC<DataBlockProps> = (props) => {
  const { noBorder, ...rest } = props
  const { classes } = useStyles()
  return (
    <Flex {...rest} className={`${classes.dataBlock} ${noBorder ? classes.noBorder : ''}`}>
      {props.children}
    </Flex>
  )
}

export default DataBlock
