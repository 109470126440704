import { AxiosHttpClient } from 'api/axios'
import { useRepruaIndexApi, useRepruaShowApi, useRepruaPostApi, useRepruaPatchApi } from 'api/shared_api_hooks'
import { ID, PagingResponse } from 'entities'
import { Dealer } from 'entities/dealer'
import { getLaboratoryInquirySearchQuery, Inquiry, InquiryForm, InquirySearchForm } from 'entities/inquiry'
import { InquiryMemberForm } from 'entities/inquiry_member'
import { ApiSet, BaseResponse, Form, IndexApiSet } from 'rac'
import { useEffectSkipFirst } from 'utils/hooks'

type InquiriesResponse = PagingResponse & {
  inquiries: Inquiry[]
  dealers: Dealer[]
}

export type InquiriesIndexApiSet = IndexApiSet<InquiriesResponse> & {
  execute: () => void
  executeAndResetPage: () => void
}
/**
 * 問合せ一覧
 */
export function useFetchInquiriesApi(props: { searchForm: Form<InquirySearchForm> }): InquiriesIndexApiSet {
  const path = 'inquiries'
  const apiSet = useRepruaIndexApi<InquiriesResponse, InquirySearchForm>(new AxiosHttpClient('user'), {
    initialResponse: { inquiries: [], dealers: [], totalCount: 0 },
  })

  const { searchText, ...rest } = props.searchForm.object

  const execute = () => {
    apiSet.execute(path, { params: rest, searchQuery: getLaboratoryInquirySearchQuery(searchText) })
  }

  const executeAndResetPage = () => {
    if (apiSet.pageSet.page === 1) {
      // ページが1ならリセットする必要なしなので実行する
      execute()
    } else {
      // ページが1でない場合の実行はuseEffectで行う
      apiSet.setState({ page: 1 })
    }
  }

  useEffectSkipFirst(() => {
    // ページの更新をハンドリングしてapiを実行
    apiSet.execute(path, { params: props.searchForm.object })
  }, [apiSet.pageSet.page])

  return {
    ...apiSet,
    execute,
    executeAndResetPage,
  }
}

export type InquiryResponse = BaseResponse & {
  inquiry: Inquiry
}

const initialResponse = {
  inquiry: {},
}

/**
 * １件の問合せを取得
 */
export function useFetchInquiryApi(): ApiSet<InquiryResponse> & { execute: (id: ID) => void } {
  const apiSet = useRepruaShowApi<InquiryResponse, {}>(new AxiosHttpClient('user'), { initialResponse: initialResponse })

  const execute = (id: ID) => {
    const apiPath = `inquiries/${id}`
    apiSet.execute(apiPath)
  }

  return {
    ...apiSet,
    execute,
  }
}

/**
 * 新規問合せを登録
 */
export function usePostInquiryApi(): ApiSet<InquiryResponse> & { execute: (form: Form<InquiryForm>) => void } {
  const apiSet = useRepruaPostApi<InquiryResponse, InquiryForm>(new AxiosHttpClient('user'), { initialResponse: initialResponse })

  const execute = (form: Form<InquiryForm>) => {
    const apiPath = 'inquiries'
    apiSet.execute(apiPath, form)
  }

  return {
    ...apiSet,
    execute,
  }
}

/**
 * 問合せを共有
 */
type SharingInquiryResponse = BaseResponse & {
  inquiry: Inquiry
}

export function useSharingInquiryApi(): ApiSet<SharingInquiryResponse> & { execute: (id: ID, form: Form<InquiryMemberForm>) => void } {
  const apiSet = useRepruaPatchApi<SharingInquiryResponse, InquiryMemberForm>(new AxiosHttpClient('user'), { initialResponse: { inquiry: {} } })

  const execute = (id: ID, form: Form<InquiryMemberForm>) => {
    const apiPath = `mypage/inquiries/${id}/shares`
    apiSet.execute(apiPath, form.object)
  }

  return {
    ...apiSet,
    execute,
  }
}
