import { ViewModel } from 'view_models'

import { MemoBase } from './memo_base'

export type UserMemo = MemoBase & {
  userMemoableId?: number
  userMemoableType?: string
  userName?: string
}

export type UserMemoForm = UserMemo & {}

export class UserMemoViewModel extends ViewModel<UserMemo> {}
