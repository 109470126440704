import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { Table, TableHead, TableRow} from '@mui/material'
import { useUpdateProofreadScheduleApi } from 'api/mypage/proodread_schedule'
import { ContainedIconButton } from 'components/shared/contained_icon_button'
import { Flex } from 'components/shared/flex'
import ImageView from 'components/shared/image_view'
import Modal, { ModalSet } from 'components/shared/modal'
import { ExTableBody, ExTableCell, ExTableRow } from 'components/shared/table'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { ProofreadEvent } from 'entities/proofread_event'
import { ProofreadSchedule, ProofreadScheduleForm } from 'entities/proofread_schedule'
import { useForm } from 'rac'
import React, { useContext, useState } from 'react'
import { MypageRouteHelper } from 'routes/mypage'
import { useEffectSkipFirst } from 'utils/hooks'
import { ProofreadEventViewModel } from 'view_models/proofread_event'
import { ProofreadScheduleViewModel } from 'view_models/proofread_schedule'

type ApprovedPlanEventFormDialogProps = {
  modalSet: ModalSet<ProofreadSchedule>
  onComplete: (schedule: ProofreadSchedule) => void
}
export const ApprovedPlanEventFormDialog: React.FC<ApprovedPlanEventFormDialogProps> = (props) => {
  const [scheduleVm, setScheduleVm] = useState(new ProofreadScheduleViewModel(props.modalSet.item))
  const globalState = useContext(GlobalStateContext)
  const patchForm = useForm<ProofreadScheduleForm>({ ...props.modalSet.item, aasmState: 'approved' })
  const patchApi = useUpdateProofreadScheduleApi(patchForm)

  useEffectSkipFirst(() => {
    setScheduleVm(new ProofreadScheduleViewModel(props.modalSet.item))
    patchForm.resetForm()
  }, [props.modalSet.item.id])

  const handleSubmit = () => {
    globalState.confirm('校正スケジュールを承認します。よろしいですか？', (e) => {
      if (e === 'ok') {
        patchApi.execute()
      }
    })
  }

  useEffectSkipFirst(() => {
    if (!patchApi.isSuccess()) return
    props.onComplete(patchApi.response.proofreadSchedule)
  }, [patchApi.loading])

  return (
    <Modal
      modalId="event-planning-form"
      modalState={props.modalSet.modalState}
      title="校正実施日程承認"
      subTitle={props.modalSet.item.supplier?.name || ''}
      size="lg"
      footer={
        <>
          <ThemedButton onClick={props.modalSet.closeModal} color="secondary">
            閉じる
          </ThemedButton>
          {/* <ThemedButton color="secondary">問合せ</ThemedButton> */}
          {props.modalSet.item.aasmState === 'requested' && (
            <ThemedButton color="primary" onClick={handleSubmit}>
              この日付で承認する
            </ThemedButton>
          )}
        </>
      }
    >
      <Flex justifyContent="space-around" style={{ marginBottom: 24 }}>
        <div>
          <label>引渡日</label>
          <div style={{ fontSize: 20 }}>{scheduleVm.getDeliveredDate()}</div>
        </div>
        <div>
          <label>返却日</label>
          <div style={{ fontSize: 20 }}>{scheduleVm.getReturnedDate()}</div>
        </div>
      </Flex>
      <div>
        <label>メモ</label>
        <div>{scheduleVm.getNote()}</div>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <ExTableCell colSpan={2} align="center">
              製品名
            </ExTableCell>
            <ExTableCell align="center">最終校正日</ExTableCell>
            <ExTableCell align="center">有効期限</ExTableCell>
            <ExTableCell align="center">校正周期</ExTableCell>
            <ExTableCell align="center">費用</ExTableCell>
            <ExTableCell align="center">担当者</ExTableCell>
            <ExTableCell align="center">カレンダー</ExTableCell>
          </TableRow>
        </TableHead>
        <ExTableBody loading={false}>
          {props.modalSet.item.proofreadEvents?.map((event: ProofreadEvent) => {
            const vm = new ProofreadEventViewModel(event)
            return (
              <ExTableRow key={event.id}>
                <ExTableCell width={100}>
                  <ImageView maxHeight={100} maxWidth={100} src={vm.getInstrumentImage()} />
                </ExTableCell>
                <ExTableCell>{vm.getInstrumentInfo()}</ExTableCell>
                <ExTableCell align="center">{vm.getLastResultDate()}</ExTableCell>
                <ExTableCell align="center">{vm.getExpiredDate()}</ExTableCell>
                <ExTableCell align="center">{vm.getCycle()}</ExTableCell>
                <ExTableCell align="center">{vm.getPrice()}</ExTableCell>
                <ExTableCell align="center">{vm.getManager()}</ExTableCell>
                <ExTableCell align="center">
                {event.instrument?.canReserve ? (
                  <ContainedIconButton
                    color="primary"
                    onClick={() => {
                      if (event.instrumentId) {
                        window.open(MypageRouteHelper.instrumentShow(event.instrumentId) + '?calendar=true', '_blank');
                      }
                    }}
                  >
                    <CalendarTodayIcon style={{ color: 'white', fontSize: 20 }} />
                  </ContainedIconButton>
                ) : (
                  '予約不可'
                )}
                </ExTableCell>
              </ExTableRow>
            )
          })}
        </ExTableBody>
      </Table>
    </Modal>
  )
}

export default ApprovedPlanEventFormDialog
