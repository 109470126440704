import { usePartnerDeleteProofreadEventApi, usePartnerPlanProofreadEventApi } from 'api/partner/proofread_event'
import { Flex } from 'components/shared/flex'
import { FilesInput, MonthField, NumberField, SelectField, StringField } from 'components/shared/form/input'
import Modal, { ModalSet } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { ProofreadEvent, ProofreadEventForm } from 'entities/proofread_event'
import { User } from 'entities/user'
import { useForm } from 'rac'
import React, { useContext, useState } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'
import { ProofreadEventViewModel } from 'view_models/proofread_event'

type NoPlanEventFormDialogProps = {
  modalSet: ModalSet<ProofreadEvent>
  onComplete: (event?: ProofreadEvent) => void
  onClickHistory: (event: ProofreadEvent) => void
  isPlanForLaboratory: boolean
  users?: User[]
}
export const NoPlanEventFormDialog: React.FC<NoPlanEventFormDialogProps> = (props) => {
  const [eventVm, setEventVm] = useState(new ProofreadEventViewModel(props.modalSet.item))
  const globalState = useContext(GlobalStateContext)

  const patchForm = useForm<ProofreadEventForm>({
    ...props.modalSet.item,
    aasmState: props.isPlanForLaboratory ? 'approved_plan' : 'attached_result',
    approverIds: [props.modalSet.item.userId!],
    requireSupplier: Boolean(props.modalSet.item.instrument?.proofreadInfo?.suppliableId),
  })
  const patchApi = usePartnerPlanProofreadEventApi(patchForm)
  const deleteApi = usePartnerDeleteProofreadEventApi(props.modalSet.item.id!)

  const handleSubmit = () => {
    globalState.confirm('校正イベントを登録します。よろしいですか？', (e) => {
      if (e === 'ok') {
        patchApi.execute()
      }
    })
  }

  const handleDelete = () => {
    globalState.confirm('校正計画から外します。よろしいですか？', (e) => {
      if (e === 'ok') {
        deleteApi.execute()
      }
    })
  }

  useEffectSkipFirst(() => {
    setEventVm(new ProofreadEventViewModel(props.modalSet.item))
    patchForm.resetForm()
  }, [props.modalSet.item.id])

  useEffectSkipFirst(() => {
    if (!patchApi.isSuccess()) return
    props.onComplete(patchApi.response.proofreadEvent)
  }, [patchApi.loading])

  useEffectSkipFirst(() => {
    if (!deleteApi.isSuccess()) return
    props.onComplete()
  }, [deleteApi.loading])

  return (
    <Modal
      modalId="event-planning-form"
      modalState={props.modalSet.modalState}
      title="校正計画設定"
      subTitle={eventVm.getInstrumentName()}
      footerStyle={{ display: 'inherit' }}
      footer={
        <Flex justifyContent="space-between" alignItems="center">
          <ThemedButton color="error" onClick={handleDelete}>
            計画から外す
          </ThemedButton>
          <div>
            <ThemedButton color="secondary" style={{ margin: 15 }} onClick={props.modalSet.closeModal}>
              閉じる
            </ThemedButton>
            <ThemedButton color="primary" onClick={handleSubmit}>
              設定
            </ThemedButton>
          </div>
        </Flex>
      }
    >
      <Flex justifyContent="space-between" style={{ marginBottom: 24 }}>
        <div>
          <label>最終校正月</label>
          <div style={{ fontSize: 20 }}>{eventVm.getLastResultDate()}</div>
        </div>
        <div>
          <label>校正周期</label>
          <div style={{ fontSize: 20 }}>{eventVm.getCycle()}</div>
        </div>
        <div>
          <label>有効期限</label>
          <div style={{ fontSize: 20 }}>{eventVm.getExpiredDate()}</div>
        </div>
        <div>
          <ThemedButton color="secondary" onClick={() => props.onClickHistory(props.modalSet.item)}>
            過去の校正を確認
          </ThemedButton>
        </div>
      </Flex>
      <Flex justifyContent="space-between">
        <MonthField label="校正月" form={patchForm} attr="scheduledDate" apiError={patchApi.apiError} required />
        <NumberField label="校正価格" form={patchForm} attr="price" apiError={patchApi.apiError} />
        {props.isPlanForLaboratory && props.users &&
          <SelectField
            label="ラボ側の担当者"
            form={patchForm}
            attr="userId"
            apiError={patchApi.apiError}
            includeBlank
            data={props.users.map((user) => ({ label: `${user.familyName} ${user.givenName}`, value: user.id }))}
            labelId="user-id"
          />
        }
      </Flex>
      <FilesInput form={patchForm} attr="attachmentsAttributes" apiError={patchApi.apiError} />
      <StringField label="計画メモ" form={patchForm} attr="noteOnPlan" apiError={patchApi.apiError} multiline />
    </Modal>
  )
}

export default NoPlanEventFormDialog
