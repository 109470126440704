import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { Table, TableHead, TableRow, Paper, TableContainer, Tooltip } from '@mui/material'
import { useFetchApprovalSettingsApi } from 'api/mypage/approval_setting'
import { useFetchCancelPoliciesApi } from 'api/mypage/cancel_policy'
import { useFetchInstrumentCategoriesApi } from 'api/mypage/instrument_categories'
import { useFetchPartnersApi } from 'api/mypage/partner'
import { useFetchRoomsApi } from 'api/mypage/room'
import {
  useBulkDiscardInstrumentApi,
  useBulkRestoreManagementUnmanagedInstrumentApi,
  useFetchUnmanagedInstrumentsApi,
} from 'api/mypage/unmanaged_instrument'
import { EditInstrumentFormDialog } from 'components/mypage/instrument/instrument_form_dialog'
import { CheckboxFooter } from 'components/shared/checkbox_footer'
import { ContentHeader, ContentTitle } from 'components/shared/content'
import DropDownCheckbox from 'components/shared/dropdown_checkbox'
import { Flex } from 'components/shared/flex'
import { SelectField } from 'components/shared/form/input'
import ImageView from 'components/shared/image_view'
import Pagination from 'components/shared/pagination'
import { SearchBar } from 'components/shared/search_bar'
import { ExTableCell, OrderTableCell, AccordionTableBody, AllCheckboxTableCell, CheckboxTableCell } from 'components/shared/table'
import { DisplaySettingModal } from 'components/shared/table/display_setting'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { MypageStateContext } from 'contexts/mypage_state_context'
import { Instrument, InstrumentSearchForm } from 'entities/instrument'
import { Room } from 'entities/room'
import { warningDiscardUnmanagedInstrumentDescription } from 'entities/unmanaged_instrument'
import { useForm } from 'rac'
import React, { useContext, useEffect } from 'react'
import { StaticContext } from 'react-router'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { MypageRouteHelper } from 'routes/mypage'
import { INDEX_TABLE_PADDING, INDEX_CHECKED_TABLE_PADDING, getTableHeight } from 'utils/component_heights'
import { useCheckboxes } from 'utils/hooks'
import { useDisplaySetting } from 'utils/hooks/index'
import { useEffectSkipFirst } from 'utils/hooks/use_effect_skip_first'
import { booleanFilterOptions, booleanFilterValues } from 'utils/utils'
import { InstrumentViewModel } from 'view_models/instrument'

const initialDisplaySettings = [
  { attr: 'createdAt', name: '作成日', visible: true },
  { attr: 'maker', name: 'メーカー', visible: true },
  { attr: 'room', name: '部屋', visible: true },
  { attr: 'purchasedAt', name: '取得日', visible: true },
  { attr: 'purchasedPrice', name: '取得価額', visible: true },
]

export type InstrumentIndexProps = {
  roomId?: number
}

type Props = RouteComponentProps<{}, StaticContext, InstrumentIndexProps>

const UnmanagedInstrumentIndex = (props: Props) => {
  const searchForm = useForm<InstrumentSearchForm>({
    q: { instrumentRoomIdEq: props.location.state?.roomId },
    canReserveFilter: booleanFilterValues,
    requireProofreadFilter: booleanFilterValues,
  })
  const unmanagedInstrumentsApi = useFetchUnmanagedInstrumentsApi(searchForm)
  const bulkRestoreManagementApi = useBulkRestoreManagementUnmanagedInstrumentApi()
  const bulkDiscardApi = useBulkDiscardInstrumentApi()
  const [editDialogOpen, setEditDialogOpen] = React.useState(false)
  const [editInstrument, setEditInstrument] = React.useState<Instrument>({})
  const displaySettingSet = useDisplaySetting(initialDisplaySettings, 'mypage-unmanaged-instruments-table')
  const roomIndexApi = useFetchRoomsApi({ perPage: 1000 })
  const categoryIndexApi = useFetchInstrumentCategoriesApi()
  const { currentUser } = useContext(MypageStateContext)
  const cancelPolicyIndexApi = useFetchCancelPoliciesApi()
  const suppliersIndexApi = useFetchPartnersApi()
  const approvalsIndexApi = useFetchApprovalSettingsApi()
  const mypageState = useContext(MypageStateContext)
  const checkboxSet = useCheckboxes(unmanagedInstrumentsApi.response.instruments)
  const globalState = useContext(GlobalStateContext)

  useEffect(() => {
    roomIndexApi.execute()
    unmanagedInstrumentsApi.execute()
    cancelPolicyIndexApi.execute()
    categoryIndexApi.execute()
    suppliersIndexApi.execute()
    approvalsIndexApi.execute()
  }, [])

  useEffectSkipFirst(() => {
    if (bulkRestoreManagementApi.isSuccess()) {
      unmanagedInstrumentsApi.execute()
      checkboxSet.clear()
    }
  }, [bulkRestoreManagementApi.loading])

  useEffectSkipFirst(() => {
    if (bulkDiscardApi.isSuccess()) {
      unmanagedInstrumentsApi.execute()
      checkboxSet.clear()
    }
  }, [bulkDiscardApi.loading])

  const handleUpdate = () => {
    setEditDialogOpen(false)
    unmanagedInstrumentsApi.execute()
  }

  const handleClickEditButton = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, instrument: Instrument) => {
    e.stopPropagation()
    setEditInstrument(instrument)
    setEditDialogOpen(true)
  }

  const handleClickDetail = (instrument: Instrument) => {
    props.history.push({
      pathname: MypageRouteHelper.instrumentShow(instrument.id!),
      state: {},
    })
  }

  const handleSearch = () => {
    unmanagedInstrumentsApi.execute()
  }

  const handleClickBulkRestoreManagement = () => {
    bulkRestoreManagementApi.execute({ instrumentIds: checkboxSet.checkedIds })
  }

  const handleClickBulkDiscard = () => {
    globalState.confirm(warningDiscardUnmanagedInstrumentDescription, (event) => {
      if (event === 'ok') {
        bulkDiscardApi.execute({ instrumentIds: checkboxSet.checkedIds })
      }
    })
  }

  return (
    <>
      <EditInstrumentFormDialog
        onComplete={handleUpdate}
        onCompleteRestoreManagement={handleUpdate}
        onCompleteDiscard={handleUpdate}
        instrument={editInstrument}
        open={editDialogOpen}
        onCancel={() => setEditDialogOpen(false)}
        rooms={roomIndexApi.response?.rooms}
        instrumentCategories={categoryIndexApi.response?.instrumentCategories}
        cancelPolicies={cancelPolicyIndexApi.response?.cancelPolicies}
        dealers={suppliersIndexApi.response.dealers}
        outsideDealers={suppliersIndexApi.response.outsideDealers}
        approvalSettings={approvalsIndexApi.response.approvalSettings}
        onCompleteRemoveImage={unmanagedInstrumentsApi.execute}
      />
      <ContentHeader
        subHeader={
          <Flex justifyContent="space-between">
            <ThemedButton
              variant="text"
              onClick={(e) => {
                props.history.push({
                  pathname: MypageRouteHelper.instrumentIndex(),
                  state: {},
                })
              }}
            >
              機器一覧に戻る
            </ThemedButton>
            <DisplaySettingModal displaySettingSet={displaySettingSet} />
          </Flex>
        }
      >
        <ContentTitle title="管理対象外の機器一覧" />
        <Flex alignItems="center">
          <SelectField
            label="部屋"
            width={200}
            form={searchForm}
            attr={['q', 'instrumentRoomIdEq']}
            labelId="search-select-room"
            data={
              roomIndexApi.response?.rooms
                ? roomIndexApi.response.rooms.map((room: Room) => {
                    return { value: room.id!, label: room.name! }
                  })
                : []
            }
            includeBlank
          />
          <SearchBar
            form={searchForm}
            searchAttr="searchText"
            placeholder="メーカー・製品名・型番・シリアル番号・管理番号で検索"
            onSubmit={handleSearch}
          />
        </Flex>
      </ContentHeader>
      <Paper>
        <TableContainer
          style={{
            maxHeight:
              checkboxSet.checkedIds.length > 0 ? getTableHeight(INDEX_CHECKED_TABLE_PADDING) : getTableHeight(INDEX_TABLE_PADDING),
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <AllCheckboxTableCell checkboxSet={checkboxSet} />
                <ExTableCell width={150}>{/* 画像 */}</ExTableCell>
                <OrderTableCell
                  width={120}
                  open={displaySettingSet.isVisible('createdAt')}
                  align="center"
                  orderAttr="createdAt"
                  orderSet={unmanagedInstrumentsApi.orderSet}
                >
                  登録日
                </OrderTableCell>
                <OrderTableCell
                  width={120}
                  open={displaySettingSet.isVisible('maker')}
                  orderAttr="maker"
                  orderSet={unmanagedInstrumentsApi.orderSet}
                >
                  メーカー
                </OrderTableCell>
                <OrderTableCell minWidth={200} orderAttr="name" orderSet={unmanagedInstrumentsApi.orderSet}>
                  型番 / シリアル番号 / 管理番号 / 製品名
                </OrderTableCell>
                <ExTableCell open={displaySettingSet.isVisible('room')}>部屋</ExTableCell>
                <ExTableCell width={120} open={displaySettingSet.isVisible('currentState')} align="center">
                  ステータス
                </ExTableCell>
                <OrderTableCell
                  width={120}
                  open={displaySettingSet.isVisible('purchasedAt')}
                  align="center"
                  orderAttr="purchasedAt"
                  orderSet={unmanagedInstrumentsApi.orderSet}
                >
                  取得日
                </OrderTableCell>
                <OrderTableCell
                  width={120}
                  open={displaySettingSet.isVisible('purchasedPrice')}
                  align="center"
                  orderAttr="purchasedPrice"
                  orderSet={unmanagedInstrumentsApi.orderSet}
                >
                  取得価額
                </OrderTableCell>
                <ExTableCell width={130}>
                  <DropDownCheckbox
                    form={searchForm}
                    attr="canReserveFilter"
                    name="canReserveFilter"
                    items={booleanFilterOptions}
                    title={
                      <div>
                        <div>カレンダー</div>
                        <div>予約</div>
                      </div>
                    }
                    onSubmit={handleSearch}
                    btnStyle={{ padding: '4px 8px' }}
                    isLeft={true}
                    maxWidth={600}
                    showFilter
                  />
                </ExTableCell>
                <ExTableCell width={120}>
                  <DropDownCheckbox
                    form={searchForm}
                    attr="requireProofreadFilter"
                    name="requireProofreadFilter"
                    items={booleanFilterOptions}
                    title="校正管理"
                    onSubmit={handleSearch}
                    btnStyle={{ padding: '4px 8px' }}
                    isLeft={true}
                    maxWidth={600}
                    showFilter
                  />
                </ExTableCell>
                <ExTableCell width={100} align="center"></ExTableCell>
              </TableRow>
            </TableHead>
            <AccordionTableBody loading={unmanagedInstrumentsApi.loading} tableName="instruments-table">
              {unmanagedInstrumentsApi.response?.instruments.map((instrument: Instrument) => {
                const instrumentVM = new InstrumentViewModel(instrument)
                return (
                  <TableRow key={instrument.id}>
                    <CheckboxTableCell checkboxesSet={checkboxSet} checkboxId={instrument.id!} />
                    <ExTableCell width={100} align="center">
                      <ImageView maxHeight={100} maxWidth={100} src={instrument.instrumentBasicInfo?.imageUrl} />
                    </ExTableCell>
                    <ExTableCell width={120} open={displaySettingSet.isVisible('createdAt')} align="center">
                      {instrumentVM.registeredAt()}
                    </ExTableCell>
                    <ExTableCell width={120} open={displaySettingSet.isVisible('maker')}>
                      {instrumentVM.getMakerName()}
                    </ExTableCell>
                    <ExTableCell>
                      <div style={{ fontSize: 11, marginBottom: 5 }}>{instrumentVM.getInstrumentNumbers()}</div>
                      <div style={{ fontSize: 16 }}>{instrumentVM.getInstrumentName()}</div>
                      {instrumentVM.getExternalLink()}
                    </ExTableCell>
                    <ExTableCell width={120} open={displaySettingSet.isVisible('room')}>
                      {instrumentVM.roomName()}
                    </ExTableCell>
                    <ExTableCell width={120} align="center" open={displaySettingSet.isVisible('currentState')}>
                      {instrumentVM.currentStateView()}
                    </ExTableCell>
                    <ExTableCell width={120} align="center" open={displaySettingSet.isVisible('purchasedAt')}>
                      {instrumentVM.purchasedAtText()}
                    </ExTableCell>
                    <ExTableCell width={120} align="center" open={displaySettingSet.isVisible('purchasedPrice')}>
                      {instrumentVM.purchasedPriceText()}
                    </ExTableCell>
                    <ExTableCell width={90} align="center">
                      {instrument.canReserve && <CheckCircleOutlineIcon style={{ color: 'purple' }} />}
                    </ExTableCell>
                    <ExTableCell width={90} align="center">
                      {instrument.requireProofread && <CheckCircleOutlineIcon style={{ color: 'green' }} />}
                    </ExTableCell>
                    <ExTableCell width={100} align="center">
                      <Flex flexDirection="column">
                        <ThemedButton color="primary" size="small" onClick={() => handleClickDetail(instrument)}>
                          詳細
                        </ThemedButton>
                        {currentUser.authority?.instrument && (
                          <Tooltip title={!instrumentVM.isInstrumentOwner(currentUser.laboratory!) ? '他社の機器は編集出来ません' : ''}>
                            <div>
                              <ThemedButton
                                disabled={!instrumentVM.isInstrumentOwner(mypageState.currentUser.laboratory!)}
                                color="secondary"
                                size="small"
                                onClick={(e) => handleClickEditButton(e, instrument)}
                              >
                                編集
                              </ThemedButton>
                            </div>
                          </Tooltip>
                        )}
                      </Flex>
                    </ExTableCell>
                  </TableRow>
                )
              })}
            </AccordionTableBody>
          </Table>
        </TableContainer>
        <Pagination response={unmanagedInstrumentsApi.response} pageSet={unmanagedInstrumentsApi.pageSet} />
        <CheckboxFooter
          content={
            <div style={{ marginRight: 10 }}>
              <ThemedButton color="error" variant="text" onClick={handleClickBulkDiscard} style={{ marginRight: 10 }}>
                廃棄する
              </ThemedButton>
              <ThemedButton color="success" onClick={handleClickBulkRestoreManagement}>
                もとに戻す
              </ThemedButton>
            </div>
          }
          checkboxSet={checkboxSet}
        />
      </Paper>
    </>
  )
}

export default withRouter(UnmanagedInstrumentIndex)
