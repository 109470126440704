import FilterIcon from '@mui/icons-material/FilterList'
import { Popover } from '@mui/material'
import {
  AutocompleteMultipleCheckBoxField,
  CheckBoxTargetId,
  DropDownCheckboxItem,
  MultipleCheckBoxField,
} from 'components/shared/form/input'
import ThemedButton from 'components/shared/themed_button'
import { Form, FormAttrType as Attribute } from 'rac'
import React, { CSSProperties } from 'react'

export type DropDownCheckboxProps<T> = {
  form: Form<T>
  attr: Attribute<T>
  title: React.ReactNode
  items: DropDownCheckboxItem[]
  name: string

  secondaryAttr?: Attribute<T>
  secondaryItems?: DropDownCheckboxItem[]
  showFilter?: boolean
  isLeft?: boolean
  maxWidth?: number
  btnClass?: string
  onSubmit?: () => void
  containerStyle?: CSSProperties
  btnStyle?: CSSProperties
  formLabel?: string
  autocomplete?: boolean
}

const DropDownCheckbox = <T,>(props: DropDownCheckboxProps<T>) => {
  const [allCheck, setAllCheck] = React.useState(true)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const open = Boolean(anchorEl)

  const dropdownBtnId = `${props.name}-dropdown-btn`

  const getFormArray = (): Array<any> => {
    let formArray = props.form.getValue(props.attr) as Array<any>
    if (!formArray) {
      formArray = []
    }
    return formArray
  }

  const allCheckBtnText = () => {
    if (getFormArray().length > 0) {
      return '選択解除'
    } else {
      return 'すべて選択'
    }
  }

  const includesItemId = (id: CheckBoxTargetId, formAttr: Attribute<T>): boolean => {
    const value = props.form.getValue(formAttr)
    if (value && value instanceof Array) {
      return value.includes(id as string)
    } else {
      return false
    }
  }

  function itemCheckboxGroups(items: DropDownCheckboxItem[], formAttr: Attribute<T>) {
    return items.map((item: DropDownCheckboxItem, index: number) => {
      return (
        <MultipleCheckBoxField
          key={item.value as string}
          checked={includesItemId(item.value, formAttr)}
          form={props.form}
          attr={formAttr}
          index={index}
          label={item.label}
          id={item.value as string}
        />
      )
    })
  }

  const handleOnClickAllChangeBtn = () => {
    if (!allCheck) {
      const allIds = props.items.map((item) => item.value)
      let secondaryAllIds: CheckBoxTargetId[] = []
      if (props.secondaryItems && props.secondaryAttr) {
        secondaryAllIds = props.secondaryItems.map((item) => String(item.value))
      }

      // attrが配列ならupdateObject、そうでないならupdate
      if (props.attr instanceof Array) {
        updateObjectAttr(allIds, secondaryAllIds)
      } else {
        updateAttr(allIds, secondaryAllIds)
      }
    } else {
      if (props.attr instanceof Array) {
        updateObjectAttr([], [])
      } else {
        updateAttr([], [])
      }
    }
    setAllCheck(!allCheck)
  }

  const updateObjectAttr = (ids: CheckBoxTargetId[], secondaryIds: CheckBoxTargetId[]) => {
    props.form.newUpdateObject(props.attr, ids)
    if (props.secondaryItems && props.secondaryAttr) {
      props.form.newUpdateObject(props.secondaryAttr, secondaryIds)
    }
  }

  const updateAttr = (ids: CheckBoxTargetId[], secondaryIds: CheckBoxTargetId[]) => {
    props.form.update((form: any) => {
      form[props.attr] = ids
      if (props.secondaryItems && props.secondaryAttr) {
        form[props.secondaryAttr] = secondaryIds
      }
    })
  }

  const handleClickBtn = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSubmit = () => {
    setAnchorEl(null)
    props.onSubmit && props.onSubmit()
  }

  const DropDownCheckBoxBodyStyle: CSSProperties = {
    top: '100%',
    padding: 15,
    right: props.isLeft ? 'auto' : 0,
    left: props.isLeft ? 0 : 'auto',
    background: 'white',
    minWidth: 350,
    maxWidth: props.maxWidth,
    borderRadius: 4,
    textAlign: 'right',
    border: '1px solid rbga(0,0,0,.15)',
    boxShadow: '0 6px 12px rgba(0,0,0,.175)',
  }

  const DropDownCheckboxGroupStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: props.isLeft ? 'flex-start' : 'center',
    overflowY: 'scroll',
    textAlign: 'right',
    fontSize: 14,
    maxWidth: props.maxWidth ? props.maxWidth : '',
  }

  const DropDownCheckBoxBody = (
    <div style={DropDownCheckBoxBodyStyle}>
      <div style={DropDownCheckboxGroupStyle}>{itemCheckboxGroups(props.items, props.attr)}</div>
      {props.secondaryItems && props.secondaryAttr && (
        <div style={DropDownCheckboxGroupStyle}>{itemCheckboxGroups(props.secondaryItems, props.secondaryAttr)}</div>
      )}

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '1rem',
        }}
      >
        <ThemedButton size="small" color="secondary" onClick={handleOnClickAllChangeBtn} style={{ marginRight: 10 }}>
          {allCheckBtnText()}
        </ThemedButton>
        <ThemedButton size="small" color="primary" onClick={handleSubmit}>
          検索
        </ThemedButton>
      </div>
    </div>
  )

  const AutocompleteDropDownBody = (
    <div style={DropDownCheckBoxBodyStyle}>
      <AutocompleteMultipleCheckBoxField
        form={props.form}
        attr={props.attr}
        items={props.items}
        label={props.title}
        id="autocomplete-checkbox"
      />

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '1rem',
        }}
      >
        <ThemedButton size="small" color="secondary" onClick={handleOnClickAllChangeBtn} style={{ marginRight: 10 }}>
          {allCheckBtnText()}
        </ThemedButton>
        <ThemedButton size="small" color="primary" onClick={handleSubmit}>
          検索
        </ThemedButton>
      </div>
    </div>
  )

  return (
    <div style={props.containerStyle}>
      {props.formLabel && (
        <div>
          <label>{props.formLabel}</label>
        </div>
      )}
      <ThemedButton size="small" variant="text" onClick={handleClickBtn} style={props.btnStyle}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {props.title}
          {getFormArray().length > 0 && props.showFilter && <FilterIcon fontSize="inherit" style={{ marginLeft: 3, marginBottom: 2 }} />}
        </div>
      </ThemedButton>
      <Popover
        id={dropdownBtnId}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={open}
        onClose={handleClose}
      >
        {props.autocomplete ? <>{AutocompleteDropDownBody}</> : <>{DropDownCheckBoxBody}</>}
      </Popover>
    </div>
  )
}
export default DropDownCheckbox
