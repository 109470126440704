import { useFetchInstrumentTotalCountApi } from 'api/mypage/instrument'
import { useCurrentUserQuery } from 'api/mypage/users'
import { MypageStateContext } from 'contexts/mypage_state_context'
import { User } from 'entities/user'
import React from 'react'
import { useEffectSkipFirst } from 'utils/hooks'

type MypageStateContainerProps = {
  children: React.ReactNode
}

const MypageStateContainer = ({ children }: MypageStateContainerProps) => {
  const [currentUser, setCurrentUser] = React.useState<User>({})
  const fetchInstrumentTotalCountApi = useFetchInstrumentTotalCountApi()
  const currentUserQuery = useCurrentUserQuery()

  const reloadUser = () => {
    currentUserQuery.refetch()
  }

  const reloadInstrumentTotalCount = () => {
    fetchInstrumentTotalCountApi.refetch()
  }

  useEffectSkipFirst(() => {
    if (currentUser.id) {
      reloadInstrumentTotalCount()
    }
  }, [currentUser.id])

  return (
    <MypageStateContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        reloadUser,
        reloadInstrumentTotalCount,
        instrumentTotalCount: fetchInstrumentTotalCountApi.data?.totalCount || 0,
      }}
    >
      {children}
    </MypageStateContext.Provider>
  )
}

export default MypageStateContainer
