import { AxiosHttpClient } from 'api/axios'
import { useRepruaIndexApi } from 'api/shared_api_hooks'
import { ID, PagingResponse } from 'entities'
import { Instrument } from 'entities/instrument'
import { RepairEvent, RepairEventSearchForm } from 'entities/repair_event'
import { IndexApiSet } from 'rac'
import { useEffectSkipFirst } from 'utils/hooks'

export type RepairEventsResponse = PagingResponse & {
  repairEvents: RepairEvent[]
  instrument: Instrument
}

/**
 * 一覧
 */
export type PartnerFetchInstrumentRepairEventsApiSet = IndexApiSet<RepairEventsResponse> & { execute: () => void }
export function usePartnerFetchInstrumentRepairEventsApi(instrumentId?: ID): PartnerFetchInstrumentRepairEventsApiSet {
  const api = useRepruaIndexApi<RepairEventsResponse, RepairEventSearchForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { repairEvents: [], totalCount: 0, instrument: {} },
  })

  const execute = () => {
    const path = `instruments/${instrumentId}/repair_events`
    api.execute(path)
  }

  useEffectSkipFirst(() => {
    execute()
  }, [api.pageSet.page, api.pageSet.perPage, api.orderSet.order, api.orderSet.orderBy])

  return { ...api, execute }
}
