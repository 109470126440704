import { Paper, TableContainer, Table, TableHead, TableRow } from '@mui/material'
import { useFetchAdminLaboratoriesApi } from 'api/admin/laboratory'
import { ContentHeader, ContentTitle } from 'components/shared/content'
import { OrderTableCell, ExTableCell, ExTableBody, ExTableRow } from 'components/shared/table'
import ThemedButton from 'components/shared/themed_button'
import { Laboratory } from 'entities/laboratory'
import React, { useEffect } from 'react'
import { StaticContext } from 'react-router'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AdminRouteHelper } from 'routes/admin'

type LaboratoryIndexProps = {}

type Props = RouteComponentProps<{}, StaticContext, LaboratoryIndexProps>

export const LaboratoryIndex: React.FC<Props> = (props) => {
  const api = useFetchAdminLaboratoriesApi()

  useEffect(() => {
    api.execute()
  }, [])

  const handleClickDetail = (laboratory: Laboratory) => {
    props.history.push({
      pathname: AdminRouteHelper.laboratoryShow(laboratory.id!),
      state: {},
    })
  }

  return (
    <>
      <ContentHeader>
        <ContentTitle title="ラボ一覧"></ContentTitle>
      </ContentHeader>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <OrderTableCell minWidth={250} orderAttr="name" orderSet={api.orderSet}>
                  ラボ名称
                </OrderTableCell>
                <ExTableCell width={80} align="center">
                  管理者ラボ
                </ExTableCell>
                <ExTableCell width={80} align="center"></ExTableCell>
              </TableRow>
            </TableHead>
            <ExTableBody loading={api.loading}>
              {api.response?.laboratories.map((laboratory: Laboratory) => {
                return (
                  <ExTableRow key={laboratory.id}>
                    <ExTableCell>{laboratory.name}</ExTableCell>
                    <ExTableCell align="center">{`${laboratory.isManager ? '✔️' : ''}`}</ExTableCell>
                    <ExTableCell>
                      <ThemedButton color="secondary" onClick={() => handleClickDetail(laboratory)}>
                        詳細
                      </ThemedButton>
                    </ExTableCell>
                  </ExTableRow>
                )
              })}
            </ExTableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  )
}

export default withRouter(LaboratoryIndex)
