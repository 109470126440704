import { AxiosHttpClient } from 'api/axios'
import { useRepruaIndexApi, useRepruaDownloadApi } from 'api/shared_api_hooks'
import { ReservationSearchForm, ReservationsResponse } from 'entities/reservation'
import { ReservationDownloadForm } from 'entities/reservation_download'
import { Form, IndexApiSet } from 'rac'

/**
 * 一覧
 */
export function useFetchUserReservationsApi(): IndexApiSet<ReservationsResponse> & {
  execute: (form: Form<ReservationSearchForm>) => void
} {
  const api = useRepruaIndexApi<ReservationsResponse, ReservationSearchForm>(new AxiosHttpClient('user'), {
    initialResponse: { reservations: [], totalCount: 0 },
  })

  const execute = (form: Form<ReservationSearchForm>) => {
    const path = 'users/reservations'
    api.execute(path, { params: form.object })
  }

  return { ...api, execute }
}

// 全ての条件でエクセルをダウンロードする
export function useFetchXlsxUserReservationsApi(): { execute: () => void } {
  const api = useRepruaDownloadApi<any>(new AxiosHttpClient('user'))
  const path = 'users/reservations.xlsx?submit_type=all'

  const execute = () => {
    api.execute(path)
  }

  return { execute }
}

// 期間指定でエクセルをダウンロードする
export function useFetchXlsxUserReservationsWithDurationApi(): { execute: (form: Form<ReservationDownloadForm>) => void } {

  const api = useRepruaDownloadApi<any>(new AxiosHttpClient('user'))
  const path = 'users/reservations.xlsx?submit_type=search'

  const execute = (form: Form<ReservationDownloadForm>) => {
    api.execute(path, { params: form.object})
  }

  return { execute }
}