import { DealerUser } from 'entities/dealer_user'
import { authorityData } from 'entities/user_authority'
import React from 'react'
import { ViewModel } from 'view_models'

export class DealerUserViewModel extends ViewModel<DealerUser> {
  /**
   * フルネーム.
   */
  public getName(): string {
    return this.object.name || '-'
  }

  public authority(authType: string): JSX.Element {
    const authority = this.object.authority!

    switch (authType) {
      case 'privileged':
        return authority.privileged ? <div style={styles.auth}>{authorityData[authType]}</div> : <></>
      default:
        return <></>
    }
  }
}

const styles = {
  auth: {
    fontSize: 12,
    display: 'inline-block',
    background: '#4581b9',
    color: '#fff',
    padding: '3px 5px 2px',
    margin: '0 8px 5px 0',
    borderRadius: 3,
  },
}
