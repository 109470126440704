import { cyan, green, grey, orange, purple, red } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'

export type ThemeColor = 'primary' | 'secondary' | 'warning' | 'error' | 'success' | 'info'

// Material UIのテーマ上書き
export const theme = createTheme({
  // カラーパレット追加
  palette: {
    mode: 'light',
    primary: {
      light: purple[300],
      main: purple[500],
      dark: purple[700],
    },
    secondary: {
      light: grey[100],
      main: grey[300],
      dark: grey[500],
    },
    success: {
      light: green[400],
      main: green[600],
      dark: green[800],
    },
    info: {
      light: cyan[300],
      main: cyan[500],
      dark: cyan[700],
    },
    warning: {
      light: orange[300],
      main: orange[500],
      dark: orange[700],
    },
    error: {
      light: red[300],
      main: red[500],
      dark: red[700],
    },
    text: {
      primary: '#585864',
    },
  },
  // レスポンシブのブレイクポイント
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 360, // スマホ用
      sm: 768, // タブレット用
      md: 992, // PC用
      lg: 1400,
      xl: 1800,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "#F6F6F9",
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          // ボタン内アルファベット文字を大文字変換しない
          textTransform: 'none',
          padding: '5px 10px',
        },
      },
      defaultProps: {
        disableElevation: true,
        variant: 'contained',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        rows: 4,
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: 43,
        },
        multiline: {
          height: 'inherit',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          height: 'inherit',
        }
      },
    },
  },
})

export default theme
