import { Alert, AlertColor, AlertTitle, Snackbar } from '@mui/material'
import React, { useState } from 'react'
import { useEffectSkipFirst } from 'utils/hooks/index'

export type NotificationSnackbarProps = {
  notificationMessage: {
    colorType?: AlertColor
    title?: string
    body: string
  }
}

export const NotificationSnackbar: React.FC<NotificationSnackbarProps> = (props: NotificationSnackbarProps) => {
  const [open, setOpen] = useState(false)

  useEffectSkipFirst(() => {
    if (props.notificationMessage.body.length > 0) {
      setOpen(true)
    }
  }, [props.notificationMessage])

  const handleCloseSnackbar = () => {
    setOpen(false)
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      autoHideDuration={6000}
      onClose={handleCloseSnackbar}
      className="notification-snackbar"
    >
      <Alert variant="filled" severity={props.notificationMessage.colorType}>
        {props.notificationMessage.title && props.notificationMessage.title.length > 0 && (
          <AlertTitle>{props.notificationMessage.title}</AlertTitle>
        )}
        {props.notificationMessage.body}
      </Alert>
    </Snackbar>
  )
}

NotificationSnackbar.defaultProps = {
  notificationMessage: {
    colorType: 'success',
    title: '',
    body: '',
  },
}

export default NotificationSnackbar
