import DeleteIcon from '@mui/icons-material/DeleteForever'
import { IconButton, Table, TableRow } from '@mui/material'
import { ExTableBody, ExTableCell } from 'components/shared/table'
import { MemoBase } from 'entities/memo_base'
import { UserMemo, UserMemoViewModel } from 'entities/user_memo'
import React, { useEffect, useRef } from 'react'

type MemoTableProps = {
  memos?: MemoBase[]
  loading: boolean
  onClickDeleteMemo: (memo: MemoBase) => void
  senderId?: number
}

export const MemoTable: React.FC<MemoTableProps> = (props) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setTimeout(() => {
      if (ref && ref.current) {
        ref.current.scrollTo(0, ref.current.scrollHeight)
      }
    }, 100)
  }, [props.memos])

  return (
    <div>
      <div style={{ maxHeight: '50vh', overflowY: 'scroll', minWidth: 660 }} ref={ref}>
        <Table>
          <ExTableBody loading={props.loading} emptyMessage="メモがありません">
            {props.memos &&
              props.memos.map((memo: UserMemo) => {
                const vm = new UserMemoViewModel(memo)
                return (
                  <TableRow key={memo.id}>
                    <ExTableCell minWidth={600}>
                      <div style={{ fontSize: 12 }}>{`${vm.createdAt().format('YYYY/MM/DD hh:mm')} ${memo.senderName}`}</div>
                      <div style={{ marginTop: 10 }}>
                        {memo.body?.split('\n').map((line: string, i) => {
                          return <div key={i}>{line}</div>
                        })}
                      </div>
                    </ExTableCell>
                    <ExTableCell width={60} style={{ verticalAlign: 'bottom' }}>
                      {props.senderId === memo.senderId && (
                        <IconButton aria-label="delete" onClick={() => props.onClickDeleteMemo(memo)} size="large">
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </ExTableCell>
                  </TableRow>
                )
              })}
          </ExTableBody>
        </Table>
      </div>
    </div>
  )
}
