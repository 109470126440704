import React, { ReactNode, useState } from 'react'

import ExTableBody, { ExTableBodyProps } from './ex_table_body'

type AccordionTableBodyProps = ExTableBodyProps & {
  children: ReactNode
  tableName: string
  page?: number
}

export const AccordionTableBody: React.FC<AccordionTableBodyProps> = (props: AccordionTableBodyProps) => {
  const [expandedRowId, setExpandedRowId] = useState('')

  const childrenWithProps = React.Children.map(props.children, (child: ReactNode, index: number) => {
    const key = `${props.tableName}${props.page}-${index}${props.page}`
    const newProps = {
      aid: key,
      onClick: () => {
        expandedRowId === key ? setExpandedRowId('') : setExpandedRowId(key)
      },
      expandedRowId: expandedRowId,
    }
    return React.cloneElement(child as React.ReactElement<any>, newProps)
  })
  const { tableName, page, ...rest } = props
  return <ExTableBody {...rest}>{childrenWithProps}</ExTableBody>
}

export default AccordionTableBody
