import { Checkbox } from '@mui/material'
import { HttpClientType } from 'api/axios'
import { ExAttributeApiSet, useBulkUpdateExAttributeApi, usePostExAttributeApi } from 'api/shared/ex_attribute'
import { CheckboxAccordion } from 'components/shared/checkbox_accordion'
import { Flex } from 'components/shared/flex'
import { NumberField, SelectField, StringField } from 'components/shared/form/input'
import Modal, { ModalSet } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { ExAttribute, ExAttributeBulkUpdateForm, ExAttributeForm } from 'entities/ex_attribute'
import { ExColumn, ExtensionClass } from 'entities/ex_column'
import { InstrumentInfoAttribute } from 'entities/instrument_info_attribute'
import { ProofreadEvent } from 'entities/proofread_event'
import { DealerClient } from 'pages/partner/ex_column'
import { Form, useForm } from 'rac'
import React, { useContext, useMemo } from 'react'
import { CheckboxesSet, useCheckboxes, useEffectSkipFirst } from 'utils/hooks'

type DialogFormProps = {
  exAttributes: ExAttribute[]
  form: Form<ExAttributeForm>
  api: ExAttributeApiSet
}
const DialogForm = (props: DialogFormProps) => {
  return (
    <Flex flexWrap="wrap" justifyContent="space-between">
      {props.exAttributes.map((exAttr) => {
        const index = (props.form.object.exAttributeValues || []).findIndex((attr) => attr.exColumnId === exAttr.exColumn.id)

        switch (exAttr.exColumn.inputType) {
          case 'string':
            return (
              <StringField
                key={index}
                label={exAttr.exColumn.name}
                form={props.form}
                attr={['exAttributeValues', index, 'value']}
                width={328}
                style={{ marginBottom: exAttr.exColumn.required ? 0 : 12 }}
                required={exAttr.exColumn.required}
                apiError={props.api.apiError}
              />
            )
          case 'text':
            return (
              <StringField
                key={index}
                label={exAttr.exColumn.name}
                form={props.form}
                attr={['exAttributeValues', index, 'value']}
                multiline
                style={{ marginBottom: exAttr.exColumn.required ? 0 : 12 }}
                required={exAttr.exColumn.required}
                apiError={props.api.apiError}
              />
            )
          case 'integer':
            return (
              <NumberField
                key={index}
                label={exAttr.exColumn.name}
                form={props.form}
                attr={['exAttributeValues', index, 'value']}
                width={328}
                style={{ marginBottom: exAttr.exColumn.required ? 0 : 12 }}
                required={exAttr.exColumn.required}
                apiError={props.api.apiError}
              />
            )
          case 'dropdown':
            return (
              <SelectField
                key={index}
                label={exAttr.exColumn.name}
                form={props.form}
                attr={['exAttributeValues', index, 'value']}
                labelId={`label-${exAttr.exColumn.id}`}
                data={(exAttr.exColumn.options || []).map((option) => ({ label: option, value: option }))}
                width={328}
                formStyleProps={{ marginBottom: exAttr.exColumn.required ? 0 : 12 }}
                required={exAttr.exColumn.required}
                includeBlank={!exAttr.exColumn.required}
                apiError={props.api.apiError}
              />
            )
          case 'price':
            return (
              <NumberField
                key={index}
                label={exAttr.exColumn.name}
                form={props.form}
                attr={['exAttributeValues', index, 'value']}
                width={328}
                style={{ marginBottom: exAttr.exColumn.required ? 0 : 12 }}
                required={exAttr.exColumn.required}
                apiError={props.api.apiError}
              />
            )
          default:
            return <></>
        }
      })}
    </Flex>
  )
}

const bulkFormWidth = 310

type BulkUpdateDialogFormProps = {
  exColumns: ExColumn[]
  checkboxSet: CheckboxesSet
  form: Form<ExAttributeForm>
  api: ExAttributeApiSet
  offset?: number
}
const BulkUpdateDialogForm = (props: BulkUpdateDialogFormProps) => {
  return (
    <Flex flexWrap="wrap" justifyContent="space-between">
      {props.exColumns.map((column, idx) => {
        const index = (props.form.object.exAttributeValues || []).findIndex((attr) => attr.exColumnId === column.id)
        const isChecked = () => {
          if (column.id) {
            return props.checkboxSet.isChecked(column.id)
          } else {
            return false
          }
        }
        const handleClickCheckbox = () => {
          if (column.id) {
            props.checkboxSet.updateCheckedIds(column.id)
          }
        }
        const bulkFormCheckbox = () => <Checkbox checked={isChecked()} onChange={() => handleClickCheckbox()} style={{ marginRight: 0 }} />

        switch (column.inputType) {
          case 'string':
            return (
              <Flex key={idx}>
                {bulkFormCheckbox()}
                <StringField
                  label={column.name}
                  form={props.form}
                  attr={['exAttributeValues', index, 'value']}
                  width={bulkFormWidth}
                  style={{ marginBottom: column.required ? 0 : 12 }}
                  required={column.required}
                  apiError={props.api.apiError}
                  disabled={!isChecked()}
                />
              </Flex>
            )
          case 'text':
            return (
              <Flex key={idx} style={{ width: '100%' }}>
                {bulkFormCheckbox()}
                <StringField
                  label={column.name}
                  form={props.form}
                  attr={['exAttributeValues', index, 'value']}
                  multiline
                  style={{ marginBottom: column.required ? 0 : 12 }}
                  required={column.required}
                  apiError={props.api.apiError}
                  disabled={!isChecked()}
                />
              </Flex>
            )
          case 'integer':
            return (
              <Flex key={idx}>
                {bulkFormCheckbox()}
                <NumberField
                  label={column.name}
                  form={props.form}
                  attr={['exAttributeValues', index, 'value']}
                  width={bulkFormWidth}
                  style={{ marginBottom: column.required ? 0 : 12 }}
                  required={column.required}
                  apiError={props.api.apiError}
                  disabled={!isChecked()}
                />
              </Flex>
            )
          case 'dropdown':
            return (
              <Flex key={idx}>
                {bulkFormCheckbox()}
                <SelectField
                  label={column.name}
                  form={props.form}
                  attr={['exAttributeValues', index, 'value']}
                  labelId={`label-${column.id}`}
                  data={(column.options || []).map((option) => ({ label: option, value: option }))}
                  width={bulkFormWidth}
                  formStyleProps={{ marginBottom: column.required ? 0 : 12 }}
                  required={column.required}
                  includeBlank={!column.required}
                  apiError={props.api.apiError}
                  disabled={!isChecked()}
                />
              </Flex>
            )
          case 'price':
            return (
              <Flex key={idx}>
                {bulkFormCheckbox()}
                <NumberField
                  label={column.name}
                  form={props.form}
                  attr={['exAttributeValues', index, 'value']}
                  width={bulkFormWidth}
                  style={{ marginBottom: column.required ? 0 : 12 }}
                  required={column.required}
                  apiError={props.api.apiError}
                  disabled={!isChecked()}
                />
              </Flex>
            )
          default:
            return <></>
        }
      })}
    </Flex>
  )
}

type ExAttributeFormDialogProps<T> = {
  clientType: HttpClientType
  modalSet: ModalSet<T>
  extensionClass: ExtensionClass
  exAttributes: ExAttribute[]
  onComplete: () => void
  dealerClient?: DealerClient
}
export const ExAttributeFormDialog = <T extends InstrumentInfoAttribute | ProofreadEvent>(props: ExAttributeFormDialogProps<T>) => {
  const postForm = useForm<ExAttributeForm>(
    {
      extensionableId: props.modalSet.item.id,
      extensionableType: props.extensionClass,
      exAttributeValues: props.exAttributes.map((ex) => ex.exAttributeValue),
    },
    'exAttribute',
  )
  const postApi = usePostExAttributeApi(props.clientType)
  const sharedExAttributes = props.exAttributes?.filter((ex) => !ex.exColumn.clientableId && !ex.exColumn.clientableType) || []

  const clientExAttributes: ExAttribute[] = useMemo(() => {
    if (!props.dealerClient || !props.dealerClient.clientableId || !props.dealerClient.clientableType) return []
    return props.exAttributes.filter(
      (ex) =>
        ex.exColumn.clientableId === props.dealerClient!.clientableId && ex.exColumn.clientableType === props.dealerClient!.clientableType,
    )
  }, [props.modalSet.item, props.exAttributes, props.dealerClient])

  useEffectSkipFirst(() => {
    if (!props.modalSet.modalState.open) return
    postForm.resetForm()
  }, [props.modalSet.item.id, props.modalSet.modalState.open])

  useEffectSkipFirst(() => {
    if (!postApi.isSuccess()) return
    postForm.resetForm()
    props.onComplete()
  }, [postApi.loading])

  return (
    <Modal
      modalId="extensin-attributes-dialog"
      modalState={props.modalSet.modalState}
      title={`${props.extensionClass === 'InstrumentInfoAttribute' ? '製品' : '校正'}管理情報`}
      footer={
        <>
          <ThemedButton color="secondary" onClick={props.modalSet.closeModal}>
            閉じる
          </ThemedButton>
          {props.exAttributes.length !== 0 && (
            <ThemedButton color="success" onClick={() => postApi.execute(postForm)}>
              登録
            </ThemedButton>
          )}
        </>
      }
    >
      {props.exAttributes.length === 0 ? (
        <>{props.extensionClass === 'InstrumentInfoAttribute' ? '製品' : '校正'}管理情報が設定されていません</>
      ) : (
        <>
          {sharedExAttributes.length > 0 && (
            <>
              <DialogForm exAttributes={sharedExAttributes.filter((ex) => !ex.exColumn.stashed)} form={postForm} api={postApi} />
              {sharedExAttributes.filter((ex) => ex.exColumn.stashed).length > 0 && (
                <CheckboxAccordion label="詳細設定" defaultChecked={false}>
                  <DialogForm exAttributes={sharedExAttributes.filter((ex) => ex.exColumn.stashed)} form={postForm} api={postApi} />
                </CheckboxAccordion>
              )}
            </>
          )}

          {clientExAttributes.length > 0 && (
            <>
              <div style={{ fontSize: 18, fontWeight: 'bold', margin: '15px 0' }}>顧客別管理情報</div>
              <DialogForm exAttributes={clientExAttributes.filter((ex) => !ex.exColumn.stashed)} form={postForm} api={postApi} />
              {clientExAttributes.filter((ex) => ex.exColumn.stashed).length > 0 && (
                <CheckboxAccordion label="詳細設定" defaultChecked={false}>
                  <DialogForm exAttributes={clientExAttributes.filter((ex) => ex.exColumn.stashed)} form={postForm} api={postApi} />
                </CheckboxAccordion>
              )}
            </>
          )}
        </>
      )}
    </Modal>
  )
}

type ExAttributeBulkUpdateFormDialogProps = {
  clientType: HttpClientType
  modalSet: ModalSet<number[]>
  extensionClass: ExtensionClass
  exColumns: ExColumn[]
  onComplete: () => void
  dealerClient?: DealerClient
}
export const ExAttributeBulkUpdateFormDialog: React.FC<ExAttributeBulkUpdateFormDialogProps> = (props) => {
  const globalState = useContext(GlobalStateContext)

  const postForm = useForm<ExAttributeBulkUpdateForm>(
    {
      extensionableIds: props.modalSet.item,
      extensionableType: props.extensionClass,
      exAttributeValues: props.exColumns.map((c) => ({ exColumnId: c.id })),
    },
    'exAttribute',
  )
  const postApi = useBulkUpdateExAttributeApi(props.clientType)
  const checkboxSet = useCheckboxes(props.exColumns)

  const sharedExColumns = props.exColumns?.filter((col) => !col.clientableId && !col.clientableType) || []
  const clientExColumns: ExColumn[] = useMemo(() => {
    if (!props.dealerClient || !props.dealerClient.clientableId || !props.dealerClient.clientableType) return []
    return props.exColumns.filter(
      (col) => col.clientableId === props.dealerClient!.clientableId && col.clientableType === props.dealerClient!.clientableType,
    )
  }, [props.modalSet.item, props.exColumns, props.dealerClient])

  const handleSubmit = () => {
    globalState.confirm('管理情報を一括登録します。よろしいですか？', (e) => {
      if (e === 'ok') {
        postApi.execute(postForm)
      }
    })
  }

  useEffectSkipFirst(() => {
    postForm.resetForm()
  }, [props.modalSet.item, props.extensionClass])

  useEffectSkipFirst(() => {
    if (!postApi.isSuccess()) return
    postForm.resetForm()
    props.onComplete()
  }, [postApi.loading])

  useEffectSkipFirst(() => {
    const tmpAttrVals = postForm.object.exAttributeValues?.map((exAttr) => {
      return { ...exAttr, isUpdate: Boolean(exAttr.exColumnId && checkboxSet.checkedIds.includes(exAttr.exColumnId)) }
    })
    const newFormObject = { ...postForm.object, exAttributeValues: tmpAttrVals }
    postForm.set(newFormObject)
  }, [checkboxSet.checkedIds])

  return (
    <Modal
      containerStyle={{ width: 760 }}
      modalId="ex-attributes-bulk-dialog"
      modalState={props.modalSet.modalState}
      title={`${props.extensionClass === 'InstrumentInfoAttribute' ? '製品' : '校正'}管理情報の一括更新`}
      footer={
        <>
          <ThemedButton color="secondary" onClick={props.modalSet.closeModal}>
            閉じる
          </ThemedButton>
          {props.exColumns.length !== 0 && (
            <ThemedButton color="success" onClick={handleSubmit}>
              登録
            </ThemedButton>
          )}
        </>
      }
    >
      {props.exColumns.length === 0 ? (
        <>{props.extensionClass === 'InstrumentInfoAttribute' ? '製品' : '校正'}管理情報が設定されていません</>
      ) : (
        <>
          <div style={{ padding: 10, paddingBottom: 5, marginBottom: 5 }}>
            <p>チェックした項目のみ入力・編集できます</p>
          </div>
          <div style={{ padding: 10, backgroundColor: '#FFF2CC', marginBottom: 16 }}>
            <p>既に{props.extensionClass === 'InstrumentInfoAttribute' ? '製品' : '校正'}管理情報が登録されている場合は上書きされます。</p>
            <p>必須項目をチェック＆入力しない場合にも、一括更新が可能です。</p>
          </div>
          {sharedExColumns.length > 0 && (
            <>
              <BulkUpdateDialogForm
                exColumns={sharedExColumns.filter((c) => !c.stashed)}
                form={postForm}
                api={postApi}
                checkboxSet={checkboxSet}
              />
              {sharedExColumns.filter((c) => c.stashed).length > 0 && (
                <CheckboxAccordion label="詳細設定" defaultChecked={false}>
                  <BulkUpdateDialogForm
                    exColumns={sharedExColumns.filter((c) => c.stashed)}
                    form={postForm}
                    offset={sharedExColumns.filter((c) => !c.stashed).length}
                    api={postApi}
                    checkboxSet={checkboxSet}
                  />
                </CheckboxAccordion>
              )}
            </>
          )}
          {clientExColumns.length > 0 && (
            <>
              <div style={{ fontSize: 18, fontWeight: 'bold', margin: '15px 0' }}>顧客別管理情報</div>
              <BulkUpdateDialogForm
                exColumns={clientExColumns.filter((c) => !c.stashed)}
                form={postForm}
                api={postApi}
                checkboxSet={checkboxSet}
              />
              {clientExColumns.filter((c) => c.stashed).length > 0 && (
                <CheckboxAccordion label="詳細設定" defaultChecked={false}>
                  <BulkUpdateDialogForm
                    exColumns={clientExColumns.filter((c) => c.stashed)}
                    form={postForm}
                    offset={clientExColumns.filter((c) => !c.stashed).length}
                    api={postApi}
                    checkboxSet={checkboxSet}
                  />
                </CheckboxAccordion>
              )}
            </>
          )}
        </>
      )}
    </Modal>
  )
}
