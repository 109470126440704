import DropDownCheckbox from 'components/shared/dropdown_checkbox'
import React from 'react'
import { ProofreadEventFilterSet } from 'utils/hooks/use_proofread_event_filter'

type InstrumentInfoFilterTableHeaderProps = {
  proofreadEventFilterSet: ProofreadEventFilterSet
}
export const InstrumentInfoFilterTableHeader = ({ proofreadEventFilterSet }: InstrumentInfoFilterTableHeaderProps) => {
  const { searchForm, handleSearch, makersOptions, modelNumbersOptions, manageNumbersOptions } = proofreadEventFilterSet

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      製品名
      <DropDownCheckbox
        form={searchForm}
        attr={['q', 'proofreadInfoInstrumentMakerIn']}
        name="makers"
        items={makersOptions()}
        title="メーカー"
        onSubmit={handleSearch}
        btnStyle={{ padding: '4px 8px' }}
        isLeft={true}
        showFilter
        autocomplete
        containerStyle={{ marginLeft: 10 }}
      />
      <DropDownCheckbox
        form={searchForm}
        attr={['q', 'proofreadInfoInstrumentModelNumberIn']}
        name="modelNumbers"
        items={modelNumbersOptions()}
        title="型番"
        onSubmit={handleSearch}
        btnStyle={{ padding: '4px 8px' }}
        isLeft={true}
        showFilter
        autocomplete
      />
      <DropDownCheckbox
        form={searchForm}
        attr={['q', 'proofreadInfoInstrumentManageNumberIn']}
        name="manageNumbers"
        items={manageNumbersOptions()}
        title="管理番号"
        onSubmit={handleSearch}
        btnStyle={{ padding: '4px 8px' }}
        isLeft={true}
        showFilter
        autocomplete
      />
    </div>
  )
}
