import React, { useEffect, useContext, useState } from 'react'
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom'
import { useForm } from 'rac'
import './index.scss'
import { AuthorizationForm, OnetimePasswordForm, useAdminAuthorizationApi, useAdminOnetimePasswordApi } from 'api/admin/authorization'
import LoginForm from 'components/shared/login/login_form'
import OnetimePassword from 'components/shared/login/onetime_password'
import { GlobalStateContext } from 'contexts/global_state_context'
import { ReactRouterLocation } from 'entities/react_router'
import { StaticContext, useLocation } from 'react-router'
import { AdminRouteHelper } from 'routes/admin'
import CookieManager from 'utils/cookie_util'

type LoginPageProps = {}
type Props = RouteComponentProps<{}, StaticContext, LoginPageProps>

const LoginPage: React.FC<Props> = (props) => {
  const api = useAdminAuthorizationApi()
  const globalState = useContext(GlobalStateContext)
  const form = useForm<AuthorizationForm>({ email: '', password: '' })

  const onetimeApi = useAdminOnetimePasswordApi()
  const [open, setOpen] = useState(false)
  const onetimeForm = useForm<OnetimePasswordForm>({ email: '', onetimeToken: '', onetimePassword: '' })

  const location = useLocation() as ReactRouterLocation
  /** ログイン後のリダイレクト先 */
  const redirectUrlAfterLogin = location.state?.from.pathname || '/admin/laboratories'

  useEffect(() => {
    globalState.setLoading(api.loading)
    if (!api.isSuccess()) return

    if (api.response.accessToken) {
      CookieManager.saveAdminToken(api.response.accessToken)
      props.history.replace(redirectUrlAfterLogin)
    } else if (api.response.onetimeToken) {
      onetimeForm.newUpdateObject('onetimeToken', api.response.onetimeToken)
      setOpen(true)
    }
  }, [api.loading])

  useEffect(() => {
    globalState.setLoading(onetimeApi.loading)
    if (!api.isSuccess()) return

    if (onetimeApi.response.accessToken) {
      CookieManager.saveAdminToken(onetimeApi.response.accessToken)
      props.history.push('/admin/laboratories')
    }
  }, [onetimeApi.loading])

  const handleClickLoginButton = () => {
    onetimeForm.newUpdateObject('email', form.object.email)
    api.execute(form)
  }

  const handleClickOnetimeLoginButton = () => {
    onetimeApi.execute(onetimeForm)
  }

  if (CookieManager.hasAdminToken()) return <Redirect to={{ pathname: AdminRouteHelper.laboratoryIndex() }} />

  return (
    <div className="login">
      <OnetimePassword open={open} setOpen={setOpen} form={onetimeForm} handleClick={handleClickOnetimeLoginButton} />
      <LoginForm form={form} handleClick={handleClickLoginButton} />
    </div>
  )
}

export default withRouter(LoginPage)
