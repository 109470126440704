import PersonIcon from '@mui/icons-material/Person'
import { Avatar } from '@mui/material'
import { Inquiry } from 'entities/inquiry'
import moment from 'moment'
import React from 'react'

import { ViewModel } from './index'

export class InquiryViewModel extends ViewModel<Inquiry> {
  public sentAtText(): string {
    return moment(this.object.sentAt).format('YYYY/MM/DD HH:mm')
  }

  public isCurrent(currentId: number): boolean {
    return this.object?.id === currentId
  }

  public getIcon() {
    return this.object.icon ? (
      <Avatar src={this.object.icon} />
    ) : (
      <Avatar>
        <PersonIcon />
      </Avatar>
    )
  }

  public inquireablePath(): string {
    switch (this.object.inquireableType) {
      case 'Estimate':
        return `/mypage/estimates/${this.object.inquireableId}`
      case 'OrderRequest':
        return `/mypage/order_requests/${this.object.inquireableId}`
      case 'Order':
        return `/mypage/orders/${this.object.inquireableId}`
      default:
        return ''
    }
  }

  public inquireableNumber(): string {
    switch (this.object.inquireableType) {
      case 'Estimate':
        return `見積No. ${this.object?.inquireableNumber}`
      case 'OrderRequest':
        return `発注No. ${this.object?.inquireableNumber}`
      case 'Order':
        return `発注No. ${this.object?.inquireableNumber}`
      default:
        return ''
    }
  }

  public dealerName(): string {
    return this.object.dealerName || '-'
  }

  public labWithSenderName(): string {
    return this.object.labWithSenderName || '-'
  }
}
