import { Paper, TableContainer, TableHead, Box, Table, TableRow, TableCell } from '@mui/material'
import { usePartnerFetchDealerDivisionsApi } from 'api/partner/dealer_divisions'
import { DealerDivisionLaboratoryRelationModal } from 'components/partner/setting/dealer_division_laboratory_relation_modal'
import { CreateDealerDivisionModal, UpdateDealerDivisionModal } from 'components/partner/setting/dealer_division_modal'
import { Flex } from 'components/shared/flex'
import { useModal, useMultipleModal } from 'components/shared/modal'
import { ExTableBody } from 'components/shared/table/table_body/ex_table_body'
import { ExTableCell } from 'components/shared/table/table_cell/ex_table_cell'
import ThemedButton from 'components/shared/themed_button'
import { PartnerStateContext } from 'contexts/partner_state_context'
import { DealerDivision } from 'entities/dealer_division'
import React, { useEffect, useContext } from 'react'

export const DealerDivisionListBox = () => {
  const fetchApi = usePartnerFetchDealerDivisionsApi()
  const { currentDealerUser } = useContext(PartnerStateContext)
  const labRelationModal = useMultipleModal<DealerDivision>()
  const updateDivisionModal = useMultipleModal<DealerDivision>()
  const createDivisionModal = useModal()

  useEffect(() => {
    fetchApi.execute()
  }, [])

  const handleCompleteDealerDivisionLaboratoryRelation = () => {
    labRelationModal.closeModal()
    fetchApi.execute()
  }

  const handleCompleteCreateDealerDivision = () => {
    createDivisionModal.setOpen(false)
    fetchApi.execute()
  }

  const handleCompleteUpdateDealerDivision = () => {
    updateDivisionModal.closeModal()
    fetchApi.execute()
  }

  return (
    <>
      <Box>
        <Box display="flex" alignItems="center" marginBottom={1}>
          <h3 style={{ marginRight: '10px' }}>部署</h3>
          {currentDealerUser.authority?.privileged && (
            <>
              <CreateDealerDivisionModal modalState={createDivisionModal} onComplete={handleCompleteCreateDealerDivision} />
              <UpdateDealerDivisionModal modalSet={updateDivisionModal} onComplete={handleCompleteUpdateDealerDivision} />
              <DealerDivisionLaboratoryRelationModal
                modalSet={labRelationModal}
                onComplete={handleCompleteDealerDivisionLaboratoryRelation}
              />
              <ThemedButton color="primary" onClick={() => createDivisionModal.setOpen(true)}>
                部署登録
              </ThemedButton>
            </>
          )}
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>部署名</TableCell>
                <TableCell>担当顧客</TableCell>
                <ExTableCell />
              </TableRow>
            </TableHead>

            <ExTableBody loading={fetchApi.loading}>
              {fetchApi.response?.dealerDivisions.map((division, idx) => {
                return (
                  <TableRow key={idx}>
                    <TableCell>{division.name}</TableCell>
                    <TableCell width={600}>
                      {(division.laboratories || []).map((lab, idx) => (
                        <span key={`lab-${idx}`}>
                          {lab.name}
                          {idx + 1 === division.laboratories?.length ? '' : ' / '}
                        </span>
                      ))}
                      <br />
                      {(division.outsideLaboratories || []).map((lab, idx) => (
                        <span key={`lab-${idx}`}>
                          {lab.name}
                          {idx + 1 === division.outsideLaboratories?.length ? '' : ' / '}
                        </span>
                      ))}
                    </TableCell>
                    <ExTableCell width={200}>
                      {currentDealerUser.authority?.privileged && (
                        <Flex>
                          <ThemedButton
                            style={{ width: 'max-content' }}
                            color="secondary"
                            onClick={() => labRelationModal.openModal(division)}
                          >
                            担当顧客設定
                          </ThemedButton>
                          <ThemedButton
                            style={{ width: 'max-content' }}
                            color="secondary"
                            onClick={() => updateDivisionModal.openModal(division)}
                          >
                            編集
                          </ThemedButton>
                        </Flex>
                      )}
                    </ExTableCell>
                  </TableRow>
                )
              })}
            </ExTableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}
