import { Paper } from '@mui/material'
import { usePartnerFetchInstrumentsApi } from 'api/partner/instrument'
import { InstrumentTableWithBarcode } from 'components/partner/instrument/instrument_table_with_barcode'
import { CheckboxFooter } from 'components/shared/checkbox_footer'
import { ContentHeader, ContentTitle } from 'components/shared/content'
import { Flex } from 'components/shared/flex'
import { SearchBar } from 'components/shared/search_bar'
import { DisplaySettingModal } from 'components/shared/table/display_setting'
import ThemedButton from 'components/shared/themed_button'
import { InstrumentSearchForm } from 'entities/instrument'
import { useForm } from 'rac'
import React, { useEffect } from 'react'
import { StaticContext } from 'react-router'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { useCheckboxes, useDisplaySetting } from 'utils/hooks/index'

const initialDisplaySettings = [
  { attr: 'createdAt', name: '登録日', visible: true },
  { attr: 'maker', name: 'メーカー', visible: true },
  { attr: 'outsideLaboratory', name: '顧客', visible: true },
  { attr: 'dealerDivision', name: '部署', visible: true },
]

type BarcodeIndexProps = {}

type Props = RouteComponentProps<{}, StaticContext, BarcodeIndexProps>

const BarcodeIndex = (_props: Props) => {
  const searchForm = useForm<InstrumentSearchForm>({ q: {} })
  const instrumentIndexApi = usePartnerFetchInstrumentsApi(searchForm)
  const displaySettingSet = useDisplaySetting(initialDisplaySettings, 'partner-instruments-table')
  const checkboxSet = useCheckboxes(instrumentIndexApi.response.instruments)

  useEffect(() => {
    instrumentIndexApi.execute()
  }, [])

  const handleSearch = () => {
    instrumentIndexApi.executeAndPageReset()
  }

  const handleJumpPreviewPage = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    window.location.href = `${window.location.protocol}//${window.location.host}/partner/barcodes/preview?ids=${checkboxSet.checkedIds}`
  }

  const tableProps = { displaySettingSet, instrumentIndexApi, checkboxSet, searchForm, onSearch: handleSearch }

  return (
    <>
      <ContentHeader subHeader={<DisplaySettingModal displaySettingSet={displaySettingSet} />}>
        <ContentTitle title="QRコード一覧" />
        <Flex alignItems="center">
          <SearchBar
            form={searchForm}
            searchAttr="searchText"
            placeholder="メーカー・製品名・型番・シリアル番号・管理番号で検索"
            onSubmit={handleSearch}
          />
        </Flex>
      </ContentHeader>
      <Paper>
        <InstrumentTableWithBarcode {...tableProps} />
      </Paper>
      <CheckboxFooter
        checkboxSet={checkboxSet}
        content={
          <ThemedButton color="primary" onClick={(e) => handleJumpPreviewPage(e)} style={{ marginRight: 12 }}>
            印刷ページへ
          </ThemedButton>
        }
      />
    </>
  )
}

export default withRouter(BarcodeIndex)
