import CircularProgress from '@mui/material/CircularProgress'
import React from 'react'

import { Flex } from './flex'

const LoadingProgress = () => {
  return (
    <Flex justifyContent="center" alignItems="center" style={{ margin: '0 10px' }}>
      <CircularProgress color="inherit" />
    </Flex>
  )
}

export default LoadingProgress
