import { AxiosHttpClient } from 'api/axios'
import { useRepruaIndexApi, useRepruaPostApi } from 'api/shared_api_hooks'
import { ID } from 'entities'
import { Reservation, ReservationForm, ReservationSearchForm, ReservationsResponse } from 'entities/reservation'
import { IndexApiSet, ApiSet, Form, BaseResponse } from 'rac'

/**
 * 一覧
 */
export function useFetchInstrumentReservationsApi(
  instrumentId: ID,
): IndexApiSet<ReservationsResponse> & { execute: (form: Form<ReservationSearchForm>) => void } {
  const api = useRepruaIndexApi<ReservationsResponse, ReservationSearchForm>(new AxiosHttpClient('user'), {
    initialResponse: { reservations: [], totalCount: 0, managingLaboratoryIds: [] },
  })

  const execute = (form: Form<ReservationSearchForm>) => {
    const path = `instruments/${instrumentId}/reservations`
    api.execute(path, { params: form.object })
  }

  return { ...api, execute }
}

type ReservationResponse = BaseResponse & {
  reservation: Reservation
}

/**
 * 登録
 */
export function usePostInstrumentReservationApi(): ApiSet<ReservationResponse> & {
  execute: (instrumentId: ID, reservationForm: Form<ReservationForm>) => void
} {
  const api = useRepruaPostApi<ReservationResponse, ReservationForm>(new AxiosHttpClient('user'), { initialResponse: { reservation: {} } })

  const execute = (instrumentId: ID, reservationForm: Form<ReservationForm>) => {
    const path = `instruments/${instrumentId}/reservations`
    api.execute(path, reservationForm)
  }

  return { ...api, execute }
}
