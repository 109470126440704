import { MutationErrorResponse } from 'api/MutationErrorResponse'
import { GlobalStateContext } from 'contexts/global_state_context'
import { BaseResponse } from 'rac'
import { useContext, useEffect } from 'react'
import { UseMutationResult } from 'react-query'

/**
 * mutationを利用してAPIをコールした際に返却されるメッセージを画面上に表示させるためのカスタムフックス
 *
 * @param mutation useMutation の返却値
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useMutationMessageHandler = <T extends BaseResponse>(mutation: UseMutationResult<T, MutationErrorResponse, any, any>) => {
  const globalState = useContext(GlobalStateContext)

  useEffect(() => {
    if (mutation.isSuccess && mutation.data?.message) {
      globalState.setNotificationMessage({ body: mutation.data.message })
    } else if (mutation.isError) {
      const errorMessage = mutation.error.response.data.messages
      if (errorMessage) {
        globalState.setNotificationMessage({ body: errorMessage, colorType: 'error' })
      }
    }
  }, [mutation.isLoading])
}
