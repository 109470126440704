import { BaseEntity } from 'entities/index'
import { InquiryComment } from 'entities/inquiry_comment'
import { InquiryMember } from 'entities/inquiry_member'
import { SearchQuery } from 'rac'


export type Inquiry = BaseEntity & {
  id?: number
  userId?: number
  dealerId?: number
  dealerName?: string
  labWithSenderName?: string
  icon?: string
  subject?: string
  sentAt?: string
  comments?: InquiryComment[]
  members?: InquiryMember[]
  inquireableType?: string
  inquireableId?: number
  inquireableNumber?: string
}

export type InquiryForm = Inquiry & {
  body?: string
  dealerIds?: number[]
}

export const inquiryValidation = {
  subject: { required: '件名が未入力です' },
  body: { required: 'お問合せ内容が未入力です' },
  dealerIds: { required: 'ディーラーが未選択です' },
}

export type InquirySearchForm = {
  searchText?: string
}

export const getLaboratoryInquirySearchQuery = (searchText?: string): SearchQuery => ({
  suffix: 'Cont',
  searchText: searchText || '',
  attrs: ['subject', ['comments', { attrs: ['body'] }], ['dealer', { attrs: ['name'] }]],
})

export const getDealerInquirySearchQuery = (searchText?: string): SearchQuery => ({
  suffix: 'Cont',
  searchText: searchText || '',
  attrs: ['subject', ['comments', { attrs: ['body'] }], ['user', { attrs: ['familyName', 'givenName', 'laboratoryName'] }]],
})
