import { Paper } from '@mui/material'
import { Flex } from 'components/shared/flex'
import { CheckBoxField, NumberField } from 'components/shared/form/input'
import ThemedButton from 'components/shared/themed_button'
import { PrintSetting, PrintSettingCheckItem, PrintSettingForm } from 'entities/print_setting'
import { Form } from 'rac'
import React, { useEffect } from 'react'
import { makeStyles } from 'tss-react/mui'

const inputProps = { step: '0.1' }

const useStyles = makeStyles()({
  formHead: {
    fontWeight: 'bold',
    fontSize: 18,
    marginBottom: 20,
  },
})

type BarcodePrintSettingFormProps = {
  settingForm: Form<PrintSettingForm>
  checkItems: PrintSettingCheckItem[]
  recommendSmallSetting: PrintSetting
  recommendLargeSetting: PrintSetting
  onSubmit: () => void
}

export const BarcodePrintSettingForm = ({
  settingForm,
  checkItems,
  recommendSmallSetting,
  recommendLargeSetting,
  onSubmit,
}: BarcodePrintSettingFormProps) => {
  const { classes } = useStyles()

  return (
    <Paper className="setting-form" style={{ padding: 20, marginBottom: 20, width: '210mm' }}>
      <div className={classes.formHead}>ページ設定</div>
      <Flex style={{ marginBottom: 10 }}>
        <NumberField inputProps={{ min: 1 }} label="1ページあたりの表示数" attr="perPage" form={settingForm} />
        <NumberField inputProps={{ min: 0 }} label="オフセット" attr="offset" form={settingForm} />
      </Flex>
      <Flex style={{ marginBottom: 15 }}>
        <NumberField label="上下余白（mm）" attr="pagePaddingTb" form={settingForm} inputProps={inputProps} />
        <NumberField label="左右余白（mm）" attr="pagePaddingLr" form={settingForm} inputProps={inputProps} />
      </Flex>
      <Flex style={{ marginBottom: 15 }}>
        <NumberField label="ラベル間上下余白（mm）" attr="gapTb" form={settingForm} inputProps={inputProps} />
        <NumberField label="ラベル間左右余白（mm）" attr="gapLr" form={settingForm} inputProps={inputProps} />
      </Flex>
      <Flex style={{ marginBottom: 20 }}>
        <ThemedButton color="secondary" onClick={() => settingForm.set(recommendSmallSetting)}>
          推奨設定(小)
        </ThemedButton>
        <ThemedButton color="secondary" onClick={() => settingForm.set(recommendLargeSetting)}>
          推奨設定(大)
        </ThemedButton>
      </Flex>
      <div className={classes.formHead}>QRコード設定</div>
      <Flex style={{ marginBottom: 10 }}>
        <NumberField label="幅（mm）" attr="width" form={settingForm} inputProps={inputProps} />
        <NumberField label="高さ（mm）" attr="height" form={settingForm} inputProps={inputProps} />
      </Flex>
      <Flex style={{ marginBottom: 10 }}>
        <NumberField label="上下余白（mm）" attr="paddingTb" form={settingForm} inputProps={inputProps} />
        <NumberField label="左右余白（mm）" attr="paddingLr" form={settingForm} inputProps={inputProps} />
      </Flex>
      <Flex style={{ marginBottom: 10 }}>
        <NumberField label="画像サイズ（%）" attr="imageScale" form={settingForm} inputProps={inputProps} />
        <NumberField label="フォントサイズ（%）" attr="fontSize" form={settingForm} inputProps={inputProps} />
      </Flex>
      <Flex style={{ marginBottom: 10 }}>
        <CheckBoxField form={settingForm} attr="showBorder" label="罫線表示" />
      </Flex>
      <div className={classes.formHead} style={{ marginBottom: 5 }}>
        表示項目
      </div>
      <Flex flexWrap="wrap" style={{ width: '80%' }}>
        {checkItems.map((item, i) => {
          return <CheckBoxField key={i} form={settingForm} attr={item.key} label={item.label} />
        })}
      </Flex>
      <Flex justifyContent="end">
        <ThemedButton color="primary" variant="outlined" onClick={onSubmit} style={{ marginRight: 10 }}>
          設定を保存
        </ThemedButton>
        <ThemedButton color="primary" onClick={() => window.print()}>
          印刷
        </ThemedButton>
      </Flex>
    </Paper>
  )
}
