import { AxiosHttpClient, HttpClientType } from 'api/axios'
import { useRepruaDeleteApi } from 'api/shared_api_hooks'
import { ID } from 'entities'
import { BaseResponse } from 'rac'

type AttachablePath = 'instruments' | 'defect_reports' | 'repair_estimates' | 'repair_events' | 'proofread_events'

/**
 * 一覧
 */
export const useDeleteAttachmentsApi = (clientType: HttpClientType, attachablePath: AttachablePath) => {
  const api = useRepruaDeleteApi<BaseResponse>(new AxiosHttpClient(clientType), {
    initialResponse: {},
  })

  const execute = (attachableId: ID, attachmentId: ID) => {
    const path = `${attachablePath}/${attachableId}/attachments/${attachmentId}`
    api.execute(path)
  }

  return { ...api, execute }
}
