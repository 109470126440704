import React from 'react'
import { makeStyles } from 'tss-react/mui'

type StatusLabelProps = {
  text: string
  color: string
  style?: React.CSSProperties
}

export const StatusLabel: React.FC<StatusLabelProps> = (props: StatusLabelProps) => {
  const { classes } = useStyles({ color: props.color })
  return (
    <div className={classes.root} style={props.style}>
      {props.text}
    </div>
  )
}

const useStyles = makeStyles<{ color: string }>()((_, { color }) => ({
  root: {
    border: '2px solid',
    borderRadius: 4,
    borderColor: color,
    padding: '0 3px',
    color: color,
    textAlign: 'center',
    display: 'inline-block',
  },
}))

export default StatusLabel
