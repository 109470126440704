import { AxiosHttpClient } from 'api/axios'
import { GlobalStateContext } from 'contexts/global_state_context'
import { snakeCase } from 'lodash'
import {
  ApiArgument,
  ApiSet,
  Form,
  IHttpClient,
  IndexApiSet,
  IndexApiState,
  useDeleteApi,
  useIndexApi,
  usePatchApi,
  usePostApi,
  useShowApi,
  BaseResponse,
  SearchQuery,
} from 'rac'
import { useContext, useEffect } from 'react'
import { useEffectSkipFirst } from 'utils/hooks/use_effect_skip_first'

/**
 * IndexでつかうApiSetを返す
 */
export function useRepruaIndexApi<T extends BaseResponse, U>(
  httpClient: IHttpClient,
  props: {
    initialResponse: T
    initialState?: Partial<IndexApiState>
    params?: U
  },
): IndexApiSet<T> & {
  execute: (path: string, options?: { params?: U; searchQuery?: SearchQuery }) => void
} {
  const globalState = useContext(GlobalStateContext)
  const api = useIndexApi<T, U>(httpClient, {
    initialResponse: props.initialResponse,
    initialState: props.initialState,
    params: props.params,
  })

  useEffectSkipFirst(() => {
    globalState.setStatusCode(api.statusCode)
    if (api.isError) {
      globalState.setError(api.apiError)
    }
  }, [api.loading])

  return api
}

export function useRepruaShowApi<T extends BaseResponse, U>(
  httpClient: IHttpClient,
  props: ApiArgument<T>,
): ApiSet<T> & { execute: (apiPath: string, params?: U) => void } {
  const globalState = useContext(GlobalStateContext)
  const api = useShowApi<T, U>(httpClient, props)

  useEffectSkipFirst(() => {
    globalState.setStatusCode(api.statusCode)
    if (api.isSuccess() && api.response.message) {
      globalState.setNotificationMessage({ body: api.response.message, colorType: 'success' })
    }
  }, [api.loading])

  return api
}

export function useRepruaPostApi<T extends BaseResponse, U>(
  httpClient: IHttpClient,
  props: ApiArgument<T>,
): ApiSet<T> & { execute: (apiPath: string, form?: Form<U>) => void } {
  const globalState = useContext(GlobalStateContext)
  const api = usePostApi<T, U>(httpClient, props)

  useEffectSkipFirst(() => {
    globalState.setStatusCode(api.statusCode)
    if (api.isSuccess() && api.response.message) {
      globalState.setNotificationMessage({ body: api.response.message, colorType: 'success' })
    } else if (!api.isSuccess()) {
      globalState.setError(api.apiError)
    }
  }, [api.loading])

  return api
}

export function useRepruaPatchApi<T extends BaseResponse, U>(
  httpClient: IHttpClient,
  props: ApiArgument<T>,
): ApiSet<T> & { execute: (apiPath: string, params?: U) => void } {
  const api = usePatchApi<T, U>(httpClient, props)
  const globalState = useContext(GlobalStateContext)

  useEffectSkipFirst(() => {
    globalState.setStatusCode(api.statusCode)
    if (api.isSuccess() && api.response.message) {
      globalState.setNotificationMessage({ body: api.response.message, colorType: 'success' })
    } else if (!api.isSuccess()) {
      globalState.setError(api.apiError)
    }
  }, [api.loading])

  return api
}

export function useRepruaDeleteApi<T extends BaseResponse>(
  httpClient: IHttpClient,
  props: ApiArgument<T>,
): ApiSet<T> & { execute: (apiPath: string) => void } {
  const api = useDeleteApi<T>(httpClient, props)
  const globalState = useContext(GlobalStateContext)

  useEffectSkipFirst(() => {
    globalState.setStatusCode(api.statusCode)
    if (api.isSuccess() && api.response.message) {
      globalState.setNotificationMessage({ body: api.response.message, colorType: 'success' })
    } else if (!api.isSuccess()) {
      globalState.setError(api.apiError)
    }
  }, [api.loading])

  return api
}

export type DownloadApi<T> = {
  execute: (path: string, options?: { params?: T; searchQuery?: SearchQuery }) => void
}
export function useRepruaDownloadApi<T>(httpClient: AxiosHttpClient): DownloadApi<T> {
  const execute = (path: string, options?: { params?: T; searchQuery?: SearchQuery }) => {
    let params: any = {}
    if (options?.params) {
      // 追加でparamsの指定がある場合
      if (options.searchQuery) {
        params = {
          ...params,
          ...options.params,
          searchQuery: parseSearchQuery(options.searchQuery),
        }
      } else {
        params = { ...params, ...options.params }
      }
      params.q = {
        ...params.q,
      }
    }

    httpClient.download(path, params)
  }
  return { execute }
}

type SearchQueryAttributes = (string | SearchQueryTuple)[]
type SearchQueryTuple = [string, { attrs: SearchQueryAttributes; polymorphicType?: string }]
const parseSearchQuery = (query: SearchQuery) => {
  const queryKeys: string[] = []
  const modelNameStack: string[] = []

  const pushKey = (attrs: SearchQueryAttributes) => {
    attrs.forEach((attr) => {
      if (typeof attr === 'string') {
        queryKeys.push(modelNameStack.join('') + snakeCase(attr))
      } else if (isSearchQueryTuple(attr)) {
        const polymorphicType = attr[1].polymorphicType
        if (polymorphicType) {
          const polymorphicModelName = snakeCase(attr[0]) + '_of_' + polymorphicType + '_type'
          modelNameStack.push(polymorphicModelName + '_')
        } else {
          modelNameStack.push(snakeCase(attr[0]) + '_')
        }
        pushKey(attr[1].attrs)
        modelNameStack.pop()
      }
    })
  }

  pushKey(query.attrs)
  const queryKey = queryKeys.join('_or_') + '_' + snakeCase(query.suffix)

  return {
    [queryKey]: query.searchText,
  }
}
const isSearchQueryTuple = (arg: any): arg is SearchQueryTuple => {
  return arg[0] !== undefined && arg[1] !== undefined
}
