import { AxiosHttpClient } from 'api/axios'
import { useRepruaIndexApi } from 'api/shared_api_hooks'
import { PagingResponse } from 'entities'
import { User } from 'entities/user'

export type UsersResponse = PagingResponse & {
  users: User[]
}
export const usePartnerIndexLaboratoryUsersApi = () => {
  const api = useRepruaIndexApi<UsersResponse, {}>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { users: [], totalCount: 0 },
  })

  const execute = (laboratoryId: number) => {
    const path = `laboratories/${laboratoryId}/users`
    api.execute(path, { params: { onlyProofreadbale: 1 } })
  }

  return { ...api, execute }
}