import { Slide } from '@mui/material'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

import styles from '../../styles/style.module.scss'

type FooterProps = {
  show: boolean
  children: React.ReactNode
}
export const SlideFooter = ({ children, show }: FooterProps) => {
  const { classes } = useStyle()
  return (
    <Slide direction="up" in={show}>
      <div className={classes.root}>
        <div className={classes.inner}>{children}</div>
      </div>
    </Slide>
  )
}

const useStyle = makeStyles()(() => ({
  root: {
    position: 'fixed',
    left: styles.drawerWidth,
    bottom: 0,
    width: '100%',
    height: 80,
    backgroundColor: '#fff',
    borderTop: '2px solid #ccc',
  },
  inner: {
    display: 'flex',
    padding: 16,
  },
}))
