import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { Table, TableHead, TableRow, Paper, TableContainer, Link } from '@mui/material'
import { usePartnerFetchDealerDivisionsApi } from 'api/partner/dealer_divisions'
import { usePartnerExportInstrumentsApi, usePartnerFetchInstrumentsApi } from 'api/partner/instrument'
import { usePartnerFetchInstrumentCategoriesApi } from 'api/partner/instrument_categories'
import { usePostExportInstrumentSharing } from 'api/partner/instruments/instrument_sharing_export'
import { usePartnerFetchLaboratoriesApi } from 'api/partner/laboratories'
import { usePartnerFetchOutsideLaboratoriesApi } from 'api/partner/outside_laboratories'
import { useFetchExColumnsApi } from 'api/shared/ex_columns'
import { NewDefectReportFormDialog } from 'components/partner/defect_report/defect_report_form_dialog'
import { NewInstrumentFormDialog, EditInstrumentFormDialog } from 'components/partner/instrument/instrument_form_dialog'
import { NewRepairEventFormDialog } from 'components/partner/repair_event/repair_event_form_dialog'
import { CheckboxFooter } from 'components/shared/checkbox_footer'
import { ContentHeader, ContentTitle } from 'components/shared/content'
import DropDownCheckbox from 'components/shared/dropdown_checkbox'
import { Flex } from 'components/shared/flex'
import { DropDownCheckboxItem } from 'components/shared/form/input'
import ImageView from 'components/shared/image_view'
import { useMultipleModal } from 'components/shared/modal'
import Pagination from 'components/shared/pagination'
import { SearchBar } from 'components/shared/search_bar'
import {
  ExTableCell,
  OrderTableCell,
  AllCheckboxTableCell,
  CheckboxTableCell,
  ExTableBody,
} from 'components/shared/table'
import { DisplaySettingModal } from 'components/shared/table/display_setting'
import { TableExAttributesInfo } from 'components/shared/table_ex_attributes_info'
import ThemedButton from 'components/shared/themed_button'
import { PartnerStateContext } from 'contexts/partner_state_context'
import { Instrument, InstrumentSearchForm } from 'entities/instrument'
import { IndexApiState, Order, useForm } from 'rac'
import React, { useContext, useEffect, useMemo } from 'react'
import { StaticContext } from 'react-router'
import { withRouter, RouteComponentProps, useLocation } from 'react-router-dom'
import { PartnerRouteHelper } from 'routes/partner'
import { INDEX_TABLE_PADDING, getTableHeight } from 'utils/component_heights'
import { useCheckboxes, useDisplaySetting, useEffectSkipFirst } from 'utils/hooks/index'
import { booleanFilterOptions, booleanFilterValues } from 'utils/utils'
import { InstrumentViewModel } from 'view_models/instrument'

import './index.scss'

const initialDisplaySettings = [
  { attr: 'createdAt', name: '登録日', visible: true },
  { attr: 'maker', name: 'メーカー', visible: true },
  { attr: 'outsideLaboratory', name: '顧客', visible: true },
  { attr: 'dealerDivision', name: '部署', visible: true },
]

export type InstrumentIndexProps = {
  roomId?: number
}

type Props = RouteComponentProps<undefined, StaticContext, InstrumentIndexProps>

const InstrumentIndex: React.FC<Props> = (props: Props) => {
  const partnerState = useContext(PartnerStateContext)
  const search = useLocation().search
  const query = new URLSearchParams(search)

  const searchForm = useForm<InstrumentSearchForm>({
    q: {},
    searchText: query.get('q') || undefined,
    dealerDivisionIds: query.get('division')?.split(',') ?? undefined,
    requireProofreadFilter: query.get('proofread')?.split(',') ?? booleanFilterValues,
    sharingInstrumentFilter: query.get('sharing')?.split(',') ?? booleanFilterValues,
  })
  const initialState: Partial<IndexApiState> = {
    ...(query.get('order') && { order: query.get('order') as Order }),
    ...(query.get('order_by') && { orderBy: query.get('order_by') as string }),
    ...(query.get('page') && { page: Number(query.get('page')) }),
    ...(query.get('perPage') && { perPage: Number(query.get('perPage')) }),
  }
  const instrumentIndexApi = usePartnerFetchInstrumentsApi(searchForm, initialState)
  const [editDialogOpen, setEditDialogOpen] = React.useState(false)
  const [editInstrument, setEditInstrument] = React.useState<Instrument>({})
  const displaySettingSet = useDisplaySetting(initialDisplaySettings, 'partner-instrument-table')
  const categoryIndexApi = usePartnerFetchInstrumentCategoriesApi()
  const laboratoriesIndexApi = usePartnerFetchLaboratoriesApi({})
  const outsideLaboratoriesIndexApi = usePartnerFetchOutsideLaboratoriesApi({ useFilter: true })
  const dealerDivisionsIndexApi = usePartnerFetchDealerDivisionsApi(false)
  const exportApi = usePartnerExportInstrumentsApi(searchForm)
  const postExportInstrumentSharing = usePostExportInstrumentSharing()
  const newDefectReportModal = useMultipleModal<Instrument>()
  const newRepairEventModal = useMultipleModal<Instrument>()
  const exColumnApi = useFetchExColumnsApi('dealer_user')
  const checkboxSet = useCheckboxes(instrumentIndexApi.response?.instruments)

  useEffect(() => {
    instrumentIndexApi.execute()
    categoryIndexApi.execute()
    outsideLaboratoriesIndexApi.execute()
    laboratoriesIndexApi.execute()
    dealerDivisionsIndexApi.execute()
    exColumnApi.execute()
  }, [])

  useEffectSkipFirst(() => {
    pushHistory()
  }, [
    instrumentIndexApi.orderSet.order,
    instrumentIndexApi.orderSet.orderBy,
    instrumentIndexApi.pageSet.page,
    instrumentIndexApi.pageSet.perPage
  ])

  const handleCreate = () => {
    instrumentIndexApi.execute()
  }

  const handleUpdate = () => {
    instrumentIndexApi.execute()
    setEditDialogOpen(false)
  }

  const handleDelete = () => {
    instrumentIndexApi.execute()
    setEditDialogOpen(false)
  }

  const handleClickEditButton = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, instrument: Instrument) => {
    setEditInstrument(instrument)
    setEditDialogOpen(true)
    e.stopPropagation()
  }

  const handleSearch = () => {
    instrumentIndexApi.executeAndPageReset()
    pushHistory()
  }

  const handleClickDetailButton = (instrument: Instrument) => {
    props.history.push({
      pathname: PartnerRouteHelper.instrumentShow(instrument.id!),
      state: {},
    })
  }

  const handleClickExport = (format: 'xlsx') => {
    exportApi.execute(format)
  }

  const handleClickUnmanagedInstrumentsLink = () => {
    props.history.push({
      pathname: PartnerRouteHelper.unmanagedInstrumentIndex(),
      state: {},
    })
  }

  const handleClickExportInstrumentSharing = () => {
    postExportInstrumentSharing.mutate({
      instrumentIds: checkboxSet.checkedIds,
    })
  }

  // 検索条件をURLに保持
  const pushHistory = () => {
    const order = instrumentIndexApi.orderSet.order
    const orderBy = instrumentIndexApi.orderSet.orderBy
    const page = instrumentIndexApi.pageSet.page
    const perPage = instrumentIndexApi.pageSet.perPage
    const params = {
      ...(searchForm.object.searchText && { q: searchForm.object.searchText }),
      ...(searchForm.object.dealerDivisionIds && { division: searchForm.object.dealerDivisionIds?.join(',') }),
      ...(searchForm.object.requireProofreadFilter && { proofread: searchForm.object.requireProofreadFilter?.join(',') }),
      ...(searchForm.object.sharingInstrumentFilter && { sharing: searchForm.object.sharingInstrumentFilter?.join(',') }),
      ...((order && orderBy) && { order: order, order_by: orderBy }),
      ...(page && { page: page.toString() }),
      ...(perPage && { perPage: perPage.toString() })
    }
    const queryParams = new URLSearchParams(params).toString();
    props.history.push(`${window.location.pathname}?${queryParams}`)
  }

  const dealerDivisionOptions = useMemo(
    (): DropDownCheckboxItem[] => [
      { value: '', label: '未選択' },
      ...dealerDivisionsIndexApi.response.dealerDivisions.map((division) => ({
        value: division.id!.toString(),
        label: division.name!,
      })),
    ],
    [dealerDivisionsIndexApi.response.dealerDivisions],
  )

  return (
    <>
      <EditInstrumentFormDialog
        onComplete={handleUpdate}
        onCompleteExcludeManagement={handleDelete}
        instrument={editInstrument}
        open={editDialogOpen}
        onCancel={() => setEditDialogOpen(false)}
        instrumentCategories={categoryIndexApi.response?.instrumentCategories}
        outsideLaboratories={outsideLaboratoriesIndexApi.response?.outsideLaboratories}
        onCompleteRemoveImage={instrumentIndexApi.execute}
      />
      <NewDefectReportFormDialog
        modalState={newDefectReportModal.modalState}
        instrumentId={newDefectReportModal.item.id}
        onComplete={() => newDefectReportModal.closeModal()}
      />
      <NewRepairEventFormDialog
        instrumentId={newRepairEventModal.item.id}
        modalState={newRepairEventModal.modalState}
        onComplete={() => newRepairEventModal.closeModal()}
        onClose={() => newRepairEventModal.closeModal()}
      />
      <ContentHeader
        subHeader={
          <>
            <ThemedButton variant="text" color="primary" onClick={handleClickUnmanagedInstrumentsLink} style={{ marginRight: 10 }}>
              管理対象外の機器一覧
            </ThemedButton>
            <DisplaySettingModal displaySettingSet={displaySettingSet} />
            <ThemedButton color="secondary" onClick={() => handleClickExport('xlsx')} style={{ marginLeft: 10 }}>
              Excel出力
            </ThemedButton>
          </>
        }
      >
        <ContentTitle title="機器一覧">
          <NewInstrumentFormDialog
            onComplete={handleCreate}
            instrumentCategories={categoryIndexApi.response?.instrumentCategories}
            outsideLaboratories={outsideLaboratoriesIndexApi.response?.outsideLaboratories}
            laboratories={laboratoriesIndexApi.response?.laboratories}
          />
        </ContentTitle>
        <SearchBar<InstrumentSearchForm>
          form={searchForm}
          searchAttr="searchText"
          placeholder="メーカー・製品名・型番・シリアル番号・管理番号で検索"
          onSubmit={handleSearch}
        />
      </ContentHeader>
      <Paper>
        <TableContainer style={{ maxHeight: getTableHeight(INDEX_TABLE_PADDING) }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {partnerState.currentDealer.authority?.enableInstrumentSharing && <AllCheckboxTableCell checkboxSet={checkboxSet} />}
                <ExTableCell width={150}></ExTableCell>
                <OrderTableCell
                  width={120}
                  open={displaySettingSet.isVisible('createdAt')}
                  align="center"
                  orderAttr="createdAt"
                  orderSet={instrumentIndexApi.orderSet}
                >
                  登録日
                </OrderTableCell>
                <OrderTableCell
                  width={120}
                  open={displaySettingSet.isVisible('maker')}
                  orderAttr="maker"
                  orderSet={instrumentIndexApi.orderSet}
                >
                  メーカー
                </OrderTableCell>
                <ExTableCell width={100} align="center" open={displaySettingSet.isVisible('outsideLaboratory')}>
                  顧客
                </ExTableCell>
                <ExTableCell width={120} open={displaySettingSet.isVisible('dealerDivision')}>
                  <DropDownCheckbox
                    form={searchForm}
                    attr="dealerDivisionIds"
                    name="dealerDivisionIds"
                    items={dealerDivisionOptions}
                    title="部署"
                    onSubmit={handleSearch}
                    btnStyle={{ padding: '4px 8px' }}
                    isLeft={true}
                    maxWidth={600}
                    showFilter
                    autocomplete
                  />
                </ExTableCell>
                <OrderTableCell minWidth={200} orderAttr="name" orderSet={instrumentIndexApi.orderSet}>
                  <div>
                    型番 / シリアル番号 / 管理番号 / 製品名
                    {exColumnApi.response.exColumns
                      .filter((col) => col.visibleOnTable)
                      .map((col) => (
                        <div style={{ fontSize: 12, lineHeight: '12px' }} key={`tablecol-${col.id}`}>
                          {col.name}
                        </div>
                      ))}
                  </div>
                </OrderTableCell>
                <ExTableCell width={120}>
                  <DropDownCheckbox
                    form={searchForm}
                    attr="requireProofreadFilter"
                    name="requireProofreadFilter"
                    items={booleanFilterOptions}
                    title="校正管理"
                    onSubmit={handleSearch}
                    btnStyle={{ padding: '4px 8px' }}
                    isLeft={true}
                    maxWidth={600}
                    showFilter
                  />
                </ExTableCell>
                {partnerState.currentDealer.authority?.enableInstrumentSharing && (
                  <ExTableCell width={120}>
                    <DropDownCheckbox
                      form={searchForm}
                      attr="sharingInstrumentFilter"
                      name="sharingInstrumentFilter"
                      items={booleanFilterOptions}
                      title="機器共有"
                      onSubmit={handleSearch}
                      btnStyle={{ padding: '4px 8px' }}
                      isLeft={true}
                      maxWidth={600}
                      showFilter
                    />
                  </ExTableCell>
                )}
                <ExTableCell width={100} align="center"></ExTableCell>
              </TableRow>
            </TableHead>
            <ExTableBody loading={instrumentIndexApi.loading}>
              {instrumentIndexApi.response?.instruments.map((instrument: Instrument, idx: number) => {
                const instrumentVM = new InstrumentViewModel(instrument)
                return (
                  <TableRow key={idx} hover={true} onClick={() => handleClickDetailButton(instrument)} sx={{ cursor: 'pointer' }}>
                    {partnerState.currentDealer.authority?.enableInstrumentSharing && (
                      <CheckboxTableCell checkboxesSet={checkboxSet} checkboxId={instrument.id!} />
                    )}
                    <ExTableCell width={100} align="center">
                      <ImageView maxHeight={100} maxWidth={100} src={instrument.instrumentBasicInfo?.imageUrl} />
                    </ExTableCell>
                    <ExTableCell width={120} open={displaySettingSet.isVisible('createdAt')} align="center">
                      {instrumentVM.registeredAt()}
                    </ExTableCell>
                    <ExTableCell width={120} open={displaySettingSet.isVisible('maker')}>
                      {instrumentVM.getMakerName()}
                    </ExTableCell>
                    <ExTableCell width={120} open={displaySettingSet.isVisible('outsideLaboratory')}>
                      {instrumentVM.ownerableName()}
                    </ExTableCell>
                    <ExTableCell width={230} open={displaySettingSet.isVisible('dealerDivision')}>
                      {instrumentVM.dealerDivisionNames()}
                    </ExTableCell>
                    <ExTableCell>
                      <div style={{ fontSize: 11, marginBottom: 5 }}>{instrumentVM.getInstrumentNumbers()}</div>
                      <div style={{ fontSize: 16 }}>{instrumentVM.getInstrumentName()}</div>
                      <TableExAttributesInfo tableExAttrs={instrument.tableExAttrs || []} id={instrument.id} />
                      {instrument.instrumentBasicInfo?.externalLink && instrument.instrumentBasicInfo?.externalLinkName && (
                        <Link target="_blank" style={{ cursor: 'pointer' }} href={instrument.instrumentBasicInfo?.externalLink}>
                          {instrument.instrumentBasicInfo?.externalLinkName}
                        </Link>
                      )}
                    </ExTableCell>
                    <ExTableCell width={90} align="center">
                      {instrument.requireProofread && (
                        <>
                          {instrument.isLastProofreadNg ? (
                            <label
                              style={{ border: '2px solid #5DA2CA', color: '#5DA2CA', borderRadius: 5, padding: '2px 3px', fontSize: 12 }}
                            >
                              不合格
                            </label>
                          ) : (
                            <CheckCircleOutlineIcon style={{ color: 'green' }} />
                          )}
                        </>
                      )}
                    </ExTableCell>
                    {partnerState.currentDealer.authority?.enableInstrumentSharing && (
                      <ExTableCell width={90} align="center">
                        {instrument.sharingState === 'sharing' && <CheckCircleOutlineIcon style={{ color: 'green' }} />}
                      </ExTableCell>
                    )}
                    <ExTableCell width={100} align="center">
                      <Flex flexDirection="column">
                        <ThemedButton size="small" color="secondary" onClick={(e) => handleClickEditButton(e, instrument)}>
                          編集
                        </ThemedButton>
                      </Flex>
                    </ExTableCell>
                  </TableRow>
                )
              })}
            </ExTableBody>
          </Table>
        </TableContainer>
        <Pagination response={instrumentIndexApi.response} pageSet={instrumentIndexApi.pageSet} />
        <CheckboxFooter
          checkboxSet={checkboxSet}
          content={
            <>
              <ThemedButton color="primary" onClick={handleClickExportInstrumentSharing}>
                機器共有インポートファイルの生成
              </ThemedButton>
            </>
          }
        />
      </Paper>
    </>
  )
}

export default withRouter(InstrumentIndex)
