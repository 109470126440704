import { Table, TableHead, TableBody, TableRow, TableCell, Checkbox } from '@mui/material'
import { useAdminPatchUserAuthorityApi } from 'api/admin/laboratories/user_authority'
import Modal, { ModalState } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { User } from 'entities/user'
import { BulkUpdateUserAuthorityForm, AuthorityTypes, authorityData } from 'entities/user_authority'
import { ApiError, Form, useForm } from 'rac'
import React, { useEffect } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'
import { UserViewModel } from 'view_models/user'

const dialogContent = (form: Form<BulkUpdateUserAuthorityForm>, error: ApiError, users: User[]) => {
  const handleChange = (userId: number, type: AuthorityTypes, value: boolean) => {
    const authority = form.object.userAuthorities.find((a) => a.userId === userId)
    const changedAuth = { ...authority, [type]: value }
    const updateState = form.object.userAuthorities.map((auth) => (auth.userId !== userId ? auth : changedAuth))
    form.update((f) => (f.userAuthorities = updateState))
  }

  const isChecked = (userId: number, type: AuthorityTypes) => {
    const auth = form.object.userAuthorities.find((a) => a.userId === userId)
    return auth && auth[type]
  }

  const styles = {
    th: {
      padding: '0 0 16px',
    },
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>名前</TableCell>
          <TableCell width={80} style={styles.th}>
            機器の
            <br />
            編集
          </TableCell>
          <TableCell width={80} style={styles.th}>
            ルームの
            <br />
            編集
          </TableCell>
          <TableCell width={80} style={styles.th}>
            カテゴリの
            <br />
            編集
          </TableCell>
          <TableCell width={90} style={styles.th}>
            修理管理の
            <br />
            閲覧・編集
          </TableCell>
          <TableCell width={90} style={styles.th}>
            校正履歴の
            <br />
            閲覧・編集
          </TableCell>
          <TableCell width={90} style={styles.th}>
            予約条件の
            <br />
            設定
          </TableCell>
          <TableCell width={80} style={styles.th}>
            管理者
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {users.map((user, index) => {
          const userVM = new UserViewModel(user)
          const authorityKeys = Object.keys(authorityData)
          return (
            <TableRow key={index}>
              <TableCell>{userVM.fullName()}</TableCell>
              {authorityKeys.map((el, idx) => {
                const auth = el as AuthorityTypes

                return (
                  <TableCell key={idx} style={{ padding: '8px 10px' }}>
                    <Checkbox
                      checked={isChecked(user.id!, auth)}
                      onChange={(e) => handleChange(user.id!, auth, e.target.checked)}
                      size="small"
                    />
                  </TableCell>
                )
              })}
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

type UserAuthorityFormDialogProps = {
  users: User[]
  modalState: ModalState
  laboratoryId: number
  onComplete?: () => void
}

export const UserAuthorityFormDialog: React.FC<UserAuthorityFormDialogProps> = (props: UserAuthorityFormDialogProps) => {
  const form = useForm<BulkUpdateUserAuthorityForm>({ userAuthorities: [] }, 'userAuthority')
  const patchApi = useAdminPatchUserAuthorityApi(props.laboratoryId)

  const handleSubmit = () => {
    if (props?.users && props.users[0]) {
      patchApi.execute(form.object)
    }
  }
  // formの初期化
  useEffect(() => {
    if (!props?.users) return
    const initialState = Object.assign([])
    props.users.forEach((user) => {
      const auth = user.authority
      initialState.push({
        userId: user.id,
        instrument: auth?.instrument,
        room: auth?.room,
        category: auth?.category,
        repair: auth?.repair,
        proofread: auth?.proofread,
        constraint: auth?.constraint,
        privileged: auth?.privileged,
      })
    })
    form.set({ userAuthorities: initialState })
  }, [props.users])

  useEffectSkipFirst(() => {
    if (patchApi.isSuccess()) {
      props.modalState.setOpen(false)
      if (props.onComplete) props.onComplete()
    }
  }, [patchApi.loading])

  return (
    <Modal
      modalId="user-authority-modal"
      title="ユーザー権限"
      modalState={props.modalState}
      size="lg"
      footer={
        <>
          <ThemedButton onClick={() => props.modalState.setOpen(false)} color="secondary">
            キャンセル
          </ThemedButton>
          <ThemedButton onClick={handleSubmit} color="success">
            保存
          </ThemedButton>
        </>
      }
    >
      {dialogContent(form, patchApi.apiError, props.users)}
    </Modal>
  )
}
