import { EventApi, EventInput, ViewApi } from '@fullcalendar/react'
import { InsertInvitation } from '@mui/icons-material'
import { Paper } from '@mui/material'
import { usePartnerFetchInstrumentApi } from 'api/partner/instrument'
import { usePartnerFetchInstrumentCategoriesApi } from 'api/partner/instrument_categories'
import { usePartnerFetchInstrumentReservationsApi } from 'api/partner/instruments/instrument_reservation'
import { usePartnerFetchOutsideLaboratoriesApi } from 'api/partner/outside_laboratories'
import { usePartnerDeleteUnmanagedInstrumentApi, usePartnerRestoreManagementUnmanagedInstrumentApi } from 'api/partner/unmanaged_instrument'
import { useFetchExColumnsApi } from 'api/shared/ex_columns'
import { DealerMemoModal } from 'components/partner/dealer_memo/dealer_memo_modal'
import DefectReportDataCard from 'components/partner/defect_report/defect_report_data_card'
import { NewDefectReportFormDialog } from 'components/partner/defect_report/defect_report_form_dialog'
import { DisabledRestoreManagementButton } from 'components/partner/instrument/disabled_restore_management_button'
import InstrumentDataCard from 'components/partner/instrument/instrument_data_card'
import InstrumentEventTable from 'components/partner/instrument/instrument_event_table'
import { EditInstrumentFormDialog } from 'components/partner/instrument/instrument_form_dialog'
import InstrumentProofreadInfoDataCard from 'components/partner/instrument/instrument_proofread_info_data_card'
import { usePartnerInstrumentEventTableSet } from 'components/partner/instrument/use_instrument_event_table'
import ProofreadEventDataCard from 'components/partner/proofread_event/proofread_event_data_card'
import RepairEstimateDataCard from 'components/partner/repair_estimate/repair_estimate_data_card'
import RepairEventDataCard from 'components/partner/repair_event/repair_event_data_card'
import { NewRepairEventFormDialog } from 'components/partner/repair_event/repair_event_form_dialog'
import { ReservationDetailModal } from 'components/partner/reservation/reservation_detail_dialog'
import Calendar from 'components/shared/calendar'
import { ContentHeader, ContentTitle } from 'components/shared/content'
import { Flex } from 'components/shared/flex'
import InstrumentInfoAttributeExColumnDataCard from 'components/shared/instrument_ex_column_data_card'
import { useModal } from 'components/shared/modal'
import StatusLabel from 'components/shared/status_label'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { DefectReport } from 'entities/defect_report'
import { isOwnerableOutsideLaboratory } from 'entities/instrument'
import { RepairEstimate } from 'entities/repair_estimate'
import { RepairEvent } from 'entities/repair_event'
import { Reservation, ReservationSearchForm } from 'entities/reservation'
import { warningDiscardUnmanagedInstrumentDescription } from 'entities/unmanaged_instrument'
import { useForm } from 'rac'
import React, { useState, useEffect, useContext, useRef, useMemo } from 'react'
import { StaticContext } from 'react-router'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Link as Scroll } from 'react-scroll'
import { PartnerRouteHelper } from 'routes/partner'
import { useCalendar, useEffectSkipFirst } from 'utils/hooks'
import { ReservationViewModel } from 'view_models/reservation'

type Props = RouteComponentProps<{ id: string }, StaticContext>

const HEADER_OFFSET = 50

export const InstrumentShow: React.FC<Props> = (props: Props) => {
  const [editInstrumentDialogOpen, setEditInstrumentDialogOpen] = useState<boolean>(false)
  const globalState = useContext(GlobalStateContext)
  const instrumentShowApi = usePartnerFetchInstrumentApi(true)
  const exColumnsApi = useFetchExColumnsApi('dealer_user')
  const newDefectReportModal = useModal()
  const newRepairEventModal = useModal()
  const [canReportEdit, setCanReportEdit] = useState<boolean>(false)
  const [canRepairEventEdit, setCanRepairEventEdit] = useState<boolean>(false)
  const instrumentEventTableSet = usePartnerInstrumentEventTableSet(instrumentShowApi)
  const memoModalState = useModal()
  const categoryIndexApi = usePartnerFetchInstrumentCategoriesApi()
  const outsideLaboratoriesIndexApi = usePartnerFetchOutsideLaboratoriesApi({ useFilter: true })
  const discardApi = usePartnerDeleteUnmanagedInstrumentApi()
  const restoreManagementInstrumentApi = usePartnerRestoreManagementUnmanagedInstrumentApi()
  const currentInstrumentId = Number(props.match.params.id)
  const reservationIndexApi = usePartnerFetchInstrumentReservationsApi(Number(props.match.params.id))
  const searchForm = useForm<ReservationSearchForm>({})
  const calendarSet = useCalendar(reservationIndexApi, searchForm)
  const calendarRef = useRef<HTMLDivElement>(null)
  const reservationDetailModalState = useModal()

  const [showReservation, setShowReservation] = useState<Reservation>({})

  const openEditReservationDialog = (reservation: Reservation) => {
    setShowReservation(reservation)
    reservationDetailModalState.setOpen(true)
  }

  /**
   * fullcalendar用のイベントデータ
   */
  const eventInputs = useMemo(() => {
    const inputs: EventInput[] = calendarSet.reservations.map((reservation) => {
      const viewModel = new ReservationViewModel(reservation)

      return {
        id: reservation.id!.toString(),
        title: viewModel.getEventTitleForDealer(),
        start: reservation.startAt,
        end: reservation.endAt,
        color: viewModel.getEventColor(),
      }
    })

    return inputs
  }, [calendarSet.reservations])

  /**
   * fullcalendarのeventクリック
   * @param arg arg
   */
  const handleClickEvent = (arg: { el: HTMLElement; event: EventApi; jsEvent: MouseEvent; view: ViewApi }) => {
    const reservation = calendarSet.reservations.find((reservation) => `${reservation.id!}` === arg.event.id)
    if (reservation) openEditReservationDialog(reservation)
  }

  const isManaged = instrumentShowApi.response.instrument.aasmState === 'managed'
  const isUnmanaged = instrumentShowApi.response.instrument.aasmState === 'unmanaged'
  const isShared = instrumentShowApi.response.instrument.sharingState === 'sharing'

  useEffect(() => {
    categoryIndexApi.execute()
    outsideLaboratoriesIndexApi.execute()
    instrumentShowApi.execute(currentInstrumentId)
    exColumnsApi.execute()
  }, [])

  useEffectSkipFirst(()=>{
    if (isManaged) {
      calendarSet.resetDate()
    }
  },[isManaged])

  /**
   * ローディングプログレスの表示
   */
  useEffect(() => {
    globalState.setLoading(instrumentShowApi.loading || reservationIndexApi.loading)
    if (instrumentShowApi.statusCode === 400) {
      // 機器が存在しない場合、廃棄されている場合は機器一覧画面まで遷移する
      globalState.setNotificationMessage({ body: instrumentShowApi.response.message || '機器が存在しません', colorType: 'error' })
      props.history.push(PartnerRouteHelper.instrumentIndex())
    }
  }, [instrumentShowApi.loading, reservationIndexApi.loading])

  /**
   * 機器の更新が完了したときのイベント
   */
  const handleCompleteUpdateInstrument = () => {
    setEditInstrumentDialogOpen(false)
    instrumentShowApi.execute(instrumentShowApi.response.instrument.id!)
  }

  /**
   * 機器が管理対象外にされたときのイベント
   */
  const handleCompleteExcludeManagement = () => {
    setEditInstrumentDialogOpen(false)
    instrumentShowApi.execute(currentInstrumentId)
  }

  /**
   * 廃棄するボタンのクリック
   */
  const handleClickDiscardButton = () => {
    globalState.confirm(warningDiscardUnmanagedInstrumentDescription, (event) => {
      const instrumentId = instrumentShowApi.response.instrument.id
      if (event === 'ok' && instrumentId) {
        discardApi.execute(instrumentId)
      }
    })
  }

  const handleClickRestoreManagementButton = () => {
    restoreManagementInstrumentApi.execute(currentInstrumentId)
  }

  const handleCompleteDiscard = () => {
    // 管理対象外一覧画面に遷移
    props.history.push(PartnerRouteHelper.unmanagedInstrumentIndex())
  }

  const handleCompleteRestoreManagement = () => {
    // 詳細画面の再読み込み
    setEditInstrumentDialogOpen(false)
    instrumentShowApi.execute(currentInstrumentId)
  }

  useEffectSkipFirst(() => {
    if (discardApi.isSuccess()) {
      props.history.push(PartnerRouteHelper.unmanagedInstrumentIndex())
    }
  }, [discardApi.loading])

  useEffectSkipFirst(() => {
    if (restoreManagementInstrumentApi.isSuccess()) {
      handleCompleteRestoreManagement()
    }
  }, [restoreManagementInstrumentApi.loading])

  // 不具合報告のアクションボタン表示切り替え
  useEffect(() => {
    if (
      (instrumentEventTableSet.defectReport &&
        !instrumentEventTableSet.defectReport.repairEstimate &&
        !instrumentEventTableSet.defectReport.repairEvent &&
        !instrumentEventTableSet.repairEvent,
      !instrumentEventTableSet.repairEstimate)
    ) {
      setCanReportEdit(true)
    } else {
      setCanReportEdit(false)
    }
  }, [instrumentEventTableSet.defectReport])

  // 修理履歴の編集可否切り替え
  useEffect(() => {
    const proofreadEvent = instrumentEventTableSet.repairEvent?.proofreadEvent || instrumentEventTableSet.proofreadEvent
    if (proofreadEvent && (proofreadEvent.aasmState === 'fix_result' || proofreadEvent.aasmState === 'approved_result')) {
      setCanRepairEventEdit(false)
    } else {
      setCanRepairEventEdit(true)
    }
  }, [instrumentEventTableSet.repairEvent])

  return (
    <>
      <EditInstrumentFormDialog
        onComplete={handleCompleteUpdateInstrument}
        onCompleteExcludeManagement={handleCompleteExcludeManagement}
        onCompleteDiscard={handleCompleteDiscard}
        onCompleteRestoreManagement={handleCompleteRestoreManagement}
        instrument={instrumentShowApi.response.instrument}
        open={editInstrumentDialogOpen}
        onCancel={() => setEditInstrumentDialogOpen(false)}
        instrumentCategories={categoryIndexApi.response.instrumentCategories}
        outsideLaboratories={outsideLaboratoriesIndexApi.response.outsideLaboratories}
        onCompleteRemoveImage={() => instrumentShowApi.execute(currentInstrumentId)}
      />
      {instrumentShowApi.response.instrument.canReserve && (
        <ReservationDetailModal modalState={reservationDetailModalState} reservation={showReservation} />
      )}
      {instrumentShowApi.isSuccess() && (
        <>
          <DealerMemoModal
            memoableId={instrumentShowApi.response.instrument.id!}
            memoableType="Instrument"
            modalState={memoModalState}
            onClose={() => memoModalState.setOpen(false)}
          />
          <NewDefectReportFormDialog
            modalState={newDefectReportModal}
            instrumentId={Number(props.match.params.id)}
            onComplete={() => {
              instrumentEventTableSet.handleRefetchDefectReports()
              newDefectReportModal.setOpen(false)
              instrumentShowApi.execute(currentInstrumentId)
            }}
          />
          <NewRepairEventFormDialog
            instrumentId={currentInstrumentId}
            modalState={newRepairEventModal}
            onComplete={() => {
              instrumentEventTableSet.handleRefetchRepairEvents()
              newRepairEventModal.setOpen(false)
              instrumentShowApi.execute(currentInstrumentId)
            }}
            onClose={() => newRepairEventModal.setOpen(false)}
          />
          <ContentHeader>
            <ContentTitle title={`機器詳細 - ${instrumentShowApi.response.instrument.instrumentBasicInfo?.name}`}>
              {isUnmanaged && <StatusLabel text="管理対象外" color="red" />}
              {isShared && <StatusLabel text="共有中" color="green" />}
            </ContentTitle>
            <Flex>
              {isUnmanaged ? (
                <>
                  <ThemedButton onClick={handleClickDiscardButton} color="error" variant="text">
                    廃棄する
                  </ThemedButton>
                  {isOwnerableOutsideLaboratory(instrumentShowApi.response.instrument, instrumentShowApi.response.instrument.ownerable) &&
                  instrumentShowApi.response.instrument.ownerable.state === 'disable' ? (
                    <DisabledRestoreManagementButton />
                  ) : (
                    <ThemedButton onClick={handleClickRestoreManagementButton} color="success">
                      もとに戻す
                    </ThemedButton>
                  )}
                </>
              ) : (
                <>
                  <ThemedButton onClick={() => newDefectReportModal.setOpen(true)} color="primary" variant="outlined">
                    不具合報告の作成
                  </ThemedButton>
                  <ThemedButton onClick={() => newRepairEventModal.setOpen(true)} color="primary" variant="outlined">
                    修理履歴の作成
                  </ThemedButton>
                  {instrumentShowApi.response.instrument.canReserve && (
                    <Scroll to="instrument-calendar" smooth duration={500} offset={-HEADER_OFFSET}>
                      <ThemedButton color="success" variant="outlined">
                        <InsertInvitation fontSize="small" style={{ marginRight: 3 }} />
                        カレンダー
                      </ThemedButton>
                    </Scroll>
                  )}
                </>
              )}
            </Flex>
          </ContentHeader>
          <Flex flexDirection="column" style={{ width: '100%', marginBottom: 20 }}>
            <InstrumentEventTable instrumentEventTableSet={instrumentEventTableSet} isInstrumentSharing={isShared} />
            <Flex style={{ width: '100%' }}>
              <Flex flexDirection="column" style={{ width: '100%' }}>
                <InstrumentDataCard
                  instrument={instrumentShowApi.response.instrument}
                  canEdit
                  onClickEdit={() => setEditInstrumentDialogOpen(true)}
                  onClickMemo={() => memoModalState.setOpen(true)}
                />
                <InstrumentInfoAttributeExColumnDataCard
                  clientType="dealer_user"
                  instrumentInfoAttribute={instrumentShowApi.response.instrument.instrumentInfoAttribute}
                  instrument={instrumentShowApi.response.instrument}
                  exAttributes={instrumentShowApi.response.instrument?.exAttributes || []}
                  onUpdate={handleCompleteUpdateInstrument}
                  editable
                />
              </Flex>
              <Flex flexDirection="column" style={{ width: '100%' }}>
                <DefectReportDataCard
                  defectReport={instrumentEventTableSet.defectReport}
                  canEdit={canReportEdit}
                  onCompleteEdit={(report?: DefectReport) => {
                    instrumentEventTableSet.setDefectReport(report)
                    instrumentEventTableSet.handleRefetchDefectReports()
                    instrumentShowApi.execute(currentInstrumentId)
                  }}
                  onCompleteCreateEvent={(event?: RepairEvent) => {
                    instrumentEventTableSet.setRepairEvent(event)
                    instrumentEventTableSet.handleRefetchRepair()
                    setCanReportEdit(false)
                    instrumentShowApi.execute(currentInstrumentId)
                  }}
                  onCompleteCreateEstimate={(estimate?: RepairEstimate) => {
                    instrumentEventTableSet.setRepairEstimate(estimate)
                    instrumentEventTableSet.handleRefetchRepair()
                    setCanReportEdit(false)
                    instrumentShowApi.execute(currentInstrumentId)
                  }}
                />
                <RepairEstimateDataCard
                  repairEstimate={instrumentEventTableSet.repairEstimate}
                  editDisabled={instrumentEventTableSet.repairEstimate == null}
                />
                <RepairEventDataCard
                  repairEvent={instrumentEventTableSet.repairEvent}
                  canEdit={canRepairEventEdit}
                  onComplete={(event?: RepairEvent) => {
                    instrumentEventTableSet.setRepairEvent(event)
                    instrumentEventTableSet.handleRefetchRepairEvents()
                    instrumentShowApi.execute(currentInstrumentId)
                  }}
                />
                <ProofreadEventDataCard proofreadEvent={instrumentEventTableSet.proofreadEvent} showDetail />
                <InstrumentProofreadInfoDataCard instrument={instrumentShowApi.response.instrument} />
              </Flex>
            </Flex>
          </Flex>
          {instrumentShowApi.response.instrument.canReserve && isManaged && (
            // 予約可能かつmanagedな機器はカレンダーが閲覧可能
            <Paper ref={calendarRef} id="instrument-calendar" style={{ padding: 40, marginTop: 20 }}>
              <Calendar
                editable={true}
                events={eventInputs}
                eventClick={handleClickEvent}
                datesSet={(arg) => calendarSet.setDate(arg.view.currentStart)}
                // eventAllow={handleEventAllow}
              />
            </Paper>
          )}
        </>
      )}
    </>
  )
}

export default withRouter(InstrumentShow)
