import { AxiosHttpClient } from 'api/axios'
import { useRepruaIndexApi } from 'api/shared_api_hooks'
import { PagingResponse } from 'entities'
import { ProofreadSchedule, ProofreadScheduleSearchForm } from 'entities/proofread_schedule'
import { BaseResponse, IndexApiSet } from 'rac'
import { useEffectSkipFirst } from 'utils/hooks'

export type ProofreadSchedulesResponse = PagingResponse & {
  proofreadSchedules: ProofreadSchedule[]
}
export type ProofreadScheduleResponse = BaseResponse & {
  proofreadSchedule: ProofreadSchedule
}

/**
 * 一覧
 */
export const useFetchProofreadSchedulesApi = (
  planId: number,
  form?: ProofreadScheduleSearchForm,
): IndexApiSet<ProofreadSchedulesResponse> & { execute: () => void } => {
  const api = useRepruaIndexApi<ProofreadSchedulesResponse, ProofreadScheduleSearchForm>(new AxiosHttpClient('user'), {
    initialResponse: { proofreadSchedules: [], totalCount: 0 },
  })

  const execute = () => {
    const path = `proofread_plans/${planId}/proofread_schedules`
    api.execute(path, { params: { ...form } })
  }

  useEffectSkipFirst(() => {
    execute()
  }, [api.pageSet.page, api.pageSet.perPage, api.orderSet.order, api.orderSet.orderBy])

  return { ...api, execute }
}
