import { Grid } from '@mui/material'
import { DateField } from 'components/shared/form/input'
import { LabelWithHelp } from 'components/shared/label_with_help'
import Modal, { ModalState } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { ReservationDownloadForm } from 'entities/reservation_download'
import { Form, useForm } from 'rac'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

type DownloadReservationExcelDialogProps = {
  modalState: ModalState
  outputApi: { execute: (form?: Form<ReservationDownloadForm>) => void }
}

export const DownloadReservationExcelDialog: React.FC<DownloadReservationExcelDialogProps> = (props: DownloadReservationExcelDialogProps) => {

  const { classes } = useStyles()

  const form = useForm<ReservationDownloadForm>({})

  // 全ての条件でエクセルをダウンロードする
  const handleClickAllDownload = () => {
    props.outputApi.execute()
  }

  // 期間指定でエクセルをダウンロードする
  const handleClickDurationDownload = (form: Form<ReservationDownloadForm>) => {
    props.outputApi.execute(form)
  }

  return (
    <Modal modalState={props.modalState} modalId="download-excel-dialog" title="Excel出力" size="sm">
      <div>
        <label>全データを出力</label>
        <div className={classes.outpuButtonArea}>
          <ThemedButton onClick={() => handleClickAllDownload()} color="success">
            出力
          </ThemedButton>
        </div>

        <LabelWithHelp
          title="期間を指定して出力"
          message="入力された「開始日時」と「終了日時」の間に「予約開始日」または「予約終了日」が設定されている機器情報のみを出力します。片方だけ入力した場合にはその条件のみ適用されます。"
        />
        <Grid container spacing={2} className={classes.durationFormGrid}>
          <Grid item xs={5.5}>
            <DateField label="開始日時" form={form} attr="from" clearable />
          </Grid>
          <Grid item xs={1} className={classes.tilde}>
            ~
          </Grid>
          <Grid item xs={5.5}>
            <DateField label="終了日時" form={form} attr="to" clearable />
          </Grid>
        </Grid>
        <div className={classes.outpuButtonArea}>
          <ThemedButton onClick={() => handleClickDurationDownload(form)} color="success">
            出力
          </ThemedButton>
        </div>
      </div>
    </Modal>
  )
}

const useStyles = makeStyles()({
  tilde: {
    textAlign: 'center',
    marginTop: '10px',
  },
  durationFormGrid: {
    paddingTop: '30px',
    paddingBottom: '10px',
  },
  outpuButtonArea: {
    textAlign: 'right',
    marginBottom: '5px',
  },
})
