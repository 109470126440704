import { usePartnerFetchRepairEventApi } from 'api/partner/repair_event'
import { GlobalStateContext } from 'contexts/global_state_context'
import React, { useContext, useEffect } from 'react'
import { StaticContext } from 'react-router'
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom'
import { MypageRouteHelper } from 'routes/mypage'
import { useEffectSkipFirst } from 'utils/hooks'

type Props = RouteComponentProps<{ id: string }, StaticContext>
export const RepairEventShow: React.FC<Props> = (props) => {
  const globalState = useContext(GlobalStateContext)
  const history = useHistory()
  const fetchApi = usePartnerFetchRepairEventApi()

  useEffect(() => {
    fetchApi.execute(Number(props.match.params.id))
  }, [])

  useEffectSkipFirst(() => {
    globalState.setLoading(fetchApi.loading)
    if (fetchApi.isSuccess() && fetchApi.response.repairEvent.repairInfoId) {
      history.push(MypageRouteHelper.repairInfoShow(fetchApi.response.repairEvent.repairInfoId))
    }
  }, [fetchApi.loading])

  return <></>
}

export default withRouter(RepairEventShow)
