import { Checkbox, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel } from '@mui/material'
import { usePostAssetRelationApi, useFetchAssetRelationsApi } from 'api/mypage/asset_relation'
import { useFetchRoomsApi } from 'api/mypage/room'
import { SharedInstrumentsForm } from 'components/mypage/laboratory/shared_instruments_form'
import ThemedButton from 'components/shared/themed_button'
import { ID } from 'entities'
import { Room } from 'entities/room'
import { useForm } from 'rac'
import React, { useEffect, useState } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'

type SharedAssetsFormModalProps = {
  onComplete: () => void
  laboratoryId: number
}

/**
 * keyはroomId
 */
export type SharedAssetForm = {
  selectedAssets: { roomId?: ID; instrumentIds?: ID[] }[]
  laboratoryId?: ID
}

export const SharedAssetsFormModal: React.FC<SharedAssetsFormModalProps> = (props: SharedAssetsFormModalProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const roomApi = useFetchRoomsApi()
  const indexApi = useFetchAssetRelationsApi()
  const postApi = usePostAssetRelationApi()
  const form = useForm<SharedAssetForm>({ laboratoryId: props.laboratoryId, selectedAssets: [] })

  const handleClose = () => setOpen(false)
  const handleOpenModal = () => setOpen(true)

  useEffect(() => {
    roomApi.execute()
  }, [])

  useEffect(() => {
    if (props.laboratoryId) {
      form.newUpdateObject('laboratoryId', props.laboratoryId)
      indexApi.execute(props.laboratoryId)
    }
  }, [props.laboratoryId])

  useEffectSkipFirst(() => {
    if (indexApi.isSuccess()) {
      const formObject: SharedAssetForm = {
        selectedAssets: indexApi.response.sharedAssets,
        laboratoryId: props.laboratoryId,
      }
      form.set(formObject)
    }
  }, [indexApi.loading])

  const handleSubmit = () => {
    postApi.execute(form)
  }

  useEffectSkipFirst(() => {
    if (postApi.isSuccess()) {
      handleClose()
      indexApi.execute(props.laboratoryId)
      props.onComplete()
    }
  }, [postApi.loading])

  const handleCheckRoom = (isChecked: boolean, roomId: ID) => {
    const roomIds = form.object.selectedAssets.map((asset) => asset.roomId)
    if (roomIds.includes(roomId)) {
      // チェックを外す
      form.update((f) => {
        f.selectedAssets.splice(roomIds.indexOf(roomId), 1)
      })
    } else {
      form.update((f) => {
        f.selectedAssets.push({ roomId: roomId, instrumentIds: [] })
      })
    }
  }

  return (
    <>
      <ThemedButton color="primary" onClick={handleOpenModal}>
        設定
      </ThemedButton>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle>共有する資産を選択</DialogTitle>
        <DialogContent>
          {roomApi.response.rooms.map((room: Room, index: number) => {
            return (
              <div key={index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={form.object.selectedAssets.map((asset) => asset.roomId).includes(room.id)}
                      onChange={(e) => {
                        handleCheckRoom(e.target.checked, room.id!)
                      }}
                    />
                  }
                  label={room.name}
                />

                <Collapse in={form.object.selectedAssets.map((asset) => asset.roomId).includes(room.id)}>
                  <div style={{ margin: '0 15px' }}>
                    <SharedInstrumentsForm instruments={room.instruments!} form={form} roomId={room.id!} index={index} />
                  </div>
                </Collapse>
              </div>
            )
          })}
        </DialogContent>
        <DialogActions>
          <ThemedButton onClick={handleClose} color="secondary">
            キャンセル
          </ThemedButton>
          <ThemedButton onClick={handleSubmit} color="success">
            更新
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
