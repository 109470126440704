import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import BuildIcon from '@mui/icons-material/Build'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import CropFreeIcon from '@mui/icons-material/CropFree'
import LaunchIcon from '@mui/icons-material/Launch'
import ListIcon from '@mui/icons-material/List'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import MailIcon from '@mui/icons-material/Mail'
import LogoutIcon from '@mui/icons-material/PowerSettingsNew'
import SettingsIcon from '@mui/icons-material/Settings'
import ShareIcon from '@mui/icons-material/Share'
import { Tooltip } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import CssBaseline from '@mui/material/CssBaseline'
import Toolbar from '@mui/material/Toolbar'
import { OwnerFilterField } from 'components/partner/shared/owner_filter_field'
import { Flex } from 'components/shared/flex'
import { Sidebar } from 'components/shared/sidebar'
import { SidebarMenuItem, SidebarSubMenuItem } from 'components/shared/sidebar_menu_item'
import { PartnerStateContext } from 'contexts/partner_state_context'
import { SidebarContext } from 'contexts/sidebar_context'
import { MenuItemPathPattern } from 'entities/menu_item_path_pattern'
import React, { ReactNode, useContext } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { PartnerRouteHelper } from 'routes/partner'
import CookieManager from 'utils/cookie_util'
import './partner_layout.scss'

enum DealerDrawerMenuId {
  INSTRUMENT,
  BARCODE,
  PROOFREAD,
  REPAIR,
  INSTRUMENT_RELATION,
  EX_COLUMN,
  INQUIRY,
  INSTRUMENT_IMPORT,
  SETTING,
}

enum DealerDrawerSubMenuId {
  INSTRUMENT_INDEX,
  BARCODE_INDEX,
  BARCODE_UNPRINTED,
  PROOFREAD_PLANS,
  PROOFREAD_INSTRUMENTS,
  REPAIR_DEFECT_REPORTS,
  REPAIR_EVENTS,
  REPAIR_ESTIMATES,
  INSTRUMENT_RELATION_EVENTS,
}

const menuItemPathPatterns: MenuItemPathPattern[] = [
  { id: DealerDrawerMenuId.INSTRUMENT, pathPattern: new RegExp('partner/instruments', 'i') },
  { id: DealerDrawerMenuId.BARCODE, pathPattern: new RegExp('partner/barcodes*|partner/unprinted_barcodes') },
  { id: DealerDrawerMenuId.PROOFREAD, pathPattern: new RegExp('partner/proofread_*', 'i') },
  { id: DealerDrawerMenuId.PROOFREAD, pathPattern: new RegExp('partner/proofread_*', 'i') },
  { id: DealerDrawerMenuId.REPAIR, pathPattern: new RegExp('partner/defect_*|partner/repair_*', 'i') },
  { id: DealerDrawerMenuId.EX_COLUMN, pathPattern: new RegExp('partner/ex_attributes') },
  { id: DealerDrawerMenuId.INSTRUMENT_RELATION, pathPattern: new RegExp('partner/instrument_relation*') },
  { id: DealerDrawerMenuId.INSTRUMENT_IMPORT, pathPattern: new RegExp('partner/import/instruments') },
  { id: DealerDrawerMenuId.INQUIRY, pathPattern: new RegExp('partner/inquiries', 'i') },
  { id: DealerDrawerMenuId.SETTING, pathPattern: new RegExp('partner/setting', 'i') },
]

const subMmenuItemPathPatterns: MenuItemPathPattern[] = [
  { id: DealerDrawerSubMenuId.INSTRUMENT_INDEX, pathPattern: new RegExp('partner/instruments') },
  { id: DealerDrawerSubMenuId.BARCODE_INDEX, pathPattern: new RegExp('partner/barcodes') },
  { id: DealerDrawerSubMenuId.BARCODE_UNPRINTED, pathPattern: new RegExp('partner/unprinted_barcodes') },
  { id: DealerDrawerSubMenuId.PROOFREAD_PLANS, pathPattern: new RegExp('partner/proofread_plans', 'i') },
  { id: DealerDrawerSubMenuId.PROOFREAD_INSTRUMENTS, pathPattern: new RegExp('partner/proofreadable_instruments') },
  { id: DealerDrawerSubMenuId.REPAIR_DEFECT_REPORTS, pathPattern: new RegExp('partner/defect_reports') },
  { id: DealerDrawerSubMenuId.INSTRUMENT_RELATION_EVENTS, pathPattern: new RegExp('partner/instrument_relation_events') },
  { id: DealerDrawerSubMenuId.REPAIR_EVENTS, pathPattern: new RegExp('partner/repair_events', 'i') },
  { id: DealerDrawerSubMenuId.REPAIR_ESTIMATES, pathPattern: new RegExp('partner/repair_estimates', 'i') },
]

type Props = RouteComponentProps & {
  children?: ReactNode
  className?: string
}

const PartnerLayout: React.FC<Props> = ({ history, children, className }) => {
  const { currentDealerUser, currentDealer } = useContext(PartnerStateContext)
  const sidebarContext = useContext(SidebarContext)
  const partnerState = useContext(PartnerStateContext)

  React.useEffect(() => {
    const pathName = history.location.pathname
    const setMenuId = () => {
      menuItemPathPatterns.forEach((item) => {
        if (item.pathPattern?.test(pathName)) {
          sidebarContext.setActiveDealerDrawerMenuId(item.id)
        }
      })
    }
    const setSubMenuId = () => {
      let isAnySubMenuActive = false
      subMmenuItemPathPatterns.forEach((item) => {
        if (item.pathPattern?.test(pathName)) {
          sidebarContext.setActiveDealerDrawerSubMenuId(item.id)
          isAnySubMenuActive = true
        }
      })
      // URLがどのサブメニューに該当しない場合は初期化する
      if (!isAnySubMenuActive) {
        sidebarContext.setActiveDealerDrawerSubMenuId(-1)
      }
    }

    setMenuId()
    setSubMenuId()
  }, [history.location.pathname])

  const handleClickLogout = () => {
    CookieManager.removeDealerUserToken()
    history.push(PartnerRouteHelper.login())
  }

  const isMenuActive = (id: number) => id === sidebarContext.activeDealerDrawerMenuId
  const isSubMenuActive = (id: number) => id === sidebarContext.activeDealerDrawerSubMenuId

  return (
    <div className={`root partner ${className}`}>
      <CssBaseline />
      <AppBar position="fixed" className="app-bar" color="default">
        <Toolbar>
          <Flex alignItems="center" justifyContent="flex-end" style={{ width: '100%' }}>
            <div>
              <OwnerFilterField />
            </div>
            <div>
              <div>{currentDealerUser.dealer?.name || ''}</div>
              <div>{currentDealerUser.name || ''}</div>
            </div>
            <Tooltip title="ログアウト" onClick={handleClickLogout}>
              <LogoutIcon />
            </Tooltip>
          </Flex>
        </Toolbar>
      </AppBar>

      <Sidebar>
        <SidebarMenuItem
          active={isMenuActive(DealerDrawerMenuId.INSTRUMENT)}
          label="機器管理"
          icon={<ListIcon />}
          onClick={() => history.push(PartnerRouteHelper.instrumentIndex())}
        />
        <SidebarMenuItem active={isMenuActive(DealerDrawerMenuId.BARCODE)} label="QRコード" icon={<CropFreeIcon />}>
          <SidebarSubMenuItem
            active={isSubMenuActive(DealerDrawerSubMenuId.BARCODE_INDEX)}
            label="QRコード一覧"
            onClick={() => history.push(PartnerRouteHelper.barcodeIndex())}
          />
          <SidebarSubMenuItem
            active={isSubMenuActive(DealerDrawerSubMenuId.BARCODE_UNPRINTED)}
            label="未印刷"
            onClick={() => history.push(PartnerRouteHelper.unprintedBarcodeIndex())}
          />
        </SidebarMenuItem>
        <SidebarMenuItem active={isMenuActive(DealerDrawerMenuId.PROOFREAD)} label="校正管理" icon={<AssignmentTurnedInIcon />}>
          <SidebarSubMenuItem
            active={isSubMenuActive(DealerDrawerSubMenuId.PROOFREAD_PLANS)}
            label="計画一覧"
            onClick={() => history.push(PartnerRouteHelper.proofreadPlanIndex())}
          />
          <SidebarSubMenuItem
            active={isSubMenuActive(DealerDrawerSubMenuId.PROOFREAD_INSTRUMENTS)}
            label="機器一覧"
            onClick={() => history.push(PartnerRouteHelper.proofreadableInstrumentIndex())}
          />
        </SidebarMenuItem>
        <SidebarMenuItem active={isMenuActive(DealerDrawerMenuId.REPAIR)} label="修理管理" icon={<BuildIcon />}>
          <SidebarSubMenuItem
            active={isSubMenuActive(DealerDrawerSubMenuId.REPAIR_DEFECT_REPORTS)}
            label="不具合報告"
            onClick={() => history.push(PartnerRouteHelper.defectReportIndex())}
          />
          {partnerState.currentDealer.authority?.enableInstrumentSharing && (
            <SidebarSubMenuItem
              active={isSubMenuActive(DealerDrawerSubMenuId.REPAIR_ESTIMATES)}
              label="修理見積"
              onClick={() => history.push(PartnerRouteHelper.repairEstimateIndex())}
            />
          )}
          <SidebarSubMenuItem
            active={isSubMenuActive(DealerDrawerSubMenuId.REPAIR_EVENTS)}
            label="修理履歴"
            onClick={() => history.push(PartnerRouteHelper.repairEventIndex())}
          />
        </SidebarMenuItem>
        {partnerState.currentDealer.authority?.enableInstrumentSharing && (
          <SidebarMenuItem active={isMenuActive(DealerDrawerMenuId.INSTRUMENT_RELATION)} label="機器共有" icon={<ShareIcon />}>
            <SidebarSubMenuItem
              active={isSubMenuActive(DealerDrawerSubMenuId.INSTRUMENT_RELATION_EVENTS)}
              label="共有イベント"
              onClick={() => history.push(PartnerRouteHelper.instrumentRelationEventIndex())}
            />
          </SidebarMenuItem>
        )}
        <SidebarMenuItem
          active={isMenuActive(DealerDrawerMenuId.EX_COLUMN)}
          label="管理情報"
          icon={<LocalOfferIcon />}
          onClick={() => history.push(PartnerRouteHelper.exAttributeIndex())}
        />
        {currentDealer.authority?.instrumentImport && currentDealerUser.authority?.privileged && (
          <SidebarMenuItem
            active={isMenuActive(DealerDrawerMenuId.INSTRUMENT_IMPORT)}
            label="インポート"
            icon={<CloudUploadIcon />}
            onClick={() => history.push(PartnerRouteHelper.instrumentImport())}
          />
        )}
        {partnerState.currentDealer.authority?.enableInstrumentSharing && (
          <SidebarMenuItem
            active={isMenuActive(DealerDrawerMenuId.INQUIRY)}
            label="問合せ"
            icon={<MailIcon />}
            onClick={() => history.push(PartnerRouteHelper.inquiryIndex())}
          />
        )}
        <SidebarMenuItem
          active={isMenuActive(DealerDrawerMenuId.SETTING)}
          label="設定"
          icon={<SettingsIcon />}
          onClick={() => history.push(PartnerRouteHelper.settingShow())}
        />
        {currentDealer.purchaseContractStatus === 1 && (
          <SidebarMenuItem
            active={false}
            label="購買管理ログイン"
            icon={<LaunchIcon />}
            onClick={() => {
              window.open(`${process.env.REACT_APP_PURCHASE_HOST}/partner`, '_blank')
            }}
          />
        )}
      </Sidebar>
      <main className="content">
        <div className="toolbar" />
        {children}
      </main>
    </div>
  )
}

export default withRouter(PartnerLayout)
