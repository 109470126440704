import { usePartnerFetchRepairEstimateApi } from 'api/partner/repair_estimate'
import { GlobalStateContext } from 'contexts/global_state_context'
import React, { useContext, useEffect } from 'react'
import { StaticContext } from 'react-router'
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom'
import { PartnerRouteHelper } from 'routes/partner'
import { useEffectSkipFirst } from 'utils/hooks'

export type RepairEstimateShowProps = {}

type Props = RouteComponentProps<{ id: string }, StaticContext, RepairEstimateShowProps>
export const RepairEstimateShow: React.FC<Props> = (props) => {
  const globalState = useContext(GlobalStateContext)
  const history = useHistory()
  const estimateApi = usePartnerFetchRepairEstimateApi(Number(props.match.params.id))

  useEffect(() => {
    estimateApi.execute()
  }, [])
  useEffectSkipFirst(() => {
    globalState.setLoading(estimateApi.loading)
    if (estimateApi.isSuccess() && estimateApi.response.repairEstimate.repairInfoId) {
      history.push(PartnerRouteHelper.repairInfoShow(estimateApi.response.repairEstimate.repairInfoId))
    }
  }, [estimateApi.loading])

  return <></>
}

export default withRouter(RepairEstimateShow)
