import { Table, TableHead, TableRow, TableContainer } from '@mui/material'
import { PartnerFetchInstrumentsApiSet } from 'api/partner/instrument'
import ImageView from 'components/shared/image_view'
import Pagination from 'components/shared/pagination'
import { QrCode } from 'components/shared/qrcode'
import { ExTableCell, OrderTableCell, AccordionTableBody, AllCheckboxTableCell, CheckboxTableCell } from 'components/shared/table'
import { Instrument } from 'entities/instrument'
import React from 'react'
import { MypageRouteHelper } from 'routes/mypage'
import { getTableHeight, INDEX_CHECKED_TABLE_PADDING, INDEX_TABLE_PADDING } from 'utils/component_heights'
import { CheckboxesSet, DisplaySettingSet } from 'utils/hooks'
import { InstrumentViewModel } from 'view_models/instrument'

type InstrumentTableWithBarcodeProps = {
  displaySettingSet: DisplaySettingSet
  instrumentIndexApi: PartnerFetchInstrumentsApiSet
  checkboxSet: CheckboxesSet
}

export const InstrumentTableWithBarcode = ({ displaySettingSet, instrumentIndexApi, checkboxSet }: InstrumentTableWithBarcodeProps) => {
  const path = MypageRouteHelper.instrumentIndex()

  return (
    <>
      <TableContainer
        style={{
          maxHeight: checkboxSet.checkedIds.length > 0 ? getTableHeight(INDEX_CHECKED_TABLE_PADDING) : getTableHeight(INDEX_TABLE_PADDING),
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <AllCheckboxTableCell checkboxSet={checkboxSet} />
              <ExTableCell width={150}></ExTableCell>
              <OrderTableCell
                width={120}
                open={displaySettingSet.isVisible('createdAt')}
                align="center"
                orderAttr="createdAt"
                orderSet={instrumentIndexApi.orderSet}
              >
                登録日
              </OrderTableCell>
              <OrderTableCell
                width={120}
                open={displaySettingSet.isVisible('maker')}
                orderAttr="maker"
                orderSet={instrumentIndexApi.orderSet}
              >
                メーカー
              </OrderTableCell>
              <OrderTableCell minWidth={200} orderAttr="name" orderSet={instrumentIndexApi.orderSet}>
                型番 / シリアル番号 / 管理番号 / 製品名
              </OrderTableCell>
              <ExTableCell open={displaySettingSet.isVisible('room')}>部屋</ExTableCell>
              <OrderTableCell
                width={120}
                open={displaySettingSet.isVisible('purchasedAt')}
                align="center"
                orderAttr="purchasedAt"
                orderSet={instrumentIndexApi.orderSet}
              >
                取得日
              </OrderTableCell>
              <OrderTableCell
                width={120}
                open={displaySettingSet.isVisible('purchasedPrice')}
                align="center"
                orderAttr="purchasedPrice"
                orderSet={instrumentIndexApi.orderSet}
              >
                取得価額
              </OrderTableCell>
              <ExTableCell align="center">QRコード</ExTableCell>
            </TableRow>
          </TableHead>
          <AccordionTableBody loading={instrumentIndexApi.loading} tableName="instruments-table">
            {instrumentIndexApi.response?.instruments.map((instrument: Instrument) => {
              const instrumentVM = new InstrumentViewModel(instrument)

              return (
                <TableRow key={instrument.id}>
                  <CheckboxTableCell checkboxesSet={checkboxSet} checkboxId={instrument.id!} />
                  <ExTableCell width={100} align="center">
                    <ImageView maxHeight={100} maxWidth={100} src={instrument.instrumentBasicInfo?.imageUrl} />
                  </ExTableCell>
                  <ExTableCell width={120} open={displaySettingSet.isVisible('createdAt')} align="center">
                    {instrumentVM.registeredAt()}
                  </ExTableCell>
                  <ExTableCell width={120} open={displaySettingSet.isVisible('maker')}>
                    {instrumentVM.getMakerName()}
                  </ExTableCell>
                  <ExTableCell>
                    <div style={{ fontSize: 11, marginBottom: 5 }}>{instrumentVM.getInstrumentNumbers()}</div>
                    <div style={{ fontSize: 16 }}>{instrumentVM.getInstrumentName()}</div>
                  </ExTableCell>
                  <ExTableCell width={120} open={displaySettingSet.isVisible('room')}>
                    {instrumentVM.roomName()}
                  </ExTableCell>
                  <ExTableCell width={120} align="center" open={displaySettingSet.isVisible('purchasedAt')}>
                    {instrumentVM.purchasedAtText()}
                  </ExTableCell>
                  <ExTableCell width={120} align="center" open={displaySettingSet.isVisible('purchasedPrice')}>
                    {instrumentVM.purchasedPriceText()}
                  </ExTableCell>
                  <ExTableCell align="center">
                    <QrCode path={path} layoutSetting={{ imageScale: '100px' }} instrument={instrument} displaySetting={{}} />
                  </ExTableCell>
                </TableRow>
              )
            })}
          </AccordionTableBody>
        </Table>
      </TableContainer>
      <Pagination response={instrumentIndexApi.response} pageSet={instrumentIndexApi.pageSet} />
    </>
  )
}
