import DataBlock from 'components/shared/data/data_block'
import DataLabel from 'components/shared/data/data_label'
import Modal, { ModalState } from 'components/shared/modal'
import StatusLabel from 'components/shared/status_label'
import { ExAttribute } from 'entities/ex_attribute'
import React from 'react'

interface ExAttributesInfoModalProps {
  modalState: ModalState
  exAttributes: ExAttribute[]
}
const ExAttributesInfoModal = (props: ExAttributesInfoModalProps) => {
  return (
    <Modal modalId="extensin-attributes-info-modal" modalState={props.modalState} title="共有された管理情報">
      <div>
        {props.exAttributes.map((attr, idx) => {
          let value = attr.exAttributeValue.value
          if (attr.exColumn.inputType === 'price' && value) {
            // 金額タイプなら桁区切り
            value = `¥${parseInt(value).toLocaleString()}`
          }
          return (
            <DataBlock
              noBorder={props.exAttributes.length - 1 === idx}
              key={`column-${attr.exColumn.id}`}
              id={`column-${attr.exColumn.id}`}
            >
              <DataLabel
                label={
                  <>
                    {attr.exColumn.name || '-'} {attr.exColumn.shared && <StatusLabel text="ラボ閲覧可" color="gray" />}
                  </>
                }
                value={attr.exAttributeValue.value || '-'}
              />
            </DataBlock>
          )
        })}
      </div>
    </Modal>
  )
}

export default ExAttributesInfoModal
