import { Tooltip } from '@mui/material'
import { Flex } from 'components/shared/flex'
import { CheckBoxField, MonthField } from 'components/shared/form/input'
import { Hint } from 'components/shared/hint'
import Modal, { ModalState } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { ProofreadableInstrumentSearchForm } from 'entities/instrument'
import { Form } from 'rac'
import React, { useEffect } from 'react'
import { makeStyles } from 'tss-react/mui'

type DetailSearchModalProps = {
  modalState: ModalState
  onSubmit: () => void
  searchForm: Form<ProofreadableInstrumentSearchForm>
}

export const DetailSearchModal = ({ modalState, onSubmit, searchForm }: DetailSearchModalProps) => {
  const isDisabledShowOnlyUnregister = !(searchForm.object.expiredAtFrom && searchForm.object.expiredAtTo)
  const { classes } = useStyles()

  useEffect(() => {
    if (isDisabledShowOnlyUnregister) {
      searchForm.update((f) => {
        f.showOnlyUnregister = false
      })
    }
  }, [isDisabledShowOnlyUnregister])
  return (
    <Modal
      modalId="detail-search-modal"
      title="詳細検索"
      modalState={modalState}
      size="sm"
      footer={
        <>
          <ThemedButton onClick={() => modalState.setOpen(false)} color="secondary">
            キャンセル
          </ThemedButton>
          <ThemedButton onClick={onSubmit} color="success">
            検索
          </ThemedButton>
        </>
      }
    >
      <label>有効期限</label>
      <Flex className={classes.formMargin} alignItems="center">
        <MonthField label="開始" attr="expiredAtFrom" form={searchForm} clearable />
        <div className={classes.fromTo}>〜</div>
        <MonthField label="終了" attr="expiredAtTo" form={searchForm} clearable />
      </Flex>
      <Flex alignItems="center">
        <Tooltip title={isDisabledShowOnlyUnregister ? '有効期限が入力されていない場合は有効にできません' : ''}>
          <div>
            <CheckBoxField label="未登録のみ表示" attr="showOnlyUnregister" form={searchForm} disabled={isDisabledShowOnlyUnregister} />
          </div>
        </Tooltip>
        <Hint title="未登録とは「有効期限が来る」かつ「有効期限までに校正計画の校正予定月が入力されていない」状態です" />
      </Flex>
    </Modal>
  )
}

const useStyles = makeStyles()({
  formMargin: {
    marginBottom: 10,
  },
  labelMargin: {
    paddingBottom: 10,
  },
  fromTo: {
    paddingLeft: 10,
    paddingRight: 10,
  },
})
