import { FormControlLabel, Checkbox, DialogTitle, DialogContent, DialogActions, Dialog } from '@mui/material'
import ThemedButton from 'components/shared/themed_button'
import React, { useState } from 'react'
import { DisplaySetting, DisplaySettingSet } from 'utils/hooks/index'

type DisplaySettingModalProps = {
  displaySettingSet: DisplaySettingSet
}

export const DisplaySettingModal: React.FC<DisplaySettingModalProps> = (props: DisplaySettingModalProps) => {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <ThemedButton color="secondary" onClick={handleClickOpen}>
        表示設定
      </ThemedButton>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">表示設定</DialogTitle>
        <DialogContent>
          {props.displaySettingSet.settings.map((setting: DisplaySetting, index: number) => {
            return (
              <div key={index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={setting.visible}
                      onChange={(e, checked) => {
                        props.displaySettingSet.update(setting.attr, checked)
                      }}
                      value={setting.name}
                    />
                  }
                  label={setting.name}
                />
              </div>
            )
          })}
        </DialogContent>
        <DialogActions>
          <ThemedButton onClick={handleClose}>閉じる</ThemedButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}
