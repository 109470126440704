import { AxiosHttpClient, HttpClientType } from 'api/axios'
import { useRepruaPostApi } from 'api/shared_api_hooks'
import { ExAttributeBulkUpdateForm, ExAttributeForm } from 'entities/ex_attribute'
import { BaseResponse, ApiSet, Form } from 'rac'

export type ExAttributeApiSet = ApiSet<BaseResponse> & { execute: (form: Form<ExAttributeForm>) => void }

/**
 * 登録・更新
 */
export function usePostExAttributeApi(clientType: HttpClientType): ExAttributeApiSet {
  const api = useRepruaPostApi<BaseResponse, ExAttributeForm>(new AxiosHttpClient(clientType), {
    initialResponse: {},
  })

  const execute = (form: Form<ExAttributeForm>) => {
    const path = 'ex_attributes'
    api.execute(path, form)
  }

  return { ...api, execute }
}

/**
 * 一括登録
 */
export function useBulkUpdateExAttributeApi(clientType: HttpClientType): ExAttributeApiSet {
  const api = useRepruaPostApi<BaseResponse, ExAttributeBulkUpdateForm>(new AxiosHttpClient(clientType), {
    initialResponse: {},
  })

  const execute = (form: Form<ExAttributeBulkUpdateForm>) => {
    const path = 'ex_attributes/bulk_update'
    api.execute(path, form)
  }

  return { ...api, execute }
}
