import { AxiosHttpClient } from 'api/axios'
import {
  useRepruaIndexApi,
  useRepruaPostApi,
  useRepruaPatchApi,
  useRepruaShowApi,
  useRepruaDeleteApi,
  useRepruaDownloadApi,
} from 'api/shared_api_hooks'
import { ID, PagingResponse } from 'entities'
import { getInstrumentSearchQuery, Instrument, InstrumentForm, InstrumentSearchForm, PrintInstrumentBarcodeForm } from 'entities/instrument'
import { IndexApiSet, Form, ApiSet, BaseResponse, IndexApiState } from 'rac'
import { useRecoilState } from 'recoil'
import { ownerFilterAtom } from 'recoil/partner/owner_filter'
import { useEffectSkipFirst } from 'utils/hooks'

type InstrumentsResponse = PagingResponse & {
  instruments: Instrument[]
}

export type PartnerFetchInstrumentsApiSet = IndexApiSet<InstrumentsResponse> & {
  execute: () => void
  executeAndPageReset: () => void
}

/**
 * 一覧
 */
export function usePartnerFetchInstrumentsApi(searchForm: Form<InstrumentSearchForm>, initialState?: Partial<IndexApiState>): PartnerFetchInstrumentsApiSet {
  // 顧客フィルターを適用する
  const [filter] = useRecoilState(ownerFilterAtom)
  const api = useRepruaIndexApi<InstrumentsResponse, InstrumentSearchForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { instruments: [], totalCount: 0 },
    ...((initialState && Object.keys(initialState).length > 0) && { initialState: initialState })
  })

  const { searchText, ...rest } = searchForm.object

  const execute = () => {
    const path = 'instruments'
    api.execute(path, { params: { ...rest, ...filter }, searchQuery: getInstrumentSearchQuery(searchText) })
  }

  const executeAndPageReset = () => {
    if (api.pageSet.page > 1) {
      api.pageSet.setPage(1)
    } else {
      execute()
    }
  }

  useEffectSkipFirst(() => {
    execute()
  }, [api.pageSet.page, api.pageSet.perPage, api.orderSet.order, api.orderSet.orderBy, filter])

  return { ...api, execute, executeAndPageReset }
}

type InstrumentResponse = BaseResponse & {
  instrument: Instrument
}

/**
 * 取得
 */
export type PartnerFetchInstrumentApiSet = ApiSet<InstrumentResponse> & { execute: (id: ID, showLabBasicInfo?: boolean) => void }
export function usePartnerFetchInstrumentApi(showLabBasicInfo?: boolean): PartnerFetchInstrumentApiSet {
  const api = useRepruaShowApi<InstrumentResponse, { id: ID; showLabBasicInfo?: boolean }>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { instrument: {} },
  })

  const execute = (id: ID) => {
    const path = `instruments/${id}`
    if (showLabBasicInfo) {
      api.execute(path, { id, showLabBasicInfo: true })
    } else {
      api.execute(path, { id })
    }
  }

  return { ...api, execute }
}

/**
 * 登録
 */
export function usePartnerPostInstrumentApi(): ApiSet<InstrumentResponse> & { execute: (instrumentForm: Form<InstrumentForm>) => void } {
  const api = useRepruaPostApi<InstrumentResponse, InstrumentForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { instrument: {} },
  })

  const execute = (instrumentForm: Form<InstrumentForm>) => {
    const path = 'instruments'
    api.execute(path, instrumentForm)
  }

  return { ...api, execute }
}

/**
 * 更新
 */
export function usePartnerPatchInstrumentApi(): ApiSet<InstrumentResponse> & { execute: (instrumentForm: InstrumentForm) => void } {
  const api = useRepruaPatchApi<InstrumentResponse, InstrumentForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { instrument: {} },
  })

  const execute = (instrumentForm: InstrumentForm) => {
    const path = `instruments/${instrumentForm.id}`
    api.execute(path, instrumentForm)
  }

  return { ...api, execute }
}

/**
 * 削除
 */
export function usePartnerDeleteInstrumentApi(): ApiSet<InstrumentResponse> & { execute: (id: ID) => void } {
  const api = useRepruaDeleteApi<InstrumentResponse>(new AxiosHttpClient('dealer_user'), { initialResponse: { instrument: {} } })

  const execute = (id: ID) => {
    const path = `instruments/${id}`
    api.execute(path)
  }

  return { ...api, execute }
}

/**
 * 出力
 */
export const usePartnerExportInstrumentsApi = (searchForm: Form<InstrumentSearchForm>): { execute: (format: 'xlsx') => void } => {
  const api = useRepruaDownloadApi<InstrumentSearchForm>(new AxiosHttpClient('dealer_user'))

  const { searchText, ...rest } = searchForm.object

  const execute = (format: 'xlsx') => {
    const path = `instruments.${format}`
    api.execute(path, { params: rest, searchQuery: getInstrumentSearchQuery(searchText) })
  }

  return { execute }
}

/**
 * 印刷
 */
export function usePartnerPrintInstrumentsApi(): ApiSet<BaseResponse> & {
  execute: (form: PrintInstrumentBarcodeForm) => void
} {
  const api = useRepruaPatchApi<BaseResponse, PrintInstrumentBarcodeForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: {},
  })

  const execute = (form: PrintInstrumentBarcodeForm) => {
    const path = 'instruments/print'
    api.execute(path, form)
  }

  return { ...api, execute }
}
