import DialogContent from '@mui/material/DialogContent'
import { Flex } from 'components/shared/flex'
import { StringField } from 'components/shared/form/input'
import Modal, { ModalState, useModal } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { ID } from 'entities'
import { InstrumentCategoryForm, InstrumentCategory } from 'entities/instrument_category'
import { ApiError, ApiSet, Form, useForm } from 'rac'
import React, { useEffect, useContext } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'

const dialogContent = (instrumentCategoryForm: Form<InstrumentCategoryForm>, error: ApiError) => {
  return (
    <DialogContent>
      <Flex>
        <StringField label="カテゴリ名" attr="name" form={instrumentCategoryForm} apiError={error} required />
      </Flex>
    </DialogContent>
  )
}

type NewInstrumentCategoryFormDialogProps<T> = {
  apiSet: T
  onSubmit: (instrumentCategoryForm: Form<InstrumentCategoryForm>) => void
  onComplete: () => void
}

export const NewInstrumentCategoryFormDialog = <T extends ApiSet<any>>({
  apiSet,
  onSubmit,
  onComplete,
}: NewInstrumentCategoryFormDialogProps<T>) => {
  const modalState = useModal()
  const instrumentCategoryForm = useForm<InstrumentCategoryForm>({ name: '' }, 'instrumentCategory')
  const globalState = useContext(GlobalStateContext)

  useEffectSkipFirst(() => {
    globalState.setLoading(apiSet.loading)

    if (apiSet.isSuccess()) {
      globalState.setNotificationMessage({ body: '機器カテゴリを登録しました' })
      instrumentCategoryForm.resetForm()
      onComplete()
      modalState.setOpen(false)
    }
  }, [apiSet.loading])

  const handleOpen = () => {
    modalState.setOpen(true)
  }

  const handleClose = () => {
    modalState.setOpen(false)
  }

  const handleSubmit = () => {
    onSubmit(instrumentCategoryForm)
  }

  return (
    <div>
      <ThemedButton color="primary" onClick={handleOpen}>
        新規登録
      </ThemedButton>

      <Modal
        modalId="instrument-category-new"
        title="機器カテゴリ登録"
        modalState={modalState}
        size="xs"
        footer={
          <>
            <ThemedButton onClick={handleClose} color="secondary">
              閉じる
            </ThemedButton>
            <ThemedButton onClick={handleSubmit} color="success">
              登録
            </ThemedButton>
          </>
        }
      >
        {dialogContent(instrumentCategoryForm, apiSet.apiError)}
      </Modal>
    </div>
  )
}

type EditInstrumentCategoryFormDialogProps<T> = {
  apiSet: T
  instrumentCategory?: InstrumentCategory
  onSubmit: (instrumentCategoryForm: InstrumentCategoryForm) => void
  onComplete: () => void
  modalState: ModalState
}

export const EditInstrumentCategoryFormDialog = <T extends ApiSet<any>>({
  apiSet,
  instrumentCategory,
  onSubmit,
  onComplete,
  modalState,
}: EditInstrumentCategoryFormDialogProps<T>) => {
  const instrumentCategoryForm = useForm<InstrumentCategoryForm>({ ...instrumentCategory }, 'instrumentCategory')
  const globalState = useContext(GlobalStateContext)
  const handleClickClose = () => {
    modalState.setOpen(false)
  }
  const handleSubmit = () => {
    onSubmit(instrumentCategoryForm.object)
  }

  useEffect(() => {
    instrumentCategoryForm.set({ ...instrumentCategory })
  }, [instrumentCategory])

  useEffectSkipFirst(() => {
    globalState.setLoading(apiSet.loading)

    if (apiSet.isSuccess()) {
      globalState.setNotificationMessage({ body: '機器カテゴリを更新しました' })
      modalState.setOpen(false)
      onComplete()
    }
  }, [apiSet.loading])

  return (
    <>
      <Modal
        modalId="instrument-category-edit"
        modalState={modalState}
        title="機器カテゴリ編集"
        size="xs"
        footer={
          <>
            <ThemedButton onClick={handleClickClose} color="secondary">
              閉じる
            </ThemedButton>
            <ThemedButton onClick={handleSubmit} color="success">
              更新
            </ThemedButton>
          </>
        }
      >
        {dialogContent(instrumentCategoryForm, apiSet.apiError)}
      </Modal>
    </>
  )
}

//
// 削除
//
type DeleteInstrumentCategoryFormDialogProps<T> = {
  instrumentCategory: InstrumentCategory
  apiSet: T
  onSubmit: (instrumentCategoryId: ID) => void
  onComplete: () => void
}

export const DeleteInstrumentCategoryButton = <T extends ApiSet<any>>({
  instrumentCategory,
  apiSet,
  onSubmit,
  onComplete,
}: DeleteInstrumentCategoryFormDialogProps<T>) => {
  const globalState = useContext(GlobalStateContext)

  const handleClick = () => {
    let message = '機器カテゴリを削除します。よろしいですか？'
    if (instrumentCategory.instrumentsCount) {
      message = `${instrumentCategory.instrumentsCount}つの機器 で使用されています。削除しますか？`
    }
    globalState.confirm(message, (e) => {
      if (e === 'ok') {
        onSubmit(instrumentCategory.id!)
      }
    })
  }

  useEffect(() => {
    globalState.setLoading(apiSet.loading)
  }, [apiSet.loading])

  useEffectSkipFirst(() => {
    if (apiSet.isSuccess()) {
      globalState.setNotificationMessage({ body: '機器カテゴリを削除しました' })
      onComplete()
    }
  }, [apiSet.loading])

  return (
    <ThemedButton color="error" onClick={handleClick}>
      削除
    </ThemedButton>
  )
}
