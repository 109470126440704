import { Table, TableHead, TableRow, Paper, TableContainer } from '@mui/material'
import { usePartnerFetchDealerDivisionsApi } from 'api/partner/dealer_divisions'
import { usePartnerFetchInstrumentCategoriesApi } from 'api/partner/instrument_categories'
import { usePartnerFetchInstrumentProofreadEventsApi } from 'api/partner/instruments/proofread_events'
import { usePartnerFetchLaboratoriesApi } from 'api/partner/laboratories'
import { usePartnerFetchOutsideLaboratoriesApi } from 'api/partner/outside_laboratories'
import { usePartnerFetchProofreadableInstrumentsApi } from 'api/partner/proofreadable_instrument'
import { ContentHeader, ContentTitle } from 'components/shared/content'
import DropDownCheckbox from 'components/shared/dropdown_checkbox'
import { Flex } from 'components/shared/flex'
import { DropDownCheckboxItem } from 'components/shared/form/input'
import ImageView from 'components/shared/image_view'
import { useModal, useMultipleModal } from 'components/shared/modal'
import Pagination from 'components/shared/pagination'
import { DetailSearchModal } from 'components/shared/proofreadable_instruments/detail_search_modal'
import { PlansModal } from 'components/shared/proofreadable_instruments/plans_modal'
import { SearchBar } from 'components/shared/search_bar'
import { ExTableCell, OrderTableCell, ExTableRow, ExTableBody } from 'components/shared/table'
import { DisplaySettingModal } from 'components/shared/table/display_setting'
import ThemedButton from 'components/shared/themed_button'
import { ID } from 'entities'
import { Instrument, ProofreadableInstrumentSearchForm } from 'entities/instrument'
import { useForm } from 'rac'
import React, { useEffect, useMemo } from 'react'
import { StaticContext } from 'react-router'
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom'
import { PartnerRouteHelper } from 'routes/partner'
import { INDEX_TABLE_PADDING, getTableHeight } from 'utils/component_heights'
import { useDisplaySetting } from 'utils/hooks/index'
import { InstrumentViewModel } from 'view_models/instrument'
import { ProofreadInfoViewModel } from 'view_models/proofread_info'
import 'pages/partner/proofreadable_instrument/index.scss'

const initialDisplaySettings = [
  { attr: 'maker', name: 'メーカー', visible: true },
  { attr: 'outsideLaboratory', name: '顧客', visible: true },
  { attr: 'dealerDivision', name: '部署', visible: true },
]
const initialDetailSearch = {
  expiredAtFrom: '',
  expiredAtTo: '',
  showOnlyUnregister: false,
}

export type ProofreadableInstrumentIndexProps = {}

type Props = RouteComponentProps<{}, StaticContext, ProofreadableInstrumentIndexProps>

const ProofreadableInstrumentIndex: React.FC<Props> = (props: Props) => {
  const searchForm = useForm<ProofreadableInstrumentSearchForm>({ q: {}, ...initialDetailSearch })
  const proofreadableInstrumentIndexApi = usePartnerFetchProofreadableInstrumentsApi(searchForm)
  const displaySettingSet = useDisplaySetting(initialDisplaySettings, 'proofreadable-instrument-table')
  const categoryIndexApi = usePartnerFetchInstrumentCategoriesApi()
  const laboratoriesIndexApi = usePartnerFetchLaboratoriesApi({})
  const dealerDivisionsIndexApi = usePartnerFetchDealerDivisionsApi(false)
  const detailSearchModalState = useModal()
  const plansModalState = useMultipleModal<Instrument>()
  const eventsIndexApi = usePartnerFetchInstrumentProofreadEventsApi(plansModalState.item?.id)
  const history = useHistory()

  useEffect(() => {
    proofreadableInstrumentIndexApi.execute()
    categoryIndexApi.execute()
    laboratoriesIndexApi.execute()
    dealerDivisionsIndexApi.execute()
  }, [])

  const handleSearch = () => {
    proofreadableInstrumentIndexApi.executeAndPageReset()
  }

  const handleDetailSearch = () => {
    proofreadableInstrumentIndexApi.executeAndPageReset()
  }

  const handleClickPlansButton = (instrument: Instrument) => {
    plansModalState.openModal(instrument)
  }

  const handleClickSearchDetailButton = () => {
    detailSearchModalState.setOpen(true)
  }

  const dealerDivisionOptions = useMemo(
    (): DropDownCheckboxItem[] => [
      { value: '', label: '未選択' },
      ...dealerDivisionsIndexApi.response.dealerDivisions.map((division) => ({
        value: division.id!.toString(),
        label: division.name!,
      })),
    ],
    [dealerDivisionsIndexApi.response.dealerDivisions],
  )

  const handleClickPlanDetail = (planId?: ID) => {
    if (planId) {
      history.push(PartnerRouteHelper.proofreadPlanShow(planId))
    }
  }

  useEffect(() => {
    if (proofreadableInstrumentIndexApi.isSuccess()) {
      detailSearchModalState.setOpen(false)
    }
  }, [proofreadableInstrumentIndexApi.loading])

  return (
    <>
      <DetailSearchModal modalState={detailSearchModalState} onSubmit={handleDetailSearch} searchForm={searchForm} />
      <PlansModal
        modalState={plansModalState.modalState}
        instrument={plansModalState.item}
        eventsIndexApi={eventsIndexApi}
        onClickPlanDetail={handleClickPlanDetail}
      />
      <ContentHeader
        subHeader={
          <>
            <DisplaySettingModal displaySettingSet={displaySettingSet} />
          </>
        }
      >
        <ContentTitle title="校正管理 - 機器一覧" />
        <Flex alignItems="center">
          <ThemedButton onClick={handleClickSearchDetailButton} color="secondary">
            詳細検索
          </ThemedButton>
          <SearchBar
            form={searchForm}
            searchAttr="searchText"
            placeholder="メーカー・製品名・型番・シリアル番号・管理番号で検索"
            onSubmit={handleSearch}
          />
        </Flex>
      </ContentHeader>
      <Paper>
        <TableContainer style={{ maxHeight: getTableHeight(INDEX_TABLE_PADDING) }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <ExTableCell width={150}></ExTableCell>
                <ExTableCell width={100} align="center" open={displaySettingSet.isVisible('outsideLaboratory')}>
                  顧客
                </ExTableCell>
                <ExTableCell width={120} open={displaySettingSet.isVisible('dealerDivision')}>
                  <DropDownCheckbox
                    form={searchForm}
                    attr="dealerDivisionIds"
                    name="dealerDivisionIds"
                    items={dealerDivisionOptions}
                    title="部署"
                    onSubmit={handleSearch}
                    btnStyle={{ padding: '4px 8px' }}
                    isLeft={true}
                    maxWidth={600}
                    showFilter
                    autocomplete
                  />
                </ExTableCell>
                <OrderTableCell
                  minWidth={150}
                  orderAttr="maker"
                  orderSet={proofreadableInstrumentIndexApi.orderSet}
                  open={displaySettingSet.isVisible('maker')}
                >
                  メーカー
                </OrderTableCell>
                <OrderTableCell minWidth={200} orderAttr="name" orderSet={proofreadableInstrumentIndexApi.orderSet}>
                  型番 / シリアル番号 / 管理番号 / 製品名
                </OrderTableCell>
                <ExTableCell width={120} align="center">
                  最終校正日
                </ExTableCell>
                <ExTableCell width={100} align="center">
                  有効期限
                </ExTableCell>
                <ExTableCell width={100} align="center">
                  校正周期
                </ExTableCell>
                <ExTableCell width={140} align="center">
                  アラーム
                </ExTableCell>
                <ExTableCell width={100} align="center"></ExTableCell>
              </TableRow>
            </TableHead>
            <ExTableBody loading={proofreadableInstrumentIndexApi.loading}>
              {proofreadableInstrumentIndexApi.response?.instruments.map((instrument: Instrument) => {
                if (instrument.proofreadInfo) {
                  const instrumentVM = new InstrumentViewModel(instrument)
                  const proofreadInfoVM = new ProofreadInfoViewModel(instrument.proofreadInfo)

                  return (
                    <ExTableRow key={instrument.id}>
                      <ExTableCell width={100} align="center">
                        <ImageView maxHeight={100} maxWidth={100} src={instrument.instrumentBasicInfo?.imageUrl} />
                      </ExTableCell>
                      <ExTableCell width={120} open={displaySettingSet.isVisible('outsideLaboratory')}>
                        {instrumentVM.ownerableName()}
                      </ExTableCell>
                      <ExTableCell width={150} open={displaySettingSet.isVisible('dealerDivision')}>
                        {instrumentVM.dealerDivisionNames()}
                      </ExTableCell>
                      <ExTableCell width={150} open={displaySettingSet.isVisible('maker')}>
                        {instrumentVM.getMakerName()}
                      </ExTableCell>
                      <ExTableCell>{instrumentVM.getInstrumentBaseInfo()}</ExTableCell>
                      <ExTableCell width={120} align="center">
                        {proofreadInfoVM.getLastResultDate()}
                      </ExTableCell>
                      <ExTableCell width={100} align="center">
                        {proofreadInfoVM.getExpiredDate()}
                      </ExTableCell>
                      <ExTableCell width={100} align="center">
                        {proofreadInfoVM.getCycle()}
                      </ExTableCell>
                      <ExTableCell width={140} align="center">
                        <div>{proofreadInfoVM.getProofreadAlarmDate() || '-'}</div>
                        {proofreadInfoVM.getAlarmExceededLabel()}
                      </ExTableCell>
                      <ExTableCell width={100} align="center">
                        <Flex flexDirection="column">
                          <ThemedButton color="primary" onClick={() => handleClickPlansButton(instrument)}>
                            計画
                          </ThemedButton>
                        </Flex>
                      </ExTableCell>
                    </ExTableRow>
                  )
                } else {
                  return null
                }
              })}
            </ExTableBody>
          </Table>
        </TableContainer>
        <Pagination response={proofreadableInstrumentIndexApi.response} pageSet={proofreadableInstrumentIndexApi.pageSet} />
      </Paper>
    </>
  )
}

export default withRouter(ProofreadableInstrumentIndex)
