import { Collapse, Table, TableBody, TableRow, Tooltip } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { usePostRepairEventApi, usePatchRepairEventApi, useDeleteRepairEventApi } from 'api/mypage/repair_event'
import { useDeleteAttachmentsApi } from 'api/shared/attachments'
import AttachmentsList from 'components/shared/data/attachments_list'
import { Flex } from 'components/shared/flex'
import { StringField, DateField, NumberField, SelectField, FilesInput, CheckBoxField } from 'components/shared/form/input'
import Modal, { ModalSet, ModalState } from 'components/shared/modal'
import { ExTableCell } from 'components/shared/table'
import ThemedButton from 'components/shared/themed_button'
import { ConfirmCallbackEvent } from 'containers/global_state_container'
import { GlobalStateContext } from 'contexts/global_state_context'
import { MypageStateContext } from 'contexts/mypage_state_context'
import { ID } from 'entities'
import { DefectReport } from 'entities/defect_report'
import { FileAttachment } from 'entities/file_attachment'
import { ProofreadEvent } from 'entities/proofread_event'
import { RepairEstimate } from 'entities/repair_estimate'
import { RepairEventForm, RepairEvent } from 'entities/repair_event'
import { Form, useForm, ApiError } from 'rac'
import React, { useEffect, useContext, useState } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'
import { ProofreadApproverViewModel } from 'view_models/proofread_approver'

const dialogContent = (repairEventForm: Form<RepairEventForm>, error: ApiError, onClickRemoveFile?: (a: FileAttachment) => void) => {
  return (
    <>
      <Flex>
        <SelectField
          form={repairEventForm}
          labelId="proofread-is-label"
          attr="aasmState"
          label="修理履歴"
          data={[
            { value: 'yet', label: '未修理' },
            { value: 'repaired', label: '修理済' },
            { value: 'unrepairable', label: '修理不可' },
          ]}
          apiError={error}
          required
        />
        <NumberField label="修理費用" attr="price" form={repairEventForm} apiError={error} />
      </Flex>

      <Flex>
        <DateField label="修理開始日" attr="startAt" form={repairEventForm} apiError={error} clearable />
        <DateField label="修理完了予定日 / 完了日" attr="endAt" form={repairEventForm} apiError={error} clearable />
      </Flex>
      <Flex>
        <StringField label="修理内容詳細" attr="detail" form={repairEventForm} apiError={error} multiline />
      </Flex>
      <AttachmentsList
        fileAttachments={repairEventForm.object.fileAttachments || []}
        // onClickRemove={onClickRemoveFile}
        emptyText=" "
      />
      <FilesInput form={repairEventForm} attr="attachmentsAttributes" />
      <Collapse in={repairEventForm.object.aasmState !== 'yet' && !!repairEventForm.object.proofreadEventId}>
        <CheckBoxField form={repairEventForm} attr="skipFixResult" label="校正結果の入力をスキップする" labelStyle={{ fontSize: 14 }} />
        <p style={{ paddingBottom: 12 }}>
          修理結果を「修理済」で登録すると、校正結果は合格で登録されます。
          <br />
          修理結果を「修理不可」で登録すると、校正結果は不合格で登録されます。
          <br />
          <span style={{ color: 'red' }}>修理履歴の編集ができなくなります</span>
        </p>
      </Collapse>
    </>
  )
}

type NewRepairEventFormDialogProps = {
  modalState: ModalState
  onComplete: () => void
  instrumentId?: ID
  proofreadEvent?: ProofreadEvent
}

export const NewRepairEventFormDialog: React.FC<NewRepairEventFormDialogProps> = (props: NewRepairEventFormDialogProps) => {
  const repairEventForm = useForm<RepairEventForm>({ aasmState: 'yet' }, 'repairEvent')
  const api = usePostRepairEventApi()
  const globalState = useContext(GlobalStateContext)

  useEffect(() => {
    if (!props.modalState.open) return
    repairEventForm.update((f) => {
      if (props.instrumentId) {
        f.instrumentId = props.instrumentId
      }
      if (props.proofreadEvent) {
        f.proofreadEventId = props.proofreadEvent.id
        if (props.proofreadEvent.defectReport) {
          f.defectReportId = props.proofreadEvent.defectReport.id
        }
      }
    })
  }, [props.modalState.open])

  useEffectSkipFirst(() => {
    globalState.setLoading(api.loading)
    if (api.isSuccess()) {
      props.onComplete()
    }
  }, [api.loading])

  const handleSubmit = () => {
    globalState.confirm('変更した修理期間に含まれる予定はキャンセルされますが、よろしいですか？', (event: ConfirmCallbackEvent) => {
      if (event === 'ok') {
        api.execute(repairEventForm)
      }
    })
  }

  return (
    <Modal
      modalState={props.modalState}
      modalId="new-repair-event"
      title="修理履歴の作成"
      size="sm"
      footer={
        <>
          <ThemedButton onClick={() => props.modalState.setOpen(false)} color="secondary">
            閉じる
          </ThemedButton>
          <ThemedButton onClick={handleSubmit} color="success">
            登録
          </ThemedButton>
        </>
      }
    >
      {dialogContent(repairEventForm, api.apiError)}
    </Modal>
  )
}

type EditRepairEventFormDialogProps = {
  modalState: ModalState
  repairEvent: RepairEvent
  onComplete?: (event?: RepairEvent) => void
  proofreadEvent?: ProofreadEvent
}
export const EditRepairEventFormDialog: React.FC<EditRepairEventFormDialogProps> = (props: EditRepairEventFormDialogProps) => {
  const { modalState, repairEvent, onComplete } = props
  const api = usePatchRepairEventApi()
  const mypageState = useContext(MypageStateContext)
  const deleteApi = useDeleteRepairEventApi()
  const globalState = useContext(GlobalStateContext)
  const deleteFileApi = useDeleteAttachmentsApi('user', 'repair_events')
  const [targetFileId, setTargetFileId] = useState<number>()

  const repairEventForm = useForm<RepairEventForm>(repairEvent, 'repairEvent')

  const onClickRemoveFile = (a: FileAttachment) => {
    setTargetFileId(a.id)
    globalState.confirm(`添付ファイル ${a.name} を削除しますか？ この操作は取り消せません`, (event) => {
      if (event === 'ok') {
        deleteFileApi.execute(props.repairEvent.id!, a.id!)
      }
    })
  }

  useEffectSkipFirst(() => {
    if (!api.loading && !api.isError) {
      onComplete && onComplete(api.response.repairEvent)
      mypageState.reloadUser()
    }
  }, [api.loading])

  useEffect(() => {
    repairEventForm.set(repairEvent)
  }, [repairEvent])

  const handleClickDelete = (repairEvent: RepairEvent) => {
    globalState.confirm('本当に削除しますか？', (event) => {
      if (event === 'ok') {
        deleteApi.execute(repairEvent.id!)
      }
    })
  }

  useEffect(() => {
    if (deleteApi.isSuccess()) {
      onComplete && onComplete()
      mypageState.reloadUser()
    }
  }, [deleteApi.loading])

  useEffectSkipFirst(() => {
    globalState.setLoading(deleteFileApi.loading)
    if (deleteFileApi.isSuccess()) {
      const arr = repairEventForm.getValue('fileAttachments') as FileAttachment[]
      const i = arr.findIndex((a) => a.id === targetFileId)
      arr.splice(i, 1)
      repairEventForm.newUpdateObject('fileAttachments', arr)
    }
  }, [deleteFileApi.loading])

  const handleSubmit = () => {
    globalState.confirm('変更した修理期間に含まれる予定はキャンセルされますが、よろしいですか？', (event: ConfirmCallbackEvent) => {
      if (event === 'ok') {
        api.execute(repairEventForm.object)
      }
    })
  }

  const isDisabledDeleteButton = (repairEvent: RepairEvent) => {
    return !(repairEvent.recorderableId === mypageState.currentUser.id && repairEvent.recorderableType === 'User')
  }

  return (
    <Modal
      modalState={modalState}
      title="修理履歴編集"
      modalId="edit-repair-event"
      size="sm"
      footer={
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', flexDirection: 'row' }}>
          <Tooltip title={isDisabledDeleteButton(props.repairEvent) ? '作成者以外削除出来ません' : ''}>
            <div>
              <ThemedButton
                color="error"
                onClick={() => handleClickDelete(props.repairEvent)}
                disabled={isDisabledDeleteButton(props.repairEvent)}
              >
                削除
              </ThemedButton>
            </div>
          </Tooltip>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <ThemedButton onClick={() => modalState.setOpen(false)} color="secondary" style={{ marginRight: 10 }}>
              閉じる
            </ThemedButton>
            <ThemedButton onClick={handleSubmit} color="success">
              更新
            </ThemedButton>
          </div>
        </div>
      }
    >
      {dialogContent(repairEventForm, api.apiError, onClickRemoveFile)}
      {(props.proofreadEvent?.approvers || []).some((a) => a.kind === 'result' && a.aasmState === 'rejected') && (
        <div style={{ marginTop: 12 }}>
          <label>承認結果</label>
          <Table>
            <TableBody>
              {props.proofreadEvent?.approvers?.map((a) => {
                if (a.kind === 'result') {
                  const aVm = new ProofreadApproverViewModel(a)
                  return (
                    <TableRow key={`approve-${a.id}`}>
                      <ExTableCell style={{ width: 130 }}>
                        <div>
                          {a.approver?.familyName} {a.approver?.givenName}
                        </div>
                      </ExTableCell>
                      <ExTableCell style={{ width: 80 }}>
                        <div>{aVm.getApproveLabel()}</div>
                      </ExTableCell>
                      <ExTableCell>
                        <div>{aVm.getApprovalNote()}</div>
                      </ExTableCell>
                    </TableRow>
                  )
                }
              })}
            </TableBody>
          </Table>
        </div>
      )}
    </Modal>
  )
}

type NewRepairEventFormDialogFromDefectReportProps = {
  onComplete: (repairEvent: RepairEvent) => void
  instrumentId?: ID
  defectReport?: DefectReport
  open: boolean
  onClickClose: () => void
}

export const NewRepairEventFormDialogFromDefectReport: React.FC<NewRepairEventFormDialogFromDefectReportProps> = (
  props: NewRepairEventFormDialogFromDefectReportProps,
) => {
  const repairEventForm = useForm<RepairEventForm>({ aasmState: 'yet' }, 'repairEvent')
  const api = usePostRepairEventApi()
  const globalState = useContext(GlobalStateContext)
  const mypageState = useContext(MypageStateContext)

  useEffect(() => {
    repairEventForm.update((f) => {
      f.instrumentId = props.instrumentId
      f.defectReportId = props.defectReport?.id
      if (props.defectReport?.proofreadEventId) {
        f.proofreadEventId = props.defectReport?.proofreadEventId
      }
    })
  }, [props.instrumentId, props.defectReport])

  useEffectSkipFirst(() => {
    globalState.setLoading(api.loading)
    if (!api.loading && !api.isError) {
      props.onComplete(api.response.repairEvent)
      mypageState.reloadUser()
    }
  }, [api.loading])

  const handleSubmit = () => {
    globalState.confirm('変更した修理期間に含まれる予定はキャンセルされますが、よろしいですか？', (event: ConfirmCallbackEvent) => {
      if (event === 'ok') {
        api.execute(repairEventForm)
      }
    })
  }

  return (
    <div>
      <Dialog open={props.open} onClose={props.onClickClose} aria-labelledby="form-dialog-title">
        <DialogTitle>修理履歴登録</DialogTitle>
        <DialogContent>{dialogContent(repairEventForm, api.apiError)}</DialogContent>
        <DialogActions>
          <ThemedButton onClick={props.onClickClose} color="secondary">
            閉じる
          </ThemedButton>
          <ThemedButton onClick={handleSubmit} color="success">
            登録
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}

type NewRepairEventFormDialogFromEstimateProps = {
  modalSet: ModalSet<RepairEstimate>
  onComplete: () => void
  canReserve?: boolean
}
export const NewRepairEventFormDialogFromEstimate: React.FC<NewRepairEventFormDialogFromEstimateProps> = (props) => {
  const postApi = usePostRepairEventApi()
  const postForm = useForm<RepairEventForm>({
    instrumentId: props.modalSet.item.instrument?.id,
    defectReportId: props.modalSet.item.defectReport?.id,
    suppliableType: props.modalSet.item.suppliableType,
    suppliableId: props.modalSet.item.suppliableId,
    aasmState: 'yet',
    price: props.modalSet.item.total,
  })

  useEffectSkipFirst(() => {
    postForm.resetForm()
  }, [props.modalSet.item])

  const handleSubmit = () => {
    postApi.execute(postForm)
  }

  useEffectSkipFirst(() => {
    if (!postApi.isSuccess()) return
    props.onComplete()
  }, [postApi.loading])

  return (
    <Modal
      modalState={props.modalSet.modalState}
      modalId="create-repair-history-modal"
      title={props.modalSet.item.suppliableType ? '修理を依頼' : '修理履歴を作成'}
      size="sm"
      footer={
        <>
          <ThemedButton color="secondary" onClick={() => props.modalSet.closeModal()}>
            閉じる
          </ThemedButton>
          <ThemedButton color="success" onClick={handleSubmit}>
            修理を依頼
          </ThemedButton>
        </>
      }
    >
      {props.modalSet.item.suppliableType === 'Dealer' && (
        <StringField label="repruaパートナーへのコメント" form={postForm} attr="noteToSupplier" apiError={postApi.apiError} multiline />
      )}
      <Flex flexDirection="column">
        <Flex>
          <DateField label="修理開始日" attr="startAt" form={postForm} apiError={postApi.apiError} clearable />
          <DateField label="修理終了日" attr="endAt" form={postForm} apiError={postApi.apiError} clearable />
        </Flex>
      </Flex>
    </Modal>
  )
}
