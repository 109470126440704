import { Table, TableContainer, TableHead, TableRow } from '@mui/material'
import { ProofreadEventsIndexApiSet } from 'api/mypage/instruments/proofread_events'
import { PartnerFetchInstrumentProofreadEventsApiSet } from 'api/partner/instruments/proofread_events'
import Modal, { ModalState } from 'components/shared/modal'
import Pagination from 'components/shared/pagination'
import { ExTableBody, ExTableCell, ExTableRow } from 'components/shared/table'
import ThemedButton from 'components/shared/themed_button'
import { ID } from 'entities'
import { Instrument } from 'entities/instrument'
import React from 'react'
import { useEffectSkipFirst } from 'utils/hooks'
import { ProofreadEventViewModel } from 'view_models/proofread_event'

type DetailSearchModalProps = {
  modalState: ModalState
  eventsIndexApi: ProofreadEventsIndexApiSet | PartnerFetchInstrumentProofreadEventsApiSet
  onClickPlanDetail: (planId?: ID) => void
  instrument?: Instrument
}

export const PlansModal = React.memo(({ modalState, instrument, eventsIndexApi, onClickPlanDetail }: DetailSearchModalProps) => {
  useEffectSkipFirst(() => {
    if (instrument) {
      eventsIndexApi.execute()
    }
  }, [instrument])

  return (
    <Modal
      modalId="plans-modal"
      title={`計画一覧 - ${instrument?.instrumentBasicInfo?.name}`}
      modalState={modalState}
      size="lg"
      footer={
        <>
          <ThemedButton onClick={() => modalState.setOpen(false)} color="secondary">
            キャンセル
          </ThemedButton>
        </>
      }
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <ExTableCell>計画名</ExTableCell>
              <ExTableCell align="center" width={110}>
                校正月
              </ExTableCell>
              <ExTableCell align="center" width={120}>
                ステータス
              </ExTableCell>
              <ExTableCell align="center" width={100}></ExTableCell>
            </TableRow>
          </TableHead>
          <ExTableBody loading={eventsIndexApi.loading}>
            {eventsIndexApi.response.proofreadEvents.map((event) => {
              const eventVM = new ProofreadEventViewModel(event)
              return (
                <ExTableRow key={event.id}>
                  <ExTableCell>{eventVM.getPlanName()}</ExTableCell>
                  <ExTableCell align="center">{eventVM.getScheduledDate()}</ExTableCell>
                  <ExTableCell align="center">{eventVM.getStatusLabel()}</ExTableCell>
                  <ExTableCell align="center">
                    <ThemedButton color="secondary" onClick={() => onClickPlanDetail(event.proofreadPlanId)}>
                      詳細
                    </ThemedButton>
                  </ExTableCell>
                </ExTableRow>
              )
            })}
          </ExTableBody>
        </Table>
        <Pagination response={eventsIndexApi.response} pageSet={eventsIndexApi.pageSet} />
      </TableContainer>
    </Modal>
  )
})
