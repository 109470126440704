import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import App from 'App'
import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from 'serviceWorker'

import './index.css'

Sentry.init({
  dsn: 'https://12d719a79f034d0b8415dfeb59a1d515@o1262547.ingest.sentry.io/4503917884342272',
  integrations: [new BrowserTracing()],
  environment: process.env.NODE_ENV || 'production',

  tracesSampleRate: 0.1,
})

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
