import { FormControl, Select, MenuItem, SelectChangeEvent, Pagination } from '@mui/material'
import { Flex } from 'components/shared/flex'
import { PagingResponse } from 'entities'
import { PageSet } from 'rac'
import React from 'react'

type Props = {
  response: PagingResponse | null
  pageSet: PageSet
}

export const PaginationTable: React.FC<Props> = (props: Props) => {
  const handleClickPagination = (offset: number) => {
    // props.pageSet.setPage(offset / props.pageSet.perPage + 1)
    props.pageSet.setPage(offset)
  }

  const handleChangePerPage = (event: SelectChangeEvent<number>) => {
    props.pageSet.setPerPage(event.target.value as number)
    props.pageSet.setPage(1)
  }

  const pagination = () => {
    return (
      <Flex alignItems="center" style={{ height: 56.5 }}>
        {props.response && (
          <Pagination
            shape="rounded"
            count={Math.ceil(props.response?.totalCount / props.pageSet.perPage)}
            page={props.pageSet.page}
            onChange={(_, page) => handleClickPagination(page)}
          />
        )}

        <Flex alignItems="center">
          <p style={{ fontSize: 12 }}>表示件数</p>
          <FormControl variant="standard">
            <Select labelId="Pagination-Select-label" id="Pagination-Select" onChange={handleChangePerPage} value={props.pageSet.perPage}>
              <MenuItem value={5}>5件</MenuItem>
              <MenuItem value={10}>10件</MenuItem>
              <MenuItem value={20}>20件</MenuItem>
              <MenuItem value={50}>50件</MenuItem>
              <MenuItem value={100}>100件</MenuItem>
            </Select>
          </FormControl>
        </Flex>
      </Flex>
    )
  }
  return <div className="pagination">{pagination()}</div>
}

export default PaginationTable
