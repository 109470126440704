import { AxiosHttpClient } from 'api/axios'
import {
  useRepruaIndexApi,
  useRepruaShowApi,
  useRepruaPostApi,
  useRepruaPatchApi,
  useRepruaDeleteApi,
  useRepruaDownloadApi,
} from 'api/shared_api_hooks'
import { ID, PagingResponse } from 'entities'
import { getRoomSearchQuery, Room, RoomForm, RoomSearchForm } from 'entities/room'
import { IndexApiSet, ApiSet, Form, BaseResponse, IndexApiState } from 'rac'
import { useEffectSkipFirst } from 'utils/hooks'

type RoomsResponse = PagingResponse & {
  rooms: Room[]
  totalCount: number
}

/**
 * 一覧
 */
export function useFetchRoomsApi(
  initialState?: Partial<IndexApiState>,
  searchForm?: Form<RoomSearchForm>,
): IndexApiSet<RoomsResponse> & { execute: () => void } {
  const api = useRepruaIndexApi<RoomsResponse, RoomSearchForm>(new AxiosHttpClient('user'), {
    initialResponse: { rooms: [], totalCount: 0 },
    initialState: initialState,
  })

  const execute = () => {
    const path = 'rooms'
    const searchText = searchForm?.object.searchText
    if (searchText) {
      api.execute(path, { params: {}, searchQuery: getRoomSearchQuery(searchText) })
    } else {
      api.execute(path)
    }
  }

  useEffectSkipFirst(() => {
    execute()
  }, [api.pageSet.page, api.pageSet.perPage, api.orderSet.order, api.orderSet.orderBy])

  return { ...api, execute }
}

type RoomResponse = BaseResponse & {
  room: Room
}

/**
 * 取得
 */
export function useFetchRoomApi(): ApiSet<RoomResponse> & { execute: (id: ID) => void } {
  const api = useRepruaShowApi<RoomResponse, { id: ID }>(new AxiosHttpClient('user'), { initialResponse: { room: {} } })

  const execute = (id: ID) => {
    const path = `rooms/${id}`
    api.execute(path, { id: id })
  }

  return { ...api, execute }
}

/**
 * 作成
 */
export function usePostRoomApi(): ApiSet<RoomResponse> & { execute: (roomForm: Form<RoomForm>) => void } {
  const api = useRepruaPostApi<RoomResponse, RoomForm>(new AxiosHttpClient('user'), { initialResponse: { room: {} } })

  const execute = (roomForm: Form<RoomForm>) => {
    const path = 'rooms'
    api.execute(path, roomForm)
  }

  return { ...api, execute }
}

/**
 * 更新
 */
export function usePatchRoomApi(): ApiSet<RoomResponse> & { execute: (roomForm: RoomForm) => void } {
  const api = useRepruaPatchApi<RoomResponse, RoomForm>(new AxiosHttpClient('user'), { initialResponse: { room: {} } })

  const execute = (roomForm: RoomForm) => {
    const path = `rooms/${roomForm.id}`
    api.execute(path, roomForm)
  }

  return { ...api, execute }
}

/**
 * 削除
 */
export function useDeleteRoomApi(): ApiSet<RoomResponse> & { execute: (id: ID) => void } {
  const api = useRepruaDeleteApi<RoomResponse>(new AxiosHttpClient('user'), { initialResponse: { room: {} } })

  const execute = (id: ID) => {
    const path = `rooms/${id}`
    api.execute(path)
  }

  return { ...api, execute }
}

/**
 * 出力
 */
export const useExportRoomsApi = (searchForm: Form<RoomSearchForm>): { execute: (format: 'xlsx') => void } => {
  const api = useRepruaDownloadApi<RoomSearchForm>(new AxiosHttpClient('user'))

  const { searchText, ...rest } = searchForm.object

  const execute = (format: 'xlsx') => {
    const path = `rooms.${format}`
    api.execute(path, { params: rest, searchQuery: getRoomSearchQuery(searchText) })
  }

  return { execute }
}
