import { MutationErrorResponse } from 'api/MutationErrorResponse'
import { AxiosHttpClient } from 'api/axios'
import { useRepruaDeleteApi, useRepruaIndexApi } from 'api/shared_api_hooks'
import { InstrumentImportResult } from 'entities/instrument_import_result'
import { BaseResponse, IndexApiSet } from 'rac'
import { useMutation } from 'react-query'
import { useMutationMessageHandler } from 'utils/hooks/use_mutation_message_handler'

type InstrumentImportResultsResponse = BaseResponse & {
  instrumentImportResults: InstrumentImportResult[]
}

type InstrumentImportResultsParams = {
  laboratoryId: number
}

/**
 * ディーラー側のインポート結果の取得
 */
type LaboratoryInstrumentImportResultsApiSet = IndexApiSet<InstrumentImportResultsResponse> & {
  execute: () => void
}
export const useLaboratoryInstrumentImportResults = (): LaboratoryInstrumentImportResultsApiSet => {
  const api = useRepruaIndexApi<InstrumentImportResultsResponse, InstrumentImportResultsParams>(new AxiosHttpClient('user'), {
    initialResponse: { instrumentImportResults: [] },
  })

  const execute = () => {
    const path = 'instrument_sharing'
    api.execute(path)
  }

  return { ...api, execute }
}

type LaboratoryInstrumentImportResultResponse = BaseResponse & {
  instrumentImportResult: InstrumentImportResult
}

type LaboratoryInstrumentImportResultParams = {
  dealerId?: string
  uploadFile: File
}

/**
 * ディーラー側の機器のエクセルインポート
 */
export const usePostLaboratoryInstrumentImportMutation = () => {
  const client = new AxiosHttpClient('user')
  const mutation = useMutation<LaboratoryInstrumentImportResultResponse, MutationErrorResponse, LaboratoryInstrumentImportResultParams>(
    'laboratories_set_instrument_import',
    async (params) => {
      const { data } = await client.post('instrument_sharing/excel', params)
      return data
    },
  )

  useMutationMessageHandler(mutation)

  return mutation
}

/**
 * ディーラー側のインポートの取り消し
 */
export const useDeleteLaboratoryInstrumentImport = () => {
  const api = useRepruaDeleteApi<LaboratoryInstrumentImportResultResponse>(new AxiosHttpClient('user'), {
    initialResponse: { instrumentImportResult: {} },
  })

  const execute = (instrumentImportResultId: number) => {
    const path = `instrument_sharing/${instrumentImportResultId}`
    api.execute(path)
  }

  return { ...api, execute }
}
