import { AxiosHttpClient } from 'api/axios'
import { useRepruaIndexApi, useRepruaPostApi } from 'api/shared_api_hooks'
import { ID, PagingResponse } from 'entities'
import { InquiryComment, InquiryCommentForm } from 'entities/inquiry_comment'
import { ApiSet, BaseResponse, Form, IndexApiSet } from 'rac'
import { useState } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'

export type InquiryCommentsResponse = PagingResponse & {
  comments: InquiryComment[]
}

type InquiriesCommentsIndexApiSet = IndexApiSet<InquiryCommentsResponse> & {
  execute: (id?: ID) => void
  executeAndResetPage: (id?: ID) => void
}

/**
 * コメント一覧
 */
export function usePartnerFetchInquiryCommentsApi(): InquiriesCommentsIndexApiSet {
  const [path, setPath] = useState('')
  const apiSet = useRepruaIndexApi<InquiryCommentsResponse, {}>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { comments: [], totalCount: 0 },
    initialState: { page: 1, perPage: 50 },
  })

  const execute = (id?: ID) => {
    if (!id) return // 0 or undefined の場合
    const currentPath = `inquiries/${id}/comments`
    // useEffectで実行
    setPath(currentPath)
  }

  const executeAndResetPage = (id?: ID) => {
    if (!id) return
    const currentPath = `inquiries/${id}/comments`
    // useEffectで実行
    setPath(currentPath)
  }

  useEffectSkipFirst(() => {
    if (apiSet.pageSet.page > 1) {
      // パスが変わったときにページが1じゃなかった場合、1に戻す。実行はpageのuseEffectで行う
      apiSet.pageSet.setPage(1)
    } else {
      // ページが1なので実行する
      apiSet.execute(path)
    }
  }, [path])

  useEffectSkipFirst(() => {
    if (!path) return
    // pageの変更を監視して実行
    apiSet.execute(path)
  }, [apiSet.pageSet.page])

  return {
    ...apiSet,
    execute,
    executeAndResetPage,
  }
}

export type InquiryCommentResponse = BaseResponse & {
  comment: InquiryComment
}

/**
 * 登録
 */
export function usePartnerPostInquiryCommentApi(): ApiSet<InquiryCommentResponse> & {
  execute: (id: ID, form: Form<InquiryCommentForm>) => void
} {
  const apiSet = useRepruaPostApi<InquiryCommentResponse, InquiryCommentForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { comment: {} },
  })

  const execute = (id: ID, form: Form<InquiryCommentForm>) => {
    const apiPath = `inquiries/${id}/comments`
    apiSet.execute(apiPath, form)
  }

  return { ...apiSet, execute }
}
