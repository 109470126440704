import { Paper, TableContainer, TableHead, Box, Table, TableRow, TableCell } from '@mui/material'
import {
  usePartnerDeleteOutsideLaboratoryApi,
  usePartnerFetchDisabledOutsideLaboratoriesApi,
  usePartnerFetchOutsideLaboratoriesApi,
  usePartnerPatchOutsideLaboratoryApi,
  usePartnerPostOutsideLaboratoryApi,
} from 'api/partner/outside_laboratories'
import {
  NewOutsideLaboratoryFormDialog,
  EditOutsideLaboratoryFormDialog,
  DeleteOutsideLaboratoryButton,
} from 'components/partner/setting/outside_laboratory_form_dialog'
import { Flex } from 'components/shared/flex'
import { useModal } from 'components/shared/modal'
import { ExTableBody } from 'components/shared/table/table_body/ex_table_body'
import { ExTableCell } from 'components/shared/table/table_cell/ex_table_cell'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { PartnerStateContext } from 'contexts/partner_state_context'
import { OutsideLaboratory } from 'entities/outside_laboratory'
import React, { useEffect, useContext, useState } from 'react'

type OutsideLaboratoryListBoxProps = {}

export const OutsideLaboratoryListBox = (_props: OutsideLaboratoryListBoxProps) => {
  const fetchApi = usePartnerFetchOutsideLaboratoriesApi({ fetchAll: true })
  const fetchDisabledOutsideLaboratoriesApi = usePartnerFetchDisabledOutsideLaboratoriesApi()
  const postApi = usePartnerPostOutsideLaboratoryApi()
  const patchApi = usePartnerPatchOutsideLaboratoryApi()
  const deleteApi = usePartnerDeleteOutsideLaboratoryApi()
  const globalState = useContext(GlobalStateContext)
  const { currentDealerUser } = useContext(PartnerStateContext)
  const [outsideLaboratory, setOutsideLaboratory] = useState<OutsideLaboratory>()
  const modalState = useModal()

  const handleClickEditButton = (outsideLaboratory: OutsideLaboratory) => {
    setOutsideLaboratory(outsideLaboratory)
    modalState.setOpen(true)
  }

  useEffect(() => {
    fetchApi.execute()
    fetchDisabledOutsideLaboratoriesApi.execute()
  }, [])

  const handleComplete = () => {
    fetchApi.execute()
    fetchDisabledOutsideLaboratoriesApi.execute()
  }

  useEffect(() => {
    globalState.setLoading(fetchApi.loading)
  }, [fetchApi.loading])

  return (
    <>
      <EditOutsideLaboratoryFormDialog
        onComplete={handleComplete}
        outsideLaboratory={outsideLaboratory}
        modalState={modalState}
        apiSet={patchApi}
        onSubmit={(form) => patchApi.execute(form)}
        disabledOutsideLaboratories={fetchDisabledOutsideLaboratoriesApi.response.outsideLaboratories}
      />
      <Box>
        <Box display="flex" alignItems="center" marginBottom={1}>
          <h3 style={{ marginRight: '10px' }}>外部顧客マスタ</h3>
          {currentDealerUser.authority?.privileged && (
            <NewOutsideLaboratoryFormDialog
              onComplete={handleComplete}
              apiSet={postApi}
              onSubmit={(form) => postApi.execute(form)}
              disabledOutsideLaboratories={fetchDisabledOutsideLaboratoriesApi.response.outsideLaboratories}
            />
          )}
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>名前</TableCell>
                <ExTableCell width={150} />
              </TableRow>
            </TableHead>

            <ExTableBody loading={fetchApi.loading}>
              {fetchApi.response?.outsideLaboratories.map((outsideLaboratory: OutsideLaboratory, idx: number) => {
                return (
                  <TableRow key={idx}>
                    <TableCell>{outsideLaboratory.name}</TableCell>
                    <ExTableCell>
                      {currentDealerUser.authority?.privileged && (
                        <Flex>
                          <ThemedButton color="secondary" onClick={() => handleClickEditButton(outsideLaboratory)}>
                            編集
                          </ThemedButton>
                          <DeleteOutsideLaboratoryButton
                            onComplete={handleComplete}
                            outsideLaboratoryId={outsideLaboratory.id!}
                            apiSet={deleteApi}
                            onSubmit={(id) => {
                              deleteApi.execute(id)
                            }}
                          />
                        </Flex>
                      )}
                    </ExTableCell>
                  </TableRow>
                )
              })}
            </ExTableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}
