import { Table, TableHead, TableRow, TableCell, TableContainer } from '@mui/material'
import {
  useDealerInstrumentImportResults,
  useDeleteDealerInstrumentImport,
  useFetchDealerInstrumentImportFormatMutation,
  usePostDealerInstrumentImportMutation,
} from 'api/partner/instruments/import'
import { ContentHeader, ContentTitle } from 'components/shared/content'
import { Flex } from 'components/shared/flex'
import { RhfBaseTextField, RhfSelectField } from 'components/shared/form/rhf_input'
import Modal, { ModalState, useModal } from 'components/shared/modal'
import { ExTableBody } from 'components/shared/table'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { PartnerStateContext } from 'contexts/partner_state_context'
import { InstrumentImportError } from 'entities/instrument_import_error'
import React, { useEffect, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { StaticContext } from 'react-router'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { useEffectSkipFirst } from 'utils/hooks'
import { InstrumentImportResultViewModel } from 'view_models/instrument_import_result'

type Props = RouteComponentProps<undefined, StaticContext>

/** 機器のインポート画面 */
const InstrumentImport: React.FC<Props> = () => {
  /** 機器インポートのモーダルのステータス */
  const InstrumentImportModalState = useModal()
  /** フォーマットのダウンロードのモーダルのステータス */
  const FormatDownloadModalState = useModal()
  const errorDetailModal = useModal()
  const [importErrors, setImportErrors] = useState<InstrumentImportError[]>([])
  const handleClickImportErrorDetailButton = (importErrors: InstrumentImportError[]) => {
    errorDetailModal.setOpen(true)
    setImportErrors(importErrors)
  }
  const indexApi = useDealerInstrumentImportResults()
  const deleteApi = useDeleteDealerInstrumentImport()
  const globalState = useContext(GlobalStateContext)

  const { currentDealerUser } = useContext(PartnerStateContext)
  const dealerId = currentDealerUser.dealerId || 0

  const loading = indexApi.loading
  const instrumentImportResults = indexApi.response.instrumentImportResults

  const toggleShowModal = () => {
    InstrumentImportModalState.setOpen(!InstrumentImportModalState.open)
  }

  const handleClickDownloadFormat = () => {
    FormatDownloadModalState.setOpen(!FormatDownloadModalState.open)
  }

  const handleComplete = () => {
    indexApi.execute(dealerId)
  }

  const handleClickImportCancelButton = (instrumentImportResultId: number) => {
    globalState.confirm(
      '本当に取消しますか？（Excelファイルからインポートされた機器は削除されますが、作成された顧客マスタ、部署、機器カテゴリは削除されずに残ります。）',
      (event) => {
        if (event === 'ok') {
          deleteApi.execute(instrumentImportResultId)
        }
      },
    )
  }

  useEffectSkipFirst(() => {
    globalState.setLoading(deleteApi.loading)
    if (deleteApi.isSuccess()) {
      handleComplete()
    }
  }, [deleteApi.loading])

  useEffect(() => {
    if (dealerId) {
      indexApi.execute(dealerId)
    }
  }, [dealerId])

  return (
    <>
      <ErrorDetailModal modalState={errorDetailModal} importErrors={importErrors} />
      <InstrumentImportModal modalState={InstrumentImportModalState} handleComplete={handleComplete} />
      <FormatDownloadImportModal modalState={FormatDownloadModalState} handleComplete={handleComplete} />
      <ContentHeader>
        <ContentTitle title="機器インポート">
          <Flex justifyContent="flex-end" style={{ marginTop: 'auto', marginBottom: 'auto' }}>
            <ThemedButton onClick={toggleShowModal} color="success">
              Excelインポート
            </ThemedButton>
            <ThemedButton color="secondary" onClick={handleClickDownloadFormat}>
              フォーマットのダウンロード
            </ThemedButton>
          </Flex>
        </ContentTitle>
        <TableContainer style={{ maxHeight: 600 }}>
          <Table stickyHeader style={{ backgroundColor: '#ffffff' }}>
            <TableHead>
              <TableRow>
                <TableCell>ファイル名</TableCell>
                <TableCell width={80} align="center">
                  総数
                </TableCell>
                <TableCell width={80} align="center">
                  失敗数
                </TableCell>
                <TableCell width={150}>作成日</TableCell>
                <TableCell width={100} align="center">
                  エラー
                </TableCell>
                <TableCell width={80} />
              </TableRow>
            </TableHead>
            <ExTableBody loading={loading} style={{ justifyContent: 'center', alignItems: 'center' }}>
              {instrumentImportResults?.map((result) => {
                const vm = new InstrumentImportResultViewModel(result)
                return (
                  <TableRow key={result.id}>
                    <TableCell>{result.fileName}</TableCell>
                    <TableCell align="center">{result.total}</TableCell>
                    <TableCell align="center">{result.failedCount}</TableCell>
                    <TableCell>{vm.createdAt().format('Y/MM/DD HH:mm')}</TableCell>
                    <TableCell align="center">
                      {result.instrumentImportErrors && result.instrumentImportErrors.length > 0 && (
                        <ThemedButton color="primary" onClick={() => handleClickImportErrorDetailButton(result.instrumentImportErrors!)}>
                          詳細
                        </ThemedButton>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <ThemedButton color="error" onClick={() => handleClickImportCancelButton(result.id!)}>
                        取消
                      </ThemedButton>
                    </TableCell>
                  </TableRow>
                )
              })}
            </ExTableBody>
          </Table>
        </TableContainer>
      </ContentHeader>
    </>
  )
}

export default withRouter(InstrumentImport)

type ErrorDetailModalProps = {
  importErrors: InstrumentImportError[]
  modalState: ModalState
}

/** エラーの詳細モーダル */
const ErrorDetailModal = ({ modalState, importErrors }: ErrorDetailModalProps) => {
  return (
    <Modal
      size="lg"
      modalId="admin-dealer-user-instrument-import-error-detail"
      title="エラー詳細"
      modalState={modalState}
      footer={
        <Flex>
          <ThemedButton onClick={() => modalState.setOpen(false)} color="secondary">
            キャンセル
          </ThemedButton>
        </Flex>
      }
    >
      <TableContainer style={{ maxHeight: 600 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>行数</TableCell>
              <TableCell>エラー内容</TableCell>
            </TableRow>
          </TableHead>
          <ExTableBody loading={false} style={{ justifyContent: 'center', alignItems: 'center' }}>
            {importErrors.map((error) => {
              return (
                <TableRow key={error.id}>
                  <TableCell>{error.rowNumber}</TableCell>
                  <TableCell>{error.message && convertErrorMessage(error.message)}</TableCell>
                </TableRow>
              )
            })}
          </ExTableBody>
        </Table>
      </TableContainer>
    </Modal>
  )
}

const convertErrorMessage = (messages: string) => {
  return (
    <>
      {messages.split(',').map((message, index) => {
        return <p key={`error-message-${index}`}>{message}</p>
      })}
    </>
  )
}

type InstrumentImportModalProps = {
  modalState: ModalState
  handleComplete: () => void
}

type InstrumentImportFormType = {
  outsideLaboratoryId: string
  uploadFile?: any
}

/** 機器インポートのモーダル */
const InstrumentImportModal = ({ modalState, handleComplete }: InstrumentImportModalProps) => {
  const globalState = useContext(GlobalStateContext)

  const form = useForm<InstrumentImportFormType>({
    defaultValues: {
      outsideLaboratoryId: '',
      uploadFile: undefined,
    },
  })
  const postDealerInstrumentImportMutation = usePostDealerInstrumentImportMutation()
  const { selectableOwners } = useContext(PartnerStateContext)

  const handleSubmit = () => {
    const uploadFile = form.watch('uploadFile')
    if (uploadFile === undefined) {
      return
    }
    postDealerInstrumentImportMutation.mutate({
      outsideLaboratoryId: form.watch('outsideLaboratoryId'),
      uploadFile: uploadFile,
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFile = (e: any) => {
    const file = e.target.files[0]
    form.setValue('uploadFile', file)
  }

  useEffectSkipFirst(() => {
    globalState.setLoading(postDealerInstrumentImportMutation.isLoading)
    if (postDealerInstrumentImportMutation.isSuccess) {
      form.reset()
      handleComplete()
      modalState.setOpen(false)
    } else if (postDealerInstrumentImportMutation.isError) {
      const errorMessages = postDealerInstrumentImportMutation.error.response.data.messages
      globalState.setNotificationMessage({
        body: errorMessages?.length ? errorMessages[0] : 'エラーが発生しました。しばらく経ってからアクセスしてください。',
        colorType: 'error',
      })
    }
  }, [postDealerInstrumentImportMutation.isLoading])

  return (
    <Modal
      size="lg"
      modalId="admin-dealer-user-instrument-import-error-detail"
      title="Excelインポート"
      modalState={modalState}
      footer={
        <Flex>
          <ThemedButton onClick={handleSubmit} color="success" disabled={!form.watch('uploadFile')}>
            アップロード
          </ThemedButton>
        </Flex>
      }
    >
      <div style={{ fontSize: 13, marginBottom: 10 }}>
        <p>フォーマットダウンロード時とインポート時は同じ外部顧客を選択してください。</p>
        <p>
          「すべての顧客」を選択してダウンロードしたフォーマットでは、複数の外部顧客のデータを1つのExcelファイルでインポートできますが、
          <br />
          各外部顧客に対して固有に定義した製品管理情報を設定することはできません。
        </p>
      </div>
      <RhfSelectField
        name="outsideLaboratoryId"
        control={form.control}
        items={selectableOwners.outsideLaboratories.map((owner) => ({ value: owner.id!, label: owner.name! }))}
        label="外部顧客"
        includeBlank
        blankLabel="すべての顧客"
      />
      {/* Excelファイルの形式のファイルのみ入力できるように */}
      <RhfBaseTextField
        type="file"
        name="uploadFile"
        control={form.control}
        onChange={handleFile}
        label="ファイル"
        accept=".xlsx,.xlsm,.xlsb,xltx,.xltm,.xls,.xlt,.xls,.xml,.xlam,.xla,.xlw,.xlr"
      />
    </Modal>
  )
}

type FormatDownloadModalProps = {
  modalState: ModalState
  handleComplete: () => void
}

type FormatDownloadFormType = {
  outsideLaboratoryId: number
}

/** フォーマットのダウンロードのモーダル */
const FormatDownloadImportModal = ({ modalState, handleComplete }: FormatDownloadModalProps) => {
  const form = useForm<FormatDownloadFormType>()
  const fetchDealerInstrumentImportFormatMutation = useFetchDealerInstrumentImportFormatMutation()
  const { selectableOwners } = useContext(PartnerStateContext)
  const globalState = useContext(GlobalStateContext)

  const handleSubmit = () => {
    fetchDealerInstrumentImportFormatMutation.mutate({
      outsideLaboratoryId: form.watch('outsideLaboratoryId'),
    })
  }

  useEffectSkipFirst(() => {
    globalState.setLoading(fetchDealerInstrumentImportFormatMutation.isLoading)
    if (fetchDealerInstrumentImportFormatMutation.isSuccess) {
      handleComplete()
      modalState.setOpen(false)
    }
  }, [fetchDealerInstrumentImportFormatMutation.isLoading])

  return (
    <Modal
      size="lg"
      modalId="admin-dealer-user-instrument-import-error-detail"
      title="フォーマットのダウンロード"
      modalState={modalState}
      footer={
        <Flex>
          <ThemedButton onClick={handleSubmit} color="success">
            ダウンロード
          </ThemedButton>
        </Flex>
      }
    >
      <div style={{ fontSize: 13, marginBottom: 10 }}>
        <p>フォーマットダウンロード時とインポート時は同じ外部顧客を選択してください。</p>
        <p>
          「すべての顧客」を選択してダウンロードしたフォーマットでは、複数の外部顧客のデータを1つのExcelファイルでインポートできますが、
          <br />
          各外部顧客に対して固有に定義した製品管理情報を設定することはできません。
        </p>
      </div>
      <RhfSelectField
        name="outsideLaboratoryId"
        control={form.control}
        items={selectableOwners.outsideLaboratories.map((owner) => ({ value: owner.id!, label: owner.name! }))}
        label="外部顧客"
        includeBlank
        blankLabel="すべての顧客"
      />
    </Modal>
  )
}
