import { useCurrentUserQuery } from 'api/mypage/users'
import { GlobalStateContext } from 'contexts/global_state_context'
import { MypageStateContext } from 'contexts/mypage_state_context'
import React, { useState, useContext, useEffect } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { MypageRouteHelper } from 'routes/mypage'
import CookieManager from 'utils/cookie_util'
import { useEffectSkipFirst } from 'utils/hooks'

type Props = {
  children: React.ReactNode
}
export const UserAuth = ({ children }: Props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(CookieManager.hasUserToken())
  const globalState = useContext(GlobalStateContext)
  const mypageState = useContext(MypageStateContext)
  const location = useLocation()
  const currentUserQuery = useCurrentUserQuery()

  useEffect(() => {
    currentUserQuery.refetch()
  }, [])

  useEffectSkipFirst(() => {
    if (currentUserQuery.isSuccess) {
      mypageState.setCurrentUser(currentUserQuery.data.user)
    }
  }, [currentUserQuery.isLoading])

  useEffectSkipFirst(() => {
    if (globalState.statusCode === 401) {
      CookieManager.removeUserToken()
      setIsAuthenticated(false)
    } else if (CookieManager.hasUserToken()) {
      setIsAuthenticated(true)
    }
  }, [globalState.statusCode])

  return isAuthenticated ? <div>{children}</div> : <Redirect to={{ pathname: MypageRouteHelper.login(), state: { from: location } }} />
}
