import { MutationErrorResponse } from 'api/MutationErrorResponse'
import { AxiosHttpClient } from 'api/axios'
import { ID } from 'entities'
import { BaseResponse } from 'rac'
import { useMutation } from 'react-query'
import { useMutationMessageHandler } from 'utils/hooks/use_mutation_message_handler'

type PostExportInstrumentSharingParams = {
  instrumentIds: ID[]
}

export const usePostExportInstrumentSharing = () => {
  const client = new AxiosHttpClient('dealer_user')
  const mutation = useMutation<BaseResponse, MutationErrorResponse, PostExportInstrumentSharingParams>(
    'instrument_sharing_export',
    async (params) => {
      const { data } = await client.download('instrument_sharing_export/excel_export', params)
      return data
    },
  )

  useMutationMessageHandler(mutation)

  return mutation
}
