import { Table, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'
import { useBulkDeleteProofreadEventApi } from 'api/mypage/proofread_event'
import { FetchProofreadEventsApi } from 'api/mypage/proofread_plans/proofread_event'
import NoPlanEventFormDialog from 'components/mypage/proofread/proofread_event/no_plan_event_form_dialog'
import { CheckboxFooter } from 'components/shared/checkbox_footer'
import ImageView from 'components/shared/image_view'
import { useMultipleModal } from 'components/shared/modal'
import Pagination from 'components/shared/pagination'
import { InstrumentCategoryFilterTableHeader } from 'components/shared/proofread/instrument_category_filter_table_header'
import { InstrumentInfoFilterTableHeader } from 'components/shared/proofread/instrument_info_filter_table_header'
import { ExTableCell, ExTableBody, ExTableRow, AllCheckboxTableCell, CheckboxTableCell } from 'components/shared/table'
import { TableExAttributesInfo } from 'components/shared/table_ex_attributes_info'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { ExColumn } from 'entities/ex_column'
import { ProofreadEvent, ProofreadEventForm, ProofreadEventSearchForm } from 'entities/proofread_event'
import { Form, useForm } from 'rac'
import React, { useContext, useEffect } from 'react'
import { getTableHeight, PROOFREAD_CHECKED_TABLE_PADDING, PROOFREAD_TABLE_PADDING } from 'utils/component_heights'
import { useCheckboxes } from 'utils/hooks'
import { useProofreadEventFilter } from 'utils/hooks/use_proofread_event_filter'
import { ProofreadEventViewModel } from 'view_models/proofread_event'

type NoPlanEventTableProps = {
  planId: number
  isPlanCreatedByDealer: boolean
  indexApi: FetchProofreadEventsApi
  searchForm: Form<ProofreadEventSearchForm>
  tableShowColumns: ExColumn[]
  onUpdate: () => void
  onClickHistory: (event: ProofreadEvent) => void
  onClickExAttr: (event: ProofreadEvent) => void
  onClickBulkExAttr: (eventIds: number[]) => void
}
export const NoPlanEventTable = (props: NoPlanEventTableProps) => {
  const proofreadEventFilterSet = useProofreadEventFilter(props.indexApi.response.filter, props.searchForm, props.indexApi)
  const checkboxSet = useCheckboxes(props.indexApi.response.proofreadEvents)
  const globalState = useContext(GlobalStateContext)
  const settingModalSet = useMultipleModal<ProofreadEvent>()

  const bulkDeleteForm = useForm<ProofreadEventForm>({ eventIds: checkboxSet.checkedIds, proofreadPlanId: props.planId })
  const bulkDeleteApi = useBulkDeleteProofreadEventApi(bulkDeleteForm)

  const handleClickSetting = (event: ProofreadEvent) => {
    settingModalSet.openModal(event)
  }
  const handleCompleteSetting = () => {
    settingModalSet.closeModal()
    props.onUpdate()
  }

  const onClickBulkDelete = () => {
    globalState.confirm('校正計画から外します。よろしいですか？', (e) => {
      if (e === 'ok') {
        bulkDeleteApi.execute()
      }
    })
  }
  useEffect(() => {
    bulkDeleteForm.resetForm()
  }, [checkboxSet.checkedIds, props.planId])

  useEffect(() => {
    globalState.setLoading(bulkDeleteApi.loading)
    if (bulkDeleteApi.isSuccess()) {
      props.onUpdate()
    }
  }, [bulkDeleteApi.loading])

  return (
    <>
      <NoPlanEventFormDialog modalSet={settingModalSet} onComplete={handleCompleteSetting} onClickHistory={props.onClickHistory} />
      <TableContainer
        style={{
          maxHeight: getTableHeight(checkboxSet.checkedIds.length > 0 ? PROOFREAD_CHECKED_TABLE_PADDING : PROOFREAD_TABLE_PADDING),
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <AllCheckboxTableCell checkboxSet={checkboxSet} />
              <ExTableCell />
              <ExTableCell>
                <InstrumentInfoFilterTableHeader proofreadEventFilterSet={proofreadEventFilterSet} />
                {props.tableShowColumns.map((col) => (
                  <div style={{ fontSize: 12, lineHeight: '12px' }} key={`tablecol-${col.id}`}>
                    {col.name}
                  </div>
                ))}
              </ExTableCell>
              <ExTableCell align="center">
                <InstrumentCategoryFilterTableHeader proofreadEventFilterSet={proofreadEventFilterSet} />
              </ExTableCell>
              <ExTableCell align="center">部屋</ExTableCell>
              <ExTableCell align="center">最終校正日</ExTableCell>
              <ExTableCell align="center">有効期限</ExTableCell>
              <ExTableCell align="center">校正周期</ExTableCell>
              <ExTableCell />
              <ExTableCell />
            </TableRow>
          </TableHead>
          <ExTableBody loading={props.indexApi.loading}>
            {props.indexApi.response.proofreadEvents.map((event: ProofreadEvent) => {
              const vm = new ProofreadEventViewModel(event)
              return (
                <ExTableRow key={event.id}>
                  <CheckboxTableCell checkboxId={event.id!} checkboxesSet={checkboxSet} />
                  <ExTableCell width={100}>
                    <ImageView maxHeight={100} maxWidth={100} src={vm.getInstrumentImage()} />
                  </ExTableCell>
                  <ExTableCell>
                    {vm.getInstrumentInfo()}
                    <TableExAttributesInfo tableExAttrs={event.tableExAttrs || []} id={event.id} />
                  </ExTableCell>
                  <ExTableCell align="center">{vm.getInstrumentCategoryName()}</ExTableCell>
                  <ExTableCell align="center">{vm.getRoom()}</ExTableCell>
                  <ExTableCell align="center">{vm.getLastResultDate()}</ExTableCell>
                  <ExTableCell align="center">{vm.getExpiredDate()}</ExTableCell>
                  <ExTableCell align="center">{vm.getCycle()}</ExTableCell>
                  <ExTableCell width={122}>
                    <ThemedButton color="success" onClick={() => props.onClickExAttr(event)}>
                      管理情報
                    </ThemedButton>
                  </ExTableCell>
                  <ExTableCell>
                    <Tooltip
                      title={
                        props.isPlanCreatedByDealer
                          ? '業者が作成した校正計画では設定できません'
                          : event.mayPlanned
                          ? ''
                          : '当機器の他の校正計画が進行中のため、計画できません'
                      }
                    >
                      <div>
                        <ThemedButton
                          color="primary"
                          onClick={() => handleClickSetting(event)}
                          disabled={!event.mayPlanned || props.isPlanCreatedByDealer}
                        >
                          設定
                        </ThemedButton>
                      </div>
                    </Tooltip>
                  </ExTableCell>
                </ExTableRow>
              )
            })}
          </ExTableBody>
        </Table>
      </TableContainer>
      <Pagination response={props.indexApi.response} pageSet={props.indexApi.pageSet} />
      <CheckboxFooter
        checkboxSet={checkboxSet}
        content={
          <>
            <ThemedButton color="success" onClick={() => props.onClickBulkExAttr(checkboxSet.checkedIds)} style={{ marginRight: 12 }}>
              管理情報を一括更新
            </ThemedButton>
            <Tooltip title={props.isPlanCreatedByDealer ? '業者が作成した校正計画ではこの操作はできません' : ''}>
              <div>
                <ThemedButton color="error" onClick={onClickBulkDelete} style={{ marginRight: 12 }} disabled={props.isPlanCreatedByDealer}>
                  計画から外す
                </ThemedButton>
              </div>
            </Tooltip>
          </>
        }
      />
    </>
  )
}

export default NoPlanEventTable
