import { useRef, useEffect, EffectCallback, DependencyList } from 'react'

/**
 * 初回ロード時は処理せず、2回目以降処理するEffect.
 * 初回以外は通常のuseEffectと同様に動作する.
 *
 * @param callback 初回以降のEffectで実行する処理.
 * @param effect Dependency list.
 */
export function useEffectSkipFirst(callback: EffectCallback, effect?: DependencyList) {
  const isFirstRender = useRef(true)
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
    } else {
      callback()
    }
  }, effect)
}
