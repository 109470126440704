import { Typography } from '@mui/material'
import DataBlock from 'components/shared/data/data_block'
import DataCard from 'components/shared/data/data_card'
import DataLabel from 'components/shared/data/data_label'
import { Instrument } from 'entities/instrument'
import React, { useEffect, useState } from 'react'
import { ProofreadInfoViewModel } from 'view_models/proofread_info'

type InstrumentProofreadInfoDataCardProps = {
  instrument: Instrument
  showReserve?: boolean
  showProofread?: boolean
}
const InstrumentProofreadInfoDataCard: React.FC<InstrumentProofreadInfoDataCardProps> = (props) => {
  const [proofreadVm, setProofreadVm] = useState(new ProofreadInfoViewModel(props.instrument.proofreadInfo || {}))

  useEffect(() => {
    setProofreadVm(new ProofreadInfoViewModel(props.instrument.proofreadInfo || {}))
  }, [props.instrument])

  return (
    <DataCard>
      <Typography variant="h6">校正管理情報</Typography>
      {props.instrument.requireProofread ? (
        <>
          <DataBlock>
            <DataLabel label="最終校正月" value={proofreadVm.getLastResultDate()} width="30%" />
            <DataLabel label="校正周期" value={proofreadVm.getCycle()} width="30%" />
            <DataLabel label="有効期限" value={proofreadVm.getExpiredDate()} width="30%" />
          </DataBlock>
          <DataBlock noBorder>
            <DataLabel label="校正価格" value={proofreadVm.getPrice()} width="30%" />
            <DataLabel label="デフォルトの委託先" value={proofreadVm.getSupplier()} width="30%" />
          </DataBlock>
        </>
      ) : (
        <>校正管理対象外です</>
      )}
    </DataCard>
  )
}

export default InstrumentProofreadInfoDataCard
