import { AxiosHttpClient } from 'api/axios'
import { useRepruaPatchApi } from 'api/shared_api_hooks'
import { BulkUpdateUserAuthorityForm } from 'entities/user_authority'
import { ApiSet, BaseResponse } from 'rac'

/**
 * 更新
 */
export const useAdminPatchUserAuthorityApi = (
  laboratoryId: number,
): ApiSet<BaseResponse> & {
  execute: (dealerUserAuthorityForm: BulkUpdateUserAuthorityForm) => void
} => {
  const api = useRepruaPatchApi<BaseResponse, {}>(new AxiosHttpClient('admin'), {
    initialResponse: {},
  })

  const execute = (dealerUserAuthorityForm: BulkUpdateUserAuthorityForm) => {
    const path = `laboratories/${laboratoryId}/user_authorities/bulk_update`
    api.execute(path, dealerUserAuthorityForm)
  }

  return { ...api, execute }
}
