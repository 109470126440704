import CancelIcon from '@mui/icons-material/CancelOutlined'
import { IconButton } from '@mui/material'
import { HttpClientType } from 'api/axios'
import { useDeleteExColumnApi, usePatchExColumnApi, usePostExColumnApi } from 'api/shared/ex_columns'
import { Flex } from 'components/shared/flex'
import { CheckBoxField, NumberField, SelectField, StringField } from 'components/shared/form/input'
import Modal, { ModalState } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { PartnerStateContext } from 'contexts/partner_state_context'
import { ExColumn, ExColumnForm, ExtensionClass } from 'entities/ex_column'
import { InstrumentOwnerableType } from 'entities/instrument'
import { ApiError, Form, useForm } from 'rac'
import React, { useContext, useEffect } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'
import { getInputTypeSelectData } from 'view_models/ex_column'

type NewExColumnDialogProps = {
  clientType: HttpClientType
  extensionClass: ExtensionClass
  modalState: ModalState
  onComplete: (column: ExColumn) => void
  dealerClientId?: number
  dealerClientType?: InstrumentOwnerableType
}
export const NewExColumnDialog = (props: NewExColumnDialogProps) => {
  const form = useForm<ExColumnForm>(
    {
      extensionClass: props.extensionClass,
      sortNumber: 1,
      clientableId: props.dealerClientId || null,
      clientableType: props.dealerClientType || null,
    },
    'exColumn',
  )
  const apiSet = usePostExColumnApi(props.clientType)
  const globalState = useContext(GlobalStateContext)
  const handleSubmit = () => {
    apiSet.execute(form)
  }

  const getModalTitle = () => {
    switch (props.extensionClass) {
      case 'InstrumentInfoAttribute':
        return '製品管理情報の作成'
      case 'ProofreadEvent':
        return '校正管理情報の作成'
    }
  }

  useEffectSkipFirst(() => {
    if (apiSet.isSuccess()) {
      globalState.setNotificationMessage({ body: '登録しました' })
      props.onComplete(apiSet.response.exColumn)
    }
  }, [apiSet.loading])

  useEffect(() => {
    globalState.setLoading(apiSet.loading)
  }, [apiSet.loading])

  useEffect(() => {
    form.resetForm()
  }, [props.extensionClass, props.dealerClientType, props.dealerClientId])

  return (
    <Modal
      modalState={props.modalState}
      modalId="create-plan-modal"
      title={getModalTitle()}
      size="sm"
      footer={
        <>
          <ThemedButton onClick={() => props.modalState.setOpen(false)} color="secondary">
            閉じる
          </ThemedButton>
          <ThemedButton onClick={handleSubmit} color="success">
            登録
          </ThemedButton>
        </>
      }
    >
      <ModalBody
        form={form}
        apiError={apiSet.apiError}
        extensionClass={props.extensionClass}
        isClientColumn={!!props.dealerClientId && !!props.dealerClientType}
        clientableType={props.dealerClientType}
      />
    </Modal>
  )
}

type EditExColumnDialogProps = {
  clientType: HttpClientType
  modalState: ModalState
  onComplete: (column: ExColumn) => void
  exColumn: ExColumn | null
}
export const EditExColumnDialog = (props: EditExColumnDialogProps) => {
  const form = useForm<ExColumnForm>({ ...props.exColumn }, 'exColumn')
  const patchApiSet = usePatchExColumnApi(props.clientType)
  const deleteApiSet = useDeleteExColumnApi(props.clientType)
  const globalState = useContext(GlobalStateContext)

  const handleSubmit = () => {
    patchApiSet.execute(form)
  }
  const handleDelete = () => {
    globalState.confirm('今まで設定した情報が削除されます。よろしいですか？', (e) => {
      if (e === 'ok') {
        deleteApiSet.execute(form)
      }
    })
  }
  const getModalTitle = () => {
    switch (props.exColumn?.extensionClass) {
      case 'InstrumentInfoAttribute':
        return '製品管理情報の更新'
      case 'ProofreadEvent':
        return '校正管理情報の更新'
      default:
        return ''
    }
  }

  useEffectSkipFirst(() => {
    if (patchApiSet.isSuccess()) {
      globalState.setNotificationMessage({ body: '更新しました' })
      props.onComplete(patchApiSet.response.exColumn)
    }
  }, [patchApiSet.loading])

  useEffectSkipFirst(() => {
    if (deleteApiSet.isSuccess()) {
      globalState.setNotificationMessage({ body: '削除しました' })
      props.onComplete(deleteApiSet.response.exColumn)
    }
  }, [deleteApiSet.loading])

  useEffect(() => {
    globalState.setLoading(patchApiSet.loading)
  }, [patchApiSet.loading])

  useEffect(() => {
    globalState.setLoading(deleteApiSet.loading)
  }, [deleteApiSet.loading])

  useEffect(() => {
    if (props.exColumn) {
      form.set(props.exColumn)
    }
  }, [props.exColumn])

  return (
    <Modal
      modalState={props.modalState}
      modalId="create-plan-modal"
      title={getModalTitle()}
      size="sm"
      footer={
        <>
          <ThemedButton onClick={() => props.modalState.setOpen(false)} color="secondary">
            閉じる
          </ThemedButton>
          <ThemedButton onClick={handleDelete} color="error">
            削除
          </ThemedButton>
          <ThemedButton onClick={handleSubmit} color="success">
            更新
          </ThemedButton>
        </>
      }
    >
      <ModalBody
        form={form}
        apiError={patchApiSet.apiError}
        extensionClass={props.exColumn?.extensionClass || 'InstrumentInfoAttribute'}
        isClientColumn={!!props.exColumn?.clientableId && !!props.exColumn?.clientableType}
        clientableType={props.exColumn?.clientableType || undefined}
      />
    </Modal>
  )
}

type ModalBodyProps = {
  form: Form<ExColumnForm>
  apiError: ApiError
  extensionClass: ExtensionClass
  isClientColumn?: boolean
  clientableType?: InstrumentOwnerableType
}
const ModalBody = (props: ModalBodyProps) => {
  const { currentDealer } = useContext(PartnerStateContext)

  const handleAddOption = () => {
    props.form.update((f) => {
      if (f.options) {
        f.options = [...f.options, '']
      } else {
        f.options = ['']
      }
    })
  }

  const handleDeleteOption = (index: number) => {
    props.form.update((f) => {
      if (f.options) {
        f.options.splice(index, 1)
        f.options = [...f.options]
      }
    })
  }

  useEffect(() => {
    if (props.form.object.inputType === 'dropdown') {
      if (!props.form.object.options) {
        props.form.update((f) => {
          f.options = ['']
        })
      }
    }
  }, [props.form.object])

  return (
    <>
      <Flex>
        <StringField label="項目名" form={props.form} attr="name" apiError={props.apiError} required />
      </Flex>
      <Flex>
        <SelectField
          label="入力タイプ"
          width={200}
          form={props.form}
          attr="inputType"
          labelId="inputTypeLabel"
          data={getInputTypeSelectData()}
          apiError={props.apiError}
          required
        />
      </Flex>
      {props.form.object.inputType === 'dropdown' &&
        props.form.object.options &&
        props.form.object.options.map((_option, i) => {
          return (
            <Flex alignItems="start" key={i}>
              <StringField label="選択肢を入力" form={props.form} attr={['options', i]} apiError={props.apiError} required />
              <IconButton style={{ minWidth: 30 }} size="large">
                {props.form.object.options!.length > 1 && <CancelIcon color="action" onClick={() => handleDeleteOption(i)} />}
              </IconButton>
            </Flex>
          )
        })}
      {props.form.object.inputType === 'dropdown' && (
        <ThemedButton color="secondary" onClick={() => handleAddOption()} style={{ marginBottom: 10 }}>
          選択肢を追加する
        </ThemedButton>
      )}
      <Flex>
        <NumberField label="並び順" attr="sortNumber" form={props.form} apiError={props.apiError} width={100} />
        <CheckBoxField label="必須項目" attr="required" form={props.form} apiError={props.apiError} />
        <CheckBoxField label="詳細項目" attr="stashed" form={props.form} apiError={props.apiError} />
      </Flex>
      {props.extensionClass === 'InstrumentInfoAttribute' && !props.isClientColumn && (
        <CheckBoxField label="機器一覧に表示する" attr="visibleOnTable" form={props.form} apiError={props.apiError} />
      )}
      {currentDealer.authority?.enableInstrumentSharing &&
        props.extensionClass === 'InstrumentInfoAttribute' &&
        props.clientableType !== 'OutsideLaboratory' && (
          <CheckBoxField label="ラボ側で閲覧可能にする" attr="shared" form={props.form} apiError={props.apiError} />
        )}
    </>
  )
}
