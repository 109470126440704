import { User } from 'entities/user'
import { authorityData } from 'entities/user_authority'
import React from 'react'
import { ViewModel } from 'view_models'

export class UserViewModel extends ViewModel<User> {
  /**
   * フルネーム.
   */
  public fullName(): string {
    return `${this.object.familyName} ${this.object.givenName}`
  }

  public authority(authType: string, index: number): JSX.Element | null {
    const authority = this.object.authority!

    switch (authType) {
      case 'instrument':
        return authority.instrument ? <div style={styles.auth}>{authorityData[authType]}</div> : null
      case 'room':
        return authority.room ? <div style={styles.auth}>{authorityData[authType]}</div> : null
      case 'category':
        return authority.category ? <div style={styles.auth}>{authorityData[authType]}</div> : null
      case 'repair':
        return authority.repair ? <div style={styles.auth}>{authorityData[authType]}</div> : null
      case 'proofread':
        return authority.proofread ? <div style={styles.auth}>{authorityData[authType]}</div> : null
      case 'constraint':
        return authority.constraint ? <div style={styles.auth}>{authorityData[authType]}</div> : null
      case 'privileged':
        return authority.privileged ? <div style={styles.auth}>{authorityData[authType]}</div> : null
      default:
        return null
    }
  }
}

const styles = {
  auth: {
    fontSize: 12,
    display: 'inline-block',
    background: '#4581b9',
    color: '#fff',
    padding: '3px 5px 2px',
    margin: '0 8px 5px 0',
    borderRadius: 3,
  },
}
