import { Typography } from '@mui/material'
import AttachmentsList from 'components/shared/data/attachments_list'
import DataBlock from 'components/shared/data/data_block'
import DataCard from 'components/shared/data/data_card'
import DataLabel from 'components/shared/data/data_label'
import { Flex } from 'components/shared/flex'
import ThemedButton from 'components/shared/themed_button'
import { ProofreadEvent } from 'entities/proofread_event'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { MypageRouteHelper } from 'routes/mypage'
import { ProofreadEventViewModel } from 'view_models/proofread_event'

type ProofreadEventDataCardProps = {
  proofreadEvent: ProofreadEvent | undefined
  showDetail?: boolean
}
const ProofreadEventDataCard: React.FC<ProofreadEventDataCardProps> = (props) => {
  const [vm, setVm] = useState(new ProofreadEventViewModel(props.proofreadEvent || {}))
  const history = useHistory()

  useEffect(() => {
    if (props.proofreadEvent) {
      setVm(new ProofreadEventViewModel(props.proofreadEvent))
    }
  }, [props.proofreadEvent])

  return (
    <DataCard>
      <Flex justifyContent="space-between">
        <Typography variant="h6">校正履歴</Typography>
        {props.proofreadEvent && props.showDetail && (
          <ThemedButton
            color="secondary"
            onClick={() => {
              const planId = props.proofreadEvent?.proofreadPlanId
              if (planId) {
                history.push(MypageRouteHelper.proofreadPlanShow(planId))
              } else {
                console.error('error: proofreadPlanId is null')
              }
            }}
          >
            詳細
          </ThemedButton>
        )}
      </Flex>
      {props.proofreadEvent ? (
        <>
          <DataBlock>
            <DataLabel label="校正月" value={vm.getScheduledDate()} />
            <DataLabel label="校正価格" value={vm.getPrice()} />
          </DataBlock>
          <DataBlock>
            <DataLabel label="委託先" value={vm.getSupplier()} />
            <DataLabel label="担当者" value={vm.getUser()} />
            <DataLabel label="ステータス" value={vm.getStatusLabel()} />
          </DataBlock>
          <DataBlock noBorder>
            <AttachmentsList fileAttachments={props.proofreadEvent.attachments || []} />
          </DataBlock>
        </>
      ) : (
        <span>校正履歴を選択してください</span>
      )}
    </DataCard>
  )
}

export default ProofreadEventDataCard
