import { Typography } from '@mui/material'
import { EditRepairEventFormDialog } from 'components/partner/repair_event/repair_event_form_dialog'
import AttachmentsList from 'components/shared/data/attachments_list'
import DataBlock from 'components/shared/data/data_block'
import DataCard from 'components/shared/data/data_card'
import DataLabel from 'components/shared/data/data_label'
import { Flex } from 'components/shared/flex'
import { useModal } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { RepairEvent } from 'entities/repair_event'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { PartnerRouteHelper } from 'routes/partner'
import { RepairEventViewModel } from 'view_models/repair_event'


type RepairEventDataCardProps = {
  repairEvent?: RepairEvent
  canEdit?: boolean
  onComplete?: (event?: RepairEvent) => void
  noTitle?: boolean
  emptyMessage?: string
  showDetail?: boolean
}
const RepairEventDataCard = (props: RepairEventDataCardProps) => {
  const history = useHistory()
  const [vm, setVm] = useState(new RepairEventViewModel(props.repairEvent || {}))
  const editModalState = useModal()
  const handleClickEdit = () => {
    editModalState.setOpen(true)
  }
  const handleCloseEdit = () => {
    editModalState.setOpen(false)
  }
  const handleComplete = (event?: RepairEvent) => {
    props.onComplete && props.onComplete(event)
    editModalState.setOpen(false)
  }
  const handleClickDetail = () => {
    history.push(PartnerRouteHelper.repairEventShow(props.repairEvent!.id!))
  }

  useEffect(() => {
    if (props.repairEvent) {
      setVm(new RepairEventViewModel(props.repairEvent))
    }
  }, [props.repairEvent])

  return (
    <>
      <EditRepairEventFormDialog
        onComplete={handleComplete}
        modalState={editModalState}
        onClose={handleCloseEdit}
        repairEvent={props.repairEvent}
      />
      <DataCard>
        <Flex alignItems="center" justifyContent="space-between">
          {!props.noTitle && <Typography variant="h6">修理履歴</Typography>}
          {props.repairEvent && props.showDetail && (
            <ThemedButton color="secondary" onClick={handleClickDetail}>
              詳細
            </ThemedButton>
          )}
          {props.canEdit && props.repairEvent && (
            <ThemedButton color="primary" onClick={handleClickEdit}>
              編集
            </ThemedButton>
          )}
        </Flex>

        {props.repairEvent ? (
          <>
            <DataBlock>
              <DataLabel label="記録者" value={vm.getRecorderName()} />
              <DataLabel label="修理期間" value={vm.getPeriodText()} />
              <DataLabel label="修理費用" value={vm.getPriceText()} />
            </DataBlock>
            <DataBlock>
              <DataLabel label="修理結果" value={vm.getResultText()} width="50%" />
              <DataLabel label="修理内容詳細" value={vm.getDetail()} />
            </DataBlock>
            <DataBlock noBorder>
              <AttachmentsList fileAttachments={props.repairEvent.fileAttachments || []} />
            </DataBlock>
          </>
        ) : (
          <span>{props.emptyMessage || '修理履歴を選択してください'}</span>
        )}
      </DataCard>
    </>
  )
}

export default RepairEventDataCard
