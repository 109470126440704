import { AxiosHttpClient } from 'api/axios'
import { useRepruaIndexApi, useRepruaDownloadApi } from 'api/shared_api_hooks'
import { ReservationSearchForm, ReservationsResponse } from 'entities/reservation'
import { ReservationDownloadForm } from 'entities/reservation_download'
import { Form, IndexApiSet } from 'rac'

/**
 * 一覧
 */
export function useFetchLaboratoryReservationsApi(): IndexApiSet<ReservationsResponse> & {
  execute: (form: Form<ReservationSearchForm>) => void
} {
  const api = useRepruaIndexApi<ReservationsResponse, ReservationSearchForm>(new AxiosHttpClient('user'), {
    initialResponse: { reservations: [], totalCount: 0 },
  })

  const execute = (form: Form<ReservationSearchForm>) => {
    const path = 'laboratories/reservations'
    api.execute(path, { params: form.object })
  }

  return { ...api, execute }
}

// 全ての条件でエクセルをダウンロードする
export function useFetchXlsxLaboratoryReservationsApi(): { execute: (form?: Form<ReservationDownloadForm>) => void } {
  const api = useRepruaDownloadApi<ReservationDownloadForm>(new AxiosHttpClient('user'))
  
  const execute = (form?: Form<ReservationDownloadForm>) => {
    const path = `laboratories/reservations.xlsx?submit_type=${form ? 'search' : 'all'}`
    api.execute(path, form ? { params: form.object} : {})
  }

  return { execute }
}
