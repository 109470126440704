/**
 * 機器の登録可能数と機器数の表示
 */

import { Typography } from '@mui/material'
import { Flex } from 'components/shared/flex'
import { Hint } from 'components/shared/hint'
import { MypageStateContext } from 'contexts/mypage_state_context'
import React, { useContext } from 'react'

export const SubscriptionPlanLimitCount = () => {
  const mypageState = useContext(MypageStateContext)
  const limitCount = mypageState.currentUser.laboratory?.subscriptionPlan?.limitCount
  const instrumentTotalCount = mypageState.instrumentTotalCount
  // 登録可能な機器数の上限と等しいまたは超えているかどうか
  const isPlanOverLimit = limitCount != null && instrumentTotalCount != null && instrumentTotalCount >= limitCount

  return (
    <>
      {/* limitCountが存在しない（データがないもしくは、無制限プラン）場合は表示させない */}
      {limitCount != null && (
        <Flex alignItems="center">
          <Typography variant="body2" color={isPlanOverLimit ? 'red' : 'GrayText'} style={{ marginRight: 0 }}>
            登録可能機器数（{instrumentTotalCount || 0}/{limitCount}）
          </Typography>
          <Hint
            title={
              <>
                <div>現在の登録機器数 / 現在のプランで登録可能な機器数 が表示されています</div>
                <div>プランによって登録可能な機器数が異なります。</div>
              </>
            }
          />
        </Flex>
      )}
    </>
  )
}
