import React from 'react'
import { makeStyles } from 'tss-react/mui'

type AccessDeniedMessageProps = {
  target: string
}
export const AccessDeniedMessage: React.FC<AccessDeniedMessageProps> = (props) => {
  const { classes } = useStyle()
  return (
    <div className={classes.root}>
      {props.target}を閲覧する権限がありません。
      <br />
      {props.target}を確認する必要がある場合、管理者の方にご相談ください。
    </div>
  )
}

export default AccessDeniedMessage

const useStyle = makeStyles()(() => ({
  root: {
    padding: '60px 0',
    position: 'relative',
    textAlign: 'center',
  },
}))
