import { useState } from 'react'

import { useEffectSkipFirst } from './use_effect_skip_first'

/**
 * 設定値1つあたりの要素
 */
export type DisplaySetting = {
  attr: string
  name: string
  visible: boolean
}

export type DisplaySettingSet = {
  settings: DisplaySetting[]
  update: (attr: string, isVisible: boolean) => void
  isVisible: (attr: string) => boolean
  visibleCount: () => number
}

/**
 * 表示設定をコントロールし、設定値をセッションに保存する
 *
 * @param initialSettings 表示設定の初期値
 * @param sessionName 保存するセッションの名前
 */
export function useDisplaySetting(initialSettings: DisplaySetting[], sessionName: string): DisplaySettingSet {
  /**
   * セッションに値がある場合はセッションの値を読み込み、ない場合はinitialSettingsを読み込む
   */
  const getSettings = (): DisplaySetting[] => {
    const settingJson = localStorage.getItem(sessionName)
    if (settingJson) {
      const setting: DisplaySetting[] = JSON.parse(settingJson)
      return setting
    } else {
      return initialSettings
    }
  }

  // const form = useForm<DisplaySetting[]>(getSettings())
  // const settings = form.object
  const [settings, setSettings] = useState<DisplaySetting[]>(getSettings())

  /**
   * セッションに保存する.
   */
  const saveSettings = () => {
    const settingJson = JSON.stringify(settings)
    localStorage.setItem(sessionName, settingJson)
  }

  /**
   * 設定値変更時のコールバック
   */
  useEffectSkipFirst(() => {
    saveSettings()
  }, [settings])

  /**
   * 設定から指定したカラムの表示設定を取得する
   *
   * @param attr カラム名
   */
  const getSetting = (attr: string): DisplaySetting | undefined => {
    return settings.find((setting) => setting.attr === attr)
  }

  /**
   * カラム名の設定値がvisible trueになっているか
   *
   * @param attr カラム名
   */
  const isVisible = (attr: string): boolean => {
    const setting = getSetting(attr)
    if (setting) {
      return setting.visible
    } else {
      return true
    }
  }

  const visibleCount = (): number => {
    return settings.filter((s) => s.visible).length
  }

  const update = (attr: string, isVisible: boolean) => {
    const copiedSettings: DisplaySetting[] = [...settings]
    const newSetting: DisplaySetting | undefined = getSetting(attr)
    if (!newSetting) return
    newSetting.visible = isVisible
    setSettings(
      copiedSettings.map((setting) => {
        return setting.attr !== attr ? setting : newSetting
      }),
    )
  }

  const displaySettingSet = {
    settings: settings,
    update: update,
    isVisible: isVisible,
    visibleCount: visibleCount,
  }

  return displaySettingSet
}
