import { Table, TableHead, TableRow, Tooltip } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useFetchLaboratoriesApi } from 'api/mypage/laboratory'
import { useFetchUsersManagerApi } from 'api/mypage/manager/user_authority copy'
import { useFetchReservationConstraintSetsApi } from 'api/mypage/reservation_constraint_set'
import { useFetchUsersApi } from 'api/mypage/users'
import { Flex } from 'components/shared/flex'
import { SelectField, SelectData } from 'components/shared/form/input'
import { ExTableBody, ExTableCell } from 'components/shared/table'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { MypageStateContext } from 'contexts/mypage_state_context'
import { Laboratory } from 'entities/laboratory'
import {
  ReservationConstraintRelation,
  reservationTargetableType,
  ReservationConstraintRelationForm,
} from 'entities/reservation_constraint_relation'
import { ReservationConstraintSet } from 'entities/reservation_constraint_set'
import { User } from 'entities/user'
import { Form, useForm } from 'rac'
import React, { useEffect, useContext } from 'react'
import { useEffectSkipFirst } from 'utils/hooks/index'
import { ReservationConstraintRelationViewModel } from 'view_models/reservation_constraint_relation'
import { UserViewModel } from 'view_models/user'

import {
  usePostReservationConstraintRelationApi,
  useDeleteReservationConstraintRelationApi,
} from '../../../api/mypage/reservation_constraint_relation'

type DialogFormContentProps = {
  reservationConstraintRelations: ReservationConstraintRelation[]
  constraintRelationForm: Form<ReservationConstraintRelationForm>
  reservationConstraintSets: ReservationConstraintSet[]
  users: User[]
  laboratories: Laboratory[]
  onClickDelete: (relationId: number) => void
  disabled: boolean
}

const DialogFormContent: React.FC<DialogFormContentProps> = (props) => {
  const getConstraintSetSelectData = (): SelectData[] => {
    const data: SelectData[] = props.reservationConstraintSets.map((constraintSet) => {
      return { label: constraintSet.name!, value: constraintSet.id! }
    })
    return data
  }

  const getConstraintTargetableTypeSelectData = (): SelectData[] => {
    const data: SelectData[] = [
      { label: '利用者全体', value: 'All' },
      { label: 'ユーザー', value: 'User' },
    ]
    if (props.laboratories && props.laboratories.length > 0) {
      data.push({ label: 'ラボ', value: 'Laboratory' })
    }
    return data
  }

  const getConstraintTargetableIdSelectData = (): SelectData[] => {
    if (!props.constraintRelationForm.object.constraintTargetableType) {
      return []
    } else if (props.constraintRelationForm.object.constraintTargetableType === 'User') {
      return props.users.map((user) => {
        return { label: user.nameWithLabName || new UserViewModel(user).fullName(), value: user.id! }
      })
    } else if (props.constraintRelationForm.object.constraintTargetableType === 'Laboratory') {
      return props.laboratories.map((laboratory) => {
        return { label: laboratory.name!, value: laboratory.id! }
      })
    } else {
      return []
    }
  }

  useEffectSkipFirst(() => {
    props.constraintRelationForm.update((f) => {
      delete f.constraintTargetableId
    })
  }, [props.constraintRelationForm.object.constraintTargetableType])

  const isVisibleConstraintTargetableIdView = () => {
    const constraintTargetableType = props.constraintRelationForm.object.constraintTargetableType
    return constraintTargetableType && constraintTargetableType !== 'All'
  }

  return (
    <DialogContent>
      <div>設定されている条件</div>
      <Table>
        <TableHead>
          <TableRow>
            <ExTableCell minWidth={200}>条件名</ExTableCell>
            <ExTableCell width={200}>条件対象</ExTableCell>
            <ExTableCell width={100}></ExTableCell>
          </TableRow>
        </TableHead>
        <ExTableBody emptyMessage="設定されている予約条件はありません" loading={false}>
          {props.reservationConstraintRelations.map((relation) => {
            const vm = new ReservationConstraintRelationViewModel(relation)
            return (
              <TableRow key={relation.id}>
                <ExTableCell>{relation.reservationConstraintSet?.name}</ExTableCell>
                <ExTableCell>{vm.constraintTargetableName()}</ExTableCell>
                <ExTableCell>
                  <Tooltip title={props.disabled ? '予約条件設定権限がありません' : ''}>
                    <div>
                      <ThemedButton color="error" size="small" onClick={() => props.onClickDelete(relation.id!)} disabled={props.disabled}>
                        解除
                      </ThemedButton>
                    </div>
                  </Tooltip>
                </ExTableCell>
              </TableRow>
            )
          })}
        </ExTableBody>
      </Table>
      <Flex style={{ marginTop: 20 }}>
        <SelectField
          label="予約条件"
          labelId="constraintSet"
          form={props.constraintRelationForm}
          attr="reservationConstraintSetId"
          data={getConstraintSetSelectData()}
          includeBlank
          disabled={props.disabled}
        />
        <SelectField
          label="条件対象タイプ"
          labelId="constraintTargetableType"
          form={props.constraintRelationForm}
          attr="constraintTargetableType"
          data={getConstraintTargetableTypeSelectData()}
          includeBlank
          disabled={props.disabled}
        />
        <div style={{ display: isVisibleConstraintTargetableIdView() ? 'block' : 'none' }}>
          <SelectField
            label="条件対象"
            labelId="constraintTargetableId"
            form={props.constraintRelationForm}
            attr="constraintTargetableId"
            data={getConstraintTargetableIdSelectData()}
            disabled={props.constraintRelationForm.object.constraintTargetableType === 'All'}
            includeBlank
          />
        </div>
      </Flex>
    </DialogContent>
  )
}

type ConstraintRelationFormDialogProps = {
  open: boolean
  onComplete: () => void
  reservationConstraintRelations: ReservationConstraintRelation[]
  reservationTargetableType: reservationTargetableType
  reservationTargetableId: number
  onClose: () => void
}

export const ConstraintRelationFormDialog: React.FC<ConstraintRelationFormDialogProps> = (props: ConstraintRelationFormDialogProps) => {
  const api = usePostReservationConstraintRelationApi()
  const globalState = useContext(GlobalStateContext)
  const { currentUser } = useContext(MypageStateContext)
  const constraintRelationForm = useForm<ReservationConstraintRelationForm>({
    reservationTargetableType: props.reservationTargetableType,
    reservationTargetableId: props.reservationTargetableId,
  })
  const deleteApi = useDeleteReservationConstraintRelationApi()
  const usersApi = useFetchUsersApi()
  const managedLaboratoriesApi = useFetchLaboratoriesApi()
  const managedUsersApi = useFetchUsersManagerApi()
  const reservationConstraintSetsApi = useFetchReservationConstraintSetsApi()

  useEffect(() => {
    usersApi.execute()
    reservationConstraintSetsApi.execute()
    if (currentUser.laboratory?.isManager) {
      managedUsersApi.execute()
      managedLaboratoriesApi.execute()
    }
  }, [])

  useEffect(() => {
    globalState.setLoading(api.loading)
    if (api.isSuccess()) {
      props.onComplete()
      constraintRelationForm.set({
        reservationTargetableType: props.reservationTargetableType,
        reservationTargetableId: props.reservationTargetableId,
      })
    }
  }, [api.loading])

  const handleSubmit = () => {
    api.execute(constraintRelationForm)
  }

  const handleClickDelete = (relationId: number) => {
    deleteApi.execute(relationId)
  }

  useEffectSkipFirst(() => {
    globalState.setLoading(deleteApi.loading)
    if (deleteApi.isSuccess()) {
      props.onComplete()
    }
  }, [deleteApi.loading])

  return (
    <div>
      <>
        <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title" maxWidth="xl">
          <DialogTitle id="form-dialog-title">予約条件の設定</DialogTitle>
          <DialogFormContent
            onClickDelete={handleClickDelete}
            users={usersApi.response.users.concat(managedUsersApi.response.users)}
            laboratories={[...managedLaboratoriesApi.response.laboratories, currentUser.laboratory!]}
            reservationConstraintSets={reservationConstraintSetsApi.response.reservationConstraintSets}
            constraintRelationForm={constraintRelationForm}
            reservationConstraintRelations={props.reservationConstraintRelations}
            disabled={!currentUser.authority?.constraint}
          />
          <DialogActions>
            <ThemedButton onClick={props.onClose}>閉じる</ThemedButton>
            <Tooltip title={!currentUser.authority?.constraint ? '予約条件設定権限がありません' : ''}>
              <div>
                <ThemedButton onClick={handleSubmit} color="success" disabled={!currentUser.authority?.constraint}>
                  登録
                </ThemedButton>
              </div>
            </Tooltip>
          </DialogActions>
        </Dialog>
      </>
    </div>
  )
}
