import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { Table, TableHead, TableRow, TableContainer } from '@mui/material'
import { ProofreadSchedulesResponse } from 'api/mypage/proofread_plans/proodread_schedule'
import { useFetchProofreadEventsApi } from 'api/mypage/proofread_plans/proofread_event'
import ApprovedPlanEventFormDialog from 'components/mypage/proofread/proofread_event/approved_plan_event_form_dialog'
import ImageView from 'components/shared/image_view'
import { useMultipleModal } from 'components/shared/modal'
import Pagination from 'components/shared/pagination'
import { ExTableCell, ExTableBody, ExTableRow } from 'components/shared/table'
import ThemedButton from 'components/shared/themed_button'
import { ProofreadEvent, ProofreadEventSearchForm } from 'entities/proofread_event'
import { ProofreadSchedule } from 'entities/proofread_schedule'
import { IndexApiSet, useForm } from 'rac'
import React, { useEffect } from 'react'
import { getTableHeight, PROOFREAD_TABLE_PADDING } from 'utils/component_heights'
import { ProofreadEventViewModel } from 'view_models/proofread_event'
import { ProofreadScheduleViewModel } from 'view_models/proofread_schedule'

type ApprovedPlanEventTableProps = {
  indexApi: IndexApiSet<ProofreadSchedulesResponse> & { execute: () => void }
  onUpdate: () => void
  planId: number
}
export const ApprovedPlanEventTable: React.FC<ApprovedPlanEventTableProps> = (props) => {
  const approvalModal = useMultipleModal<ProofreadSchedule>()
  const searchForm = useForm<ProofreadEventSearchForm>({ q: { aasmStateIn: ['approved_plan'] } })
  const eventApi = useFetchProofreadEventsApi(props.planId, searchForm)

  const handleClickCheck = (schedule: ProofreadSchedule) => {
    approvalModal.openModal(schedule)
  }

  const handleComplete = () => {
    approvalModal.closeModal()
    eventApi.execute()
    props.onUpdate()
  }

  useEffect(() => {
    eventApi.execute()
  }, [])

  return (
    <>
      <ApprovedPlanEventFormDialog modalSet={approvalModal} onComplete={handleComplete} />
      <TableContainer style={{ maxHeight: getTableHeight(PROOFREAD_TABLE_PADDING) }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <ExTableCell align="center">引渡予定日</ExTableCell>
              <ExTableCell align="center">返却予定日</ExTableCell>
              <ExTableCell align="center">パートナー</ExTableCell>
              <ExTableCell align="center">対象機器数</ExTableCell>
              <ExTableCell align="center">担当者</ExTableCell>
              <ExTableCell align="center">日程承認</ExTableCell>
              <ExTableCell />
            </TableRow>
          </TableHead>
          <ExTableBody loading={props.indexApi.loading}>
            {props.indexApi.response.proofreadSchedules.map((schedule: ProofreadSchedule) => {
              const vm = new ProofreadScheduleViewModel(schedule)
              return (
                <ExTableRow key={schedule.id}>
                  <ExTableCell align="center">{vm.getDeliveredDate()}</ExTableCell>
                  <ExTableCell align="center">{vm.getReturnedDate()}</ExTableCell>
                  <ExTableCell align="center">{vm.getSupplier()}</ExTableCell>
                  <ExTableCell align="center">{vm.getInstrumentCount()}</ExTableCell>
                  <ExTableCell align="center">
                    {vm.getManagers().length > 0 ? vm.getManagers().map((manager, index) => <p key={index}>{manager}</p>) : '-'}
                  </ExTableCell>
                  <ExTableCell align="center">
                    {schedule.aasmState === 'requested' ? '' : <CheckCircleOutlineIcon style={{ color: 'green' }} />}
                  </ExTableCell>
                  <ExTableCell>
                    {schedule.deliveredDate ? (
                      <ThemedButton
                        color={schedule.aasmState === 'requested' ? 'success' : 'secondary'}
                        onClick={() => handleClickCheck(schedule)}
                      >
                        {schedule.aasmState === 'requested' ? '承認依頼を確認' : '確認'}
                      </ThemedButton>
                    ) : (
                      <span>調整中</span>
                    )}
                  </ExTableCell>
                </ExTableRow>
              )
            })}
          </ExTableBody>
        </Table>
        <Pagination response={props.indexApi.response} pageSet={props.indexApi.pageSet} />
        <div style={{ padding: 10, backgroundColor: '#FFF2CC', margin: 10, marginTop: 64 }}>
          以下はパートナーによる日程調整待ちの機器です。ラボ側での作業はありません
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <ExTableCell colSpan={2} align="center">
                製品名
              </ExTableCell>
              <ExTableCell align="center">担当者</ExTableCell>
              <ExTableCell align="center">部屋</ExTableCell>
              <ExTableCell align="center">校正月</ExTableCell>
              <ExTableCell align="center">パートナー</ExTableCell>
              <ExTableCell />
            </TableRow>
          </TableHead>
          <ExTableBody loading={eventApi.loading}>
            {eventApi.response.proofreadEvents
              .filter((e) => !e.proofreadScheduleId)
              .map((event: ProofreadEvent) => {
                const vm = new ProofreadEventViewModel(event)
                return (
                  <ExTableRow key={event.id}>
                    <ExTableCell width={100}>
                      <ImageView maxHeight={100} maxWidth={100} src={vm.getInstrumentImage()} />
                    </ExTableCell>
                    <ExTableCell>{vm.getInstrumentInfo()}</ExTableCell>
                    <ExTableCell align="center">{vm.getUser()}</ExTableCell>
                    <ExTableCell align="center">{vm.getRoom()}</ExTableCell>
                    <ExTableCell align="center">{vm.getScheduledDate()}</ExTableCell>
                    <ExTableCell align="center">{vm.getSupplier()}</ExTableCell>
                  </ExTableRow>
                )
              })}
          </ExTableBody>
        </Table>
      </TableContainer>
      <Pagination response={eventApi.response} pageSet={eventApi.pageSet} />
    </>
  )
}

export default ApprovedPlanEventTable
