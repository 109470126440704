import { Paper, TableContainer, Table, TableHead, TableRow } from '@mui/material'
import { useFetchAdminDealersApi } from 'api/admin/dealers'
import { ContentHeader, ContentTitle } from 'components/shared/content'
import { OrderTableCell, ExTableCell, ExTableBody, ExTableRow } from 'components/shared/table'
import ThemedButton from 'components/shared/themed_button'
import { Dealer } from 'entities/dealer'
import React, { useEffect } from 'react'
import { StaticContext } from 'react-router'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AdminRouteHelper } from 'routes/admin'

type DealerIndexProps = {}

type Props = RouteComponentProps<{}, StaticContext, DealerIndexProps>

export const DealerIndex: React.FC<Props> = (props) => {
  const api = useFetchAdminDealersApi()

  useEffect(() => {
    api.execute()
  }, [])

  const handleClickDetail = (dealer: Dealer) => {
    props.history.push({
      pathname: AdminRouteHelper.dealerShow(dealer.id!),
      state: {},
    })
  }

  return (
    <>
      <ContentHeader>
        <ContentTitle title="ディーラー一覧"></ContentTitle>
      </ContentHeader>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <OrderTableCell minWidth={250} orderAttr="name" orderSet={api.orderSet}>
                  ディーラー名称
                </OrderTableCell>
                <ExTableCell width={80} align="center" />
              </TableRow>
            </TableHead>
            <ExTableBody loading={api.loading}>
              {api.response?.dealers.map((dealer: Dealer) => {
                return (
                  <ExTableRow key={dealer.id} id={`dealer-${dealer.id}`}>
                    <ExTableCell>
                      {dealer.name} {dealer.branch}
                    </ExTableCell>
                    <ExTableCell>
                      <ThemedButton color="secondary" onClick={() => handleClickDetail(dealer)}>
                        詳細
                      </ThemedButton>
                    </ExTableCell>
                  </ExTableRow>
                )
              })}
            </ExTableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  )
}

export default withRouter(DealerIndex)
