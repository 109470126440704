import PersonIcon from '@mui/icons-material/Person'
import { Avatar } from '@mui/material'
import { CommentableType, InquiryComment } from 'entities/inquiry_comment'
import moment from 'moment'
import React from 'react'
import { ViewModel } from 'view_models/index'

export class InquiryCommentViewModel extends ViewModel<InquiryComment> {
  public isSent(commentableType: CommentableType): boolean {
    return this.object.commentableType === commentableType
  }

  public sentAtText(): string {
    return moment(this.object.createdAt).format('YYYY/MM/DD HH:mm')
  }

  public iconUrl(): string {
    return this.object.icon ? this.object.icon : '/images/common/no_photo_user.png'
  }

  public getIcon() {
    return this.object.icon ? (
      <Avatar src={this.object.icon} />
    ) : (
      <Avatar>
        <PersonIcon />
      </Avatar>
    )
  }

  public getBody() {
    return this.object.body?.split('\n').map((str: string, idx: number) => {
      return (
        <span key={idx}>
          {str}
          <br />
        </span>
      )
    })
  }
}
