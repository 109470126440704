import { InstrumentBasicInfo } from 'entities/instrument_basic_info'
import moment, { Moment } from 'moment'
import React from 'react'
import { ViewModel } from 'view_models'

export class InstrumentBasicInfoViewModel extends ViewModel<InstrumentBasicInfo> {
  // 登録日はInstrumentBasicInfoの作成日を参照する
  public registeredAt = () => {
    return moment(this.object.createdAt).format('Y/MM/DD')
  }

  public getImageUrl = () => {
    return this.object.imageUrl
  }

  public modelNumber(): string {
    return this.object.modelNumber || '指定なし'
  }

  public serialNumber(): string {
    return this.object.serialNumber || '指定なし'
  }

  public manageNumber(): string {
    return this.object.manageNumber || '指定なし'
  }

  public purchasedAt(): Moment | null {
    return this.object.purchasedAt ? moment(this.object.purchasedAt) : null
  }

  public purchasedAtText(): string {
    const date = this.purchasedAt()
    if (date) {
      return date.format('Y/MM/DD')
    } else {
      return '-'
    }
  }

  public purchasedPriceText(): string {
    if (this.object.purchasedPrice) {
      return `¥${this.object.purchasedPrice.toLocaleString()}`
    } else {
      return '-'
    }
  }

  /**
   * 型番・シリアル番号・管理番号をつなげて出力
   */
  public getInstrumentNumbers(): string {
    return `${this.object.modelNumber} / ${this.serialNumber()} / ${this.manageNumber()}`
  }

  public getNote(): string {
    if (this.object.note) {
      return this.object.note
    } else {
      return '-'
    }
  }

  public getMakerName(): string {
    return this.object.maker || '-'
  }

  public instrumentCategoryName(): string {
    return this.object.instrumentCategoryName || '-'
  }

  public getLink(): React.ReactNode {
    return (
      <a href={this.object.externalLink} target="_blank" rel="noopener noreferrer">
        {this.object.externalLink}
      </a>
    )
  }

  public getInstrumentName = () => {
    return this.object.name || '-'
  }

  public getInstrumentRoomName = () => {
    return this.object.roomName || '-'
  }
}
