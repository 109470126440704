import { Paper, Table, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'
import { useDeleteProofreadPlanApi, useFetchProofreadPlansApi } from 'api/mypage/proofread_plan'
import ProofreadPlanFormDialog from 'components/mypage/proofread/proofread_plan/proofread_plan_form_dialog'
import { ContentHeader, ContentTitle } from 'components/shared/content'
import { Flex } from 'components/shared/flex'
import { useModal } from 'components/shared/modal'
import Pagination from 'components/shared/pagination'
import { ExTableCell, ExTableBody, ExTableRow } from 'components/shared/table'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { MypageStateContext } from 'contexts/mypage_state_context'
import { ProofreadPlan } from 'entities/proofread_plan'
import React, { useContext, useEffect } from 'react'
import { StaticContext } from 'react-router'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { MypageRouteHelper } from 'routes/mypage'
import { getTableHeight, HEIGHTS } from 'utils/component_heights'
import { ProofreadPlanViewModel } from 'view_models/proofread_plan'

const TABLE_PADDING = HEIGHTS.FLOAT_HEADER + HEIGHTS.PROOFREAD_CONTENT_HEADER + HEIGHTS.PAGINATION + HEIGHTS.MARGIN

export type ProofreadPlanIndexProps = never
type Props = RouteComponentProps<never, StaticContext, ProofreadPlanIndexProps>

export const ProofreadPlanIndex: React.FC<Props> = (props) => {
  const globalState = useContext(GlobalStateContext)
  const mypageState = useContext(MypageStateContext)

  const api = useFetchProofreadPlansApi()
  const modalState = useModal()
  const deletePlanApi = useDeleteProofreadPlanApi()

  useEffect(() => {
    api.execute()
  }, [])

  const handleCompleteCreate = () => {
    modalState.setOpen(false)
    api.execute()
  }

  const handleClickCreate = () => {
    modalState.setOpen(true)
  }

  const handleClickDeleteButton = (id: number) => {
    globalState.confirm('本当に削除しますか？', (event) => {
      if (event === 'ok') {
        deletePlanApi.execute(id)
      }
    })
  }

  useEffect(() => {
    globalState.setLoading(deletePlanApi.loading)
    if (deletePlanApi.isSuccess()) {
      api.execute()
    }
  }, [deletePlanApi.loading])

  return (
    <>
      <ProofreadPlanFormDialog modalState={modalState} onComplete={handleCompleteCreate} />
      <ContentHeader>
        <ContentTitle title="校正計画一覧">
          <ThemedButton color="primary" onClick={handleClickCreate}>
            新しく校正計画を開始する
          </ThemedButton>
        </ContentTitle>
      </ContentHeader>
      <Paper>
        <TableContainer style={{ maxHeight: getTableHeight(TABLE_PADDING) }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <ExTableCell rowSpan={2} width={150}>
                  名称
                </ExTableCell>
                {mypageState.currentUser.laboratory?.authority?.enableInstrumentSharing && (
                  <ExTableCell rowSpan={2} width={120}>
                    業者
                  </ExTableCell>
                )}
                <ExTableCell rowSpan={2} width={100}>
                  対象期間
                </ExTableCell>
                <ExTableCell colSpan={2} align="center">
                  計画
                </ExTableCell>
                {mypageState.currentUser.laboratory?.authority?.enableInstrumentSharing && (
                  <>
                    <ExTableCell rowSpan={2} width={50} align="center">
                      日程調整
                    </ExTableCell>
                    <ExTableCell rowSpan={2} width={50} align="center">
                      未実施
                    </ExTableCell>
                  </>
                )}
                <ExTableCell colSpan={2} align="center">
                  結果
                </ExTableCell>
                <ExTableCell rowSpan={2} width={50} align="center">
                  完了
                </ExTableCell>
                <ExTableCell rowSpan={2} width={30} />
              </TableRow>
              <TableRow>
                <ExTableCell width={50} align="center">
                  未入力
                </ExTableCell>
                <ExTableCell width={50} align="center">
                  未承認
                </ExTableCell>
                <ExTableCell width={50} align="center">
                  未入力
                </ExTableCell>
                <ExTableCell width={50} align="center">
                  未承認
                </ExTableCell>
              </TableRow>
            </TableHead>
            <ExTableBody loading={api.loading}>
              {api.response.proofreadPlans.map((plan: ProofreadPlan) => {
                const vm = new ProofreadPlanViewModel(plan)
                return (
                  <ExTableRow key={plan.id}>
                    <ExTableCell>{vm.getTitle()}</ExTableCell>
                    {mypageState.currentUser.laboratory?.authority?.enableInstrumentSharing && (
                      <ExTableCell>{vm.getDealerName()}</ExTableCell>
                    )}
                    <ExTableCell>{vm.getTargetRange()}</ExTableCell>
                    <ExTableCell align="center">{vm.getStateCount('no_plan')}</ExTableCell>
                    <ExTableCell align="center">{vm.getStateCount('planned')}</ExTableCell>
                    {mypageState.currentUser.laboratory?.authority?.enableInstrumentSharing && (
                      <>
                        <ExTableCell align="center">{vm.getStateCount('approved_plan')}</ExTableCell>
                        <ExTableCell align="center">{vm.getStateCount('fix_date')}</ExTableCell>
                      </>
                    )}
                    <ExTableCell align="center">{vm.getStateCount('attached_result')}</ExTableCell>
                    <ExTableCell align="center">{vm.getStateCount('fix_result')}</ExTableCell>
                    <ExTableCell align="center">{vm.getStateCount('approved_result')}</ExTableCell>
                    <ExTableCell>
                      <Flex>
                        <ThemedButton color="primary" onClick={() => props.history.push(MypageRouteHelper.proofreadPlanShow(plan.id!))}>
                          詳細
                        </ThemedButton>
                        <Tooltip
                          title={
                            vm.getInstrumentCount() !== vm.getStateCount('no_plan') ? '校正が進んでいる機器があるため削除できません' : ''
                          }
                        >
                          <div>
                            <ThemedButton
                              color="error"
                              onClick={() => handleClickDeleteButton(plan.id!)}
                              // 校正が進んでいる機器がある場合、削除できない
                              disabled={vm.getInstrumentCount() !== vm.getStateCount('no_plan')}
                            >
                              削除
                            </ThemedButton>
                          </div>
                        </Tooltip>
                      </Flex>
                    </ExTableCell>
                  </ExTableRow>
                )
              })}
            </ExTableBody>
          </Table>
        </TableContainer>
        <Pagination response={api.response} pageSet={api.pageSet} />
      </Paper>
    </>
  )
}
export default withRouter(ProofreadPlanIndex)
