import { ErrorBoundary } from '@sentry/react'
import MypageLayout from 'components/mypage/mypage_layout'
import { FrontendErrorFallback } from 'components/shared/frontend_error_fallback'
import AuthorityStateContainer from 'containers/authority_state_container'
import { UserAuth } from 'containers/user_auth'
import { MypageStateContext } from 'contexts/mypage_state_context'
import BarcodeIndex from 'pages/mypage/barcode/index'
import BarcodePreview from 'pages/mypage/barcode/preview'
import DefectReportIndex from 'pages/mypage/defect_report/index'
import DefectReportShow from 'pages/mypage/defect_report/show'
import { ExColumnIndex } from 'pages/mypage/ex_column'
import InquiryIndex from 'pages/mypage/inquiry'
import InstrumentImport from 'pages/mypage/instrument/import'
import InstrumentIndex from 'pages/mypage/instrument/index'
import InstrumentShow from 'pages/mypage/instrument/show'
import Login from 'pages/mypage/login/index'
import AssetReservationIndex from 'pages/mypage/manager/laboratory/asset_reservations'
import LaboratoryIndex from 'pages/mypage/manager/laboratory/index'
import LaboratoryShow from 'pages/mypage/manager/laboratory/show'
import ProofreadApprovalIndex from 'pages/mypage/proofread_approval'
import ProofreadPlanIndex from 'pages/mypage/proofread_plan'
import ProofreadPlanShow from 'pages/mypage/proofread_plan/show'
import ProofreadableInstrumentIndex from 'pages/mypage/proofreadable_instrument'
import RepairEstimateIndex from 'pages/mypage/repair_estimate'
import RepairEstimateShow from 'pages/mypage/repair_estimate/show'
import RepairEventIndex from 'pages/mypage/repair_event/index'
import RepairEventShow from 'pages/mypage/repair_event/show'
import RepairInfoShow from 'pages/mypage/repair_info/show'
import ReservationIndex from 'pages/mypage/reservation/index'
import RoomIndex from 'pages/mypage/room/index'
import RoomShow from 'pages/mypage/room/show'
import SettingShow from 'pages/mypage/setting/show'
import UnmanagedInstrumentIndex from 'pages/mypage/unmanaged_instrument/index'
import UnprintedBarcodeIndex from 'pages/mypage/unprinted_barcode/index'
import React, { useContext } from 'react'
import { Route, Switch } from 'react-router-dom'

const MypageRoutes = () => {
  const mypageState = useContext(MypageStateContext)

  return (
    <Switch>
      <Route exact path={MypageRouteHelper.root()}>
        <Login />
      </Route>
      <Route exact path={MypageRouteHelper.login()}>
        <Login />
      </Route>
      <Route path="/mypage">
        <UserAuth>
          <MypageLayout>
            <AuthorityStateContainer>
              <ErrorBoundary fallback={FrontendErrorFallback}>
                <Switch>
                  {/* 機器 */}
                  <Route exact path={MypageRouteHelper.instrumentIndex()}>
                    <InstrumentIndex />
                  </Route>
                  <Route exact path={MypageRouteHelper.instrumentShow(':id')}>
                    <InstrumentShow />
                  </Route>
                  {/* バーコード */}
                  <Route exact path={MypageRouteHelper.barcodeIndex()}>
                    <BarcodeIndex />
                  </Route>
                  <Route exact path={MypageRouteHelper.barcodePreview()}>
                    <BarcodePreview />
                  </Route>
                  <Route exact path={MypageRouteHelper.unprintedBarcodeIndex()}>
                    <UnprintedBarcodeIndex />
                  </Route>

                  {/* 管理対象外の機器 */}
                  <Route exact path={MypageRouteHelper.unmanagedInstrumentIndex()}>
                    <UnmanagedInstrumentIndex />
                  </Route>

                  {/* 部屋 */}
                  <Route exact path={MypageRouteHelper.roomIndex()}>
                    <RoomIndex />
                  </Route>
                  <Route exact path={MypageRouteHelper.roomShow(':id')}>
                    <RoomShow />
                  </Route>

                  {/* マイカレンダー */}
                  <Route exact path={MypageRouteHelper.reservationIndex()}>
                    <ReservationIndex />
                  </Route>

                  {/* 不具合報告 */}
                  <Route exact path={MypageRouteHelper.defectReportIndex()}>
                    <DefectReportIndex />
                  </Route>
                  <Route exact path={MypageRouteHelper.defectReportShow(':id')}>
                    <DefectReportShow />
                  </Route>
                  {/* 修理結果 */}
                  <Route exact path={MypageRouteHelper.repairEventIndex()}>
                    <RepairEventIndex />
                  </Route>
                  <Route exact path={MypageRouteHelper.repairEventShow(':id')}>
                    <RepairEventShow />
                  </Route>
                  {/* 修理見積 */}
                  <Route exact path={MypageRouteHelper.repairEstimateIndex()}>
                    <RepairEstimateIndex />
                  </Route>
                  <Route exact path={MypageRouteHelper.repairEstimateShow(':id')}>
                    <RepairEstimateShow />
                  </Route>

                  {/* 修理情報 */}
                  <Route exact path={MypageRouteHelper.repairInfoShow(':id')}>
                    <RepairInfoShow />
                  </Route>

                  {/* 校正計画 */}
                  <Route exact path={MypageRouteHelper.proofreadPlanIndex()}>
                    <ProofreadPlanIndex />
                  </Route>
                  <Route exact path={MypageRouteHelper.proofreadPlanShow(':id')}>
                    <ProofreadPlanShow />
                  </Route>
                  {/* 校正承認一覧 */}
                  <Route exact path={MypageRouteHelper.proofreadApprovalIndex()}>
                    <ProofreadApprovalIndex />
                  </Route>
                  {/* 校正対象機器一覧 */}
                  <Route exact path={MypageRouteHelper.proofreadableInstrumentIndex()}>
                    <ProofreadableInstrumentIndex />
                  </Route>

                  {/* 管理情報 */}
                  <Route exact path={MypageRouteHelper.exAttributeIndex()}>
                    <ExColumnIndex />
                  </Route>
                  <Route exact path={MypageRouteHelper.inquiryShow(':id')}>
                    <InquiryIndex />
                  </Route>

                  {/* 設定画面 */}
                  <Route exact path={MypageRouteHelper.settingShow()}>
                    <SettingShow />
                  </Route>

                  {/* 管理ラボ */}
                  <Route exact path={MypageRouteHelper.laboratoryIndex()}>
                    <LaboratoryIndex />
                  </Route>
                  {/* すべての予約 */}
                  <Route exact path={MypageRouteHelper.assetReservationIndex()}>
                    <AssetReservationIndex />
                  </Route>
                  {/* 管理ラボ詳細 */}
                  <Route exact path={MypageRouteHelper.laboratoryShow(':id')}>
                    <LaboratoryShow />
                  </Route>
                  {mypageState.currentUser.laboratory?.authority?.enableInstrumentSharing && (
                    <>
                      {/* 機器共有インポート */}
                      <Route exact path={MypageRouteHelper.instrumentImport()}>
                        <InstrumentImport />
                      </Route>
                      {/* 問合せ */}
                      <Route exact path={MypageRouteHelper.inquiryIndex()}>
                        <InquiryIndex />
                      </Route>
                    </>
                  )}
                </Switch>
              </ErrorBoundary>
            </AuthorityStateContainer>
          </MypageLayout>
        </UserAuth>
      </Route>
    </Switch>
  )
}

export default MypageRoutes

/**
 * ルート定義
 */
export class MypageRouteHelper {
  static basePath = (path: string): string => `/mypage/${path}`

  public static root = (): string => '/mypage'
  // ログイン
  public static login = (): string => MypageRouteHelper.basePath('login')

  // 機器
  public static instrumentIndex = (): string => MypageRouteHelper.basePath('instruments')
  public static instrumentShow = (id: number | string): string => MypageRouteHelper.basePath(`instruments/${id}`)
  public static instrumentImport = (): string => MypageRouteHelper.basePath('import/instruments')

  // QR・バーコード
  public static barcodeIndex = (): string => MypageRouteHelper.basePath('barcodes')
  public static barcodePreview = (): string => MypageRouteHelper.basePath('barcodes/preview')
  public static unprintedBarcodeIndex = (): string => MypageRouteHelper.basePath('unprinted_barcodes')

  // 管理対象外の機器
  public static unmanagedInstrumentIndex = (): string => MypageRouteHelper.basePath('unmanaged_instruments')

  public static defectReportShow = (reportId: number | string): string => MypageRouteHelper.basePath(`defect_reports/${reportId}`)

  // 部屋
  public static roomIndex = (): string => MypageRouteHelper.basePath('rooms')
  public static roomShow = (id: number | string): string => MypageRouteHelper.basePath(`rooms/${id}`)

  public static reservationIndex = (): string => MypageRouteHelper.basePath('reservations')

  public static defectReportIndex = (): string => MypageRouteHelper.basePath('defect_reports')

  public static proofreadPlanIndex = (): string => MypageRouteHelper.basePath('proofread_plans')
  public static proofreadPlanShow = (id: number | string): string => MypageRouteHelper.basePath(`proofread_plans/${id}`)
  public static proofreadApprovalIndex = (): string => MypageRouteHelper.basePath('proofread_approvals')
  public static proofreadableInstrumentIndex = (): string => MypageRouteHelper.basePath('proofreadable_instruments')

  // 修理管理
  public static repairInfoShow = (id: number | string): string => MypageRouteHelper.basePath(`repair_infos/${id}`)

  public static repairEventIndex = (): string => MypageRouteHelper.basePath('repair_events')
  public static repairEventShow = (id: number | string): string => MypageRouteHelper.basePath(`repair_events/${id}`)

  public static repairEstimateIndex = (): string => MypageRouteHelper.basePath('repair_estimates')
  public static repairEstimateShow = (id: number | string): string => MypageRouteHelper.basePath(`repair_estimates/${id}`)

  // 管理情報
  public static exAttributeIndex = (): string => MypageRouteHelper.basePath('ex_attributes')

  // 問合せ
  public static inquiryIndex = (): string => MypageRouteHelper.basePath('inquiries')
  public static inquiryShow = (id: number | string): string => MypageRouteHelper.basePath(`inquiries/${id}`)

  // 設定
  public static settingShow = (): string => MypageRouteHelper.basePath('setting')

  // 管理ラボ
  public static laboratoryIndex = (): string => MypageRouteHelper.basePath('laboratories')
  public static laboratoryShow = (id: number | string): string => MypageRouteHelper.basePath(`laboratories/${id}`)
  public static assetReservationIndex = (): string => MypageRouteHelper.basePath('laboratories/reservations')
}
