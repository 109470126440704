import { TableContainer, Table, TableHead, TableRow } from '@mui/material'
import { PartnerFetchProofreadEventsApi } from 'api/partner/proofread_plans/proofread_event'
import { CreateDefectReportWithEstimateFormDialog } from 'components/partner/defect_report/defect_report_form_dialog'
import FixDateEventFormDialog from 'components/partner/proofread/proofread_event/fix_date_event_form_dialog'
import { CheckboxFooter } from 'components/shared/checkbox_footer'
import { Flex } from 'components/shared/flex'
import ImageView from 'components/shared/image_view'
import { useMultipleModal } from 'components/shared/modal'
import Pagination from 'components/shared/pagination'
import { ExTableCell, ExTableBody, ExTableRow, CheckboxTableCell, AllCheckboxTableCell } from 'components/shared/table'
import ThemedButton from 'components/shared/themed_button'
import { ProofreadEvent } from 'entities/proofread_event'
import React from 'react'
import { getTableHeight, PROOFREAD_CHECKED_TABLE_PADDING, PROOFREAD_TABLE_PADDING } from 'utils/component_heights'
import { useCheckboxes } from 'utils/hooks'
import { ProofreadEventViewModel } from 'view_models/proofread_event'

type FixDateEventTableProps = {
  indexApi: PartnerFetchProofreadEventsApi
  planId: number
  onUpdate: () => void
  onClickExAttr: (event: ProofreadEvent) => void
  onClickBulkExAttr: (eventIds: number[]) => void
}
export const FixDateEventTable: React.FC<FixDateEventTableProps> = (props) => {
  const checkboxSet = useCheckboxes(props.indexApi.response.proofreadEvents)
  const attachModalSet = useMultipleModal<number[]>()
  // const createRepairModal = useMultipleModal<ProofreadEvent>()

  const handleClickAttach = (id: number) => {
    attachModalSet.openModal([id])
  }
  const handleClickBulkAttach = () => {
    attachModalSet.openModal(checkboxSet.checkedIds)
  }
  const handleCompleteAttach = () => {
    attachModalSet.closeModal()
    props.onUpdate()
  }

  // const handleClickRepair = (event: ProofreadEvent) => {
  //   createRepairModal.openModal(event)
  // }

  return (
    <>
      {/* <CreateDefectReportWithEstimateFormDialog modalSet={createRepairModal} onComplete={createRepairModal.closeModal} /> */}
      <FixDateEventFormDialog modalSet={attachModalSet} onComplete={handleCompleteAttach} planId={props.planId} />
      <TableContainer
        style={{
          maxHeight:
            checkboxSet.checkedIds.length > 0 ? getTableHeight(PROOFREAD_CHECKED_TABLE_PADDING) : getTableHeight(PROOFREAD_TABLE_PADDING),
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <AllCheckboxTableCell checkboxSet={checkboxSet} />
              <ExTableCell colSpan={2} align="center">
                製品名
              </ExTableCell>
              <ExTableCell align="center">引渡日</ExTableCell>
              <ExTableCell align="center">返却日</ExTableCell>
              <ExTableCell align="center">料金</ExTableCell>
              <ExTableCell />
              <ExTableCell />
            </TableRow>
          </TableHead>
          <ExTableBody loading={props.indexApi.loading}>
            {props.indexApi.response.proofreadEvents.map((event: ProofreadEvent) => {
              const vm = new ProofreadEventViewModel(event)
              return (
                <ExTableRow key={event.id}>
                  <CheckboxTableCell checkboxId={event.id!} checkboxesSet={checkboxSet} />
                  <ExTableCell width={100}>
                    <ImageView maxHeight={100} maxWidth={100} src={vm.getInstrumentImage()} />
                  </ExTableCell>
                  <ExTableCell>{vm.getInstrumentInfo()}</ExTableCell>
                  <ExTableCell align="center">{vm.getSupplierDeliveredDate()}</ExTableCell>
                  <ExTableCell align="center">{vm.getSupplierReturnedDate()}</ExTableCell>
                  <ExTableCell align="center">{vm.getPrice()}</ExTableCell>
                  <ExTableCell width={122}>
                    <ThemedButton color="success" onClick={() => props.onClickExAttr(event)}>
                      管理情報
                    </ThemedButton>
                  </ExTableCell>
                  <ExTableCell width={250}>
                    <Flex justifyContent="space-between">
                      {/* <ThemedButton color="error" onClick={() => handleClickRepair(event)}>
                        修理見積を提示
                      </ThemedButton> */}
                      <ThemedButton color="primary" onClick={() => handleClickAttach(event.id!)}>
                        結果入力
                      </ThemedButton>
                    </Flex>
                  </ExTableCell>
                </ExTableRow>
              )
            })}
          </ExTableBody>
        </Table>
      </TableContainer>
      <Pagination response={props.indexApi.response} pageSet={props.indexApi.pageSet} />
      <CheckboxFooter
        checkboxSet={checkboxSet}
        content={
          <>
            <ThemedButton color="primary" onClick={handleClickBulkAttach} style={{ marginRight: 12 }}>
              一括で完了にする
            </ThemedButton>
            <ThemedButton color="success" onClick={() => props.onClickBulkExAttr(checkboxSet.checkedIds)} style={{ marginRight: 12 }}>
              校正管理情報を一括登録
            </ThemedButton>
          </>
        }
      />
    </>
  )
}

export default FixDateEventTable
