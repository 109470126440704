import CancelIcon from '@mui/icons-material/CancelOutlined'
import { FileAttachment } from 'entities/file_attachment'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

const useStyle = makeStyles()({
  list: {
    listStyle: 'none',
  },
  item: {
    paddingLeft: 6,
    marginTop: 6,
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    cursor: 'pointer',
  },
})

type AttachmentsListProps<T> = {
  fileAttachments: T[]
  label?: string
  emptyText?: string
  onClickRemove?: (a: T) => void
}
const AttachmentsList = <T extends FileAttachment>(props: AttachmentsListProps<T>) => {
  const { classes } = useStyle()

  return (
    <div>
      <label>{props.label || '添付ファイル'}</label>
      <ul className={classes.list}>
        {props.fileAttachments.length === 0 ? (
          <li className={classes.item}>{props.emptyText || 'なし'}</li>
        ) : (
          props.fileAttachments?.map((f, i) => (
            <li className={classes.item} key={`attachments-li-${i}`}>
              <a href={f.url} target="_blank" rel="noopener noreferrer">
                {f.name}
              </a>
              {props.onClickRemove && <CancelIcon className={classes.icon} color="error" onClick={() => props.onClickRemove!(f)} />}
            </li>
          ))
        )}
      </ul>
    </div>
  )
}

export default AttachmentsList
