import { Button, ButtonProps } from '@mui/material'
import React from 'react'

type ThemedButtonProps = ButtonProps

const ThemedButton = (props: ThemedButtonProps) => {
  const { ...rest } = props

  return <Button {...rest} />
}

export default ThemedButton
