import { usePostProofreadPlanApi } from 'api/mypage/proofread_plan'
import { Flex } from 'components/shared/flex'
import { MonthField, StringField } from 'components/shared/form/input'
import Modal, { ModalState } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { ProofreadPlan, ProofreadPlanForm } from 'entities/proofread_plan'
import { useForm } from 'rac'
import React, { useContext, useEffect } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'

type ProofreadPlanFormDialogProps = {
  modalState: ModalState
  onComplete: (res: ProofreadPlan) => void
}
export const ProofreadPlanFormDialog: React.FC<ProofreadPlanFormDialogProps> = (props) => {
  const postForm = useForm<ProofreadPlanForm>({}, 'proofreadPlan')
  const postApi = usePostProofreadPlanApi(postForm)
  const globalState = useContext(GlobalStateContext)

  useEffectSkipFirst(() => {
    props.onComplete(postApi.response.proofreadPlan)
  }, [postApi.response.proofreadPlan])

  useEffect(() => {
    globalState.setLoading(postApi.loading)
  }, [postApi.loading])

  const handleSubmit = () => {
    postApi.execute()
  }

  return (
    <>
      <Modal
        modalState={props.modalState}
        modalId="create-plan-modal"
        title="校正計画を開始する"
        size="sm"
        footer={
          <>
            <ThemedButton onClick={() => props.modalState.setOpen(false)} color="secondary">
              閉じる
            </ThemedButton>
            <ThemedButton onClick={handleSubmit} color="success">
              登録
            </ThemedButton>
          </>
        }
      >
        <Flex>
          <StringField label="名称" form={postForm} attr="title" apiError={postApi.apiError} required />
        </Flex>
        <Flex>
          <MonthField label="開始月" form={postForm} attr="startDate" apiError={postApi.apiError} required />
          <MonthField label="終了月" form={postForm} attr="endDate" apiError={postApi.apiError} required />
        </Flex>
      </Modal>
    </>
  )
}

export default ProofreadPlanFormDialog
