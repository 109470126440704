import { AxiosHttpClient } from 'api/axios'
import { useRepruaDeleteApi, useRepruaIndexApi, useRepruaPostApi } from 'api/shared_api_hooks'
import { Laboratory } from 'entities/laboratory'
import { ApiSet, BaseResponse, Form, IndexApiSet } from 'rac'

type ManagedLaboratoriesResponse = BaseResponse & {
  laboratories: Laboratory[]
}
/**
 * 一覧
 */
export function useFetchAdminManagedLaboratoriesApi(): IndexApiSet<ManagedLaboratoriesResponse> & {
  execute: (managerLaboratoryId: number) => void
} {
  const api = useRepruaIndexApi<ManagedLaboratoriesResponse, {}>(new AxiosHttpClient('admin'), {
    initialResponse: { laboratories: [] },
    initialState: { perPage: 1000 },
  })

  const execute = (managerLaboratoryId: number) => {
    const path = `laboratories/${managerLaboratoryId}/management_laboratories`
    api.execute(path)
  }

  return { ...api, execute }
}

export type ManagementLaboratoryForm = {
  managedLaboratoryId?: number
}

type ManagedLaboratoryResponse = BaseResponse & {}
/**
 * 取得
 */
export function usePostAdminManagedLaboratoryApi(): ApiSet<ManagedLaboratoryResponse> & {
  execute: (laboratoryId: number, form: Form<ManagementLaboratoryForm>) => void
} {
  const api = useRepruaPostApi<ManagedLaboratoryResponse, ManagementLaboratoryForm>(new AxiosHttpClient('admin'), { initialResponse: {} })

  const execute = (laboratoryId: number, form: Form<ManagementLaboratoryForm>) => {
    const path = `laboratories/${laboratoryId}/management_laboratories`
    api.execute(path, form)
  }

  return { ...api, execute }
}

export function useDeleteAdminManagedLaboratoryApi(): ApiSet<ManagedLaboratoryResponse> & {
  execute: (laboratoryId: number, managedLaboratoryId: number) => void
} {
  const api = useRepruaDeleteApi<ManagedLaboratoryResponse>(new AxiosHttpClient('admin'), { initialResponse: {} })

  const execute = (managerLaboratoryId: number, managedLaboratoryId: number) => {
    const path = `laboratories/${managerLaboratoryId}/management_laboratories/${managedLaboratoryId}`
    api.execute(path)
  }

  return { ...api, execute }
}
