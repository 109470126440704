import { usePostRepairEstimateApi } from 'api/partner/repair_estimate'
import Modal, { ModalState } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { PartnerStateContext } from 'contexts/partner_state_context'
import { DefectReport } from 'entities/defect_report'
import { RepairEstimate, RepairEstimateForm } from 'entities/repair_estimate'
import { useForm } from 'rac'
import React, { useContext } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'

type RepairEstimateRequestFormDialogProps = {
  modalState: ModalState
  defectReport: DefectReport
  onComplete: (estimate: RepairEstimate) => void
}
/**
 * 共有機器の修理見積を作成フォーム
 */
export const RepairEstimateRequestFormDialog: React.FC<RepairEstimateRequestFormDialogProps> = (props) => {

  const { currentDealer } = useContext(PartnerStateContext)

  const postForm = useForm<RepairEstimateForm>({
    defectReportId: props.defectReport.id,
    suppliableType: 'Dealer',
    suppliableId: currentDealer.id,
    aasmState: 'estimating',
  })
  const postApi = usePostRepairEstimateApi(postForm)

  useEffectSkipFirst(() => {
    if (!postApi.isSuccess()) return
    props.onComplete(postApi.response.repairEstimate)
  }, [postApi.loading])

  return (
    <Modal
      title="修理見積を作成"
      modalState={props.modalState}
      modalId="repair-estimate-request-form"
      size="sm"
      footer={
        <>
          <ThemedButton color="secondary" onClick={() => props.modalState.setOpen(false)}>
            閉じる
          </ThemedButton>
          <ThemedButton color="success" onClick={postApi.execute}>
            OK
          </ThemedButton>
        </>
      }
    >
      <>修理見積を作成すると、不具合報告の編集が出来なくなります。<br/>よろしいですか？</>
    </Modal>
  )
}
