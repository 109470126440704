import CloseIcon from '@mui/icons-material/Close'
import { Dialog, IconButton, Slide, SlideProps } from '@mui/material'
import MuiDialogActions from '@mui/material/DialogActions'
import MuiDialogContent from '@mui/material/DialogContent'
import MuiDialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle'
import ThemedButton from 'components/shared/themed_button'
import React, { ReactNode, CSSProperties, useState, Dispatch, SetStateAction, useEffect } from 'react'
import { makeStyles, withStyles } from 'tss-react/mui'

type CustomDialogTitleProps = DialogTitleProps & {
  children: React.ReactNode
  onClose: () => void
  fullScreen: boolean
  useFullScreenButton?: boolean
  closeFullScreenButtonName?: string
  headerRight?: React.ReactNode
  onClickFullScreenButton: () => void
}

// stylesオブジェクトを作成するとき，createStylesヘルパーを使わないと型エラーが発生する
const useTitleStyles = makeStyles()((theme) => ({
  root: {
    margin: 0,
    padding: '10px 15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    fontSize: 18,
    display: 'inline-block',
    fontWeight: 'bold',
  },
  fullScreenButton: {
    marginRight: 10,
  },
}))

const useModalStyles = makeStyles()({
  positionHigh: {
    position: 'absolute',
    margin: 'auto',
    top: 30,
  },
})

const DialogTitle = (props: CustomDialogTitleProps) => {
  const { children, useFullScreenButton, fullScreen, headerRight, onClickFullScreenButton, onClose } = props
  const { classes } = useTitleStyles()

  return (
    <MuiDialogTitle classes={classes}>
      <div className={classes.title}>
        {children}
        {fullScreen && (
          <ThemedButton size="small" onClick={onClose} style={{ marginLeft: 15 }}>
            {props.closeFullScreenButtonName ? props.closeFullScreenButtonName : '閉じる'}
          </ThemedButton>
        )}
      </div>
      <div>
        {headerRight ? headerRight : null}
        {useFullScreenButton ? (
          <ThemedButton size="small" onClick={onClickFullScreenButton} className={classes.fullScreenButton}>
            {fullScreen ? '全画面表示を終了' : '全画面表示'}
          </ThemedButton>
        ) : null}
        {onClose ? (
          <IconButton onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        ) : null}
      </div>
    </MuiDialogTitle>
  )
}

const DialogContent = withStyles(MuiDialogContent, (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))

const DialogActions = withStyles(MuiDialogActions, (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))

export type ModalState = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export function useModal(): ModalState {
  const [open, setOpen] = useState(false)

  return {
    open: open,
    setOpen: setOpen,
  }
}

export type ModalSet<T> = {
  modalState: ModalState
  item: T
  openModal: (item: T) => void
  closeModal: () => void
  resetItem: () => void
}

/**
 * 複数のアイテムがある中で特定のアイテムの情報をモダール内に表示させる際に使用
 */
export function useMultipleModal<T>(): ModalSet<T> {
  const modalState = useModal()
  const [item, setItem] = useState<T>({} as T)

  const openModal = (item: T) => {
    setItem(item)
    modalState.setOpen(true)
  }

  const closeModal = () => {
    modalState.setOpen(false)
  }

  /**
   * 設定しているitemを空にする
   */
  const resetItem = () => {
    setItem({} as T)
  }

  return {
    modalState,
    item,
    openModal,
    closeModal,
    resetItem,
  }
}

// スライドアニメーションを適用するためのコンポーネント
const Transition = React.forwardRef((props: SlideProps, ref: React.Ref<unknown>) => {
  return <Slide direction="down" ref={ref} {...props} />
})

export type ModalSize = false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl'

const getWidth = (size: ModalSize | number | undefined) => {
  if (!size) {
    return 720
  } else if (typeof size === 'string') {
    switch (size) {
      case 'xs':
        return 300
      case 'sm':
        return 540
      case 'md':
        return 720
      case 'lg':
        return 900
      case 'xl':
        return 1080
      case '2xl':
        return 1200
      case '3xl':
        return 1320
    }
  } else {
    return size
  }
}

type ModalProps = {
  modalId: string
  title: string
  subTitle?: string
  children: ReactNode
  headerRight?: ReactNode
  footer?: ReactNode
  modalState: ModalState
  onClose?: () => void
  size?: ModalSize | number
  containerStyle?: CSSProperties
  contentStyle?: CSSProperties
  fullWidth?: boolean
  fullScreen?: boolean
  useFullScreenButton?: boolean
  closeFullScreenButtonName?: string
  noHeader?: boolean
  footerStyle?: CSSProperties
  positionHigh?: boolean
}

export const Modal: React.FC<ModalProps> = (props: ModalProps) => {
  const { classes } = useModalStyles()

  const [fullScreen, setFullScreen] = useState(!!props.fullScreen)
  const handleClickFullScreenButton = () => {
    setFullScreen(!fullScreen)
  }
  // フルスクリーンボタンを表示させる場合，モーダルを開くときにはFullScreenをfalseにする
  useEffect(() => {
    if (props.modalState.open && props.useFullScreenButton) {
      setFullScreen(false)
    }
  }, [props.modalState.open])

  const handleClose = () => {
    props.modalState.setOpen(false)
    if (props.onClose) {
      props.onClose()
    }
  }

  const getContainerStyle = (): CSSProperties => {
    if (fullScreen) {
      return {
        width: '100%',
        ...props.containerStyle,
      }
    } else {
      return {
        width: getWidth(props.size),
        ...props.containerStyle,
      }
    }
  }

  return (
    <Dialog
      id={props.modalId}
      onClose={handleClose}
      aria-labelledby={`${props.modalId}-label`}
      open={props.modalState.open}
      maxWidth={false}
      fullWidth={false}
      fullScreen={fullScreen}
      TransitionComponent={Transition}
      classes={{
        paper: props.positionHigh ? classes.positionHigh : '',
      }}
    >
      <div style={getContainerStyle()}>
        {!props.noHeader && (
          <>
            <DialogTitle
              id="modal-title"
              onClose={handleClose}
              useFullScreenButton={props.useFullScreenButton}
              onClickFullScreenButton={handleClickFullScreenButton}
              fullScreen={fullScreen}
              headerRight={props.headerRight}
              closeFullScreenButtonName={props.closeFullScreenButtonName}
            >
              {props.title}
              {props.subTitle && (
                <>
                  <br />
                  <div style={{ marginTop: 7, fontSize: 14 }}>{props.subTitle}</div>
                </>
              )}
            </DialogTitle>
          </>
        )}
        <DialogContent dividers style={props.contentStyle}>
          {props.children}
        </DialogContent>

        {props.footer && <DialogActions style={{ padding: '10px 15px', ...props.footerStyle }}>{props.footer}</DialogActions>}
      </div>
    </Dialog>
  )
}

export default Modal
