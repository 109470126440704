import WarningIcon from '@mui/icons-material/Warning'
import { Flex } from 'components/shared/flex'
import { ProofreadInfo } from 'entities/proofread_info'
import moment from 'moment'
import React from 'react'
import { ViewModel } from 'view_models'

const MSEC_OF_DAY = 86400000

export class ProofreadInfoViewModel extends ViewModel<ProofreadInfo> {
  public getLastResultDate = () => {
    if (!this.object.baseDate) return '-'
    return moment(this.object.baseDate).format('YYYY/MM')
  }

  public getCycle = () => {
    if (!this.object.cycle) return '-'
    return `${this.object.cycle} ${this.object.cycleUnit === 'month' ? 'ヶ月' : '年'}`
  }

  public getExpiredDate = () => {
    if (!this.object.expiredAt) return '-'
    return moment(this.object.expiredAt).format('YYYY/MM')
  }

  public getInstrumentImage = () => {
    return this.object.instrumentBasicInfo?.imageUrl
  }

  public getSupplier = (): string => {
    if (this.object.supplierName) {
      return this.object.supplierName
    } else {
      return this.object.supplier?.name || '-'
    }
  }

  public getPrice = () => {
    return this.object.price?.toLocaleString() || '-'
  }

  public getProofreadAlarmDate = () => {
    const alarm = this.object.proofreadAlarm
    const expiredAt = this.object.expiredAt

    if (alarm && alarm.unit && alarm.value && expiredAt) {
      const date = new Date(expiredAt)
      switch (alarm.unit) {
        case 'day':
          date.setDate(date.getDate() - alarm.value)
          break
        case 'week':
          date.setDate(date.getDate() - alarm.value * 7)
          break
        case 'month':
          date.setMonth(date.getMonth() - alarm.value)
          break
      }
      return moment(date).format('YYYY/MM/DD')
    } else {
      return null
    }
  }

  public getAlarmExceededLabel = () => {
    const alarmDateStr = this.getProofreadAlarmDate()
    if (alarmDateStr) {
      const now = new Date()
      const alarmDate = new Date(alarmDateStr)
      const diff = now.getTime() - alarmDate.getTime()
      const exceedDateCount = Math.floor(diff / MSEC_OF_DAY)
      if (diff > 0 && exceedDateCount >= 1) {
        return (
          <Flex alignItems="center" justifyContent="center">
            <WarningIcon color="error" style={{ fontSize: 15, marginRight: 3 }} />
            <div style={{ color: 'red', fontWeight: 'bold' }}>{`超過：${exceedDateCount}日`}</div>
          </Flex>
        )
      }
    }
    return null
  }
}
