import { Inquiry } from 'entities/inquiry'
import { InquiryComment } from 'entities/inquiry_comment'
import React, { useReducer } from 'react'

type InquiryState = {
  inquiries: Inquiry[]
  inquiry: Inquiry
  comments: InquiryComment[]
}

type InquiryStoreContextType = InquiryState & {
  dispatch: React.Dispatch<InquiryActionType>
}

const initialState = {
  inquiries: [],
  inquiry: {},
  comments: [],
}

export const InquiryStoreContext: React.Context<InquiryStoreContextType> = React.createContext<InquiryStoreContextType>({
  ...initialState,
  dispatch: (action: InquiryActionType) => {},
})

// ########### Action ##############
export enum InquiryAction {
  PUSH_INQUIRIES = 'PUSH_INQUIRES',
  REPLACE_INQUIRIES = 'REPLACE_INQUIRES',
  SET_INQUIRY = 'SET_INQUIRY',
  PUSH_COMMENTS = 'PUSH_COMMENTS',
  REPLACE_COMMENTS = 'REPLACE_COMMENTS',
}

// 各Actionの型
type PushInquiriesType = { type: InquiryAction.PUSH_INQUIRIES; payload: Inquiry[] }
type ReplaceInquiriesType = { type: InquiryAction.REPLACE_INQUIRIES; payload: Inquiry[] }
type PushCommentsType = { type: InquiryAction.PUSH_COMMENTS; payload: InquiryComment[] }
type ReplaceCommentsType = { type: InquiryAction.REPLACE_COMMENTS; payload: InquiryComment[] }
type SetInquiryType = { type: InquiryAction.SET_INQUIRY; payload: Inquiry }

// dispatcherが受け取るActionの型
type InquiryActionType = PushInquiriesType | ReplaceInquiriesType | SetInquiryType | PushCommentsType | ReplaceCommentsType
// ############ Action ###############

// ############ ActionCreator ↓ #############
export const pushInquiries = (inquiries: Inquiry[]): PushInquiriesType => {
  return { type: InquiryAction.PUSH_INQUIRIES, payload: inquiries }
}

export const replaceInquiries = (inquiries: Inquiry[]): ReplaceInquiriesType => {
  return { type: InquiryAction.REPLACE_INQUIRIES, payload: inquiries }
}

export const setInquiry = (inquiry: Inquiry): SetInquiryType => {
  return { type: InquiryAction.SET_INQUIRY, payload: inquiry }
}

export const pushComments = (comments: InquiryComment[]): PushCommentsType => {
  return { type: InquiryAction.PUSH_COMMENTS, payload: comments }
}

export const replaceComments = (comments: InquiryComment[]): ReplaceCommentsType => {
  return { type: InquiryAction.REPLACE_COMMENTS, payload: comments }
}
// ############## ActionCreator ↑ ############

// ############## Reducer ###############
const reducer = (state: InquiryState, action: InquiryActionType): InquiryState => {
  switch (action.type) {
    case InquiryAction.PUSH_INQUIRIES: {
      const inquiries: Inquiry[] = state.inquiries.concat(action.payload)
      return { ...state, inquiries: inquiries }
    }
    case InquiryAction.REPLACE_INQUIRIES:
      return { ...state, inquiries: action.payload }

    case InquiryAction.SET_INQUIRY:
      return { ...state, inquiry: action.payload }

    case InquiryAction.PUSH_COMMENTS: {
      const comments: InquiryComment[] = state.comments.concat(action.payload)
      return { ...state, comments: comments }
    }

    case InquiryAction.REPLACE_COMMENTS:
      return { ...state, comments: action.payload }

    default:
      return state
  }
}

export const InquiryStore: React.FC<{}> = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return <InquiryStoreContext.Provider value={{ ...state, dispatch: dispatch }}>{props.children}</InquiryStoreContext.Provider>
}
