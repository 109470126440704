import React from 'react'
import { MenuItem, SubMenu } from 'react-pro-sidebar'

type MenuItemProps = {
  active: boolean
  label: string
  icon?: React.ReactNode
  onClick?: () => void
  children?: React.ReactNode
}

export const SidebarMenuItem = (props: MenuItemProps) => {
  const { active, children, label, ...rest } = props
  const className = active ? 'active' : ''

  return (
    <>
      {children ? (
        <SubMenu className={className} title={label} {...rest}>
          {children}
        </SubMenu>
      ) : (
        <MenuItem className={className} {...rest}>
          {label}
        </MenuItem>
      )}
    </>
  )
}

type SubMenuItemProps = {
  active: boolean
  label: string
  onClick?: () => void
}

export const SidebarSubMenuItem = (props: SubMenuItemProps) => {
  const { active, label, ...rest } = props

  return (
    <MenuItem className={active ? 'sub-active' : ''} {...rest}>
      {label}
    </MenuItem>
  )
}
