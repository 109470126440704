import { Table, TableHead, TableRow, Paper, TableContainer } from '@mui/material'
import { usePartnerFetchInstrumentRelationEventsApi } from 'api/partner/instrument_relation/instrument_relation_event'
import { ContentHeader, ContentTitle } from 'components/shared/content'
import Pagination from 'components/shared/pagination'
import { ExTableBody, ExTableCell, OrderTableCell } from 'components/shared/table'
import { InstrumentRelationEvent } from 'entities/instrument_relation_event'
import React, { useEffect, useState } from 'react'
import { StaticContext } from 'react-router'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { INDEX_TABLE_PADDING, getTableHeight } from 'utils/component_heights'
import { useEffectSkipFirst } from 'utils/hooks'
import { InstrumentViewModel } from 'view_models/instrument'

type Props = RouteComponentProps<never, StaticContext>

const InstrumentRelationEventIndex: React.FC<Props> = () => {
  const eventsApi = usePartnerFetchInstrumentRelationEventsApi()
  const [events, setEvents] = useState<InstrumentRelationEvent[]>([])

  useEffect(() => {
    eventsApi.execute()
  }, [])

  useEffectSkipFirst(() => {
    if (!eventsApi.isSuccess()) return
    setEvents(eventsApi.response.instrumentRelationEvents)
  }, [eventsApi.loading])

  return (
    <>
      <ContentHeader>
        <ContentTitle title="機器共有 - 共有イベント" />
      </ContentHeader>
      <Paper>
        <TableContainer style={{ maxHeight: getTableHeight(INDEX_TABLE_PADDING) }}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <OrderTableCell width={120} align="center" orderAttr="createdAt" orderSet={eventsApi.orderSet}>
                  発生日時
                </OrderTableCell>
                <ExTableCell width={150}>機器</ExTableCell>
                <ExTableCell width={350}>イベント</ExTableCell>
              </TableRow>
            </TableHead>
            <ExTableBody loading={eventsApi.loading}>
              {events.map((event) => {
                const instrumentVM = new InstrumentViewModel(event.instrument!)
                return (
                  <TableRow key={`instrument-relation-event-${event.id}`}>
                    <ExTableCell width={120} align="center">
                      {instrumentVM.timestampToDatetimeText(event.createdAt)}
                    </ExTableCell>
                    <ExTableCell width={150} align="left">
                      {instrumentVM.getInstrumentBaseInfoIncludeMaker()}
                    </ExTableCell>
                    <ExTableCell width={350} align="left">
                      {event.summary}
                    </ExTableCell>
                  </TableRow>
                )
              })}
            </ExTableBody>
          </Table>
        </TableContainer>
        <Pagination response={eventsApi.response} pageSet={eventsApi.pageSet} />
      </Paper>
    </>
  )
}

export default withRouter(InstrumentRelationEventIndex)
