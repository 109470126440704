import { AxiosHttpClient } from 'api/axios'
import { useRepruaDeleteApi, useRepruaIndexApi, useRepruaPatchApi, useRepruaPostApi } from 'api/shared_api_hooks'
import { PagingResponse } from 'entities'
import { ApprovalSetting, ApprovalSettingForm } from 'entities/approval_setting'
import { ApiSet, BaseResponse, Form, IndexApiSet } from 'rac'

type ApprovalSettingsResponse = PagingResponse & {
  approvalSettings: ApprovalSetting[]
  totalCount: number
}

type ApprovalSettingResponse = BaseResponse & {
  approvalSetting: ApprovalSetting
}

/**
 * 一覧
 */
export function useFetchApprovalSettingsApi(): IndexApiSet<ApprovalSettingsResponse> & { execute: () => void } {
  const api = useRepruaIndexApi<ApprovalSettingsResponse, {}>(new AxiosHttpClient('user'), {
    initialResponse: { approvalSettings: [], totalCount: 0 },
  })

  const execute = () => {
    const path = 'approval_settings'
    api.execute(path)
  }
  return { ...api, execute }
}

/**
 * 作成
 */
export function usePostApprovalSettingApi(): ApiSet<ApprovalSettingResponse> & { execute: (form: Form<ApprovalSettingForm>) => void } {
  const api = useRepruaPostApi<ApprovalSettingResponse, ApprovalSettingForm>(new AxiosHttpClient('user'), {
    initialResponse: { approvalSetting: {} },
  })

  const execute = (form: Form<ApprovalSettingForm>) => {
    const path = 'approval_settings'
    api.execute(path, form)
  }
  return { ...api, execute }
}

/**
 * 更新
 */
export function usePatchApprovalSettingApi(): ApiSet<ApprovalSettingResponse> & { execute: (form: ApprovalSettingForm) => void } {
  const api = useRepruaPatchApi<ApprovalSettingResponse, ApprovalSettingForm>(new AxiosHttpClient('user'), {
    initialResponse: { approvalSetting: {} },
  })

  const execute = (form: ApprovalSettingForm) => {
    const path = `approval_settings/${form.id}`
    api.execute(path, form)
  }

  return { ...api, execute }
}

/**
 * 削除
 */
export function useDeleteApprovalSettingApi(): ApiSet<BaseResponse> & { execute: (id: number) => void } {
  const api = useRepruaDeleteApi<BaseResponse>(new AxiosHttpClient('user'), {
    initialResponse: {},
  })

  const execute = (id: number) => {
    const path = `approval_settings/${id}`
    api.execute(path)
  }

  return { ...api, execute }
}
