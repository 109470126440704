import { CodeProps } from 'entities/print_setting'
import QRCode from 'qrcode.react'
import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { InstrumentViewModel } from 'view_models/instrument'
import { ProofreadInfoViewModel } from 'view_models/proofread_info'

type QrCodeProps = CodeProps & {
  path: string
}
const host = window.location.protocol + '//' + window.location.host

export const QrCode = ({ layoutSetting, instrument, displaySetting, path }: QrCodeProps) => {
  const value = `${host}${path}/${instrument.id || ''}`

  const useStyles = makeStyles()({
    qrcode: {
      '& canvas': {
        height: 'auto !important',
        width: `${layoutSetting?.imageScale} !important`,
      },
    },
    text: {
      fontSize: 10,
      transform: layoutSetting?.fontScale,
      overflowWrap: 'break-word',
    },
  })

  const { classes } = useStyles()

  const instrumentVM = new InstrumentViewModel(instrument)
  const proofreadInfoVM = instrument.proofreadInfo ? new ProofreadInfoViewModel(instrument.proofreadInfo) : null
  const codeStyle = { width: layoutSetting?.areaWidth, ...layoutSetting }

  return (
    <div className={classes.qrcode} style={codeStyle}>
      {displaySetting.showName && (
        <div className={classes.text} style={{ textAlign: 'center' }}>
          {instrumentVM.getInstrumentName()}
        </div>
      )}
      {displaySetting.showMaker && <div className={classes.text}>メーカー: {instrumentVM.getMakerName()}</div>}
      {displaySetting.showModelNumber && <div className={classes.text}>型番: {instrumentVM.modelNumber()}</div>}
      {displaySetting.showManageNumber && <div className={classes.text}>管理番号: {instrumentVM.manageNumber()}</div>}
      {displaySetting.showSerialNumber && <div className={classes.text}>S/N: {instrumentVM.serialNumber()}</div>}
      {displaySetting.showDealerName && <div className={classes.text}>担当業者: {instrumentVM.getDealerName()}</div>}
      {displaySetting.showOutsideDealerContactDetail && (
        <div className={classes.text}>連絡先: {instrumentVM.getOutsideDealerContactDetail()}</div>
      )}
      {displaySetting.showOutsideLaboratoryName && <div className={classes.text}>顧客名: {instrumentVM.ownerableName()}</div>}
      {displaySetting.showBaseDate && (
        <div className={classes.text}>最終校正月: {proofreadInfoVM ? proofreadInfoVM.getLastResultDate() : '-'}</div>
      )}
      <div style={{ textAlign: 'center', marginTop: 5 }}>
        <QRCode level="M" value={value} />
      </div>
    </div>
  )
}
