import { Table, TableBody, TableRow } from '@mui/material'
import { usePatchProofreadEventApproveApi } from 'api/mypage/proofread_event'
import DefectReportDataCard from 'components/mypage/defect_report/defect_report_data_card'
import RepairEventDataCard from 'components/mypage/repair_event/repair_event_data_card'
import { DisplayAttachmentsContainer } from 'components/shared/display_attachments_container'
import { StringField } from 'components/shared/form/input'
import Modal, { ModalSet } from 'components/shared/modal'
import { ExTableCell } from 'components/shared/table'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { MypageStateContext } from 'contexts/mypage_state_context'
import { ProofreadApproverForm } from 'entities/proofread_approver'
import { ProofreadEvent } from 'entities/proofread_event'
import { useForm } from 'rac'
import React, { useContext, useState } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'
import { ProofreadApproverViewModel } from 'view_models/proofread_approver'
import { ProofreadEventViewModel } from 'view_models/proofread_event'

type FixResultEventFormDialogProps = {
  modalSet: ModalSet<ProofreadEvent>
  onComplete: (event: ProofreadEvent) => void
}
export const FixResultEventFormDialog: React.FC<FixResultEventFormDialogProps> = (props) => {
  const { currentUser } = useContext(MypageStateContext)
  const globalState = useContext(GlobalStateContext)

  const [eventVm, setEventVm] = useState(new ProofreadEventViewModel(props.modalSet.item))

  const patchForm = useForm<ProofreadApproverForm>({
    proofreadEventId: props.modalSet.item.id!,
    kind: 'result',
    approverId: currentUser.id,
  })
  const patchApi = usePatchProofreadEventApproveApi(patchForm)

  const handleApprove = (status: 'approved' | 'rejected') => {
    globalState.confirm(`校正結果を${status === 'approved' ? '承認' : '却下'}します。よろしいですか？`, (e) => {
      if (e === 'ok') {
        patchApi.execute(status)
      }
    })
  }

  useEffectSkipFirst(() => {
    patchForm.resetForm()
    setEventVm(new ProofreadEventViewModel(props.modalSet.item))
  }, [props.modalSet.item.id])

  useEffectSkipFirst(() => {
    if (!patchApi.isSuccess()) return
    props.onComplete(props.modalSet.item)
  }, [patchApi.loading])

  return (
    <Modal
      modalId="event-planning-form"
      modalState={props.modalSet.modalState}
      title="校正結果承認"
      subTitle={eventVm.getInstrumentName()}
      footer={
        <>
          <ThemedButton onClick={props.modalSet.closeModal} color="secondary">
            閉じる
          </ThemedButton>
          {props.modalSet.item.approvers?.some((a) => a.approverId === currentUser.id && a.kind === 'result' && a.aasmState === 'yet') && (
            <>
              <ThemedButton color="error" onClick={() => handleApprove('rejected')}>
                却下
              </ThemedButton>
              <ThemedButton color="success" onClick={() => handleApprove('approved')}>
                承認
              </ThemedButton>
            </>
          )}
        </>
      }
    >
      <div>
        <label>結果</label>
        <p style={{ paddingLeft: 12, margin: '6px 0' }}>{eventVm.getResultLabel()}</p>
      </div>
      <div>
        <label>計画時メモ</label>
        <p style={{ paddingLeft: 12, margin: '6px 0' }}>{props.modalSet.item.noteOnPlan || 'なし'}</p>
      </div>
      <DisplayAttachmentsContainer label="計画時添付ファイル" attachments={props.modalSet.item.attachments} kind="plan" />
      {props.modalSet.item.suppliableType === 'Dealer' && (
        <div>
          <div>
            <label>パートナーメモ</label>
            <p style={{ paddingLeft: 12, margin: '6px 0' }}>{props.modalSet.item.noteOnDealerResult || 'なし'}</p>
          </div>
          <DisplayAttachmentsContainer label="パートナー添付ファイル" attachments={props.modalSet.item.attachments} kind="partner" />
        </div>
      )}
      {props.modalSet.item.defectReport && (
        <div style={{ marginBottom: 12 }}>
          <label>不具合報告</label>
          <DefectReportDataCard defectReport={props.modalSet.item.defectReport} noTitle />
        </div>
      )}
      {props.modalSet.item.repairEvent && (
        <div>
          <label>修理履歴</label>
          <RepairEventDataCard repairEvent={props.modalSet.item.repairEvent} noTitle />
        </div>
      )}

      <div style={{ marginTop: 12 }}>
        <label>結果入力時メモ</label>
        <p style={{ paddingLeft: 12, margin: '6px 0' }}>{props.modalSet.item.noteOnResult || 'なし'}</p>
      </div>
      <DisplayAttachmentsContainer label="結果入力時添付ファイル" attachments={props.modalSet.item.attachments} kind="result" />

      <div style={{ marginTop: 12 }}>
        <label>承認者</label>
        <Table>
          <TableBody>
            {props.modalSet.item.approvers?.map((a) => {
              if (a.kind === 'result') {
                const aVm = new ProofreadApproverViewModel(a)
                return (
                  <TableRow key={`approve-${a.id}`}>
                    <ExTableCell>
                      <div style={{ width: 130 }}>
                        {a.approver?.familyName} {a.approver?.givenName}
                      </div>
                    </ExTableCell>
                    <ExTableCell>
                      <div style={{ width: 60 }}>{aVm.getApproveLabel()}</div>
                    </ExTableCell>
                    <ExTableCell>
                      <div style={{ width: 360 }}>{aVm.getApprovalNote()}</div>
                    </ExTableCell>
                  </TableRow>
                )
              }
            })}
          </TableBody>
        </Table>
      </div>
      {props.modalSet.item.approvers?.some((a) => a.approverId === currentUser.id && a.kind === 'result' && a.aasmState === 'yet') && (
        <StringField label="承認メモ" form={patchForm} attr="note" apiError={patchApi.apiError} multiline style={{ marginTop: 24 }} />
      )}
    </Modal>
  )
}

export default FixResultEventFormDialog
