import { Table, TableBody, TableRow } from '@mui/material'
import { useUpdateProofreadEventApi } from 'api/mypage/proofread_event'
import { useDeleteAttachmentsApi } from 'api/shared/attachments'
import DefectReportDataCard from 'components/mypage/defect_report/defect_report_data_card'
import RepairEventDataCard from 'components/mypage/repair_event/repair_event_data_card'
import { DisplayAttachmentsContainer } from 'components/shared/display_attachments_container'
import { FilesInput } from 'components/shared/form/input'
import Modal, { ModalSet } from 'components/shared/modal'
import { ExTableCell } from 'components/shared/table'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { ProofreadEvent, ProofreadEventForm } from 'entities/proofread_event'
import { ProofreadEventAttachment } from 'entities/proofread_event_attachments'
import { useForm } from 'rac'
import React, { useContext, useState } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'
import { ProofreadApproverViewModel } from 'view_models/proofread_approver'
import { ProofreadEventViewModel } from 'view_models/proofread_event'

type ApprovedResultEventFormDialogProps = {
  modalSet: ModalSet<ProofreadEvent>
  onComplete: (event: ProofreadEvent) => void
}
export const ApprovedResultEventFormDialog: React.FC<ApprovedResultEventFormDialogProps> = (props) => {
  const globalState = useContext(GlobalStateContext)
  const [eventVm, setEventVm] = useState(new ProofreadEventViewModel(props.modalSet.item))
  const patchForm = useForm<ProofreadEventForm>(props.modalSet.item)
  const patchApi = useUpdateProofreadEventApi(patchForm)
  const deleteFileApi = useDeleteAttachmentsApi('user', 'proofread_events')

  const handleClickAddFileButton = () => {
    patchApi.execute()
  }

  const handleClickRemove = (a: ProofreadEventAttachment) => {
    globalState.confirm(`添付ファイル ${a.name} を削除しますか？ この操作は取り消せません`, (event) => {
      if (event === 'ok') {
        deleteFileApi.execute(props.modalSet.item.id!, a.id!)
      }
    })
  }

  useEffectSkipFirst(() => {
    setEventVm(new ProofreadEventViewModel(props.modalSet.item))
    patchForm.set(props.modalSet.item)
  }, [props.modalSet.item.id])

  useEffectSkipFirst(() => {
    if (!patchApi.isSuccess()) return
    props.onComplete(patchApi.response.proofreadEvent)
  }, [patchApi.loading])

  useEffectSkipFirst(() => {
    globalState.setLoading(deleteFileApi.loading)
    if (deleteFileApi.isSuccess()) {
      props.onComplete(patchApi.response.proofreadEvent)
    }
  }, [deleteFileApi.loading])

  return (
    <Modal
      modalId="event-planning-form"
      modalState={props.modalSet.modalState}
      title="校正結果確認"
      subTitle={eventVm.getInstrumentName()}
      footer={
        <>
          <ThemedButton onClick={props.modalSet.closeModal} color="secondary">
            閉じる
          </ThemedButton>
          <ThemedButton color="primary" onClick={handleClickAddFileButton}>
            ファイルの登録
          </ThemedButton>
        </>
      }
    >
      <div>
        <label>計画時メモ</label>
        <p style={{ paddingLeft: 12, margin: '6px 0' }}>{props.modalSet.item.noteOnPlan || 'なし'}</p>
      </div>
      <DisplayAttachmentsContainer label="計画時添付ファイル" attachments={props.modalSet.item.attachments} kind="plan" />
      {props.modalSet.item.suppliableType === 'Dealer' && (
        <div>
          <div>
            <label>パートナーメモ</label>
            <p style={{ paddingLeft: 12, margin: '6px 0' }}>{props.modalSet.item.noteOnDealerResult || 'なし'}</p>
          </div>
          <DisplayAttachmentsContainer label="パートナー添付ファイル" attachments={props.modalSet.item.attachments} kind="partner" />
        </div>
      )}
      {props.modalSet.item.defectReport && (
        <div style={{ marginBottom: 12 }}>
          <label>不具合報告</label>
          <DefectReportDataCard defectReport={props.modalSet.item.defectReport} noTitle />
        </div>
      )}
      {props.modalSet.item.repairEvent && (
        <div>
          <label>修理履歴</label>
          <RepairEventDataCard repairEvent={props.modalSet.item.repairEvent} noTitle />
        </div>
      )}
      <div style={{ marginTop: 12 }}>
        <label>結果入力時メモ</label>
        <p style={{ paddingLeft: 12, margin: '6px 0' }}>{props.modalSet.item.noteOnResult || 'なし'}</p>
      </div>
      <DisplayAttachmentsContainer label="結果入力時添付ファイル" attachments={props.modalSet.item.attachments} kind="result" />
      <div style={{ marginTop: 12 }}>
        <label>計画承認</label>
        <Table>
          <TableBody>
            {props.modalSet.item.approvers?.map((a) => {
              if (a.kind === 'plan') {
                const aVm = new ProofreadApproverViewModel(a)
                return (
                  <TableRow key={`approve-${a.id}`}>
                    <ExTableCell>
                      <div style={{ width: 130 }}>
                        {a.approver?.familyName} {a.approver?.givenName}
                      </div>
                    </ExTableCell>
                    <ExTableCell>
                      <div style={{ width: 60 }}>{aVm.getApproveLabel()}</div>
                    </ExTableCell>
                    <ExTableCell>
                      <div style={{ width: 360 }}>{aVm.getApprovalNote()}</div>
                    </ExTableCell>
                  </TableRow>
                )
              }
            })}
          </TableBody>
        </Table>
        {!props.modalSet.item.approvers?.some((a) => a.kind === 'plan') && <span style={{ paddingLeft: 12 }}>なし</span>}
      </div>
      <div style={{ marginTop: 12 }}>
        <label>結果承認</label>
        <Table>
          <TableBody>
            {props.modalSet.item.approvers?.map((a) => {
              if (a.kind === 'result') {
                const aVm = new ProofreadApproverViewModel(a)
                return (
                  <TableRow key={`approve-${a.id}`}>
                    <ExTableCell>
                      <div style={{ width: 130 }}>
                        {a.approver?.familyName} {a.approver?.givenName}
                      </div>
                    </ExTableCell>
                    <ExTableCell>
                      <div style={{ width: 60 }}>{aVm.getApproveLabel()}</div>
                    </ExTableCell>
                    <ExTableCell>
                      <div style={{ width: 360 }}>{aVm.getApprovalNote()}</div>
                    </ExTableCell>
                  </TableRow>
                )
              }
            })}
          </TableBody>
        </Table>
        {!props.modalSet.item.approvers?.some((a) => a.kind === 'result') && <span style={{ paddingLeft: 12 }}>なし</span>}
      </div>
      <div style={{ marginTop: 12 }}>
        <DisplayAttachmentsContainer
          label="校正完了後の追加ファイル"
          attachments={props.modalSet.item.attachments}
          kind="complete"
          onClickRemove={handleClickRemove}
        />
        <FilesInput form={patchForm} attr="attachmentsAttributes" apiError={patchApi.apiError} />
      </div>
    </Modal>
  )
}

export default ApprovedResultEventFormDialog
