import { ReservationTerm } from 'entities/reservation_term'
import moment from 'moment'

import { ViewModel } from '.'

export class ReservationTermViewModel extends ViewModel<ReservationTerm> {
  public getUnit(): string {
    if (this.object.unit === 'minute') {
      return '分'
    } else if (this.object.unit === 'hour') {
      return '時間'
    } else if (this.object.unit === 'day') {
      return '日'
    } else {
      return '-'
    }
  }

  public getAvalableTimeText(): string {
    return `${this.getStartAtText()} ~ ${this.getEndAtText()}`
  }

  public getStartAtText(): string {
    if (this.object.startTime) {
      const date = moment(this.object.startTime, ['YYYY-MM-DDTHH:mm:ssZ', 'HH:mm'])
      return date.format('HH:mm')
    } else {
      return ''
    }
  }

  public getEndAtText(): string {
    if (this.object.endTime) {
      const date = moment(this.object.endTime, ['YYYY-MM-DDTHH:mm:ssZ', 'HH:mm'])
      return date.format('HH:mm')
    } else {
      return ''
    }
  }

  public getPriceAndUnitText(): string {
    if (this.object.price) {
      return `¥${this.object.price.toLocaleString()} / ${this.getUnit()}`
    } else {
      return '-'
    }
  }

  public getSectionText(): string {
    if (this.object.section) {
      return `${this.object.section}分`
    } else {
      return '-'
    }
  }
}
