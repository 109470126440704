import { Table, TableHead, TableRow, TableCell, TableContainer, Tooltip } from '@mui/material'
import { Flex } from 'components/shared/flex'
import { FileInput } from 'components/shared/form/input'
import Modal, { ModalState, useModal } from 'components/shared/modal'
import { ExTableBody } from 'components/shared/table'
import ThemedButton from 'components/shared/themed_button'
import { InstrumentImportError } from 'entities/instrument_import_error'
import { InstrumentImportResult, InstrumentImportForm } from 'entities/instrument_import_result'
import { Form } from 'rac/dist/use_form'
import React, { useState } from 'react'
import { InstrumentImportResultViewModel } from 'view_models/instrument_import_result'

type InstrumentImportModalProps = {
  modalState: ModalState
  form: Form<InstrumentImportForm>
  loading: boolean
  instrumentImportResults: InstrumentImportResult[]
  onClickImportCancelButton: (id: number) => void
  onClickDownloadFormat: () => void
  onSubmit: () => void
}

export const InstrumentImportModal = ({
  modalState,
  form,
  loading,
  instrumentImportResults,
  onClickDownloadFormat,
  onClickImportCancelButton,
  onSubmit,
}: InstrumentImportModalProps) => {
  const errorDetailModal = useModal()
  const [importErrors, setImportErrors] = useState<InstrumentImportError[]>([])
  const handleClickImportErrorDetailButton = (importErrors: InstrumentImportError[]) => {
    errorDetailModal.setOpen(true)
    setImportErrors(importErrors)
  }

  return (
    <>
      <ErrorDetailModal modalState={errorDetailModal} importErrors={importErrors} />
      <Modal
        size="lg"
        modalId="admin-dealer-user-instrument-import-modal"
        title="機器のインポート"
        modalState={modalState}
        footer={
          <Flex style={{ width: '100%' }} justifyContent="space-between">
            <div>
              <ThemedButton color="secondary" onClick={onClickDownloadFormat}>
                フォーマットのダウンロード
              </ThemedButton>
            </div>
            <Flex>
              <ThemedButton onClick={() => modalState.setOpen(false)} color="secondary">
                キャンセル
              </ThemedButton>
            </Flex>
          </Flex>
        }
      >
        <FileInput index={0} form={form} attr="uploadFile" />
        <Flex justifyContent="flex-end" style={{ marginBottom: 20 }}>
          <ThemedButton onClick={onSubmit} color="success" disabled={!form.object.uploadFile}>
            インポート
          </ThemedButton>
        </Flex>
        <TableContainer style={{ maxHeight: 600 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>名前</TableCell>
                <TableCell width={80} align="center">
                  総数
                </TableCell>
                <TableCell width={80} align="center">
                  失敗数
                </TableCell>
                <TableCell width={150}>作成日</TableCell>
                <TableCell width={100} align="center">
                  エラー
                </TableCell>
                <TableCell width={80} />
              </TableRow>
            </TableHead>
            <ExTableBody loading={loading} style={{ justifyContent: 'center', alignItems: 'center' }}>
              {instrumentImportResults.map((result) => {
                const vm = new InstrumentImportResultViewModel(result)
                return (
                  <TableRow key={result.id}>
                    <TableCell>{result.fileName}</TableCell>
                    <TableCell align="center">{result.total}</TableCell>
                    <TableCell align="center">{result.failedCount}</TableCell>
                    <TableCell>{vm.createdAt().format('Y/MM/DD HH:mm')}</TableCell>
                    <TableCell align="center">
                      {result.instrumentImportErrors && result.instrumentImportErrors.length > 0 && (
                        <ThemedButton color="secondary" onClick={() => handleClickImportErrorDetailButton(result.instrumentImportErrors!)}>
                          詳細
                        </ThemedButton>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title={result.importType === 'sharing' ? '機器共有インポートのため取り消せません' : ''}>
                        <div>
                          <ThemedButton color="error" onClick={() => onClickImportCancelButton(result.id!)} disabled={result.importType === 'sharing'}>
                            取消
                          </ThemedButton>
                        </div>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                )
              })}
            </ExTableBody>
          </Table>
        </TableContainer>
      </Modal>
    </>
  )
}

type ErrorDetailModalProps = {
  importErrors: InstrumentImportError[]
  modalState: ModalState
}

const ErrorDetailModal = ({ modalState, importErrors }: ErrorDetailModalProps) => {
  return (
    <Modal
      size="lg"
      modalId="admin-dealer-user-instrument-import-error-detail"
      title="エラー詳細"
      modalState={modalState}
      footer={
        <Flex>
          <ThemedButton onClick={() => modalState.setOpen(false)} color="secondary">
            キャンセル
          </ThemedButton>
        </Flex>
      }
    >
      <TableContainer style={{ maxHeight: 600 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>行数</TableCell>
              <TableCell>エラー内容</TableCell>
            </TableRow>
          </TableHead>
          <ExTableBody loading={false} style={{ justifyContent: 'center', alignItems: 'center' }}>
            {importErrors.map((error) => {
              return (
                <TableRow key={error.id}>
                  <TableCell>{error.rowNumber}</TableCell>
                  <TableCell>{error.message && convertErrorMessage(error.message)}</TableCell>
                </TableRow>
              )
            })}
          </ExTableBody>
        </Table>
      </TableContainer>
    </Modal>
  )
}

const convertErrorMessage = (messages: string) => {
  return (
    <>
      {messages.split(',').map((message, index) => {
        return <p key={`error-message-${index}`}>{message}</p>
      })}
    </>
  )
}
