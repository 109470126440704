import { OwnerFilter } from 'entities/dealer';
import { atom } from 'recoil';
import { localStorageEffect } from 'recoil/shared/local_storage_effect'

export const ownerFilterAtom = atom<OwnerFilter>({
  key: 'ownerFilter',
  default: {
    ownerableId: undefined,
    ownerableType: 'OutsideLaboratory'
  },
  effects: [
    localStorageEffect('owner-filter'),
  ]
});