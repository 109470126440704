import { Help } from '@mui/icons-material'
import { Box, Tooltip } from '@mui/material'
import React from 'react'

type LabelWithHelpProps = {
  title: string
  message: string
}

// ラベルとヘルプアイコンを表示しmessageで指定した内容をツールチップで表示するコンポーネント
export const LabelWithHelp: React.FC<LabelWithHelpProps> = (props) => {
  return (
    <Box display="flex" alignItems="center">
      <label>{props.title}</label>
      <Tooltip title={props.message}>
        <Help sx={{ ml: '5px', fontSize: '20px' }} />
      </Tooltip>
    </Box>
  )
}
