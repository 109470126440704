import { Flex } from 'components/shared/flex'
import { StringField } from 'components/shared/form/input'
import Modal, { ModalState, useModal } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { ID } from 'entities'
import { OutsideLaboratory, OutsideLaboratoryForm } from 'entities/outside_laboratory'
import { ApiError, ApiSet, Form, useForm } from 'rac'
import React, { useEffect, useContext } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'

// 過去に使用済みの名前かどうかをチェックする関数
const isUsedName = (name: string, disabledOutsideLaboratories: OutsideLaboratory[]) => {
  return disabledOutsideLaboratories.map((o) => o.name).includes(name)
}

const dialogContent = (outsideLaboratoryForm: Form<OutsideLaboratoryForm>, error: ApiError) => {
  return (
    <Flex>
      <StringField label="名前" attr="name" form={outsideLaboratoryForm} apiError={error} required />
    </Flex>
  )
}

type NewOutsideLaboratoryFormDialogProps<T> = {
  apiSet: T
  onSubmit: (outsideLaboratoryForm: Form<OutsideLaboratoryForm>) => void
  onComplete: () => void
  disabledOutsideLaboratories: OutsideLaboratory[]
}

export const NewOutsideLaboratoryFormDialog = <T extends ApiSet<any>>({
  apiSet,
  onSubmit,
  onComplete,
  disabledOutsideLaboratories,
}: NewOutsideLaboratoryFormDialogProps<T>) => {
  const modalState = useModal()
  const outsideLaboratoryForm = useForm<OutsideLaboratoryForm>({ name: '' }, 'outsideLaboratory')
  const globalState = useContext(GlobalStateContext)

  useEffectSkipFirst(() => {
    globalState.setLoading(apiSet.loading)

    if (apiSet.isSuccess()) {
      globalState.setNotificationMessage({ body: '外部顧客マスタを登録しました' })
      outsideLaboratoryForm.resetForm()
      onComplete()
      modalState.setOpen(false)
    }
  }, [apiSet.loading])

  const handleOpen = () => {
    modalState.setOpen(true)
  }

  const handleClose = () => {
    modalState.setOpen(false)
  }

  const handleSubmit = () => {
    if (outsideLaboratoryForm.object.name && isUsedName(outsideLaboratoryForm.object.name, disabledOutsideLaboratories)) {
      globalState.confirm('過去に同名の外部顧客を登録していますがよろしいですか？', (event) => {
        if (event === 'ok') {
          onSubmit(outsideLaboratoryForm)
        }
      })
    } else {
      onSubmit(outsideLaboratoryForm)
    }
  }

  return (
    <div>
      <ThemedButton color="primary" onClick={handleOpen}>
        新規登録
      </ThemedButton>

      <Modal
        modalId="outside-laboratory-new"
        title="外部顧客マスタ登録"
        modalState={modalState}
        size="xs"
        footer={
          <>
            <ThemedButton onClick={handleClose} color="secondary">
              閉じる
            </ThemedButton>
            <ThemedButton onClick={handleSubmit} color="success">
              登録
            </ThemedButton>
          </>
        }
      >
        {dialogContent(outsideLaboratoryForm, apiSet.apiError)}
      </Modal>
    </div>
  )
}

type EditOutsideLaboratoryFormDialogProps<T> = {
  apiSet: T
  outsideLaboratory?: OutsideLaboratory
  onSubmit: (outsideLaboratoryForm: OutsideLaboratoryForm) => void
  onComplete: () => void
  modalState: ModalState
  disabledOutsideLaboratories: OutsideLaboratory[]
}

export const EditOutsideLaboratoryFormDialog = <T extends ApiSet<any>>({
  apiSet,
  outsideLaboratory,
  onSubmit,
  onComplete,
  modalState,
  disabledOutsideLaboratories,
}: EditOutsideLaboratoryFormDialogProps<T>) => {
  const outsideLaboratoryForm = useForm<OutsideLaboratoryForm>({ ...outsideLaboratory }, 'outsideLaboratory')
  const globalState = useContext(GlobalStateContext)
  const handleClickClose = () => {
    modalState.setOpen(false)
  }

  const handleSubmit = () => {
    if (outsideLaboratoryForm.object.name && isUsedName(outsideLaboratoryForm.object.name, disabledOutsideLaboratories)) {
      globalState.confirm('過去に同名の外部顧客を登録していますがよろしいですか？', (event) => {
        if (event === 'ok') {
          onSubmit(outsideLaboratoryForm.object)
        }
      })
    } else {
      onSubmit(outsideLaboratoryForm.object)
    }
  }

  useEffect(() => {
    outsideLaboratoryForm.set({ ...outsideLaboratory })
  }, [outsideLaboratory])

  useEffectSkipFirst(() => {
    globalState.setLoading(apiSet.loading)

    if (apiSet.isSuccess()) {
      globalState.setNotificationMessage({ body: '外部顧客マスタを更新しました' })
      modalState.setOpen(false)
      onComplete()
    }
  }, [apiSet.loading])

  return (
    <>
      <Modal
        modalId="instrument-category-edit"
        modalState={modalState}
        title="外部顧客マスタ編集"
        size="xs"
        footer={
          <>
            <ThemedButton onClick={handleClickClose} color="secondary">
              閉じる
            </ThemedButton>
            <ThemedButton onClick={handleSubmit} color="success">
              更新
            </ThemedButton>
          </>
        }
      >
        {dialogContent(outsideLaboratoryForm, apiSet.apiError)}
      </Modal>
    </>
  )
}

//
// 削除
//
type DeleteOutsideLaboratoryButtonProps<T> = {
  outsideLaboratoryId: number
  apiSet: T
  onSubmit: (outsideLaboratoryId: ID) => void
  onComplete: () => void
}

export const DeleteOutsideLaboratoryButton = <T extends ApiSet<any>>({
  outsideLaboratoryId,
  apiSet,
  onSubmit,
  onComplete,
}: DeleteOutsideLaboratoryButtonProps<T>) => {
  const globalState = useContext(GlobalStateContext)

  const handleClick = () => {
    globalState.confirm('外部顧客マスタを削除します。よろしいですか？（外部顧客マスタに紐づく機器は管理対象外に遷移します）', (e) => {
      if (e === 'ok') {
        onSubmit(outsideLaboratoryId)
      }
    })
  }

  useEffect(() => {
    globalState.setLoading(apiSet.loading)
  }, [apiSet.loading])

  useEffectSkipFirst(() => {
    if (apiSet.isSuccess()) {
      globalState.setNotificationMessage({ body: '外部顧客マスタを削除しました' })
      onComplete()
    }
  }, [apiSet.loading])

  return (
    <ThemedButton color="error" onClick={handleClick}>
      削除
    </ThemedButton>
  )
}
