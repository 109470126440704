import { AxiosHttpClient } from 'api/axios'
import { useRepruaPostApi } from 'api/shared_api_hooks'
import { RepairApproval, RepairApprovalForm } from 'entities/repair_approval'
import { ApiSet, BaseResponse, Form } from 'rac'

type RepairApprovalResponse = BaseResponse & {
  repairApproval: RepairApproval
}

/**
 * 作成
 */
export function usePostEstimateRepairApprovalApi(): ApiSet<RepairApprovalResponse> & { execute: (form: Form<RepairApprovalForm>) => void } {
  const api = useRepruaPostApi<RepairApprovalResponse, RepairApprovalForm>(new AxiosHttpClient('user'), {
    initialResponse: { repairApproval: {} },
  })

  const execute = (form: Form<RepairApprovalForm>) => {
    const path = 'repair_approvals'
    api.execute(path, form)
  }
  return { ...api, execute }
}
