import CancelIcon from '@mui/icons-material/CancelOutlined'
import { Collapse, Stack, Tooltip } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { usePostInstrumentApi, usePatchInstrumentApi, useDeleteInstrumentApi } from 'api/mypage/instrument'
import { useDeleteUnmanagedInstrumentApi, useRestoreManagementUnmanagedInstrumentApi } from 'api/mypage/unmanaged_instrument'
import { useFetchUsersApi } from 'api/mypage/users'
import { useDeleteAttachmentsApi } from 'api/shared/attachments'
import { useDeleteInstrumentImageApi } from 'api/shared/instrument_image'
import { CheckboxAccordion } from 'components/shared/checkbox_accordion'
import AttachmentsList from 'components/shared/data/attachments_list'
import { Flex } from 'components/shared/flex'
import {
  StringField,
  NumberField,
  DateTimeField,
  SelectField,
  ImageInput,
  MonthField,
  FilesInput,
  DateField,
} from 'components/shared/form/input'
import { Hint } from 'components/shared/hint'
import { ProofreadAlarmForm } from 'components/shared/proofread_alarm_form'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { ApprovalSetting } from 'entities/approval_setting'
import { CancelPolicy } from 'entities/cancel_policy'
import { Dealer } from 'entities/dealer'
import { FileAttachment } from 'entities/file_attachment'
import { InstrumentForm, Instrument, cantEditProofreadInfoMessage } from 'entities/instrument'
import { InstrumentAlarmAttributeForm } from 'entities/instrument_alarm_attribute'
import { InstrumentAttachment } from 'entities/instrument_attachment'
import { InstrumentCategory } from 'entities/instrument_category'
import { OutsideDealer } from 'entities/outside_dealer'
import { getTimeSelectObject } from 'entities/reservation_term'
import { Room } from 'entities/room'
import { partnerLabels } from 'entities/supplier'
import { warningDiscardUnmanagedInstrumentDescription, warningExcludeManagementInstrumentDescription } from 'entities/unmanaged_instrument'
import { User } from 'entities/user'
import moment from 'moment'
import { ApiError, Form, useForm } from 'rac'
import React, { useState, useEffect, useContext } from 'react'
import { useEffectSkipFirst } from 'utils/hooks/index'
import { InstrumentViewModel } from 'view_models/instrument'
import { ReservationTermViewModel } from 'view_models/reservation_term'

const maxInstrumentAlarmCount = 10

type DialogFormContentProps = {
  instrumentForm: Form<InstrumentForm>
  error: ApiError
  rooms?: Room[]
  instrumentCategories?: InstrumentCategory[]
  cancelPolicies?: CancelPolicy[]
  users?: User[]
  dealers?: Dealer[]
  outsideDealers?: OutsideDealer[]
  approvalSettings?: ApprovalSetting[]
  onClickRemoveFile?: (a: InstrumentAttachment) => void
  onClickRemoveImage?: () => void
  formType: 'create' | 'update'
}

const DialogFormContent: React.FC<DialogFormContentProps> = (props) => {
  const { instrumentForm: form, error, rooms, instrumentCategories, cancelPolicies, approvalSettings } = props
  const instrumentVM = new InstrumentViewModel(form.object)
  const reservationVM = new ReservationTermViewModel(form.object.reservationTermAttributes!)

  const proofreadBaseDateEditable =
    form.object?.proofreadInfo?.proofreadBaseDateEditable === undefined ? true : form.object?.proofreadInfo?.proofreadBaseDateEditable

  const handleRemoveInstrumentAlarm = (alarmIndex: number) => {
    form.update((f) => {
      if (!f.instrumentAlarmsAttributes || !f.instrumentAlarmsAttributes[alarmIndex]) {
        return
      }
      if (props.formType === 'create') {
        f.instrumentAlarmsAttributes.splice(alarmIndex, 1)
      } else {
        f.instrumentAlarmsAttributes[alarmIndex]._destroy = true
      }
    })
  }

  const handleClickAddInstrumentAlarm = () => {
    const now = moment().toString()
    form.update((f) => {
      if (!f.instrumentAlarmsAttributes) {
        return
      }
      if (f.instrumentAlarmsAttributes.filter((c) => !c._destroy).length >= maxInstrumentAlarmCount) {
        alert(`機器アラームの設定は最大${maxInstrumentAlarmCount}個までです`)
        return
      }
      if (f.instrumentAlarmsAttributes) {
        f.instrumentAlarmsAttributes.push({ createdAt: now })
      } else {
        f.instrumentAlarmsAttributes = [{ createdAt: now }]
      }
    })
  }

  useEffect(() => {
    // 校正のアラームの有効化が変更された際に_destroyの値を変更する
    form.update((f) => {
      const proofreadAlarmAttributes = f.proofreadAlarmAttributes
      if (proofreadAlarmAttributes) {
        if (f.alarmEnable) {
          proofreadAlarmAttributes._destroy = false
        } else {
          proofreadAlarmAttributes._destroy = true
        }
      }
    })
  }, [form.object.alarmEnable])

  return (
    <DialogContent>
      <ImageInput
        attr={['imageAttributes', 'attachment']}
        form={form}
        urlAttr={['instrumentBasicInfoAttributes', 'imageUrl']}
        onClickRemove={props.onClickRemoveImage}
      />
      <Stack>
        {/* 共有機器は業者を変更できない */}
        <label style={{ marginBottom: 10 }}>担当業者</label>
        {form.object.isShared ? (
          <p style={{ marginBottom: 10 }}>{instrumentVM.getDealerName()}</p>
        ) : (
          <SelectField
            form={form}
            labelId="manager-label"
            attr="outsideDealerId"
            label="外部業者"
            data={(props.outsideDealers || []).map((p) => ({ value: p.id!, label: p.name! }))}
            apiError={error}
            includeBlank
          />
        )}
      </Stack>
      <Stack>
        <label style={{ marginBottom: 10 }}>部屋</label>
        {rooms && (
          <SelectField
            form={form}
            attr="roomId"
            data={rooms.map((room) => {
              return { value: room.id!, label: room.name! }
            })}
            labelId="select-rooms"
            label="部屋"
            required
            apiError={error}
          />
        )}
      </Stack>
      <Stack>
        <label style={{ marginBottom: 10 }}>機器情報</label>
        <Flex>
          <StringField label="メーカー" attr={['instrumentBasicInfoAttributes', 'maker']} form={form} apiError={error} required />
          <StringField label="型番" attr={['instrumentBasicInfoAttributes', 'modelNumber']} form={form} apiError={error} required />
        </Flex>
        <StringField label="名称" attr={['instrumentBasicInfoAttributes', 'name']} form={form} apiError={error} required />
        <SelectField
          form={form}
          labelId="manager-label"
          attr="managerId"
          label="担当者"
          data={props.users?.map((u) => ({ label: `${u.familyName} ${u.givenName}`, value: u.id })) || []}
          apiError={error}
          includeBlank
          required={form.object.alarmEnable}
        />
        {instrumentCategories && instrumentCategories.length > 0 && (
          <SelectField
            form={form}
            attr={['instrumentBasicInfoAttributes', 'instrumentCategoryId']}
            data={instrumentCategories.map((instrumentCategory) => {
              return { value: instrumentCategory.id!, label: instrumentCategory.name! }
            })}
            includeBlank
            labelId="select-instrument-categories"
            label="機器カテゴリ"
            apiError={error}
          />
        )}
        {approvalSettings && approvalSettings.length > 0 && (
          <SelectField
            form={form}
            attr="approvalSettingId"
            data={approvalSettings.map((approvalSettin) => {
              return { value: approvalSettin.id!, label: approvalSettin.name! }
            })}
            includeBlank
            labelId="select-instrument-approval"
            label="承認設定"
            apiError={error}
          />
        )}
        <Flex>
          <StringField label="シリアル番号" attr={['instrumentBasicInfoAttributes', 'serialNumber']} form={form} apiError={error} />
          <StringField label="管理番号" attr={['instrumentBasicInfoAttributes', 'manageNumber']} form={form} apiError={error} />
        </Flex>
        <Flex>
          <DateTimeField label="取得日" attr={['instrumentBasicInfoAttributes', 'purchasedAt']} form={form} apiError={error} clearable />
          <NumberField label="取得価額" attr={['instrumentBasicInfoAttributes', 'purchasedPrice']} form={form} apiError={error} />
        </Flex>
        <StringField label="機器の説明等" attr={['instrumentBasicInfoAttributes', 'note']} form={form} apiError={error} multiline />
      </Stack>
      <Stack>
        <label style={{ marginBottom: 10 }}>外部リンク</label>
        <Flex>
          <StringField label="表示名" attr={['instrumentBasicInfoAttributes', 'externalLinkName']} form={form} apiError={error} />
          <StringField label="URL" attr={['instrumentBasicInfoAttributes', 'externalLink']} form={form} apiError={error} />
        </Flex>
      </Stack>
      <AttachmentsList
        fileAttachments={form.object.instrumentBasicInfo?.fileAttachments || []}
        onClickRemove={props.onClickRemoveFile}
        emptyText=" "
      />
      <FilesInput form={form} attr="attachmentsAttributes" apiError={error} />
      <CheckboxAccordion
        label="機器のアラーム設定をする"
        onChangeChecked={(checked) => {
          form.newUpdateObject(['instrumentBasicInfoAttributes', 'requireInstrumentAlarm'], checked)
        }}
        defaultChecked={form.object.instrumentBasicInfo?.requireInstrumentAlarm}
      >
        {form.object.instrumentAlarmsAttributes?.map((alarm: InstrumentAlarmAttributeForm, i: number) => {
          return alarm._destroy ? (
            <></>
          ) : (
            <div key={`instrumentAlarm-${i}`}>
              <div style={{ padding: 20, backgroundColor: '#fafafa', margin: '10px 0', position: 'relative' }}>
                <Flex style={{ marginBottom: 10 }}>
                  <label>アラーム情報{i + 1}</label>
                </Flex>
                <Flex>
                  <div>
                    <Flex alignItems="flex-start">
                      <Tooltip title={proofreadBaseDateEditable ? '' : cantEditProofreadInfoMessage}>
                        <div style={{ width: '40%' }}>
                          <DateField
                            label="基準日"
                            attr={['instrumentAlarmsAttributes', i, 'baseDate']}
                            form={form}
                            apiError={error}
                            required
                            clearable
                          />
                        </div>
                      </Tooltip>
                      <div style={{ marginTop: 12 }}>の</div>

                      <NumberField attr={['instrumentAlarmsAttributes', i, 'value']} form={form} required apiError={error} width={160} />
                      <SelectField
                        form={form}
                        labelId="proofread-alarm-unit-label"
                        attr={['instrumentAlarmsAttributes', i, 'unit']}
                        label="単位"
                        data={[
                          { value: 'day', label: '日' },
                          { value: 'week', label: '週' },
                          { value: 'month', label: '月' },
                        ]}
                        required
                        apiError={error}
                        width={100}
                      />
                      <div style={{ marginTop: 12, width: 150 }}>前に通知</div>
                    </Flex>
                    <Flex>
                      <StringField
                        label="アラームのメッセージ"
                        attr={['instrumentAlarmsAttributes', i, 'alarmMessage']}
                        form={form}
                        required
                        apiError={error}
                        multiline
                      />
                    </Flex>
                  </div>
                </Flex>
                <div style={{ minWidth: 30, top: 20, right: 20, position: 'absolute' }}>
                  {i > 0 && <CancelIcon color="action" onClick={() => handleRemoveInstrumentAlarm(i)}></CancelIcon>}
                </div>
              </div>
            </div>
          )
        })}
        {form.object.instrumentAlarmsAttributes &&
          form.object.instrumentAlarmsAttributes.filter((c) => !c._destroy).length < maxInstrumentAlarmCount && (
            <ThemedButton onClick={() => handleClickAddInstrumentAlarm()} color="secondary">
              アラームを追加
            </ThemedButton>
          )}
      </CheckboxAccordion>
      <CheckboxAccordion
        label="機器の予約管理をする"
        onChangeChecked={(checked) => {
          form.update((f) => (f.canReserve = checked))
        }}
        defaultChecked={form.object.canReserve}
      >
        <Flex style={{ marginBottom: 10 }}>
          <label>予約情報</label>
        </Flex>
        <Flex>
          <SelectField
            required
            form={form}
            labelId="startTime-label"
            attr={['reservationTermAttributes', 'startTime']}
            label="貸出開始時間"
            data={getTimeSelectObject()}
            defaultValue={reservationVM.getStartAtText()}
            apiError={error}
          />
          <SelectField
            required
            form={form}
            labelId="startTime-label"
            attr={['reservationTermAttributes', 'endTime']}
            label="貸出終了時間"
            data={getTimeSelectObject()}
            defaultValue={reservationVM.getEndAtText()}
            apiError={error}
          />
        </Flex>
        <Flex>
          <NumberField label="貸出単価" attr={['reservationTermAttributes', 'price']} form={form} apiError={error} />

          <SelectField
            form={form}
            labelId="unit-label"
            attr={['reservationTermAttributes', 'unit']}
            label="貸出単位"
            data={[
              { value: 'minute', label: '分あたり' },
              { value: 'hour', label: '時間あたり' },
              { value: 'day', label: '1日あたり' },
            ]}
            apiError={error}
            required={!!form.object.reservationTermAttributes?.price}
          />
        </Flex>
        <Flex>
          <NumberField label="予約単位(分)" attr={['reservationTermAttributes', 'section']} form={form} apiError={error} />
        </Flex>
        {cancelPolicies && cancelPolicies.length > 0 && (
          <SelectField
            form={form}
            attr={['reservationTermAttributes', 'cancelPolicyId']}
            data={cancelPolicies.map((cancelPolicy) => {
              return {
                value: cancelPolicy.id,
                label: `${instrumentVM.fixDeadline(cancelPolicy.deadline)}前までキャンセル可能`,
              }
            })}
            includeBlank
            labelId="select-cancel-policies"
            label="キャンセルポリシー"
            apiError={error}
          />
        )}
      </CheckboxAccordion>
      <CheckboxAccordion
        label="機器の校正管理をする"
        onChangeChecked={(checked) => form.newUpdateObject('requireProofread', checked)}
        defaultChecked={form.object.requireProofread}
      >
        {/* 機器共有していない機器のみ、デフォルト外部業者を設定できる */}
        {(props.outsideDealers || []).length > 0 && !form.object.isShared && (
          <>
            <Flex>
              <label>デフォルトの委託先</label>
              <Hint title="機器の校正計画時にデフォルトで設定される委託先を選択できます。" />
            </Flex>
            <Flex>
              <div style={{ flex: 1 }}>
                <SelectField
                  form={form}
                  labelId="proofread-supplier-type-label"
                  attr={['proofreadInfoAttributes', 'suppliableType']}
                  label="業者種別"
                  data={partnerLabels.filter((l) => {
                    return l.value === 'OutsideDealer'
                  })}
                  apiError={error}
                  includeBlank
                />
              </div>
              <div style={{ flex: 1 }}>
                <Collapse in={!!form.object.proofreadInfoAttributes.suppliableType}>
                  <SelectField
                    form={form}
                    labelId="proofread-supplier-id-label"
                    attr={['proofreadInfoAttributes', 'suppliableId']}
                    label="外部業者"
                    data={(props.outsideDealers || []).map((p) => ({ value: p.id!, label: p.name! }))}
                    apiError={error}
                    required={!!form.object.proofreadInfoAttributes.suppliableType}
                  />
                </Collapse>
              </div>
            </Flex>
          </>
        )}
        <Flex style={{ marginBottom: 10 }}>
          <label>校正情報</label>
        </Flex>
        <Flex>
          <Tooltip title={proofreadBaseDateEditable ? '' : cantEditProofreadInfoMessage}>
            <div style={{ width: '100%' }}>
              <NumberField
                label="校正周期"
                attr={['proofreadInfoAttributes', 'cycle']}
                form={form}
                apiError={error}
                required
                disabled={!proofreadBaseDateEditable}
              />
            </div>
          </Tooltip>
          <Tooltip title={proofreadBaseDateEditable ? '' : cantEditProofreadInfoMessage}>
            <div style={{ width: '100%' }}>
              <SelectField
                form={form}
                labelId="proofread-unit-label"
                attr={['proofreadInfoAttributes', 'cycleUnit']}
                label="周期単位"
                data={[
                  { value: 'month', label: '月毎' },
                  { value: 'year', label: '年毎' },
                ]}
                apiError={error}
                required
                disabled={!proofreadBaseDateEditable}
              />
            </div>
          </Tooltip>
        </Flex>
        <Flex>
          <Tooltip title={proofreadBaseDateEditable ? '' : cantEditProofreadInfoMessage}>
            <div style={{ width: '100%' }}>
              <MonthField
                label="校正基準月・最終校正月"
                attr={['proofreadInfoAttributes', 'baseDate']}
                form={form}
                apiError={error}
                required
                disabled={!proofreadBaseDateEditable}
              />
            </div>
          </Tooltip>
        </Flex>
        <Flex>
          <NumberField label="校正価格" attr={['proofreadInfoAttributes', 'price']} form={form} apiError={error} />
        </Flex>
        <ProofreadAlarmForm form={form} error={error} ownerableType="laboratory" />
      </CheckboxAccordion>
    </DialogContent>
  )
}

type NewInstrumentFormDialogProps = {
  onComplete: (instrument: Instrument) => void
  rooms?: Room[]
  instrumentCategories?: InstrumentCategory[]
  cancelPolicies?: CancelPolicy[]
  dealers?: Dealer[]
  outsideDealers?: OutsideDealer[]
  approvalSettings?: ApprovalSetting[]
}

export const NewInstrumentFormDialog: React.FC<NewInstrumentFormDialogProps> = (props: NewInstrumentFormDialogProps) => {
  const [open, setOpen] = useState(false)
  const instrumentForm = useForm<InstrumentForm>(
    {
      imageAttributes: {},
      reservationTermAttributes: { section: 5 },
      instrumentBasicInfoAttributes: {},
      proofreadInfoAttributes: {},
      proofreadAlarmAttributes: {},
      instrumentAlarmsAttributes: [{}],
    },
    'instrument',
  )
  const api = usePostInstrumentApi()
  const globalState = useContext(GlobalStateContext)
  const usersApi = useFetchUsersApi()

  useEffectSkipFirst(() => {
    setOpen(false)
    props.onComplete(api.response.instrument)
    instrumentForm.resetForm()
  }, [api.response.instrument])

  useEffect(() => {
    globalState.setLoading(api.loading)
  }, [api.loading])

  useEffect(() => {
    usersApi.execute()
  }, [])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = () => {
    api.execute(instrumentForm)
  }

  return (
    <div>
      <ThemedButton color="primary" onClick={handleClickOpen}>
        新規登録
      </ThemedButton>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="xl">
        <DialogTitle id="form-dialog-title">機器登録</DialogTitle>
        <DialogFormContent
          instrumentForm={instrumentForm}
          error={api.apiError}
          rooms={props.rooms}
          instrumentCategories={props.instrumentCategories}
          cancelPolicies={props.cancelPolicies}
          users={usersApi.response.users}
          dealers={props.dealers}
          outsideDealers={props.outsideDealers}
          approvalSettings={props.approvalSettings}
          formType="create"
        />
        <DialogActions>
          <ThemedButton onClick={handleClose} color="secondary">
            閉じる
          </ThemedButton>
          <ThemedButton onClick={handleSubmit} color="success">
            登録
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}

type EditInstrumentFormDialogProps = {
  open: boolean
  instrument: Instrument
  onComplete: (instrument: Instrument) => void
  onCompleteExcludeManagement?: (instrument: Instrument) => void
  onCompleteDiscard?: () => void
  onCompleteRestoreManagement?: () => void
  onCompleteRemoveImage?: () => void
  onCancel: () => void
  rooms?: Room[]
  instrumentCategories?: InstrumentCategory[]
  cancelPolicies?: CancelPolicy[]
  managingLaboratoryIds?: number[]
  dealers?: Dealer[]
  outsideDealers?: OutsideDealer[]
  approvalSettings?: ApprovalSetting[]
}

export const EditInstrumentFormDialog = (props: EditInstrumentFormDialogProps) => {
  /**
   * propsからInstrumentFormを生成する
   * reservationTermが存在することが確定しないため、存在しなかったらblankのobjectをreservationTermにする
   */
  const propsToInstrumentForm = (): InstrumentForm => {
    const { reservationTerm, proofreadInfo, instrumentBasicInfo, proofreadAlarm } = props.instrument
    const alarms =
      props.instrument.instrumentAlarms && props.instrument.instrumentAlarms.length > 0 ? props.instrument.instrumentAlarms : [{}]

    return {
      ...props.instrument,
      imageAttributes: {},
      reservationTermAttributes: { ...reservationTerm },
      instrumentBasicInfoAttributes: { ...instrumentBasicInfo },
      instrumentAlarmsAttributes: alarms,
      proofreadInfoAttributes: { ...proofreadInfo },
      alarmEnable: !!proofreadAlarm,
      proofreadAlarmAttributes: { ...proofreadAlarm },
      attachmentsAttributes: [{}],
    }
  }

  const instrumentForm = useForm<InstrumentForm>(propsToInstrumentForm(), 'instrument')
  const api = usePatchInstrumentApi()
  const deleteApi = useDeleteInstrumentApi()
  const deleteUnmanagedInstrumentApi = useDeleteUnmanagedInstrumentApi()
  const restoreManagementInstrumentApi = useRestoreManagementUnmanagedInstrumentApi()
  const globalState = useContext(GlobalStateContext)
  const usersApi = useFetchUsersApi()
  const deleteFileApi = useDeleteAttachmentsApi('user', 'instruments')
  const [targetFileId, setTargetFileId] = useState<number>()
  const deleteImageApi = useDeleteInstrumentImageApi('user')

  const handleClickExcludeManagement = () => {
    globalState.confirm(warningExcludeManagementInstrumentDescription, (event) => {
      if (event === 'ok') {
        deleteApi.execute(props.instrument.id!)
      }
    })
  }

  const handleClickDiscard = () => {
    globalState.confirm(warningDiscardUnmanagedInstrumentDescription, (event) => {
      if (event === 'ok') {
        deleteUnmanagedInstrumentApi.execute(props.instrument.id!)
      }
    })
  }

  const handleClickRestoreManagement = () => {
    restoreManagementInstrumentApi.execute(props.instrument.id!)
  }

  const handleClickRemoveFile = (a: InstrumentAttachment) => {
    setTargetFileId(a.id)
    globalState.confirm(`添付ファイル ${a.name} を削除しますか？ この操作は取り消せません`, (event) => {
      if (event === 'ok') {
        deleteFileApi.execute(instrumentForm.object.id!, a.id!)
      }
    })
  }
  const handleClickRemoveImage = () => {
    globalState.confirm('機器画像を削除しますか？　この操作は取り消せません', (event) => {
      if (event === 'ok') {
        deleteImageApi.execute(instrumentForm.object.id!)
      }
    })
  }

  // 廃棄時
  useEffectSkipFirst(() => {
    if (deleteUnmanagedInstrumentApi.isSuccess() && props.onCompleteDiscard) {
      props.onCompleteDiscard()
    }
  }, [deleteUnmanagedInstrumentApi.loading])

  useEffectSkipFirst(() => {
    if (restoreManagementInstrumentApi.isSuccess() && props.onCompleteRestoreManagement) {
      props.onCompleteRestoreManagement()
    }
  }, [restoreManagementInstrumentApi.loading])

  useEffect(() => {
    instrumentForm.set(() => propsToInstrumentForm())
  }, [props.instrument])

  useEffectSkipFirst(() => {
    props.onComplete(api.response.instrument)
  }, [api.response.instrument])

  useEffectSkipFirst(() => {
    if (deleteApi.isSuccess() && props.onCompleteExcludeManagement) {
      props.onCompleteExcludeManagement(deleteApi.response.instrument)
    }
  }, [deleteApi.loading])

  useEffect(() => {
    usersApi.execute()
  }, [])

  useEffectSkipFirst(() => {
    globalState.setLoading(deleteFileApi.loading)
    if (deleteFileApi.isSuccess()) {
      const arr = instrumentForm.getValue(['instrumentBasicInfoAttributes', 'fileAttachments']) as FileAttachment[]
      const i = arr.findIndex((a) => a.id === targetFileId)
      arr.splice(i, 1)
      instrumentForm.newUpdateObject(['instrumentBasicInfoAttributes', 'fileAttachments'], arr)
    }
  }, [deleteFileApi.loading])

  useEffectSkipFirst(() => {
    globalState.setLoading(deleteImageApi.loading)
    if (deleteImageApi.isSuccess()) {
      instrumentForm.newUpdateObject(['instrumentBasicInfoAttributes', 'imageUrl'], '')
      props.onCompleteRemoveImage && props.onCompleteRemoveImage()
    }
  }, [deleteImageApi.loading])

  const handleSubmit = () => {
    if (!instrumentForm.object.requireProofread) {
      api.execute({ ...instrumentForm.object, proofreadInfoAttributes: {} })
    } else {
      api.execute(instrumentForm.object)
    }
  }

  return (
    <div>
      <Dialog open={props.open} onClose={props.onCancel} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">機器編集</DialogTitle>
        <DialogFormContent
          instrumentForm={instrumentForm}
          error={api.apiError}
          rooms={props.rooms}
          instrumentCategories={props.instrumentCategories}
          cancelPolicies={props.cancelPolicies}
          users={usersApi.response.users}
          dealers={props.dealers}
          outsideDealers={props.outsideDealers}
          approvalSettings={props.approvalSettings}
          onClickRemoveFile={handleClickRemoveFile}
          onClickRemoveImage={handleClickRemoveImage}
          formType="update"
        />
        <DialogActions>
          <Flex justifyContent="space-between" style={{ width: '100%' }}>
            {props.instrument.aasmState === 'managed' ? (
              <div>
                <ThemedButton color="error" variant="text" onClick={handleClickExcludeManagement}>
                  管理対象外にする
                </ThemedButton>
              </div>
            ) : (
              <Flex>
                <ThemedButton color="error" variant="text" onClick={handleClickDiscard} style={{ marginRight: 10 }}>
                  廃棄する
                </ThemedButton>
                <ThemedButton color="success" onClick={handleClickRestoreManagement}>
                  もとに戻す
                </ThemedButton>
              </Flex>
            )}
            <Flex>
              <ThemedButton onClick={props.onCancel} color="secondary">
                閉じる
              </ThemedButton>
              <ThemedButton onClick={handleSubmit} color="success">
                更新
              </ThemedButton>
            </Flex>
          </Flex>
        </DialogActions>
      </Dialog>
    </div>
  )
}
