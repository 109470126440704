import { TableSortLabel } from '@mui/material'
import { OrderSet } from 'rac'
import React from 'react'

import { ExTableCell, ExTableCellProps } from './ex_table_cell'

type OrderTableCellProps = ExTableCellProps & {
  orderAttr: string
  orderSet: OrderSet
}

export const OrderTableCell: React.FC<OrderTableCellProps> = (props: OrderTableCellProps) => {
  const { orderAttr, orderSet, ...rest } = props
  const handleClick = () => {
    orderSet.changeOrder(orderAttr)
  }

  return (
    <ExTableCell {...rest}>
      <TableSortLabel
        active={orderSet.orderBy === orderAttr}
        onClick={(e) => handleClick()}
        direction={orderSet.orderBy === orderAttr ? orderSet.order : 'asc'}
      >
        {props.children}

        <style>{`
          .MuiTableSortLabel-root .MuiTableSortLabel-icon {
            position: absolute;
            left: -25px;
          }
        `}</style>
      </TableSortLabel>
    </ExTableCell>
  )
}

export default OrderTableCell
