import { AxiosHttpClient } from 'api/axios'
import { ProofreadSchedulesResponse } from 'api/mypage/proofread_plans/proodread_schedule'
import { useRepruaIndexApi } from 'api/shared_api_hooks'
import { ProofreadScheduleSearchForm } from 'entities/proofread_schedule'
import { IndexApiSet } from 'rac'
import { useEffectSkipFirst } from 'utils/hooks'

export type PartnerFetchProofreadSchedulesApiSet = IndexApiSet<ProofreadSchedulesResponse> & { execute: () => void }

/**
 * 一覧
 */
export function usePartnerFetchProofreadSchedulesApi(planId: number): PartnerFetchProofreadSchedulesApiSet {
  const api = useRepruaIndexApi<ProofreadSchedulesResponse, ProofreadScheduleSearchForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { proofreadSchedules: [], totalCount: 0 },
  })

  const execute = () => {
    const path = `proofread_plans/${planId}/proofread_schedules`
    api.execute(path)
  }

  useEffectSkipFirst(() => {
    execute()
  }, [api.pageSet.page, api.pageSet.perPage, api.orderSet.order, api.orderSet.orderBy])

  return { ...api, execute }
}
