import { TableHead, TableRow, Table, TableContainer } from '@mui/material'
import { RepairEventsResponse } from 'api/mypage/repair_event'
import { useFetchExColumnsApi } from 'api/shared/ex_columns'
import { AttachmentsPopover } from 'components/shared/attachments_popover'
import Pagination from 'components/shared/pagination'
import StatusLabel from 'components/shared/status_label'
import { OrderTableCell, ExTableCell, ExTableBody, ExTableRow } from 'components/shared/table'
import { TableExAttributesInfo } from 'components/shared/table_ex_attributes_info'
import ThemedButton from 'components/shared/themed_button'
import { RepairEvent } from 'entities/repair_event'
import { IndexApiSet } from 'rac'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { PartnerRouteHelper } from 'routes/partner'
import { INDEX_TABLE_PADDING, getTableHeight } from 'utils/component_heights'
import { RepairEventViewModel } from 'view_models/repair_event'

type RepairEventTableProps = {
  indexApi: IndexApiSet<RepairEventsResponse> & { execute: () => void }
  showInstrument?: boolean
}

const RepairEventTable: React.FC<RepairEventTableProps> = (props: RepairEventTableProps) => {
  const history = useHistory()
  const { indexApi, showInstrument } = props
  const exColumnApi = useFetchExColumnsApi('dealer_user')

  useEffect(() => {
    exColumnApi.execute()
  }, [])

  return (
    <>
      <TableContainer style={{ maxHeight: getTableHeight(INDEX_TABLE_PADDING) }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {/* <ExTableCell width={200}>顧客</ExTableCell> */}
              <OrderTableCell width={100} align="center" orderAttr="reportedAt" orderSet={indexApi.orderSet}>
                修理期間
              </OrderTableCell>
              {showInstrument && (
                <ExTableCell width={200}>
                  <div>
                    メーカー / 型番 / 管理番号 / 製品名
                    {exColumnApi.response.exColumns
                      .filter((col) => col.visibleOnTable)
                      .map((col) => (
                        <div style={{ fontSize: 12, lineHeight: '12px' }} key={`tablecol-${col.id}`}>
                          {col.name}
                        </div>
                      ))}
                  </div>
                </ExTableCell>
              )}
              <ExTableCell width={70} align="center">
                修理結果
              </ExTableCell>
              <ExTableCell width={70} align="center">
                修理費用
              </ExTableCell>
              <ExTableCell>不具合箇所 / 不具合内容</ExTableCell>
              <ExTableCell width={140}>添付ファイル</ExTableCell>
              <ExTableCell width={100}></ExTableCell>
            </TableRow>
          </TableHead>
          <ExTableBody loading={indexApi.loading}>
            {indexApi.response.repairEvents.map((repairEvent: RepairEvent) => {
              const repairEventVM = new RepairEventViewModel(repairEvent)
              return (
                <ExTableRow key={repairEvent.id}>
                  {/* <ExTableCell>{repairEventVM.getLaboratoryName()}</ExTableCell> */}
                  <ExTableCell width={100} align="center">
                    {repairEventVM.getPeriodText()}
                  </ExTableCell>
                  {props.showInstrument && (
                    <ExTableCell width={200}>
                      <div>{repairEventVM.getInstrumentInfo()}</div>
                      <TableExAttributesInfo tableExAttrs={repairEvent.tableExAttrs || []} id={repairEvent.id} />
                    </ExTableCell>
                  )}
                  <ExTableCell width={50} align="center">
                    <StatusLabel text={repairEventVM.getResultText()} color={repairEventVM.getResultColor()} />
                  </ExTableCell>
                  <ExTableCell width={50} align="center">
                    {repairEventVM.getPriceText()}
                  </ExTableCell>
                  <ExTableCell width={200}>
                    <>
                      <div style={{ paddingBottom: 5, fontWeight: 'bold' }}>{repairEventVM.getDefectPoint()}</div>
                      <div>{repairEventVM.getDefectCause()}</div>
                    </>
                  </ExTableCell>

                  <ExTableCell width={140} align="center">
                    <AttachmentsPopover fileAttachments={repairEvent.fileAttachments} />
                  </ExTableCell>

                  <ExTableCell width={100} align="center">
                    <ThemedButton
                      color="primary"
                      size="small"
                      onClick={() => history.push(PartnerRouteHelper.repairInfoShow(repairEvent.repairInfoId!))}
                    >
                      詳細
                    </ThemedButton>
                  </ExTableCell>
                </ExTableRow>
              )
            })}
          </ExTableBody>
        </Table>
      </TableContainer>
      <Pagination response={indexApi.response} pageSet={indexApi.pageSet} />
    </>
  )
}

export default RepairEventTable
