import { Table, TableHead, TableBody, TableRow, TableCell, Checkbox } from '@mui/material'
import { usePatchUserAuthorityManagerApi } from 'api/mypage/manager/user_authority'
import { usePatchUserAuthorityApi } from 'api/mypage/user_authority'
import Modal, { ModalState } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { MypageStateContext } from 'contexts/mypage_state_context'
import { User } from 'entities/user'
import { BulkUpdateUserAuthorityForm, AuthorityTypes, authorityData } from 'entities/user_authority'
import { ApiError, Form, useForm } from 'rac'
import React, { useEffect, useContext } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'
import { UserViewModel } from 'view_models/user'

const dialogContent = (form: Form<BulkUpdateUserAuthorityForm>, error: ApiError, users: User[], currentUser: User) => {
  const handleChange = (userId: number, type: AuthorityTypes, value: boolean) => {
    const authority = form.object.userAuthorities.find((a) => a.userId === userId)
    const changedAuth = { ...authority, [type]: value }
    const updateState = form.object.userAuthorities.map((auth) => (auth.userId !== userId ? auth : changedAuth))
    form.update((f) => (f.userAuthorities = updateState))
  }

  const isChecked = (userId: number, type: AuthorityTypes) => {
    const auth = form.object.userAuthorities.find((a) => a.userId === userId)
    return !auth ? false : auth[type]
  }

  const styles = {
    th: {
      padding: '0 0 16px',
    },
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>名前</TableCell>
          <TableCell width={80} style={styles.th}>
            機器の
            <br />
            編集
          </TableCell>
          <TableCell width={80} style={styles.th}>
            ルームの
            <br />
            編集
          </TableCell>
          <TableCell width={80} style={styles.th}>
            カテゴリの
            <br />
            編集
          </TableCell>
          <TableCell width={90} style={styles.th}>
            修理管理の
            <br />
            閲覧・編集
          </TableCell>
          <TableCell width={90} style={styles.th}>
            校正履歴の
            <br />
            閲覧・編集
          </TableCell>
          <TableCell width={90} style={styles.th}>
            予約条件の
            <br />
            設定
          </TableCell>
          <TableCell width={80} style={styles.th}>
            管理者
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {users.map((user, index) => {
          const userVM = new UserViewModel(user)
          const authorityKeys = Object.keys(authorityData)
          return (
            <TableRow key={index}>
              <TableCell>{userVM.fullName()}</TableCell>
              {authorityKeys.map((el, idx) => {
                const auth = el as AuthorityTypes

                return (
                  <TableCell key={idx} style={{ padding: '8px 10px' }}>
                    <Checkbox
                      checked={isChecked(user.id!, auth)}
                      onChange={(e) => handleChange(user.id!, auth, e.target.checked)}
                      size="small"
                      disabled={currentUser.id === user.id && auth === 'privileged' ? true : false}
                    />
                  </TableCell>
                )
              })}
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

type UserAuthorityFormDialogProps = {
  users: User[]
  isManager: boolean
  modalState: ModalState
  onComplete?: () => void
}

export const UserAuthorityFormDialog: React.FC<UserAuthorityFormDialogProps> = (props: UserAuthorityFormDialogProps) => {
  const form = useForm<BulkUpdateUserAuthorityForm>({ userAuthorities: [] }, 'userAuthority')
  const authorityManagerApi = usePatchUserAuthorityManagerApi()
  const authorityApi = usePatchUserAuthorityApi()
  const { currentUser } = useContext(MypageStateContext)

  const handleSubmit = () => {
    if (props?.users && props.users[0]) {
      patchApi().execute(form.object, props.users[0].laboratoryId!)
    }
  }

  const patchApi = () => {
    return props.isManager ? authorityManagerApi : authorityApi
  }

  // formの初期化
  useEffect(() => {
    if (!props?.users) return
    const initialState = Object.assign([])
    props.users.forEach((user) => {
      const auth = user.authority!
      initialState.push({
        userId: user.id,
        instrument: auth.instrument,
        room: auth.room,
        category: auth.category,
        repair: auth.repair,
        proofread: auth.proofread,
        constraint: auth.constraint,
        privileged: auth.privileged,
      })
    })
    form.set({ userAuthorities: initialState })
  }, [props.users])

  useEffectSkipFirst(() => {
    if (patchApi().isSuccess()) {
      props.modalState.setOpen(false)
      if (props.onComplete) props.onComplete()
    }
  }, [patchApi().loading])

  return (
    <Modal
      modalId="user-authority-modal"
      title="ユーザー権限"
      modalState={props.modalState}
      size="lg"
      footer={
        <>
          <ThemedButton onClick={() => props.modalState.setOpen(false)} color="secondary">
            キャンセル
          </ThemedButton>
          <ThemedButton onClick={handleSubmit} color="success">
            保存
          </ThemedButton>
        </>
      }
    >
      {props?.users ? dialogContent(form, patchApi().apiError, props.users, currentUser) : <p>ユーザーが存在しません</p>}
    </Modal>
  )
}
