import { Paper, List, ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material'
import { useFetchUserMailNotificationSettingApi, usePatchUserMailNotificationSettingApi } from 'api/mypage/user_mail_notification_setting'
import { Flex } from 'components/shared/flex'
import { SwitchField } from 'components/shared/form/input'
import ThemedButton from 'components/shared/themed_button'
import { UserMailNotificationSettingForm, userMailNotificationSettings } from 'entities/user_mail_notification_setting'
import { isEqual } from 'lodash'
import { useForm } from 'rac'
import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'

export const MailNortificationSettingsForm = () => {
  const { classes } = useStyles()
  const fetchApiSet = useFetchUserMailNotificationSettingApi()
  const patchApiSet = usePatchUserMailNotificationSettingApi()
  const mailNotificationForm = useForm<UserMailNotificationSettingForm>({})
  const [currentMailNotificationSetting, setCurrentMailNotificationSetting] = useState<UserMailNotificationSettingForm>({})
  const handleClickUpdateSetting = () => {
    patchApiSet.execute(mailNotificationForm.object)
  }

  useEffect(() => {
    fetchApiSet.execute()
  }, [])

  useEffect(() => {
    mailNotificationForm.set(fetchApiSet.response.userMailNotificationSetting)
    setCurrentMailNotificationSetting(fetchApiSet.response.userMailNotificationSetting)
  }, [fetchApiSet.response])

  useEffect(() => {
    setCurrentMailNotificationSetting(patchApiSet.response.userMailNotificationSetting)
  }, [patchApiSet.response])

  return (
    <Paper className={classes.root}>
      <div className={classes.boxTitle}>
        <label>メール通知設定</label>
      </div>
      <List>
        {userMailNotificationSettings.map((setting, i) => {
          return (
            <ListItem key={i}>
              <ListItemText primary={setting.displayName} secondary={setting.description} />
              <ListItemSecondaryAction>
                <SwitchField form={mailNotificationForm} attr="instrumentReservation" />
              </ListItemSecondaryAction>
            </ListItem>
          )
        })}
      </List>
      <Flex justifyContent="flex-end" style={{ padding: 10 }}>
        <ThemedButton
          color="success"
          onClick={handleClickUpdateSetting}
          disabled={isEqual(mailNotificationForm.object, currentMailNotificationSetting)}
        >
          保存
        </ThemedButton>
      </Flex>
    </Paper>
  )
}

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    maxWidth: 600,
    marginBottom: 10,
  },
  boxTitle: {
    paddingTop: 20,
    marginLeft: 20,
    marginBottom: 20,
  },
}))
