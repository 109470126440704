import { Paper } from '@mui/material'
import { useFetchInstrumentsApi, usePrintInstrumentsApi } from 'api/mypage/instrument'
import { useShowPrintSettingApi, usePatchPrintSettingApi } from 'api/mypage/print_setting'
import { BarcodePrintSettingForm } from 'components/shared/BarcodePrintSettingForm'
import AccessDeniedMessage from 'components/shared/access_denied_message'
import { Flex } from 'components/shared/flex'
import { QrCode } from 'components/shared/qrcode'
import { MypageStateContext } from 'contexts/mypage_state_context'
import { Instrument, InstrumentSearchForm } from 'entities/instrument'
import {
  CodeProps,
  getPageLayoutSetting,
  getCodeDisplaySetting,
  getCodeLayoutSetting,
  getRecommendLargeLayoutSetting,
  getRecommendSmallLayoutSetting,
  getRecommendCodeDisplaySetting,
  PrintImageType,
  PrintSetting,
  PrintSettingCheckItem,
  PrintSettingForm,
} from 'entities/print_setting'
import { useForm } from 'rac'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { StaticContext } from 'react-router'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { MypageRouteHelper } from 'routes/mypage'
import { useEffectSkipFirst } from 'utils/hooks'
import { chunk, getUrlSearchParams } from 'utils/utils'

const getRecommendSmallSetting = (): PrintSetting => {
  return {
    ...getRecommendSmallLayoutSetting(),
    ...getRecommendCodeDisplaySetting(),
  }
}

const getRecommendLargeSetting = (): PrintSetting => {
  return {
    ...getRecommendLargeLayoutSetting(),
    ...getRecommendCodeDisplaySetting(),
  }
}

const checkItems: PrintSettingCheckItem[] = [
  { key: 'showName', label: '製品名' },
  { key: 'showMaker', label: 'メーカー' },
  { key: 'showModelNumber', label: '型番' },
  { key: 'showManageNumber', label: '管理番号' },
  { key: 'showSerialNumber', label: 'シリアル番号' },
  { key: 'showDealerName', label: '担当業者' },
  { key: 'showOutsideDealerContactDetail', label: '連絡先' },
  { key: 'showBaseDate', label: '最終校正月' },
]

/**
 * URLクエリから選択中の機器IDを返す
 */
const getInstrumentIds = () => {
  const params = getUrlSearchParams<{ ids: string }>()
  if (!params) return
  return (params.ids || '').split(',')
}

type BarcodePreviewProps = {}
type Props = RouteComponentProps<{}, StaticContext, BarcodePreviewProps>

export const BarcodePreview = (_props: Props) => {
  const settingForm = useForm<PrintSettingForm>({})
  const instrumentForm = useForm<InstrumentSearchForm>({ q: {}, ids: getInstrumentIds() })
  const settingShowApi = useShowPrintSettingApi()
  const settingPatchApi = usePatchPrintSettingApi(settingForm)
  const instrumentIndexApi = useFetchInstrumentsApi(instrumentForm)
  const printInstrumentApi = usePrintInstrumentsApi()
  const [instrumentGroups, setInstrumentGroups] = useState<Instrument[][]>([])
  const [currentImageType] = useState<PrintImageType>('qrcode')

  const path = MypageRouteHelper.instrumentIndex()

  const isExistPrintSetting = useMemo(() => {
    return Object.keys(settingShowApi.response.printSetting).length > 0
  }, [settingShowApi.response.printSetting])

  useEffect(() => {
    settingShowApi.execute(currentImageType)
    instrumentIndexApi.execute()
  }, [])

  useEffectSkipFirst(() => {
    if (!settingShowApi.isSuccess()) return
    setPrintSettingForm()
  }, [settingShowApi.loading])

  /**
   * DBに印刷設定データがあればDBのデータ、なければ推奨値(小)をformの値をセット
   */
  const setPrintSettingForm = () => {
    if (isExistPrintSetting) {
      settingForm.set(settingShowApi.response.printSetting)
    } else {
      settingForm.set(getRecommendSmallSetting())
    }
  }

  // 1ページごとの表示数を元に機器レスポンスの配列を二次元配列に分割
  useEffect(() => {
    if (settingForm.object.perPage && instrumentIndexApi.isSuccess()) {
      const copy: Instrument[] = Object.assign([], instrumentIndexApi.response.instruments)
      setInstrumentGroups(chunk(copy, settingForm.object.perPage))
    }
  }, [settingForm.object.perPage, instrumentIndexApi.loading])

  const handleSubmit = () => settingPatchApi.execute(currentImageType)

  const codeProps: Omit<CodeProps, 'instrument'> = {
    displaySetting: getCodeDisplaySetting(settingForm.object),
    layoutSetting: getCodeLayoutSetting(settingForm.object),
  }

  /**
   * 印刷完了後のコールバック
   */
  window.onafterprint = () => {
    const ids = getInstrumentIds()
    if (ids) {
      printInstrumentApi.execute({ ids: ids })
    }
  }

  const settingProps = {
    settingForm,
    checkItems,
    recommendSmallSetting: getRecommendSmallSetting(),
    recommendLargeSetting: getRecommendLargeSetting(),
    onSubmit: handleSubmit,
  }

  const mypageState = useContext(MypageStateContext)
  const currentUser = mypageState.currentUser
  if (currentUser.laboratory?.isManaged) {
    return <AccessDeniedMessage target="該当情報" />
  }

  return (
    <div className="print-preview-page">
      <BarcodePrintSettingForm {...settingProps} />
      {instrumentGroups.length > 0 &&
        instrumentGroups.map((group, idx) => {
          return (
            <Paper key={idx} className="print-area" style={{ width: '210mm', height: '296mm', marginBottom: 20 }}>
              <Flex childMargin={false} style={getPageLayoutSetting(settingForm.object)} flexWrap="wrap">
                {[...Array(settingForm.object.offset)].map((_, i) => {
                  return <div key={i} style={{ width: `${settingForm.object.width}mm`, ...codeProps }}></div> // オフセット
                })}
                {group.map((instrument) => (
                  <QrCode key={instrument.id} instrument={instrument} path={path} {...codeProps} />
                ))}
              </Flex>
            </Paper>
          )
        })}
    </div>
  )
}

export default withRouter(BarcodePreview)
