import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle'
import ExposureIcon from '@mui/icons-material/Exposure'
import LocalAtmIcon from '@mui/icons-material/LocalAtm'
import ShortTextIcon from '@mui/icons-material/ShortText'
import SubjectIcon from '@mui/icons-material/Subject'
import { SelectData } from 'components/shared/form/input'
import { ExColumn, inputType, InputType } from 'entities/ex_column'
import React from 'react'
import { ViewModel } from 'view_models'

export class ExColumnViewModel extends ViewModel<ExColumn> {
  public getInputTypeText = () => {
    if (this.object.inputType) {
      return getInputTypeText(this.object.inputType)
    } else {
      return null
    }
  }

  public getInputTypeIcon = () => {
    if (this.object.inputType) {
      return getInputTypeIcon(this.object.inputType)
    } else {
      return null
    }
  }
}

export const getInputTypeSelectData = (): SelectData[] => {
  const data: SelectData[] = []
  inputType.forEach((type) => {
    data.push({ value: type, label: getInputTypeText(type) })
  })

  return data
}

export const getInputTypeText = (type: InputType): string => {
  switch (type) {
    case 'string':
      return '記述式'
    case 'text':
      return '段落'
    case 'integer':
      return '数値'
    case 'dropdown':
      return 'ドロップダウン'
    case 'price':
      return '金額'
    default:
      return 'NaN'
  }
}

export const getInputTypeIcon = (type: InputType) => {
  switch (type) {
    case 'string':
      return <ShortTextIcon fontSize="small" />
    case 'text':
      return <SubjectIcon fontSize="small" />
    case 'integer':
      return <ExposureIcon fontSize="small" />
    case 'dropdown':
      return <ArrowDropDownCircleIcon fontSize="small" />
    case 'price':
      return <LocalAtmIcon fontSize="small" />
    default:
      return null
  }
}
