import { MutationErrorResponse } from 'api/MutationErrorResponse'
import { AxiosHttpClient } from 'api/axios'
import { ID } from 'entities'
import { SubscriptionPlan, SubscriptionPlanForm } from 'entities/subscription_plan'
import { BaseResponse } from 'rac'
import { useMutation, useQuery } from 'react-query'

type SubscriptionPlanResponse = BaseResponse & {
  subscriptionPlan: SubscriptionPlan
}
/**
 * 一覧
 */
export function useFetchAdminLaboratoriesSubscriptionPlanApi(laboratoryId: ID) {
  const client = new AxiosHttpClient('admin')
  const query = useQuery<undefined, undefined, SubscriptionPlanResponse>(
    'laboratories_subscription_plan',
    async () => {
      const { data } = await client.get(`laboratories/${laboratoryId}/subscription_plan`, {})
      return data
    },
    { enabled: false },
  )

  return query
}

type PostAdminLaboratoryUpdateAuthorityParams = SubscriptionPlanForm & {
  subscriptionPlanType: string
}

/**
 * 取得
 */
export function usePatchAdminLaboratoriesSubscriptionPlanApi(laboratoryId: ID) {
  const client = new AxiosHttpClient('admin')
  const mutation = useMutation<SubscriptionPlanResponse, MutationErrorResponse, PostAdminLaboratoryUpdateAuthorityParams>(
    'laboratories_update_authority',
    async (params) => {
      const { data } = await client.patch(`laboratories/${laboratoryId}/subscription_plan`, params)
      return data
    },
  )

  return mutation
}
