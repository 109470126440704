import { BaseEntity } from 'entities'
import moment, { Moment } from 'moment'

/**
 * ViewModelの基底クラス.
 */
export abstract class ViewModel<T extends BaseEntity> {
  public object: T

  constructor(object: T) {
    this.object = object
  }

  public createdAt(): Moment {
    return moment(this.object.createdAt)
  }

  public updatedAt(): Moment {
    return moment(this.object.updatedAt)
  }

  public timestampToDateText(timestamp: string | undefined): string {
    return moment(timestamp).format('Y/MM/DD')
  }

  public timestampToDatetimeText(timestamp: string | undefined): string {
    return moment(timestamp).format('Y/MM/DD HH:mm')
  }
}
