import CancelIcon from '@mui/icons-material/CancelOutlined'
import { usePostReservationConstraintSetApi, usePatchReservationConstraintSetApi } from 'api/mypage/reservation_constraint_set'
import { Flex } from 'components/shared/flex'
import { StringField, NumberField, CheckBoxField, TimeField, DayOfTheWeekInput } from 'components/shared/form/input'
import Modal, { ModalState, useModal } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { ReservationConstraintForm, ReservationConstraint, DayOfTheWeek } from 'entities/reservation_constraint'
import { ReservationConstraintSetForm, ReservationConstraintSet } from 'entities/reservation_constraint_set'
import { ReservationTimeConstraintForm, ReservationTimeConstraint, defaultTime } from 'entities/reservation_time_constraint'
import moment from 'moment'
import { ApiError, Form, useForm } from 'rac'
import React, { useEffect, useContext } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'

const dialogContent = (form: Form<ReservationConstraintSetForm>, error: ApiError, type: 'create' | 'update') => {
  const handleClickAddConstraint = () => {
    const now = moment().toString()
    form.update((f) => {
      if (f.reservationConstraintsAttributes) {
        f.reservationConstraintsAttributes.push({
          dayOfTheWeeks: [],
          reservableHoliday: true,
          reservationTimeConstraintsAttributes: [{ createdAt: now, ...defaultTime }],
        })
      } else {
        f.reservationConstraintsAttributes = [
          { dayOfTheWeeks: [], reservableHoliday: true, reservationTimeConstraintsAttributes: [{ createdAt: now, ...defaultTime }] },
        ]
      }
    })
  }

  const handleClickAddTimeConstraint = (constraintIndex: number) => {
    const now = moment().toString()
    form.update((f) => {
      if (!f.reservationConstraintsAttributes || !f.reservationConstraintsAttributes[constraintIndex]) {
        return
      }
      if (f.reservationConstraintsAttributes[constraintIndex].reservationTimeConstraintsAttributes) {
        f.reservationConstraintsAttributes[constraintIndex].reservationTimeConstraintsAttributes!.push({ createdAt: now, ...defaultTime })
      } else {
        f.reservationConstraintsAttributes[constraintIndex].reservationTimeConstraintsAttributes = [{ createdAt: now, ...defaultTime }]
      }
    })
  }

  const handleRemoveConstraint = (constraintIndex: number) => {
    form.update((f) => {
      if (!f.reservationConstraintsAttributes || !f.reservationConstraintsAttributes[constraintIndex]) {
        return
      }
      if (type === 'create') {
        f.reservationConstraintsAttributes.splice(constraintIndex, 1)
      } else {
        f.reservationConstraintsAttributes[constraintIndex]!._destroy = true
      }
    })
  }

  const handleRemoveTimeConstraint = (constraintIndex: number, timeConstraintIndex: number) => {
    form.update((f) => {
      if (!f.reservationConstraintsAttributes || !f.reservationConstraintsAttributes[constraintIndex]) {
        return
      }
      if (type === 'create') {
        f.reservationConstraintsAttributes[constraintIndex].reservationTimeConstraintsAttributes!.splice(timeConstraintIndex, 1)
      } else {
        f.reservationConstraintsAttributes[constraintIndex].reservationTimeConstraintsAttributes![timeConstraintIndex]._destroy = true
      }
    })
  }

  return (
    <>
      <StringField label="予約条件の名前" attr="name" form={form} apiError={error} required placeholder="予約条件を識別する名前" />
      <div>
        {form.object.reservationConstraintsAttributes && (
          <>
            {type === 'create' && (
              // 予約不可設定は登録時のみ可能。編集時もOKにすると既に登録している条件の削除など処理が煩雑になるため
              <div>
                <CheckBoxField label="利用不可として登録する" attr={['reservationConstraintsAttributes', 0, 'unavailable']} form={form} />
              </div>
            )}
            <div>
              {!form.object.reservationConstraintsAttributes[0].unavailable && (
                <>
                  {form.object.reservationConstraintsAttributes.map((constraint: ReservationConstraintForm, i: number) => {
                    if (constraint._destroy) {
                      return <></>
                    }
                    const timeConstraints = constraint.reservationTimeConstraintsAttributes
                    return (
                      <div style={{ padding: 20, backgroundColor: '#fafafa', margin: '10px 0' }} key={i}>
                        <Flex justifyContent="space-between">
                          <div style={{ fontWeight: 'bold', fontSize: 16 }}>予約条件{i + 1}</div>
                          {i > 0 && <CancelIcon color="action" onClick={() => handleRemoveConstraint(i)}></CancelIcon>}
                        </Flex>
                        <div style={{ marginBottom: 10 }}>
                          <DayOfTheWeekInput
                            attr={['reservationConstraintsAttributes', i, 'dayOfTheWeeksObject']}
                            form={form}
                            dayOfTheWeeks={constraint.dayOfTheWeeks}
                          />
                        </div>
                        <div style={{ marginBottom: 10 }}>
                          <CheckBoxField
                            label="祝日の予約を許可する"
                            attr={['reservationConstraintsAttributes', i, 'reservableHoliday']}
                            form={form}
                          />
                        </div>
                        {timeConstraints &&
                          timeConstraints.map((timeConstraint: ReservationTimeConstraintForm, j: number) => {
                            if (timeConstraint._destroy) {
                              return <></>
                            }
                            return (
                              <Flex style={{ marginBottom: 10 }} key={j}>
                                <TimeField
                                  label="開始時間"
                                  attr={['reservationConstraintsAttributes', i, 'reservationTimeConstraintsAttributes', j, 'startTime']}
                                  form={form}
                                  required
                                />
                                <TimeField
                                  label="終了時間"
                                  attr={['reservationConstraintsAttributes', i, 'reservationTimeConstraintsAttributes', j, 'endTime']}
                                  form={form}
                                  required
                                />
                                <NumberField
                                  label="利用価格"
                                  attr={['reservationConstraintsAttributes', i, 'reservationTimeConstraintsAttributes', j, 'price']}
                                  form={form}
                                />
                                <div style={{ minWidth: 30, marginTop: 8 }}>
                                  {j > 0 && <CancelIcon color="action" onClick={() => handleRemoveTimeConstraint(i, j)}></CancelIcon>}
                                </div>
                              </Flex>
                            )
                          })}
                        <div>
                          <ThemedButton onClick={() => handleClickAddTimeConstraint(i)} color="secondary">
                            時間条件を追加
                          </ThemedButton>
                        </div>
                      </div>
                    )
                  })}
                  <ThemedButton onClick={() => handleClickAddConstraint()} color="secondary">
                    条件を追加
                  </ThemedButton>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  )
}

type NewReservationConstraintSetFormDialogProps = {
  onComplete: () => void
}

export const NewReservationConstraintSetFormDialog: React.FC<NewReservationConstraintSetFormDialogProps> = (
  props: NewReservationConstraintSetFormDialogProps,
) => {
  const api = usePostReservationConstraintSetApi()
  const initialForm: ReservationConstraintSetForm = {
    reservationConstraintsAttributes: [
      {
        dayOfTheWeeks: [],
        reservableHoliday: true,
        unavailable: false,
        reservationTimeConstraintsAttributes: [{ createdAt: moment().toString(), ...defaultTime }],
      }, // 存在判定のため、updatedAtを設定する
    ],
  }
  const postForm = useForm<ReservationConstraintSetForm>(initialForm, 'reservationConstraintSet')
  const globalState = useContext(GlobalStateContext)
  const modalState = useModal()

  useEffectSkipFirst(() => {
    globalState.setLoading(api.loading)

    if (api.isSuccess()) {
      modalState.setOpen(false)
      postForm.resetForm()
      props.onComplete()
    }
  }, [api.loading])

  const handleSubmit = () => {
    api.execute(postForm)
  }

  return (
    <div>
      <ThemedButton color="primary" onClick={() => modalState.setOpen(true)}>
        新規登録
      </ThemedButton>
      <Modal
        modalState={modalState}
        modalId="create-constraint-set-modal"
        title="予約条件セットの登録"
        size="sm"
        footer={
          <>
            <ThemedButton onClick={() => modalState.setOpen(false)} color="secondary">
              閉じる
            </ThemedButton>
            <ThemedButton onClick={handleSubmit} color="success">
              登録
            </ThemedButton>
          </>
        }
      >
        {dialogContent(postForm, api.apiError, 'create')}
      </Modal>
    </div>
  )
}

type EditReservationConstraintSetFormDialogProps = {
  reservationConstraintSet: ReservationConstraintSet
  onComplete: () => void
  modalState: ModalState
}

export const EditReservationConstraintSetFormDialog: React.FC<EditReservationConstraintSetFormDialogProps> = (
  props: EditReservationConstraintSetFormDialogProps,
) => {
  const { reservationConstraintSet, onComplete } = props
  const api = usePatchReservationConstraintSetApi()

  const initialForm = (): ReservationConstraintSetForm => {
    if (!reservationConstraintSet.reservationConstraints) {
      return {}
    }
    const form: ReservationConstraintSetForm = { ...reservationConstraintSet }
    form.reservationConstraintsAttributes = []
    reservationConstraintSet.reservationConstraints.forEach((constraint: ReservationConstraint, i: number) => {
      const dayOfTheWeeksObject: { [key: number]: DayOfTheWeek } = {}
      constraint.dayOfTheWeeks!.forEach((dayOfTheWeek: DayOfTheWeek, index: number) => {
        dayOfTheWeeksObject[index] = dayOfTheWeek
      })
      form.reservationConstraintsAttributes![i] = {
        ...constraint,
        reservationTimeConstraintsAttributes: [],
        dayOfTheWeeksObject: dayOfTheWeeksObject,
        updatedAt: moment().toString(),
      } // dayOfWeeksだけの更新だとbeforeSaveが動かないため、updatedAtをこちらで更新する

      constraint.reservationTimeConstraints!.forEach((timeConstraint: ReservationTimeConstraint, j: number) => {
        form.reservationConstraintsAttributes![i].reservationTimeConstraintsAttributes![j] = { ...timeConstraint }
      })
    })
    return form
  }

  const patchForm = useForm<ReservationConstraintSetForm>(initialForm(), 'reservationConstraintSet')
  const globalState = useContext(GlobalStateContext)

  useEffect(() => {
    if (props.modalState.open) {
      patchForm.resetForm()
    }
  }, [props.modalState.open])

  useEffectSkipFirst(() => {
    globalState.setLoading(api.loading)
    if (api.isSuccess()) {
      onComplete()
    }
  }, [api.loading])

  const handleSubmit = () => {
    api.execute(patchForm.object)
  }

  return (
    <Modal
      title="予約条件セットの編集"
      modalState={props.modalState}
      modalId="edit-constraint-set-modal"
      size="sm"
      footer={
        <>
          <ThemedButton onClick={() => props.modalState.setOpen(false)} color="secondary">
            閉じる
          </ThemedButton>
          <ThemedButton onClick={handleSubmit} color="success">
            更新
          </ThemedButton>
        </>
      }
    >
      {dialogContent(patchForm, api.apiError, 'update')}
    </Modal>
  )
}
