import { useRepruaPostApi } from 'api/shared_api_hooks'
import { ID } from 'entities'
import { ApiSet, BaseResponse } from 'rac'

import { AxiosHttpClient } from '../../axios'

type UserAgentsResponse = BaseResponse & {
  accessToken: string
}

/**
 * 代行ログイン
 */
export function useAdminUserAgentsApi(): ApiSet<UserAgentsResponse> & { execute: (id: ID) => void } {
  const api = useRepruaPostApi<UserAgentsResponse, { email: string; password: string }>(new AxiosHttpClient('admin'), {
    initialResponse: { accessToken: '' },
  })

  const execute = (id: ID) => {
    const path = `users/${id}/agents`
    api.execute(path)
  }

  return { ...api, execute }
}

export function useAdminDealerUserAgentsApi(): ApiSet<UserAgentsResponse> & { execute: (id: ID) => void } {
  const api = useRepruaPostApi<UserAgentsResponse, { email: string; password: string }>(new AxiosHttpClient('admin'), {
    initialResponse: { accessToken: '' },
  })

  const execute = (id: ID) => {
    const path = `dealer_users/${id}/agents`
    api.execute(path)
  }

  return { ...api, execute }
}
