import { RepairEvent } from 'entities/repair_event'
import moment, { Moment } from 'moment'
import { ViewModel } from 'view_models'
import { InstrumentViewModel } from 'view_models/instrument'

export class RepairEventViewModel extends ViewModel<RepairEvent> {
  public isYet(): boolean {
    return this.object.aasmState === 'yet'
  }
  public isOk(): boolean {
    return this.object.aasmState === 'repaired'
  }
  public isNg(): boolean {
    return this.object.aasmState === 'unrepairable'
  }

  public getResultText(): string {
    if (this.isYet()) {
      return '未修理'
    } else if (this.isOk()) {
      return '修理済'
    } else if (this.isNg()) {
      return '修理不可'
    } else {
      return ''
    }
  }

  public getResultColor(): string {
    if (this.isYet()) {
      return '#808080'
    } else if (this.isOk()) {
      return '#4CAF50'
    } else if (this.isNg()) {
      return '#F44336'
    } else {
      return ''
    }
  }

  /**
   * 記録者
   */
  public getRecorderName(): string {
    return this.object.recorderName || '-'
  }

  /**
   * 開始日時
   */
  public startAt(): Moment {
    return moment(this.object.startAt)
  }

  /**
   * 終了日時
   */
  public endAt(): Moment {
    return moment(this.object.endAt)
  }

  public getInstrumentName(): string {
    return this.object.instrument?.instrumentBasicInfo?.name || '-'
  }

  public getStartAtText(): string {
    return this.startAt().format('Y/MM/DD')
  }

  public getEndAtText(): string {
    return this.endAt().format('Y/MM/DD')
  }

  public getPeriodText(): string {
    if (this.object.startAt && this.object.endAt) {
      return `${this.getStartAtText()} 〜 ${this.getEndAtText()}`
    } else {
      return '未指定'
    }
  }

  public getPriceText(): string {
    if (this.object.price) {
      return `¥${this.object.price.toLocaleString()}`
    } else {
      return '-'
    }
  }

  public getLaboratoryName(): string {
    const laboratory = this.object.laboratory
    if (laboratory && laboratory.name) {
      return laboratory.name
    } else {
      return '-'
    }
  }

  public getDefectPoint(): string {
    const defectPoint = this.object.defectReport?.point
    return defectPoint || '-'
  }

  public getDefectCause(): string {
    const defectCause = this.object.defectReport?.content
    return defectCause || '-'
  }

  public getDetail(): string {
    return this.object.detail || '-'
  }

  public getNoteToSupplier(): string {
    return this.object.noteToSupplier || '-'
  }

  public getSupplierName(): string {
    return this.object.supplierName || '-'
  }

  public getInstrumentInfo = (linkDisabled = false) => {
    if (this.object.instrument) {
      const vm = new InstrumentViewModel(this.object.instrument)
      return vm.getInstrumentBaseInfoIncludeMaker(linkDisabled)
    } else {
      '-'
    }
  }
}
