import { Checkbox, FormControlLabel } from '@mui/material'
import { SharedAssetForm } from 'components/mypage/laboratory/shared_assets_form_modal'
import { ID } from 'entities'
import { Instrument } from 'entities/instrument'
import { Form } from 'rac'
import React from 'react'

type SharedInstrumentsFormProps = {
  instruments: Instrument[]
  form: Form<SharedAssetForm>
  roomId: ID
  index: number
}

export const SharedInstrumentsForm: React.FC<SharedInstrumentsFormProps> = (props: SharedInstrumentsFormProps) => {
  const handleClickCheckbox = (instrument: Instrument) => {
    props.form.update((f) => {
      const asset = f.selectedAssets.find((asset) => asset.roomId === props.roomId)
      const instrumentIds = asset?.instrumentIds
      if (asset) {
        if (instrumentIds?.includes(instrument.id!)) {
          // チェックを外す
          const index = instrumentIds?.indexOf(instrument.id!)
          asset.instrumentIds?.splice(index, 1)
        } else {
          asset.instrumentIds?.push(instrument.id!)
        }
      }
    })
  }

  return (
    <>
      {props.instruments.map((instrument: Instrument) => {
        const selectedInstrumentIds = props.form.object.selectedAssets.find((selectedAsset) => selectedAsset.roomId === props.roomId)
          ?.instrumentIds
        return (
          <FormControlLabel
            key={instrument.id}
            control={
              <Checkbox
                checked={selectedInstrumentIds && selectedInstrumentIds.length > 0 ? selectedInstrumentIds.includes(instrument.id!) : false}
                onChange={(e) => {
                  handleClickCheckbox(instrument)
                }}
              />
            }
            label={instrument.instrumentBasicInfo?.name}
          />
        )
      })}
    </>
  )
}
