import { AxiosHttpClient } from 'api/axios'
import { useRepruaDeleteApi, useRepruaIndexApi, useRepruaPatchApi, useRepruaPostApi } from 'api/shared_api_hooks'
import { PagingResponse } from 'entities'
import { DealerDivision, DealerDivisionForm, DealerDivisionSearchForm } from 'entities/dealer_division'
import { ApiSet, BaseResponse, Form, IndexApiSet } from 'rac'


type DealerDivisionsResponse = PagingResponse & {
  dealerDivisions: DealerDivision[]
}
/**
 * 一覧
 */
export const usePartnerFetchDealerDivisionsApi = (fetchAll = true): IndexApiSet<DealerDivisionsResponse> & { execute: () => void } => {
  const api = useRepruaIndexApi<DealerDivisionsResponse, DealerDivisionSearchForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { dealerDivisions: [], totalCount: 0 },
  })

  const execute = () => {
    const path = `dealer_divisions?fetch_all=${fetchAll}`
    api.execute(path)
  }

  return { ...api, execute }
}

type DealerDivisionResponse = BaseResponse & {
  dealerDivision: DealerDivision
}

/**
 * 作成
 */
export const usePartnerPostDealerDivisionApi = (): ApiSet<DealerDivisionResponse> & {
  execute: (form: Form<DealerDivisionForm>) => void
} => {
  const api = useRepruaPostApi<DealerDivisionResponse, DealerDivisionForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { dealerDivision: {} },
  })

  const execute = (form: Form<DealerDivisionForm>) => {
    const path = 'dealer_divisions'
    api.execute(path, form)
  }

  return { ...api, execute }
}

/**
 * 更新
 */
export const usePartnerPatchDealerDivisionApi = (
  form: Form<DealerDivisionForm>,
): ApiSet<DealerDivisionResponse> & {
  execute: (dealerDivisionId: number) => void
} => {
  const api = useRepruaPatchApi<DealerDivisionResponse, DealerDivisionForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { dealerDivision: {} },
  })

  const execute = (dealerDivisionId: number) => {
    const path = `dealer_divisions/${dealerDivisionId}`
    api.execute(path, form.object)
  }

  return { ...api, execute }
}

/**
 * 削除
 */
export const usePartnerDeleteDealerDivisionRelationApi = (): ApiSet<BaseResponse> & {
  execute: (dealerUserId: number) => void
} => {
  const api = useRepruaDeleteApi<BaseResponse>(new AxiosHttpClient('dealer_user'), { initialResponse: {} })

  const execute = (dealerDivisionId: number) => {
    const path = `dealer_divisions/${dealerDivisionId}`
    api.execute(path)
  }

  return { ...api, execute }
}
