import { Table, TableHead, TableRow, TableContainer } from '@mui/material'
import { FetchProofreadEventsApi } from 'api/mypage/proofread_plans/proofread_event'
import BulkApproveFormDialog from 'components/mypage/proofread/proofread_event/bulk_approval_form_dialog'
import PlannedEventFormDialog from 'components/mypage/proofread/proofread_event/planned_event_form_dialog'
import { CheckboxFooter } from 'components/shared/checkbox_footer'
import ImageView from 'components/shared/image_view'
import { useMultipleModal } from 'components/shared/modal'
import Pagination from 'components/shared/pagination'
import { InstrumentCategoryFilterTableHeader } from 'components/shared/proofread/instrument_category_filter_table_header'
import { InstrumentInfoFilterTableHeader } from 'components/shared/proofread/instrument_info_filter_table_header'
import { ExTableCell, ExTableBody, ExTableRow, AllCheckboxTableCell, CheckboxTableCell } from 'components/shared/table'
import { TableExAttributesInfo } from 'components/shared/table_ex_attributes_info'
import ThemedButton from 'components/shared/themed_button'
import { MypageStateContext } from 'contexts/mypage_state_context'
import { ExColumn } from 'entities/ex_column'
import { ProofreadEvent, ProofreadEventSearchForm } from 'entities/proofread_event'
import { Form } from 'rac'
import React, { useContext } from 'react'
import { getTableHeight, PROOFREAD_CHECKED_TABLE_PADDING, PROOFREAD_TABLE_PADDING } from 'utils/component_heights'
import { useCheckboxes } from 'utils/hooks'
import { useProofreadEventFilter } from 'utils/hooks/use_proofread_event_filter'
import { ProofreadEventViewModel } from 'view_models/proofread_event'

type PlannedEventTableProps = {
  indexApi: FetchProofreadEventsApi
  searchForm: Form<ProofreadEventSearchForm>
  tableShowColumns: ExColumn[]
  onUpdate: () => void
  onClickHistory: (event: ProofreadEvent) => void
  onClickExAttr: (event: ProofreadEvent) => void
  onClickBulkExAttr: (eventIds: number[]) => void
}
export const PlannedEventTable: React.FC<PlannedEventTableProps> = (props) => {
  const proofreadEventFilterSet = useProofreadEventFilter(props.indexApi.response.filter, props.searchForm, props.indexApi)
  const mypageState = useContext(MypageStateContext)
  const approvalModalSet = useMultipleModal<ProofreadEvent>()
  const bulkApprovalModalSet = useMultipleModal<number[]>()
  const checkboxSet = useCheckboxes(props.indexApi.response.proofreadEvents)

  const handleClickApproval = (event: ProofreadEvent) => {
    approvalModalSet.openModal(event)
  }

  const handleCompleteApproval = () => {
    approvalModalSet.closeModal()
    bulkApprovalModalSet.closeModal()
    props.onUpdate()
    mypageState.reloadUser()
  }

  const handleClickBulkApprove = (eventIds: number[]) => {
    bulkApprovalModalSet.openModal(eventIds)
  }

  return (
    <>
      <PlannedEventFormDialog modalSet={approvalModalSet} onComplete={handleCompleteApproval} onClickHistory={props.onClickHistory} />
      <BulkApproveFormDialog modalSet={bulkApprovalModalSet} onComplete={handleCompleteApproval} kind="plan" />
      <TableContainer
        style={{
          maxHeight:
            checkboxSet.checkedIds.length > 0 ? getTableHeight(PROOFREAD_CHECKED_TABLE_PADDING) : getTableHeight(PROOFREAD_TABLE_PADDING),
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <AllCheckboxTableCell checkboxSet={checkboxSet} />
              <ExTableCell />
              <ExTableCell>
                <InstrumentInfoFilterTableHeader proofreadEventFilterSet={proofreadEventFilterSet} />
                {props.tableShowColumns.map((col) => (
                  <div style={{ fontSize: 12, lineHeight: '12px' }} key={`tablecol-${col.id}`}>
                    {col.name}
                  </div>
                ))}
              </ExTableCell>
              <ExTableCell align="center">
                <InstrumentCategoryFilterTableHeader proofreadEventFilterSet={proofreadEventFilterSet} />
              </ExTableCell>
              <ExTableCell align="center">委託先</ExTableCell>
              <ExTableCell align="center">担当者</ExTableCell>
              <ExTableCell align="center">部屋</ExTableCell>
              <ExTableCell align="center">校正月</ExTableCell>
              <ExTableCell align="center">校正価格</ExTableCell>
              <ExTableCell />
              <ExTableCell />
            </TableRow>
          </TableHead>
          <ExTableBody loading={props.indexApi.loading}>
            {props.indexApi.response.proofreadEvents.map((event: ProofreadEvent) => {
              const vm = new ProofreadEventViewModel(event)
              return (
                <ExTableRow key={event.id}>
                  <CheckboxTableCell checkboxId={event.id!} checkboxesSet={checkboxSet} />
                  <ExTableCell width={100}>
                    <ImageView maxHeight={100} maxWidth={100} src={vm.getInstrumentImage()} />
                  </ExTableCell>
                  <ExTableCell>
                    {vm.getInstrumentInfo()}
                    <TableExAttributesInfo tableExAttrs={event.tableExAttrs || []} id={event.id} />
                  </ExTableCell>
                  <ExTableCell align="center">{vm.getInstrumentCategoryName()}</ExTableCell>
                  <ExTableCell align="center">{vm.getSupplier()}</ExTableCell>
                  <ExTableCell align="center">{vm.getUser()}</ExTableCell>
                  <ExTableCell align="center">{vm.getRoom()}</ExTableCell>
                  <ExTableCell align="center">{vm.getScheduledDate()}</ExTableCell>
                  <ExTableCell align="center">{vm.getPrice()}</ExTableCell>
                  <ExTableCell width={122}>
                    <ThemedButton color="success" onClick={() => props.onClickExAttr(event)}>
                      管理情報
                    </ThemedButton>
                  </ExTableCell>
                  <ExTableCell>
                    {event.approvers?.some(
                      (a) => a.approverId === mypageState.currentUser.id && a.kind === 'plan' && a.aasmState === 'yet',
                    ) ? (
                      <ThemedButton color="primary" onClick={() => handleClickApproval(event)}>
                        承認
                      </ThemedButton>
                    ) : (
                      <ThemedButton color="secondary" onClick={() => handleClickApproval(event)}>
                        確認
                      </ThemedButton>
                    )}
                  </ExTableCell>
                </ExTableRow>
              )
            })}
          </ExTableBody>
        </Table>
      </TableContainer>
      <Pagination response={props.indexApi.response} pageSet={props.indexApi.pageSet} />
      <CheckboxFooter
        checkboxSet={checkboxSet}
        content={
          <>
            <ThemedButton color="success" onClick={() => props.onClickBulkExAttr(checkboxSet.checkedIds)} style={{ marginRight: 12 }}>
              管理情報を一括更新
            </ThemedButton>
            <ThemedButton color="primary" onClick={() => handleClickBulkApprove(checkboxSet.checkedIds)} style={{ marginRight: 12 }}>
              一括承認
            </ThemedButton>
          </>
        }
      />
    </>
  )
}

export default PlannedEventTable
