import { Table, TableContainer, TableHead, TableRow } from '@mui/material'
import { RepairEstimatesResponse } from 'api/mypage/instruments/repair_estimate'
import Pagination from 'components/shared/pagination'
import { OrderTableCell, ExTableCell, ExTableBody, ExTableRow } from 'components/shared/table'
import ThemedButton from 'components/shared/themed_button'
import { RepairEstimate } from 'entities/repair_estimate'
import * as H from 'history'
import { IndexApiSet } from 'rac'
import React from 'react'
import { PartnerRouteHelper } from 'routes/partner'
import { getTableHeight, INDEX_TABLE_PADDING } from 'utils/component_heights'
import { RepairEstimateViewModel } from 'view_models/repair_estimate'

type RepairEstimateTableProps = {
  indexApi: IndexApiSet<RepairEstimatesResponse> & { execute: () => void }
  history: H.History
}

const RepairEstimateTable: React.FC<RepairEstimateTableProps> = (props: RepairEstimateTableProps) => {
  const { indexApi } = props

  return (
    <>
      <TableContainer style={{ maxHeight: getTableHeight(INDEX_TABLE_PADDING) }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <OrderTableCell align="center" width={100} orderAttr="reportedAt" orderSet={indexApi.orderSet}>
                見積依頼日
              </OrderTableCell>
              <ExTableCell align="center" width={200}>
                機器名称
              </ExTableCell>
              <ExTableCell align="center" width={180}>
                顧客
              </ExTableCell>
              <ExTableCell align="center" width={120}>
                ステータス
              </ExTableCell>
              <ExTableCell align="center" width={120}>
                金額
              </ExTableCell>
              <ExTableCell align="center" width={120}>
                修理期間
              </ExTableCell>
              <ExTableCell align="center" width={130}>
                見積No.
              </ExTableCell>
              <ExTableCell width={100} />
            </TableRow>
          </TableHead>
          <ExTableBody loading={indexApi.loading}>
            {indexApi.response.repairEstimates.map((repairEstimate: RepairEstimate) => {
              const vm = new RepairEstimateViewModel(repairEstimate)
              return (
                <ExTableRow key={repairEstimate.id}>
                  <ExTableCell align="center">{vm.getRequestDate()}</ExTableCell>
                  <ExTableCell>{vm.getInstrumentName()}</ExTableCell>
                  <ExTableCell>{vm.getLaboratoryName()}</ExTableCell>
                  <ExTableCell align="center">{vm.getStatusLabel()}</ExTableCell>
                  {repairEstimate.aasmState === 'estimating' ? (
                    <ExTableCell colSpan={2} align="center">
                      見積中
                    </ExTableCell>
                  ) : (
                    <>
                      <ExTableCell align="center">{vm.getPriceText()}</ExTableCell>
                      <ExTableCell align="center">{vm.getDeliveryTerm()}</ExTableCell>
                    </>
                  )}
                  <ExTableCell align="center">{vm.getEstimateNumber()}</ExTableCell>
                  <ExTableCell align="center">
                    <ThemedButton
                      color="primary"
                      onClick={() => props.history.push(PartnerRouteHelper.repairEstimateShow(repairEstimate.id!))}
                    >
                      詳細
                    </ThemedButton>
                  </ExTableCell>
                </ExTableRow>
              )
            })}
          </ExTableBody>
        </Table>
      </TableContainer>
      <Pagination response={indexApi.response} pageSet={indexApi.pageSet} />
    </>
  )
}

export default RepairEstimateTable
