import { useRepruaIndexApi } from 'api/shared_api_hooks'
import { PagingResponse } from 'entities'
import { DealerDivisionDealerUserRelation } from 'entities/dealer_division_dealer_user_relation'
import { IndexApiSet } from 'rac'

import { AxiosHttpClient } from '../axios'

type DealerDivisionDealerUserRelationsResponse = PagingResponse & {
  dealerDivisionDealerUserRelations: DealerDivisionDealerUserRelation[]
}
/**
 * 一覧
 */
export const usePartnerFetchDealerDivisionDealerUserRelationsApi = (): IndexApiSet<DealerDivisionDealerUserRelationsResponse> & {
  execute: (dealerUserId: number) => void
} => {
  const api = useRepruaIndexApi<DealerDivisionDealerUserRelationsResponse, {}>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { dealerDivisionDealerUserRelations: [], totalCount: 0 },
  })

  const execute = (dealerUserId: number) => {
    const path = `dealer_users/${dealerUserId}/dealer_division_dealer_user_relations`
    api.execute(path)
  }

  return { ...api, execute }
}
