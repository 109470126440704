import { Stack } from '@mui/material'
import { usePostInquiryCommentApi } from 'api/mypage/inquiries/inquiry_comment'
import { FileInput, StringField } from 'components/shared/form/input'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { InquiryComment, InquiryCommentForm, maxFileSize } from 'entities/inquiry_comment'
import { useForm } from 'rac'
import React, { useContext } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'

type InquiryCommentFormViewProps = {
  inquiryId: number
  onComplete: (comment: InquiryComment) => void
}

const initialForm = {
  body: '',
  attachmentAttributes: {},
}

export const InquiryCommentFormView: React.FC<InquiryCommentFormViewProps> = (props: InquiryCommentFormViewProps) => {
  const form = useForm<InquiryCommentForm>(initialForm, 'InquiryComment')
  const apiSet = usePostInquiryCommentApi()
  const globalState = useContext(GlobalStateContext)

  const handleSubmit = () => {
    const attachment = form.object.attachmentAttributes?.attachment
    const body = form.object.body

    if (attachment && attachment?.size > maxFileSize) {
      globalState.setNotificationMessage({ body: '添付ファイルのサイズが大きすぎます（5MB以下にしてください）', colorType: 'error' })
      return
    }

    if (body || attachment) {
      apiSet.execute(props.inquiryId, form)
    } else {
      globalState.setNotificationMessage({ body: '本文入力もしくはファイルを添付してください', colorType: 'error' })
    }
  }

  useEffectSkipFirst(() => {
    globalState.setLoading(apiSet.loading)
    if (!apiSet.isSuccess()) return

    props.onComplete({ ...apiSet.response.comment })
    form.update((f) => {
      f.body = ''
      f.attachmentAttributes = {}
    })
  }, [apiSet.loading])

  return (
    <Stack gap={1}>
      <StringField form={form} attr="body" placeholder="問合せ内容を入力" multiline />
      <FileInput index={0} form={form} attr={['attachmentAttributes', 'attachment']} />
      <ThemedButton color="primary" onClick={handleSubmit} sx={{ width: 120, alignSelf: 'end' }}>
        送信
      </ThemedButton>
    </Stack>
  )
}
