import { SlideFooter } from 'components/shared/slide_footer'
import ThemedButton from 'components/shared/themed_button'
import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { CheckboxesSet } from 'utils/hooks'

type CheckboxFooterProps = {
  content: JSX.Element
  checkboxSet: CheckboxesSet
}

export const CheckboxFooter = (props: CheckboxFooterProps) => {
  const { classes } = useStyle()

  const handleClickDeselectAll = () => {
    props.checkboxSet.clear()
    if (props.checkboxSet.allChecked) {
      props.checkboxSet.changeAllChecked()
    }
  }
  return (
    <SlideFooter show={props.checkboxSet.checkedIds.length > 0}>
      {props.content}
      <div className={classes.countWrapper}>
        <span className={classes.count}>{props.checkboxSet.checkedIds.length}</span>件選択中
      </div>
      <ThemedButton color="secondary" onClick={handleClickDeselectAll}>
        選択を解除
      </ThemedButton>
    </SlideFooter>
  )
}

const useStyle = makeStyles()(() => ({
  countWrapper: {
    paddingLeft: 16,
    marginRight: 16,
    paddingTop: 6,
    borderLeft: '1px solid #808080',
  },
  count: {
    marginRight: 8,
    fontWeight: 'bold',
    fontSize: 16,
  },
}))
