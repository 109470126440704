import { Grid, List, ListItem, ListItemText, Paper } from '@mui/material'
import { DealerDivisionListBox } from 'components/partner/setting/dealer_division_list_box'
import { DealerUserListBox } from 'components/partner/setting/dealer_user_list_box'
import { InstrumentCategoryListBox } from 'components/partner/setting/instrument_category_list_box'
import { OutsideLaboratoryListBox } from 'components/partner/setting/outside_laboratory_list_box'
import { PersonalSettingsBox } from 'components/partner/setting/personal_settings_box'
import { ContentHeader, ContentTitle } from 'components/shared/content'
import React, { useState } from 'react'
import { StaticContext } from 'react-router'
import { withRouter, RouteComponentProps, useLocation } from 'react-router-dom'

export type SettingShowProps = {}

type Props = RouteComponentProps<{}, StaticContext, SettingShowProps>

type SettingItem = {
  component: JSX.Element
}

type SettingItemKeys = 'userAuthorities' | 'equipmentCategories' | 'outsideLaboratories' | 'dealerDivisions' | 'personalSettings'

type SettingItems = {
  [key in SettingItemKeys]: SettingItem
}
type LocationState = {
  settingItemKey?: SettingItemKeys
}

export const SettingShow: React.FC<Props> = (_: Props) => {
  const location = useLocation<LocationState>()
  const [currentSetting, setCurrentSetting] = useState<SettingItemKeys>(
    location.state?.settingItemKey ? location.state.settingItemKey : 'userAuthorities',
  )

  const settingItems: SettingItems = {
    userAuthorities: {
      component: <DealerUserListBox />,
    },
    equipmentCategories: {
      component: <InstrumentCategoryListBox />,
    },
    outsideLaboratories: {
      component: <OutsideLaboratoryListBox />,
    },
    dealerDivisions: {
      component: <DealerDivisionListBox />,
    },
    personalSettings: {
      component: <PersonalSettingsBox />,
    },
  }

  return (
    <>
      <ContentHeader>
        <ContentTitle title="設定" />
      </ContentHeader>
      <Grid container spacing={3} style={{ padding: '20px 10px' }}>
        {/* ユーザ */}
        <Grid item xs={9}>
          {settingItems[currentSetting].component}
        </Grid>
        <Grid item xs={3}>
          <Paper style={{ marginTop: 56 }}>
            <List component="nav" aria-label="main mailbox folders">
              <ListItem button onClick={() => setCurrentSetting('userAuthorities')} selected={currentSetting === 'userAuthorities'}>
                <ListItemText primary="ユーザー権限設定" />
              </ListItem>
              <ListItem button onClick={() => setCurrentSetting('equipmentCategories')} selected={currentSetting === 'equipmentCategories'}>
                <ListItemText primary="機器カテゴリ設定" />
              </ListItem>
              <ListItem button onClick={() => setCurrentSetting('outsideLaboratories')} selected={currentSetting === 'outsideLaboratories'}>
                <ListItemText primary="外部顧客マスタ設定" />
              </ListItem>
              <ListItem button onClick={() => setCurrentSetting('dealerDivisions')} selected={currentSetting === 'dealerDivisions'}>
                <ListItemText primary="部署設定" />
              </ListItem>
              <ListItem button onClick={() => setCurrentSetting('personalSettings')} selected={currentSetting === 'personalSettings'}>
                <ListItemText primary="個人設定" />
              </ListItem>
            </List>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default withRouter(SettingShow)
