import { Paper, TableContainer, TableHead, Box, Table, TableRow, TableCell } from '@mui/material'
import { useFetchCancelPoliciesApi } from 'api/mypage/cancel_policy'
import { Flex } from 'components/shared/flex'
import { ExTableBody } from 'components/shared/table/table_body/ex_table_body'
import { ExTableCell } from 'components/shared/table/table_cell/ex_table_cell'
import { GlobalStateContext } from 'contexts/global_state_context'
import { MypageStateContext } from 'contexts/mypage_state_context'
import { CancelPolicy } from 'entities/cancel_policy'
import React, { useEffect, useContext } from 'react'

import { DeleteCancelPolicyFormDialog, EditCancelPolicyFormDialog, NewCancelPolicyFormDialog } from './cancel_policy_form_dialog'

type CancelPolicyListBoxProps = {}

export const CancelPolicyListBox: React.FC<CancelPolicyListBoxProps> = (props: CancelPolicyListBoxProps) => {
  const api = useFetchCancelPoliciesApi()
  const globalState = useContext(GlobalStateContext)
  const { currentUser } = useContext(MypageStateContext)

  useEffect(() => {
    api.execute()
  }, [])

  const handleAfterCommit = () => {
    api.execute()
  }

  useEffect(() => {
    globalState.setLoading(api.loading)
  }, [api.loading])

  return (
    <Box>
      <Box display={'flex'} alignItems={'center'} marginBottom={1}>
        <h3 style={{ marginRight: '10px' }}>キャンセルポリシー</h3>
        {currentUser.authority?.constraint && <NewCancelPolicyFormDialog onComplete={handleAfterCommit} />}
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>キャンセル期限</TableCell>
              <ExTableCell width={150} />
            </TableRow>
          </TableHead>

          <ExTableBody loading={api.loading}>
            {api.response.cancelPolicies &&
              api.response.cancelPolicies.map((cancelPolicy: CancelPolicy, idx: number) => {
                return (
                  <TableRow key={idx}>
                    {cancelPolicy.deadline >= 24 ? (
                      <TableCell>{cancelPolicy.deadline / 24} 日前までキャンセル可能</TableCell>
                    ) : (
                      <TableCell>{cancelPolicy.deadline} 時間前までキャンセル可能</TableCell>
                    )}
                    <ExTableCell>
                      {currentUser.authority?.constraint && (
                        <Flex>
                          <EditCancelPolicyFormDialog onComplete={handleAfterCommit} cancelPolicy={cancelPolicy} />
                          <DeleteCancelPolicyFormDialog onComplete={handleAfterCommit} cancelPolicy={cancelPolicy} />
                        </Flex>
                      )}
                    </ExTableCell>
                  </TableRow>
                )
              })}
          </ExTableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
