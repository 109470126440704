import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { ManagementLaboratoryForm, usePostAdminManagedLaboratoryApi } from 'api/admin/laboratories/managed_laboratory'
import { useFetchAdminLaboratoriesApi } from 'api/admin/laboratory'
import { Flex } from 'components/shared/flex'
import { SelectField, SelectData } from 'components/shared/form/input'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { Laboratory } from 'entities/laboratory'
import { Form, useForm } from 'rac'
import React, { useState, useEffect, useContext } from 'react'

type DialogFormContentProps = {
  laboratories: Laboratory[]
  form: Form<ManagementLaboratoryForm>
}

const DialogFormContent: React.FC<DialogFormContentProps> = (props) => {
  const createSelectLaboratories = (): SelectData[] => {
    return props.laboratories.map((lab) => {
      return { value: lab.id!, label: lab.name! }
    })
  }

  return (
    <DialogContent>
      <Flex>
        <SelectField
          label="管理されるラボ"
          labelId="managedLaboratoryId"
          form={props.form}
          attr="managedLaboratoryId"
          data={createSelectLaboratories()}
          includeBlank
        />
      </Flex>
    </DialogContent>
  )
}

type ManagementLaboratoryRelationFormDialogProps = {
  onComplete: () => void
  managerLaboratoryId: number
}

export const ManagementLaboratoryRelationFormDialog: React.FC<ManagementLaboratoryRelationFormDialogProps> = (
  props: ManagementLaboratoryRelationFormDialogProps,
) => {
  const [open, setOpen] = useState(false)
  const api = usePostAdminManagedLaboratoryApi()
  const globalState = useContext(GlobalStateContext)
  const form = useForm<ManagementLaboratoryForm>({})
  const laboratoriesApi = useFetchAdminLaboratoriesApi()

  useEffect(() => {
    laboratoriesApi.execute()
  }, [])

  useEffect(() => {
    globalState.setLoading(api.loading)
    if (api.isSuccess()) {
      props.onComplete()
      form.set({})
    }
  }, [api.loading])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = () => {
    api.execute(props.managerLaboratoryId, form)
  }

  return (
    <div>
      <>
        <ThemedButton color="primary" onClick={handleClickOpen}>
          関連の追加
        </ThemedButton>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="xl">
          <DialogTitle id="form-dialog-title">関連の追加</DialogTitle>
          <DialogFormContent laboratories={laboratoriesApi.response.laboratories} form={form} />
          <DialogActions>
            <ThemedButton onClick={handleClose} color="secondary">
              閉じる
            </ThemedButton>
            <ThemedButton onClick={handleSubmit} color="success">
              登録
            </ThemedButton>
          </DialogActions>
        </Dialog>
      </>
    </div>
  )
}
