import { AxiosHttpClient } from 'api/axios'
import { FetchUserReservationsParams, ReservationsResponse } from 'entities/reservation'
import { QueryFunction, QueryFunctionContext, QueryKey, useQuery } from 'react-query'

/**
 * マイカレンダー > 自分のみ > reservations一覧の取得
 */
export const useFetchUserReservationsQuery = (initPageParam: FetchUserReservationsParams) => {
  const client = new AxiosHttpClient('user')
  const getStockItems: QueryFunction<FetchUserReservationsParams> = async ({
    pageParam = initPageParam,
  }: QueryFunctionContext<QueryKey, FetchUserReservationsParams>) => {
    const { data } = await client.get('users/reservations', {
      ...pageParam,
    })
    return data
  }

  const query = useQuery<FetchUserReservationsParams, undefined, ReservationsResponse>(
    `use_search_items_user_room_${initPageParam.roomId}_instrument_category_${initPageParam.instrumentCategoryId}_query`,
    getStockItems,
    {
      enabled: false,
    },
  )

  return query
}
