import { EventApi, EventInput, ViewApi } from '@fullcalendar/core'
import { Paper } from '@mui/material'
import { useFetchAssetReservationsApi, useFetchLaboratoriesApi, useFetchXlsxAssetReservationsApi } from 'api/mypage/laboratory'
import { DownloadReservationExcelDialog } from 'components/mypage/reservation/download_reservation_excel_dialog'
import { ReservationDetailDialog } from 'components/mypage/reservation/reservation_detail_dialog'
import Calendar from 'components/shared/calendar'
import { ContentHeader, ContentTitle } from 'components/shared/content'
import { Flex } from 'components/shared/flex'
import { SelectField } from 'components/shared/form/input'
import { useModal } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { Laboratory } from 'entities/laboratory'
import { ManagerReservationSearchForm, Reservation } from 'entities/reservation'
import { useForm } from 'rac'
import React, { useState, useEffect, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import { useCalendar, useEffectSkipFirst } from 'utils/hooks'
import { ReservationViewModel } from 'view_models/reservation'

export const AssetReservationIndex: React.FC = () => {
  const searchForm = useForm<ManagerReservationSearchForm>({})
  const [detailDialogOpen, setDetailDialogOpen] = useState<boolean>(false)
  const [selectedReservation, setSelectedReservation] = useState<Reservation>({})
  const globalState = useContext(GlobalStateContext)
  const laboratoryIndexApi = useFetchLaboratoriesApi()
  const api = useFetchAssetReservationsApi()
  const calendarSet = useCalendar<ManagerReservationSearchForm>(api, searchForm)
  const [events, setEvents] = useState<EventInput[]>([])
  const downloadXlsxApi = useFetchXlsxAssetReservationsApi()
  const downloadExcelModal = useModal()

  useEffect(() => {
    globalState.setLoading(api.loading)
  }, [api.loading])

  useEffectSkipFirst(() => {
    const eventInputs: EventInput[] = []
    calendarSet.reservations.forEach((reservation) => {
      const viewModel = new ReservationViewModel(reservation)
      eventInputs.push({
        id: reservation.id!.toString(),
        title: viewModel.getManagerCalendarTitle(),
        start: reservation.startAt,
        end: reservation.endAt,
        color: viewModel.getEventColor(),
      })
    })
    setEvents(eventInputs)
  }, [calendarSet.reservations])

  useEffect(() => {
    laboratoryIndexApi.execute()
  }, [])
  const handleClickEvent = (arg: { el: HTMLElement; event: EventApi; jsEvent: MouseEvent; view: ViewApi }) => {
    const reservation = calendarSet.reservations.find((reservation) => `${reservation.id!}` === arg.event.id)
    if (reservation) {
      setSelectedReservation(reservation)
      setDetailDialogOpen(true)
    }
  }

  const handleClickOutput = () => {
    downloadExcelModal.setOpen(true)
  }

  return (
    <>
      {
        <>
          <ReservationDetailDialog
            open={detailDialogOpen}
            reservation={selectedReservation}
            onClose={() => {
              setDetailDialogOpen(false)
            }}
          />
          <DownloadReservationExcelDialog
            modalState={downloadExcelModal}
            outputApi={downloadXlsxApi}
          />
          <ContentHeader>
            <ContentTitle title="すべての予約状況">{/* ボタンなど */}</ContentTitle>

            <Flex alignItems="center">
              {laboratoryIndexApi.response.laboratories.length > 0 && (
                <>
                  <SelectField
                    label="ラボで絞り込み"
                    width={400}
                    form={searchForm}
                    attr="laboratoryId"
                    labelId="search-select-laboratory"
                    data={laboratoryIndexApi.response.laboratories.map((laboratory: Laboratory) => {
                      return { value: laboratory.id!, label: laboratory.name! }
                    })}
                    includeBlank
                  />
                  <ThemedButton color="secondary" onClick={() => calendarSet.resetDate()}>
                    絞り込み
                  </ThemedButton>
                </>
              )}
            </Flex>
            <Flex alignItems="center">
              <ThemedButton color="secondary" onClick={handleClickOutput}>
                Excel出力
              </ThemedButton>
            </Flex>
          </ContentHeader>
          <Paper style={{ padding: 40, marginTop: 20 }}>
            <Calendar events={events} eventClick={handleClickEvent} datesSet={(arg) => calendarSet.setDate(arg.view.currentStart)} />
          </Paper>
        </>
      }
    </>
  )
}

export default withRouter(AssetReservationIndex)
