import { usePartnerIndexLaboratoryUsersApi } from 'api/partner/laboratory_users'
import { usePartnerFetchProofreadPlanApi } from 'api/partner/proofread_plan'
import { usePartnerExportProofreadEventsApi, usePartnerFetchProofreadEventsApi } from 'api/partner/proofread_plans/proofread_event'
import { useFetchExColumnsApi } from 'api/shared/ex_columns'
import ApprovedPlanEventTable from 'components/partner/proofread/proofread_event/approved_plan_event_table'
import ApprovedResultEventTable from 'components/partner/proofread/proofread_event/approved_result_event_table'
import AttachedResultEventTable from 'components/partner/proofread/proofread_event/attached_result_event_table'
import CompletedEventTable from 'components/partner/proofread/proofread_event/completed_event_table'
import CreateEventModal from 'components/partner/proofread/proofread_event/create_event_modal'
import EventHistoryModal from 'components/partner/proofread/proofread_event/event_history_modal'
import FixDateEventTable from 'components/partner/proofread/proofread_event/fix_date_event_table'
import NoPlanEventTable from 'components/partner/proofread/proofread_event/no_plan_event_table'
import WaitingDateEventTable from 'components/partner/proofread/proofread_event/waiting_date_event_table'
import { ContentHeader, ContentTitle } from 'components/shared/content'
import { ExAttributeBulkUpdateFormDialog, ExAttributeFormDialog } from 'components/shared/ex_attribute/ex_attribute_form_dialog'
import { Flex } from 'components/shared/flex'
import { useModal, useMultipleModal } from 'components/shared/modal'
import ProofreadStepTab, { StepTab } from 'components/shared/proofread_step_tab'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { ExColumn, ExtensionClass } from 'entities/ex_column'
import {
  PARTNER_LAB_CREATED_PROOFREAD_STEPS,
  PARTNER_SUPPLIER_CREATED_LAB_PROOFREAD_STEPS,
  PARTNER_SUPPLIER_CREATED_OUTSIDE_LAB_PROOFREAD_STEPS,
  ProofreadEvent,
  ProofreadEventSearchForm,
  ProofreadEventState,
  supplierProofreadEventStateTexts,
} from 'entities/proofread_event'
import { useForm } from 'rac'
import React, { useContext, useEffect, useState } from 'react'
import { StaticContext } from 'react-router'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import CookieManager from 'utils/cookie_util'
import { useEffectSkipFirst } from 'utils/hooks/use_effect_skip_first'
import { tabTypes } from 'utils/tab_types'
import { ProofreadPlanViewModel } from 'view_models/proofread_plan'

const exAttributeExtensionClassType = 'ProofreadEvent'

type Props = RouteComponentProps<{ id: string }, StaticContext>

export const ProofreadPlanShow = (props: Props) => {
  const showApi = usePartnerFetchProofreadPlanApi(Number(props.match.params.id))

  const approvedPlanSearchForm = useForm<ProofreadEventSearchForm>(
    { q: { aasmStateIn: ['approved_plan'], proofreadScheduleIdNull: true } },
    'proofreadEventForm',
  )
  const approvedPlanApi = usePartnerFetchProofreadEventsApi(Number(props.match.params.id), approvedPlanSearchForm)

  const waitingDateSearchForm = useForm<ProofreadEventSearchForm>(
    { q: { aasmStateIn: ['approved_plan'], proofreadScheduleIdNull: false } },
    'proofreadEventForm',
  )
  const waitingDateApi = usePartnerFetchProofreadEventsApi(Number(props.match.params.id), waitingDateSearchForm)

  const fixDateSearchForm = useForm<ProofreadEventSearchForm>({ q: { aasmStateIn: ['fix_date'] } }, 'proofreadEventForm')
  const fixDateApi = usePartnerFetchProofreadEventsApi(Number(props.match.params.id), fixDateSearchForm)

  const completedSearchForm = useForm<ProofreadEventSearchForm>(
    { q: { aasmStateIn: ['attached_result', 'fix_result', 'approved_result'] } },
    'proofreadEventForm',
  )
  const completedApi = usePartnerFetchProofreadEventsApi(Number(props.match.params.id), completedSearchForm)

  const noPlanSearchForm = useForm<ProofreadEventSearchForm>({ q: { aasmStateIn: ['no_plan'] } }, 'proofreadEventForm')
  const noPlanApi = usePartnerFetchProofreadEventsApi(Number(props.match.params.id), noPlanSearchForm)

  const attachedResultSearchForm = useForm<ProofreadEventSearchForm>({ q: { aasmStateIn: ['attached_result'] } }, 'proofreadEventForm')
  const attachedResultApi = usePartnerFetchProofreadEventsApi(Number(props.match.params.id), attachedResultSearchForm)

  const approvedResultSearchForm = useForm<ProofreadEventSearchForm>({ q: { aasmStateIn: ['approved_result'] } }, 'proofreadEventForm')
  const approvedResultApi = usePartnerFetchProofreadEventsApi(Number(props.match.params.id), approvedResultSearchForm)

  const exportApi = usePartnerExportProofreadEventsApi(Number(props.match.params.id))
  const usersApi = usePartnerIndexLaboratoryUsersApi()

  const globalState = useContext(GlobalStateContext)

  const exColumnModalSet = useMultipleModal<ProofreadEvent>()
  const exColumnBulkUpdateModalSet = useMultipleModal<number[]>()

  const [planId] = useState(Number(props.match.params.id))

  const [step, setStep] = useState<ProofreadEventState>()
  const createEventModal = useModal()
  const eventHistoryModal = useMultipleModal<ProofreadEvent>()

  const indexExColumnApi = useFetchExColumnsApi('dealer_user')
  const [exClass, setExClass] = useState<ExtensionClass>('InstrumentInfoAttribute')
  const [exColumns, setExColumns] = useState<ExColumn[]>([])
  const [tableShowColumns, setTableShowColumns] = useState<ExColumn[]>([])

  const refetchTable = () => {
    if (isPlanCreatedByLaboratory) {
      // リプルア顧客向けにラボが作成した校正計画
      approvedPlanApi.execute()
      waitingDateApi.execute()
      fixDateApi.execute()
      completedApi.execute()
    } else if (isPlanForLaboratory) {
      // リプルア顧客向けにディーラが作成した校正計画
      noPlanApi.execute()
      approvedPlanApi.execute()
      waitingDateApi.execute()
      fixDateApi.execute()
      completedApi.execute()
    } else {
      // 外部顧客向けにディーラが作成した校正計画
      noPlanApi.execute()
      attachedResultApi.execute()
      approvedResultApi.execute()
    }
  }

  const handleUpdate = () => showApi.execute()

  /*
    ownerable: Laboratory / Dealer
    clientable: Laboratory / OutsideLaboratory 
      ownerable が Dealer の場合のみ値を持つ
  */
  const isPlanCreatedByLaboratory = showApi.response.proofreadPlan.ownerableType === 'Laboratory'
  const isPlanForLaboratory = showApi.response.proofreadPlan.clientableType === 'Laboratory'

  const setStepId = () => {
    if (isPlanCreatedByLaboratory) {
      // ラボが作成した校正計画
      handleSelectStep(
        PARTNER_LAB_CREATED_PROOFREAD_STEPS.includes(
          CookieManager.getSelectedTab<ProofreadEventState>(tabTypes.PARTNER_LAB_CREATED_PROOFREAD) ||
          PARTNER_SUPPLIER_CREATED_LAB_PROOFREAD_STEPS[0],
        )
          ? CookieManager.getSelectedTab<ProofreadEventState>(tabTypes.PARTNER_LAB_CREATED_PROOFREAD) ||
          PARTNER_LAB_CREATED_PROOFREAD_STEPS[0]
          : PARTNER_LAB_CREATED_PROOFREAD_STEPS[0],
      )
    } else if (isPlanForLaboratory) {
      // 代理店が作成したリプルア顧客向けの校正計画
      handleSelectStep(
        PARTNER_SUPPLIER_CREATED_LAB_PROOFREAD_STEPS.includes(
          CookieManager.getSelectedTab<ProofreadEventState>(tabTypes.PARTNER_SUPPLIER_CREATED_LAB_PROOFREAD) ||
          PARTNER_SUPPLIER_CREATED_LAB_PROOFREAD_STEPS[0],
        )
          ? CookieManager.getSelectedTab<ProofreadEventState>(tabTypes.PARTNER_SUPPLIER_CREATED_LAB_PROOFREAD) ||
          PARTNER_SUPPLIER_CREATED_LAB_PROOFREAD_STEPS[0]
          : PARTNER_SUPPLIER_CREATED_LAB_PROOFREAD_STEPS[0],
      )
    } else {
      // 代理店が作成した外部顧客向けの校正計画
      handleSelectStep(
        PARTNER_SUPPLIER_CREATED_OUTSIDE_LAB_PROOFREAD_STEPS.includes(
          CookieManager.getSelectedTab<ProofreadEventState>(tabTypes.PARTNER_SUPPLIER_CREATED_OUTSIDE_LAB_PROOFREAD) ||
          PARTNER_SUPPLIER_CREATED_OUTSIDE_LAB_PROOFREAD_STEPS[0],
        )
          ? CookieManager.getSelectedTab<ProofreadEventState>(tabTypes.PARTNER_SUPPLIER_CREATED_OUTSIDE_LAB_PROOFREAD) ||
          PARTNER_SUPPLIER_CREATED_OUTSIDE_LAB_PROOFREAD_STEPS[0]
          : PARTNER_SUPPLIER_CREATED_OUTSIDE_LAB_PROOFREAD_STEPS[0],
      )
    }
  }

  const saveSelectedTabId = (step: ProofreadEventState) => {
    if (isPlanCreatedByLaboratory) {
      // ラボが作成した校正計画
      CookieManager.saveSelectedTab(tabTypes.PARTNER_LAB_CREATED_PROOFREAD, step)
    } else if (isPlanForLaboratory) {
      // 代理店が作成したリプルア顧客向けの校正計画
      CookieManager.saveSelectedTab(tabTypes.PARTNER_SUPPLIER_CREATED_LAB_PROOFREAD, step)
    } else {
      // 代理店が作成した外部顧客向けの校正計画
      CookieManager.saveSelectedTab(tabTypes.PARTNER_SUPPLIER_CREATED_OUTSIDE_LAB_PROOFREAD, step)
    }
  }

  useEffectSkipFirst(() => {
    if (showApi.isSuccess()) {
      refetchTable()
      setStepId()
      if (showApi.response.proofreadPlan.clientableType === 'Laboratory' && showApi.response.proofreadPlan.clientableId) {
        usersApi.execute(showApi.response.proofreadPlan.clientableId)
      }
    }
  }, [showApi.loading])

  useEffect(() => {
    showApi.execute()
    // 管理情報設定の取得
    indexExColumnApi.execute()
  }, [])

  const handleClickExport = (format: 'xlsx') => {
    exportApi.execute(format)
  }

  const handleClickExAttr = (event: ProofreadEvent) => {
    setExClass(exAttributeExtensionClassType)
    setExColumns(indexExColumnApi.response.exColumns.filter((c) => c.extensionClass === exAttributeExtensionClassType))
    exColumnModalSet.openModal(event)
  }
  const handleClickBulkExAttr = (eventIds: number[]) => {
    setExClass(exAttributeExtensionClassType)
    setExColumns(indexExColumnApi.response.exColumns.filter((c) => c.extensionClass === exAttributeExtensionClassType))
    exColumnBulkUpdateModalSet.openModal(eventIds)
  }
  const handleCompleteExAttr = () => {
    exColumnModalSet.closeModal()
    exColumnBulkUpdateModalSet.closeModal()
    showApi.execute()
  }

  const handleSelectStep = (step: ProofreadEventState) => {
    saveSelectedTabId(step)
    setStep(step)
  }
  const handleClickAddInstrument = () => {
    createEventModal.setOpen(true)
  }
  const handleCompleteCreate = () => {
    createEventModal.setOpen(false)
    showApi.execute()
  }
  const handleClickHistory = (event: ProofreadEvent) => {
    eventHistoryModal.openModal(event)
  }

  useEffectSkipFirst(() => {
    if (!indexExColumnApi.isSuccess()) return
    setTableShowColumns(indexExColumnApi.response.exColumns.filter((col) => col.visibleOnTable))
  }, [indexExColumnApi.loading])

  /**
   * ローディングプログレスの表示
   */
  useEffect(() => {
    globalState.setLoading(showApi.loading)
  }, [showApi.loading])

  const stepTabsCreatedByLab: StepTab[] = [
    {
      id: 'approved_plan',
      label: supplierProofreadEventStateTexts.approved_plan,
      component: (
        <ApprovedPlanEventTable
          indexApi={approvedPlanApi}
          onUpdate={handleUpdate}
          planId={planId}
          onClickExAttr={handleClickExAttr}
          onClickBulkExAttr={handleClickBulkExAttr}
        />
      ),
    },
    {
      id: 'waiting_date',
      label: supplierProofreadEventStateTexts.waiting_date,
      component: (
        <WaitingDateEventTable
          indexApi={waitingDateApi}
          planId={Number(props.match.params.id)}
          onUpdate={handleUpdate}
          onClickExAttr={handleClickExAttr}
          onClickBulkExAttr={handleClickBulkExAttr}
        />
      ),
    },
    {
      id: 'fix_date',
      label: supplierProofreadEventStateTexts.fix_date,
      component: (
        <FixDateEventTable
          indexApi={fixDateApi}
          onUpdate={handleUpdate}
          planId={planId}
          onClickExAttr={handleClickExAttr}
          onClickBulkExAttr={handleClickBulkExAttr}
        />
      ),
    },
    {
      id: 'completed',
      label: supplierProofreadEventStateTexts.completed,
      component: (
        <CompletedEventTable
          indexApi={completedApi}
          onUpdate={handleUpdate}
          onClickExAttr={handleClickExAttr}
          onClickBulkExAttr={handleClickBulkExAttr}
          isPlanForLaboratory={isPlanForLaboratory}
        />
      ),
    },
  ]

  const stepTabsCreatedBySupplier: StepTab[] = [
    {
      id: 'no_plan',
      label: supplierProofreadEventStateTexts.no_plan,
      component: (
        <NoPlanEventTable
          planId={Number(props.match.params.id)}
          searchForm={noPlanSearchForm}
          indexApi={noPlanApi}
          onUpdate={handleUpdate}
          onClickExAttr={handleClickExAttr}
          onClickHistory={handleClickHistory}
          onClickBulkExAttr={handleClickBulkExAttr}
          tableShowColumns={tableShowColumns}
          isPlanForLaboratory={false}
        />
      ),
    },
    {
      id: 'attached_result',
      label: supplierProofreadEventStateTexts.attached_result,
      component: (
        <AttachedResultEventTable
          searchForm={attachedResultSearchForm}
          planId={Number(props.match.params.id)}
          indexApi={attachedResultApi}
          onUpdate={handleUpdate}
          onClickExAttr={handleClickExAttr}
          onClickBulkExAttr={handleClickBulkExAttr}
          tableShowColumns={tableShowColumns}
        />
      ),
    },
    {
      id: 'approved_result',
      label: supplierProofreadEventStateTexts.approved_result,
      component: (
        <ApprovedResultEventTable
          indexApi={approvedResultApi}
          searchForm={approvedResultSearchForm}
          onUpdate={handleUpdate}
          onClickExAttr={handleClickExAttr}
          onClickBulkExAttr={handleClickBulkExAttr}
          tableShowColumns={tableShowColumns}
        />
      ),
    },
  ]

  const getStepTabs = (): StepTab[] => {
    if (isPlanCreatedByLaboratory) {
      // リプルア顧客が作成した校正計画
      return stepTabsCreatedByLab
    } else {
      if (isPlanForLaboratory) {
        // リプルア顧客向けにディーラが作成した校正計画
        return [
          {
            id: 'no_plan',
            label: supplierProofreadEventStateTexts.no_plan,
            component: (
              <NoPlanEventTable
                planId={Number(props.match.params.id)}
                searchForm={noPlanSearchForm}
                indexApi={noPlanApi}
                onUpdate={handleUpdate}
                onClickExAttr={handleClickExAttr}
                onClickHistory={handleClickHistory}
                onClickBulkExAttr={handleClickBulkExAttr}
                tableShowColumns={tableShowColumns}
                isPlanForLaboratory={true}
                users={usersApi.response.users}
              />
            ),
          },
          ...stepTabsCreatedByLab,
        ]
      } else {
        // 外部顧客向けにディーラが作成した校正計画
        return stepTabsCreatedBySupplier
      }
    }
  }

  const vm = new ProofreadPlanViewModel(showApi.response.proofreadPlan)

  return (
    <>
      {showApi.isSuccess() && (
        <>
          <CreateEventModal modalState={createEventModal} planId={Number(props.match.params.id)} onComplete={handleCompleteCreate} />
          <ExAttributeFormDialog
            clientType="dealer_user"
            modalSet={exColumnModalSet}
            extensionClass={exClass}
            exAttributes={exColumnModalSet.item.exAttributes || []}
            onComplete={handleCompleteExAttr}
            dealerClient={{
              clientableId: showApi.response.proofreadPlan.clientableId,
              clientableType: showApi.response.proofreadPlan.clientableType,
            }}
          />
          <ExAttributeBulkUpdateFormDialog
            clientType="dealer_user"
            modalSet={exColumnBulkUpdateModalSet}
            extensionClass={exClass}
            exColumns={exColumns}
            onComplete={handleCompleteExAttr}
            dealerClient={{
              clientableId: showApi.response.proofreadPlan.clientableId,
              clientableType: showApi.response.proofreadPlan.clientableType,
            }}
          />
          <EventHistoryModal modalSet={eventHistoryModal} />
          <ContentHeader>
            <ContentTitle title={`${vm.getLaboratoryName()} - ${vm.getTitle()}`}>
              {step === 'no_plan' && (
                <ThemedButton color="primary" onClick={handleClickAddInstrument}>
                  新しく機器を追加する
                </ThemedButton>
              )}
            </ContentTitle>
            <Flex>
              <ThemedButton color="secondary" onClick={() => handleClickExport('xlsx')}>
                Excel出力
              </ThemedButton>
            </Flex>
          </ContentHeader>
          <ProofreadStepTab data={getStepTabs()} currentStepId={step} plan={showApi.response.proofreadPlan} onSelect={handleSelectStep} />
        </>
      )}
    </>
  )
}

export default withRouter(ProofreadPlanShow)
