import { Paper } from '@mui/material'
import { usePartnerFetchRepairEstimatesApi } from 'api/partner/repair_estimate'
import RepairEstimateTable from 'components/partner/repair_estimate/repair_estimate_table'
import { ContentHeader, ContentTitle } from 'components/shared/content'
import { SearchBar } from 'components/shared/search_bar'
import { RepairEstimateSearchForm } from 'entities/repair_estimate'
import { useForm } from 'rac'
import React, { useEffect } from 'react'
import { StaticContext } from 'react-router'
import { RouteComponentProps, withRouter } from 'react-router-dom'

export type RepairEstimateIndexProps = {}

type Props = RouteComponentProps<{}, StaticContext, RepairEstimateIndexProps>
export const RepairEstimateIndex: React.FC<Props> = (props) => {
  const searchForm = useForm<RepairEstimateSearchForm>({})
  const estimateIndexApi = usePartnerFetchRepairEstimatesApi(searchForm)
  const handleSearch = () => {
    estimateIndexApi.execute()
  }

  useEffect(() => {
    estimateIndexApi.execute()
  }, [])

  return (
    <>
      <ContentHeader>
        <ContentTitle title="修理見積一覧" />
        <SearchBar<RepairEstimateSearchForm>
          form={searchForm}
          searchAttr="searchText"
          placeholder="機器名称・依頼者・不具合内容・見積もりNo.等で検索"
          onSubmit={handleSearch}
        />
      </ContentHeader>
      <Paper>
        <RepairEstimateTable indexApi={estimateIndexApi} history={props.history} />
      </Paper>
    </>
  )
}

export default withRouter(RepairEstimateIndex)
