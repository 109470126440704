import { AxiosHttpClient } from 'api/axios'
import { useRepruaPatchApi } from 'api/shared_api_hooks'
import { BulkUpdateDealerUserAuthorityForm } from 'entities/dealer_user_authority'
import { ApiSet, BaseResponse } from 'rac'

type DealerUsersResponse = BaseResponse

/**
 * 更新
 */
export function usePartnerPatchDealerDealerUserAuthorityApi(): ApiSet<DealerUsersResponse> & {
  execute: (dealerUserAuthorityForm: BulkUpdateDealerUserAuthorityForm) => void
} {
  const api = useRepruaPatchApi<DealerUsersResponse, BulkUpdateDealerUserAuthorityForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: {},
  })

  const execute = (dealerUserAuthorityForm: BulkUpdateDealerUserAuthorityForm) => {
    const path = 'dealer_user_authorities/bulk_update'
    api.execute(path, dealerUserAuthorityForm)
  }

  return { ...api, execute }
}
