import CancelIcon from '@mui/icons-material/CancelOutlined'
import { ProofreadEventAttachment, ProofreadEventAttachmentKind } from 'entities/proofread_event_attachments'
import React from 'react'

type DisplayAttachmentsContainerProps = {
  label: string
  attachments: ProofreadEventAttachment[] | undefined
  kind: ProofreadEventAttachmentKind
  onClickRemove?: (a: ProofreadEventAttachment) => void
}
export const DisplayAttachmentsContainer = ({ label, attachments, kind, onClickRemove }: DisplayAttachmentsContainerProps) => {
  const filteredAttachments = attachments?.filter((a) => a.kind === kind)

  return (
    <div style={{ marginBottom: 12 }}>
      <label>{label}</label>
      <ul style={{ listStyle: 'none', marginTop: 6, paddingLeft: 12 }}>
        {filteredAttachments && filteredAttachments.length > 0 ? (
          filteredAttachments.map((a, i) => (
            <li key={`${kind}-list-${i}`}>
              <a href={a.url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                {a.name}
              </a>
              {onClickRemove && <CancelIcon style={{ cursor: 'pointer' }} color="error" onClick={() => onClickRemove(a)} />}
            </li>
          ))
        ) : (
          <span>なし</span>
        )}
      </ul>
    </div>
  )
}
