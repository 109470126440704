import { MutationErrorResponse } from 'api/MutationErrorResponse'
import { AxiosHttpClient } from 'api/axios'
import { useRepruaDeleteApi, useRepruaIndexApi } from 'api/shared_api_hooks'
import { InstrumentImportResult } from 'entities/instrument_import_result'
import { BaseResponse, IndexApiSet } from 'rac'
import { useMutation } from 'react-query'
import { useMutationMessageHandler } from 'utils/hooks/use_mutation_message_handler'

type InstrumentImportResultsResponse = BaseResponse & {
  instrumentImportResults: InstrumentImportResult[]
}

type InstrumentImportResultsParams = {
  dealerId: number
}

/**
 * ディーラー側のインポート結果の取得
 */
type DealerInstrumentImportResultsApiSet = IndexApiSet<InstrumentImportResultsResponse> & {
  execute: (id: number) => void
}
export const useDealerInstrumentImportResults = (): DealerInstrumentImportResultsApiSet => {
  const api = useRepruaIndexApi<InstrumentImportResultsResponse, InstrumentImportResultsParams>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { instrumentImportResults: [] },
  })

  const execute = () => {
    const path = 'instrument_import_results'
    api.execute(path)
  }

  return { ...api, execute }
}

type DealerInstrumentImportResultResponse = BaseResponse & {
  instrumentImportResult: InstrumentImportResult
}

type DealerInstrumentImportResultParams = {
  outsideLaboratoryId?: string
  uploadFile: File
}

/**
 * ディーラー側の機器のエクセルインポート
 */
export const usePostDealerInstrumentImportMutation = () => {
  const client = new AxiosHttpClient('dealer_user')
  const mutation = useMutation<DealerInstrumentImportResultResponse, MutationErrorResponse, DealerInstrumentImportResultParams>(
    'dealers_set_instrument_import',
    async (params) => {
      const { data } = await client.post('instrument_import_results/excel', params)
      return data
    },
  )

  useMutationMessageHandler(mutation)

  return mutation
}

/**
 * ディーラー側のインポートの取り消し
 */
export const useDeleteDealerInstrumentImport = () => {
  const api = useRepruaDeleteApi<DealerInstrumentImportResultResponse>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { instrumentImportResult: {} },
  })

  const execute = (instrumentImportResultId: number) => {
    const path = `instrument_import_results/${instrumentImportResultId}`
    api.execute(path)
  }

  return { ...api, execute }
}

type FetchDealerInstrumentImportFormatParams = {
  outsideLaboratoryId?: number
}
/**
 * ディーラー側のフォーマットのダウンロード
 */
export const useFetchDealerInstrumentImportFormatMutation = () => {
  const client = new AxiosHttpClient('dealer_user')
  const mutation = useMutation<BaseResponse, MutationErrorResponse, FetchDealerInstrumentImportFormatParams>(
    'dealers_set_instrument_import',
    async (params) => {
      const { data } = await client.download('instrument_import_results/excel_format', params)
      return data
    },
  )

  useMutationMessageHandler(mutation)

  return mutation
}
