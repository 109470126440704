import { usePatchProofreadEventBulkApproveApi } from 'api/mypage/proofread_event'
import { StringField } from 'components/shared/form/input'
import Modal, { ModalSet } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { MypageStateContext } from 'contexts/mypage_state_context'
import { ProofreadBulkApproveForm } from 'entities/proofread_approver'
import { useForm } from 'rac'
import React, { useContext } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'

type BulkApproveFormDialogProps = {
  modalSet: ModalSet<number[]>
  onComplete: () => void
  kind: 'plan' | 'result'
}
export const BulkApproveFormDialog: React.FC<BulkApproveFormDialogProps> = (props) => {
  const { currentUser } = useContext(MypageStateContext)
  const globalState = useContext(GlobalStateContext)
  const patchForm = useForm<ProofreadBulkApproveForm>({
    proofreadEventIds: props.modalSet.item,
    approverId: currentUser.id,
    kind: props.kind,
  })
  const patchApi = usePatchProofreadEventBulkApproveApi(patchForm)

  const handleApprove = (status: 'approved' | 'rejected') => {
    globalState.confirm(`校正計画を一括で${status === 'approved' ? '承認' : '却下'}します。よろしいですか？`, (e) => {
      if (e === 'ok') {
        patchApi.execute(status)
      }
    })
  }

  useEffectSkipFirst(() => {
    patchForm.resetForm()
  }, [props.modalSet, props.kind])

  useEffectSkipFirst(() => {
    if (!patchApi.isSuccess()) return
    props.onComplete()
  }, [patchApi.loading])

  return (
    <Modal
      modalId="event-bulk-approve-form"
      modalState={props.modalSet.modalState}
      title="校正計画一括承認"
      size="sm"
      footer={
        <>
          <ThemedButton onClick={props.modalSet.closeModal} color="secondary">
            閉じる
          </ThemedButton>
          <ThemedButton color="error" onClick={() => handleApprove('rejected')}>
            却下
          </ThemedButton>
          <ThemedButton color="success" onClick={() => handleApprove('approved')}>
            承認
          </ThemedButton>
        </>
      }
    >
      <p style={{ color: 'red' }}>
        選択中の校正計画のうち、
        <br />
        <b>承認者として割り当てられているもの</b>を一括で承認／却下します
      </p>
      <StringField label="承認メモ" form={patchForm} attr="note" apiError={patchApi.apiError} multiline style={{ marginTop: 24 }} />
    </Modal>
  )
}

export default BulkApproveFormDialog
