import { Table, TableHead, TableRow, TableContainer } from '@mui/material'
import { usePartnerFetchDealerDivisionsApi } from 'api/partner/dealer_divisions'
import { PartnerFetchInstrumentsApiSet } from 'api/partner/instrument'
import { usePartnerFetchOutsideLaboratoriesApi } from 'api/partner/outside_laboratories'
import DropDownCheckbox from 'components/shared/dropdown_checkbox'
import { DropDownCheckboxItem } from 'components/shared/form/input'
import ImageView from 'components/shared/image_view'
import Pagination from 'components/shared/pagination'
import { QrCode } from 'components/shared/qrcode'
import { ExTableCell, OrderTableCell, AccordionTableBody, AllCheckboxTableCell, CheckboxTableCell } from 'components/shared/table'
import { Instrument, InstrumentSearchForm } from 'entities/instrument'
import { Form } from 'rac'
import React, { useEffect, useMemo } from 'react'
import { PartnerRouteHelper } from 'routes/partner'
import { getTableHeight, INDEX_CHECKED_TABLE_PADDING, INDEX_TABLE_PADDING } from 'utils/component_heights'
import { CheckboxesSet, DisplaySettingSet } from 'utils/hooks'
import { InstrumentViewModel } from 'view_models/instrument'

type InstrumentTableWithBarcodeProps = {
  displaySettingSet: DisplaySettingSet
  instrumentIndexApi: PartnerFetchInstrumentsApiSet
  checkboxSet: CheckboxesSet
  searchForm: Form<InstrumentSearchForm>
  onSearch: () => void
}

export const InstrumentTableWithBarcode = ({
  displaySettingSet,
  instrumentIndexApi,
  checkboxSet,
  searchForm,
  onSearch,
}: InstrumentTableWithBarcodeProps) => {
  const outsideLaboratoriesIndexApi = usePartnerFetchOutsideLaboratoriesApi({ useFilter: true })
  const dealerDivisionsIndexApi = usePartnerFetchDealerDivisionsApi(false)
  const path = PartnerRouteHelper.instrumentIndex()

  const outsideLaboratoryOptions = useMemo(
    (): DropDownCheckboxItem[] => [
      { value: '', label: '未選択' },
      ...outsideLaboratoriesIndexApi.response.outsideLaboratories.map((lab) => ({
        value: lab.id!.toString(),
        label: lab.name!,
      })),
    ],
    [outsideLaboratoriesIndexApi.response.outsideLaboratories],
  )

  const dealerDivisionOptions = useMemo(
    (): DropDownCheckboxItem[] => [
      { value: '', label: '未選択' },
      ...dealerDivisionsIndexApi.response.dealerDivisions.map((division) => ({
        value: division.id!.toString(),
        label: division.name!,
      })),
    ],
    [dealerDivisionsIndexApi.response.dealerDivisions],
  )

  useEffect(() => {
    outsideLaboratoriesIndexApi.execute()
    dealerDivisionsIndexApi.execute()
  }, [])

  return (
    <>
      <TableContainer
        style={{
          maxHeight: checkboxSet.checkedIds.length > 0 ? getTableHeight(INDEX_CHECKED_TABLE_PADDING) : getTableHeight(INDEX_TABLE_PADDING),
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <AllCheckboxTableCell checkboxSet={checkboxSet} />
              <ExTableCell width={150}></ExTableCell>
              <OrderTableCell
                width={120}
                open={displaySettingSet.isVisible('createdAt')}
                align="center"
                orderAttr="createdAt"
                orderSet={instrumentIndexApi.orderSet}
              >
                登録日
              </OrderTableCell>
              <OrderTableCell
                width={120}
                open={displaySettingSet.isVisible('maker')}
                orderAttr="maker"
                orderSet={instrumentIndexApi.orderSet}
              >
                メーカー
              </OrderTableCell>
              <ExTableCell width={120} open={displaySettingSet.isVisible('outsideLaboratory')}>
                <DropDownCheckbox
                  form={searchForm}
                  attr="outsideLaboratoryIds"
                  name="outsideLaboratoryId"
                  items={outsideLaboratoryOptions}
                  title="顧客"
                  onSubmit={onSearch}
                  btnStyle={{ padding: '4px 8px' }}
                  isLeft={true}
                  maxWidth={600}
                  showFilter
                  autocomplete
                />
              </ExTableCell>
              <ExTableCell width={120} open={displaySettingSet.isVisible('dealerDivision')}>
                <DropDownCheckbox
                  form={searchForm}
                  attr="dealerDivisionIds"
                  name="dealerDivisionIds"
                  items={dealerDivisionOptions}
                  title="部署"
                  onSubmit={onSearch}
                  btnStyle={{ padding: '4px 8px' }}
                  isLeft={true}
                  maxWidth={600}
                  showFilter
                  autocomplete
                />
              </ExTableCell>
              <OrderTableCell minWidth={200} orderAttr="name" orderSet={instrumentIndexApi.orderSet}>
                型番 / シリアル番号 / 管理番号 / 製品名
              </OrderTableCell>
              <ExTableCell align="center">QRコード</ExTableCell>
            </TableRow>
          </TableHead>
          <AccordionTableBody loading={instrumentIndexApi.loading} tableName="instruments-table">
            {instrumentIndexApi.response?.instruments.map((instrument: Instrument) => {
              const instrumentVM = new InstrumentViewModel(instrument)

              return (
                <TableRow key={instrument.id}>
                  <CheckboxTableCell checkboxesSet={checkboxSet} checkboxId={instrument.id!} />
                  <ExTableCell width={100} align="center">
                    <ImageView maxHeight={100} maxWidth={100} src={instrument.instrumentBasicInfo?.imageUrl} />
                  </ExTableCell>
                  <ExTableCell width={120} open={displaySettingSet.isVisible('createdAt')} align="center">
                    {instrumentVM.registeredAt()}
                  </ExTableCell>
                  <ExTableCell width={120} open={displaySettingSet.isVisible('maker')}>
                    {instrumentVM.getMakerName()}
                  </ExTableCell>
                  <ExTableCell width={120} open={displaySettingSet.isVisible('outsideLaboratory')}>
                    {instrumentVM.ownerableName()}
                  </ExTableCell>
                  <ExTableCell width={120} open={displaySettingSet.isVisible('dealerDivision')}>
                    {instrumentVM.dealerDivisionNames()}
                  </ExTableCell>
                  <ExTableCell>
                    <div style={{ fontSize: 11, marginBottom: 5 }}>{instrumentVM.getInstrumentNumbers()}</div>
                    <div style={{ fontSize: 16 }}>{instrumentVM.getInstrumentName()}</div>
                  </ExTableCell>
                  <ExTableCell align="center">
                    <QrCode path={path} layoutSetting={{ imageScale: '100px' }} instrument={instrument} displaySetting={{}} />
                  </ExTableCell>
                </TableRow>
              )
            })}
          </AccordionTableBody>
        </Table>
      </TableContainer>
      <Pagination response={instrumentIndexApi.response} pageSet={instrumentIndexApi.pageSet} />
    </>
  )
}
