import { AxiosHttpClient } from 'api/axios'
import { useRepruaIndexApi, useRepruaPostApi, useRepruaDeleteApi } from 'api/shared_api_hooks'
import { ID } from 'entities'
import { UserMemo, UserMemoForm } from 'entities/user_memo'
import { ApiSet, BaseResponse, Form, IndexApiSet } from 'rac'
import { toPluralSnakeCase } from 'utils/utils'


type UserMemosResponse = BaseResponse & {
  userMemos: UserMemo[]
}

type UserMemosIndexApiSet = IndexApiSet<UserMemosResponse> & {
  execute: () => void
}

export type UserMemoableType = 'Instrument' | 'Room'

/**
 * 一覧
 */
export function useFetchUserMemosApi(memoableId: number, memoableType: UserMemoableType): UserMemosIndexApiSet {
  const apiPath = `${toPluralSnakeCase(memoableType)}/${memoableId}/user_memos`
  const apiSet = useRepruaIndexApi<UserMemosResponse, unknown>(new AxiosHttpClient('user'), { initialResponse: { userMemos: [] } })

  const execute = () => {
    apiSet.execute(apiPath)
  }

  return { ...apiSet, execute }
}

type UserMemoResponse = BaseResponse & {}

/**
 * 登録
 */
export function usePostUserMemosApi(
  memoableId: number,
  memoableType: UserMemoableType,
): ApiSet<UserMemoResponse> & { execute: (params: Form<UserMemoForm>) => void } {
  const apiPath = `${toPluralSnakeCase(memoableType)}/${memoableId}/user_memos`
  const apiSet = useRepruaPostApi<UserMemoResponse, UserMemoForm>(new AxiosHttpClient('user'), { initialResponse: {} })

  const execute = (form: Form<UserMemoForm>) => {
    apiSet.execute(apiPath, form)
  }

  return { ...apiSet, execute }
}

/**
 * 削除
 */
export function useDeleteUserMemosApi(): ApiSet<UserMemoResponse> & { execute: (id: ID) => void } {
  const apiSet = useRepruaDeleteApi<UserMemoResponse>(new AxiosHttpClient('user'), { initialResponse: {} })

  const execute = (id: ID) => {
    const apiPath = `user_memos/${id}`
    apiSet.execute(apiPath)
  }

  return { ...apiSet, execute }
}
