import CancelIcon from '@mui/icons-material/CancelOutlined'
import Clear from '@mui/icons-material/Clear'
import {
  IconButton,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  SelectProps,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlProps,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Switch,
  Button,
  Autocomplete,
  AutocompleteInputChangeReason,
  SelectChangeEvent,
} from '@mui/material'
import { DatePicker, DatePickerProps, TimePicker, TimePickerProps, DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers'
import { Flex } from 'components/shared/flex'
import ImageView from 'components/shared/image_view'
import ThemedButton from 'components/shared/themed_button'
import { format as fnsFormat, isValid as fnsIsValid } from 'date-fns'
import { DayOfTheWeek, DayOfTheWeeks } from 'entities/reservation_constraint'
import { Form, FormAttrType, ParseableValue, ApiError, FormAttrType as Attribute } from 'rac'
import React, { useState, useEffect, Dispatch, SetStateAction, ReactNode, CSSProperties, useRef } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useEffectSkipFirst } from 'utils/hooks'

/**
 * Formクラスと連携するためのPropsType
 */
type ExFieldProps<T> = {
  attr: Attribute<T>
  form: Form<T>
  apiError?: ApiError
  width?: number
}

type FormWrapperProps = {
  children: ReactNode
  apiError?: ApiError
  width?: number
  required?: boolean
}

const FormWrapper: React.FC<FormWrapperProps> = (props: FormWrapperProps) => {
  return (
    <div
      className={`custom-form ${!props.apiError && !props.required ? '' : 'helper-form'}`}
      style={{ width: props.width ? props.width : '100%' }}
    >
      {props.children}
    </div>
  )
}

function setError<T>(
  apiError: ApiError | undefined,
  modelName: string | undefined,
  attr: Attribute<T>,
  setError: Dispatch<SetStateAction<string | null>>,
  label?: ReactNode,
) {
  if (!apiError || !modelName) {
    return
  }
  const modelDetails = apiError.details[modelName]
  if (!modelDetails) {
    setError(null)
    return null
  }
  const message = modelDetails[attr as string]
  if (!message) {
    setError(null)
    return null
  }

  setError(label + message)
}

/**
 * 文字列用のインプット（TextFieldのWrapper）
 */
type TextFieldProps<T> = MuiTextFieldProps &
  ExFieldProps<T> & {
    onDownEnter?: (value?: string) => void
  }

export const StringField = <T,>(props: TextFieldProps<T>) => {
  const { onDownEnter, attr, form, apiError, children, ...rest } = props
  const [errorMessage, setErrorMessage] = useState<null | string>(null)

  const handleChangeForm = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    form.newUpdateObject(attr, e.target.value)
  }

  useEffect(() => {
    setError(apiError, form.modelName, attr, setErrorMessage, props.label)
  }, [apiError])

  return (
    <FormWrapper apiError={apiError} required={props.required} width={props.width}>
      <MuiTextField
        {...rest}
        type={props.type ? props.type : 'text'}
        value={form.getValue(attr) || undefined}
        onChange={handleChangeForm}
        error={errorMessage !== null}
        helperText={props.helperText && !errorMessage ? props.helperText : props.required && !errorMessage ? '必須' : errorMessage}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            // @ts-ignore エンターキー押下時の処理
            const value = e.target.value as string
            onDownEnter && onDownEnter(value)
          }
        }}
      >
        {props.children}
      </MuiTextField>
    </FormWrapper>
  )
}

/**
 * 数値用のインプット（TextFieldのWrapper）
 */
export const NumberField = <T,>(props: TextFieldProps<T>) => {
  const { attr, form, apiError, children, ...rest } = props

  const [errorMessage, setErrorMessage] = useState<null | string>(null)

  useEffect(() => {
    setError(apiError, form.modelName, attr, setErrorMessage, props.label)
  }, [apiError])

  const handleChangeForm = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    form.newUpdateObject(attr, e.target.value === '' ? '' : Number(e.target.value))
  }

  return (
    <FormWrapper apiError={apiError} required={props.required} width={props.width}>
      <MuiTextField
        {...rest}
        type="number"
        value={form.getValue(attr) === 0 ? 0 : form.getValue(attr) || ''}
        onChange={handleChangeForm}
        error={errorMessage !== null}
        helperText={props.required && !errorMessage ? '必須' : errorMessage}
      >
        {props.children}
      </MuiTextField>
    </FormWrapper>
  )
}

const getValueOnlyString = <T,>(form: Form<T>, attr: FormAttrType<T>): string | null => {
  const value = form.getValue(attr)
  if (value !== '' && typeof value === 'string') {
    return value
  } else {
    return null
  }
}

const handleChangedDateTime = <T,>(date: Date | null, format: string, form: Form<T>, attr: FormAttrType<T>) => {
  if (date && fnsIsValid(date)) {
    const formattedDate: string = fnsFormat(date, format)
    form.newUpdateObject(attr, formattedDate)
  } else {
    form.newUpdateObject(attr, null)
  }
}

type DatePickerFieldProps<T> = ExFieldProps<T> &
  MuiTextFieldProps & {
    PickerProps?: Omit<DatePickerProps<string, Date>, 'value' | 'onChange' | 'renderInput'>
    clearable?: boolean
  }

/**
 * 日付用のインプット（ DatePicker のラッパー）
 */
export const DateField = <T,>(props: DatePickerFieldProps<T>) => {
  const { attr, form, apiError, PickerProps, ...rest } = props
  const [errorMessage, setErrorMessage] = useState<null | string>(null)

  useEffect(() => {
    setError(apiError, form.modelName, attr, setErrorMessage, rest.label)
  }, [apiError])

  return (
    <FormWrapper apiError={apiError} required={rest.required} width={props.width}>
      <DatePicker<string, Date>
        value={getValueOnlyString<T>(form, attr)}
        inputFormat="yyyy/MM/dd"
        onChange={(date) => handleChangedDateTime(date, 'yyyy-MM-dd', form, attr)}
        renderInput={(params) => (
          <MuiTextField
            {...params}
            autoComplete="off"
            error={errorMessage !== null}
            helperText={rest?.required && !errorMessage ? '必須' : errorMessage}
            onKeyDown={(e) => e.preventDefault()}
            {...rest}
          />
        )}
        InputProps={{
          endAdornment: (
            <>
              {props.clearable && (
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation()
                    handleChangedDateTime(null, '', form, attr)
                  }}
                >
                  <Clear fontSize="small" />
                </IconButton>
              )}
            </>
          ),
        }}
        InputAdornmentProps={{ position: 'start' }}
        disabled={props.disabled}
        {...PickerProps}
      />
    </FormWrapper>
  )
}

/**
 * 年月用のインプット（ MonthPicker のラッパー）
 */
export const MonthField = <T,>(props: DatePickerFieldProps<T>) => {
  const { attr, form, apiError, PickerProps, ...rest } = props

  return (
    <DateField
      attr={attr}
      form={form}
      apiError={apiError}
      PickerProps={{
        views: ['year', 'month'],
        inputFormat: 'yyyy/MM',
        ...PickerProps,
      }}
      {...rest}
    />
  )
}

type TimeFieldProps<T> = ExFieldProps<T> &
  MuiTextFieldProps & {
    PickerProps?: Omit<TimePickerProps<string, Date>, 'value' | 'onChange' | 'renderInput'>
    clearable?: boolean
  }

/**
 * 時間用のインプット（ TimePicker のラッパー ）
 */
export const TimeField = <T,>(props: TimeFieldProps<T>) => {
  const { attr, form, apiError, PickerProps, clearable, ...rest } = props
  const [errorMessage, setErrorMessage] = useState<null | string>(null)
  useEffect(() => {
    setError(apiError, form.modelName, attr, setErrorMessage, props.label)
  }, [apiError])

  return (
    <FormWrapper apiError={apiError} required={props.required} width={props.width}>
      <TimePicker<string, Date>
        value={getValueOnlyString<T>(form, attr)}
        onChange={(date) => handleChangedDateTime(date, 'yyyy-MM-dd HH:mm', form, attr)}
        renderInput={(params) => (
          <MuiTextField
            {...params}
            error={errorMessage !== null}
            helperText={rest?.required && !errorMessage ? '必須' : errorMessage}
            onKeyDown={(e) => e.preventDefault()}
            {...rest}
          />
        )}
        InputProps={{
          endAdornment: (
            <>
              {clearable && (
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation()
                    handleChangedDateTime(null, '', form, attr)
                  }}
                >
                  <Clear fontSize="small" />
                </IconButton>
              )}
            </>
          ),
        }}
        ampm={false}
        minutesStep={5}
        {...PickerProps}
      />
    </FormWrapper>
  )
}

type DateTimeField<T> = ExFieldProps<T> &
  MuiTextFieldProps & {
    PickerProps?: Omit<DateTimePickerProps<string, Date>, 'value' | 'onChange' | 'renderInput'>
    clearable?: boolean
  }
/**
 * 日時用のインプット（ DateTimePicker のラッパー ）
 */
export const DateTimeField = <T,>(props: DateTimeField<T>) => {
  const { attr, form, apiError, PickerProps, clearable, ...rest } = props
  const [errorMessage, setErrorMessage] = useState<null | string>(null)

  useEffect(() => {
    setError(apiError, form.modelName, attr, setErrorMessage, props.label)
  }, [apiError])

  return (
    <FormWrapper apiError={apiError} required={props.required} width={props.width}>
      <DateTimePicker<string, Date>
        value={getValueOnlyString<T>(form, attr)}
        onChange={(date) => handleChangedDateTime(date, 'yyyy-MM-dd HH:mm', form, attr)}
        renderInput={(params) => (
          <MuiTextField
            {...params}
            error={errorMessage !== null}
            helperText={rest?.required && !errorMessage ? '必須' : errorMessage}
            onKeyDown={(e) => e.preventDefault()}
            {...rest}
          />
        )}
        InputProps={{
          endAdornment: (
            <>
              {clearable && (
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation()
                    handleChangedDateTime(null, '', form, attr)
                  }}
                >
                  <Clear fontSize="small" />
                </IconButton>
              )}
            </>
          ),
        }}
        ampm={false}
        minutesStep={5}
        disabled={props.disabled}
        {...PickerProps}
      />
    </FormWrapper>
  )
}

export type SelectData = { value?: string | number; label: string }
/**
 * labelIDはそのフォームが確実にユニークになるような文字列を渡す
 * eachでSelectFieldを表示するような場合は”識別子＋index”のような文字列を渡す
 */
type SelectFieldProps<T> = SelectProps &
  ExFieldProps<T> & {
    data: SelectData[]
    labelId: string
    formControlProps?: FormControlProps
    defaultValue?: string
    includeBlank?: boolean
    blankText?: string
    formStyleProps?: React.CSSProperties
  }

export const SelectField = <T,>(props: SelectFieldProps<T>) => {
  const { attr, form, apiError, defaultValue } = props
  const [errorMessage, setErrorMessage] = useState<null | string>(null)
  const [data, setData] = useState<{ value: string | number; label: string }[]>([])

  useEffect(() => {
    setError(apiError, form.modelName, attr, setErrorMessage, props.label)
  }, [apiError])

  useEffect(() => {
    const copy = Object.assign([], props.data)
    if (props.includeBlank) {
      copy.unshift({ label: props.blankText ? props.blankText : '未選択', value: '' })
    }
    setData(copy)
  }, [props.data])

  useEffect(() => {
    if (defaultValue) {
      form.newUpdateObject(attr, defaultValue)
    }
  }, [])

  const handleChangeForm = (e: SelectChangeEvent<{}>) => {
    // if (e.target.value === '') {
    //   // 空文字だったらattrを完全に削除する
    //   form.update((f) => {
    //     delete f[attr as keyof T]
    //   })
    //   return
    // }

    if (typeof e.target.value == 'string' || typeof e.target.value == 'number') {
      form.newUpdateObject(attr, e.target.value)
    }
  }

  return (
    <FormWrapper apiError={apiError} required={props.required} width={props.width}>
      <FormControl
        variant="outlined"
        style={{ minWidth: props.width || 200, width: '100%', ...props.formStyleProps }}
        {...props.formControlProps}
        error={errorMessage !== null}
      >
        <InputLabel id={props.labelId}>{props.label}</InputLabel>
        <Select
          labelId={props.labelId}
          onChange={(e) => handleChangeForm(e)}
          value={form.getValue(attr) || ''}
          disabled={props.disabled}
          style={props.style}
          defaultValue={defaultValue}
        >
          {data.map((d) => {
            return (
              <MenuItem key={d.label} value={d.value}>
                {d.label}
              </MenuItem>
            )
          })}
        </Select>
        {(props.apiError || props.required) && <FormHelperText>{props.required && !errorMessage ? '必須' : errorMessage}</FormHelperText>}
      </FormControl>
    </FormWrapper>
  )
}

type AutocompleteSelectFieldProps<T> = MuiTextFieldProps &
  ExFieldProps<T> & {
    data: SelectData[]
    labelId: string
    formControlProps?: FormControlProps
    formStyle?: React.CSSProperties
    inputStyle?: React.CSSProperties
    defaultData?: SelectData
  }
const useAutocompleteClasses = makeStyles()({
  inputRoot: { padding: '12px !important', paddingRight: '65px !important' },
  input: { padding: '0 !important' },
})

export const AutocompleteSelectField = <T,>(props: AutocompleteSelectFieldProps<T>) => {
  const { data, attr, form, apiError, defaultData } = props
  const [errorMessage, setErrorMessage] = useState<null | string>(null)
  const [value, setValue] = useState(form.getValue(attr))
  const [inputValue, setInputValue] = useState<string>('')
  const { classes } = useAutocompleteClasses()

  useEffect(() => {
    setError(apiError, form.modelName, attr, setErrorMessage, props.label)
  }, [apiError])

  useEffect(() => {
    // formにvalueをセットし、入力欄にlabelをセットする
    if (defaultData) {
      setValue(defaultData.value)
    } else {
      setValue(form.getValue(attr))
      setInputValue(data.find((d) => d.value === value)?.label || '')
    }
  }, [])

  useEffectSkipFirst(() => {
    form.newUpdateObject(attr, value)
  }, [value])

  const handleChangeForm = (data: SelectData | null) => {
    if (data) {
      setValue(data.value)
      setInputValue(data.label)
    } else {
      // valueがない場合はformにnullをセット
      setValue(null)
    }
  }

  const handleChangeInput = (value: string, reason: AutocompleteInputChangeReason) => {
    // 初期表示で一度フォーカスし、そのままフォーカスを外すと入力が消える不具合があるのでそれを阻止
    if (reason === 'reset' && value === '') return

    setInputValue(value)
    if (value === '') {
      setValue(null)
    }
  }

  return (
    <FormWrapper apiError={apiError} required={props.required} width={props.width}>
      <FormControl
        variant="outlined"
        style={{ minWidth: 200, width: '100%', ...props.formStyle }}
        {...props.formControlProps}
        error={errorMessage !== null}
      >
        <Autocomplete
          id={props.labelId}
          classes={classes}
          options={data}
          getOptionLabel={(option) => option.label}
          onChange={(_: React.ChangeEvent<{}>, data: SelectData | null) => handleChangeForm(data)}
          defaultValue={props.defaultData}
          renderInput={(params) => <MuiTextField {...params} label={props.label} style={props.inputProps} />}
          inputValue={inputValue}
          onInputChange={(_, inputValue, reason) => handleChangeInput(inputValue, reason)}
          style={props.style}
          disabled={props.disabled}
        />
        {(props.apiError || props.required) && <FormHelperText>{props.required && !errorMessage ? '必須' : errorMessage}</FormHelperText>}
      </FormControl>
    </FormWrapper>
  )
}

type ImageInputProps<T> = ExFieldProps<T> & {
  urlAttr: Attribute<T>
  onClickRemove?: () => void
}

export const ImageInput = <T,>(props: ImageInputProps<T>) => {
  const [dataUrl, setDataUrl] = useState<string | null | ArrayBuffer>(null)
  const fileElem = useRef<HTMLInputElement>(null)

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target: HTMLInputElement = e.target as HTMLInputElement
    const file: File | null | undefined = target?.files?.item(0)
    if (file) {
      props.form.newUpdateObject(props.attr, file)

      const fileReader = new FileReader()
      fileReader.onload = (e) => {
        if (e.target) {
          setDataUrl(e.target.result)
        } else {
          setDataUrl(null)
        }
      }
      fileReader.readAsDataURL(file)
    }
  }

  const getImageSrc = (): string | null | undefined => {
    const url = props.form.getValue(props.urlAttr) as string
    if (dataUrl && typeof dataUrl == 'string') {
      // フォーム上で入力したファイルのURL
      return dataUrl
    } else {
      // DB上に保存済みのファイルURL
      return url
    }
  }

  const hancleClickRemove = () => {
    if (dataUrl && typeof dataUrl == 'string') {
      // 同じファイルを入力した際に onChange が効かないため、input の value を削除
      if (fileElem.current?.value) {
        fileElem.current.value = ''
      }
      setDataUrl('')
      props.form.newUpdateObject(props.attr, null)
    } else {
      props.onClickRemove && props.onClickRemove()
    }
  }

  return (
    <Flex alignItems="flex-end" style={{ marginBottom: 5 }}>
      <div>
        <ImageView src={getImageSrc()} maxHeight={100} maxWidth={100} />
      </div>
      <Flex alignItems="center" style={{ paddingBottom: 10, gap: 5 }}>
        <Button color="secondary" component="label" disableElevation>
          画像ファイルを選択
          <input
            onChange={(e) => handleChangeFile(e)}
            type="file"
            accept="image/*,.png,.jpg,.jpeg"
            style={{ display: 'none' }}
            ref={fileElem}
          />
        </Button>
        {props.onClickRemove && getImageSrc() && <CancelIcon color="error" onClick={hancleClickRemove} style={{ cursor: 'pointer' }} />}
      </Flex>
    </Flex>
  )
}

type FileInputProps<T> = ExFieldProps<T> & {
  handleClickRemove?: (index: number) => void
  index: number
  keyword?: string
  accept?: string
}

export const FileInput = <T,>(props: FileInputProps<T>) => {
  const [fileName, setFileName] = useState('ファイルを選択してください')

  const handleChangeFile = (e: any) => {
    const target: HTMLInputElement = e.target as HTMLInputElement
    const file: File | null | undefined = target?.files?.item(0)
    if (file) {
      setFileName(file.name)
      let fileAttr: ParseableValue = props.form.getValue(props.attr)
      fileAttr = file
      props.form.newUpdateObject(props.attr, fileAttr)
    }
  }

  useEffect(() => {
    try {
      const fileAttr: ParseableValue = props.form.getValue(props.attr)
      const fileObj = fileAttr as File
      if (fileObj) {
        setFileName(fileObj.name)
      } else {
        setFileName('ファイルを選択してください')
      }
    } catch (e) {
      console.error(e)
    }
  }, [props.form.object])

  return (
    <div style={{ paddingBottom: 10 }}>
      <input
        accept={props.accept || '*'}
        style={{ display: 'none' }}
        id={`${String(props.attr)}-${props.keyword || ''}-${props.index}`}
        // multiple
        type="file"
        onChange={(e) => handleChangeFile(e)}
      />
      <Flex
        alignItems="center"
        style={{
          backgroundColor: '#ffffff',
          border: '1px rgba(0, 0, 0, 0.23) solid',
          borderRadius: 4,
          paddingLeft: 15,
          paddingRight: 15,
          paddingTop: 5,
          paddingBottom: 5,
        }}
      >
        <label htmlFor={`${String(props.attr)}-${props.keyword || ''}-${props.index}`} style={{ flexGrow: 1 }}>
          <Flex alignItems="center">
            <div style={{ flexGrow: 1 }}>{fileName}</div>
            <Button size="small" component="span" color="secondary">
              ファイル選択
            </Button>
          </Flex>
        </label>
        {props.handleClickRemove ? (
          <CancelIcon color="error" onClick={() => props.handleClickRemove!(props.index)}></CancelIcon>
        ) : (
          <div style={{ width: 24 }}></div>
        )}
      </Flex>
    </div>
  )
}

type FilesInputProps<T> = ExFieldProps<T> & {
  keyword?: string
}

export const FilesInput = <T,>(props: FilesInputProps<T>) => {
  const [dataCount, setDataCount] = useState<number>(1)
  const [attrs] = useState<any[]>(typeof props.attr === 'object' ? [...props.attr] : [props.attr])

  const handleClickRemove = (index: number) => {
    const copy = Object.assign([], props.form.getValue(props.attr))
    copy.splice(index, 1)
    props.form.newUpdateObject(props.attr, copy)
    setDataCount(dataCount - 1)
  }

  useEffect(() => {
    const value = props.form.getValue(props.attr)
    if (value instanceof Array) {
      if (value.length < dataCount) {
        // フォームの配列の要素数がdataCountより小さい場合，空のオブジェクトを代入する
        const copy = [...value]
        const diff = dataCount - copy.length
        for (let i = 0; i < diff; i++) {
          copy.push({})
        }
        props.form.newUpdateObject(props.attr, copy)
      }
    } else {
      // フォームの値が配列でなかった場合は空のオブジェクト入り配列を用意する
      props.form.newUpdateObject(props.attr, [{}])
    }
  }, [dataCount])
  const createFileInput = (): ReactNode[] => {
    const inputs: ReactNode[] = []
    for (let i = 0; i < dataCount; i++) {
      inputs.push(
        <FileInput
          key={`file-input-${i}-${props.keyword || ''}`}
          form={props.form}
          attr={[...attrs, i, 'attachment']}
          index={i}
          handleClickRemove={handleClickRemove}
          keyword={props.keyword}
        />,
      )
    }
    return inputs
  }

  const handleAddFile = () => {
    setDataCount(dataCount + 1)
    props.form.newUpdateObject(props.attr, [...(props.form.getValue(props.attr) as Array<any>), {}])
  }

  return (
    <div style={{ paddingBottom: 10 }}>
      {createFileInput()}
      <Flex justifyContent="flex-end">
        <ThemedButton color="secondary" size="small" onClick={handleAddFile}>
          ファイルを追加
        </ThemedButton>
      </Flex>
    </div>
  )
}

type CheckBoxFieldProps<T> = ExFieldProps<T> & {
  id?: string | number
  index?: string | number
  label: string
  default?: boolean
  labelStyle?: CSSProperties
  containerStyle?: CSSProperties
  style?: CSSProperties
  disabled?: boolean
}

export const CheckBoxField = <T,>(props: CheckBoxFieldProps<T>) => {
  const { form, attr, disabled } = props

  const handleClick = () => {
    form.newUpdateObject(attr, !isChecked())
  }

  const isChecked = (): boolean => {
    const value = form.getValue(attr)
    if (value) {
      return value as boolean
    } else {
      return false
    }
  }

  return (
    <div className="form-group" style={props?.containerStyle}>
      <FormControlLabel
        control={
          <Checkbox
            checked={isChecked()}
            onChange={(e) => {
              handleClick()
            }}
            disabled={disabled}
          />
        }
        label={props.label}
      />
    </div>
  )
}

type SwitchFieldProps<T> = ExFieldProps<T>

export const SwitchField = <T,>(props: SwitchFieldProps<T>) => {
  const { form, attr } = props

  const handleClick = () => {
    form.updateObject(attr, !isChecked())
  }

  const isChecked = (): boolean => {
    const value = form.getValue(attr)
    if (value) {
      return value as boolean
    } else {
      return false
    }
  }

  return (
    <Switch
      edge="end"
      checked={isChecked()}
      onChange={(e) => {
        handleClick()
      }}
    />
  )
}

export type CheckBoxTargetId = string | number | null

type MultipleCheckBoxFieldProps<T> = CheckBoxFieldProps<T> & {
  id: CheckBoxTargetId
  checked: boolean
  style?: CSSProperties
}
export const MultipleCheckBoxField = <T,>(props: MultipleCheckBoxFieldProps<T>) => {
  const { form, id, style } = props

  const handleClickCheckbox = () => {
    const newArray: Array<CheckBoxTargetId> = Object.assign([], form.getValue(props.attr))
    if (!props.checked) {
      newArray.push(id)
    } else {
      newArray.splice(newArray.indexOf(id), 1)
    }
    form.newUpdateObject(props.attr, newArray)
  }

  return (
    <div className="form-group" style={style}>
      <FormControlLabel control={<Checkbox onChange={handleClickCheckbox} checked={props.checked} />} label={props.label} />
    </div>
  )
}

export type DropDownCheckboxItem = {
  value: CheckBoxTargetId
  label: string
}

type AutocompleteMultipleCheckBoxFieldProps<T> = Omit<CheckBoxFieldProps<T>, 'label'> & {
  label: React.ReactNode
  items: DropDownCheckboxItem[]
  id: string
  style?: CSSProperties
}

/**
 * 検索機能つきのチェックボックス複数選択フィールド
 */
export const AutocompleteMultipleCheckBoxField = <T,>(props: AutocompleteMultipleCheckBoxFieldProps<T>) => {
  const { form, style } = props

  const handleClickCheckbox = (newArray: DropDownCheckboxItem[]) => {
    form.newUpdateObject(
      props.attr,
      newArray.map((a) => a.value),
    )
  }

  const isChecked = (id: CheckBoxTargetId): boolean => {
    const value = props.form.getValue(props.attr)
    if (value && value instanceof Array) {
      return value.includes(id as string)
    } else {
      return false
    }
  }

  const getSelectedValues = (): DropDownCheckboxItem[] => {
    const selected = form.getValue(props.attr)
    if (selected && selected instanceof Array) {
      return props.items.filter((i) => selected.includes(i.value))
    } else {
      return []
    }
  }

  return (
    <div className="form-group" style={style}>
      <Autocomplete
        multiple
        id={props.id}
        value={getSelectedValues()}
        options={props.items}
        getOptionLabel={(option) => option.label}
        disableCloseOnSelect
        renderOption={(props, option) => (
          <li {...props}>
            <Checkbox style={{ marginRight: 8 }} checked={isChecked(option.value)} />
            {option.label}
          </li>
        )}
        onChange={(_, newArray) => handleClickCheckbox(newArray)}
        renderInput={(params) => <MuiTextField {...params} variant="outlined" label={props.label} />}
        // getOptionSelected={(option, selected) => {
        //   return option.value === selected.value
        // }}
        style={props.style}
      />
    </div>
  )
}

type DayOfTheWeekInputProps<T> = {
  attr: Attribute<T>
  form: Form<T>
  dayOfTheWeeks?: DayOfTheWeek[]
}

export const DayOfTheWeekInput = <T,>(props: DayOfTheWeekInputProps<T>) => {
  const [dayOfTheWeeks, setDayOfTheWeeks] = useState<DayOfTheWeek[]>(props.dayOfTheWeeks ? props.dayOfTheWeeks : [])

  const arrayToDayOfTheWeekObject = (array: DayOfTheWeek[]) => {
    const obj: { [key: number]: DayOfTheWeek } = {}
    array.forEach((dw: DayOfTheWeek, index: number) => {
      obj[index] = dw
    })
    return obj
  }
  const handleCheck = (dayOfTheWeek: DayOfTheWeek) => {
    if (dayOfTheWeeks.includes(dayOfTheWeek)) {
      const newArray = [...dayOfTheWeeks]
      newArray.splice(newArray.indexOf(dayOfTheWeek), 1)
      setDayOfTheWeeks(newArray)
      props.form.newUpdateObject(props.attr, arrayToDayOfTheWeekObject(newArray))
    } else {
      const newArray = [...dayOfTheWeeks, dayOfTheWeek]
      setDayOfTheWeeks(newArray)
      props.form.newUpdateObject(props.attr, arrayToDayOfTheWeekObject(newArray))
    }
  }

  return (
    <Flex>
      {Object.keys(DayOfTheWeeks).map((dw: string, i) => {
        return (
          <FormControlLabel
            style={{ marginRight: 15 }}
            control={
              <Checkbox
                checked={dayOfTheWeeks.includes(dw as DayOfTheWeek)}
                onChange={(e) => {
                  handleCheck(dw as DayOfTheWeek)
                }}
              />
            }
            label={DayOfTheWeeks[dw as DayOfTheWeek]}
            key={i}
          />
        )
      })}
    </Flex>
  )
}

type RadioButtonFieldProps<T> = ExFieldProps<T> & {
  name: string
  value: string | number | string[]
  label: string
  labelStyle?: CSSProperties
  containerStyle?: CSSProperties
}
export const RadioButtonField = <T,>(props: RadioButtonFieldProps<T>) => {
  const { name, value, label, labelStyle, containerStyle, form, attr } = props

  const handleClickLabel = () => {
    form.newUpdateObject(attr, value)
  }

  const isChecked = (): boolean => {
    // @ts-ignore
    // eslint-disable-next-line eqeqeq
    return form.object[attr] == value
  }

  return (
    <div className="form-group" style={containerStyle}>
      <div className="radio-group">
        <input className="custom-radio" type="radio" name={name} checked={isChecked()} value={value} style={{ marginRight: 4 }} />
        <label className="custom-radio-label" onClick={handleClickLabel} style={labelStyle}>
          {label}
        </label>
      </div>
    </div>
  )
}
