import { Paper, Table, TableContainer, TableHead, TableRow } from '@mui/material'
import { useFetchProofreadEventsWithCurrentUserApprovalApi } from 'api/mypage/proofread_event'
import { ContentHeader, ContentTitle } from 'components/shared/content'
import ImageView from 'components/shared/image_view'
import Pagination from 'components/shared/pagination'
import { ExTableCell, ExTableRow, AccordionTableBody } from 'components/shared/table'
import ThemedButton from 'components/shared/themed_button'
import { ProofreadEventSearchForm } from 'entities/proofread_event'
import { ProofreadPlan } from 'entities/proofread_plan'
import { useForm } from 'rac'
import React, { useEffect } from 'react'
import { StaticContext } from 'react-router'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { MypageRouteHelper } from 'routes/mypage'
import { getTableHeight, HEIGHTS } from 'utils/component_heights'
import { ProofreadApproverViewModel } from 'view_models/proofread_approver'
import { ProofreadEventViewModel } from 'view_models/proofread_event'

const TABLE_PADDING = HEIGHTS.FLOAT_HEADER + HEIGHTS.TITLE_HEADER + HEIGHTS.PAGINATION + HEIGHTS.MARGIN

export type ProofreadApprovalIndexProps = {}

type Props = RouteComponentProps<{}, StaticContext, ProofreadApprovalIndexProps>

export const ProofreadApprovalIndex: React.FC<Props> = (props) => {
  const searchForm = useForm<ProofreadEventSearchForm>({ q: {} })
  const api = useFetchProofreadEventsWithCurrentUserApprovalApi(searchForm)

  useEffect(() => {
    api.execute()
  }, [])

  const handleClickPlanShow = (plan: ProofreadPlan) => {
    props.history.push({ pathname: MypageRouteHelper.proofreadPlanShow(plan.id!) })
  }

  return (
    <>
      <ContentHeader>
        <ContentTitle title="承認申請一覧" />
      </ContentHeader>
      <Paper>
        <TableContainer style={{ maxHeight: getTableHeight(TABLE_PADDING) }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <ExTableCell rowSpan={2} align="center">
                  校正計画名
                </ExTableCell>
                <ExTableCell rowSpan={2} align="center">
                  校正ステータス
                </ExTableCell>
                <ExTableCell rowSpan={2} colSpan={2} align="center">
                  製品名
                </ExTableCell>
                <ExTableCell colSpan={2} align="center">
                  承認ステータス
                </ExTableCell>
                <ExTableCell rowSpan={2} align="center">
                  校正担当者
                </ExTableCell>
                <ExTableCell rowSpan={2} />
              </TableRow>
              <TableRow>
                <ExTableCell align="center">校正計画</ExTableCell>
                <ExTableCell align="center">校正結果</ExTableCell>
              </TableRow>
            </TableHead>

            <AccordionTableBody loading={api.loading} tableName="instruments-table">
              {api.response?.proofreadEvents.map((event) => {
                const vm = new ProofreadEventViewModel(event)
                const planVm = new ProofreadApproverViewModel(event.approvers![0])
                const resultVm = new ProofreadApproverViewModel(event.approvers![1])

                return (
                  <ExTableRow key={event.id}>
                    <ExTableCell align="center">{event.proofreadPlan?.title || ''}</ExTableCell>
                    <ExTableCell align="center">{vm.getStatusLabel()}</ExTableCell>
                    <ExTableCell width={100}>
                      <ImageView maxHeight={100} maxWidth={100} src={vm.getInstrumentImage()} />
                    </ExTableCell>
                    <ExTableCell>{vm.getInstrumentInfo()}</ExTableCell>
                    <ExTableCell align="center">{planVm.getCurrentUserApproveLabel()}</ExTableCell>
                    <ExTableCell align="center">
                      {!event.approvers![1] || event.aasmState !== 'fix_result' ? '-' : resultVm.getCurrentUserApproveLabel()}
                    </ExTableCell>
                    <ExTableCell align="center">{vm.getUser()}</ExTableCell>
                    <ExTableCell align="center">
                      <ThemedButton color="primary" onClick={() => handleClickPlanShow(event.proofreadPlan!)}>
                        詳細
                      </ThemedButton>
                    </ExTableCell>
                  </ExTableRow>
                )
              })}
            </AccordionTableBody>
          </Table>
        </TableContainer>
        <Pagination response={api.response} pageSet={api.pageSet} />
      </Paper>
    </>
  )
}
export default withRouter(ProofreadApprovalIndex)
