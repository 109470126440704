import { BaseEntity } from 'entities'

export type ReservationTimeConstraint = BaseEntity & {
  startTime?: string
  endTime?: string
  price?: number
}

export type ReservationTimeConstraintForm = ReservationTimeConstraint & {
  _destroy?: boolean
}

export const defaultTime = { startTime: '2000-01-01 00:00', endTime: '2000-01-01 00:00' }
