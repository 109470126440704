import { ReservationTimeConstraint } from 'entities/reservation_time_constraint'
import moment from 'moment'

import { ViewModel } from '.'

export class ReservationTimeConstraintViewModel extends ViewModel<ReservationTimeConstraint> {
  public startTime = () => {
    if (this.object.startTime) {
      return moment(this.object.startTime)
    } else {
      return undefined
    }
  }
  public startTimeText = () => {
    const startTime = this.startTime()
    if (startTime) {
      return startTime.format('HH:mm')
    } else {
      return '-'
    }
  }
  public endTime = () => {
    if (this.object.endTime) {
      return moment(this.object.endTime)
    } else {
      return undefined
    }
  }
  public endTimeText = () => {
    const endTime = this.endTime()
    if (endTime) {
      return endTime.format('HH:mm')
    } else {
      return '-'
    }
  }

  public price = () => {
    if (this.object.price) {
      return `¥${this.object.price.toLocaleString()}`
    } else {
      return '設定なし'
    }
  }
}
