import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { Table, TableHead, TableRow, TableCell, Paper, TableContainer, Tooltip } from '@mui/material'
import { useFetchCancelPoliciesApi } from 'api/mypage/cancel_policy'
import { useExportRoomsApi, useFetchRoomsApi } from 'api/mypage/room'
import { NewRoomFormDialog, EditRoomFormDialog } from 'components/mypage/room/room_form_dialog'
import { ContainedIconButton } from 'components/shared/contained_icon_button'
import { ContentHeader, ContentTitle } from 'components/shared/content'
import ImageView from 'components/shared/image_view'
import { SearchBar } from 'components/shared/search_bar'
import { ExTableBody, ExTableCell } from 'components/shared/table'
import ThemedButton from 'components/shared/themed_button'
import { MypageStateContext } from 'contexts/mypage_state_context'
import { Room, RoomSearchForm } from 'entities/room'
import { useForm } from 'rac'
import React, { useContext, useEffect, useState } from 'react'
import { StaticContext, withRouter } from 'react-router'
import { RouteComponentProps } from 'react-router-dom'
import { MypageRouteHelper } from 'routes/mypage'
import { INDEX_TABLE_PADDING, getTableHeight } from 'utils/component_heights'
import { RoomViewModel } from 'view_models/room'

export type RoomIndexProps = {}

type Props = RouteComponentProps<{}, StaticContext, RoomIndexProps>

const RoomIndexPage: React.FC<Props> = (props: Props) => {
  const searchForm = useForm<RoomSearchForm>({})
  const api = useFetchRoomsApi({}, searchForm)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [editRoom, setEditRoom] = useState<Room>({})
  const cancelPolicyIndexApi = useFetchCancelPoliciesApi()
  const { currentUser } = useContext(MypageStateContext)
  const exportApi = useExportRoomsApi(searchForm)

  useEffect(() => {
    api.execute()
    cancelPolicyIndexApi.execute()
  }, [])

  const handleAfterCreate = () => {
    api.execute()
  }

  const handleAfterUpdate = () => {
    api.execute()
  }

  const handleClickEditButton = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, room: Room) => {
    setEditRoom(room)
    setEditDialogOpen(true)
    e.stopPropagation()
  }

  const handleEditClose = () => {
    setEditDialogOpen(false)
  }

  const handleClickInstrumentIndex = (room: Room) => {
    props.history.push({
      pathname: MypageRouteHelper.instrumentIndex(),
      state: { roomId: room.id },
    })
  }

  const handleSearch = () => {
    api.execute()
  }
  const handleClickExport = (format: 'xlsx') => {
    exportApi.execute(format)
  }

  return (
    <>
      <EditRoomFormDialog
        cancelPolicies={cancelPolicyIndexApi.response?.cancelPolicies}
        onComplete={handleAfterUpdate}
        room={editRoom}
        open={editDialogOpen}
        onClose={handleEditClose}
      />
      <ContentHeader
        subHeader={
          <>
            <ThemedButton color="secondary" onClick={() => handleClickExport('xlsx')} style={{ marginLeft: 10 }}>
              Excel出力
            </ThemedButton>
          </>
        }
      >
        <ContentTitle title="ルーム一覧">
          {currentUser.authority?.room && (
            <NewRoomFormDialog cancelPolicies={cancelPolicyIndexApi.response?.cancelPolicies} onComplete={handleAfterCreate} />
          )}
        </ContentTitle>
        <SearchBar<RoomSearchForm> form={searchForm} searchAttr="searchText" placeholder="部屋名で検索" onSubmit={handleSearch} />
      </ContentHeader>

      <Paper>
        <TableContainer style={{ height: getTableHeight(INDEX_TABLE_PADDING) }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <ExTableCell width={150}></ExTableCell>
                <TableCell>登録日</TableCell>
                <TableCell>部屋名</TableCell>
                <TableCell align="center">専有</TableCell>
                <TableCell align="center">
                  <div>カレンダー</div>
                  <div>予約</div>
                </TableCell>
                <TableCell></TableCell>
                {currentUser.authority?.room && <TableCell></TableCell>}
              </TableRow>
            </TableHead>
            <ExTableBody loading={api.loading}>
              {api.response?.rooms.map((room: Room, idx: number) => {
                const roomVM = new RoomViewModel(room)
                return (
                  <TableRow key={idx}>
                    <ExTableCell width={100} align="center">
                      <ImageView maxHeight={100} maxWidth={100} src={room.imageUrl} />
                    </ExTableCell>
                    <ExTableCell width={120}>{roomVM.createdAt().format('Y/MM/DD')}</ExTableCell>
                    <ExTableCell>{room.name}</ExTableCell>
                    <ExTableCell align="center" width={80}>
                      {roomVM.proprietaryFlag()}
                    </ExTableCell>
                    <ExTableCell width={150} align="center">
                      {room.canReserve ? (
                        <ContainedIconButton
                          color="primary"
                          onClick={(e) => {
                            props.history.push(MypageRouteHelper.roomShow(room.id!))
                          }}
                        >
                          <CalendarTodayIcon style={{ color: 'white', fontSize: 20 }} />
                        </ContainedIconButton>
                      ) : (
                        '予約不可'
                      )}
                    </ExTableCell>
                    <ExTableCell width={130} align="center">
                      <ThemedButton color="secondary" onClick={(e) => handleClickInstrumentIndex(room)}>
                        機器一覧
                      </ThemedButton>
                    </ExTableCell>
                    {currentUser.authority?.room && (
                      <ExTableCell width={60} align="center">
                        <Tooltip title={!roomVM.isRoomOwner(currentUser.laboratory!) ? '他社のルームは編集出来ません' : ''}>
                          <div>
                            <ThemedButton
                              disabled={!roomVM.isRoomOwner(currentUser.laboratory!)}
                              color="secondary"
                              onClick={(e) => handleClickEditButton(e, room)}
                            >
                              編集
                            </ThemedButton>
                          </div>
                        </Tooltip>
                      </ExTableCell>
                    )}
                  </TableRow>
                )
              })}
            </ExTableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  )
}

export default withRouter(RoomIndexPage)
