import { usePostInquiryApi } from 'api/mypage/inquiry'
import { Flex } from 'components/shared/flex'
import { MultipleCheckBoxField, StringField } from 'components/shared/form/input'
import Modal, { ModalState } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { Dealer } from 'entities/dealer'
import { InquiryForm } from 'entities/inquiry'
import { useForm } from 'rac'
import React, { useContext } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useEffectSkipFirst } from 'utils/hooks'

type InquiryFormModalProps = {
  modalState: ModalState
  dealers: Dealer[]
  onComplete: (id: number) => void
}

export const InquiryFormModal: React.FC<InquiryFormModalProps> = (props: InquiryFormModalProps) => {
  const { modalState, dealers, onComplete } = props
  const form = useForm<InquiryForm>({ dealerIds: [] }, 'Inquiry')
  const globalState = useContext(GlobalStateContext)
  const apiSet = usePostInquiryApi()
  const { classes } = useStyles()

  const handleSubmit = () => {
    if (form.object.dealerIds!.length > 3) {
      globalState.setNotificationMessage({ body: '3社以上の選択はできません', colorType: 'error' })
      return
    }
    apiSet.execute(form)
  }

  useEffectSkipFirst(() => {
    globalState.setLoading(apiSet.loading)
    if (!apiSet.isSuccess()) return

    onComplete(apiSet.response.inquiry.id!)
    form.resetForm()
    modalState.setOpen(false)
  }, [apiSet.loading])

  return (
    <>
      <Modal
        size="md"
        modalState={modalState}
        modalId="new-inquiry-form"
        title="新しい問合せ"
        footer={
          <>
            <ThemedButton onClick={() => modalState.setOpen(false)} color="secondary">
              キャンセル
            </ThemedButton>
            <ThemedButton onClick={handleSubmit}>パートナーへ送信</ThemedButton>
          </>
        }
      >
        <p className={classes.explain}>
          宛先をお選びください。複数のパートナーにまとめて問合せることが可能です。
          <br />
          最大3社までお選びいただけます。
        </p>
        <div>
          <Flex flexWrap="wrap">
            {dealers.map((dealer, idx) => {
              return (
                <React.Fragment key={idx}>
                  <MultipleCheckBoxField
                    label={`${dealer.shortenedName} ${dealer.branch}`}
                    form={form}
                    attr="dealerIds"
                    checked={form.object.dealerIds!.includes(dealer.id!)}
                    index={idx}
                    id={dealer.id!}
                    default={false}
                  />
                </React.Fragment>
              )
            })}
          </Flex>
          <Flex className={classes.marginBottom}>
            <StringField form={form} attr="subject" placeholder="件名" />
          </Flex>
          <Flex className={classes.marginBottom}>
            <StringField form={form} attr="body" placeholder="お問合せ内容" multiline />
          </Flex>
        </div>
      </Modal>
    </>
  )
}

const useStyles = makeStyles()({
  subject: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderBottom: 'none',
  },
  explain: {
    marginBottom: '20px',
  },
  marginBottom: {
    marginBottom: '10px',
  },
})
