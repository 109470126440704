import { DealerAuthority } from 'entities/dealer_authority'
import { BaseEntity } from 'entities/index'
import { InstrumentOwnerableType } from 'entities/instrument'

export type Dealer = BaseEntity & {
  name?: string
  shortenedName?: string
  branch?: string
  zip?: string
  address?: string
  building?: string
  phone?: string
  fax?: string
  logo?: string
  corporateStamp?: string
  presidentStamp?: string
  publisher?: string
  state?: number
  basicContractDocument?: number
  productLicenseAgreement?: number
  nondisclosureAgreement?: number
  plan?: number
  demo?: boolean
  contractedAt?: string
  /** 購買管理契約 */
  purchaseContractStatus?: number
  authority?: DealerAuthority
}

export type DealerForm = Dealer

export type DealerSearchForm = {
  // nameCont: string
}

export const dealerValidation = {}

/**
 * 顧客フィルター
 */
export type OwnerFilter = {
  ownerableId?: number | ''
  ownerableType?: InstrumentOwnerableType | ''
  disabled?: boolean
  ownerableString?: string // ownerableId と ownerableType を SEPARATOR で区切った文字列
}
export const SEPARATOR = '---'