import { AxiosHttpClient } from 'api/axios'
import { useRepruaPatchApi } from 'api/shared_api_hooks'
import { BulkUpdateUserAuthorityForm } from 'entities/user_authority'
import { ApiSet, BaseResponse } from 'rac'

type UsersResponse = BaseResponse

/**
 * 更新
 */
export function usePatchUserAuthorityManagerApi(): ApiSet<UsersResponse> & {
  execute: (userAuthorityForm: BulkUpdateUserAuthorityForm, laboratoryId: number) => void
} {
  const api = useRepruaPatchApi<UsersResponse, BulkUpdateUserAuthorityForm>(new AxiosHttpClient('user'), { initialResponse: {} })

  const execute = (userAuthorityForm: BulkUpdateUserAuthorityForm, laboratoryId: number) => {
    const path = `manager/user_authorities/${laboratoryId}`
    api.execute(path, userAuthorityForm)
  }

  return { ...api, execute }
}
