import ThemedButton from 'components/shared/themed_button'
import { PageSet } from 'rac'
import React from 'react'

type ViewMoreButtonProps = {
  pageSet: PageSet
  count: number
  totalCount: number
  label?: string
}
export const ViewMoreButton: React.FC<ViewMoreButtonProps> = (props) => {
  const { pageSet, count, totalCount } = props

  const handleClick = () => {
    pageSet.setPage(pageSet.page + 1)
  }
  return (
    <ThemedButton color="secondary" disabled={count >= totalCount} onClick={handleClick}>
      {props.label || 'もっと見る'}
    </ThemedButton>
  )
}

export default ViewMoreButton
