import { AxiosHttpClient } from 'api/axios'
import { useRepruaIndexApi, useRepruaShowApi } from 'api/shared_api_hooks'
import { ID } from 'entities'
import { Dealer } from 'entities/dealer'
import { ApiSet, BaseResponse, IndexApiSet } from 'rac'

type DealersResponse = BaseResponse & {
  dealers: Dealer[]
}
/**
 * 一覧
 */
export function useFetchAdminDealersApi(): IndexApiSet<DealersResponse> & { execute: () => void } {
  const api = useRepruaIndexApi<DealersResponse, {}>(new AxiosHttpClient('admin'), {
    initialResponse: { dealers: [] },
    initialState: { perPage: 1000 },
  })

  const execute = () => {
    const path = 'dealers'
    api.execute(path)
  }

  return { ...api, execute }
}

type DealerResponse = BaseResponse & {
  dealer: Dealer
}
/**
 * 取得
 */
export function useFetchAdminDealerApi(): ApiSet<DealerResponse> & { execute: (id: ID) => void } {
  const api = useRepruaShowApi<DealerResponse, { id: ID }>(new AxiosHttpClient('admin'), {
    initialResponse: { dealer: {} },
  })

  const execute = (id: ID) => {
    const path = `dealers/${id}`
    api.execute(path, { id: id })
  }

  return { ...api, execute }
}
