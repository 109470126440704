import { AxiosHttpClient } from 'api/axios'
import { useRepruaDeleteApi, useRepruaIndexApi, useRepruaPostApi, useRepruaShowApi } from 'api/shared_api_hooks'
import { ID, PagingResponse } from 'entities'
import { ProofreadPlan, ProofreadPlanForm, ProofreadPlanSearchForm } from 'entities/proofread_plan'
import { ApiSet, BaseResponse, Form, IndexApiSet } from 'rac'
import { useRecoilState } from 'recoil'
import { ownerFilterAtom } from 'recoil/partner/owner_filter'
import { useEffectSkipFirst } from 'utils/hooks'

export type ProofreadPlansResponse = PagingResponse & {
  proofreadPlans: ProofreadPlan[]
}
export type ProofreadPlanResponse = BaseResponse & {
  proofreadPlan: ProofreadPlan
}

export type PartnerFetchProofreadPlansApiSet = IndexApiSet<ProofreadPlansResponse> & { execute: () => void }
type CreatedBy = 'laboratory' | 'supplier'

/**
 * 一覧
 */
export function usePartnerFetchProofreadPlansApi(createdBy: CreatedBy): PartnerFetchProofreadPlansApiSet {
  // 顧客フィルターを適用する
  const [filter] = useRecoilState(ownerFilterAtom)
  const api = useRepruaIndexApi<ProofreadPlansResponse, ProofreadPlanSearchForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { proofreadPlans: [], totalCount: 0 },
  })

  const execute = () => {
    const path = `proofread_plans?created_by=${createdBy}`
    api.execute(path, { params: filter })
  }

  useEffectSkipFirst(() => {
    execute()
  }, [api.pageSet.page, api.pageSet.perPage, api.orderSet.order, api.orderSet.orderBy, filter])

  return { ...api, execute }
}

/**
 * 取得
 */
export function usePartnerFetchProofreadPlanApi(id: number): ApiSet<ProofreadPlanResponse> & { execute: () => void } {
  const api = useRepruaShowApi<ProofreadPlanResponse, undefined>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { proofreadPlan: {} },
  })

  const execute = () => {
    const path = `proofread_plans/${id}`
    api.execute(path)
  }

  return { ...api, execute }
}

/**
 * 登録
 */
export function usePartnerPostProofreadPlanApi(
  form: Form<ProofreadPlanForm>,
): ApiSet<ProofreadPlanResponse> & {
  execute: () => void
} {
  const api = useRepruaPostApi<ProofreadPlanResponse, ProofreadPlanForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { proofreadPlan: {} },
  })

  const execute = () => {
    const path = 'proofread_plans'
    api.execute(path, form)
  }

  return { ...api, execute }
}

/**
 * 削除
 */
export function usePartnerDeleteProofreadPlanApi(): ApiSet<ProofreadPlanResponse> & { execute: (id: ID) => void } {
  const api = useRepruaDeleteApi<ProofreadPlanResponse>(new AxiosHttpClient('dealer_user'), { initialResponse: { proofreadPlan: {} } })

  const execute = (id: ID) => {
    const path = `proofread_plans/${id}`
    api.execute(path)
  }

  return { ...api, execute }
}
