import { AxiosHttpClient } from 'api/axios'
import { useRepruaIndexApi } from 'api/shared_api_hooks'
import { ID, PagingResponse } from 'entities'
import { DefectReport, DefectReportSearchForm } from 'entities/defect_report'
import { Instrument } from 'entities/instrument'
import { IndexApiSet } from 'rac'
import { useEffectSkipFirst } from 'utils/hooks'

export type DefectReportsResponse = PagingResponse & {
  defectReports: DefectReport[]
  instrument: Instrument
}

/**
 * 一覧
 */
export type PartnerFetchInstrumentDefectReportsApiSet = IndexApiSet<DefectReportsResponse> & { execute: () => void }
export function usePartnerFetchInstrumentDefectReportsApi(instrumentId?: ID): PartnerFetchInstrumentDefectReportsApiSet {
  const api = useRepruaIndexApi<DefectReportsResponse, DefectReportSearchForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { defectReports: [], totalCount: 0, instrument: {} },
  })

  const execute = () => {
    const path = `instruments/${instrumentId}/defect_reports`
    api.execute(path)
  }

  useEffectSkipFirst(() => {
    execute()
  }, [api.pageSet.page, api.pageSet.perPage, api.orderSet.order, api.orderSet.orderBy])

  return { ...api, execute }
}
