import { AxiosHttpClient } from 'api/axios'
import { useRepruaIndexApi, useRepruaPostApi } from 'api/shared_api_hooks'
import { SharedAssetForm } from 'components/mypage/laboratory/shared_assets_form_modal'
import { ID } from 'entities'
import { IndexApiSet, ApiSet, Form, BaseResponse } from 'rac'

export type AssetRelationsResponse = BaseResponse & {
  sharedAssets: { roomId: ID; instrumentIds: ID[] }[]
}

/**
 * 一覧
 */
export function useFetchAssetRelationsApi(): IndexApiSet<AssetRelationsResponse> & { execute: (laboratoryId: ID) => void } {
  const api = useRepruaIndexApi<AssetRelationsResponse, { laboratoryId: ID }>(new AxiosHttpClient('user'), {
    initialResponse: { sharedAssets: [] },
  })

  const execute = (laboratoryId: ID) => {
    const path = 'manager/asset_relations'
    api.execute(path, { params: { laboratoryId: laboratoryId } })
  }

  return { ...api, execute }
}

export type AssetRelationResponse = BaseResponse & {}

export function usePostAssetRelationApi(): ApiSet<AssetRelationResponse> & { execute: (form: Form<SharedAssetForm>) => void } {
  const api = useRepruaPostApi<AssetRelationResponse, SharedAssetForm>(new AxiosHttpClient('user'), { initialResponse: {} })

  const execute = (form: Form<SharedAssetForm>) => {
    const path = 'manager/asset_relations'
    api.execute(path, form)
  }

  return { ...api, execute }
}
