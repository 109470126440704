import { Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { useFetchLaboratoryApi } from 'api/mypage/laboratory'
import { SharedAssetsFormModal } from 'components/mypage/laboratory/shared_assets_form_modal'
import { UserListBox } from 'components/mypage/setting/user_list_box'
import { ContentHeader, ContentTitle } from 'components/shared/content'
import { GlobalStateContext } from 'contexts/global_state_context'
import { MypageStateContext } from 'contexts/mypage_state_context'
import { Instrument } from 'entities/instrument'
import { Laboratory } from 'entities/laboratory'
import React, { useContext, useEffect, useState } from 'react'
import { StaticContext } from 'react-router'
import { RouteComponentProps, withRouter } from 'react-router-dom'

type LaboratoryShowProps = StaticContext & {}
type Props = RouteComponentProps<{}, LaboratoryShowProps>

const LaboratoryShow: React.FC<Props> = (props: Props) => {
  const globalState = useContext(GlobalStateContext)
  const laboratoryApi = useFetchLaboratoryApi()
  const [laboratory, setLaboratory] = useState<Laboratory>()
  const { reloadUser } = useContext(MypageStateContext)
  const roomsIndex: number[] = []

  useEffect(() => {
    laboratoryApi.execute(getParams().id, true)
  }, [])

  // ローディングプログレスの表示
  useEffect(() => {
    globalState.setLoading(laboratoryApi.loading)
    if (laboratoryApi.isSuccess()) {
      setLaboratory(laboratoryApi.response.laboratory)
    }
  }, [laboratoryApi.loading])

  // 権限 or 共有資産の更新後に実行
  const handleCompleteUpdate = () => {
    laboratoryApi.execute(getParams().id, true)
    reloadUser()
  }

  const getParams = () => props.match.params as { id: number }

  return (
    <>
      <ContentHeader>
        <ContentTitle title={`管理ラボ詳細 - ${laboratory?.name || ''}`}></ContentTitle>
      </ContentHeader>

      <Grid container spacing={3} style={{ padding: '0 10px' }}>
        <Grid item xs={6}>
          <UserListBox users={laboratory?.users || []} onComplete={handleCompleteUpdate} isManager={true} />
        </Grid>
        <Grid item xs={6}>
          <Box>
            <Box display={'flex'} alignItems={'center'} marginBottom={1}>
              <h3 style={{ marginRight: 10 }}>共有資産</h3>
              <SharedAssetsFormModal onComplete={handleCompleteUpdate} laboratoryId={getParams().id} />
            </Box>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {laboratory?.sharedInstruments &&
                        laboratory.sharedInstruments.map((instrument: Instrument, idx: number) => {
                          const found = roomsIndex.find((id) => id === instrument.roomId)
                          if (!found) roomsIndex.push(instrument.roomId!)

                          return <SharedInstrumentsTableRow key={idx} instrument={instrument} found={!!found} />
                        })}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

type SharedInstrumentsTableRowProps = {
  instrument: Instrument
  found: boolean
}
/**
 * 共有資産の表示
 */
export const SharedInstrumentsTableRow: React.FC<SharedInstrumentsTableRowProps> = (props) => {
  return !props.found ? (
    <>
      <div style={{ marginTop: 6 }}>{props.instrument.roomName}</div>
      <span style={{ fontSize: 13 }}>{props.instrument.instrumentBasicInfo?.name} / </span>
    </>
  ) : (
    <span style={{ fontSize: 13 }}>{props.instrument.instrumentBasicInfo?.name} / </span>
  )
}

export default withRouter(LaboratoryShow)
