import { FormControlLabel } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { usePostRoomApi, usePatchRoomApi } from 'api/mypage/room'
import { Flex } from 'components/shared/flex'
import { StringField, NumberField, SelectField, ImageInput } from 'components/shared/form/input'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { MypageStateContext } from 'contexts/mypage_state_context'
import { CancelPolicy } from 'entities/cancel_policy'
import { getTimeSelectObject } from 'entities/reservation_term'
import { RoomForm, Room } from 'entities/room'
import { ApiError, Form, useForm } from 'rac'
import React, { useState, useEffect, useContext } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'
import { InstrumentViewModel } from 'view_models/instrument'
import { ReservationTermViewModel } from 'view_models/reservation_term'

import { CheckboxAccordion } from '../../shared/checkbox_accordion'

type DialogFormContentProps = {
  form: Form<RoomForm>
  error: ApiError
  cancelPolicies?: CancelPolicy[]
}

export const DialogFormContent: React.FC<DialogFormContentProps> = (props) => {
  const { form, error, cancelPolicies } = props

  return (
    <DialogContent>
      <Flex>
        <ImageInput attr={['imageAttributes', 'attachment']} form={form} urlAttr="imageUrl" />
      </Flex>
      <Flex>
        <StringField label="部屋名" attr="name" form={form} apiError={error} required />
      </Flex>
      <Flex>
        <StringField label="部屋の説明等" attr="note" form={form} apiError={error} multiline />
      </Flex>
      <CheckboxAccordion
        label="部屋の予約管理をする"
        onChangeChecked={(checked) => {
          form.update((f) => (f.canReserve = checked))
        }}
        defaultChecked={form.object.canReserve}
      >
        <Flex>
          <FormControlLabel
            control={
              <Checkbox
                checked={form.object.proprietaryFlag}
                onChange={(_, checked) => {
                  form.update((f) => (f.proprietaryFlag = checked))
                }}
              />
            }
            label="予約時は専有状態となる"
          />
        </Flex>
        <Flex>
          <SelectField
            form={form}
            labelId="startTime-label"
            attr={['reservationTermAttributes', 'startTime']}
            label="貸出開始時間"
            data={getTimeSelectObject()}
            defaultValue={new ReservationTermViewModel(form.object.reservationTermAttributes!).getStartAtText()}
            apiError={error}
            required
          />
          <SelectField
            form={form}
            labelId="startTime-label"
            attr={['reservationTermAttributes', 'endTime']}
            label="貸出終了時間"
            data={getTimeSelectObject()}
            defaultValue={new ReservationTermViewModel(form.object.reservationTermAttributes!).getEndAtText()}
            apiError={error}
            required
          />
        </Flex>
        <Flex>
          <NumberField
            label="貸出単価"
            attr={['reservationTermAttributes', 'price']}
            form={form}
            apiError={error}
            inputProps={{ step: '5' }}
          />

          <SelectField
            form={form}
            labelId="unit-label"
            attr={['reservationTermAttributes', 'unit']}
            label="貸出単位"
            data={[
              { value: 'minute', label: '分あたり' },
              { value: 'hour', label: '時間あたり' },
              { value: 'day', label: '1日あたり' },
            ]}
            apiError={error}
            required={!!form.object.reservationTermAttributes?.price}
          />
        </Flex>
        <Flex>
          <NumberField label="予約単位(分)" attr={['reservationTermAttributes', 'section']} form={form} apiError={error} />
        </Flex>
        {cancelPolicies && cancelPolicies.length > 0 && (
          <SelectField
            form={form}
            labelId="cancel-policies-label"
            attr="cancelPolicyId"
            label="キャンセルポリシー"
            data={cancelPolicies.map((cancelPolicy) => {
              return {
                value: cancelPolicy.id,
                label: `${new InstrumentViewModel(form.object).fixDeadline(cancelPolicy.deadline)}前までキャンセル可能`,
              }
            })}
            includeBlank={true}
            apiError={error}
          />
        )}
      </CheckboxAccordion>
    </DialogContent>
  )
}

type NewRoomFormDialogProps = {
  onComplete: () => void
  cancelPolicies?: CancelPolicy[]
}

export const NewRoomFormDialog: React.FC<NewRoomFormDialogProps> = (props: NewRoomFormDialogProps) => {
  const [open, setOpen] = useState(false)
  const roomForm = useForm<RoomForm>({ imageAttributes: {}, reservationTermAttributes: { section: 5 } }, 'room')
  const api = usePostRoomApi()
  const globalState = useContext(GlobalStateContext)
  const { currentUser } = useContext(MypageStateContext)

  useEffectSkipFirst(() => {
    setOpen(false)
    props.onComplete()
    roomForm.set({ reservationTermAttributes: { section: 5 } })
  }, [api.response.room])

  useEffect(() => {
    globalState.setLoading(api.loading)
  }, [api.loading])

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = () => {
    api.execute(roomForm)
  }

  return (
    <div>
      {currentUser.authority?.room && (
        <ThemedButton color="primary" onClick={handleOpen}>
          新規登録
        </ThemedButton>
      )}
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle>ルーム登録</DialogTitle>
        <DialogFormContent form={roomForm} error={api.apiError} cancelPolicies={props.cancelPolicies} />
        <DialogActions>
          <ThemedButton onClick={handleClose} color="secondary">
            閉じる
          </ThemedButton>
          <ThemedButton onClick={handleSubmit} color="success">
            登録
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}

type EditRoomFormDialogProps = {
  open: boolean
  room: Room
  onComplete: () => void
  onClose: () => void
  cancelPolicies?: CancelPolicy[]
}

export const EditRoomFormDialog: React.FC<EditRoomFormDialogProps> = (props: EditRoomFormDialogProps) => {
  const { open, room, cancelPolicies, onComplete, onClose } = props
  const api = usePatchRoomApi()

  const propsToRoomForm = (): RoomForm => {
    const reservationTerm = room.reservationTerm ? room.reservationTerm : {}
    return Object.assign(
      {},
      {
        ...room,
        imageAttributes: {},
        reservationTermAttributes: {
          section: 5,
          ...reservationTerm,
        },
      },
    )
  }
  const roomForm = useForm<RoomForm>(propsToRoomForm(), 'room')

  useEffect(() => {
    roomForm.set(() => propsToRoomForm())
  }, [room])

  useEffectSkipFirst(() => {
    if (!(api.apiError.messages.length > 0)) {
      onClose()
      onComplete()
    }
  }, [api.response.room])

  const handleSubmit = () => {
    api.execute(roomForm.object)
  }

  return (
    <div>
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle>ルーム編集</DialogTitle>
        <DialogFormContent form={roomForm} error={api.apiError} cancelPolicies={cancelPolicies} />
        <DialogActions>
          <ThemedButton onClick={onClose} color="secondary">
            閉じる
          </ThemedButton>
          <ThemedButton onClick={handleSubmit} color="success">
            更新
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}
