import { useFetchExColumnsApi } from 'api/shared/ex_columns'
import { ContentHeader, ContentTitle } from 'components/shared/content'
import { EditExColumnDialog, NewExColumnDialog } from 'components/shared/ex_attribute/ex_column_dialog'
import { ExColumnTable } from 'components/shared/ex_attribute/ex_column_table'
import { Flex } from 'components/shared/flex'
import { useModal } from 'components/shared/modal'
import { MypageStateContext } from 'contexts/mypage_state_context'
import { ExColumn, ExtensionClass } from 'entities/ex_column'
import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'

const clientType = 'user'

export const ExColumnIndex: React.VFC = () => {
  const mypageState = useContext(MypageStateContext)
  const currentUser = mypageState.currentUser
  const indexApiSet = useFetchExColumnsApi(clientType)
  const newModalState = useModal()
  const editModalState = useModal()
  const [selectedColumn, setSelectedColumn] = useState<ExColumn | null>(null)
  const [selectedExtensionClass, setSelectedExtensionClass] = useState<ExtensionClass>('InstrumentInfoAttribute')

  const handleClickNewButton = (extensionClass: ExtensionClass) => {
    setSelectedExtensionClass(extensionClass)
    newModalState.setOpen(true)
  }

  const handleClickEditButton = (column: ExColumn) => {
    setSelectedColumn(column)
    editModalState.setOpen(true)
  }

  const handleCompleteCreate = () => {
    newModalState.setOpen(false)
    indexApiSet.execute()
  }

  const handleCompleteUpdate = () => {
    editModalState.setOpen(false)
    indexApiSet.execute()
  }

  useEffect(() => {
    indexApiSet.execute()
  }, [])

  return (
    <>
      <NewExColumnDialog
        clientType={clientType}
        modalState={newModalState}
        onComplete={handleCompleteCreate}
        extensionClass={selectedExtensionClass}
      />
      <EditExColumnDialog clientType={clientType} modalState={editModalState} onComplete={handleCompleteUpdate} exColumn={selectedColumn} />
      <ContentHeader>
        <ContentTitle title="管理情報" />
      </ContentHeader>
      <Flex>
        <ExColumnTable
          title="製品管理情報"
          extensionClass="InstrumentInfoAttribute"
          exColumns={indexApiSet.response.exColumns.filter((column) => column.extensionClass === 'InstrumentInfoAttribute')}
          loading={indexApiSet.loading}
          onClickNewButton={handleClickNewButton}
          onClickEditButton={handleClickEditButton}
          editable={currentUser.authority?.instrument}
          open
        />
        <ExColumnTable
          title="校正管理情報"
          extensionClass="ProofreadEvent"
          exColumns={indexApiSet.response.exColumns.filter((column) => column.extensionClass === 'ProofreadEvent')}
          loading={indexApiSet.loading}
          onClickNewButton={handleClickNewButton}
          onClickEditButton={handleClickEditButton}
          editable={currentUser.authority?.proofread}
          open
        />
      </Flex>
    </>
  )
}

export default withRouter(ExColumnIndex)
