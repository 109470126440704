import { Stack, Typography } from '@mui/material'
import { HttpClientType } from 'api/axios'
import DataBlock from 'components/shared/data/data_block'
import DataCard from 'components/shared/data/data_card'
import DataLabel from 'components/shared/data/data_label'
import { ExAttributeFormDialog } from 'components/shared/ex_attribute/ex_attribute_form_dialog'
import ExAttributesInfoModal from 'components/shared/ex_attribute/ex_attributes_info_modal'
import { Flex } from 'components/shared/flex'
import { useModal, useMultipleModal } from 'components/shared/modal'
import StatusLabel from 'components/shared/status_label'
import ThemedButton from 'components/shared/themed_button'
import { ExAttribute } from 'entities/ex_attribute'
import { Instrument } from 'entities/instrument'
import { InstrumentInfoAttribute } from 'entities/instrument_info_attribute'
import React from 'react'

type InstrumentInfoAttributeExColumnDataCardProps = {
  clientType: HttpClientType
  instrumentInfoAttribute?: InstrumentInfoAttribute
  instrument?: Instrument
  exAttributes: ExAttribute[]
  dealerExAttributes?: ExAttribute[]
  onUpdate: () => void
  editable?: boolean
}
const InstrumentInfoAttributeExColumnDataCard: React.FC<InstrumentInfoAttributeExColumnDataCardProps> = (props) => {
  const instrumentOwnerableId = props.instrument?.ownerableId
  const instrumentOwnerableType = props.instrument?.ownerableType
  const attributeModal = useMultipleModal<InstrumentInfoAttribute>()
  const dealerAttrsModalState = useModal()
  const sharedExAttributes = props.exAttributes?.filter((ex) => !ex.exColumn.clientableId && !ex.exColumn.clientableType) || []
  const clientExAttributes =
    props.exAttributes?.filter(
      (ex) => ex.exColumn.clientableId === instrumentOwnerableId && ex.exColumn.clientableType === instrumentOwnerableType,
    ) || []

  const handleUpdate = () => {
    attributeModal.closeModal()
    props.onUpdate()
  }

  return (
    <DataCard>
      <ExAttributeFormDialog
        clientType={props.clientType}
        modalSet={attributeModal}
        onComplete={handleUpdate}
        extensionClass="InstrumentInfoAttribute"
        exAttributes={props.exAttributes}
        dealerClient={{ clientableId: instrumentOwnerableId, clientableType: instrumentOwnerableType }}
      />
      <ExAttributesInfoModal modalState={dealerAttrsModalState} exAttributes={props.dealerExAttributes || []} />
      <Flex alignItems="center" justifyContent="space-between">
        <Typography variant="h6">製品管理情報</Typography>
        <Stack direction="row" spacing={1}>
          {props.exAttributes.length > 0 && props.editable && (
            <ThemedButton color="primary" onClick={() => attributeModal.openModal(props.instrumentInfoAttribute || {})}>
              編集
            </ThemedButton>
          )}
        </Stack>
      </Flex>
      {props.exAttributes.length > 0 || (props.dealerExAttributes && props.dealerExAttributes.length > 0) ? (
        <>
          {sharedExAttributes.length > 0 && (
            <>
              {sharedExAttributes.map((ex, idx) => {
                let value = ex.exAttributeValue.value
                if (ex.exColumn.inputType === 'price' && value) {
                  // 金額タイプなら桁区切り
                  value = `¥${parseInt(value).toLocaleString()}`
                }
                return (
                  <DataBlock noBorder={sharedExAttributes.length - 1 === idx} key={idx}>
                    <DataLabel
                      label={
                        <>
                          {ex.exColumn.name || '-'}
                          {props.clientType === 'dealer_user' && ex.exColumn.shared && (
                            <StatusLabel text="ラボ閲覧可" color="gray" style={{ marginLeft: 10 }} />
                          )}
                        </>
                      }
                      value={value || '-'}
                    />
                  </DataBlock>
                )
              })}
            </>
          )}
          {clientExAttributes.length > 0 && (
            <>
              <Typography variant="h6">顧客別管理情報</Typography>
              {clientExAttributes.map((ex, idx) => {
                let value = ex.exAttributeValue.value
                if (ex.exColumn.inputType === 'price' && value) {
                  // 金額タイプなら桁区切り
                  value = `¥${parseInt(value).toLocaleString()}`
                }
                return (
                  <DataBlock noBorder={clientExAttributes.length - 1 === idx} key={idx}>
                    <DataLabel label={ex.exColumn.name} value={value || '-'} />
                  </DataBlock>
                )
              })}
            </>
          )}
          {props.dealerExAttributes && props.dealerExAttributes.length > 0 && (
            <>
              <Typography variant="h6">代理店の管理情報</Typography>
              {props.dealerExAttributes.map((ex, idx) => {
                let value = ex.exAttributeValue.value;
                if (ex.exColumn.inputType === 'price' && value) {
                  value = `¥${parseInt(value).toLocaleString()}`;
                }
                return (
                  <DataBlock
                    noBorder={props.exAttributes.length - 1 === idx}
                    key={`column-${ex.exColumn.id}`}
                    id={`column-${ex.exColumn.id}`}
                  >
                    <DataLabel label={ex.exColumn.name} value={ex.exAttributeValue.value || '-'} />
                  </DataBlock>
                );
              })}
            </>
          )}
        </>
      ) : (
        <>
          <>製品管理情報が未設定です</>
        </>
      )}
    </DataCard>
  )
}

export default InstrumentInfoAttributeExColumnDataCard
