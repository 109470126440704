import HelpIcon from '@mui/icons-material/Help'
import Tooltip, { TooltipProps } from '@mui/material/Tooltip'
import React, { CSSProperties } from 'react'

type HintProps = Omit<TooltipProps, 'children'> & {
  tooltipStyle?: CSSProperties
}

export const Hint = (props: HintProps) => {
  const { tooltipStyle, ...hintProps } = props
  return (
    <Tooltip placement="top" arrow {...hintProps}>
      <div style={{ display: 'inline-block', ...props.tooltipStyle }}>
        <HelpIcon fontSize="small" />
      </div>
    </Tooltip>
  )
}
