import { AxiosHttpClient } from 'api/axios'
import { useRepruaDeleteApi, useRepruaIndexApi, useRepruaPostApi } from 'api/shared_api_hooks'
import { PagingResponse } from 'entities'
import { DealerUserLaboratoryRelation, DealerUserLaboratoryRelationForm } from 'entities/dealer_user_laboratory_relation'
import { ApiSet, BaseResponse, Form } from 'rac'


type DealerUserLaboratoryRelationsResponse = PagingResponse & {
  dealerUserLaboratoryRelations: DealerUserLaboratoryRelation[]
}

/**
 * 一覧
 */
export function usePartnerFetchDealerUserLaboratoryRelationsApi(): ApiSet<DealerUserLaboratoryRelationsResponse> & {
  execute: (dealerUserId: number) => void
} {
  const api = useRepruaIndexApi<DealerUserLaboratoryRelationsResponse, {}>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { dealerUserLaboratoryRelations: [], totalCount: 0 },
  })

  const execute = (dealerUserId: number) => {
    const path = `dealer_users/${dealerUserId}/dealer_user_laboratory_relations`
    api.execute(path)
  }

  return { ...api, execute }
}

/**
 * 一括作成
 */
export function usePartnerBulkCreateDealerUserLaboratoryRelationsApi(): ApiSet<BaseResponse> & { execute: (dealerUserId: number, form: Form<DealerUserLaboratoryRelationForm>) => void } {
  const api = useRepruaPostApi<BaseResponse, DealerUserLaboratoryRelationForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: {},
  })

  const execute = (dealerUserId: number, form: Form<DealerUserLaboratoryRelationForm>) => {
    const path = `dealer_users/${dealerUserId}/dealer_user_laboratory_relations/bulk_create`
    api.execute(path, form)
  }

  return { ...api, execute }
}

/**
 * 削除
 */
export function usePartnerDeleteDealerUserLaboratoryRelationApi(): ApiSet<BaseResponse> & {
  execute: (dealerUserId: number, relationId: number) => void
} {
  const api = useRepruaDeleteApi<BaseResponse>(new AxiosHttpClient('dealer_user'), { initialResponse: {} })

  const execute = (dealerUserId: number, relationId: number) => {
    const path = `dealer_users/${dealerUserId}/dealer_user_laboratory_relations/${relationId}`
    api.execute(path)
  }

  return { ...api, execute }
}
