import { Card, CardProps } from '@mui/material'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()({
  card: {
    width: '100%',
    padding: 20,
    // border: '2px solid black',
  },
})

type DataCardProps = CardProps & {}
export const DataCard: React.FC<DataCardProps> = (props) => {
  const { ...rest } = props
  const { classes } = useStyles()
  return (
    <Card className={classes.card} {...rest}>
      {props.children}
    </Card>
  )
}

export default DataCard
