import { AtomEffect, DefaultValue } from 'recoil'

/**
 * Atom の値を LocalStorage に保存して永続化する
 * @param key LocalStorage のキー
 */
export const localStorageEffect: <T>(key: string) => AtomEffect<T> = (key: string) => ({ setSelf, onSet }) => {
  const savedValue = localStorage.getItem(key)

  if (savedValue != null) {
    setSelf(JSON.parse(savedValue))
  }

  onSet((newValue) => {
    if (newValue instanceof DefaultValue) {
      localStorage.removeItem(key)
    } else {
      localStorage.setItem(key, JSON.stringify(newValue))
    }
  })
}
