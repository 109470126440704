import { usePartnerFetchDefectReportApi } from 'api/partner/defect_report'
import { GlobalStateContext } from 'contexts/global_state_context'
import React, { useContext, useEffect } from 'react'
import { StaticContext } from 'react-router'
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom'
import { PartnerRouteHelper } from 'routes/partner'
import { useEffectSkipFirst } from 'utils/hooks'

type DefectReportShowProps = {}

type Props = RouteComponentProps<{ id: string }, StaticContext, DefectReportShowProps>
export const DefectReportShow: React.FC<Props> = (props) => {
  const globalState = useContext(GlobalStateContext)
  const history = useHistory()
  const defectReportApi = usePartnerFetchDefectReportApi()
  const defectReportId = Number(props.match.params.id)

  useEffect(() => {
    defectReportApi.execute(defectReportId)
  }, [])

  useEffectSkipFirst(() => {
    globalState.setLoading(defectReportApi.loading)
    if (defectReportApi.isSuccess() && defectReportApi.response.defectReport.repairInfoId) {
      history.push(PartnerRouteHelper.repairInfoShow(defectReportApi.response.defectReport.repairInfoId))
    }
  }, [defectReportApi.loading])

  return <></>
}

export default withRouter(DefectReportShow)
