import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import ErrorSnackbar from 'components/shared/error_snackbar'
import { NotificationSnackbar } from 'components/shared/notification_snackbar'
import OverlayLoadingProgress from 'components/shared/overlay_loading_progress'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { ApiError } from 'rac'
import React, { ReactNode } from 'react'

type GlobalStateContainerProps = {
  children: ReactNode
}

export type ConfirmCallbackEvent = 'ok' | 'cancel'

export type Confirm = {
  message: string
  callback: (event: ConfirmCallbackEvent) => void
  open: boolean
}

const GlobalStateContainer: React.FC<GlobalStateContainerProps> = (props: GlobalStateContainerProps) => {
  const [loading, setLoading] = React.useState(false)
  const [apiError, setApiError] = React.useState<ApiError>({ message: '', messages: [], details: {} })
  const [notificationMessage, setNotificationMessage] = React.useState<{ body: string }>({ body: '' })
  const [confirmState, setConfirmState] = React.useState<Confirm>({ open: false, callback: () => {}, message: '' })
  const [statusCode, setStatusCode] = React.useState<number>(0)

  const confirm = (message: string, callback: (event: ConfirmCallbackEvent) => void) => {
    setConfirmState({ message: message, callback: callback, open: true })
  }

  const handleClickConfirm = (event: ConfirmCallbackEvent) => {
    confirmState.callback(event)
    setConfirmState({ ...confirmState, open: false })
  }

  return (
    <GlobalStateContext.Provider
      value={{
        loading: loading,
        setLoading: setLoading,
        apiError: apiError,
        setError: setApiError,
        notificationMessage: notificationMessage,
        setNotificationMessage: setNotificationMessage,
        confirm: confirm,
        statusCode,
        setStatusCode,
      }}
    >
      <Dialog open={confirmState.open}>
        <DialogTitle>確認</DialogTitle>
        <DialogContent>{confirmState.message}</DialogContent>
        <DialogActions>
          <ThemedButton color="secondary" onClick={() => handleClickConfirm('cancel')}>
            戻る
          </ThemedButton>
          <ThemedButton color="success" onClick={() => handleClickConfirm('ok')}>
            OK
          </ThemedButton>
        </DialogActions>
      </Dialog>
      <OverlayLoadingProgress open={loading} />
      {props.children}
      <ErrorSnackbar error={apiError} />
      <NotificationSnackbar notificationMessage={notificationMessage} />
    </GlobalStateContext.Provider>
  )
}

export default GlobalStateContainer
