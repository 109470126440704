import {
  useAdminDeleteDealerInstrumentImport,
  useAdminFetchDealerInstrumentImportFormat,
  useAdminDealerInstrumentImportResults,
  useAdminPostDealerInstrumentImport,
} from 'api/admin/dealers/instrument'
import { InstrumentImportModal } from 'components/admin/shared/instrument_import_modal'
import { ModalState } from 'components/shared/modal'
import { GlobalStateContext } from 'contexts/global_state_context'
import { InstrumentImportForm } from 'entities/instrument_import_result'
import { useForm } from 'rac'
import React, { useContext, useEffect } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'

type InstrumentImportModalContainerProps = {
  dealerId: number
  modalState: ModalState
  onComplete?: () => void
}

export const InstrumentImportModalContainer = ({ dealerId, modalState, onComplete }: InstrumentImportModalContainerProps) => {
  const form = useForm<InstrumentImportForm>({}, 'instrumentImport')
  const indexApi = useAdminDealerInstrumentImportResults()
  const postApi = useAdminPostDealerInstrumentImport()
  const deleteApi = useAdminDeleteDealerInstrumentImport()
  const formatDownloadApi = useAdminFetchDealerInstrumentImportFormat()
  const globalState = useContext(GlobalStateContext)

  const handleSubmit = () => {
    postApi.execute(form, dealerId)
  }

  const handleClickDownloadFormat = () => {
    formatDownloadApi.execute(dealerId)
  }

  const handleComplete = () => {
    form.set({})
    indexApi.execute(dealerId)
    onComplete && onComplete()
  }

  const handleClickImportCancelButton = (instrumentImportResultId: number) => {
    globalState.confirm(
      '本当に取消しますか？（Excelファイルからインポートされた機器は削除されますが、作成された外部顧客マスタ、部署、機器カテゴリは削除されずに残ります。）',
      (event) => {
        if (event === 'ok') {
          deleteApi.execute(instrumentImportResultId)
        }
      },
    )
  }

  useEffectSkipFirst(() => {
    globalState.setLoading(postApi.loading)
    if (postApi.isSuccess()) {
      handleComplete()
    }
  }, [postApi.loading])

  useEffectSkipFirst(() => {
    globalState.setLoading(deleteApi.loading)
    if (deleteApi.isSuccess()) {
      handleComplete()
    }
  }, [deleteApi.loading])

  useEffect(() => {
    if (dealerId) {
      indexApi.execute(dealerId)
    }
  }, [dealerId])

  return (
    <InstrumentImportModal
      modalState={modalState}
      form={form}
      loading={indexApi.loading}
      instrumentImportResults={indexApi.response.instrumentImportResults}
      onClickDownloadFormat={handleClickDownloadFormat}
      onClickImportCancelButton={handleClickImportCancelButton}
      onSubmit={handleSubmit}
    />
  )
}
