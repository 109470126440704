import { Paper, Table, TableContainer, TableHead, TableRow } from '@mui/material'
import { useFetchLaboratoriesApi } from 'api/mypage/laboratory'
import { ContentHeader, ContentTitle } from 'components/shared/content'
import { ExTableBody, ExTableCell, OrderTableCell, ExTableRow } from 'components/shared/table'
import ThemedButton from 'components/shared/themed_button'
import { Instrument } from 'entities/instrument'
import { Laboratory } from 'entities/laboratory'
import React, { useEffect } from 'react'
import { StaticContext } from 'react-router'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { MypageRouteHelper } from 'routes/mypage'

import { SharedInstrumentsTableRow } from './show'

type LaboratoryIndexProps = {}

type Props = RouteComponentProps<{}, StaticContext, LaboratoryIndexProps>

export const LaboratoryIndex: React.FC<Props> = (props) => {
  const laboratoriesApi = useFetchLaboratoriesApi()

  useEffect(() => {
    laboratoriesApi.execute()
  }, [])

  const handleShow = (laboratoryId: number | string) => {
    props.history.push({
      pathname: MypageRouteHelper.laboratoryShow(laboratoryId),
      state: {},
    })
  }

  return (
    <>
      <ContentHeader>
        <ContentTitle title="管理ラボ一覧"></ContentTitle>
      </ContentHeader>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <OrderTableCell width={250} orderAttr="name" orderSet={laboratoriesApi.orderSet}>
                  ラボ名称
                </OrderTableCell>
                <ExTableCell minWidth={200}>利用可能な資産</ExTableCell>
                <ExTableCell width={130} align="center">
                  各ラボの設定
                </ExTableCell>
              </TableRow>
            </TableHead>
            <ExTableBody loading={laboratoriesApi.loading}>
              {laboratoriesApi.response?.laboratories.map((laboratory: Laboratory, idx: number) => {
                const roomsIndex: number[] = []
                return (
                  <ExTableRow key={idx}>
                    <ExTableCell>{laboratory.name}</ExTableCell>
                    <ExTableCell>
                      {laboratory.sharedInstruments &&
                        laboratory.sharedInstruments.map((instrument: Instrument, idx: number) => {
                          const found = roomsIndex.find((id) => id === instrument.roomId)
                          if (!found) roomsIndex.push(instrument.roomId!)

                          return <SharedInstrumentsTableRow key={idx} instrument={instrument} found={!!found} />
                        })}
                    </ExTableCell>
                    <ExTableCell align="right">
                      <ThemedButton color="secondary" onClick={() => handleShow(laboratory.id!)}>
                        ラボ詳細
                      </ThemedButton>
                    </ExTableCell>
                  </ExTableRow>
                )
              })}
            </ExTableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  )
}

export default withRouter(LaboratoryIndex)
