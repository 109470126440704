import { AttachmentAttributes } from 'entities/attachment_attributes'
import { Instrument } from 'entities/instrument'
import { Laboratory } from 'entities/laboratory'
import { ReservationTerm, ReservationTermForm } from 'entities/reservation_term'
import { SearchQuery } from 'rac'

import { BaseEntity } from '.'

/**
 * 部屋
 */
export type Room = BaseEntity & {
  name?: string
  proprietaryFlag?: boolean
  canReserve?: boolean
  imageUrl?: string
  note?: string

  laboratory?: Laboratory
  laboratoryId?: number
  reservationTerm?: ReservationTerm
  instruments?: Instrument[]
}

export type RoomForm = Room & {
  reservationTermAttributes?: ReservationTermForm
  imageAttributes?: AttachmentAttributes
  cancelPolicyId?: number
}

export type RoomSearchForm = {
  searchText?: string
}

export const getRoomSearchQuery = (searchText?: string): SearchQuery => ({
  suffix: 'Cont',
  searchText: searchText || '',
  attrs: ['name', 'note'],
})
