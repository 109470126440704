import { PopoverOrigin, Menu, MenuItem } from '@mui/material'
import { ThemeColor } from 'config/theme'
import React from 'react'

import ThemedButton from './themed_button'

export type ListItem = {
  text: string
  onClick: () => void
}

export type DropdownMenuProps = {
  id: string
  buttonText: string
  listItems: ListItem[]
  anchorOrigin?: PopoverOrigin
  transformOrigin?: PopoverOrigin
  buttonSize?: 'small' | 'medium' | 'large'
  buttonColor?: ThemeColor
  buttonVariant?: 'text' | 'outlined' | 'contained'
  disabled?: boolean
}
export const DropdownMenu: React.FC<DropdownMenuProps> = (props: DropdownMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & Element) | null>(null)

  const handleClick = (e: React.MouseEvent) => {
    setAnchorEl(e.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'DropdownMenu' : undefined

  return (
    <div>
      <ThemedButton
        variant={props.buttonVariant || 'contained'}
        aria-describedby={id}
        size={props.buttonSize || 'medium'}
        color={props.buttonColor}
        onClick={handleClick}
        disabled={props.disabled}
      >
        {props.buttonText}
      </ThemedButton>
      <Menu id={props.id} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {props.listItems.map((listItem, i) => {
          return (
            <MenuItem
              key={`${props.id}-menu-item-${i}`}
              onClick={() => {
                listItem.onClick()
                handleClose()
              }}
            >
              {listItem.text}
            </MenuItem>
          )
        })}
      </Menu>
    </div>
  )
}
