// FullCalendarのみ一番上にimportする
// 「Please import the top-level fullcalendar lib before attempting to import a plugin.」
// eslint-disable-next-line import/order
import FullCalendar from '@fullcalendar/react'

import { ViewApi, Duration, EventSourceFunc, EventApi, EventInput, DatesSetArg, AllowFunc } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import React from 'react'

type CalendarProps = {
  editable?: boolean
  events?: string | EventSourceFunc | EventInput[] | undefined
  eventClick: (arg: { el: HTMLElement; event: EventApi; jsEvent: MouseEvent; view: ViewApi }) => boolean | void
  dateClick?: (arg: {
    date: Date
    dateStr: string
    allDay: boolean
    resource?: any
    dayEl: HTMLElement
    jsEvent: MouseEvent
    view: ViewApi
  }) => void
  eventDrop?: (arg: {
    el: HTMLElement
    event: EventApi
    oldEvent: EventApi
    delta: Duration
    revert: () => void
    jsEvent: Event
    view: ViewApi
  }) => void
  datesSet?: (arg: DatesSetArg) => void
  eventAllow?: AllowFunc
}

export const Calendar = (props: CalendarProps) => {
  return (
    <FullCalendar
      locale="ja"
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
      initialView="dayGridMonth"
      headerToolbar={{
        start: 'prev,next today',
        center: 'title',
        end: 'dayGridMonth,timeGridWeek,timeGridDay',
      }}
      buttonText={{
        prev: '前',
        next: '次',
        prevYear: '前年',
        nextYear: '次年',
        today: '今日',
        month: '月',
        week: '週',
        day: '日',
      }}
      dayMaxEvents={5}
      dayMaxEventRows={5}
      moreLinkContent="もっとみる"
      eventTimeFormat={(arg) => `${arg.start.hour}:${('00' + arg.start.minute).slice(-2)}`}
      {...props}
    />
  )
}

export default Calendar
