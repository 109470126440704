import { BaseEntity } from 'entities'
import { InstrumentOwnerableType } from 'entities/instrument'

export type ExColumn = BaseEntity & {
  ownerableId?: number
  ownerableType?: InstrumentOwnerableType
  clientableId?: number | null
  clientableType?: InstrumentOwnerableType | null
  extensionClass?: ExtensionClass
  name?: string
  inputType?: InputType
  options?: string[]
  required?: boolean
  stashed?: boolean
  sortNumber?: number
  visibleOnTable?: boolean
  shared?: boolean
}

export const inputType = ['string', 'text', 'integer', 'dropdown', 'price'] as const
export type InputType = typeof inputType[number]

const extensionClass = ['InstrumentInfoAttribute', 'ProofreadEvent'] as const
export type ExtensionClass = typeof extensionClass[number]

export type ExColumnForm = ExColumn
