// 各コンポーネントの高さを定数化してテーブル高さ調整に用いる
/**
 * レイアウトヘッダー
 */
const FLOAT_HEADER = 64

/**
 * タイトルがあるだけのメインヘッダー
 */
const TITLE_HEADER = 50

/**
 * 検索欄などがあるメインヘッダー
 */
const CONTENT_HEADER = 63

/**
 * Excel出力などがあるサブヘッダー
 */
const CONTENT_SUBHEADER = 46.5

/**
 * テーブルのページネーション
 */
const PAGINATION = 56.5

/**
 * 校正計画のメインヘッダー
 */
const PROOFREAD_CONTENT_HEADER = 56.5

/**
 * 校正計画のステップタブ
 */
const PROOFREAD_STEP = 96

/**
 * 一覧選択時に表示されるフッター
 */
const CHECKBOX_FOOTER = 80

/**
 * 余白
 */
const MARGIN = 10

export const HEIGHTS = {
  FLOAT_HEADER,
  TITLE_HEADER,
  CONTENT_HEADER,
  CONTENT_SUBHEADER,
  PAGINATION,
  PROOFREAD_CONTENT_HEADER,
  PROOFREAD_STEP,
  CHECKBOX_FOOTER,
  MARGIN,
}

/**
 * テーブル以外の要素の高さからテーブルの高さを算出
 */
export const getTableHeight = (padding: number) => {
  return `calc(100vh - ${padding}px)`
}

// よく使う値
/**
 * 一般的な一覧ページのテーブル以外の要素の高さ
 */
export const INDEX_TABLE_PADDING = FLOAT_HEADER + CONTENT_HEADER + CONTENT_SUBHEADER + PAGINATION + MARGIN

/**
 * チェックボックス選択時の一般テーブル以外の要素の高さ
 */
export const INDEX_CHECKED_TABLE_PADDING = FLOAT_HEADER + CONTENT_HEADER + CONTENT_SUBHEADER + PAGINATION + CHECKBOX_FOOTER + MARGIN

/**
 * 校正計画の詳細テーブル以外の要素の高さ
 */
export const PROOFREAD_TABLE_PADDING = FLOAT_HEADER + PROOFREAD_CONTENT_HEADER + PAGINATION + PROOFREAD_STEP + MARGIN

/**
 * 機器共有インポート画面のテーブル以外の要素の高さ
 */
export const SHARING_IMPORT_TABLE_PADDING = FLOAT_HEADER + MARGIN * 7

/**
 * チェックボックス選択時の校正計画テーブル以外の要素の高さ
 */
export const PROOFREAD_CHECKED_TABLE_PADDING =
  FLOAT_HEADER + PROOFREAD_CONTENT_HEADER + PAGINATION + PROOFREAD_STEP + CHECKBOX_FOOTER + MARGIN
