export const tabTypes = {
  /** ラボアカウントから見た校正計画 */
  MYPAGE_PROOFREAD: 'mypage_proofread',
  /** 代理店アカウントから見た、ラボが開始した校正計画 */
  PARTNER_LAB_CREATED_PROOFREAD: 'partner_lab_created_proofread', 
  /** 代理店アカウントから見た、代理店が開始したリプルア顧客向け校正計画 */
  PARTNER_SUPPLIER_CREATED_LAB_PROOFREAD: 'partner_supplier_created_lab_proofread', 
  /** 代理店アカウントから見た、代理店が開始した外部顧客向け校正計画 */
  PARTNER_SUPPLIER_CREATED_OUTSIDE_LAB_PROOFREAD: 'partner_supplier_created_outside_lab_proofread', 
  PARTNER_PROOFREAD_INDEX: 'partner_proofread_index',
  REPAIR_INDEX: 'repair_index',
  MYPAGE_INSTRUMENT_EVENT_INDEX: 'mypage_instrument_event_index',
  PARTNER_INSTRUMENT_EVENT_INDEX: 'partner_instrument_event_index',
  SETTING: 'setting',
} as const

export type TabTypes = typeof tabTypes[keyof typeof tabTypes]
