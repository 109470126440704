import { ID } from 'entities'
import { Reservation, ReservationForm, ReservationSearchForm, ReservationsResponse } from 'entities/reservation'
import { IndexApiSet, ApiSet, Form, BaseResponse } from 'rac'

import { AxiosHttpClient } from '../../axios'
import { useRepruaIndexApi, useRepruaPostApi } from '../../shared_api_hooks'

/**
 * 一覧
 */
export function useFetchRoomReservationsApi(
  roomId: ID,
): IndexApiSet<ReservationsResponse> & { execute: (form: Form<ReservationSearchForm>) => void } {
  const api = useRepruaIndexApi<ReservationsResponse, unknown>(new AxiosHttpClient('user'), {
    initialResponse: { reservations: [], totalCount: 0 },
  })

  const execute = (form: Form<ReservationSearchForm>) => {
    const path = `rooms/${roomId}/reservations`
    api.execute(path, { params: form.object })
  }

  return { ...api, execute }
}

export type ReservationResponse = BaseResponse & {
  reservation: Reservation
}

/**
 * 登録
 */
export function usePostRoomReservationApi(): ApiSet<ReservationResponse> & {
  execute: (roomId: ID, reservationForm: Form<ReservationForm>) => void
} {
  const api = useRepruaPostApi<ReservationResponse, ReservationForm>(new AxiosHttpClient('user'), { initialResponse: { reservation: {} } })

  const execute = (roomId: ID, reservationForm: Form<ReservationForm>) => {
    const path = `rooms/${roomId}/reservations`
    api.execute(path, reservationForm)
  }

  return { ...api, execute }
}
