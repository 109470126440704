import { Paper, TableContainer, Box, Table, TableRow, TableCell } from '@mui/material'
import { useModal } from 'components/shared/modal'
import { GlobalStateContext } from 'contexts/global_state_context'
import { MypageStateContext } from 'contexts/mypage_state_context'
import { ReservationConstraintSet } from 'entities/reservation_constraint_set'
import React, { useEffect, useContext, useState } from 'react'
import { useEffectSkipFirst } from 'utils/hooks/use_effect_skip_first'

import { useFetchReservationConstraintSetsApi, useDeleteReservationConstraintSetApi } from '../../../api/mypage/reservation_constraint_set'
import { ExTableBody } from '../../shared/table/table_body/ex_table_body'
import { ConstraintDetail } from '../shared/constraint_detail'

import { EditReservationConstraintSetFormDialog, NewReservationConstraintSetFormDialog } from './reservation_constraint_form_dialog'

type ReservationConstraintListBoxProps = {}

export const ReservationConstraintListBox: React.FC<ReservationConstraintListBoxProps> = (props: ReservationConstraintListBoxProps) => {
  const api = useFetchReservationConstraintSetsApi()
  const globalState = useContext(GlobalStateContext)
  const { currentUser } = useContext(MypageStateContext)
  const [selectedConstraintSet, setSelectedConstraintSet] = useState<ReservationConstraintSet>({})
  const editModalState = useModal()
  const deleteApi = useDeleteReservationConstraintSetApi()

  const handleAfterCommit = () => {
    editModalState.setOpen(false)
    api.execute()
  }

  useEffect(() => {
    globalState.setLoading(api.loading)
  }, [api.loading])

  const handleOpenEdit = (constraintSet: ReservationConstraintSet) => {
    setSelectedConstraintSet(constraintSet)
    editModalState.setOpen(true)
  }

  const handleClickDelete = (constraintSet: ReservationConstraintSet) => {
    let message = '予約条件を削除します。よろしいですか？'
    if (constraintSet.roomsCount || constraintSet.instrumentsCount) {
      message = `${constraintSet.roomsCount ? constraintSet.roomsCount + 'つの部屋 ' : ''}
         ${constraintSet.instrumentsCount ? constraintSet.instrumentsCount + 'つの機器 ' : ''}で使用されています。削除しますか？`
    }
    globalState.confirm(message, (event) => {
      if (event === 'ok') {
        deleteApi.execute(constraintSet.id!)
      }
    })
  }

  useEffectSkipFirst(() => {
    globalState.setLoading(deleteApi.loading)
    if (deleteApi.isSuccess()) {
      api.execute()
    }
  }, [deleteApi.loading])

  return (
    <Box>
      <Box display={'flex'} alignItems={'center'} marginBottom={1}>
        <h3 style={{ marginRight: '10px' }}>予約条件</h3>
        {currentUser && currentUser.authority?.constraint && <NewReservationConstraintSetFormDialog onComplete={handleAfterCommit} />}
        <EditReservationConstraintSetFormDialog
          onComplete={handleAfterCommit}
          reservationConstraintSet={selectedConstraintSet}
          modalState={editModalState}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <ExTableBody loading={api.loading}>
            {api.response?.reservationConstraintSets.map((reservationConstraintSet: ReservationConstraintSet, idx: number) => {
              return (
                <TableRow key={idx}>
                  <TableCell>
                    <ConstraintDetail
                      reservationConstraintSet={reservationConstraintSet}
                      onClickDelete={handleClickDelete}
                      onClickEdit={handleOpenEdit}
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </ExTableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
