import { Typography } from '@mui/material'
import ApprovalResultsTable from 'components/shared/data/approval_results_table'
import AttachmentsList from 'components/shared/data/attachments_list'
import DataBlock from 'components/shared/data/data_block'
import DataCard from 'components/shared/data/data_card'
import DataLabel from 'components/shared/data/data_label'
import { Flex } from 'components/shared/flex'
import { useMultipleModal } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { RepairEstimate } from 'entities/repair_estimate'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { MypageRouteHelper } from 'routes/mypage'
import { RepairEstimateViewModel } from 'view_models/repair_estimate'

import { EditRepairEstimateFormDialog } from './repair_estimate_form_dialog'

type RepairEstimateDataCardProps = {
  repairEstimate: RepairEstimate | undefined
  showDetail?: boolean
  canEdit?: boolean
  onCompleteEdit?: () => void
  emptyMessage?: string
}
export const RepairEstimateDataCard: React.FC<RepairEstimateDataCardProps> = (props) => {
  const [vm, setVm] = useState(new RepairEstimateViewModel(props.repairEstimate || {}))
  const history = useHistory()
  const editModal = useMultipleModal<RepairEstimate>()

  useEffect(() => {
    if (props.repairEstimate) {
      setVm(new RepairEstimateViewModel(props.repairEstimate))
    }
  }, [props.repairEstimate])

  const handleClickDetail = () => {
    history.push(MypageRouteHelper.repairEstimateShow(props.repairEstimate!.id!))
  }

  const handleCompleteEdit = () => {
    editModal.closeModal()
    props.onCompleteEdit && props.onCompleteEdit()
  }

  return (
    <DataCard>
      <EditRepairEstimateFormDialog modalSet={editModal} onComplete={handleCompleteEdit} />
      <Flex justifyContent="space-between" alignItems="center">
        <Typography variant="h6">修理見積</Typography>
        <Flex>
          {props.repairEstimate && props.showDetail && (
            <ThemedButton color="secondary" onClick={handleClickDetail}>
              詳細
            </ThemedButton>
          )}
          {props.repairEstimate && props.canEdit && (
            <ThemedButton color="primary" onClick={() => editModal.openModal(props.repairEstimate!)}>
              編集
            </ThemedButton>
          )}
        </Flex>
      </Flex>
      {props.repairEstimate ? (
        <>
          <DataBlock>
            <DataLabel label="見積元" value={vm.getSupplierName()} />
            <DataLabel label="見積No." value={vm.getEstimateNumber()} />
          </DataBlock>
          {props.repairEstimate.survey && (
            <DataBlock>
              <DataLabel label="現地調査結果" value={vm.getSurvey()} />
            </DataBlock>
          )}
          {props.repairEstimate.aasmState === 'estimating' ? (
            <DataBlock justifyContent="center" noBorder>
              <p style={{ textAlign: 'center' }}>見積中</p>
            </DataBlock>
          ) : (
            <>
              <DataBlock>
                <DataLabel label="納期" value={vm.getDeliveryTerm()} />
                <DataLabel label="修理費（税別）" value={vm.getPriceExTaxText()} />
                <DataLabel label="修理費（税込）" value={vm.getPriceText()} />
              </DataBlock>
              <DataBlock>
                <AttachmentsList fileAttachments={props.repairEstimate.fileAttachments || []} />
              </DataBlock>
              <DataBlock noBorder={(props.repairEstimate.approvalResults || []).length === 0}>
                <DataLabel label="備考" value={vm.getNote()} />
              </DataBlock>
              {(props.repairEstimate.approvalResults || []).length > 0 && (
                <DataBlock noBorder>
                  <ApprovalResultsTable approvalResults={props.repairEstimate.approvalResults!} />
                </DataBlock>
              )}
            </>
          )}
        </>
      ) : (
        <span>{props.emptyMessage || '修理見積を選択してください'}</span>
      )}
    </DataCard>
  )
}

export default RepairEstimateDataCard
