import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { Table, TableHead, TableRow, Paper, TableContainer } from '@mui/material'
import { usePartnerFetchDealerDivisionsApi } from 'api/partner/dealer_divisions'
import { usePartnerFetchInstrumentCategoriesApi } from 'api/partner/instrument_categories'
import { usePartnerFetchOutsideLaboratoriesApi } from 'api/partner/outside_laboratories'
import {
  usePartnerBulkDiscardInstrumentApi,
  usePartnerBulkRestoreManagementUnmanagedInstrumentApi,
  usePartnerFetchUnmanagedInstrumentsApi,
} from 'api/partner/unmanaged_instrument'
import { EditInstrumentFormDialog } from 'components/partner/instrument/instrument_form_dialog'
import { CheckboxFooter } from 'components/shared/checkbox_footer'
import { ContentHeader, ContentTitle } from 'components/shared/content'
import DropDownCheckbox from 'components/shared/dropdown_checkbox'
import { Flex } from 'components/shared/flex'
import { DropDownCheckboxItem } from 'components/shared/form/input'
import ImageView from 'components/shared/image_view'
import Pagination from 'components/shared/pagination'
import { SearchBar } from 'components/shared/search_bar'
import { ExTableCell, OrderTableCell, AccordionTableBody, AllCheckboxTableCell, CheckboxTableCell } from 'components/shared/table'
import { DisplaySettingModal } from 'components/shared/table/display_setting'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { Instrument, InstrumentSearchForm } from 'entities/instrument'
import { warningDiscardUnmanagedInstrumentDescription } from 'entities/unmanaged_instrument'
import { useForm } from 'rac'
import React, { useContext, useEffect, useMemo } from 'react'
import { StaticContext } from 'react-router'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { PartnerRouteHelper } from 'routes/partner'
import { getTableHeight, INDEX_CHECKED_TABLE_PADDING, INDEX_TABLE_PADDING } from 'utils/component_heights'
import { useCheckboxes } from 'utils/hooks'
import { useDisplaySetting } from 'utils/hooks/index'
import { useEffectSkipFirst } from 'utils/hooks/use_effect_skip_first'
import { booleanFilterOptions, booleanFilterValues } from 'utils/utils'
import { InstrumentViewModel } from 'view_models/instrument'

const initialDisplaySettings = [
  { attr: 'createdAt', name: '作成日', visible: true },
  { attr: 'maker', name: 'メーカー', visible: true },
  { attr: 'outsideLaboratory', name: '顧客', visible: true },
  { attr: 'dealerDivision', name: '部署', visible: true },
]

export type InstrumentIndexProps = {
  roomId?: number
}

type Props = RouteComponentProps<{}, StaticContext, InstrumentIndexProps>

const UnmanagedInstrumentIndex = (props: Props) => {
  const searchForm = useForm<InstrumentSearchForm>({
    q: { instrumentRoomIdEq: props.location.state?.roomId },
    requireProofreadFilter: booleanFilterValues,
  })
  const unmanagedInstrumentsApi = usePartnerFetchUnmanagedInstrumentsApi(searchForm)
  const bulkRestoreManagementApi = usePartnerBulkRestoreManagementUnmanagedInstrumentApi()
  const bulkDiscardApi = usePartnerBulkDiscardInstrumentApi()
  const [editDialogOpen, setEditDialogOpen] = React.useState(false)
  const [editInstrument, setEditInstrument] = React.useState<Instrument>({})
  const displaySettingSet = useDisplaySetting(initialDisplaySettings, 'partner-unmanaged-instruments-table')
  const outsideLaboratoriesIndexApi = usePartnerFetchOutsideLaboratoriesApi({ fetchAll: false, includesDisable: true })
  const categoryIndexApi = usePartnerFetchInstrumentCategoriesApi()
  const dealerDivisionsIndexApi = usePartnerFetchDealerDivisionsApi(false)
  const checkboxSet = useCheckboxes(unmanagedInstrumentsApi.response.instruments)
  const globalState = useContext(GlobalStateContext)

  useEffect(() => {
    unmanagedInstrumentsApi.execute()
    categoryIndexApi.execute()
    outsideLaboratoriesIndexApi.execute()
    dealerDivisionsIndexApi.execute()
  }, [])

  useEffectSkipFirst(() => {
    if (bulkRestoreManagementApi.isSuccess()) {
      unmanagedInstrumentsApi.execute()
      checkboxSet.clear()
    }
  }, [bulkRestoreManagementApi.loading])

  useEffectSkipFirst(() => {
    if (bulkDiscardApi.isSuccess()) {
      unmanagedInstrumentsApi.execute()
      checkboxSet.clear()
    }
  }, [bulkDiscardApi.loading])

  const selectableOutsideLaboratories = useMemo(() => {
    return outsideLaboratoriesIndexApi.response.outsideLaboratories.filter((o) => o.state === 'enable')
  }, [outsideLaboratoriesIndexApi.response])

  const handleUpdate = () => {
    setEditDialogOpen(false)
    unmanagedInstrumentsApi.execute()
  }

  const handleClickEditButton = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, instrument: Instrument) => {
    e.stopPropagation()
    setEditInstrument(instrument)
    setEditDialogOpen(true)
  }

  const handleSearch = () => {
    unmanagedInstrumentsApi.execute()
  }

  const handleClickDetail = (instrument: Instrument) => {
    props.history.push({
      pathname: PartnerRouteHelper.instrumentShow(instrument.id!),
      state: {},
    })
  }

  const handleClickBulkRestoreManagement = () => {
    bulkRestoreManagementApi.execute({ instrumentIds: checkboxSet.checkedIds })
  }

  const handleClickBulkDiscard = () => {
    globalState.confirm(warningDiscardUnmanagedInstrumentDescription, (event) => {
      if (event === 'ok') {
        bulkDiscardApi.execute({ instrumentIds: checkboxSet.checkedIds })
      }
    })
  }

  const dealerDivisionOptions = useMemo(
    (): DropDownCheckboxItem[] => [
      { value: '', label: '未選択' },
      ...dealerDivisionsIndexApi.response.dealerDivisions.map((division) => ({
        value: division.id!.toString(),
        label: division.name!,
      })),
    ],
    [dealerDivisionsIndexApi.response.dealerDivisions],
  )

  return (
    <>
      <EditInstrumentFormDialog
        onComplete={handleUpdate}
        onCompleteRestoreManagement={handleUpdate}
        onCompleteDiscard={handleUpdate}
        instrument={editInstrument}
        open={editDialogOpen}
        onCancel={() => setEditDialogOpen(false)}
        instrumentCategories={categoryIndexApi.response?.instrumentCategories}
        outsideLaboratories={selectableOutsideLaboratories}
        onCompleteRemoveImage={unmanagedInstrumentsApi.execute}
      />
      <ContentHeader
        subHeader={
          <Flex justifyContent="space-between">
            <ThemedButton
              variant="text"
              onClick={(e) => {
                props.history.push({
                  pathname: PartnerRouteHelper.instrumentIndex(),
                  state: {},
                })
              }}
            >
              機器一覧に戻る
            </ThemedButton>
            <DisplaySettingModal displaySettingSet={displaySettingSet} />
          </Flex>
        }
      >
        <ContentTitle title="管理対象外の機器一覧" />
        <Flex alignItems="center">
          <SearchBar
            form={searchForm}
            searchAttr="searchText"
            placeholder="メーカー・製品名・型番・シリアル番号・管理番号で検索"
            onSubmit={handleSearch}
          />
        </Flex>
      </ContentHeader>
      <Paper>
        <TableContainer
          style={{
            maxHeight:
              checkboxSet.checkedIds.length > 0 ? getTableHeight(INDEX_CHECKED_TABLE_PADDING) : getTableHeight(INDEX_TABLE_PADDING),
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <AllCheckboxTableCell checkboxSet={checkboxSet} />
                <ExTableCell width={150}>{/* 画像 */}</ExTableCell>
                <OrderTableCell
                  width={120}
                  open={displaySettingSet.isVisible('createdAt')}
                  align="center"
                  orderAttr="createdAt"
                  orderSet={unmanagedInstrumentsApi.orderSet}
                >
                  登録日
                </OrderTableCell>
                <OrderTableCell
                  width={120}
                  open={displaySettingSet.isVisible('maker')}
                  orderAttr="maker"
                  orderSet={unmanagedInstrumentsApi.orderSet}
                >
                  メーカー
                </OrderTableCell>
                <ExTableCell width={100} align="center" open={displaySettingSet.isVisible('outsideLaboratory')}>
                  顧客
                </ExTableCell>
                <ExTableCell width={120} open={displaySettingSet.isVisible('dealerDivision')}>
                  <DropDownCheckbox
                    form={searchForm}
                    attr="dealerDivisionIds"
                    name="dealerDivisionIds"
                    items={dealerDivisionOptions}
                    title="部署"
                    onSubmit={handleSearch}
                    btnStyle={{ padding: '4px 8px' }}
                    isLeft={true}
                    maxWidth={600}
                    showFilter
                    autocomplete
                  />
                </ExTableCell>
                <OrderTableCell minWidth={200} orderAttr="name" orderSet={unmanagedInstrumentsApi.orderSet}>
                  型番 / シリアル番号 / 管理番号 / 製品名
                </OrderTableCell>
                <ExTableCell width={120}>
                  <DropDownCheckbox
                    form={searchForm}
                    attr="requireProofreadFilter"
                    name="requireProofreadFilter"
                    items={booleanFilterOptions}
                    title="校正管理"
                    onSubmit={handleSearch}
                    btnStyle={{ padding: '4px 8px' }}
                    isLeft={true}
                    maxWidth={600}
                    showFilter
                  />
                </ExTableCell>
                <ExTableCell width={100} align="center" />
              </TableRow>
            </TableHead>
            <AccordionTableBody loading={unmanagedInstrumentsApi.loading} tableName="instruments-table">
              {unmanagedInstrumentsApi.response?.instruments.map((instrument: Instrument) => {
                const instrumentVM = new InstrumentViewModel(instrument)
                return (
                  <TableRow key={instrument.id}>
                    <CheckboxTableCell checkboxesSet={checkboxSet} checkboxId={instrument.id!} />
                    <ExTableCell width={100} align="center">
                      <ImageView maxHeight={100} maxWidth={100} src={instrument.instrumentBasicInfo?.imageUrl} />
                    </ExTableCell>
                    <ExTableCell width={120} open={displaySettingSet.isVisible('createdAt')} align="center">
                      {instrumentVM.registeredAt()}
                    </ExTableCell>
                    <ExTableCell width={120} open={displaySettingSet.isVisible('maker')}>
                      {instrumentVM.getMakerName()}
                    </ExTableCell>
                    <ExTableCell width={120} open={displaySettingSet.isVisible('outsideLaboratory')} align="center">
                      {instrumentVM.ownerableName()}
                    </ExTableCell>
                    <ExTableCell width={230} open={displaySettingSet.isVisible('dealerDivision')}>
                      {instrumentVM.dealerDivisionNames()}
                    </ExTableCell>
                    <ExTableCell>
                      <div style={{ fontSize: 11, marginBottom: 5 }}>{instrumentVM.getInstrumentNumbers()}</div>
                      <div style={{ fontSize: 16 }}>{instrumentVM.getInstrumentName()}</div>
                      {instrumentVM.getExternalLink()}
                    </ExTableCell>
                    <ExTableCell width={90} align="center">
                      {instrument.requireProofread && <CheckCircleOutlineIcon style={{ color: 'green' }} />}
                    </ExTableCell>
                    <ExTableCell width={100} align="center">
                      <Flex flexDirection="column">
                        <ThemedButton color="primary" size="small" onClick={() => handleClickDetail(instrument)}>
                          詳細
                        </ThemedButton>
                        <ThemedButton color="secondary" size="small" onClick={(e) => handleClickEditButton(e, instrument)}>
                          編集
                        </ThemedButton>
                      </Flex>
                    </ExTableCell>
                  </TableRow>
                )
              })}
            </AccordionTableBody>
          </Table>
          <CheckboxFooter
            content={
              <div style={{ marginRight: 10 }}>
                <ThemedButton color="error" variant="text" onClick={handleClickBulkDiscard} style={{ marginRight: 10 }}>
                  廃棄する
                </ThemedButton>
                <ThemedButton color="success" onClick={handleClickBulkRestoreManagement}>
                  もとに戻す
                </ThemedButton>
              </div>
            }
            checkboxSet={checkboxSet}
          />
          <Pagination response={unmanagedInstrumentsApi.response} pageSet={unmanagedInstrumentsApi.pageSet} />
        </TableContainer>
      </Paper>
    </>
  )
}

export default withRouter(UnmanagedInstrumentIndex)
