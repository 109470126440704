import DomainIcon from '@mui/icons-material/Domain'
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation'
import LogoutIcon from '@mui/icons-material/PowerSettingsNew'
import { AppBar, CssBaseline, Toolbar, Tooltip } from '@mui/material'
import { Flex } from 'components/shared/flex'
import { Sidebar } from 'components/shared/sidebar'
import { SidebarMenuItem } from 'components/shared/sidebar_menu_item'
import { AdminStateContext } from 'contexts/admin_state_context'
import { SidebarContext } from 'contexts/sidebar_context'
import { MenuItemPathPattern } from 'entities/menu_item_path_pattern'
import React, { ReactNode, useContext } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AdminRouteHelper } from 'routes/admin'
import CookieManager from 'utils/cookie_util'

import './admin_layout.scss'

type AdminLayoutProps = RouteComponentProps & {
  children?: ReactNode
  className?: string
}

enum AdminDrawerMenuId {
  LABORATORIES,
  DEALERS,
}

const menuItemPathPatterns: MenuItemPathPattern[] = [
  { id: AdminDrawerMenuId.LABORATORIES, pathPattern: new RegExp('admin/laboratories$', 'i') },
  { id: AdminDrawerMenuId.DEALERS, pathPattern: new RegExp('admin/dealers$', 'i') },
]

export const AdminLayout: React.FC<AdminLayoutProps> = ({ history, children, className }) => {
  const adminState = useContext(AdminStateContext)
  const sidebarContext = useContext(SidebarContext)

  React.useEffect(() => {
    const pathName = history.location.pathname
    const setMenuId = () => {
      menuItemPathPatterns.forEach((item) => {
        if (item.pathPattern?.test(pathName)) {
          sidebarContext.setActiveAdminDrawerMenuId(item.id)
        }
      })
    }

    setMenuId()
  }, [history.location.pathname])

  const handleClickLogout = () => {
    CookieManager.removeAdminToken()
    history.push(AdminRouteHelper.login())
  }

  const isMenuActive = (id: number) => id === sidebarContext.activeAdminDrawerMenuId

  return (
    <div className={`root admin ${className}`}>
      <CssBaseline />
      <AppBar position="fixed" className="app-bar" color="default">
        <Toolbar>
          <Flex alignItems="center" justifyContent="flex-end" style={{ width: '100%' }}>
            <div>
              {adminState.currentAdmin && adminState.currentAdmin.name && (
                <>
                  <div>{adminState.currentAdmin.email}</div>
                  <div>{adminState.currentAdmin.name}</div>
                </>
              )}
            </div>
            <Tooltip title="ログアウト" onClick={handleClickLogout}>
              <LogoutIcon />
            </Tooltip>
          </Flex>
        </Toolbar>
      </AppBar>
      <Sidebar>
        <SidebarMenuItem
          active={isMenuActive(AdminDrawerMenuId.LABORATORIES)}
          label="ラボラトリ管理"
          onClick={() => history.push(AdminRouteHelper.laboratoryIndex())}
          icon={<DomainIcon />}
        />
        <SidebarMenuItem
          active={isMenuActive(AdminDrawerMenuId.DEALERS)}
          label="ディーラ管理"
          onClick={() => history.push(AdminRouteHelper.dealerIndex())}
          icon={<EmojiTransportationIcon />}
        />
      </Sidebar>
      <main className="content">
        <div className="toolbar" />
        {children}
      </main>
    </div>
  )
}

export default withRouter(AdminLayout)
