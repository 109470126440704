import { Paper, TableContainer, TableHead, Box, Table, TableRow, TableCell } from '@mui/material'
import { useFetchOutsideDealersApi } from 'api/mypage/outside_dealer'
import { Flex } from 'components/shared/flex'
import { GlobalStateContext } from 'contexts/global_state_context'
import { MypageStateContext } from 'contexts/mypage_state_context'
import { OutsideDealer } from 'entities/outside_dealer'
import React, { useEffect, useContext } from 'react'

import { ExTableBody } from '../../shared/table/table_body/ex_table_body'
import { ExTableCell } from '../../shared/table/table_cell/ex_table_cell'

import { DeleteOutsideDealerButton, EditOutsideDealerFormDialog, NewOutsideDealerFormDialog } from './outside_dealer_form_dialog'

type OutsideDealersBoxProps = {}

export const OutsideDealersBox = (_props: OutsideDealersBoxProps) => {
  const api = useFetchOutsideDealersApi()
  const globalState = useContext(GlobalStateContext)
  const { currentUser } = useContext(MypageStateContext)

  useEffect(() => {
    api.execute()
  }, [])

  const handleAfterCommit = () => {
    api.execute()
  }

  useEffect(() => {
    globalState.setLoading(api.loading)
  }, [api.loading])

  return (
    <Box>
      <Box display={'flex'} alignItems={'center'} marginBottom={1}>
        <h3 style={{ marginRight: '10px' }}>外部業者設定</h3>
        {currentUser.authority?.privileged && <NewOutsideDealerFormDialog onComplete={handleAfterCommit} />}
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>外部業者名</TableCell>
              <TableCell>連絡先</TableCell>
              <ExTableCell width={150} />
            </TableRow>
          </TableHead>

          <ExTableBody loading={api.loading}>
            {api.response.outsideDealers &&
              api.response.outsideDealers.map((outsideDealer: OutsideDealer, idx: number) => {
                return (
                  <TableRow key={idx}>
                    <TableCell>{outsideDealer.name}</TableCell>
                    <TableCell>{outsideDealer.contactDetail || '-'}</TableCell>
                    <ExTableCell>
                      {currentUser.authority?.privileged && (
                        <Flex>
                          <EditOutsideDealerFormDialog onComplete={handleAfterCommit} outsideDealer={outsideDealer} />
                          <DeleteOutsideDealerButton onComplete={handleAfterCommit} outsideDealer={outsideDealer} />
                        </Flex>
                      )}
                    </ExTableCell>
                  </TableRow>
                )
              })}
          </ExTableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
