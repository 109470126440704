import { User } from 'entities/user'
import React, { Dispatch, SetStateAction } from 'react'

type MypageStateContext = {
  currentUser: User
  setCurrentUser: Dispatch<SetStateAction<User>>
  reloadUser: () => void
  instrumentTotalCount: number
  reloadInstrumentTotalCount: () => void
}

export const initialMypageState: MypageStateContext = {
  currentUser: {},
  setCurrentUser: () => {},
  reloadUser: () => {},
  instrumentTotalCount: 0,
  reloadInstrumentTotalCount: () => {},
}

export const MypageStateContext = React.createContext(initialMypageState)
