import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Checkbox, Typography } from '@mui/material'
import MuiAccordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import { Flex } from 'components/shared/flex'
import React, { useState, useEffect, ReactNode, useRef } from 'react'
import { withStyles } from 'tss-react/mui'

const Accordion = withStyles(MuiAccordion, {
  root: {
    boxShadow: 'none',
  },
})

type CheckboxAccordionProps = {
  label: string
  defaultChecked?: boolean
  onChangeChecked?: (checked: boolean) => void
  children: ReactNode
}

export const CheckboxAccordion = ({ label, defaultChecked, onChangeChecked, children }: CheckboxAccordionProps) => {
  const accordionElem = useRef<HTMLDivElement>(null)
  const [checked, setChecked] = useState(!!defaultChecked)
  const [expanded, setExpanded] = useState(!!defaultChecked)

  useEffect(() => {
    onChangeChecked && onChangeChecked(checked)
  }, [checked])

  return (
    <Accordion
      ref={accordionElem}
      expanded={expanded}
      onChange={(_, expanded) => {
        setChecked(expanded)
        setExpanded(expanded)
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: '#f9f9f9' }}>
        <Flex alignItems="center">
          <Checkbox checked={checked} />
          <Typography variant="subtitle1">{label}</Typography>
        </Flex>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ width: '100%' }}>{children}</div>
      </AccordionDetails>
    </Accordion>
  )
}
