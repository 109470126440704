import React, { ReactNode } from 'react'

type AlignItems = 'center' | 'end' | 'flex-end' | 'flex-start' | 'start' | 'stretch'
type JustifyContent = AlignItems | 'space-around' | 'space-between' | 'space-evenly'
type FlexDirection = 'column' | 'column-reverse' | 'row' | 'row-reverse'
type FlexWrap = 'wrap' | 'nowrap' | 'wrap-reverse'

export type FlexProps = React.HTMLAttributes<HTMLDivElement> & {
  justifyContent?: JustifyContent
  alignItems?: AlignItems
  flexDirection?: FlexDirection
  childMargin?: boolean
  flexWrap?: FlexWrap
  children?: ReactNode
  style?: React.CSSProperties
}

export const Flex = (props: FlexProps) => {
  const { justifyContent, alignItems, flexDirection, childMargin, flexWrap, children, style, ...rest } = props

  return (
    <>
      <div
        className={`flexbox ${childMargin ? flexDirection + '-child-margin-10' : ''}`}
        style={{
          display: 'flex',
          justifyContent: justifyContent,
          alignItems: alignItems,
          flexDirection: flexDirection,
          flexWrap: flexWrap,
          ...style,
        }}
        {...rest}
      >
        {children}
      </div>
    </>
  )
}

Flex.defaultProps = {
  justifyContent: 'start',
  alignItems: 'start',
  flexDirection: 'row',
  childMargin: true,
}
