import { ViewApi, Duration, EventApi, EventInput, AllowFunc } from '@fullcalendar/core'
import InsertInvitation from '@mui/icons-material/InsertInvitation'
import { Paper, Tooltip } from '@mui/material'
import { useFetchApprovalSettingsApi } from 'api/mypage/approval_setting'
import { useFetchCancelPoliciesApi } from 'api/mypage/cancel_policy'
import { useFetchInstrumentApi } from 'api/mypage/instrument'
import { useFetchInstrumentCategoriesApi } from 'api/mypage/instrument_categories'
import { useFetchInstrumentReservationsApi, usePostInstrumentReservationApi } from 'api/mypage/instruments/instrument_reservation'
import { useFetchPartnersApi } from 'api/mypage/partner'
import { usePatchReservationApi } from 'api/mypage/reservation'
import { useFetchReservationConstraintRelationsApi } from 'api/mypage/reservation_constraint_relation'
import { useFetchRoomsApi } from 'api/mypage/room'
import { useRestoreManagementUnmanagedInstrumentApi, useDeleteUnmanagedInstrumentApi } from 'api/mypage/unmanaged_instrument'
import DefectReportDataCard from 'components/mypage/defect_report/defect_report_data_card'
import { NewDefectReportFormDialog, NewDefectReportWithEstimateFormDialog } from 'components/mypage/defect_report/defect_report_form_dialog'
import InstrumentDataCard from 'components/mypage/instrument/instrument_data_card'
import InstrumentEventTable from 'components/mypage/instrument/instrument_event_table'
import { EditInstrumentFormDialog } from 'components/mypage/instrument/instrument_form_dialog'
import InstrumentProofreadInfoDataCard from 'components/mypage/instrument/instrument_proofread_info_data_card'
import InstrumentReservationTermDataCard from 'components/mypage/instrument/instrument_reservation_term_data_card'
import { useInstrumentEventTableSet } from 'components/mypage/instrument/use_instrument_event_table'
import ProofreadEventDataCard from 'components/mypage/proofread_event/proofread_event_data_card'
import RepairEstimateDataCard from 'components/mypage/repair_estimate/repair_estimate_data_card'
import RepairEventDataCard from 'components/mypage/repair_event/repair_event_data_card'
import { NewRepairEventFormDialog } from 'components/mypage/repair_event/repair_event_form_dialog'
import { NewReservationFormDialog, EditReservationFormDialog, CalendarData } from 'components/mypage/reservation/reservation_form_dialog'
import { ConstraintRelationFormDialog } from 'components/mypage/shared/constraint_relation_form_dialog'
import { UserMemoModal } from 'components/mypage/user_memo/user_memo_modal'
import Calendar from 'components/shared/calendar'
import { ContentHeader, ContentTitle } from 'components/shared/content'
import { Flex } from 'components/shared/flex'
import InstrumentInfoAttributeExColumnDataCard from 'components/shared/instrument_ex_column_data_card'
import { useModal, useMultipleModal } from 'components/shared/modal'
import StatusLabel from 'components/shared/status_label'
import ThemedButton from 'components/shared/themed_button'
import { ConfirmCallbackEvent } from 'containers/global_state_container'
import { GlobalStateContext } from 'contexts/global_state_context'
import { MypageStateContext } from 'contexts/mypage_state_context'
import { DefectReport } from 'entities/defect_report'
import { Instrument } from 'entities/instrument'
import { RepairEstimate } from 'entities/repair_estimate'
import { RepairEvent } from 'entities/repair_event'
import { Reservation, ReservationSearchForm } from 'entities/reservation'
import { ReservationConstraintRelationSearchForm } from 'entities/reservation_constraint_relation'
import { warningDiscardUnmanagedInstrumentDescription } from 'entities/unmanaged_instrument'
import moment from 'moment'
import { useForm } from 'rac'
import React, { useState, useEffect, useContext, useRef, useMemo } from 'react'
import { StaticContext } from 'react-router'
import { withRouter, RouteComponentProps, useLocation } from 'react-router-dom'
import { Link as Scroll } from 'react-scroll'
import { MypageRouteHelper } from 'routes/mypage'
import { makeStyles } from 'tss-react/mui'
import { useCalendar, useEffectSkipFirst } from 'utils/hooks'
import { InstrumentViewModel } from 'view_models/instrument'
import { ReservationViewModel } from 'view_models/reservation'

type InstrumentShowProps = {
  reservation?: Reservation
  calendar?: boolean
}

type Props = RouteComponentProps<{ id: string }, StaticContext, InstrumentShowProps>

const useStyles = makeStyles()(() => ({
  calendarEventStyle: {
    opacity: 0.8,
  },
}))
const HEADER_OFFSET = 50

export const InstrumentShow: React.FC<Props> = (props: Props) => {
  const [newDialogOpen, setNewDialogOpen] = useState<boolean>(false)
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false)
  const [editInstrumentDialogOpen, setEditInstrumentDialogOpen] = useState<boolean>(false)
  const [calendarData, setCalendarData] = useState<CalendarData>({ date: '', type: '' })
  const searchForm = useForm<ReservationSearchForm>({})
  const reservationIndexApi = useFetchInstrumentReservationsApi(Number(props.match.params.id))
  const calendarSet = useCalendar(reservationIndexApi, searchForm)
  const globalState = useContext(GlobalStateContext)
  const mypageState = useContext(MypageStateContext)
  const currentUser = mypageState.currentUser
  const [editReservation, setEditReservation] = useState<Reservation>({})
  const instrumentShowApi = useFetchInstrumentApi()
  const reservationUpdateApi = usePatchReservationApi()
  const memoModalState = useModal()
  const [constraintSetOpen, setConstraintSetOpen] = useState(false)
  const { classes } = useStyles()
  const cancelPolicyIndexApi = useFetchCancelPoliciesApi()
  const constraintRelationSearchForm = useForm<ReservationConstraintRelationSearchForm>({
    reservationTargetableId: Number(props.match.params.id),
    reservationTargetableType: 'Instrument',
  })
  const constraintRelationApi = useFetchReservationConstraintRelationsApi(constraintRelationSearchForm)
  const partnersApi = useFetchPartnersApi()
  const newDefectReportModal = useModal()
  const newReportWithEstimateModal = useMultipleModal<Instrument>()
  const newRepairEventModal = useModal()

  const [canReportEdit, setCanReportEdit] = useState<boolean>(false)
  const [canREventEdit, setCanREventEdit] = useState<boolean>(false)

  const instrumentEventTableSet = useInstrumentEventTableSet(instrumentShowApi)
  const currentInstrumentId = Number(props.match.params.id)
  const calendarRef = useRef<HTMLDivElement>(null)
  const discardApi = useDeleteUnmanagedInstrumentApi()
  const restoreManagementInstrumentApi = useRestoreManagementUnmanagedInstrumentApi()
  const categoryIndexApi = useFetchInstrumentCategoriesApi()
  const approvalsIndexApi = useFetchApprovalSettingsApi()
  const roomIndexApi = useFetchRoomsApi({ perPage: 1000 })

  const isUnmanaged = instrumentShowApi.response.instrument.aasmState === 'unmanaged'

  const search = useLocation().search;
  const query = new URLSearchParams(search);

  /**
   * fullcalendar用のイベントデータ
   */
  const eventInputs = useMemo(() => {
    const inputs: EventInput[] = calendarSet.reservations.map((reservation) => {
      const viewModel = new ReservationViewModel(reservation)
      const laboratory = mypageState.currentUser.laboratory

      return {
        id: reservation.id!.toString(),
        title: viewModel.getCalendarTitle(laboratory),
        start: reservation.startAt,
        end: reservation.endAt,
        color: viewModel.getEventColor(),
        classNames: viewModel.isOtherLaboratoryReservation(laboratory) ? classes.calendarEventStyle : '',
      }
    })

    return inputs
  }, [calendarSet.reservations, mypageState.currentUser.laboratory, classes])

  useEffect(() => {
    instrumentShowApi.execute(currentInstrumentId)
    roomIndexApi.execute()
    cancelPolicyIndexApi.execute()
    constraintRelationApi.execute()
    partnersApi.execute()
    categoryIndexApi.execute()
    approvalsIndexApi.execute()
  }, [])

  // 不具合報告のアクションボタン表示切り替え
  useEffect(() => {
    if (
      instrumentEventTableSet.defectReport &&
      !instrumentEventTableSet.defectReport.repairEstimate &&
      !instrumentEventTableSet.defectReport.repairEvent &&
      !instrumentEventTableSet.repairEvent &&
      !instrumentEventTableSet.repairEstimate
    ) {
      setCanReportEdit(
        (instrumentEventTableSet.defectReport?.instrument?.ownerableType === 'Laboratory' &&
          instrumentEventTableSet.defectReport?.instrument?.ownerableId === currentUser.laboratoryId) ||
          (instrumentEventTableSet.defectReport?.reporterableId === currentUser.id &&
            instrumentEventTableSet.defectReport?.reporterableType === 'User'),
      )
    } else {
      setCanReportEdit(false)
    }
  }, [instrumentEventTableSet.defectReport])

  // 修理履歴の編集ボタン表示切り替え
  useEffect(() => {
    const repairEvent = instrumentEventTableSet.repairEvent
    const proofreadEvent = repairEvent?.proofreadEvent || instrumentEventTableSet.proofreadEvent
    if (
      // ラボが作成した修理履歴か
      repairEvent &&
      repairEvent.suppliableType !== 'Dealer' &&
      repairEvent.ownerableType === 'Laboratory' &&
      repairEvent.ownerableId === currentUser.laboratoryId
    ) {
      if (proofreadEvent && (proofreadEvent.aasmState === 'fix_result' || proofreadEvent.aasmState === 'approved_result')) {
        // 校正結果入力後は編集不可
        setCanREventEdit(false)
      } else {
        setCanREventEdit(true)
      }
    } else {
      setCanREventEdit(false)
    }
  }, [instrumentEventTableSet.repairEvent])

  /**
   * ローディングプログレスの表示
   */
  useEffect(() => {
    globalState.setLoading(reservationIndexApi.loading || instrumentShowApi.loading || reservationUpdateApi.loading)
  }, [reservationIndexApi.loading, instrumentShowApi.loading, reservationUpdateApi.loading])

  /**
   * ドラッグドロップで予定が変更された場合は予定を再読み込みする
   */
  useEffectSkipFirst(() => {
    if (!reservationUpdateApi.loading) {
      calendarSet.resetDate()
    }
  }, [reservationUpdateApi.loading])

  /**
   * fullcalendarのdateクリック処理
   * @param event event
   */
  const handleClickDate = (event: {
    date: Date
    dateStr: string
    allDay: boolean
    resource?: never
    dayEl: HTMLElement
    jsEvent: MouseEvent
    view: ViewApi
  }) => {
    setCalendarData({
      date: event.dateStr,
      type: event.view.type,
    })
    setNewDialogOpen(true)
  }

  const handleEventAllow: AllowFunc = (_, movingEvent) => {
    const reservation = calendarSet.reservations.find((reservation) => movingEvent && String(reservation.id) === String(movingEvent.id))

    if (reservation) {
      // 校正イベントが紐づかないイベントのみDnDが可能
      return reservation.proofreadEventId == null
    } else {
      // 予約が存在しない場合はDnDさせない
      return false
    }
  }

  /**
   * マイカレンダーの編集リンクでアクセスしてきた際の処理
   */
  useEffect(() => {
    // 予約が一つ以上ある かつ historyで遷移する際にreservationを引数で渡している
    if (calendarSet.reservations.length === 0 || !props.location.state?.reservation) return
    scrollToCalendarAdjusted()
    openEditReservationDialog(props.location.state.reservation)
    // リロードした時に再度モーダルが開かないようにhistoryを更新
    props.history.replace({ state: {} })
  }, [calendarSet.reservations])

  useEffect(() => {
    if (instrumentShowApi.isSuccess() && (props.location.state?.calendar || query.get('calendar') === 'true')) {
      // 機器一覧でカレンダーアイコンを押した際の処理
      scrollToCalendarAdjusted()
      props.history.replace({ state: {} })
    } else if (instrumentShowApi.statusCode === 400) {
      // 機器が存在しない場合、廃棄されている場合は機器一覧画面まで遷移する
      globalState.setNotificationMessage({ body: instrumentShowApi.response.message || '機器が存在しません', colorType: 'error' })
      props.history.push(MypageRouteHelper.instrumentIndex())
    }
  }, [instrumentShowApi.loading])

  const scrollToCalendarAdjusted = () => {
    const element = document.getElementById('instrument-calendar')
    if (element === null) return
    const elementPosition = element.getBoundingClientRect().top
    window.scrollBy({ top: elementPosition - HEADER_OFFSET })
  }

  /**
   * fullcalendarのeventクリック
   * @param arg arg
   */
  const handleClickEvent = (arg: { el: HTMLElement; event: EventApi; jsEvent: MouseEvent; view: ViewApi }) => {
    const reservation = calendarSet.reservations.find((reservation) => `${reservation.id!}` === arg.event.id)
    if (reservation) openEditReservationDialog(reservation)
  }

  const openEditReservationDialog = (reservation: Reservation) => {
    setEditReservation(reservation)
    setEditDialogOpen(true)
  }

  /**
   * fullcalendarのドラッグドロップで予定が移動したときのイベント
   * @param info info
   */
  const handleDropEvent = (info: {
    el: HTMLElement
    event: EventApi
    oldEvent: EventApi
    delta: Duration
    revert: () => void
    jsEvent: Event
    view: ViewApi
  }) => {
    const reservation = calendarSet.reservations.find((reservation) => `${reservation.id!}` === info.event.id)
    if (reservation) {
      const start = moment(info.event.start)
      reservation.startAt = start.format('Y/MM/DD HH:mm')
      const end = moment(info.event.end)
      reservation.endAt = end.format('Y/MM/DD HH:mm')
      if (reservation.kind === 'repair') {
        globalState.confirm('変更した修理期間に含まれる予定はキャンセルされますが、よろしいですか？', (event: ConfirmCallbackEvent) => {
          if (event === 'cancel') {
            calendarSet.resetDate()
          } else {
            reservationUpdateApi.execute(reservation)
          }
        })
      } else {
        reservationUpdateApi.execute(reservation)
      }
    }
  }

  /**
   * 予定の新規作成が完了したとき
   */
  const handleCompletePostReservation = () => {
    setNewDialogOpen(false)
    calendarSet.resetDate()
    instrumentShowApi.execute(currentInstrumentId)
  }

  /**
   * 予定の更新が完了したときのイベント
   */
  const handleCompleteUpdateReservation = () => {
    setEditDialogOpen(false)
    calendarSet.resetDate()
    instrumentShowApi.execute(currentInstrumentId)
  }

  /**
   * 機器の更新が完了したときのイベント
   */
  const handleCompleteUpdateInstrument = () => {
    setEditInstrumentDialogOpen(false)
    instrumentShowApi.execute(currentInstrumentId)
    calendarSet.resetDate()
  }

  /**
   * 機器が管理対象外にされたときのイベント
   */
  const handleCompleteExcludeManagement = () => {
    setEditInstrumentDialogOpen(false)
    instrumentShowApi.execute(currentInstrumentId)
  }

  /**
   * 機器のViewModelを取得する
   */
  const getInstrumentVM = (): InstrumentViewModel => {
    return new InstrumentViewModel(instrumentShowApi.response.instrument)
  }

  /**
   * 廃棄するボタンのクリック
   */
  const handleClickDiscardButton = () => {
    globalState.confirm(warningDiscardUnmanagedInstrumentDescription, (event) => {
      const instrumentId = instrumentShowApi.response.instrument.id
      if (event === 'ok' && instrumentId) {
        discardApi.execute(instrumentId)
      }
    })
  }

  const handleClickRestoreManagementButton = () => {
    restoreManagementInstrumentApi.execute(currentInstrumentId)
  }

  const handleCompleteDiscard = () => {
    // 管理対象外一覧画面に遷移
    props.history.push(MypageRouteHelper.unmanagedInstrumentIndex())
  }

  const handleCompleteRestoreManagement = () => {
    // 詳細画面の再読み込み
    setEditInstrumentDialogOpen(false)
    instrumentShowApi.execute(currentInstrumentId)
    calendarSet.resetDate()
  }

  useEffectSkipFirst(() => {
    if (discardApi.isSuccess()) {
      props.history.push(MypageRouteHelper.unmanagedInstrumentIndex())
    }
  }, [discardApi.loading])

  useEffectSkipFirst(() => {
    if (restoreManagementInstrumentApi.isSuccess()) {
      handleCompleteRestoreManagement()
    }
  }, [restoreManagementInstrumentApi.loading])

  return (
    <>
      <EditInstrumentFormDialog
        cancelPolicies={cancelPolicyIndexApi.response?.cancelPolicies}
        rooms={roomIndexApi.response?.rooms}
        onComplete={handleCompleteUpdateInstrument}
        onCompleteExcludeManagement={handleCompleteExcludeManagement}
        onCompleteDiscard={handleCompleteDiscard}
        onCompleteRestoreManagement={handleCompleteRestoreManagement}
        instrument={instrumentShowApi.response.instrument}
        open={editInstrumentDialogOpen}
        onCancel={() => setEditInstrumentDialogOpen(false)}
        dealers={partnersApi.response.dealers}
        outsideDealers={partnersApi.response.outsideDealers}
        onCompleteRemoveImage={() => instrumentShowApi.execute(currentInstrumentId)}
        instrumentCategories={categoryIndexApi.response?.instrumentCategories}
        approvalSettings={approvalsIndexApi.response.approvalSettings}
      />
      {instrumentShowApi.isSuccess() && (
        <>
          <UserMemoModal
            memoableId={instrumentShowApi.response.instrument.id!}
            memoableType="Instrument"
            modalState={memoModalState}
            onClose={() => memoModalState.setOpen(false)}
          />
          <NewDefectReportFormDialog
            modalState={newDefectReportModal}
            instrumentId={Number(props.match.params.id)}
            onComplete={() => {
              instrumentEventTableSet.handleRefetchDefectReports()
              newDefectReportModal.setOpen(false)
              instrumentShowApi.execute(currentInstrumentId)
            }}
          />
          <NewDefectReportWithEstimateFormDialog
            modalSet={newReportWithEstimateModal}
            onComplete={() => {
              instrumentEventTableSet.handleRefetchRepair()
              newReportWithEstimateModal.closeModal()
              instrumentShowApi.execute(currentInstrumentId)
            }}
            outsideDealers={partnersApi.response.outsideDealers}
          />
          <NewRepairEventFormDialog
            modalState={newRepairEventModal}
            instrumentId={Number(props.match.params.id)}
            onComplete={() => {
              instrumentEventTableSet.handleRefetchRepairEvents()
              newRepairEventModal.setOpen(false)
              instrumentShowApi.execute(currentInstrumentId)
              calendarSet.resetDate() // 修理により予定が作成されることがあるため再読み込み
            }}
          />
          {instrumentShowApi.response.instrument.canReserve && (
            <>
              <NewReservationFormDialog
                isInstrument
                reservable={instrumentShowApi.response.instrument}
                apiHooks={usePostInstrumentReservationApi}
                open={newDialogOpen}
                reservationTerm={instrumentShowApi.response.instrument.reservationTerm!}
                calendarData={calendarData}
                onCancel={() => setNewDialogOpen(false)}
                onComplete={handleCompletePostReservation}
              />
              <EditReservationFormDialog
                isInstrument
                reservable={instrumentShowApi.response.instrument}
                open={editDialogOpen}
                reservationTerm={instrumentShowApi.response.instrument.reservationTerm!}
                reservation={editReservation}
                onCancel={() => setEditDialogOpen(false)}
                onComplete={handleCompleteUpdateReservation}
                managingLaboratoryIds={reservationIndexApi.response.managingLaboratoryIds}
              />
              <ConstraintRelationFormDialog
                open={constraintSetOpen}
                reservationConstraintRelations={constraintRelationApi.response.reservationConstraintRelations}
                reservationTargetableType="Instrument"
                reservationTargetableId={Number(props.match.params.id)}
                onComplete={() => {
                  constraintRelationApi.execute()
                }}
                onClose={() => setConstraintSetOpen(false)}
              />
            </>
          )}
          <ContentHeader>
            <ContentTitle title={`機器詳細 - ${instrumentShowApi.response.instrument.instrumentBasicInfo?.name}`}>
              {isUnmanaged && <StatusLabel text="管理対象外" color="red" />}
              {instrumentShowApi.response.instrument.isShared && <StatusLabel text="共有中" color="green" />}
            </ContentTitle>
            <Flex>
              {isUnmanaged ? (
                <>
                  <ThemedButton onClick={handleClickDiscardButton} color="error" variant="text">
                    廃棄する
                  </ThemedButton>
                  <ThemedButton onClick={handleClickRestoreManagementButton} color="success">
                    もとに戻す
                  </ThemedButton>
                </>
              ) : (
                <>
                  <ThemedButton onClick={() => newDefectReportModal.setOpen(true)} color="primary" variant="outlined">
                    不具合報告の作成
                  </ThemedButton>
                  {instrumentShowApi.response.instrument.ownerableId === mypageState.currentUser.laboratoryId &&
                    instrumentShowApi.response.instrument.ownerableType === 'Laboratory' &&
                    currentUser.authority?.repair && (
                      <>
                        <Tooltip title={instrumentShowApi.response.instrument.isShared ? '共有機器のためラボからの修理見積の作成はできません。' : ''}>
                          <div>
                            <ThemedButton
                              color="primary"
                              variant="outlined"
                              disabled={instrumentShowApi.response.instrument.isShared}
                              onClick={() => newReportWithEstimateModal.openModal(instrumentShowApi.response.instrument)}
                            >
                              修理見積の作成
                            </ThemedButton>
                          </div>
                        </Tooltip>
                        <ThemedButton onClick={() => newRepairEventModal.setOpen(true)} color="primary" variant="outlined">
                          修理履歴の作成
                        </ThemedButton>
                      </>
                    )}
                  {instrumentShowApi.response.instrument.canReserve && (
                    <Scroll to="instrument-calendar" smooth duration={500} offset={-HEADER_OFFSET}>
                      <ThemedButton color="success" variant="outlined">
                        <InsertInvitation fontSize="small" style={{ marginRight: 3 }} />
                        カレンダー
                      </ThemedButton>
                    </Scroll>
                  )}
                </>
              )}
            </Flex>
          </ContentHeader>
          <Flex flexDirection="column" style={{ width: '100%', marginBottom: 20 }}>
            {(currentUser.authority?.repair || currentUser.authority?.proofread) && (
              <InstrumentEventTable instrumentEventTableSet={instrumentEventTableSet} />
            )}

            <Flex style={{ width: '100%' }}>
              <Flex flexDirection="column" style={{ width: '100%' }}>
                <InstrumentDataCard
                  instrument={instrumentShowApi.response.instrument}
                  canEdit
                  onClickEdit={
                    currentUser.authority?.instrument && getInstrumentVM().isInstrumentOwner(currentUser.laboratory!)
                      ? () => setEditInstrumentDialogOpen(true)
                      : undefined
                  }
                  onClickMemo={() => memoModalState.setOpen(true)}
                  onClickConstraintSet={
                    instrumentShowApi.response.instrument.canReserve && getInstrumentVM().isInstrumentOwner(currentUser.laboratory!)
                      ? () => setConstraintSetOpen(true)
                      : undefined
                  }
                />
                <InstrumentReservationTermDataCard
                  instrument={instrumentShowApi.response.instrument}
                  myConstraintSets={constraintRelationApi.response.myConstraintSets}
                />
                <InstrumentInfoAttributeExColumnDataCard
                  clientType="user"
                  instrumentInfoAttribute={instrumentShowApi.response.instrument?.instrumentInfoAttribute}
                  dealerExAttributes={instrumentShowApi.response.instrument?.dealerExAttrs || []}
                  exAttributes={instrumentShowApi.response.instrument?.exAttributes || []}
                  onUpdate={handleCompleteUpdateInstrument}
                  editable={currentUser.authority?.instrument}
                />
              </Flex>
              {(currentUser.authority?.repair || currentUser.authority?.proofread) && (
                <Flex flexDirection="column" style={{ width: '100%' }}>
                  {currentUser.authority?.repair && (
                    <>
                      <DefectReportDataCard
                        defectReport={instrumentEventTableSet.defectReport}
                        outsideDealers={partnersApi.response.outsideDealers}
                        canEdit={canReportEdit}
                        dealer={instrumentShowApi.response.instrument.dealer}
                        onCompleteEdit={
                          (instrumentEventTableSet.defectReport?.instrument?.ownerableType === 'Laboratory' &&
                            instrumentEventTableSet.defectReport?.instrument?.ownerableId === currentUser.laboratoryId) ||
                          (instrumentEventTableSet.defectReport?.reporterableId === currentUser.id &&
                            instrumentEventTableSet.defectReport?.reporterableType === 'User')
                            ? (report?: DefectReport) => {
                                instrumentEventTableSet.setDefectReport(report)
                                instrumentEventTableSet.handleRefetchDefectReports()
                                instrumentShowApi.execute(currentInstrumentId)
                              }
                            : undefined
                        }
                        onCompleteCreateEvent={
                          instrumentEventTableSet.defectReport?.instrument?.ownerableType === 'Laboratory' &&
                          instrumentEventTableSet.defectReport?.instrument?.ownerableId === currentUser.laboratoryId
                            ? (event: RepairEvent) => {
                                instrumentEventTableSet.setRepairEvent(event)
                                instrumentEventTableSet.handleRefetchRepair()
                                setCanReportEdit(false)
                                instrumentShowApi.execute(currentInstrumentId)
                              }
                            : undefined
                        }
                        onCompleteCreateEstimate={
                          instrumentEventTableSet.defectReport?.instrument?.ownerableType === 'Laboratory' &&
                          instrumentEventTableSet.defectReport?.instrument?.ownerableId === currentUser.laboratoryId
                            ? (estimate: RepairEstimate) => {
                                instrumentEventTableSet.setRepairEstimate(estimate)
                                instrumentEventTableSet.handleRefetchRepair()
                                setCanReportEdit(false)
                                instrumentShowApi.execute(currentInstrumentId)
                              }
                            : undefined
                        }
                      />
                      <RepairEstimateDataCard repairEstimate={instrumentEventTableSet.repairEstimate} showDetail />
                      <RepairEventDataCard
                        repairEvent={instrumentEventTableSet.repairEvent}
                        canEdit={canREventEdit}
                        onCompleteUpdate={(event?: RepairEvent) => {
                          instrumentEventTableSet.setRepairEvent(event)
                          instrumentEventTableSet.handleRefetchRepairEvents()
                          setCanReportEdit(false)
                          instrumentShowApi.execute(currentInstrumentId)
                          calendarSet.resetDate() // 修理の変更により予定が変更されることがあるため再読み込み
                        }}
                      />
                    </>
                  )}
                  {currentUser.authority?.proofread && (
                    <ProofreadEventDataCard proofreadEvent={instrumentEventTableSet.proofreadEvent} showDetail />
                  )}
                  <InstrumentProofreadInfoDataCard instrument={instrumentShowApi.response.instrument} />
                </Flex>
              )}
            </Flex>
          </Flex>
          {instrumentShowApi.response.instrument.canReserve && !isUnmanaged && (
            // 予約可能かつmanagedな機器はカレンダーが閲覧可能
            <Paper ref={calendarRef} id="instrument-calendar" style={{ padding: 40, marginTop: 20 }}>
              <Calendar
                editable={true}
                dateClick={handleClickDate}
                events={eventInputs}
                eventClick={handleClickEvent}
                eventDrop={handleDropEvent}
                datesSet={(arg) => calendarSet.setDate(arg.view.currentStart)}
                eventAllow={handleEventAllow}
              />
            </Paper>
          )}
        </>
      )}
    </>
  )
}

export default withRouter(InstrumentShow)
