import { useRepruaIndexApi, useRepruaPostApi, useRepruaDeleteApi } from 'api/shared_api_hooks'
import { ID } from 'entities'
import { DealerMemo, DealerMemoForm } from 'entities/dealer_memo'
import { ApiSet, BaseResponse, Form, IndexApiSet } from 'rac'
import { toPluralSnakeCase } from 'utils/utils'

import { AxiosHttpClient } from '../axios'

type UserMemosResponse = BaseResponse & {
  dealerMemos: DealerMemo[]
}

type UserMemosIndexApiSet = IndexApiSet<UserMemosResponse> & {
  execute: () => void
}

export type DealerMemoableType = 'Instrument'

/**
 * 一覧
 */
export function usePartnerFetchDealerMemosApi(memoableId: number, memoableType: DealerMemoableType): UserMemosIndexApiSet {
  const apiPath = `${toPluralSnakeCase(memoableType)}/${memoableId}/dealer_memos`
  const apiSet = useRepruaIndexApi<UserMemosResponse, unknown>(new AxiosHttpClient('dealer_user'), { initialResponse: { dealerMemos: [] } })

  const execute = () => {
    apiSet.execute(apiPath)
  }

  return { ...apiSet, execute }
}

type UserMemoResponse = BaseResponse & {}

/**
 * 登録
 */
export function usePartnerPostDealerMemosApi(
  memoableId: number,
  memoableType: DealerMemoableType,
): ApiSet<UserMemoResponse> & { execute: (form: Form<DealerMemoForm>) => void } {
  const apiSet = useRepruaPostApi<UserMemoResponse, DealerMemoForm>(new AxiosHttpClient('dealer_user'), { initialResponse: {} })

  const execute = (form: Form<DealerMemoForm>) => {
    const apiPath = `${toPluralSnakeCase(memoableType)}/${memoableId}/dealer_memos`
    apiSet.execute(apiPath, form)
  }

  return { ...apiSet, execute }
}

/**
 * 削除
 */
export function usePartnerDeleteDealerMemosApi(): ApiSet<UserMemoResponse> & { execute: (id: ID) => void } {
  const apiSet = useRepruaDeleteApi<UserMemoResponse>(new AxiosHttpClient('dealer_user'), { initialResponse: {} })

  const execute = (id: ID) => {
    const apiPath = `dealer_memos/${id}`
    apiSet.execute(apiPath)
  }

  return { ...apiSet, execute }
}
