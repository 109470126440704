import AlarmIcon from '@mui/icons-material/Alarm'
import { Checkbox, FormControlLabel, Collapse, Typography, Link } from '@mui/material'
import { Flex } from 'components/shared/flex'
import { NumberField, SelectField } from 'components/shared/form/input'
import { Hint } from 'components/shared/hint'
import theme from 'config/theme'
import { InstrumentForm } from 'entities/instrument'
import { Form, ApiError } from 'rac'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { PartnerRouteHelper } from 'routes/partner'

type ProofreadAlarmFormProps = {
  form: Form<InstrumentForm>
  error: ApiError
  ownerableType: 'laboratory' | 'dealer'
  isSelectedClientHasDealerUsers?: () => boolean
}

export const ProofreadAlarmForm = React.memo(
  ({ form, error, ownerableType, isSelectedClientHasDealerUsers }: ProofreadAlarmFormProps) => {
    const history = useHistory()

    return (
      <div style={{ width: '80%' }}>
        <Flex style={{ marginBottom: isSelectedClientHasDealerUsers && isSelectedClientHasDealerUsers() ? 10 : 0 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={form.object.alarmEnable}
                onChange={(e) => {
                  form.update((f) => (f.alarmEnable = e.target.checked))
                }}
              />
            }
            label={
              <Flex alignItems="center">
                <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
                  校正のアラームの有効化
                </Typography>
                <Hint
                  title={
                    <>
                      <div>アラームで設定した日に</div>
                      <div>{ownerableType === 'laboratory' ? '機器の担当者' : '機器に紐づく顧客の担当者'}宛に通知します。</div>
                    </>
                  }
                />
              </Flex>
            }
          />
        </Flex>
        <Collapse in={form.object.alarmEnable}>
          {isSelectedClientHasDealerUsers && !isSelectedClientHasDealerUsers() && (
            <div style={{ marginBottom: 13, marginLeft: 32 }}>
              <div style={{ color: theme.palette.error.main }}>※顧客の担当者が設定されていません</div>
              <Link
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  history.push({ pathname: `${PartnerRouteHelper.settingShow()}`, state: { settingItemKey: 'userAuthorities' } })
                }
              >
                担当者を設定する
              </Link>
            </div>
          )}
          {ownerableType === 'laboratory' && !form.object.managerId && (
            <div style={{ marginBottom: 13, marginLeft: 32 }}>
              <div style={{ color: theme.palette.error.main }}>※担当者が設定されていません</div>
            </div>
          )}
          <Flex alignItems="flex-start">
            <div style={{ marginTop: 10 }}>
              <AlarmIcon />
            </div>
            <NumberField
              attr={['proofreadAlarmAttributes', 'value']}
              form={form}
              apiError={error}
              InputProps={{ inputProps: { min: 0 } }}
            />
            <SelectField
              form={form}
              labelId="proofread-alarm-unit-label"
              attr={['proofreadAlarmAttributes', 'unit']}
              label="単位"
              data={[
                { value: 'day', label: '日' },
                { value: 'week', label: '週' },
                { value: 'month', label: '月' },
              ]}
              apiError={error}
              width={100}
            />
            <div style={{ marginTop: 12, width: 150 }}>前に通知</div>
          </Flex>
        </Collapse>
      </div>
    )
  },
)
