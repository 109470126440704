import { Reservation, ReservationSearchForm, ReservationsResponse } from 'entities/reservation'
import moment from 'moment'
import { Form, IndexApiSet } from 'rac'
import { useState } from 'react'

import { useEffectSkipFirst } from './use_effect_skip_first'

export type CalendarSet = {
  reservations: Reservation[]
  viewCurrent: Date
  setDate: (date: Date) => void
  resetDate: (baseDate?: Date) => void
}

export const useCalendar = <T extends ReservationSearchForm>(
  api: IndexApiSet<ReservationsResponse> & { execute: (form: Form<T>) => void },
  form: Form<T>,
) => {
  // カレンダーで実際に表示されている月
  const [viewCurrent, setViewCurrent] = useState<Date>(new Date())
  // 結果取得済みの月を格納する配列
  const [fetched, setFetched] = useState<string[]>([])
  const [reservations, setReservations] = useState<Reservation[]>([])

  // カレンダー描画時の動作
  const setDate = (baseDate: Date) => {
    // 表示されている月としてviewCurrent更新
    setViewCurrent(baseDate)

    const current = moment(baseDate).startOf('month')
    const next = current.clone().add(1, 'months')
    const prev = current.clone().subtract(1, 'months')
    const newMoments = [prev, current, next]
    const unfetches: moment.Moment[] = []

    newMoments.forEach((moment) => {
      if (!fetched.includes(moment.toString())) {
        unfetches.push(moment)
      }
    })

    // unfetchesをもとにformを更新
    if (unfetches.length > 0) {
      const start = moment.min(unfetches)
      const end = moment.max(unfetches).clone().endOf('month')
      const strUnfetches: string[] = unfetches.map((m) => m.toString())
      form.update((f) => {
        f.startAt = start.toString()
        f.endAt = end.toString()
      })
      setFetched([...fetched, ...strUnfetches])
    }
  }

  // 予約作成・更新時に再取得する
  const resetDate = (baseDate?: Date) => {
    // 予約データをクリア
    setReservations([])

    // 再取得の基準となる月
    const base = baseDate || viewCurrent
    const current = moment(base).startOf('month')
    const next = current.clone().add(1, 'months')
    const prev = current.clone().subtract(1, 'months')
    const newMoments = [prev, current, next]
    const strNewMoments: string[] = newMoments.map((m) => m.toString())
    // 取得済み情報もリセット
    setFetched(strNewMoments)

    // newMomentsをもとにformを更新
    const start = moment.min(newMoments)
    const end = moment.max(newMoments).clone().endOf('month')
    // 日付範囲以外のform.objectが変更されていた場合、手動で実行
    if (start.toString() === form.object.startAt && end.toString() === form.object.endAt) {
      fetchReservations()
    } else {
      form.update((f) => {
        f.startAt = start.toString()
        f.endAt = end.toString()
      })
    }
  }

  useEffectSkipFirst(() => {
    fetchReservations()
  }, [form.object.startAt, form.object.endAt])

  const fetchReservations = () => {
    api.execute(form)
  }

  useEffectSkipFirst(() => {
    if (api.isSuccess()) {
      setReservations([...reservations, ...api.response.reservations])
    }
  }, [api.loading])

  const calendarSet: CalendarSet = {
    reservations,
    viewCurrent,
    setDate,
    resetDate,
  }

  return calendarSet
}
