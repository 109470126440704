import { Tooltip, Typography } from '@mui/material'
import { usePartnerPatchRepairEstimateApi } from 'api/partner/repair_estimate'
import AttachmentsList from 'components/shared/data/attachments_list'
import DataBlock from 'components/shared/data/data_block'
import DataCard from 'components/shared/data/data_card'
import DataLabel from 'components/shared/data/data_label'
import FormBlock from 'components/shared/data/form_block'
import { DropdownMenu, DropdownMenuProps } from 'components/shared/dropdown_menu'
import { Flex } from 'components/shared/flex'
import { FilesInput, SelectField, StringField, NumberField } from 'components/shared/form/input'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { getDeliveryTermSelectData, RepairEstimate, RepairEstimateForm } from 'entities/repair_estimate'
import { useForm } from 'rac'
import React, { useContext, useEffect, useState } from 'react'
import { RepairEstimateViewModel } from 'view_models/repair_estimate'

type RepairEstimateDataCardProps = {
  repairEstimate?: RepairEstimate
  editDisabled?: boolean
  emptyMessage?: string
  onComplete?: () => void
}
export const RepairEstimateDataCard = (props: RepairEstimateDataCardProps) => {
  const globalState = useContext(GlobalStateContext)
  const actions = {
    INPUT_SURVEY: 'input_survey',
    INPUT_ESTIMATE: 'input_estimate',
  }
  type Actions = typeof actions[keyof typeof actions]
  const [currentAction, setCurrentAction] = useState<Actions | null>(null)
  const listItems = [
    {
      text: '現地調査について入力',
      onClick: () => {
        setCurrentAction(actions.INPUT_SURVEY)
      },
    },
    {
      text: '修理見積を入力',
      onClick: () => {
        setCurrentAction(actions.INPUT_ESTIMATE)
      },
    },
  ]
  const isActionDisable = () => {
    return props.repairEstimate?.aasmState === 'ordered'
  } // 修理依頼済ステータスではディーラは編集不可

  const ActionMenuProps: DropdownMenuProps = {
    listItems,
    id: 'estimate-action',
    buttonText: 'アクション',
    buttonColor: 'primary',
    buttonVariant: 'contained',
    disabled: isActionDisable(),
  }

  const handleClickCancel = () => {
    setCurrentAction(null)
  }

  const handleOnComplete = () => {
    globalState.setNotificationMessage({ body: '送信しました' })
    props.onComplete && props.onComplete()
    setCurrentAction(null)
  }

  return (
    <DataCard>
      <Flex alignItems="center" justifyContent="space-between">
        <Typography variant="h6" style={{ marginRight: 30 }}>
          修理見積
        </Typography>
        {props.repairEstimate && !props.editDisabled && (
          <Tooltip title={isActionDisable() ? '修理依頼済ステータスの見積を編集することはできません' : ''}>
            <div>
              <DropdownMenu {...ActionMenuProps} />
            </div>
          </Tooltip>
        )}
      </Flex>
      {props.repairEstimate ? (
        <>
          {currentAction === actions.INPUT_SURVEY ? (
            <InputSurveyComponent repairEstimate={props.repairEstimate} onClickCancel={handleClickCancel} onComplete={handleOnComplete} />
          ) : currentAction === actions.INPUT_ESTIMATE ? (
            <InputEstimateComponent repairEstimate={props.repairEstimate} onClickCancel={handleClickCancel} onComplete={handleOnComplete} />
          ) : (
            <EstimateDetailComponent repairEstimate={props.repairEstimate} />
          )}
        </>
      ) : (
        <span>{props.emptyMessage || '修理見積を選択してください'}</span>
      )}
    </DataCard>
  )
}

type EstimateDetailComponentProps = {
  repairEstimate: RepairEstimate
}
const EstimateDetailComponent = (props: EstimateDetailComponentProps) => {
  const [vm, setVm] = useState(new RepairEstimateViewModel(props.repairEstimate))
  useEffect(() => {
    setVm(new RepairEstimateViewModel(props.repairEstimate))
  }, [props.repairEstimate])

  return (
    <>
      <DataBlock>
        <DataLabel label="見積元" value={vm.getLaboratoryName()} />
        <DataLabel label="修理費（税別）" value={vm.getPriceExTaxText()} />
        <DataLabel label="修理費（税込）" value={vm.getPriceText()} />
      </DataBlock>
      <DataBlock>
        <DataLabel label="納期" value={vm.getDeliveryTerm()} />
        <DataLabel label="見積No." value={vm.getEstimateNumber()} />
        <div style={{ width: '100%' }}>
          <AttachmentsList fileAttachments={props.repairEstimate.fileAttachments || []} />
        </div>
      </DataBlock>
      <DataBlock>
        <DataLabel label="現地調査について" value={vm.getSurvey()} />
      </DataBlock>
      <DataBlock>
        <DataLabel label="引き渡し・修理作業の進め方・備考等" value={vm.getNote()} />
      </DataBlock>
    </>
  )
}

type InputSurveyComponentProps = {
  repairEstimate: RepairEstimate
  onClickCancel: () => void
  onComplete: () => void
}
const InputSurveyComponent = (props: InputSurveyComponentProps) => {
  const form = useForm<RepairEstimateForm>(props.repairEstimate, 'repair_estimate')
  const apiSet = usePartnerPatchRepairEstimateApi(form, props.repairEstimate.id!)
  const handleClickSubmit = () => {
    apiSet.execute()
  }
  const handleClickCancel = () => {
    props.onClickCancel()
  }

  useEffect(() => {
    if (apiSet.isSuccess()) {
      props.onComplete()
    }
  }, [apiSet.loading])

  return (
    <>
      <FormBlock>
        <StringField label="現地調査について入力" attr="survey" form={form} apiError={apiSet.apiError} multiline />
      </FormBlock>
      <FormBlock>
        <ThemedButton color="primary" onClick={handleClickSubmit} style={{ marginRight: 10 }}>
          送信
        </ThemedButton>
        <ThemedButton color="secondary" onClick={handleClickCancel}>
          キャンセル
        </ThemedButton>
      </FormBlock>
    </>
  )
}

type InputEstimateComponentProps = {
  repairEstimate: RepairEstimate
  onClickCancel: () => void
  onComplete: () => void
}
const InputEstimateComponent = (props: InputEstimateComponentProps) => {
  const form = useForm<RepairEstimateForm>(
    { ...props.repairEstimate, attachmentsAttributes: [{}], aasmState: 'estimated' },
    'repair_estimate',
  )
  const apiSet = usePartnerPatchRepairEstimateApi(form, props.repairEstimate.id!)
  const handleClickSubmit = () => {
    apiSet.execute()
  }
  const handleClickCancel = () => {
    props.onClickCancel()
  }
  const inputProps = {
    form,
    apiError: apiSet.apiError,
  }

  const getTotalText = () => `¥${(form.object.total || 0).toLocaleString()}`

  const taxRate = 0.1
  useEffect(() => {
    const newTax = Math.ceil((form.object.subtotal || 0) * taxRate)
    const newTotal = (form.object.subtotal || 0) + newTax
    form.update((f) => {
      f.tax = newTax
      f.total = newTotal
    })
  }, [form.object.subtotal])
  useEffect(() => {
    const newTotal = (form.object.subtotal || 0) + (form.object.tax || 0)
    form.update((f) => {
      f.total = newTotal
    })
  }, [form.object.tax])

  useEffect(() => {
    if (apiSet.isSuccess()) {
      props.onComplete()
    }
  }, [apiSet.loading])

  return (
    <>
      <FormBlock justifyContent="space-between">
        <NumberField label="修理費用（税別）" attr="subtotal" required {...inputProps} style={{ paddingRight: 20 }} />
        <NumberField label="消費税" attr="tax" required {...inputProps} style={{ paddingRight: 20 }} />
        <DataLabel label="合計額" value={getTotalText()} />
      </FormBlock>
      <FormBlock>
        <SelectField
          label="納期"
          labelId="delivery-label"
          attr="deliveryTerm"
          data={getDeliveryTermSelectData()}
          required
          {...inputProps}
          style={{ width: '50%' }}
        />
      </FormBlock>
      <FormBlock>
        <StringField label="引き渡し・修理作業の進め方・備考等" attr="note" multiline {...inputProps} />
      </FormBlock>
      <FormBlock>
        <div style={{ width: '100%' }}>
          <div style={{ marginBottom: 10 }}>
            <AttachmentsList fileAttachments={props.repairEstimate.fileAttachments || []} />
          </div>
          <FilesInput attr="attachmentsAttributes" {...inputProps} />
        </div>
      </FormBlock>
      <FormBlock>
        <ThemedButton color="primary" onClick={handleClickSubmit} style={{ marginRight: 10 }}>
          送信
        </ThemedButton>
        <ThemedButton color="secondary" onClick={handleClickCancel}>
          キャンセル
        </ThemedButton>
      </FormBlock>
    </>
  )
}

export default RepairEstimateDataCard
