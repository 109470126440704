import AccessDeniedMessage from 'components/shared/access_denied_message'
import { GlobalStateContext } from 'contexts/global_state_context'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useEffectSkipFirst } from 'utils/hooks'

type AuthorityStateContainerProps = {}

const AuthorityStateContainer: React.FC<AuthorityStateContainerProps> = ({ children }) => {
  const globalState = useContext(GlobalStateContext)
  const history = useHistory()
  const [isAuthorized, setIsAuthorized] = useState(false)

  useEffect(() => {
    if (globalState.statusCode === 403) {
      setIsAuthorized(false)
    } else {
      setIsAuthorized(true)
    }
  }, [globalState.statusCode])

  useEffectSkipFirst(() => {
    setIsAuthorized(true)
  }, [history.location.pathname])

  return isAuthorized ? <>{children}</> : <AccessDeniedMessage target="該当情報" />
}

export default AuthorityStateContainer
