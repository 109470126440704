import { TableCell, TableCellProps } from '@mui/material'
import React, { ReactNode } from 'react'

export type ExTableCellProps = TableCellProps & {
  width?: number
  minWidth?: number
  open?: boolean
  children?: ReactNode
}

export const ExTableCell: React.FC<ExTableCellProps> = (props: ExTableCellProps) => {
  const { width, open, minWidth, ...rest } = props

  const getStyle = (): React.CSSProperties => {
    const styles: React.CSSProperties = {}
    if (width) {
      styles['width'] = width
      styles['minWidth'] = width
    }

    if (minWidth) {
      styles['minWidth'] = minWidth
    }

    if (open) {
      styles['display'] = 'table-cell'
    } else {
      styles['display'] = 'none'
    }
    return styles
  }

  return (
    <TableCell {...rest} style={{ ...props.style, ...getStyle() }} padding={props.open ? 'normal' : 'none'}>
      {props.children}
    </TableCell>
  )
}

ExTableCell.defaultProps = {
  open: true,
}

export default ExTableCell
