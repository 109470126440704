import { useDeleteUserMemosApi, useFetchUserMemosApi, usePostUserMemosApi, UserMemoableType } from 'api/mypage/user_memo'
import { Flex } from 'components/shared/flex'
import { StringField } from 'components/shared/form/input'
import { MemoTable } from 'components/shared/memo_table'
import Modal, { ModalState } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { MypageStateContext } from 'contexts/mypage_state_context'
import { UserMemo, UserMemoForm } from 'entities/user_memo'
import { useForm } from 'rac'
import React, { useContext, useEffect } from 'react'
import { useEffectSkipFirst } from 'utils/hooks/use_effect_skip_first'

type UserMemoModalProps = {
  memoableId: number
  memoableType: UserMemoableType
  modalState: ModalState
  onClose: () => void
}

export const UserMemoModal = ({ memoableId, memoableType, modalState, onClose }: UserMemoModalProps) => {
  const indexApi = useFetchUserMemosApi(memoableId, memoableType)
  const postApi = usePostUserMemosApi(memoableId, memoableType)
  const deleteApi = useDeleteUserMemosApi()
  const form = useForm<UserMemoForm>({ body: '' }, 'userMemo')
  const mypageState = useContext(MypageStateContext)
  const globalState = useContext(GlobalStateContext)

  useEffect(() => {
    if (memoableId) {
      indexApi.execute()
    }
  }, [memoableId])

  useEffect(() => {
    const user = mypageState.currentUser
    if (user) {
      form.update((f) => {
        f.userName = `${user.familyName} ${user.givenName}`
        f.senderId = user.id!
      })
    }
  }, [mypageState.currentUser, postApi.loading])

  const handleClickPostMemo = () => {
    postApi.execute(form)
  }

  useEffectSkipFirst(() => {
    if (postApi.isSuccess() || deleteApi.isSuccess()) {
      indexApi.execute()
    }
  }, [postApi.loading, deleteApi.loading])

  const handleClickDeleteMemo = (memo: UserMemo) => {
    globalState.confirm('本当に削除しますか？', (event) => {
      if (event === 'ok') {
        deleteApi.execute(memo.id!)
      }
    })
  }

  return (
    <Modal title="メモ" modalId="user-memo-modal" modalState={modalState} onClose={onClose} aria-labelledby="form-dialog-title" size="md">
      <MemoTable
        memos={indexApi.response.userMemos}
        onClickDeleteMemo={handleClickDeleteMemo}
        loading={indexApi.loading}
        senderId={mypageState.currentUser.id}
      />
      <Flex alignItems="flex-end" style={{ marginTop: 10 }}>
        <div style={{ flexGrow: 1 }}>
          <StringField multiline attr="body" form={form} apiError={postApi.apiError} label="本文" />
        </div>
        <div style={{ width: 60, marginBottom: 10 }}>
          <ThemedButton color="success" onClick={() => handleClickPostMemo()}>
            送信
          </ThemedButton>
        </div>
      </Flex>
    </Modal>
  )
}
