import { Paper } from '@mui/material'
import { useExportRepairEstimatesApi, useFetchRepairEstimatesApi } from 'api/mypage/repair_estimate'
import RepairEstimateTable from 'components/mypage/repair_estimate/repair_estimate_table'
import { ContentHeader, ContentTitle } from 'components/shared/content'
import { SearchBar } from 'components/shared/search_bar'
import ThemedButton from 'components/shared/themed_button'
import { RepairEstimateSearchForm } from 'entities/repair_estimate'
import { useForm } from 'rac'
import React, { useEffect } from 'react'
import { StaticContext } from 'react-router'
import { RouteComponentProps, withRouter } from 'react-router-dom'

export type RepairEstimateIndexProps = {}

type Props = RouteComponentProps<{}, StaticContext, RepairEstimateIndexProps>
export const RepairEstimateIndex: React.FC<Props> = (props) => {
  const searchForm = useForm<RepairEstimateSearchForm>({})
  const estimateIndexApi = useFetchRepairEstimatesApi(searchForm)
  const exportApi = useExportRepairEstimatesApi(searchForm)

  const handleSearch = () => {
    estimateIndexApi.executeAndPageReset()
  }
  const handleClickExport = (format: 'xlsx') => {
    exportApi.execute(format)
  }

  useEffect(() => {
    estimateIndexApi.execute()
  }, [])

  return (
    <>
      <ContentHeader
        subHeader={
          <>
            <ThemedButton color="secondary" onClick={() => handleClickExport('xlsx')} style={{ marginLeft: 10 }}>
              Excel出力
            </ThemedButton>
          </>
        }
      >
        <ContentTitle title="修理見積一覧" />
        <SearchBar<RepairEstimateSearchForm>
          form={searchForm}
          searchAttr="searchText"
          placeholder="機器名称・依頼者・不具合内容・見積もりNo.等で検索"
          onSubmit={handleSearch}
        />
      </ContentHeader>
      <Paper>
        <RepairEstimateTable indexApi={estimateIndexApi} history={props.history} showInstrument />
      </Paper>
    </>
  )
}

export default withRouter(RepairEstimateIndex)
