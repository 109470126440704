import { AxiosHttpClient } from 'api/axios'
import { useRepruaShowApi, useRepruaPatchApi } from 'api/shared_api_hooks'
import { PrintImageType, PrintSetting, PrintSettingForm } from 'entities/print_setting'
import { ApiSet, BaseResponse, Form } from 'rac'

export type PrintSettingResponse = BaseResponse & {
  printSetting: PrintSetting
}

/**
 * 取得
 */
export function useShowPrintSettingApi(): ApiSet<PrintSettingResponse> & { execute: (imageType: PrintImageType) => void } {
  const api = useRepruaShowApi<PrintSettingResponse, {}>(new AxiosHttpClient('user'), { initialResponse: { printSetting: {} } })

  const execute = (imageType: PrintImageType) => {
    const path = 'print_setting'
    api.execute(path, { imageType: imageType })
  }

  return { ...api, execute }
}

/**
 * 更新
 */
export function usePatchPrintSettingApi(
  form: Form<PrintSettingForm>,
): ApiSet<PrintSettingResponse> & { execute: (imageType: PrintImageType) => void } {
  const api = useRepruaPatchApi<PrintSettingResponse, PrintSettingForm>(new AxiosHttpClient('user'), {
    initialResponse: { printSetting: {} },
  })

  const execute = (imageType: PrintImageType) => {
    const path = 'print_setting'
    api.execute(path, { ...form.object, ...{ imageType: imageType } })
  }

  return { ...api, execute }
}
