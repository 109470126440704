import { Autocomplete, TextareaAutosize, Tooltip } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import { OutlinedInputProps } from '@mui/material/OutlinedInput'
import { SelectProps } from '@mui/material/Select'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { Hint } from 'components/shared/hint'
import React, { CSSProperties, ReactElement } from 'react'
import { Control, Controller, ControllerRenderProps, FieldPath, FieldValues, Path, PathValue } from 'react-hook-form'
import { makeStyles } from 'tss-react/mui'

/** react-hook-formの共通のprops */
type BaseFieldProps<T extends FieldValues> = {
  name: FieldPath<T>
  control: Control<T>
  children?: ReactElement
  disabled?: boolean
  required?: boolean
  id?: number
  tooltip?: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal
  label?: string
  containerStyle?: CSSProperties
  tooltipStyle?: CSSProperties
  inputStyle?: CSSProperties
  isError?: boolean
}

/**
 * 共通のpropsを持つコンポーネント
 */
export const RhfBaseField = <T extends FieldValues>(props: RhfTextFieldProps<T>) => {
  return (
    <div
      style={{ width: '100%', ...props.containerStyle }}
      className={`form-group ${props.required ? 'required' : ''} ${props.isError ? 'has-error' : ''}`}
    >
      <div>
        {props.label && (
          <label htmlFor={`${props.id}`} style={{ position: 'relative' }}>
            {props.label}
            {props.tooltip && <Hint title={props.tooltip} tooltipStyle={props.tooltipStyle} />}
          </label>
        )}
      </div>
      {props.children}
    </div>
  )
}

/**
 * TextFieldのBaseのインプット（ TextField のラッパー）
 */
export const RhfBaseTextField = <T extends FieldValues>(props: RhfTextFieldProps<T>) => {
  return (
    <RhfBaseField {...props}>
      <TextField
        type={props.type}
        variant="outlined"
        fullWidth
        size="small"
        {...props.TextFieldProps}
        {...props.field}
        inputRef={props.field?.ref}
        disabled={props.disabled}
        placeholder={props.placeholder}
        inputProps={{ style: props.disabled ? { ...props.inputStyle, ...styles.disabled } : props.inputStyle, accept: props.accept }}
        value={props.value}
        onChange={(val) => (props.onChange ? props.onChange(val) : {})}
      />
    </RhfBaseField>
  )
}

/**
 * 文字列用のインプット（ TextField のラッパー）
 */
export const RhfTextField = <T extends FieldValues>(props: RhfTextFieldProps<T>) => {
  return (
    <RhfBaseField {...props}>
      <Controller<T>
        control={props.control}
        name={props.name}
        render={({ field: { onChange, value } }) => (
          <RhfBaseTextField
            {...props}
            value={props.value ? props.value : value}
            onChange={(e) => (props.onChange ? props.onChange(e) : onChange(e))}
          />
        )}
      />
    </RhfBaseField>
  )
}
type RhfTextFieldProps<T extends FieldValues> = BaseFieldProps<T> & {
  TextFieldProps?: Partial<TextFieldProps>
  value?: string | number
  field?: ControllerRenderProps<T, Path<T>>
  type?: string
  onFocus?: () => void
  onPressEnter?: () => void
  disabled?: boolean
  placeholder?: string
  onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onChange?: (...event: any[]) => void
  accept?: string
}

/**
 * 数字用のインプット（ TextField のラッパー）
 */
export const RhfNumberField = <T extends FieldValues>(props: RhfTextFieldProps<T>) => {
  return (
    <RhfBaseField {...props} type="number">
      <Controller<T>
        control={props.control}
        name={props.name}
        render={({ field: { onChange, value } }) => (
          <RhfBaseTextField
            {...props}
            type="number"
            value={props.value ? props.value : value}
            onChange={(e) => (props.onChange ? props.onChange(e) : onChange(e))}
          />
        )}
      />
    </RhfBaseField>
  )
}

/**
 * TextAreaのインプット（ TextareaAutosize のラッパー）
 */
export const RhfTextArea = <T extends FieldValues>(props: RhfTextFieldProps<T>) => {
  return (
    <RhfBaseField {...props}>
      <Controller
        control={props.control}
        name={props.name}
        render={({ field }) => {
          return <TextareaAutosize placeholder={props.placeholder} style={{ width: '100%', height: '60px' }} {...field} />
        }}
      />
    </RhfBaseField>
  )
}

/**
 * セレクター用のインプット
 */
export const RhfSelectField = <T extends FieldValues>(props: SelectFieldProps<T>) => {
  return (
    <RhfBaseField {...props}>
      <Controller
        control={props.control}
        name={props.name}
        render={({ field }) => (
          <TextField
            select
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{ style: { fontSize: 13 }, ...props.InputProps }}
            SelectProps={{ displayEmpty: props.includeBlank, ...props.SelectProps, defaultValue: props.defaultValue || '' }}
            disabled={props.disabled}
            style={props.disabled ? { ...styles.disabled, ...props.selectStyle } : { ...props.selectStyle }}
            {...field}
            onChange={(e) => {
              field.onChange(e)
              props.onChange && props.onChange(e)
            }}
          >
            {props.includeBlank && <MenuItem value="">{props.blankLabel || '未選択'}</MenuItem>}
            {props.items.map((item) => (
              <MenuItem key={`${item.label}-${item.value}`} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
    </RhfBaseField>
  )
}
type SelectFieldProps<T extends FieldValues> = BaseFieldProps<T> & {
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  items: SelectItem[]
  defaultValue?: string | number
  includeBlank?: boolean
  blankLabel?: string
  SelectProps?: Partial<SelectProps>
  InputProps?: Partial<OutlinedInputProps>
  selectStyle?: CSSProperties
}
export type SelectItem = {
  value: number | string
  label: string
}

const styles = {
  disabled: {
    backgroundColor: '#eee',
  },
}

/**
 * 検索機能つきセレクター用のインプット
 */
export const RhfAutocompleteSelectField = (props: AutocompleteSelectFieldProps) => {
  const { classes } = useAutocompleteClasses()
  const { name, control, items } = props

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Autocomplete
          classes={classes}
          options={props.blankItem ? [props.blankItem, ...items] : items}
          renderInput={(params) => (
            <Tooltip title={props.tooltip}>
              <div>
                <TextField {...params} label={props.innerLabel} />
              </div>
            </Tooltip>
          )}
          disableClearable={props.disableClearable}
          isOptionEqualToValue={(option, val) => option.value === val.value}
          onChange={(_, item) => {
            field.onChange(item?.value)
          }}
          value={{
            label: items.find((i) => i.value === field.value)?.label || props.blankItem?.label || '',
            value: field.value,
          }}
          disabled={props.disabled}
        />
      )}
    />
  )
}
type AutocompleteSelectFieldProps = {
  name: string
  control: Control
  items: SelectItem[]
  innerLabel?: string
  blankItem?: SelectItem
  defaultItem?: SelectItem
  disableClearable?: boolean
  disabled?: boolean
  tooltip?: string
}
const useAutocompleteClasses = makeStyles()({
  inputRoot: { padding: '12px !important', paddingRight: '65px !important', minWidth: 240 },
  input: { padding: '0 !important' },
})
