import { AxiosHttpClient } from 'api/axios'
import { useRepruaShowApi } from 'api/shared_api_hooks'
import { ID, PagingResponse } from 'entities'
import { Instrument } from 'entities/instrument'
import { RepairInfo } from 'entities/repair_info'
import { ApiSet, BaseResponse } from 'rac'

export type RepairInfosResponse = PagingResponse & {
  repairEvents: RepairInfo[]
  instrument: Instrument
}

export type RepairInfoResponse = BaseResponse & {
  repairInfo: RepairInfo
}

/**
 * 取得
 */
export function usePartnerFetchRepairInfoApi(): ApiSet<RepairInfoResponse> & { execute: (id: ID) => void } {
  const api = useRepruaShowApi<RepairInfoResponse, { id: ID }>(new AxiosHttpClient('dealer_user'), { initialResponse: { repairInfo: {} } })

  const execute = (id: ID) => {
    const path = `repair_infos/${id}`
    api.execute(path, { id: id })
  }

  return { ...api, execute }
}
