import Modal, { ModalState } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { Reservation } from 'entities/reservation'
import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { ReservationViewModel } from 'view_models/reservation'

type ReservationDetailDialogProps = {
  modalState: ModalState
  reservation: Reservation
}

export const ReservationDetailModal = ({ modalState, reservation }: ReservationDetailDialogProps) => {
  const reservationVM = new ReservationViewModel(reservation)
  const { classes } = useStyles()
  const handleClose = () => {
    modalState.setOpen(false)
  }

  return (
    <div>
      <Modal
        modalId="reservation-detail"
        modalState={modalState}
        title="予約詳細"
        footer={
          <>
            <ThemedButton onClick={handleClose} color="secondary">
              閉じる
            </ThemedButton>
          </>
        }
      >
        <div className={classes.detailRow}>
          <div className={classes.rowTitle}>予約種別</div>
          <div className={classes.rowValue}>{reservationVM.getKindText()}</div>
        </div>
        <div className={classes.detailRow}>
          <div className={classes.rowTitle}>予約対象</div>
          <div className={classes.rowValue}>{reservationVM.reservableName()}</div>
        </div>
        <div className={classes.detailRow}>
          <div className={classes.rowTitle}>予約時間</div>
          <div className={classes.rowValue}>{`${reservationVM.getStartAtText()} 〜 ${reservationVM.getEndAtText()}`}</div>
        </div>
        <div className={classes.detailRow}>
          <div className={classes.rowTitle}>予約者</div>
          <div className={classes.rowValue}>{reservation.reservedUserName}</div>
        </div>
        <div className={classes.detailRow}>
          <div className={classes.rowTitle}>メモ・コメント</div>
          <div className={classes.rowValue}>{reservation.comment || 'メモなし'}</div>
        </div>
      </Modal>
    </div>
  )
}

const useStyles = makeStyles()({
  detailRow: {
    marginBottom: 10,
  },
  rowTitle: {
    fontSize: 14,
  },
  rowValue: {
    fontSize: 16,
  },
})
