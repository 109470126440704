import { AxiosHttpClient, HttpClientType } from 'api/axios'
import { useRepruaDeleteApi, useRepruaIndexApi, useRepruaPatchApi, useRepruaPostApi } from 'api/shared_api_hooks'
import { ExColumn, ExColumnForm } from 'entities/ex_column'
import { ApiSet, BaseResponse, Form, IndexApiSet } from 'rac'

type ExColumnsResponse = BaseResponse & {
  exColumns: ExColumn[]
}

/**
 * 一覧
 */
export type FetchExColumnsApiType = IndexApiSet<ExColumnsResponse> & { execute: () => void }
export function useFetchExColumnsApi(clientType: HttpClientType): IndexApiSet<ExColumnsResponse> & { execute: () => void } {
  const api = useRepruaIndexApi<ExColumnsResponse, {}>(new AxiosHttpClient(clientType), { initialResponse: { exColumns: [] } })

  const execute = () => {
    const path = 'ex_columns'
    api.execute(path)
  }

  return { ...api, execute }
}

type ExColumnResponse = BaseResponse & {
  exColumn: ExColumn
}
export type ExColumnApiSet = ApiSet<ExColumnResponse> & { execute: (form: Form<ExColumnForm>) => void }
/**
 * 登録
 */
export function usePostExColumnApi(clientType: HttpClientType): ExColumnApiSet {
  const api = useRepruaPostApi<ExColumnResponse, ExColumnForm>(new AxiosHttpClient(clientType), {
    initialResponse: { exColumn: {} },
  })

  const execute = (form: Form<ExColumnForm>) => {
    const path = 'ex_columns'
    api.execute(path, form)
  }

  return { ...api, execute }
}

/**
 * 更新
 */
export function usePatchExColumnApi(clientType: HttpClientType): ExColumnApiSet {
  const api = useRepruaPatchApi<ExColumnResponse, ExColumnForm>(new AxiosHttpClient(clientType), {
    initialResponse: { exColumn: {} },
  })

  const execute = (form: Form<ExColumnForm>) => {
    const path = `ex_columns/${form.object.id}`
    api.execute(path, form.object)
  }

  return { ...api, execute }
}

/**
 * 削除
 */
export function useDeleteExColumnApi(clientType: HttpClientType): ExColumnApiSet {
  const api = useRepruaDeleteApi<ExColumnResponse>(new AxiosHttpClient(clientType), {
    initialResponse: { exColumn: {} },
  })

  const execute = (form: Form<ExColumnForm>) => {
    const path = `ex_columns/${form.object.id}`
    api.execute(path)
  }

  return { ...api, execute }
}
