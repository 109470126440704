import { Paper } from '@mui/material'
import { Flex } from 'components/shared/flex'
import theme from 'config/theme'
import { ProofreadEventState } from 'entities/proofread_event'
import { ProofreadPlan } from 'entities/proofread_plan'
import React, { useEffect, useState, ReactNode } from 'react'
import { makeStyles } from 'tss-react/mui'
import { ProofreadPlanViewModel } from 'view_models/proofread_plan'

const useStyles = makeStyles()({
  rootUl: {
    listStyle: 'none',
    display: 'flex',
    justifyContent: 'space-around',
    padding: 0,
    margin: '24px 0',
  },
  list: {
    width: '100%',
    border: 'solid black',
    borderWidth: '2px 0px 2px 2px',
    textAlign: 'center',
    height: 48,
    lineHeight: '46px',
    cursor: 'pointer',
    userSelect: 'none',
    marginRight: 0,
  },
  paper: {},
  rootDiv: {
    display: 'none',
  },
  active: {
    display: 'block',
  },
  selected: {
    color: '#fff',
    fontWeight: 600,
    backgroundColor: theme.palette.info.main,
    border: 'none',
    paddingTop: 2,
  },
  triangle: {
    '&::before': {
      borderLeft: '17px solid black',
      position: 'absolute',
      content: '""',
      width: 0,
      height: 0,
      borderRight: '12px solid transparent',
      borderTop: '24px solid transparent',
      borderBottom: '24px solid transparent',
    },
    '&::after': {
      borderLeft: '15px solid #fafafa',
      position: 'absolute',
      content: '""',
      width: 0,
      height: 0,
      marginTop: 2,
      borderRight: '10px solid transparent',
      borderTop: '22px solid transparent',
      borderBottom: '22px solid transparent',
    },
  },
  selectedTriangle: {
    '&::before': {
      borderLeft: `17px solid ${theme.palette.info.main}`,
      position: 'absolute',
      content: '""',
      width: 0,
      height: 0,
      borderRight: '12px solid transparent',
      borderTop: '24px solid transparent',
      borderBottom: '24px solid transparent',
    },
  },
})

export type StepTab = { id: ProofreadEventState; label: string; component: ReactNode }

type ProofreadStepTabProps = {
  data: StepTab[]
  currentStepId?: ProofreadEventState
  plan: ProofreadPlan
  onSelect: (step: ProofreadEventState) => void
}
export const ProofreadStepTab: React.FC<ProofreadStepTabProps> = (props) => {
  const { classes } = useStyles()

  const [vm, setVm] = useState(new ProofreadPlanViewModel(props.plan))

  useEffect(() => {
    setVm(new ProofreadPlanViewModel(props.plan))
  }, [props.plan])

  return (
    <div>
      <div className={classes.rootUl}>
        {props.data.map((step) => (
          <Flex style={{ width: '100%', margin: '0 15px' }} childMargin={false} key={`button-${step.id}`}>
            <div
              className={`${classes.list} ${step.id === props.currentStepId ? classes.selected : ''}`}
              onClick={() => props.onSelect(step.id)}
            >
              {step.label} - {vm.getStateCount(step.id)} 件
            </div>
            <div className={step.id === props.currentStepId ? classes.selectedTriangle : classes.triangle} />
          </Flex>
        ))}
      </div>
      <Paper className={classes.paper}>
        {props.data.map((step: StepTab) => (
          <div className={step.id === props.currentStepId ? classes.active : classes.rootDiv} key={`table-${step.id}`}>
            {step.component}
          </div>
        ))}
      </Paper>
    </div>
  )
}

export default ProofreadStepTab
