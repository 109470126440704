import React from 'react'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(() => ({
  item: {
    textAlign: 'center',
  },
  wrapper: {
    height: 30,
    overflow: 'hidden',
  },
  arrow: {
    width: '85%',
    display: 'inline-block',
  },
  label: {
    color: '#fff',
    lineHeight: '30px',
  },
  afterArrow: {
    display: 'inline-block',
    marginBottom: -10,
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '15px 0 15px 15px',
  },
  description: {
    paddingTop: 12,
  },
}))

const approvalColor = (steo: number) => {
  switch (steo) {
    case 1:
      return '#97defa'
    case 2:
      return '#87cefa'
    case 3:
      return '#77befa'
    case 4:
      return '#67aefa'
    default:
      return '#579efa'
  }
}
type ApprovalChartProps = {
  visible: boolean
  step: number
  width: string
  text: string | React.ReactNode
}
export const ApprovalChart: React.FC<ApprovalChartProps> = (props) => {
  const { visible, step, width } = props
  const { classes } = useStyles()

  return (
    <li className={classes.item} style={{ width: width, display: visible ? '' : 'none' }}>
      <div className={classes.wrapper}>
        <div className={classes.arrow} style={{ backgroundColor: approvalColor(step) }}>
          <span className={classes.label}>{step}次承認</span>
        </div>
        <div className={classes.afterArrow} style={{ borderColor: `transparent transparent transparent ${approvalColor(step)}` }} />
      </div>
      <div className={classes.description}>{props.text}</div>
    </li>
  )
}

export default ApprovalChart
