import { AxiosHttpClient } from 'api/axios'
import { useRepruaIndexApi } from 'api/shared_api_hooks'
import { PagingResponse, ID } from 'entities'
import { ProofreadEvent, ProofreadEventSearchForm } from 'entities/proofread_event'
import { IndexApiSet } from 'rac'
import { useEffectSkipFirst } from 'utils/hooks'

export type ProofreadEventsResponse = PagingResponse & {
  proofreadEvents: ProofreadEvent[]
}

/**
 * 一覧
 */
export type ProofreadEventsIndexApiSet = IndexApiSet<ProofreadEventsResponse> & { execute: () => void }
export function useFetchInstrumentProofreadEventsApi(instrumentId?: ID): ProofreadEventsIndexApiSet {
  const api = useRepruaIndexApi<ProofreadEventsResponse, ProofreadEventSearchForm>(new AxiosHttpClient('user'), {
    initialResponse: { proofreadEvents: [], totalCount: 0 },
  })

  const execute = () => {
    const path = `instruments/${instrumentId}/proofread_events`
    api.execute(path)
  }

  useEffectSkipFirst(() => {
    execute()
  }, [api.pageSet.page, api.pageSet.perPage, api.orderSet.order, api.orderSet.orderBy])

  return { ...api, execute }
}
