import { ID, PagingResponse } from 'entities'
import { BulkUpdateInstrumentStateForm, getInstrumentSearchQuery, Instrument, InstrumentSearchForm } from 'entities/instrument'
import { Laboratory } from 'entities/laboratory'
import { IndexApiSet, Form, ApiSet, BaseResponse } from 'rac'
import { useEffectSkipFirst } from 'utils/hooks'

import { AxiosHttpClient } from '../axios'
import { useRepruaIndexApi, useRepruaPatchApi, useRepruaDeleteApi } from '../shared_api_hooks'

type UnmanagedInstrumentsResponse = PagingResponse & {
  instruments: Instrument[]
}

/**
 * 一覧
 */
export function usePartnerFetchUnmanagedInstrumentsApi(
  searchForm: Form<InstrumentSearchForm>,
): IndexApiSet<UnmanagedInstrumentsResponse> & { execute: () => void } {
  const api = useRepruaIndexApi<UnmanagedInstrumentsResponse, InstrumentSearchForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { instruments: [], totalCount: 0 },
  })

  const { searchText, ...rest } = searchForm.object

  const execute = () => {
    const path = 'unmanaged_instruments'
    api.execute(path, { params: rest, searchQuery: getInstrumentSearchQuery(searchText) })
  }

  useEffectSkipFirst(() => {
    execute()
  }, [api.pageSet.page, api.pageSet.perPage, api.orderSet.order, api.orderSet.orderBy])

  return { ...api, execute }
}

type UnmanagedInstrumentResponse = BaseResponse & {
  instrument: Instrument
  currentLaboratory?: Laboratory
  managedLaboratories?: Laboratory[]
}

/**
 * もとに戻す
 */
export function usePartnerRestoreManagementUnmanagedInstrumentApi(): ApiSet<UnmanagedInstrumentResponse> & {
  execute: (id: ID) => void
} {
  const api = useRepruaPatchApi<UnmanagedInstrumentResponse, {}>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { instrument: {} },
  })

  const execute = (id: ID) => {
    const path = `unmanaged_instruments/${id}/restore_management`
    api.execute(path)
  }

  return { ...api, execute }
}

/**
 * 一括でもとに戻す
 */
export function usePartnerBulkRestoreManagementUnmanagedInstrumentApi(): ApiSet<UnmanagedInstrumentResponse> & {
  execute: (bulkUpdateInstrumentForm: BulkUpdateInstrumentStateForm) => void
} {
  const api = useRepruaPatchApi<UnmanagedInstrumentResponse, BulkUpdateInstrumentStateForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { instrument: {} },
  })

  const execute = (instrumentForm: BulkUpdateInstrumentStateForm) => {
    const path = 'unmanaged_instruments/bulk_restore_management'
    api.execute(path, instrumentForm)
  }

  return { ...api, execute }
}

/**
 * 廃棄
 */
export function usePartnerDeleteUnmanagedInstrumentApi(): ApiSet<UnmanagedInstrumentResponse> & { execute: (id: ID) => void } {
  const api = useRepruaDeleteApi<UnmanagedInstrumentResponse>(new AxiosHttpClient('dealer_user'), { initialResponse: { instrument: {} } })

  const execute = (id: ID) => {
    const path = `unmanaged_instruments/${id}`
    api.execute(path)
  }

  return { ...api, execute }
}

/**
 * 一括でもとに戻す
 */
export function usePartnerBulkDiscardInstrumentApi(): ApiSet<UnmanagedInstrumentResponse> & {
  execute: (bulkUpdateInstrumentForm: BulkUpdateInstrumentStateForm) => void
} {
  const api = useRepruaPatchApi<UnmanagedInstrumentResponse, BulkUpdateInstrumentStateForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { instrument: {} },
  })

  const execute = (instrumentForm: BulkUpdateInstrumentStateForm) => {
    const path = 'unmanaged_instruments/bulk_discard'
    api.execute(path, instrumentForm)
  }

  return { ...api, execute }
}
