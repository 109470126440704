import { Checkbox, FormControlLabel, Tooltip } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import MuiDialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { usePatchReservationApi, useDeleteReservationApi } from 'api/mypage/reservation'
import { ReservationResponse } from 'api/mypage/rooms/room_reservation'
import { Flex } from 'components/shared/flex'
import { StringField, DateField, DateTimeField, SelectField } from 'components/shared/form/input'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { ID } from 'entities'
import { Instrument } from 'entities/instrument'
import { Reservation, ReservationForm } from 'entities/reservation'
import { ReservationTerm } from 'entities/reservation_term'
import { Room } from 'entities/room'
import moment from 'moment'
import { ApiError, ApiSet, useForm, Form } from 'rac'
import React, { useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { MypageRouteHelper } from 'routes/mypage'
import { useEffectSkipFirst } from 'utils/hooks/index'
import { ReservationViewModel } from 'view_models/reservation'

type DialogContentProps = {
  form: Form<Reservation>
  apiError: ApiError
  reservationTerm: ReservationTerm
  isInstrument: boolean
  type: 'new' | 'edit'
  isRelateToProofreadEvent?: boolean
  isDisabledEdit?: boolean
  isDisabledEditStartAt?: boolean
}
const DialogContent = ({
  form,
  apiError,
  reservationTerm,
  isInstrument,
  type,
  isRelateToProofreadEvent,
  isDisabledEdit,
  isDisabledEditStartAt,
}: DialogContentProps) => {
  const reservationVM = new ReservationViewModel(form.object)
  const cancelPolicy = reservationTerm.cancelPolicy

  const data = [
    { value: 'use', label: '利用' },
    { value: 'proofread', label: '校正' },
  ]

  return (
    <MuiDialogContent>
      <Flex style={{ marginTop: 10 }}>
        {reservationTerm.unit === 'day' || form.object.allDay ? (
          <>
            <DateField
              label="開始日時"
              attr="startAt"
              form={form}
              apiError={apiError}
              required
              disabled={isDisabledEditStartAt || isRelateToProofreadEvent}
            />
            <DateField
              label="終了日時"
              attr="endAt"
              form={form}
              apiError={apiError}
              required
              disabled={isDisabledEdit || isRelateToProofreadEvent}
            />
          </>
        ) : (
          <>
            <DateTimeField
              label="開始日時"
              attr="startAt"
              form={form}
              apiError={apiError}
              required
              disabled={isDisabledEditStartAt || isRelateToProofreadEvent}
            />
            <DateTimeField
              label="終了日時"
              attr="endAt"
              form={form}
              apiError={apiError}
              required
              disabled={isDisabledEdit || isRelateToProofreadEvent}
            />
          </>
        )}
      </Flex>
      {cancelPolicy && reservationVM.isOverDeadline(cancelPolicy) && (
        <>
          <div>
            {type === 'new'
              ? '以下のキャンセルポリシーが設定されているため、予約後のキャンセルはできません。'
              : 'キャンセル期限が過ぎている為、管理者以外は予約をキャンセルできません。'}
          </div>
          <div style={{ color: 'red', paddingTop: 4, fontWeight: 'bold' }}>
            キャンセルポリシー：{reservationVM.fixDeadline(cancelPolicy.deadline)}前までキャンセル可能
          </div>
        </>
      )}
      {!isRelateToProofreadEvent && (
        <FormControlLabel
          control={
            <Checkbox
              checked={form.object.allDay}
              defaultChecked={form.object.allDay || reservationTerm.unit === 'day'}
              disabled={reservationTerm.unit === 'day' || isDisabledEdit}
              onChange={(e) => {
                form.update((f) => (f.allDay = e.target.checked))
              }}
            />
          }
          label="終日"
        />
      )}

      {isInstrument && type === 'new' && (
        <Flex>
          <SelectField
            form={form}
            labelId="kind-label"
            attr="kind"
            label="種別"
            data={data}
            apiError={apiError}
            formControlProps={{ required: true }}
            disabled={isDisabledEdit}
            defaultValue="use"
          />
        </Flex>
      )}
      {!isRelateToProofreadEvent && (
        <Flex>
          <StringField form={form} attr="comment" label="コメント" apiError={apiError} multiline disabled={isDisabledEdit} />
        </Flex>
      )}
    </MuiDialogContent>
  )
}

export type CalendarData = {
  date: string
  type: string
}

type NewReservationFormDialogProps = {
  open: boolean
  calendarData: CalendarData
  reservable: Instrument | Room
  reservationTerm: ReservationTerm
  onCancel: () => void
  onComplete: (reservation: Reservation) => void
  apiHooks: () => ApiSet<ReservationResponse> & { execute: (instrumentId: ID, form: Form<ReservationForm>) => void }
  isInstrument: boolean
}

export const NewReservationFormDialog = (props: NewReservationFormDialogProps) => {
  const reservationForm = useForm<Reservation>({}, 'reservation')
  const api = props.apiHooks()
  const globalState = useContext(GlobalStateContext)

  useEffectSkipFirst(() => {
    props.onComplete(api.response.reservation)
  }, [api.response.reservation])

  useEffect(() => {
    globalState.setLoading(api.loading)
  }, [api.loading])

  const handleSubmit = () => {
    if (reservationForm.object.kind === 'repair') {
      globalState.confirm('変更した修理期間に含まれる予定はキャンセルされますが、よろしいですか？', (event) => {
        if (event === 'ok') {
          api.execute(props.reservable.id!, reservationForm)
        }
      })
    } else {
      api.execute(props.reservable.id!, reservationForm)
    }
  }

  useEffect(() => {
    const date = moment(props.calendarData.date)
    if (props.calendarData.type === 'dayGridMonth') {
      // 月表示カレンダーの場合、デフォルトの時間が00:00なので、現在時間分足す
      const now = moment()
      date.add(now.hour(), 'hour')
    }
    const startAt = date.format('Y/MM/DD HH:mm')
    date.add(1, 'hour')
    const endAt = date.format('Y/MM/DD HH:mm')
    reservationForm.update((f) => {
      f.startAt = startAt
      f.endAt = endAt
    })
  }, [props.calendarData])

  return (
    <div>
      <Dialog open={props.open} onClose={props.onCancel} aria-labelledby="form-dialog-title" maxWidth="xl">
        <DialogTitle id="form-dialog-title">予約作成</DialogTitle>
        <DialogContent
          form={reservationForm}
          apiError={api.apiError}
          reservationTerm={props.reservationTerm}
          isInstrument={props.isInstrument}
          type="new"
          isDisabledEdit={false}
        />
        <DialogActions>
          <ThemedButton onClick={props.onCancel} color="secondary">
            閉じる
          </ThemedButton>
          <ThemedButton onClick={handleSubmit} color="success">
            予約
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}

type EditReservationFormDialogProps = {
  open: boolean
  reservable: Instrument | Room
  reservationTerm: ReservationTerm
  reservation: Reservation
  onComplete: (reservation: Reservation) => void
  onCancel: () => void
  isInstrument: boolean
  managingLaboratoryIds?: number[]
}

export const EditReservationFormDialog = (props: EditReservationFormDialogProps) => {
  const isRelateToProofreadEvent = props.reservation.proofreadEventId != null
  const reservationForm = useForm<ReservationForm>(props.reservation, 'reservation')
  const globalState = useContext(GlobalStateContext)
  const deleteApi = useDeleteReservationApi()
  const patchApi = usePatchReservationApi()
  const history = useHistory()

  useEffectSkipFirst(() => {
    props.onComplete(patchApi.response.reservation)
  }, [patchApi.response.reservation])

  const handleSubmit = () => {
    if (reservationForm.object.kind === 'repair') {
      globalState.confirm('変更した修理期間に含まれる予定はキャンセルされますが、よろしいですか？', (event) => {
        if (event === 'ok') {
          patchApi.execute(reservationForm.object)
        }
      })
    } else {
      patchApi.execute(reservationForm.object)
    }
  }

  const handleClickProofreadButton = (proofreadPlanId: number) => {
    history.push(MypageRouteHelper.proofreadPlanShow(proofreadPlanId))
  }

  useEffect(() => {
    reservationForm.set(() => props.reservation)
  }, [props.reservation])

  useEffect(() => {
    globalState.setLoading(patchApi.loading)
  }, [patchApi.loading])

  useEffect(() => {
    if (deleteApi.isSuccess()) {
      props.onComplete(deleteApi.response.reservation)
    }
  }, [deleteApi.loading])

  const handleClickDelete = (reservation: Reservation) => {
    let message: string
    if (reservation.repairEvent) {
      message = '紐付いている修理履歴も削除されます。よろしいですか？'
    } else {
      message = '本当に削除しますか？'
    }

    globalState.confirm(message!, (event) => {
      if (event === 'ok') {
        deleteApi.execute(props.reservation.id!)
      }
    })
  }

  return (
    <div>
      <Dialog open={props.open} onClose={props.onCancel} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">予約編集</DialogTitle>
        <DialogContent
          form={reservationForm}
          apiError={patchApi.apiError}
          reservationTerm={props.reservationTerm}
          isInstrument={props.isInstrument}
          type="edit"
          isDisabledEdit={props.reservation.isDisabledEdit}
          isDisabledEditStartAt={props.reservation.isDisabledEditStartAt}
          isRelateToProofreadEvent={isRelateToProofreadEvent}
        />
        <DialogActions>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
            {!isRelateToProofreadEvent && (
              <Tooltip title={props.reservation.cancelTooltipText || ''}>
                <div>
                  <ThemedButton
                    color="error"
                    onClick={(e) => handleClickDelete(props.reservation)}
                    disabled={props.reservation.isDisabledCancel}
                  >
                    予約キャンセル
                  </ThemedButton>
                </div>
              </Tooltip>
            )}
            <div style={{ display: 'flex' }}>
              <ThemedButton onClick={props.onCancel} color="secondary" style={{ marginRight: 10 }}>
                閉じる
              </ThemedButton>

              {isRelateToProofreadEvent ? (
                <div>
                  <ThemedButton
                    color="primary"
                    onClick={() => props.reservation.proofreadPlanId && handleClickProofreadButton(props.reservation.proofreadPlanId)}
                    disabled={props.reservation.isDisabledEdit}
                  >
                    校正
                  </ThemedButton>
                </div>
              ) : (
                <Tooltip title={props.reservation.editTooltipText || ''}>
                  <div>
                    <ThemedButton color="success" onClick={handleSubmit} disabled={props.reservation.isDisabledEdit}>
                      更新
                    </ThemedButton>
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  )
}
