import { Collapse } from '@mui/material'
import { usePartnerPatchProofreadEventApi } from 'api/partner/proofread_event'
import { Flex } from 'components/shared/flex'
import { CheckBoxField, FilesInput, MonthField, NumberField, SelectField, StringField } from 'components/shared/form/input'
import Modal, { ModalSet } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { ProofreadEvent, ProofreadEventForm } from 'entities/proofread_event'
import moment from 'moment'
import { useForm } from 'rac'
import React, { useContext, useState } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'
import { ProofreadEventViewModel } from 'view_models/proofread_event'

const results = [
  { label: '取り下げ', value: 'canceled' },
  { label: '不合格', value: 'ng' },
  { label: '合格', value: 'ok' },
]

type AttachedResultEventFormDialogProps = {
  modalSet: ModalSet<ProofreadEvent>
  onComplete: (event: ProofreadEvent) => void
}
export const AttachedResultEventFormDialog: React.FC<AttachedResultEventFormDialogProps> = (props) => {
  const [eventVm, setEventVm] = useState(new ProofreadEventViewModel(props.modalSet.item))
  const globalState = useContext(GlobalStateContext)

  const patchForm = useForm<ProofreadEventForm>({
    ...props.modalSet.item,
    aasmState: 'approved_result',
    result: 'ok',
    scheduledDate: props.modalSet.item.scheduledDate || moment().toString(),
    proofreadInfoAttributes: {
      ...props.modalSet.item.instrument?.proofreadInfo,
    },
  })
  const patchApi = usePartnerPatchProofreadEventApi(patchForm)

  const handleSubmit = () => {
    globalState.confirm('校正結果を登録します。よろしいですか？', (e) => {
      if (e === 'ok') {
        patchApi.execute()
      }
    })
  }

  useEffectSkipFirst(() => {
    setEventVm(new ProofreadEventViewModel(props.modalSet.item))
    patchForm.resetForm()
  }, [props.modalSet.item.id])

  useEffectSkipFirst(() => {
    if (!patchApi.isSuccess()) return
    props.onComplete(patchApi.response.proofreadEvent)
  }, [patchApi.loading])

  return (
    <Modal
      modalId="event-planning-form"
      modalState={props.modalSet.modalState}
      title="校正計画実施結果"
      subTitle={eventVm.getInstrumentName()}
      size="sm"
      footer={
        <>
          <ThemedButton onClick={props.modalSet.closeModal}>閉じる</ThemedButton>
          <ThemedButton
            color="primary"
            onClick={handleSubmit}
            disabled={
              props.modalSet.item.repairState === 'unnecessary'
                ? patchForm.object.result === 'ng' && !patchForm.object.approveNoRepair
                : !patchForm.object.approveUneditable
            }
          >
            登録
          </ThemedButton>
        </>
      }
    >
      <SelectField label="合否" form={patchForm} attr="result" apiError={patchApi.apiError} required data={results} labelId="result-id" />
      <div>
        <label>添付ファイル</label>
        <FilesInput form={patchForm} attr="attachmentsAttributes" apiError={patchApi.apiError} />
      </div>

      <Flex style={{ marginTop: 12 }}>
        <MonthField label="校正月" form={patchForm} attr="scheduledDate" required apiError={patchApi.apiError} />
        <NumberField label="校正周期" form={patchForm} attr={['proofreadInfoAttributes', 'cycle']} apiError={patchApi.apiError} />
        <SelectField
          form={patchForm}
          labelId="proofread-unit-label"
          attr={['proofreadInfoAttributes', 'cycleUnit']}
          label="周期単位"
          data={[
            { value: 'month', label: '月毎' },
            { value: 'year', label: '年毎' },
          ]}
          apiError={patchApi.apiError}
        />
      </Flex>

      <StringField
        label="結果入力時メモ"
        form={patchForm}
        attr="noteOnResult"
        apiError={patchApi.apiError}
        multiline
        style={{ marginTop: 12 }}
      />
      <Collapse in={props.modalSet.item.repairState === 'unnecessary' && patchForm.object.result === 'ng'}>
        <CheckBoxField form={patchForm} attr="approveNoRepair" label="修理せずに校正結果を不合格にする" labelStyle={{ fontSize: 14 }} />
        <p style={{ color: 'red', fontSize: 12 }}>修理履歴を元に校正を進める場合は、不具合報告を作成してください</p>
      </Collapse>
      {props.modalSet.item.repairState !== 'unnecessary' && (
        <>
          <CheckBoxField form={patchForm} attr="approveUneditable" label="修理履歴を確定する" labelStyle={{ fontSize: 14 }} />
          <p style={{ color: 'red', fontSize: 12 }}>校正完了後は、この校正に紐づく修理履歴が編集できなくなります</p>
        </>
      )}
    </Modal>
  )
}

export default AttachedResultEventFormDialog
