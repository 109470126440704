import { usePartnerCompleteProofreadEventApi } from 'api/partner/proofread_event'
import { FilesInput, StringField } from 'components/shared/form/input'
import Modal, { ModalSet } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { ProofreadEventForm } from 'entities/proofread_event'
import { useForm } from 'rac'
import React, { useContext } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'

type FixDateEventFormDialogProps = {
  modalSet: ModalSet<number[]>
  planId: number
  onComplete: () => void
}
export const FixDateEventFormDialog: React.FC<FixDateEventFormDialogProps> = (props) => {
  const globalState = useContext(GlobalStateContext)

  const patchForm = useForm<ProofreadEventForm>({
    proofreadPlanId: props.planId,
    eventIds: props.modalSet.item,
  })
  const patchApi = usePartnerCompleteProofreadEventApi(patchForm)

  const handleSubmit = () => {
    globalState.confirm('校正結果を登録します。よろしいですか？', (e) => {
      if (e === 'ok') {
        patchApi.execute()
      }
    })
  }

  useEffectSkipFirst(() => {
    if (!props.modalSet.modalState.open) return
    patchForm.resetForm()
  }, [props.modalSet.modalState.open])

  useEffectSkipFirst(() => {
    if (!patchApi.isSuccess()) return
    props.onComplete()
  }, [patchApi.loading])

  return (
    <Modal
      title="校正完了"
      modalId="attach-event-modal"
      modalState={props.modalSet.modalState}
      size="sm"
      footer={
        <>
          <ThemedButton color="secondary" onClick={() => props.modalSet.closeModal()}>
            閉じる
          </ThemedButton>
          <ThemedButton color="primary" onClick={handleSubmit}>
            完了
          </ThemedButton>
        </>
      }
    >
      <StringField label="結果メモ" form={patchForm} attr="noteOnDealerResult" multiline />

      <label>添付ファイル</label>
      <FilesInput form={patchForm} attr="attachmentsAttributes" />
    </Modal>
  )
}

export default FixDateEventFormDialog
