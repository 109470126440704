import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useDeleteCancelPolicyApi, usePatchCancelPolicyApi, usePostCancelPoliciesApi } from 'api/mypage/cancel_policy'
import { Flex } from 'components/shared/flex'
import { NumberField, SelectField } from 'components/shared/form/input'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { CancelPolicy, CancelPolicyForm } from 'entities/cancel_policy'
import { ApiError, Form, useForm } from 'rac'
import React, { useState, useEffect, useContext } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'

type DialogFormContentProps = {
  cancelPolicyForm: Form<CancelPolicyForm>
  error: ApiError
}

const DialogFormContent: React.FC<DialogFormContentProps> = (props) => {
  const { cancelPolicyForm, error } = props

  // deadlineTypeがdayの場合、サーバーに送信されるキャンセル期限（deadline）には24をかける（日⇨時間へ変換）
  useEffect(() => {
    if (cancelPolicyForm.object.deadlineType === 'day') {
      cancelPolicyForm.newUpdateObject('deadline', cancelPolicyForm.object.displayDeadline! * 24)
    } else {
      cancelPolicyForm.newUpdateObject('deadline', cancelPolicyForm.object.displayDeadline)
    }
  }, [cancelPolicyForm.object.displayDeadline, cancelPolicyForm.object.deadlineType])

  return (
    <DialogContent style={{ overflowY: 'hidden' }}>
      <Flex>
        <NumberField label="キャンセル期限" attr="displayDeadline" form={cancelPolicyForm} apiError={error} style={{ width: 160 }} />
        <SelectField
          formStyleProps={{ minWidth: 80 }}
          form={cancelPolicyForm}
          attr="deadlineType"
          data={[
            { value: 'day', label: '日' },
            { value: 'hour', label: '時間' },
          ]}
          labelId="select-deadline-types"
          apiError={error}
        />
        <div style={{ minWidth: 150, fontSize: 13, height: 40, display: 'flex', alignItems: 'flex-end' }}>
          <div>前までキャンセル可能</div>
        </div>
      </Flex>
    </DialogContent>
  )
}

//
// 登録
//
type NewCancelPolicyFormDialogProps = {
  onComplete: () => void
}

export const NewCancelPolicyFormDialog: React.FC<NewCancelPolicyFormDialogProps> = (props: NewCancelPolicyFormDialogProps) => {
  const [open, setOpen] = useState(false)
  const api = usePostCancelPoliciesApi()
  const cancelPolicyForm = useForm<CancelPolicyForm>({ deadline: 0, displayDeadline: 0, deadlineType: 'day' }, 'cancelPolicy')
  const globalState = useContext(GlobalStateContext)

  useEffectSkipFirst(() => {
    globalState.setLoading(api.loading)

    if (api.isSuccess()) {
      globalState.setNotificationMessage({ body: 'キャンセルポリシーを登録しました' })
      setOpen(false)
      props.onComplete()
    }
  }, [api.loading])

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = () => {
    api.execute(cancelPolicyForm)
  }

  return (
    <div>
      <ThemedButton color="primary" onClick={handleOpen}>
        新規登録
      </ThemedButton>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle>キャンセルポリシー登録</DialogTitle>
        <DialogFormContent cancelPolicyForm={cancelPolicyForm} error={api.apiError} />
        <DialogActions>
          <ThemedButton onClick={handleClose} color="secondary">
            閉じる
          </ThemedButton>
          <ThemedButton onClick={handleSubmit} disabled={!cancelPolicyForm.object.deadlineType} color="success">
            登録
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}

//
// 更新
//
type EditCancelPolicyFormDialogProps = {
  cancelPolicy: CancelPolicy
  onComplete: () => void
}

export const EditCancelPolicyFormDialog: React.FC<EditCancelPolicyFormDialogProps> = (props: EditCancelPolicyFormDialogProps) => {
  const { cancelPolicy, onComplete } = props
  const api = usePatchCancelPolicyApi()
  const [open, setOpen] = useState(false)
  const cancelPolicyForm = useForm<CancelPolicyForm>(cancelPolicy, 'cancelPolicy')
  const globalState = useContext(GlobalStateContext)

  useEffectSkipFirst(() => {
    globalState.setLoading(api.loading)

    if (api.isSuccess()) {
      setOpen(false)
      globalState.setNotificationMessage({ body: 'キャンセルポリシーを更新しました' })
      onComplete()
    }
  }, [api.loading])

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = () => {
    // キャンセルポリシーがルームや機器と紐付いている場合は確認メッセージ
    if (cancelPolicy.roomsCount || cancelPolicy.instrumentsCount) {
      globalState.confirm(
        `${cancelPolicy.roomsCount ? cancelPolicy.roomsCount + 'つの部屋 ' : ''}
         ${cancelPolicy.instrumentsCount ? cancelPolicy.instrumentsCount + 'つの機器 ' : ''}で使用されています。更新しますか？`,
        (event) => {
          if (event === 'ok') {
            api.execute(cancelPolicyForm.object)
          }
        },
      )
    } else {
      api.execute(cancelPolicyForm.object)
    }
  }

  // ローディング時に編集前のキャンセルポリシーをフォームにセット
  useEffect(() => {
    cancelPolicyForm.set({
      ...cancelPolicy,
      deadline: cancelPolicy.deadline,
      deadlineType: cancelPolicy.deadline >= 24 ? 'day' : 'hour',
      displayDeadline: cancelPolicy.deadline >= 24 ? cancelPolicy.deadline / 24 : cancelPolicy.deadline,
    })
  }, [])

  return (
    <div>
      <ThemedButton onClick={handleOpen}>編集</ThemedButton>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle>キャンセルポリシー編集</DialogTitle>
        <DialogFormContent cancelPolicyForm={cancelPolicyForm} error={api.apiError} />
        <DialogActions>
          <ThemedButton onClick={handleClose} color="secondary">
            閉じる
          </ThemedButton>
          <ThemedButton onClick={handleSubmit} color="success" disabled={!cancelPolicyForm.object.deadlineType}>
            更新
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}

//
// 削除
//
type DeleteCancelPolicyFormDialogProps = {
  cancelPolicy: CancelPolicy
  onComplete: () => void
}

export const DeleteCancelPolicyFormDialog: React.FC<DeleteCancelPolicyFormDialogProps> = (props: DeleteCancelPolicyFormDialogProps) => {
  const { cancelPolicy, onComplete } = props
  const api = useDeleteCancelPolicyApi()
  const globalState = useContext(GlobalStateContext)

  useEffect(() => {
    globalState.setLoading(api.loading)
  }, [api.loading])

  useEffectSkipFirst(() => {
    if (!api.loading && !api.isError) {
      globalState.setNotificationMessage({ body: 'キャンセルポリシーを削除しました' })
      onComplete()
    }
  }, [api.loading])

  const handleClick = () => {
    let message = 'キャンセルポリシーを削除します。よろしいですか？'
    // キャンセルポリシーがルームや機器と紐付いている場合は確認メッセージ
    if (cancelPolicy.roomsCount || cancelPolicy.instrumentsCount) {
      message = `${cancelPolicy.roomsCount ? cancelPolicy.roomsCount + 'つの部屋 ' : ''}
         ${cancelPolicy.instrumentsCount ? cancelPolicy.instrumentsCount + 'つの機器 ' : ''}で使用されています。削除しますか？`
    }
    globalState.confirm(message, (e) => {
      if (e === 'ok') {
        api.execute(cancelPolicy.id!)
      }
    })
  }

  return (
    <div>
      <ThemedButton color="error" onClick={handleClick}>
        削除
      </ThemedButton>
    </div>
  )
}
