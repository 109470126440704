import { BaseEntity } from '.'

/**
 * ユーザー権限
 */
export type UserAuthority = BaseEntity & {
  userId?: number
  instrument?: boolean
  room?: boolean
  category?: boolean
  repair?: boolean // 修理管理（不具合報告の閲覧権限当も含む）
  proofread?: boolean
  constraint?: boolean
  privileged?: boolean
}

export type UserAuthorityForm = UserAuthority

export type BulkUpdateUserAuthorityForm = {
  userAuthorities: UserAuthorityForm[]
}

export const authorityData = {
  instrument: '機器登録',
  room: 'ルーム登録',
  category: 'カテゴリ作成',
  repair: '修理・不具合報告の閲覧・編集',
  proofread: '校正履歴の閲覧・編集',
  constraint: '予約条件の設定',
  privileged: '管理者',
}

export const initialFormState = {
  userAuthorities: [],
}

export const authorityKeys = Object.keys(authorityData)
export type AuthorityTypes = 'instrument' | 'room' | 'category' | 'repair' | 'proofread' | 'constraint' | 'privileged'
