import { AxiosHttpClient } from 'api/axios'
import { useRepruaIndexApi } from 'api/shared_api_hooks'
import { PagingResponse } from 'entities'
import { getInstrumentSearchQuery, Instrument, ProofreadableInstrumentSearchForm } from 'entities/instrument'
import { IndexApiSet, Form } from 'rac'
import { useEffectSkipFirst } from 'utils/hooks'

type ProofreadableInstrumentsResponse = PagingResponse & {
  instruments: Instrument[]
}

/**
 * 一覧
 */
type FetchProofreadableInstrumentsApiSet = IndexApiSet<ProofreadableInstrumentsResponse> & {
  execute: () => void
  executeAndPageReset: () => void
}
export function useFetchProofreadableInstrumentsApi(
  searchForm: Form<ProofreadableInstrumentSearchForm>,
): FetchProofreadableInstrumentsApiSet {
  const api = useRepruaIndexApi<ProofreadableInstrumentsResponse, ProofreadableInstrumentSearchForm>(new AxiosHttpClient('user'), {
    initialResponse: { instruments: [], totalCount: 0 },
  })

  const { searchText, ...rest } = searchForm.object

  const execute = () => {
    const path = 'proofreadable_instruments'
    api.execute(path, { params: rest, searchQuery: getInstrumentSearchQuery(searchText) })
  }

  const executeAndPageReset = () => {
    if (api.pageSet.page > 1) {
      api.pageSet.setPage(1)
    } else {
      execute()
    }
  }

  useEffectSkipFirst(() => {
    execute()
  }, [api.pageSet.page, api.pageSet.perPage, api.orderSet.order, api.orderSet.orderBy])

  return { ...api, execute, executeAndPageReset }
}
