import { AxiosHttpClient } from 'api/axios'
import { useRepruaIndexApi } from 'api/shared_api_hooks'
import { PagingResponse } from 'entities'
import { getInstrumentSearchQuery, Instrument, ProofreadableInstrumentSearchForm } from 'entities/instrument'
import { IndexApiSet, Form } from 'rac'
import { useRecoilState } from 'recoil'
import { ownerFilterAtom } from 'recoil/partner/owner_filter'
import { useEffectSkipFirst } from 'utils/hooks'

type ProofreadableInstrumentsResponse = PagingResponse & {
  instruments: Instrument[]
}

/**
 * 一覧
 */
type PartnerFetchProofreadableInstrumentsApiSet = IndexApiSet<ProofreadableInstrumentsResponse> & {
  execute: () => void
  executeAndPageReset: () => void
}
export function usePartnerFetchProofreadableInstrumentsApi(
  searchForm: Form<ProofreadableInstrumentSearchForm>,
): PartnerFetchProofreadableInstrumentsApiSet {
  // 顧客フィルターを適用する
  const [filter] = useRecoilState(ownerFilterAtom)
  const api = useRepruaIndexApi<ProofreadableInstrumentsResponse, ProofreadableInstrumentSearchForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { instruments: [], totalCount: 0 },
  })

  const { searchText, ...rest } = searchForm.object

  const execute = () => {
    const path = 'proofreadable_instruments'
    api.execute(path, { params: { ...rest, ...filter }, searchQuery: getInstrumentSearchQuery(searchText) })
  }

  const executeAndPageReset = () => {
    if (api.pageSet.page > 1) {
      api.pageSet.setPage(1)
    } else {
      execute()
    }
  }

  useEffectSkipFirst(() => {
    execute()
  }, [api.pageSet.page, api.pageSet.perPage, api.orderSet.order, api.orderSet.orderBy, filter])

  return { ...api, execute, executeAndPageReset }
}
