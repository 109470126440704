import { usePartnerFetchDealerDivisionDealerUserRelationsApi } from 'api/partner/dealer_division_dealer_user_relations'
import { usePartnerFetchDealerDivisionsApi } from 'api/partner/dealer_divisions'
import { usePartnerPatchDealerUserUpdateDivisionIdsApi } from 'api/partner/dealer_users'
import { Flex } from 'components/shared/flex'
import { MultipleCheckBoxField } from 'components/shared/form/input'
import Modal, { ModalSet } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { UpdateDivisionIdsForm, DealerUser } from 'entities/dealer_user'
import { useForm } from 'rac'
import React, { useEffect } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'

type Props = {
  modalSet: ModalSet<DealerUser>
  onComplete: () => void
}
export const DealerDivisionDealerUserRelationModal = ({ modalSet, onComplete }: Props) => {
  const form = useForm<UpdateDivisionIdsForm>({ divisionIds: [] })
  const fetchDivisionsApi = usePartnerFetchDealerDivisionsApi()
  const fetchRelationsApi = usePartnerFetchDealerDivisionDealerUserRelationsApi()
  const patchDealerUserApi = usePartnerPatchDealerUserUpdateDivisionIdsApi(form)

  useEffect(() => {
    fetchDivisionsApi.execute()
  }, [])

  useEffectSkipFirst(() => {
    if (!modalSet.modalState.open) return
    fetchRelationsApi.execute(modalSet.item.id!)
  }, [modalSet.modalState.open])

  // DBに保存されている部署×ディーラーユーザーのリレーションを元にフォームを更新する
  useEffectSkipFirst(() => {
    if (!fetchRelationsApi.isSuccess()) return
    const divisionIds = fetchRelationsApi.response.dealerDivisionDealerUserRelations.map((rel) => rel.dealerDivisionId!)

    form.update((f) => (f.divisionIds = divisionIds.concat(''))) // 空のパラメーターを送る為空文字を挿入
  }, [fetchRelationsApi.loading])

  useEffectSkipFirst(() => {
    if (!patchDealerUserApi.isSuccess()) return
    onComplete()
  }, [patchDealerUserApi.loading])

  return (
    <Modal
      modalState={modalSet.modalState}
      modalId="dealer-division-dealer-user-relation-modal"
      title="所属部署の設定"
      size="sm"
      footer={
        <>
          <ThemedButton onClick={() => modalSet.closeModal()} color="secondary">
            閉じる
          </ThemedButton>
          <ThemedButton onClick={() => patchDealerUserApi.execute(modalSet.item.id!)} color="success">
            更新
          </ThemedButton>
        </>
      }
    >
      <Flex flexWrap="wrap">
        {fetchDivisionsApi.response?.dealerDivisions.length === 0 ? (
          <div style={{ width: '100%', textAlign: 'center' }}>結果がありません</div>
        ) : (
          fetchDivisionsApi.response?.dealerDivisions.map((division) => {
            return (
              <MultipleCheckBoxField
                key={division.id}
                id={division.id!}
                label={division.name!}
                form={form}
                attr="divisionIds"
                checked={form.object.divisionIds!.includes(division.id!)}
              />
            )
          })
        )}
      </Flex>
    </Modal>
  )
}
