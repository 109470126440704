import { DialogContent, Tooltip } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import ThemedButton from 'components/shared/themed_button'
import { Reservation } from 'entities/reservation'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { MypageRouteHelper } from 'routes/mypage'
import { ReservationViewModel } from 'view_models/reservation'

type ReservationDetailDialogProps = {
  open: boolean
  reservation: Reservation
  onClose: () => void
}

export const ReservationDetailDialog: React.FC<ReservationDetailDialogProps> = (props: ReservationDetailDialogProps) => {
  const reservationVM = new ReservationViewModel(props.reservation)

  const history = useHistory()

  const styles = {
    detailRow: {
      marginBottom: 10,
    },
    rowTitle: {
      fontSize: 14,
    },
    rowValue: {
      fontSize: 16,
    },
  }

  const handleClickEditReservation = () => {
    history.push({
      pathname: MypageRouteHelper.instrumentShow(props.reservation.reservableId!),
      state: { reservation: props.reservation },
    })
  }

  return (
    <div>
      <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title" maxWidth="xl">
        <DialogTitle id="form-dialog-title">予約詳細</DialogTitle>
        <DialogContent>
          <div style={styles.detailRow}>
            <div style={styles.rowTitle}>予約種別</div>
            <div style={styles.rowValue}>{reservationVM.getKindText()}</div>
          </div>
          <div style={styles.detailRow}>
            <div style={styles.rowTitle}>予約対象</div>
            <div style={styles.rowValue}>{reservationVM.reservableName()}</div>
          </div>
          <div style={styles.detailRow}>
            <div style={styles.rowTitle}>予約時間</div>
            <div style={styles.rowValue}>{`${reservationVM.getStartAtText()} 〜 ${reservationVM.getEndAtText()}`}</div>
          </div>
          <div style={styles.detailRow}>
            <div style={styles.rowTitle}>予約者</div>
            <div style={styles.rowValue}>{props.reservation.reservedUserName}</div>
          </div>
          <div style={styles.detailRow}>
            <div style={styles.rowTitle}>メモ・コメント</div>
            <div style={styles.rowValue}>{props.reservation.comment ? props.reservation.comment : 'メモなし'}</div>
          </div>
        </DialogContent>
        <DialogActions>
          <Tooltip title={!props.reservation.reservableCanReserve ? '予約対象機器ではありません' : ''}>
            <div>
              <ThemedButton disabled={!props.reservation.reservableCanReserve} onClick={handleClickEditReservation} color="primary">
                編集
              </ThemedButton>
            </div>
          </Tooltip>
          <ThemedButton onClick={props.onClose} color="secondary">
            閉じる
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}
