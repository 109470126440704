import { ProofreadEventState } from 'entities/proofread_event'
import { ProofreadPlan } from 'entities/proofread_plan'
import moment from 'moment'
import { ViewModel } from 'view_models'

export class ProofreadPlanViewModel extends ViewModel<ProofreadPlan> {
  public getTitle(): string {
    return this.object.title || '-'
  }
  // 業者が作成している場合は業者の名前を表示させる
  public getDealerName(): string {
    return this.object.dealer?.name || '-'
  }
  public getLaboratoryName(): string {
    return this.object.laboratory?.name || this.object.outsideLaboratory?.name || '-'
  }
  public getTargetRange(): string {
    const startDate = moment(this.object.startDate).format('YYYY/MM')
    const endDate = moment(this.object.endDate).format('YYYY/MM')
    return `${startDate} ~ ${endDate}`
  }
  public getStateCount(key: ProofreadEventState): number {
    switch (key) {
      case 'no_plan':
        return this.object.countNoPlan || 0
      case 'planned':
        return this.object.countPlanned || 0
      case 'approved_plan':
        return this.object.countApprovedPlan || 0
      case 'waiting_date':
        return this.object.countWaitingDate || 0
      case 'fix_date':
        return this.object.countFixDate || 0
      case 'attached_result':
        return this.object.countAttachedResult || 0
      case 'fix_result':
        return this.object.countFixResult || 0
      case 'approved_result':
        return this.object.countApprovedResult || 0
      case 'completed':
        return this.object.countCompleted || 0
      default:
        return this.object.countNoPlan || 0
    }
  }
  public getInstrumentCount(): number {
    return this.object.requestInstrumentsCount || this.object.countAll || 0
  }
}
