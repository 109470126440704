import { AxiosHttpClient } from 'api/axios'
import { useRepruaDeleteApi, useRepruaDownloadApi, useRepruaIndexApi, useRepruaPostApi } from 'api/shared_api_hooks'
import { InstrumentImportResult, InstrumentImportForm } from 'entities/instrument_import_result'
import { BaseResponse, Form, IndexApiSet } from 'rac'

type InstrumentImportResultsResponse = BaseResponse & {
  instrumentImportResults: InstrumentImportResult[]
}

type InstrumentImportResultsParams = {
  dealerId: number
}

/**
 * インポート結果の取得
 */
type AdminDealerInstrumentImportResultsApiSet = IndexApiSet<InstrumentImportResultsResponse> & {
  execute: (id: number) => void
}
export const useAdminDealerInstrumentImportResults = (): AdminDealerInstrumentImportResultsApiSet => {
  const api = useRepruaIndexApi<InstrumentImportResultsResponse, InstrumentImportResultsParams>(new AxiosHttpClient('admin'), {
    initialResponse: { instrumentImportResults: [] },
  })

  const execute = (id: number) => {
    const path = `dealers/${id}/instrument_excel_import_results`
    api.execute(path)
  }

  return { ...api, execute }
}

type DealerInstrumentImportResultResponse = BaseResponse & {
  instrumentImportResult: InstrumentImportResult
}

/**
 * 機器のエクセルインポート
 */
export const useAdminPostDealerInstrumentImport = () => {
  const api = useRepruaPostApi<DealerInstrumentImportResultResponse, InstrumentImportForm>(new AxiosHttpClient('admin'), {
    initialResponse: { instrumentImportResult: {} },
  })

  const execute = (instrumentForm: Form<InstrumentImportForm>, dealerId: number) => {
    const path = `dealers/${dealerId}/import_instrument_excel`
    api.execute(path, instrumentForm)
  }

  return { ...api, execute }
}

/**
 * インポートの取り消し
 */
export const useAdminDeleteDealerInstrumentImport = () => {
  const api = useRepruaDeleteApi<DealerInstrumentImportResultResponse>(new AxiosHttpClient('admin'), {
    initialResponse: { instrumentImportResult: {} },
  })

  const execute = (instrumentExcelImportResultId: number) => {
    const path = `instrument_excel_import_results/${instrumentExcelImportResultId}`
    api.execute(path)
  }

  return { ...api, execute }
}

export function useAdminFetchDealerInstrumentImportFormat(): { execute: (dealerId: number) => void } {
  const api = useRepruaDownloadApi<any>(new AxiosHttpClient('admin'))

  const execute = (dealerId: number) => {
    const path = `dealers/${dealerId}/download_instrument_excel_import_format`
    api.execute(path)
  }

  return { execute }
}
