import React from 'react'
import { makeStyles } from 'tss-react/mui'

type DataLabelProps = {
  label: React.ReactNode
  value: React.ReactNode
  style?: React.CSSProperties
  width?: string | number
}
export const DataLabel = (props: DataLabelProps) => {
  const { classes } = useStyles({ width: props.width || '100%' })
  return (
    <div className={classes.root} style={props.style}>
      <div>
        <label>{props.label}</label>
      </div>
      <p className={classes.value}>{props.value}</p>
    </div>
  )
}

const useStyles = makeStyles<{ width: string | number }>()((_, { width }) => ({
  root: {
    width: width,
  },
  value: {
    marginTop: 6,
    whiteSpace: 'pre-wrap',
  },
}))

export default DataLabel
