import { Typography } from '@mui/material'
import { EditDefectReportFormDialog } from 'components/mypage/defect_report/defect_report_form_dialog'
import { RepairEstimateRequestFormDialog, NewRepairEstimateFormDialog } from 'components/mypage/repair_estimate/repair_estimate_form_dialog'
import { NewRepairEventFormDialogFromDefectReport } from 'components/mypage/repair_event/repair_event_form_dialog'
import AttachmentsList from 'components/shared/data/attachments_list'
import DataBlock from 'components/shared/data/data_block'
import DataCard from 'components/shared/data/data_card'
import DataLabel from 'components/shared/data/data_label'
import { DropdownMenu, ListItem } from 'components/shared/dropdown_menu'
import { Flex } from 'components/shared/flex'
import { useModal } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { Dealer } from 'entities/dealer'
import { DefectReport } from 'entities/defect_report'
import { OutsideDealer } from 'entities/outside_dealer'
import { RepairEstimate } from 'entities/repair_estimate'
import { RepairEvent } from 'entities/repair_event'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { MypageRouteHelper } from 'routes/mypage'
import { DefectReportViewModel } from 'view_models/defect_report'

type DefectReportDataCardProps = {
  defectReport: DefectReport | undefined
  outsideDealers?: OutsideDealer[]
  canEdit?: boolean
  onCompleteEdit?: (report?: DefectReport) => void
  onCompleteCreateEvent?: (repairEvent: RepairEvent) => void
  onCompleteCreateEstimate?: (repairEstimate: RepairEstimate) => void
  noTitle?: boolean
  emptyMessage?: string
  showDetail?: boolean
  dealer?: Dealer
}
export const DefectReportDataCard: React.FC<DefectReportDataCardProps> = (props) => {
  const history = useHistory()
  const [vm, setVm] = useState(new DefectReportViewModel(props.defectReport || {}))
  const [listItems, setListItems] = useState<ListItem[]>([])
  const [openEventDialog, setOpenEventDialog] = useState<boolean>(false)
  const editModal = useModal()
  const createEstimateModal = useModal()
  const createEstimateRequestModal = useModal()

  useEffect(() => {
    if (props.defectReport) {
      setVm(new DefectReportViewModel(props.defectReport))
    }
  }, [props.defectReport])

  useEffect(() => {
    if (!props.canEdit) return
    const items: ListItem[] = []
    if (props.onCompleteEdit) {
      items.push({ text: '不具合報告の編集', onClick: () => editModal.setOpen(true) })
    }
    if (props.onCompleteCreateEstimate) {
      if (!props.dealer) {
        items.push({ text: '修理見積を作成', onClick: () => createEstimateModal.setOpen(true) })
      }
    }
    if (props.onCompleteCreateEvent) {
      items.push({ text: '修理履歴を作成', onClick: () => setOpenEventDialog(true) })
    }
    setListItems(items)
  }, [props.canEdit])

  const handleCompleteUpdate = (report?: DefectReport) => {
    editModal.setOpen(false)
    if (props.onCompleteEdit) {
      props.onCompleteEdit(report || undefined)
    }
  }

  const handleCompleteCreateRepairEvent = (repairEvent: RepairEvent) => {
    setOpenEventDialog(false)
    if (props.onCompleteCreateEvent) {
      props.onCompleteCreateEvent(repairEvent)
    }
  }

  const handleCompleteCreateEstimate = (repairEstimate: RepairEstimate) => {
    createEstimateRequestModal.setOpen(false)
    if (props.onCompleteCreateEstimate) {
      props.onCompleteCreateEstimate(repairEstimate)
    }
  }

  const handleClickDetail = () => {
    history.push(MypageRouteHelper.defectReportShow(props.defectReport!.id!))
  }

  return (
    <DataCard>
      {props.canEdit && props.defectReport && (
        <>
          <EditDefectReportFormDialog modalState={editModal} defectReport={props.defectReport} onComplete={handleCompleteUpdate} />
          <NewRepairEventFormDialogFromDefectReport
            open={openEventDialog}
            defectReport={props.defectReport}
            instrumentId={props.defectReport.instrument?.id}
            onClickClose={() => setOpenEventDialog(false)}
            onComplete={handleCompleteCreateRepairEvent}
          />
          <NewRepairEstimateFormDialog
            modalState={createEstimateModal}
            defectReportId={props.defectReport.id!}
            onComplete={handleCompleteCreateEstimate}
            outsideDealers={props.outsideDealers}
          />
          <RepairEstimateRequestFormDialog
            modalState={createEstimateRequestModal}
            defectReportId={props.defectReport.id!}
            onComplete={handleCompleteCreateEstimate}
            dealer={props.dealer}
          />
        </>
      )}
      <Flex alignItems="center" justifyContent="space-between">
        {!props.noTitle && <Typography variant="h6">不具合報告</Typography>}
        {props.defectReport && props.showDetail && (
          <ThemedButton color="secondary" onClick={handleClickDetail}>
            詳細
          </ThemedButton>
        )}
        {props.canEdit && props.defectReport && (
          <DropdownMenu buttonText="アクション" id="defect-report-action" buttonColor="primary" listItems={listItems} />
        )}
      </Flex>
      {props.defectReport ? (
        <>
          <DataBlock>
            <DataLabel label="報告者" value={vm.getUserName()} />
            <DataLabel label="発生日時" value={vm.getReportedAtText()} />
            <DataLabel label="不具合箇所" value={vm.getPoint()} />
          </DataBlock>
          <DataBlock>
            <DataLabel label="不具合発生時の状況" value={vm.getContent()} />
          </DataBlock>
          <DataBlock noBorder>
            <AttachmentsList fileAttachments={props.defectReport.fileAttachments || []} />
          </DataBlock>
        </>
      ) : (
        <span>{props.emptyMessage || '不具合報告を選択してください'}</span>
      )}
    </DataCard>
  )
}

export default DefectReportDataCard
