import { DefectReport } from 'entities/defect_report'
import moment, { Moment } from 'moment'
import { ViewModel } from 'view_models'
import { InstrumentViewModel } from 'view_models/instrument'
import { RepairEstimateViewModel } from 'view_models/repair_estimate'
import { RepairEventViewModel } from 'view_models/repair_event'


export class DefectReportViewModel extends ViewModel<DefectReport> {
  /**
   * 報告日時
   */
  public reportedAt(): Moment {
    return moment(this.object.reportedAt)
  }

  public getReportedAtText(): string {
    return this.reportedAt().format('Y/MM/DD HH:mm')
  }

  public getUserName(): string {
    return this.object.reportedUserName || '-'
  }

  public getPoint(): string {
    return this.object.point || '-'
  }

  public getContent(): string {
    return this.object.content || '-'
  }

  public getStatusText(): string {
    // 修理履歴があればそのステータスに合わせる
    if (this.object.repairEvent) {
      return new RepairEventViewModel(this.object.repairEvent).getResultText()
    }
    // 修理見積があればそのステータスに合わせる
    if (this.object.repairEstimate) {
      return new RepairEstimateViewModel(this.object.repairEstimate).getStatusText()
    }
    // 修理履歴も見積もりもなければ、未対応
    return '未対応'
  }

  public getStatusColor(): string {
    // 修理履歴があればそのステータスに合わせる
    if (this.object.repairEvent) {
      return new RepairEventViewModel(this.object.repairEvent).getResultColor()
    }
    // 修理見積があればそのステータスに合わせる
    if (this.object.repairEstimate) {
      return new RepairEstimateViewModel(this.object.repairEstimate).getStatusColor()
    }
    // 修理履歴も見積もりもなければ、未対応
    return '#808080'
  }

  // 不具合報告からの経過日数を取得する
  public getElapsedDates(): string {
    if ((!this.object.repairEvent && !this.object.repairEstimate) || this.object.repairEvent?.aasmState === 'yet') {
      // 修理見積も修理履歴もない、または未修理の場合のみ表示
      const elapsed = moment().diff(moment(this.object.reportedAt), 'days')
      return `${elapsed}日`
    }
    return ''
  }

  public getInstrumentInfo = (linkDisabled = false) => {
    if (this.object.instrument) {
      const vm = new InstrumentViewModel(this.object.instrument)
      return vm.getInstrumentBaseInfoIncludeMaker(linkDisabled)
    } else {
      '-'
    }
  }
}
