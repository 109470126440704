import { usePartnerPostInquiryApi } from 'api/partner/inquiry'
import { Flex } from 'components/shared/flex'
import { SelectField, StringField } from 'components/shared/form/input'
import Modal, { ModalState } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { InquiryForm } from 'entities/inquiry'
import { User } from 'entities/user'
import { useForm } from 'rac'
import React, { useContext } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useEffectSkipFirst } from 'utils/hooks'
import { selectDataGenerator } from 'utils/utils'

type InquiryFormModalProps = {
  modalState: ModalState
  users: User[]
  onComplete: (id: number) => void
}

export const InquiryFormModal: React.FC<InquiryFormModalProps> = (props: InquiryFormModalProps) => {
  const { modalState, users, onComplete } = props
  const form = useForm<InquiryForm>({}, 'Inquiry')
  const globalState = useContext(GlobalStateContext)
  const apiSet = usePartnerPostInquiryApi()
  const { classes } = useStyles()

  const handleSubmit = () => {
    if (!form.object.userId) {
      globalState.setNotificationMessage({ body: '宛先を選択してください', colorType: 'error' })
      return
    }
    apiSet.execute(form)
  }

  useEffectSkipFirst(() => {
    globalState.setLoading(apiSet.loading)
    if (!apiSet.isSuccess()) return

    onComplete(apiSet.response.inquiry.id!)
    form.resetForm()
    modalState.setOpen(false)
  }, [apiSet.loading])

  return (
    <>
      <Modal
        size="md"
        modalState={modalState}
        modalId="new-inquiry-form"
        title="新しい問合せ"
        footer={
          <>
            <ThemedButton color="secondary" onClick={() => modalState.setOpen(false)}>
              キャンセル
            </ThemedButton>
            <ThemedButton onClick={handleSubmit}>送信</ThemedButton>
          </>
        }
      >
        <div>
          <Flex className={classes.marginBottom}>
            <SelectField
              label="宛先"
              labelId="userId"
              form={form}
              attr="userId"
              data={selectDataGenerator(users, 'id', 'nameWithLabName')}
              includeBlank
            />
          </Flex>
          <Flex className={classes.marginBottom}>
            <StringField form={form} attr="subject" placeholder="件名" />
          </Flex>
          <Flex className={classes.marginBottom}>
            <StringField form={form} attr="body" placeholder="お問合せ内容" multiline />
          </Flex>
        </div>
      </Modal>
    </>
  )
}

const useStyles = makeStyles()({
  subject: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderBottom: 'none',
  },
  explain: {
    marginBottom: '20px',
  },
  marginBottom: {
    marginBottom: '10px',
  },
})
