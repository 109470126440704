import {
  DealerMemoableType,
  usePartnerDeleteDealerMemosApi,
  usePartnerFetchDealerMemosApi,
  usePartnerPostDealerMemosApi,
} from 'api/partner/dealer_memo'
import { Flex } from 'components/shared/flex'
import { StringField } from 'components/shared/form/input'
import { MemoTable } from 'components/shared/memo_table'
import Modal, { ModalState } from 'components/shared/modal'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { PartnerStateContext } from 'contexts/partner_state_context'
import { DealerMemo, DealerMemoForm } from 'entities/dealer_memo'
import { useForm } from 'rac'
import React, { useContext, useEffect } from 'react'
import { useEffectSkipFirst } from 'utils/hooks/use_effect_skip_first'

type DealerMemoModalProps = {
  memoableId: number
  memoableType: DealerMemoableType
  modalState: ModalState
  onClose: () => void
}

export const DealerMemoModal = ({ memoableId, memoableType, modalState, onClose }: DealerMemoModalProps) => {
  const indexApi = usePartnerFetchDealerMemosApi(memoableId, memoableType)
  const postApi = usePartnerPostDealerMemosApi(memoableId, memoableType)
  const deleteApi = usePartnerDeleteDealerMemosApi()
  const form = useForm<DealerMemoForm>({ body: '' }, 'dealerMemo')
  const partnerState = useContext(PartnerStateContext)
  const globalState = useContext(GlobalStateContext)

  useEffect(() => {
    if (memoableId) {
      indexApi.execute()
    }
  }, [memoableId])

  const handleClickPostMemo = () => {
    postApi.execute(form)
  }

  useEffectSkipFirst(() => {
    if (postApi.isSuccess()) {
      indexApi.execute()
    }
  }, [postApi.loading])

  useEffectSkipFirst(() => {
    if (deleteApi.isSuccess()) {
      indexApi.execute()
    }
  }, [deleteApi.loading])

  const handleClickDeleteMemo = (memo: DealerMemo) => {
    globalState.confirm('本当に削除しますか？', (event) => {
      if (event === 'ok') {
        deleteApi.execute(memo.id!)
      }
    })
  }

  return (
    <Modal title="メモ" modalId="dealer-memo-modal" modalState={modalState} onClose={onClose} aria-labelledby="form-dialog-title" size="md">
      <MemoTable
        memos={indexApi.response.dealerMemos}
        onClickDeleteMemo={handleClickDeleteMemo}
        loading={indexApi.loading}
        senderId={partnerState.currentDealerUser.id}
      />
      <Flex alignItems="flex-end" style={{ marginTop: 10 }}>
        <div style={{ flexGrow: 1 }}>
          <StringField multiline attr="body" form={form} apiError={postApi.apiError} label="本文" />
        </div>
        <div style={{ width: 60, marginBottom: 10 }}>
          <ThemedButton color="success" onClick={() => handleClickPostMemo()}>
            送信
          </ThemedButton>
        </div>
      </Flex>
    </Modal>
  )
}
