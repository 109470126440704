import { useCurrentDealerUserQuery } from 'api/partner/dealer_users'
import { usePartnerFetchLaboratoriesApi } from 'api/partner/laboratories'
import { usePartnerFetchOutsideLaboratoriesApi } from 'api/partner/outside_laboratories'
import { GlobalStateContext } from 'contexts/global_state_context'
import { PartnerStateContext } from 'contexts/partner_state_context'
import React, { useState, useContext, useEffect } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { PartnerRouteHelper } from 'routes/partner'
import CookieManager from 'utils/cookie_util'
import { useEffectSkipFirst } from 'utils/hooks'

type Props = {
  children: React.ReactNode
}
const DealerUserAuth = ({ children }: Props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(CookieManager.hasDealerUserToken())
  const globalState = useContext(GlobalStateContext)
  const partnerState = useContext(PartnerStateContext)
  const location = useLocation()

  const currentDealerUserQuery = useCurrentDealerUserQuery()
  const selectableOutsideLabApi = usePartnerFetchOutsideLaboratoriesApi({})
  const selectableLaboratoryApi = usePartnerFetchLaboratoriesApi({})

  useEffect(() => {
    currentDealerUserQuery.refetch()
    selectableOutsideLabApi.execute()
    selectableLaboratoryApi.execute()
  }, [])

  useEffectSkipFirst(() => {
    if (currentDealerUserQuery.isSuccess) {
      partnerState.setCurrentDealer(currentDealerUserQuery.data.dealer)
      partnerState.setCurrentDealerUser(currentDealerUserQuery.data.dealerUser)
    }
  }, [currentDealerUserQuery.isLoading])

  useEffectSkipFirst(() => {
    if (selectableOutsideLabApi.isSuccess() && selectableLaboratoryApi.isSuccess()) {
      partnerState.setSelectableOwners({
        laboratories: selectableLaboratoryApi.response.laboratories,
        outsideLaboratories: selectableOutsideLabApi.response.outsideLaboratories,
      })
    }
  }, [selectableOutsideLabApi.loading, selectableLaboratoryApi.loading])

  useEffectSkipFirst(() => {
    if (globalState.statusCode === 401) {
      CookieManager.removeDealerUserToken()
      setIsAuthenticated(false)
    } else if (CookieManager.hasDealerUserToken()) {
      setIsAuthenticated(true)
    }
  }, [globalState.statusCode])

  return isAuthenticated ? <div>{children}</div> : <Redirect to={{ pathname: PartnerRouteHelper.login(), state: { from: location } }} />
}

export default DealerUserAuth
