import NoImage from 'images/no-image.jpg'
import React from 'react'

type ImageViewProps = {
  maxWidth: number
  maxHeight: number
  src?: string | undefined | null
  style?: React.CSSProperties
}

export const ImageView: React.FC<ImageViewProps> = (props: ImageViewProps) => {
  return (
    <>
      {props.src ? (
        <img
          src={props.src}
          alt=""
          style={{ objectFit: 'contain', maxHeight: props.maxHeight, maxWidth: props.maxWidth, ...props.style }}
        />
      ) : (
        <img
          src={NoImage}
          alt="noimage"
          style={{ objectFit: 'contain', maxHeight: props.maxHeight, maxWidth: props.maxWidth, ...props.style }}
        />
      )}
    </>
  )
}

export default ImageView
