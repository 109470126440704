import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { Paper, Table, TableContainer, TableHead, TableRow, Tooltip, Collapse, IconButton } from '@mui/material'
import { Flex } from 'components/shared/flex'
import StatusLabel from 'components/shared/status_label'
import { ExTableBody, ExTableCell, ExTableRow } from 'components/shared/table'
import ThemedButton from 'components/shared/themed_button'
import { ExColumn, ExtensionClass } from 'entities/ex_column'
import { InstrumentOwnerableType } from 'entities/instrument'
import { DealerClient } from 'pages/partner/ex_column'
import React, { ReactNode, useState } from 'react'
import { ExColumnViewModel } from 'view_models/ex_column'

type ExColumnTableProps = {
  title: string | ReactNode
  extensionClass: ExtensionClass
  exColumns: ExColumn[]
  loading: boolean
  editable?: boolean
  onClickNewButton: (extensionClass: ExtensionClass, client: DealerClient) => void
  onClickEditButton: (column: ExColumn) => void
  clientableId?: number
  clientableType?: InstrumentOwnerableType
  open?: boolean
}

export const ExColumnTable = (props: ExColumnTableProps) => {
  const [open, setOpen] = useState(props.open || false)

  return (
    <Paper style={{ padding: 20, marginBottom: 20, width: '100%' }}>
      <Flex alignItems="center" justifyContent="space-between">
        <div style={{ fontWeight: 'bold', fontSize: 18 }}>{props.title}</div>
        <div>
          {props.editable && (
            <ThemedButton
              color="success"
              onClick={() =>
                props.onClickNewButton(props.extensionClass, { clientableId: props.clientableId, clientableType: props.clientableType })
              }
            >
              登録
            </ThemedButton>
          )}
          <IconButton
            onClick={() => setOpen(!open)}
            size="small"
            sx={{
              marginLeft: 1,
              transition: '.5s',
              transform: open ? 'none' : 'rotate(90deg)',
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </div>
      </Flex>
      <Collapse in={open}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <ExTableCell minWidth={160}>名前</ExTableCell>
                <ExTableCell minWidth={100} align="center">
                  入力タイプ
                </ExTableCell>
                <ExTableCell minWidth={65} align="center">
                  必須
                </ExTableCell>
                <ExTableCell minWidth={65} align="center">
                  詳細
                </ExTableCell>
                <ExTableCell minWidth={60} align="center" />
              </TableRow>
            </TableHead>
            <ExTableBody loading={props.loading}>
              {props.exColumns.map((column: ExColumn) => {
                const vm = new ExColumnViewModel(column)
                // 何番目のテーブル表示管理情報か
                const countindex = props.exColumns.filter((c) => c.visibleOnTable).findIndex((c) => c.id === column.id)
                return (
                  <ExTableRow key={column.id}>
                    <ExTableCell>
                      <Flex alignItems="center" style={{ gap: 12 }}>
                        {column.name}
                        {column.visibleOnTable && (
                          <Tooltip title={`管理情報 ${countindex + 1} として機器一覧上で表示されます`}>
                            <div>
                              <StatusLabel text={`一覧表示 ${countindex + 1}`} color="gray" />
                            </div>
                          </Tooltip>
                        )}
                        {column.shared && (
                          <Tooltip title="ラボ側で閲覧可能な管理情報です">
                            <div>
                              <StatusLabel text="ラボ閲覧可" color="gray" />
                            </div>
                          </Tooltip>
                        )}
                      </Flex>
                    </ExTableCell>
                    {/* もしロゴを付けるなら，数値:ExposureZero，記述式:ShortText，段落:Notes，ドロップダウン:ExpandMore */}
                    <ExTableCell align="left" width={170}>
                      <Flex alignItems="center" justifyContent="start">
                        {vm.getInputTypeIcon()}
                        <div>{vm.getInputTypeText()}</div>
                      </Flex>
                    </ExTableCell>
                    <ExTableCell align="center" width={50}>
                      {column.required && <CheckCircleOutlineIcon style={{ color: 'green' }} />}
                    </ExTableCell>
                    <ExTableCell align="center" width={50}>
                      {column.stashed && <CheckCircleOutlineIcon style={{ color: 'green' }} />}
                    </ExTableCell>
                    <ExTableCell>
                      {props.editable && (
                        <ThemedButton color="secondary" onClick={() => props.onClickEditButton(column)}>
                          編集
                        </ThemedButton>
                      )}
                    </ExTableCell>
                  </ExTableRow>
                )
              })}
            </ExTableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </Paper>
  )
}
