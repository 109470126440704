import { AxiosHttpClient } from 'api/axios'
import { useRepruaIndexApi } from 'api/shared_api_hooks'
import { PagingResponse } from 'entities'
import { OwnerFilter } from 'entities/dealer'
import { InstrumentRelationEvent, InstrumentRelationEventSearchForm } from 'entities/instrument_relation_event'
import { IndexApiSet } from 'rac'
import { useRecoilState } from 'recoil'
import { ownerFilterAtom } from 'recoil/partner/owner_filter/atom'
import { useEffectSkipFirst } from 'utils/hooks'

export type InstrumentRelationEventsResponse = PagingResponse & {
  instrumentRelationEvents: InstrumentRelationEvent[]
}
export type PartnerFetchInstrumentRelationEventsApiSet = IndexApiSet<InstrumentRelationEventsResponse> & { execute: () => void }

/**
 * 一覧
 */
export function usePartnerFetchInstrumentRelationEventsApi(): PartnerFetchInstrumentRelationEventsApiSet {
  // 顧客フィルターを適用する
  const [filter] = useRecoilState(ownerFilterAtom)
  const api = useRepruaIndexApi<InstrumentRelationEventsResponse, InstrumentRelationEventSearchForm & OwnerFilter>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { instrumentRelationEvents: [], totalCount: 0, },
  })

  const execute = () => {
    const path = 'instrument_relation_events'
    api.execute(path, { params: filter })
  }

  useEffectSkipFirst(() => {
    execute()
  }, [api.pageSet.page, api.pageSet.perPage, api.orderSet.order, api.orderSet.orderBy, filter])

  return { ...api, execute }
}
