import { useRepruaDeleteApi, useRepruaIndexApi, useRepruaPatchApi, useRepruaPostApi } from 'api/shared_api_hooks'
import { ID, PagingResponse } from 'entities'
import { OutsideDealer, OutsideDealerForm, OutsideDealerSearchForm } from 'entities/outside_dealer'
import { ApiSet, BaseResponse, Form, IndexApiSet } from 'rac'
import { useEffectSkipFirst } from 'utils/hooks'

import { AxiosHttpClient } from '../axios'

/**
 * 取得
 */
type OutsideDealersResponse = PagingResponse & {
  outsideDealers: OutsideDealer[]
}

export function useFetchOutsideDealersApi(): IndexApiSet<OutsideDealersResponse> & { execute: () => void } {
  const api = useRepruaIndexApi<OutsideDealersResponse, OutsideDealerSearchForm>(new AxiosHttpClient('user'), {
    initialResponse: { outsideDealers: [], totalCount: 0 },
  })

  const execute = () => {
    const path = 'outside_dealers'
    api.execute(path)
  }

  useEffectSkipFirst(() => {
    execute()
  }, [api.pageSet.page, api.pageSet.perPage, api.orderSet.order, api.orderSet.orderBy])

  return { ...api, execute }
}

/**
 * 登録
 */
type OutsideDealerResponse = BaseResponse & {
  outsideDealer?: OutsideDealer
}

export function usePostOutsideDealerApi(): ApiSet<OutsideDealerResponse> & {
  execute: (outsideDealerForm: Form<OutsideDealerForm>) => void
} {
  const api = useRepruaPostApi<OutsideDealerResponse, OutsideDealerForm>(new AxiosHttpClient('user'), { initialResponse: {} })

  const execute = (outsideDealerForm: Form<OutsideDealerForm>) => {
    const path = 'outside_dealers'
    api.execute(path, outsideDealerForm)
  }

  return { ...api, execute }
}

/**
 * 更新
 */
export function usePatchOutsideDealerApi(): ApiSet<OutsideDealerResponse> & { execute: (outsideDealerForm: OutsideDealerForm) => void } {
  const api = useRepruaPatchApi<OutsideDealerResponse, OutsideDealerForm>(new AxiosHttpClient('user'), { initialResponse: {} })

  const execute = (outsideDealerForm: OutsideDealerForm) => {
    const path = `outside_dealers/${outsideDealerForm.id}`
    api.execute(path, outsideDealerForm)
  }

  return { ...api, execute }
}

/**
 * 削除
 */
export function useDeleteOutsideDealerApi(): ApiSet<OutsideDealerResponse> & { execute: (id: ID) => void } {
  const api = useRepruaDeleteApi<OutsideDealerResponse>(new AxiosHttpClient('user'), { initialResponse: {} })

  const execute = (id: ID) => {
    const path = `outside_dealers/${id}`
    api.execute(path)
  }

  return { ...api, execute }
}
